import React from 'react'
import _ from 'lodash'

function setTdHeight() {
    setTimeout(() =>{
        try {
            let tableItems = document.querySelectorAll('.info-item-list');
            for (let i=0;i<tableItems.length;i++) {
                let tables = tableItems[i].children || [];
                let firstTr = tables[0].firstChild.children;
                let secondTr = tables[1].firstChild.children;
                for (let j=0;j<firstTr.length;j++) {
                    if (firstTr[j].offsetHeight > secondTr[j].offsetHeight) {
                        for (let k=0;k<secondTr[j].children.length;k++) {
                            secondTr[j].children[k].style.height = firstTr[j].offsetHeight + 'px'
                        }
                    }
                    if (firstTr[j].offsetHeight < secondTr[j].offsetHeight) {
                        for (let k=0;k<firstTr[j].children.length;k++) {
                            firstTr[j].children[k].style.height = secondTr[j].offsetHeight + 'px'
                        }
                    }
                }
            }
        }
        catch (e) {
            console.log(e)
        }
    },0)
}

class InfoItemList extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            list: props.list
        }
    }

    componentDidMount() {
        // 设置不同高度的行
        setTdHeight();
        window.onresize = () =>{
            setTdHeight();
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (JSON.stringify(this.state.list)!==JSON.stringify(nextProps.list)) {
            console.log('list更新')
            this.setState({
                list: nextProps.list
            },() =>{
                setTdHeight()
            })
        }
    }

    render() {
        let {list} = this.state;
        let addTd = false;
        let lastColor = '';
        let isSingle = Math.ceil((list.length/2))%2!==0;
        return (
            <div className="info-item-list">
                <table className="info-item-list__table" style={isSingle?{marginBottom:0}:{marginBottom: 40}}>
                    <tbody>
                    {
                        list.map((item,index) =>{
                            if (index%2===0) {
                                let className = '';
                                if ((index/2)%2!==0) {
                                    className = 'double-row'
                                }
                                return (
                                    <tr key={index} className={className}>
                                        <td className="table-label"><span>{item.label}</span></td>
                                        <td className="table-value"><span>{item.value || '-'}</span></td>
                                    </tr>)
                            }
                            else {
                                return null
                            }
                        })
                    }
                    </tbody>
                </table>

                <table className="info-item-list__table" style={isSingle?{marginBottom:0}:{marginBottom: 40}}>
                    <tbody>
                    {
                        list.map((item,index) =>{
                            if (index%2!==0) {
                                let className = '';
                                if (((index+1)/2)%2===0) {
                                    className = 'double-row'
                                }

                                if (index===list.length-2 && list[index+1] && !_.isEmpty(list[index+1])) {
                                    addTd = true;
                                    if (!className) {
                                        lastColor = 'double-row'
                                    }
                                }
                                return (
                                    <tr key={index} className={className}>
                                        <td className="table-label"><span>{item.label}</span></td>
                                        <td className="table-value"><span>{item.value || '-'}</span></td>
                                    </tr>)

                            }
                            else {
                                return null
                            }
                        })
                    }
                    {
                        addTd?(
                            <tr key='addTd' className={lastColor}>
                                <td className="table-label"><span/></td>
                                <td className="table-value"><span/></td>
                            </tr>
                        ) : null
                    }
                    </tbody>
                </table>
            </div>
        )
    }
}

export default InfoItemList