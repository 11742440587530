/**
 * 问题解析
 */
import React, {useState, useEffect} from 'react'
import {Icon, Spin, Button} from 'antd'
import AddTagComponent from "./AddTagComponent";
import attrData from './attributeList';
import stores from "../../../store";
import {observer} from 'mobx-react'

/*
[
    {"attribute": "object", "value": "营收"},
    {"attribute": "subject", "value": "万科"}
]
*/

function HomeRightQuestion(props) {
    const {homeLeftStore, homeRightStore, homeCommonStore} = stores;

    let {questionAnaList, setQuestionAnaList, questionAnaLoading, attributeModal, setAttributeModal} = homeRightStore;
    let {rightStatus} = homeCommonStore;
    let {crtQuestionIndex} = homeLeftStore;

    const attrList = attrData.keys;
    const [resAttrList, setResAttrList] = useState([]);    // 最终已选择列表
    const [actAttrList, setActAttrList] = useState([]);      // modal已选择列表

    useEffect(() => {
        let list = [];
        if (questionAnaList.length) {
            questionAnaList.forEach(item => {
                list.push(item.attribute);
            });
            setActAttrList(list)
        }
        setResAttrList(list);
    }, [questionAnaList]);

    // 删除标签
    const deleteTag = (index, valIndex) => {
        let newQuestionAnaList = [...questionAnaList];
        newQuestionAnaList[index].value.splice(valIndex, 1);
        setQuestionAnaList(newQuestionAnaList);
    };

    const attrModalClose = () => {
        setActAttrList(resAttrList);
        setAttributeModal(false);
    };

    const attrModalOK = () => {
        let addAttr = [],
            delAttr = [];
        for (let i = 0; i < resAttrList.length; i++) {
            let currAttr = resAttrList[i];
            if (!actAttrList.includes(currAttr)) {
                delAttr.push(currAttr)
            }
        }
        for (let i = 0; i < actAttrList.length; i++) {
            let currAttr = actAttrList[i];
            if (!resAttrList.includes(currAttr)) {
                addAttr.push(currAttr)
            }
        }

        if (!addAttr.length && !delAttr.length) {
            setAttributeModal(false);
            return;
        }
        let newList = [...questionAnaList];
        for (let i = 0; i < newList.length; i++) {
            if (delAttr.includes(newList[i].attribute)) {
                newList.splice(i, 1);
                i--;
            }
        }
        addAttr.forEach(attr => {
            newList.push({
                attribute: attr,
                value: []
            })
        });
        setQuestionAnaList(newList);
        setResAttrList(actAttrList);

        setAttributeModal(false);
    };

    const attrTagClick = attr => {
        let newList = [...actAttrList];
        let currIndex = newList.indexOf(attr);
        if (currIndex === -1) {
            newList.push(attr);
        } else {
            newList.splice(currIndex, 1);
        }
        setActAttrList(newList);
    };

    const QuestionAnaListUI = () => {
        return questionAnaList.map((item, index) => {
            return (
                <div key={index} className="right-question-card">
                    <div className="right-question-card-attribute">{item["attribute"]}</div>
                    <ul className="right-question-card-values">
                        {
                            item["value"].map((val, valIndex) => {
                                return (
                                    <li key={index + "_" + valIndex}
                                        className="value-item-card">
                                        {val}<Icon type="close"
                                                   onClick={() => deleteTag(index, valIndex)}
                                                   className="tag-delete-icon"/>
                                    </li>
                                )
                            })
                        }
                        <AddTagComponent list={questionAnaList} setList={setQuestionAnaList} item={item} index={index}
                                         placeholder="输入属性值"/>
                        {/*<li className="add-tag">
                            {
                                item["addStatus"] ? (
                                    <Input className="input-status"
                                           placeholder="输入属性值"
                                           onKeyUp={ev => addInputKeyUp(ev, index)}
                                           onBlur={ev => addInputBlur(ev, index)}
                                           ref={el => inputRefs[`input_${index}`] = el}/>
                                ) : (
                                    <div className="btn-status"
                                         onClick={() => changeAddStatus(index)}>
                                        <Icon type="plus" className="add-icon"/>添加
                                    </div>
                                )
                            }
                        </li>*/}
                    </ul>
                </div>
            )
        })

    };

    return (
        <>
            <div className="home-right-question home-right-module"
                 style={rightStatus === null || rightStatus === 0 ? null : {height: 0}}>
                {rightStatus === null ? "点击左侧问题提纲问题获取解析内容" : (
                    <>
                        <h3 className="home-right-module-title">问题{crtQuestionIndex + 1}</h3>
                        <Spin spinning={questionAnaLoading}>
                            <ul className="home-right-module-content">
                                <li className="home-right-card">
                                    {questionAnaList.length === 0 ? null : QuestionAnaListUI()}
                                    <div className="right-question-card">
                                        <div className="right-question-card-attribute add-attribute"
                                             onClick={() =>setAttributeModal(true)}><Icon type="plus"
                                                                               className="add-icon"/> 添加
                                        </div>
                                        <ul className="right-question-card-values"/>
                                    </div>
                                </li>
                            </ul>
                        </Spin>
                    </>
                )}
            </div>
            <div className="add-attribute-modal" style={attributeModal ? {} : {display: "none"}}>
                <div className="attr-modal-content">
                    <span className="attr-modal-close" onClick={attrModalClose}><Icon type="close"/></span>
                    <h4 className="attr-modal-title">类型</h4>
                    <ul className="attr-modal-ul">
                        {
                            attrList.map((attr, index) => {
                                return <li key={`attr_${index}`} onClick={() => attrTagClick(attr)}
                                           className={actAttrList.includes(attr) ? "selected-attr attr-tags" : "attr-tags"}>{attr}</li>
                            })
                        }
                    </ul>
                    <div className="attr-modal-btn">
                        <Button type="primary" onClick={attrModalOK} className="attr-modal-btn">确定</Button>
                    </div>
                </div>
            </div>
        </>

    )
}

export default observer(HomeRightQuestion)
