import React from 'react'
import _ from 'lodash'
import {
  Input,
  Select,
  Button,
  Icon,
  message,
  Upload,
  Drawer,
  Popover,
  Affix
} from 'antd'
import AppContext from '../../AppContext'
import './PublicHeader.scss'
import '../../css/iconfont/iconfont.css'
import '../../css/iconfont2/iconfont.css'
import '../../css/iconf/iconfont.css'
import '../../css/navIcon/iconfont.css'
import '../../css/liuqi/iconfont.css'
import PublicReadList from './PublicReadList'
import { webSdk } from '../../api/WebSdk'
import cycleAnswerClose from '../../assets/svg/cycleAnswer_close.svg'
import cycleAnswerOpen from '../../assets/svg/cycleAnswer_open.svg'

const { Search } = Input
const { Option } = Select
let backVal = ''

//let load = false
class PublicHeader extends React.PureComponent {
  static contextType = AppContext

  constructor(props) {
    super(props)
    //debugger
    this.state = {
      load: false,
      currentUrl: props.currentUrl || '',
      shrinkMenu: props.shrinkMenu || false,
      visible: false, //知识点页面-显示左侧抽屉菜单
      menuList: [
        {
          icon: 'nav_icon icon-nav_home',
          label: '首页',
          key: 'home'
        },
        // {
        //     icon: 'nav_icon icon-nav_know',
        //     label: '知识点',
        //     key: 'knowledge'
        // },
        // {
        //     icon: 'nav_icon icon-liebiao',
        //     label: '企业列表',
        //     key: 'companyList'
        // },
        {
          icon: 'nav_icon icon-nav_teach',
          label: '提纲模板',
          key: 'syllabus'
        },
        {
          icon: 'nav_icon icon-nav_setting',
          label: '报告管理',
          key: 'report'
        },
        {
          icon: 'nav_icon icon-nav_rese',
          label: '研报库',
          key: 'research'
        },
        {
          icon: 'nav_icon icon-nav_annu',
          label: '企业年报',
          key: 'yearReport'
        },
        {
          icon: 'nav_icon icon-nav_inst',
          label: '招股说明书',
          key: 'invitation'
        },
        {
          icon: 'iconfont_mei icongupiao-copy',
          label: '财经库',
          key: 'finance'
        },
        {
          icon: 'liuqi icon-finance-sub',
          label: '财务子库',
          key: 'fincSubLibrary'
        },
        {
          icon: 'liuqi icon-database',
          label: '工商数据库',
          key: 'businessDatabase'
        },
        {
          icon: 'iconf icon-zhengcefagui',
          label: '政策法规',
          key: 'regulationFall'
        },
        {
          icon: 'liuqi icon-zhishitupu',
          label: '学科知识图谱',
          key: 'subjectGraph'
        },
        //下期需求
        // {
        //     icon: 'font_family icon-baobiao',
        //     label: '数据集',
        //     key: 'gathering'
        // },
        {
          icon: 'nav_icon icon-nav_port',
          label: '企业画像',
          key: 'entersinfo'
        },
        {
          icon: 'nav_icon icon-nav_pers',
          label: '个人中心',
          key: 'UserCenter',
          showChild: false,
          children: [
            {
              label: '阅读列表',
              key: 'readList'
            },
            // {
            //     label: '上传文档',
            //     key: 'upload'
            // },
            {
              label: '文档管理',
              key: 'fileManage'
            }
          ]
        }
        // {
        //     icon: 'nav_icon iconTeachingprogram1',
        //     label: '教纲管理',
        //     key: 'lessonplan'
        // },
      ],
      currentMenu: props.currentMenu || 'home',
      searchValue: props.searchValue || '',
      selectData: [],
      //搜索类型
      typeSelect: [
        {
          label: '首页',
          value: 'home'
        },
        /*{
                    label: '知识点',
                    value: 'knowledge'
                },*/
        {
          label: '企业',
          value: 'companyList'
        },
        {
          label: '研报库',
          value: 'research'
        },
        {
          label: '企业年报',
          value: 'yearReport'
        },
        {
          label: '招股说明书',
          value: 'invitation'
        },
        {
          label: '企业画像',
          value: 'entersinfo'
        },
        {
          label: '教纲管理',
          value: 'lessonplan'
        }
      ],
      type: 'home',
      /*showSearchList: false,
            searchList: [
                {
                    label: '下拉数据一',
                    value: ''
                },
                {
                    label: '下拉数据二',
                    value: ''
                },
                {
                    label: '下拉数据三',
                    value: ''
                },
                {
                    label: '下拉数据四',
                    value: ''
                },
                {
                    label: '下拉数据五',
                    value: ''
                },
                {
                    label: '下拉数据六',
                    value: ''
                }
            ],
            hoverIndex: -1,*/
      location: props.location,
      showMenu: props.showMenu || true, //是否显示菜单栏
      popoverVisible: false,
      // 12.14 搜索框下方列表
      searchType: props.currentSearchType || 'list',
      /*isSearchPage: props.isSearchPage || false,
            graphSearchList: [
                /!*{
                    label: '文图',
                    key: 'text'
                },*!/
                {
                    label: '列表',
                    key: 'list'
                },
                {
                    label: '可视化',
                    key: 'graph'
                },
                /!*{
                    label: '模板',
                    key: 'template'
                }*!/
            ],*/
      isShowCycleQues: false, //多伦问答是否展示
      filterList: {
        //筛选条件列表
        isShow: false, //是否显示
        list: [
          {
            content: '十日均量线下'
          },
          {
            content: '昨日成交量大于前一天'
          },
          {
            content: '昨日成交量大于前一天'
          },
          {
            content: '昨日成交量大于前一天'
          },
          {
            content: '昨日成交量大于前一天'
          },
          {
            content: '昨日成交量大于前一天'
          },
          {
            content: '昨日成交量大于前一天'
          }
        ]
      }
    }

    this.menuDo = this.menuDo.bind(this)
    this.upLoadChange = this.upLoadChange.bind(this)
    this.searchWord = this.searchWord.bind(this)
    this.searchInputChange = this.searchInputChange.bind(this)
    this.changeType = this.changeType.bind(this)
    this.toHome = this.toHome.bind(this)
    this.loginOut = this.loginOut.bind(this)
  }

  componentDidMount() {
    document.addEventListener('click', () => {
      this.setState({
        popoverVisible: false
      })
    })
  }

  componentWillReceiveProps(nextProps) {
    backVal = nextProps.searchValue || ''
    if (
      nextProps.currentMenu === 'readList' ||
      nextProps.currentMenu === 'fileManage'
    ) {
      let menuList = [...this.state.menuList]
      menuList[menuList.length - 1].showChild = true
      this.setState({
        menuList
      })
    }

    let currentUrl = nextProps.currentUrl
    let searchValue = nextProps.searchValue
    if (
      nextProps.currentMenu === 'finance' ||
      nextProps.currentMenu === 'fincSubLibrary'
    ) {
      searchValue = ''
    }
    this.setState({
      showMenu: nextProps.showMenu,
      currentMenu: nextProps.currentMenu,
      shrinkMenu: nextProps.shrinkMenu,
      visible: false,
      searchValue: searchValue,
      searchType: nextProps.currentSearchType,
      type: nextProps.searchType,
      load: true,
      isSearchPage: nextProps.isSearchPage,
      currentUrl: currentUrl
    })
  }

  componentWillUnmount() {
    document.removeEventListener('click', () => {})
    // 卸载异步操作设置状态
    this.setState = (state, callback) => {
      return
    }
  }

  toHome() {
    this.context.getHistory().push({
      pathname: `/home`
    })
  }

  showMenu() {
    let currUrl = this.props.currentUrl
    if (
      this.state.currentMenu &&
      this.state.currentMenu !== 'knowledge' &&
      this.state.currentMenu !== 'resultList' &&
      this.state.currentMenu !== 'entersinfo' &&
      !currUrl.includes('/financedetail/')
    ) {
      this.props.fnShrinkMenu()
    } else {
      this.setState({
        visible: !this.state.visible
      })
    }
  }

  onClose() {
    this.setState({
      visible: false
    })
  }

  menuDo(item, ev) {
    if (item.key === 'readList') {
      let currUrl = this.props.currentUrl // currUrl = /researchResult/1/14

      if (/^\/researchResult\/[0-9]*\/[0-9]*\/[0-9]*/.test(currUrl)) {
        let currUrlArr = _.split(currUrl, '/')
        if (!_.isEmpty(currUrlArr) && currUrlArr.length >= 5) {
          let id = currUrlArr[3]
          let type = currUrlArr[2]
          this.refs.publicReadList.openReadList(false, id, type)
        }
      } else {
        this.refs.publicReadList.openReadList(false)
      }
    } else {
      if (item.children && item.children.length > 0) {
        if (this.state.shrinkMenu) {
          ev.nativeEvent.stopImmediatePropagation()
          this.setState({
            popoverVisible: !this.state.popoverVisible
          })
        }
        item.showChild = !item.showChild
        let menuList = [...this.state.menuList]
        this.setState({
          menuList
        })
      } else {
        /*if (this.state.currentMenu === 'knowledge') {
                    this.setState({
                        visible: false,
                        searchValue: '',
                        type: 'knowledge'
                    }, () => {
                        this.context.getHistory().push({
                            pathname: `/${item.key}`,
                        })
                        this.props.fnChangeMenu(item)
                        document.querySelector('body').style.overflow = 'auto'
                    })
                }
                else {
                    this.setState({
                        visible: false
                    }, () => {
                        let pathname = this.state.searchValue?`/${item.key}/${this.state.searchValue}`:`/${item.key}`;
                        this.context.getHistory().push({
                            pathname,
                        })
                        this.props.fnChangeValue(this.state.searchValue);
                        this.props.fnChangeMenu(item);
                        /!*this.setState({
                            searchValue: '',
                            type: item.key && item.key !== 'readList' ? item.key : 'knowledge'
                        })*!/
                        document.querySelector('body').style.overflow = 'auto'
                    })
                }*/
        this.setState(
          {
            visible: false
          },
          () => {
            let pathname = `/${item.key}`
            this.context.getHistory().push({
              pathname
            })
            this.props.fnChangeValue(this.state.searchValue)
            this.props.fnChangeMenu(item)
            /*this.setState({
                        searchValue: '',
                        type: item.key && item.key !== 'readList' ? item.key : 'knowledge'
                    })*/
            // 防止svg显示滚动条
            let elems = document.querySelectorAll('svg.leader-line')
            for (let i = 0; i < elems.length; i++) {
              elems[i].style.display = 'none'
            }
            document.querySelector('body').style.overflow = 'auto'
          }
        )
      }
    }
  }

  //上传文件进程
  upLoadChange(info) {
    //debugger
    if (info.file.status === 'uploading') {
      document.querySelector('html').classList.add('lockHTML')
    } else if (info.file.status === 'done') {
      //info.file.response.name...
      document.querySelector('html').classList.remove('lockHTML')
      message.success('上传成功', 1.5)
      this.context.getHistory().push({
        pathname: `/result/fileAnalysis/${info.file.name}`,
        state: {
          fileName: info.file.name,
          keyword: ''
        }
      })
    } else {
      document.querySelector('html').classList.remove('lockHTML')
      message.error('上传失败', 3)
    }
  }

  /**
   * 搜索事件
   */
  searchWord(val) {
    /*let {type} = this.state;
        let path = type==='home'?'resultList':type;
        let url = '';
        if (val) {
            url = val? `/${path}/${val}`: `/${path}`
        }
        else {
            if (type==='home') {
                url = '/home'
            }
            else {
                url = `/${path}`
            }
        }*/
    let currentUrl = this.state.currentUrl
    let url = ''
    if (val !== '') {
      url = `/resultList/${val}${
        this.state.searchType ? '/' + this.state.searchType : null
      }`

      if (val.indexOf('企业画像') > -1) {
        url = `/entersinfo/${val}`
      }
    } else {
      // url = '/home'
      url = `/resultList/${
        this.state.searchType ? '/' + this.state.searchType : null
      }`
    }

    this.setState({
      searchValue: val
    })
    this.props.fnSetType(null, val)
    this.context.getHistory().push({
      pathname: url
    })
  }

  /**
   * 搜索框数据变更
   */
  searchInputChange(ev) {
    let val = ev.target.value
    backVal = val
    this.setState({
      searchValue: val
    })
    // this.props.fnSetType(null, val)
  }

  /**
   * 搜索框搜索类型变更
   */
  changeType(val) {
    this.setState({
      type: val
    })
  }

  /**
   * 搜索框按键时间，上下控制选择的数据
   */
  /*searchInputKeyDown(ev) {
        if (ev.keyCode === 40 || ev.keyCode === 38) {
            ev.nativeEvent.stopImmediatePropagation();
            let searchList = this.state.searchList,
                hoverIndex = this.state.hoverIndex;
            let val = '';
            if (ev.keyCode === 40) {        //down
                if (searchList.length - 1 === hoverIndex) {
                    hoverIndex = -1;
                    val = backVal;
                }
                else {
                    hoverIndex++;
                    val = searchList[hoverIndex].label;
                }
            }
            else if (ev.keyCode === 38) {     //up
                if (hoverIndex === 0) {
                    hoverIndex = -1;
                    val = backVal;
                }
                else if (hoverIndex === -1) {
                    hoverIndex = searchList.length - 1;
                    val = searchList[hoverIndex].label;
                }
                else {
                    hoverIndex--;
                    val = searchList[hoverIndex].label;
                }
            }
            this.setState({
                hoverIndex,
                searchValue: val
            })
        }

    }*/

  /**
   * 搜搜下拉框鼠标移入事件
   */
  /*searchDropEnter(index) {
        this.setState({
            hoverIndex: index
        })
    }*/

  /**
   * 搜索框聚焦
   */
  /*searchInputFocus(ev) {
        this.setState({
            showSearchList: true,
            hoverIndex: -1
        })
    }*/

  /**
   * 搜索框失去焦点
   */

  /*searchInputBlur(ev) {
        backVal = ev.target.value;
        this.setState({
            showSearchList: false,
            hoverIndex: -1
        })
    }*/

  /**跳转到对应页面中*/
  fnToArticlePage(item) {
    this.context.getHistory().push({
      pathname: `/researchResult/${item.type}/${item.obj_id}/${item.type}`
    })
  }

  loginOut() {
    //关闭多伦问答
    let isShowCycleQues = this.state.isShowCycleQues
    if (isShowCycleQues) {
      this.handleCycleQuestion()
      this.props.fnClearCycleAnswerStor()
    }

    webSdk.search
      .loginOut()
      .then((res) => {
        if (res.result) {
          window.sessionStorage.setItem('hasLogin', '')
          window.localStorage.setItem('userInfo', '')

          this.context.getHistory().push({
            pathname: '/login'
          })
          message.success('退出登录')
        } else {
          message.error(res.errMsg)
        }
      })
      .catch((e) => {
        message.error('服务器繁忙，请稍后重试')
      })
  }

  /*changeSearchType = searchType => {
        this.setState({
            searchType
        }, () => {
            this.props.changeSearchType(searchType)
        })
    }*/

  /**操作多伦问答显隐 */
  handleCycleQuestion() {
    let isShowCycleQues = !this.state.isShowCycleQues

    this.handleCycleQuestionAuto(isShowCycleQues)
    //清除多伦问答数据
    this.props.fnHandleCycleQuestion()
  }

  /**手动操作多伦问答显隐 */
  handleCycleQuestionAuto(value) {
    this.setState({
      isShowCycleQues: value
    })
  }

  /**设置过滤列表数据
   *
   * @param {array} list 列表数组
   */
  initFilterList(list) {
    let filterList = { ...this.state.filterList }
    filterList.list = list
    this.setState({
      filterList
    })
  }

  /**是否显示过滤列表
   *
   * @param {boolean} type 类型：true显示；false隐藏
   * @param {boolean} allow 是否允许控制弹窗
   */
  triggerFilterList(type, allow) {
    let filterList = { ...this.state.filterList }
    if (allow) {
      filterList.isShow = type
    } else {
      filterList.isShow = false
    }

    this.setState({
      filterList
    })
  }

  /**点击过滤列表
   *
   * @param {object} item 过滤项
   */
  clickFilterList(item) {
    let filterList = { ...this.state.filterList }
    let searchValue = this.state.searchValue
    searchValue += `，${item.content}`
    filterList.isShow = false
    this.setState(
      {
        filterList,
        searchValue
      },
      () => {
        this.searchWord(searchValue)
      }
    )
  }

  render() {
    //let { graphSearchList, isSearchPage, searchType } = this.state;

    let LeftMenu = (
      <ul className="drawer-left-menu">
        {this.state.menuList.map((item, index) => {
          return (
            <li key={index} className="left-menu-li">
              <div
                className={
                  this.state.currentMenu === item.key
                    ? 'select-menu-item drawer-left-menu-item'
                    : 'drawer-left-menu-item'
                }
                onClick={(ev) => this.menuDo(item, ev)}
              >
                {/* <span className="left-menu-item-icon">{item.icon ? (<span className="font_icon">{item.icon}</span>) : null}</span> */}
                <span className="left-menu-item-icon">
                  {item.icon ? <span className={item.icon} /> : null}
                </span>
                <span className="left-menu-item-label">{item.label}</span>
                {item.children && item.children.length > 0 ? (
                  <span
                    className={
                      item.showChild > 0
                        ? 'drawer-left-menu-item-dropIcon up-icon'
                        : 'drawer-left-menu-item-dropIcon'
                    }
                  >
                    <Icon type="down" />
                  </span>
                ) : null}
              </div>
              {item.children && item.children.length > 0 ? (
                <ul
                  className={
                    item.showChild
                      ? 'drawer-left-menu-child show-child'
                      : 'drawer-left-menu-child hidden-child'
                  }
                >
                  {item.children.map((child, childIndex) => {
                    if (child.key === 'upload') {
                      return (
                        <li
                          key={childIndex}
                          className="drawer-left-menu-item upload-item"
                        >
                          <Upload
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            showUploadList={false}
                            onChange={this.upLoadChange}
                          >
                            <div className="upload-box">
                              <div className="upload-txt">{child.label}</div>
                            </div>
                          </Upload>
                        </li>
                      )
                    } else {
                      return (
                        <li
                          onClick={(ev) => this.menuDo(child, ev)}
                          className={
                            this.state.currentMenu === child.key
                              ? 'select-menu-item drawer-left-menu-item'
                              : 'drawer-left-menu-item'
                          }
                          style={{ paddingLeft: 57 }}
                          key={childIndex}
                        >
                          {child.label}
                        </li>
                      )
                    }
                  })}
                </ul>
              ) : null}
            </li>
          )
        })}
      </ul>
    )

    let MinMenuRight = (
      <ul className="min-left-menu-popover">
        {_.find(
          this.state.menuList,
          (item) => item.key === 'UserCenter'
        ).children.map((item, index) => {
          return (
            <li
              key={item.key}
              className={
                this.state.currentMenu === item.key
                  ? 'min-left-menu-popover-item active-popover-item'
                  : 'min-left-menu-popover-item'
              }
              onClick={(ev) => this.menuDo(item, ev)}
            >
              {item.label}
            </li>
          )
        })}
      </ul>
    )
    let MinLeftMenu = (
      <ul className="drawer-left-menu">
        {this.state.menuList.map((item, index) => {
          if (item.icon) {
            return (
              <li
                key={index}
                onClick={(ev) => this.menuDo(item, ev)}
                className={
                  this.state.currentMenu === item.key
                    ? 'select-menu-item drawer-left-menu-item-min'
                    : 'drawer-left-menu-item-min'
                }
              >
                {item.key === 'UserCenter' ? (
                  <div className="useCenter-min-card">
                    <Popover
                      placement="right"
                      content={MinMenuRight}
                      trigger="click"
                      overlayClassName="user-center-popover"
                      visible={this.state.popoverVisible}
                      getPopupContainer={() =>
                        document.querySelector('.useCenter-min-card')
                      }
                    >
                      <span className="left-menu-item-icon">
                        {item.icon ? <span className={item.icon} /> : null}
                      </span>
                      <span className="left-menu-item-label">{item.label}</span>
                    </Popover>
                  </div>
                ) : (
                  <>
                    <span className="left-menu-item-icon">
                      {item.icon ? <span className={item.icon} /> : null}
                    </span>
                    <span className="left-menu-item-label">{item.label}</span>
                  </>
                )}
              </li>
            )
          } else {
            return null
          }
        })}
      </ul>
    )

    // 搜索类型选择
    let SearchSelect = (
      <div className="public-header__search-select">
        <Select
          style={{ width: 101, height: 30 }}
          value={this.state.type}
          onChange={this.changeType}
          dropdownClassName="search-select-item"
        >
          {this.state.typeSelect.map((item, index) => {
            return (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            )
          })}
        </Select>
      </div>
    )

    //搜索框下拉列表
    /*let SearchDropList = (
            <div className="search-drop-SearchResultList-box" style={this.state.showSearchList && this.state.searchList.length > 0?{display: 'block'}:{display: 'none'}}>
                <ul className="search-drop-SearchResultList">
                    {
                        this.state.searchList.map((item, index) => {
                            return (
                                <li className={this.state.hoverIndex === index ? "search-drop-item-hover search-drop-item" : "search-drop-item"} key={item.label} onMouseEnter={() =>this.searchDropEnter(index)}>{item.label}</li>
                            )
                        })
                    }
                </ul>
            </div>
        )*/

    // 12.14 新版搜索搜索框下方列表
    /*let TypeList = (
            <ul className="graph-search-list">
                {
                    graphSearchList.map((item, index) => {
                        return (
                            <li key={item.key}
                                className={searchType === item.key ? "graph-search-item active-item" : "graph-search-item"}
                                onClick={() => this.changeSearchType(item.key)}>{item.label}</li>
                        )
                    })
                }
            </ul>
        )*/

    //筛选列表
    let filterList = (
      <ul className="search-filterlist">
        {this.state.filterList.list.map((item, idx) => (
          <li key={idx} onClick={this.clickFilterList.bind(this, item)}>
            <span className="iconfont search-filter-icon">&#xe60c;</span>
            {item.content}
          </li>
        ))}
      </ul>
    )

    return (
      <div className="public-header">
        <div className="public-header-style">
          <div className="public-header__main">
            <div className="public-header__left-menu">
              <span
                onClick={this.showMenu.bind(this)}
                className="font_icon header-left"
                style={{ fontSize: 21 }}
              >
                &#xe660;
              </span>
              <span className="center-search-logo">
                <span className="logo-click" onClick={() => this.toHome()} />
                企业信息库
              </span>
              {/* <span className="center-search-logo">
                        <span className="logo-click" onClick={() => this.toHome()}>
                            <i className="iconfont logo-click-icon">&#xe607;</i>
                        </span>
                        企业信息库
                    </span> */}
            </div>
            <div className="public-header__center-search">
              <Popover
                trigger="click"
                placement="bottomLeft"
                overlayClassName="public-header__search-filter"
                content={filterList}
                visible={this.state.filterList.isShow}
                onVisibleChange={this.triggerFilterList.bind(this)}
              >
                <Search
                  className="center-search-input"
                  placeholder="请输入关键词搜索"
                  allowClear={true}
                  value={this.state.searchValue}
                  onChange={(ev) => this.searchInputChange(ev)}
                  onSearch={(value) => this.searchWord(value)}
                  /*onKeyDown={ev => this.searchInputKeyDown(ev)}
                                onFocus={ev => this.searchInputFocus(ev)}
                                onBlur={ev => this.searchInputBlur(ev)}*/
                  style={{ width: 500, height: 30 }}
                  //style={{ width: 380, height: 30 }}
                  // prefix={SearchSelect}
                />
              </Popover>
              <div
                className="header-cycle-question"
                onClick={this.handleCycleQuestion.bind(this)}
                title="多伦问答"
              >
                <img
                  src={
                    this.state.isShowCycleQues
                      ? cycleAnswerOpen
                      : cycleAnswerClose
                  }
                  alt=""
                />
              </div>
              {this.state.filterList.isShow ? <div></div> : null}
              {/*{
                            isSearchPage ? TypeList : null
                        }*/}
              {/* {SearchDropList} */}
            </div>
            <div className="public-header__right-login">
              {/*<Button className="right-login-login">登录</Button>*/}
              {/*<Button className="right-login-register" type="primary">注册</Button>*/}
              <Button
                className="right-login-login"
                onClick={this.loginOut}
                style={{ padding: '0 15px', width: 'auto' }}
              >
                退出登录
              </Button>
            </div>
            {this.state.load && this.state.showMenu ? (
              <Drawer
                placement="left"
                className="public-header-left-menu left-always-menu"
                width={this.state.shrinkMenu ? 110 : 190}
                style={{ top: 60 }}
                closable={false}
                mask={false}
                visible={true}
              >
                {this.state.shrinkMenu ? MinLeftMenu : LeftMenu}
              </Drawer>
            ) : (
              <Drawer
                placement="left"
                width={190}
                className="public-header-left-menu left-drop-menu"
                style={{ top: 60 }}
                closable={false}
                onClose={this.onClose.bind(this)}
                visible={this.state.visible}
              >
                {LeftMenu}
              </Drawer>
            )}
            <PublicReadList
              ref="publicReadList"
              fnToArticlePage={this.fnToArticlePage.bind(this)}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default PublicHeader
