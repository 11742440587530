import {action, observable} from 'mobx'

/**
 * 首页左侧
 */
export default class HomeCenterStore {
    @observable centerOpen = false;
    @observable page = 1;
    @observable pageSize = 5;
    @observable totalNum = 0;
    @observable corpusMatchLoading = false;
    @observable corpusMatchList = [];   // 语料列表
    @observable corpusMatchChecked = [];   // 已勾选语料列表
    @observable crtCorpusId = null;   // 当前选择的中间语料id
    @observable crtCorpusItem = {};     // 当前选择的中间语料对象

    @observable corpusMatchFalse = false;   // 获取语料列表错误

    @observable articleModal = false;       // 查看原文弹窗
    @observable articleModalTitle = '';     // 弹窗标题
    @observable articleModalUrl = '';       // 弹窗pdf url

    @observable hoverId = null;             // 鼠标移入的id，用作连线高亮边框

    @observable dbClickId = null;           // 当前双击对象的id
    @observable positionTop = null;         // 当前双击对象距离浏览器顶部位置

    @observable positionData = null;        // 查看原文彈窗高亮信息

    @action unmountAction = () =>{
        this.centerOpen = false;
        this.page = 1;
        this.pageSize = 5;
        this.totalNum = 0;
        this.corpusMatchLoading = false;
        this.corpusMatchList = [];
        this.corpusMatchChecked = [];
        this.crtCorpusId = null;
        this.crtCorpusItem = {};
        this.corpusMatchFalse = false;
        this.articleModal = false;
        this.articleModalTitle = '';
        this.articleModalUrl = '';
        this.hoverId = null;
        this.dbClickId = null;
        this.positionTop = null;
        this.positionData = null;
    };


    @action setCenterOpen = status => {
        this.centerOpen = status;
    };

    @action setPageData = (page, totalNum, pageSize) => {
        if (page || page === 0) this.page = page;
        if (totalNum || totalNum === 0) this.totalNum = totalNum;
        if (pageSize || pageSize === 0) this.pageSize = pageSize;
    };

    @action setCorpusMatchLoading = status => {
        this.corpusMatchLoading = status;
    };

    @action setCorpusMatchList = list => {
        this.corpusMatchList = list;
    };

    @action setCorpusMatchChecked = list => {
        this.corpusMatchChecked = list;
    };

    @action setCrtCorpusId = id => {
        this.crtCorpusId = id;
    };

    @action setCrtCorpusItem = item => {
        this.crtCorpusItem = item;
    };

    @action setCorpusMatchFalse = status => {
        this.corpusMatchFalse = status;
    };

    @action setArticleModal = status => {
        this.articleModal = status;
        if (!status) {
            this.articleModalTitle = '';     // 弹窗标题
            this.articleModalUrl = '';       // 弹窗pdf url
            this.positionData = null;
        }
    };

    @action setArticleModalTitle = title => {
        this.articleModalTitle = title;
    };

    @action setArticleModalUrl = url => {
        this.articleModalUrl = url;
    };

    @action setHoverId = id => {
        this.hoverId = id;
    };

    @action setDbItem = (id, top) => {
        this.dbClickId = id;
        this.positionTop = top;
    };

    @action setPositionData = data => {
        this.positionData = data;
    }
}
