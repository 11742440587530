import React, { PureComponent } from 'react';
import _ from 'lodash'
import { observer, inject } from 'mobx-react'
import './ExampleList.scss'
import { Collapse, Button, Icon, Checkbox, Input, Spin, Empty } from 'antd';
import { webSdk } from '../../../api/WebSdk';

const { Panel } = Collapse;
const { TextArea } = Input;

@inject("smartSearchStore")//引入共享数据库
@observer
class ExampleList extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            currentActivePanel: [],//当前激活的面板
            isAllowCreateSummary: false,//是否允许生成面板
            isSpinning: true,//是否加载数据
            addExampleInfo: {
                isShow: false,//是否展示添加案例
                forWhichOne: -1,//添加到哪个案例
                value: "",//案例文案
            },
            answerList: [ //案例列表
                // {
                //     id: 12,
                //     question: "万科地产股份",
                //     examChecked: [],//选中案例的idx
                //     examples: [
                //         {
                //             id: 12,
                //             title: "泰禾集团上半年亏损15.82亿元 控股股东拟引万科为战投",
                //             time: "2020.08.18",
                //             summary: "正忙于解决负债，引入战投的泰禾集团(000732)8月14日晚间发布半年报。公司上半年实现营收24.63亿元，同比减少83.02%；净利润亏损15.82亿元，去年同期盈利15.61亿元。"
                //         },
                //     ]
                // },
            ],
        }
    }

    componentDidMount() {
        this.getExampleDatas()
    }

    /** 获取案例列表 */
    getExampleDatas() {
        let answerList = []
        let currentActivePanel = []
        let smartSearchStore = this.props.smartSearchStore
        let questions = smartSearchStore.getQuestionList()
        this.handleSpinning(true)

        // 获取问题列表，并判断是哪种请求方式
        let paramQuest = []
        let paramQuestIds = []
        _.forEach(questions, quesItem => {
            if (quesItem.id != null) {
                paramQuestIds.push(quesItem.id)
            } else if (quesItem.text != null) {
                paramQuest.push(quesItem.text)
            } else {
                this.props.history.push({
                    pathname: "/smartsearchhome"
                })
            }
        })

        let params = {
            questions: !_.isEmpty(paramQuest) ? JSON.stringify(paramQuest) : "",
            questionIds: !_.isEmpty(paramQuestIds) ? JSON.stringify(paramQuestIds) : ""
        }

        webSdk.smartSearchApi.caseQuery(params).then(res => {
            _.forEach(res, (resItem, resIdx) => {
                let resCase = resItem.case
                let resAddCase = resItem.add_case

                // 搜索出来的案例
                let exampleList = []
                _.forEach(resCase, (resCaseItem, resCaseIdx) => {

                    exampleList.push({
                        id: resCaseItem.kp_id,
                        title: resCaseItem.art_title || "",
                        time: resCaseItem.art_date ? _.replace(resCaseItem.art_date, /\-/g, ".") : "",
                        summary: resCaseItem.text || ""
                    })
                })

                // 用户添加的案例
                if (resAddCase !== null && resAddCase !== undefined && resAddCase !== "") {
                    let resAddCaseObj = JSON.parse(resAddCase)
                    _.forEach(resAddCaseObj, caseItem => {
                        exampleList.push({
                            id: -1,
                            title: "",
                            time: "",
                            summary: caseItem.text
                        })
                    })
                }

                let ques = {
                    id: resItem.qid,
                    question: resItem.query,
                    examChecked: [],//选中案例的idx+1
                    examples: exampleList
                }

                currentActivePanel.push(resIdx)
                answerList.push(ques)
            })

            this.setState({
                answerList, currentActivePanel
            }, () => {
                this.handleSpinning(false)
                this.upgradeQuestionListInStore()
            })
        }).catch(err => {
            this.setState({
                answerList
            }, () => {
                this.handleSpinning(false)
            })
        })
    }

    /** 操作是否加载数据状态 */
    handleSpinning(status) {
        this.setState({
            isSpinning: status
        })
    }

    /** 拿到数据后，更新当前store里的问题列表 */
    upgradeQuestionListInStore() {
        let smartSearchStore = this.props.smartSearchStore
        let answerList = this.state.answerList

        if (_.isEmpty(answerList)) return;


        let questionList = []
        _.forEach(answerList, ansItem => {
            questionList.push({
                id: ansItem.id,
                text: ansItem.question
            })
        })

        smartSearchStore.setQuestionList(questionList)
    }

    /** 操作面板激活 */
    changePanels(panels) {
        this.setState({
            currentActivePanel: panels
        })
    }

    /** 操作添加面板 */
    handleAddPanel(isShow, quesId, event) {
        let addExampleInfo = { ...this.state.addExampleInfo }
        addExampleInfo.isShow = isShow

        if (isShow) {
            addExampleInfo.forWhichOne = quesId
        } else {
            addExampleInfo.forWhichOne = -1
            addExampleInfo.value = ""
        }

        this.setState({
            addExampleInfo
        })

        if (event)
            event.stopPropagation();
    }

    /** 改变添加案例文本 */
    changeExampleText(event) {
        let addExampleInfo = { ...this.state.addExampleInfo }
        addExampleInfo.value = event.target.value
        this.setState({
            addExampleInfo
        })

    }

    /** 添加案例 */
    addExample() {
        let addExampleInfo = this.state.addExampleInfo
        let answerList = [...this.state.answerList]
        let value = addExampleInfo.value
        if (!value) return;

        let quesIdx = addExampleInfo.forWhichOne//添加到哪个问题的idx
        answerList[quesIdx].examples.push({
            id: -1,
            title: "",
            time: "",
            summary: value
        })

        this.setState({
            answerList
        }, () => {
            this.handleAddPanel(false, null, null)
            this.behaviourRecord(quesIdx)
        })
    }

    /** 全选 */
    handleCheckAll(quesIdx, event) {
        event.stopPropagation()
        let answerList = [...this.state.answerList]
        let currentQuestion = answerList[quesIdx]
        let examChecked = currentQuestion.examChecked
        let examples = currentQuestion.examples

        if (examChecked.length === examples.length) {
            currentQuestion.examChecked = []

        } else {
            currentQuestion.examChecked = examples.map((item, idx) => idx)
        }

        this.setState({
            answerList
        }, () => {
            this.handleCreateSummaryBtn()
            this.behaviourRecord(quesIdx)
        })

    }

    /** 多选框 */
    stopPropCheckBox(event) {
        event.stopPropagation()
    }
    handleCheckBox(quesIdx, examIdx, event) {
        let isChecked = event.target.checked
        let answerList = [...this.state.answerList]

        if (isChecked) {
            answerList[quesIdx].examChecked.push(examIdx)
        } else {
            answerList[quesIdx].examChecked = answerList[quesIdx].examChecked.filter((item, idx) => item !== examIdx)
        }

        this.setState({
            answerList
        }, () => {
            this.handleCreateSummaryBtn()
            this.behaviourRecord(quesIdx)
        })
    }

    /** 操作允许生成摘要按钮 */
    handleCreateSummaryBtn() {
        const answerList = this.state.answerList
        let isAllowCreateSummary = false

        _.forEach(answerList, ansItem => {
            const examChecked = ansItem.examChecked
            if (!_.isEmpty(examChecked)) {
                isAllowCreateSummary = true
            }
        })

        this.setState({
            isAllowCreateSummary
        })
    }

    /** 生成行为接口的参数 */
    behaviourRecord(questionIdx) {
        let answerList = this.state.answerList
        let behaviourAnswer = answerList[questionIdx]

        let checked = [], userExample = []
        let examChecked = behaviourAnswer.examChecked
        let examples = behaviourAnswer.examples
        _.forEach(examples, (exampleItem, exampleIdx) => {
            if (examChecked.includes(exampleIdx)) {//已选择
                if (exampleItem.id === -1) {//用户补充的案例
                    userExample.push({
                        text: exampleItem.summary,
                        flag: true,
                    })
                } else {
                    checked.push(exampleItem.id)
                }
            } else {//未选择
                if (exampleItem.id === -1) {//用户补充的案例
                    userExample.push({
                        text: exampleItem.summary,
                        flag: false,
                    })
                }
            }
        })


        const params = {
            id: behaviourAnswer.id,
            checked: !_.isEmpty(checked) ? JSON.stringify(checked) : "[]",
            userExample: !_.isEmpty(userExample) ? JSON.stringify(userExample) : "[]",
        }

        webSdk.smartSearchApi.caseRecord(params).then(res => {
            // 成功与否都不需要提示用户
        })
    }

    /** 生成摘要 */
    createSummary() {
        this.props.history.push({
            pathname: "/smartsearch/summary"
        })
    }

    /** 返回搜索页 */
    backToSearchPage() {
        this.props.history.push({
            pathname: "/smartsearchhome"
        })
    }

    render() {
        const { addExampleInfo, answerList, currentActivePanel } = this.state

        return (
            <Spin spinning={this.state.isSpinning}>
                <div className="example-list">
                    <div className="example-left">
                        <div className="example-left-panel">
                            {
                                !this.state.isSpinning && _.isEmpty(answerList) ? (
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} ></Empty>
                                )
                                    : (
                                        <Collapse
                                            activeKey={currentActivePanel}
                                            expandIconPosition="right"
                                            bordered={false}
                                            expandIcon={({ isActive }) =>
                                                <Icon type="down" className="example-left-panel-icon" rotate={isActive ? 180 : 0} />
                                            }
                                            onChange={this.changePanels.bind(this)}
                                        >
                                            {
                                                answerList.map((quesItem, quesIdx) => {
                                                    let isIndeterminate = quesItem.examChecked.length > 0 && quesItem.examChecked.length < quesItem.examples.length
                                                    let isCheckedAll = quesItem.examChecked.length === quesItem.examples.length
                                                    return (
                                                        <Panel
                                                            key={quesIdx}
                                                            header={
                                                                <div className="example-left-panel-head">
                                                                    <Checkbox
                                                                        className="example-left-panel-chk"
                                                                        indeterminate={isIndeterminate}
                                                                        checked={isCheckedAll}
                                                                        onClick={this.handleCheckAll.bind(this, quesIdx)}
                                                                    ></Checkbox>
                                                                    <h5 className="example-left-panel-title">{`${quesIdx + 1}、${quesItem.question}`}</h5>
                                                                    {
                                                                        !addExampleInfo.isShow ? (
                                                                            <Button
                                                                                type="primary"
                                                                                className="example-btn panel-inner"
                                                                                onClick={this.handleAddPanel.bind(this, true, (quesIdx))}
                                                                            >补充案例</Button>
                                                                        ) : null
                                                                    }
                                                                </div>
                                                            }
                                                        >
                                                            <ul className="example-left-panel-list">
                                                                {
                                                                    quesItem.examples.map((examItem, examIdx) => {
                                                                        let isChecked = quesItem.examChecked.includes(examIdx)
                                                                        return (
                                                                            <li key={examIdx}>
                                                                                <Checkbox
                                                                                    className="example-left-panel-chk"
                                                                                    checked={isChecked}
                                                                                    onChange={this.handleCheckBox.bind(this, quesIdx, examIdx)}
                                                                                    onClick={this.stopPropCheckBox.bind(this)}
                                                                                ></Checkbox>
                                                                                <div className="example-left-panel-content">
                                                                                    {
                                                                                        examItem.title ? (<h5>{examItem.title}</h5>) : null
                                                                                    }
                                                                                    <p>
                                                                                        {
                                                                                            examItem.time ? (
                                                                                                <span className="example-left-panel-time">{examItem.time}</span>
                                                                                            ) : null
                                                                                        }
                                                                                        {examItem.summary}
                                                                                    </p>
                                                                                </div>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </Panel>
                                                    )
                                                })
                                            }
                                        </Collapse>
                                    )
                            }


                        </div>
                        <div className="example-btngroup">
                            <Button
                                className="example-btn back"
                                onClick={this.backToSearchPage.bind(this)}
                            >返回上一步</Button>
                            <Button
                                type="primary"
                                className="example-btn"
                                disabled={!this.state.isAllowCreateSummary}
                                onClick={this.createSummary.bind(this)}
                            >生成摘要</Button>
                        </div>
                    </div>
                    {
                        addExampleInfo.isShow ? (
                            <div className="example-right">
                                <h5>补充案例</h5>
                                <div className="examole-right-inputroot">
                                    <TextArea
                                        placeholder="请粘贴或手动输入语句、段落"
                                        autoSize={{ minRows: 10 }}
                                        value={addExampleInfo.value}
                                        onChange={this.changeExampleText.bind(this)}
                                    />
                                    <div className="example-right-btngroup">
                                        <Button
                                            className="example-btn back"
                                            onClick={this.handleAddPanel.bind(this, false, null)}
                                        >取消</Button>
                                        <Button
                                            disabled={!addExampleInfo.value}
                                            type="primary"
                                            className="example-btn"
                                            onClick={this.addExample.bind(this)}
                                        >添加</Button>
                                    </div>
                                </div>
                            </div>
                        ) : null
                    }
                </div>
            </Spin >

        );
    }
}

export default ExampleList;