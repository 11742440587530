import React, {useEffect, useState} from 'react';
import {Button, Spin, Table, message, Popconfirm} from 'antd';
import stores from "../../../store";
import {observer} from 'mobx-react'
import {webSdk} from "../../../api/WebSdk";
import {querySyllabusListApi, deleteSyllabusApi} from "./utils"


function SyllabusList(props) {
    const {syllabusStore} = stores;
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);

    let unmount = true;
    useEffect(() => {
        unmount = false;
        syllabusStore.setPageInfo([
            {
                label: "提纲模板",
                key: "/syllabus/list"
            }
        ], 'list');
        queryTable()
        return () => {
            unmount = true;
        }
    }, []);

    const columns = [
        {
            title: '序号',
            dataIndex: 'index',
            render: (text, record, index) => `${index + 1}`,
            align: 'center',
            width: 70
        },
        {
            title: '模板名称',
            dataIndex: 'name',
            key: 'name',
            render: text => text || '-'
        },
        {
            title: '问题数量',
            dataIndex: 'ques_num',
            key: 'ques_num',
            width: 90,
            render: text => text || '-'
        },
        {
            title: '创建日期',
            dataIndex: 'create_time',
            key: 'create_time',
            width: 180,
            render: text => text || '-'
        },
        {
            title: '最后修改日期',
            dataIndex: 'update_time',
            key: 'update_time',
            width: 180,
            render: text => text || '-'
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            width: 250,
            render: (text, record) => {
                return (
                    <div className="table-action-td">
                        <div className="table-action-cell" onClick={() => viewClick(record)}>查看</div>
                        <div className="table-action-cell" onClick={() => editClick(record)}>编辑</div>
                        <div className="table-action-cell">
                            <Popconfirm
                                title={`确认要删除"${record['name']}"？`}
                                onConfirm={() => deleteClick(record)}
                                placement="bottom"
                                okText="是"
                                cancelText="否"
                            >删除</Popconfirm>
                        </div>
                        <div className="table-action-cell" onClick={() => createClick(record)}>生成报告</div>
                    </div>
                )
            }
        }
    ];

    const viewClick = item => {
        props.history.push({
            pathname: `/syllabus/info/${item.id}`
        })
    };

    const editClick = item => {
        props.history.push({
            pathname: `/syllabus/edit/${item.id}`
        })
    };

    const deleteClick = item => {
        setLoading(true);
        deleteSyllabusApi(item).then(res => {
            setLoading(false);
            queryTable(page, pageSize);
        }).catch(e => {
            setLoading(false);
        });
    };

    const createClick = item => {
        props.history.push({
            pathname: `/syllabus/createReport/${item.id}`
        })
    };

    const pageChange = (page, pageSize) => {
        setPage(page);
        queryTable(page);
    };

    const pageSizeChange = (page, pageSize) => {
        setPage(1);
        setPageSize(pageSize);
        queryTable(1, pageSize);
    };

    const queryTable = (_page, _pageSize) => {
        setLoading(true);
        querySyllabusListApi(_page || page, _pageSize || pageSize).then(res => {
            setLoading(false);
            let list = [];
            if (res && res.data) list = res.data || [];
            let totalNum = res.total_num || 0;
            setTableData(list);
            setTotal(totalNum);
            // if (unmount) return;
        }).catch(e => {
            setLoading(false);
        });
    };

    return (
        <div className="syllabus-list">
            <Spin spinning={loading}>
                <div>
                    <Table
                        columns={columns}
                        dataSource={tableData}
                        rowClassName={(record, index) => index % 2 === 0 ? 'single-row' : 'double-row'}
                        rowKey={(record, index) => {
                            return index
                        }}
                        pagination={{
                            showSizeChanger: true,
                            showQuickJumper: true,
                            pageSizeOptions: ["10", "15", "20", "25"],
                            current: page,
                            pageSize: pageSize,
                            total: total,
                            onChange: (page, pageSize) => pageChange(page, pageSize),
                            onShowSizeChange: (current, size) => pageSizeChange(current, size),
                            showTotal: total => `共 ${total} 条`
                        }}
                    />
                </div>
            </Spin>
        </div>
    )
}

export default observer(SyllabusList)
