import React, {useEffect, useState} from 'react';
import stores from "../../../store";
import {observer} from 'mobx-react';
import {Spin, Button, message} from 'antd';
import ScrollBar from "../../common/ScrollBar";
import {queryReportInfoApi, updateReportApi} from "./utils";

function ReportEdit(props) {
    const {reportStore} = stores;

    const [id,setId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [questionList, setQuestionList] = useState([]);

    const [saveLoading, setSaveLoading] = useState(false);

    let unmounted = true;
    useEffect(() => {
        unmounted = false;
        let breadcrumb = [];
        let type = '';
        let id = props.match.params.id;
        if (id) {    // 编辑
            breadcrumb = [
                {
                    label: "报告管理",
                    key: "/report/list"
                },
                {
                    label: "查看报告",
                    key: `/report/info/${id}`
                },
                {
                    label: "编辑报告",
                    key: "/report/edit"
                }
            ];
            type = 'edit';
            setId(id);
            queryReportInfo(id);
            reportStore.setPageInfo(breadcrumb, type);
        } else {
            props.history.push({
                pathname: '/report/list'
            })
            /*breadcrumb = [
                {
                    label: "报告管理",
                    key: "/report/list"
                },
                {
                    label: "新增报告",
                    key: "/report/add"
                }
            ];
            type = 'add';*/
        }
        return () => {
            unmounted = true;
        }
    }, []);

    const queryReportInfo = id => {
        setLoading(true);
        queryReportInfoApi(id).then(res => {
            setLoading(false);
            let questionList = [];
            if (res.content) {
                try {
                    questionList = JSON.parse(res.content);
                } catch (e) {
                    console.log(e)
                }
            }
            res.questionList = questionList;
            setQuestionList(questionList)
        }).catch(e => {
            setLoading(false);
        })
    };

    const textareaChange = (ev,index) =>{
        let newList = [...questionList];
        newList[index].question = ev.target.value;
        setQuestionList(newList);
    };

    const cancelClick = () =>{
        let id = props.match.params.id;
        let pathname = '';
        if (id) {
            pathname = `/report/info/${id}`
        } else {
            pathname = '/report/list'
        }
        props.history.push({pathname})
    };

    const saveClick = () =>{
        let questions = [];
        for (let i=0;i<questionList.length;i++) {
            let item = questionList[i];
            if (item.question === '') {
                message.error(`请输入问题${i+1}`);
                return false
            }
            questions.push({
                id: item.id,
                question: item.question,
                tag_names: item.tag_names,
                tag_ids: item.tag_ids,
                answer: item.answer
            })
        }
        let postData = {
            id: id,
            questions
        };
        setSaveLoading(true);
        updateReportApi(postData).then(res =>{
            setSaveLoading(false);
            cancelClick();
        }).catch(e =>{
            setSaveLoading(false);
        })
    };

    return (
        <div className="report-edit">
            <Spin spinning={loading}>
                <ScrollBar className="report-edit-scroll">
                    <ul className="report-edit-list">
                        {
                            questionList.map((item, index) => {
                                return (
                                    <li className="report-edit-item" key={`edit_item_${index}`}>
                                        <h4 className="report-item-title">问题{index + 1}</h4>
                                        <div className="report-item-content">
                                            <textarea className="report-item-textarea" value={item.question} onChange={ev =>textareaChange(ev,index)} />
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </ScrollBar>
                <div className="report-edit-btn">
                    <Button className="cancel-btn" onClick={cancelClick}>取消</Button>
                    <Button className="save-btn" type="primary" loading={saveLoading} onClick={saveClick}>保存</Button>
                </div>
            </Spin>
        </div>
    )
}

export default observer(ReportEdit)
