var win = window;
var html = document.documentElement;
var body = document.body;

var isWinNode = function isWinNode(node) {
  return [body, html, document, win].indexOf(node) >= 0;
};

function $node(el) {
  return typeof el === 'string' ? document.querySelector(el) : el;
}

function css(el, name) {
  var node = $node(el);
  var styles = node instanceof Element ? getComputedStyle(node) : {};
  return name ? styles[name] : styles;
}

function _scrollNode(axisY, node) {
  var _key = axisY ? 'overflowY' : 'overflowX';

  node = $node(node);

  do {
    if (!node || isWinNode(node)) return null;
    var styles = css(node);
    var val = styles && (styles[_key] || styles['overflow']);
    if (val == 'auto' || val == 'scroll') return node;
  } while (node = node.parentNode);

  return null;
}

function outerHeight(el) {
  var addMargin = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  if (!el) return window.outerHeight;
  var node = $node(el);
  if (!node) return null;
  var margin = 0;

  if (addMargin) {
    var styles = window.getComputedStyle(node);
    margin = parseFloat(styles['marginTop']) + parseFloat(styles['marginBottom']);
  }

  var h = node.offsetHeight;
  return margin ? Math.ceil(h + margin) : h;
}

export default {
  $node: $node,
  css: css,
  outerHeight: outerHeight,
  scrollInfo: function scrollInfo(el) {
    var node = el ? $node(el) : html;
    if (!node) return {};
    var isWin = isWinNode(node);
    node = isWin ? html : node;
    return {
      /* 元素可滚动位置 */
      y: isWin && win.pageYOffset || node.scrollTop || isWin && body.scrollTop || 0,
      x: isWin && win.pageXOffset || node.scrollLeft || isWin && body.scrollLeft || 0,

      /* 元素可滚动高度和宽度，包括padding, 不包括margin */
      w: node.scrollWidth,
      h: node.scrollHeight,

      /* 元素显示高度和宽度，包括padding, 不包括margin */
      offsetH: node.offsetHeight,
      offsetW: node.offsetWidth
    };
  },
  findScrollXNode: _scrollNode.bind(null, 0),
  findScrollYNode: _scrollNode.bind(null, 1)
};