import React from 'react'
import BarAndGraph from "../../../../../../graphComponent/BarAndGraph";

class ProfitInfoGraph extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            cardList: props.cardList,
            currentList: props.currentList,
            options: props.options,
            parentClass: '.card'
        };
        this.changeCard = this.changeCard.bind(this)
    }
    componentWillMount() {

    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {
        //debugger
        this.setState({
            options: nextProps.options,
            cardList: nextProps.cardList
        })
    }

    /**
     * 列表变更事件
     */
    changeCard(item){
        this.setState({
            currentList: item.key
        })
        this.props.fnChangelist(item)
        //重新获取曲线数据

    }

    render() {
        return (
            <div className="profit-info-graph">
                <ul className="graph-list">
                    {
                        this.state.cardList.map((item,index) => {
                            return (
                                <li key={index} onClick={() => {this.changeCard(item)}} className={this.state.currentList===item.key?'current-list-card':''}>{item.label}</li>
                            )
                        })
                    }
                </ul>
                <div className="graph-main">
                    <BarAndGraph options={this.state.options}/>
                </div>
            </div>
        )
    }
}

export default ProfitInfoGraph