import React, { PureComponent } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom'
import "./index.scss"
import AppContext from '../../../../AppContext'
import { Spin, Empty, } from 'antd';
import stores from "../../../../store";
import _ from 'lodash'
import { observer } from 'mobx-react';
import Breadcrumb from "../../../common/Breadcrumb/Breadcrumb";
import RegulationFallDetailArticle from './Article'
import RegulationFallDetailIng from './Ingredients';
import { webSdk } from "../../../../api/WebSdk";

class RegulationFallDetailIdx extends PureComponent {
    static contextType = AppContext

    constructor(props) {
        super(props);
        this.state = {
            regulationFallParamsStorKey: "regulationFallPageParams",
            articleId: null,
            articleTitle: "",
            ingredLoading: false,
            content: "",
        }
    }

    componentDidMount() {
        this.init();
    }

    /** 在localStorage中保存值
    * @param {string} key 保存的键名
    * @param {object} obj 保存的对象
    */
    setLocStorage(key, obj) {
        let objJSON = ""
        if (!_.isEmpty(obj))
            objJSON = JSON.stringify(obj)
        window.localStorage.setItem(key, objJSON)
    }

    /** 从localStorage中获取值
     * @param {string} key 键名
     */
    getLocStorage(key) {
        let objStr = window.localStorage.getItem(key)
        if (!objStr) return null;
        return JSON.parse(objStr)
    }

    /**修改localStorage中的pageType或currentYear参数 */
    changeStorByObj({ pageType, currentYear }) {
        let enterinfoParamsStorKey = this.state.enterinfoParamsStorKey
        let enterinfoParams = this.getLocStorage(enterinfoParamsStorKey)
        enterinfoParams.pageType = pageType || enterinfoParams.pageType
        enterinfoParams.currentYear = currentYear || enterinfoParams.currentYear
        this.setLocStorage(enterinfoParamsStorKey, enterinfoParams)
    }

    init() {
        console.log('进来了')
        let regulationFallParamsStorKey = this.state.regulationFallParamsStorKey
        let pageParams = this.getLocStorage(regulationFallParamsStorKey)

        if (_.isEmpty(pageParams)) {
            this.returnBackListPage()
            return;
        }
        let regulationFallTitle = pageParams.regulationFallTitle;

        stores.regulationFallStore.setPageInfo([
            {
                label: "政策法规",
                key: "/regulationFall"
            },
            {
                label: regulationFallTitle,
                key: ""
            },
        ], 'list');


        this.setState({
            articleId: pageParams.regulationFallId,
            articleTitle: regulationFallTitle,
        })

    }

    /**返回到政策法规列表页面 */
    returnBackListPage() {
        this.context.getHistory().push({
            pathname: `/regulationFall`
        })
    }

    // 获取知识点数据
    getData() {
        let { articleId } = this.state
        console.log("获取数据", articleId);
        this.setState({
            ingredLoading: true
        }, () => {
            webSdk.policyApi.getArticleKnowledge(articleId).then(res => {

                this.setState({
                    ingredLoading: false,
                })

            }).catch(e => {
                this.setState({
                    ingredLoading: false
                })
            });
        })
    }

    render() {
        let { articleId, ingredLoading } = this.state
        return (
            <div className="regulation-fall-detail">
                <Breadcrumb history={this.context.getHistory()} crumbList={stores.regulationFallStore.crumbList.slice()} />
                <div className="regulation-fall-detail-content">
                    <div className="detail-article">
                        <RegulationFallDetailArticle articleId={articleId} />
                    </div>
                    <div className="detail-ingredient">
                        <RegulationFallDetailIng ingredLoading={ingredLoading} articleId={articleId} />
                    </div>

                </div>
            </div>
        );
    }
}

export default RegulationFallDetailIdx;