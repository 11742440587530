import * as d3 from "d3js"

class TreeMap {
    constructor(id, { data, width = 800, height = 400 }) {
        this.init(id, data, width, height)
    }

    init(id, data, width, height) {
        const root = this.tree(data, width);
        let x0 = Infinity;
        let x1 = -x0;
        root.each(d => {
            if (d.x > x1) x1 = d.x;
            if (d.x < x0) x0 = d.x;
        });

        const svg = d3.select(`#${id}`).append("svg")
            .attr("viewBox", [0, 0, width, x1 - x0 + root.dx * 2]);

        const g = svg.append("g")
            .attr("font-family", "sans-serif")
            .attr("font-size", 12)
            .attr("transform", `translate(${root.dy + root.dy / 2}, ${root.dx - x0})`);

        const link = g.append("g")
            .attr("fill", "none")
            .attr("stroke", "#555")
            .attr("stroke-opacity", 0.4)
            .attr("stroke-width", 1.5)
            .selectAll("path")
            .data(root.links())
            .join("path")
            .attr("d", d3.linkHorizontal()
                .x(d => {
                    //上游数据转为左侧
                    let direction = d.data.direction
                    let y = d.y
                    if (direction && direction == "upstream") {
                        y = -d.y
                    }

                    y = y * 3 / 4
                    return y / 2
                })
                .y(d => {
                    let x = d.x
                    return x
                })
            )
            .attr("stroke", d => {
                if (d.target.data.point) {
                    return "red"
                } else {
                    return "#555"
                }
            });

        const node = g.append("g")
            .attr("stroke-linejoin", "round")
            .attr("stroke-width", 3)
            .selectAll("g")
            .data(root.descendants())
            .join("g")
            .attr("transform", d => {
                //上游数据的文字和圈，转为左侧
                let direction = d.data.direction
                let y = d.y
                if (direction && direction == "upstream") {
                    y = -d.y
                }

                y = y * 3 / 4
                return `translate(${y / 2}, ${d.x})`
            });

        node.append("circle")
            .attr("fill", d => {
                if (d.data.point) {
                    return "red"
                } else {
                    return d.children ? "#555" : "#999"
                }
            })
            .attr("r", 2.5);

        node.append("text")
            .attr("dy", "0.3em")
            .attr("x", d => {
                //根据 上游、下游数据，修改文字与圆圈间隔
                let direction = d.data.direction
                let space = 0
                if (d.children) {
                    if (direction && direction == "upstream" || d.depth == 0) {//上游
                        space = 6
                    } else if (direction && direction == "downstream") {//下游
                        space = -6
                    }
                } else {
                    if (direction && direction == "upstream") {//上游
                        space = -6
                    } else if (direction && direction == "downstream") {//下游
                        space = 6
                    }
                }
                return space
            })
            .attr("text-anchor", d => {
                //根据 上游、下游数据，修改文字方位
                let direction = d.data.direction
                let textDir = ""
                if (d.children) {
                    if (direction && direction == "upstream") {//上游
                        textDir = "start"
                    } else if (direction && direction == "downstream") {//下游
                        textDir = "end"
                    }
                } else {
                    if (direction && direction == "upstream") {//上游
                        textDir = "end"
                    } else if (direction && direction == "downstream") {//下游
                        textDir = "start"
                    }
                }
                return textDir
            })
            .attr("fill", d => {
                if (d.data.point) {
                    return "red"
                } else {
                    return "black"
                }
            })
            .text(d => d.data.name)
            .clone(true).lower()
            .attr("stroke", "white");

        // return svg.node();

    }

    tree(data, width) {
        const root = d3.hierarchy(data);
        root.dx = 20;
        root.dy = width / (root.height + 1);
        return d3.tree().nodeSize([root.dx, root.dy])(root);
    }

}


export default TreeMap