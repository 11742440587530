import {action, observable} from 'mobx'

export default class SyllabusStore {
    @observable crumbList = [
        {
            label: "提纲模板",
            key: "/syllabus/list"
        }
    ];
    @observable pageType = 'list';

    @action setPageInfo = (list, type) => {
        this.crumbList = list;
        this.pageType = type;
    }
}
