import _ from 'lodash'
//import { arrOffset, mergeMockApi } from './mockWebApi'
import { mergeMockApi } from './mockWebApi'

import ResearchLibraryApi from './ResearchLibraryApi'

export class ResearchLibraryMock extends ResearchLibraryApi {
    constructor(opts) {
        super(opts)
        this.mockData = async (url, delay) => {
            delay = (delay || 10) + _.random(0, 1) // 模拟服务端延迟
            const resp = await this.gate.fake.get(url);
            return await new Promise(resolve => {
                setTimeout(() => {
                    resolve(resp);
                }, delay);
            });
        }
    }

    //根据id获取研报信息
    async getPDFDataById(opts) {
        return ""

    }
}

export default mergeMockApi(ResearchLibraryApi, ResearchLibraryMock)