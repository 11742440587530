import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Button, Icon } from 'antd';
import './ResearchLibraryIndex.scss'
import ResearchLibraryBody from './ResearchLibraryBody'
import PublicReadList from '../PublicReadList'

export class ResearchLibraryIndex extends Component {
    constructor(props) {
        super(props)

        this.state = {
            currArticleId: props.match.params.id || '',//当前文章的id
            currArticleType: props.match.params.type || '',//当前文章的类型
            currArticleLightId: props.match.params.lightId || '',//高亮id
            title: '',
            addLoading: false,
        }
    }

    componentDidMount() {
        this.props.fnSetType('research')
    }

    componentWillUnmount() {
        let homePageContent = window.document.body.querySelector("div.home-page-content")
        homePageContent.className = "home-page-content"
    }

    //修改标题
    changeTitle(title) {
        this.setState({
            title: title
        })
    }

    //打开阅读列表
    openReadList() {
        const _this = this;
        _this.setState({
            addLoading: true
        }, () => {
            _this.refs.publicReadList.openReadList(true, this.state.currArticleId, this.state.currArticleType);
        })
    }
    /**关闭按钮加载状态 */
    fnUnloadingAdd() {
        this.setState({
            addLoading: false
        })
    }

    /**跳转到对应页面中*/
    fnToArticlePage(item) {
        this.props.history.push({
            pathname: `/researchResult/${item.type}/${item.obj_id}/${item.type}`
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.id !== this.state.currArticleId) {
            this.setState({
                currArticleId: nextProps.match.params.id || '',//当前文章的id
                currArticleType: nextProps.match.params.type || '',//当前文章的类型
            })
        }
    }

    render() {
        let selectType = Number(this.props.match.params.selectType || 1);
        // let keyword = this.props.match.params.keyword
        return (
            <div className="research"
                style={
                    {
                        width: `${(document.body.clientWidth - 10) < 1220 ? 1220 : (document.body.clientWidth - 10)}px`,
                        minWidth: '1220px'
                    }
                }
            >
                <div className="countent">
                    <div className="title">
                        <ul className="nav">
                            {
                                selectType === 1 || selectType === 2 ?
                                    (
                                        <>
                                            <li>
                                                <Link to="/research">研究报库</Link>/
                                            </li>
                                            <li>
                                                <Link to={`/research//${selectType}`}>
                                                    {selectType && selectType === 2 ? "行业分析" : "公司研究"}
                                                </Link>/
                                            </li>
                                        </>
                                    ) : null
                            }
                            {
                                selectType === 6 ?
                                    (
                                        <>
                                            <li>
                                                <span className="txt">个人中心</span>/
                                            </li>
                                            <li>
                                                <Link to={`/fileManage`}>
                                                    文档管理
                                                </Link>/
                                            </li>
                                        </>
                                    ) : null
                            }
                            {
                                selectType === 100 ?
                                    (
                                        <>
                                            <li>
                                                <span className="txt">知识点</span>/
                                            </li>
                                            <li>
                                                <Link to={`/knowledge`}>
                                                    知识点列表
                                                </Link>/
                                            </li>
                                        </>
                                    ) : null
                            }
                            {
                                selectType === 101 ?
                                    (
                                        <>
                                            <li>
                                                <Link to={`/resultList/${this.props.match.params.keyWord}`}>
                                                    首页
                                                </Link>/
                                            </li>
                                        </>
                                    ) : null
                            }
                            {
                                selectType === 102 ?
                                    (
                                        <>
                                            <li>
                                                <Link to={`/home`}>
                                                    首页
                                                </Link>/
                                            </li>
                                        </>
                                    ) : null
                            }

                            {this.state.title ?
                                (
                                    <li><span className="txt">{this.state.title}</span></li>
                                ) : null
                            }

                        </ul>
                        <div className="title-btn">
                            <Button className="btn"
                                type="primary"
                                loading={this.state.addLoading}
                                onClick={this.openReadList.bind(this)}
                                style={{ height: 30, padding: "0 15px", fontSize: 12 }}
                            >
                                加入阅读列表
                                <Icon type="plus" />
                            </Button>
                            <PublicReadList
                                ref="publicReadList"
                                fnToArticlePage={this.fnToArticlePage.bind(this)}
                                fnUnloadingAdd={this.fnUnloadingAdd.bind(this)}
                            />
                        </div>
                    </div>
                    <div className="body">
                        <ResearchLibraryBody
                            articleId={this.state.currArticleId}
                            articleType={this.state.currArticleType}
                            changeTitle={this.changeTitle.bind(this)}
                            currArticleLightId={this.state.currArticleLightId}
                            lightId={this.props.match.params.lightId}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default ResearchLibraryIndex
