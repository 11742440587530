import BaseWebApi from './BaseWebApi'

class SmartSearchApi extends BaseWebApi {
    constructor(options) {
        super()
        this._apiV2 = this.gate.searchV2
    }

    /**问答接口
     * 
     * @param {string} questions 问题列表 ["问题1","问题2","问题3"]
     * @param {string} questionIds 问题id列表 [12,33,3]
     */
    async caseQuery({ questions, questionIds }) {
        let params = {}

        if (questionIds) {
            params = {
                qid: questionIds
            }
        } else if (questions) {
            params = {
                query: questions
            }
        }

        console.log(params);

        let resp = await this._apiV2.postForm('case/query', params)
        return resp.data
    }

    /**行为记录
     * 
     * @param {object} param0 
     * @param {int} id  问题id
     * @param {string} userExample  用户补充案例 [{"text":"用户添加的案例","flag":true}] flag为true表示用户勾选
     * @param {string} checked  搜索结果中用户选择的案例id [12,44,234]
     */
    async caseRecord({ id, userExample, checked }) {

        let params = {
            qid: id,
            add_case: userExample,
            sents: checked
        }

        let resp = await this._apiV2.postForm('case/record', params)
        return resp.data
    }
}

export default SmartSearchApi