import React, { PureComponent, Fragment } from 'react';
import _ from 'lodash'
import AppContext from '../../../AppContext'
import { Descriptions, Button, Collapse } from 'antd'
import './DisplayPlan.scss'

const { Panel } = Collapse

class DisplayPlan extends PureComponent {
    static contextType = AppContext

    constructor(props) {
        super(props);
        this.state = {
            content: ""
        }
    }

    componentDidMount() {
    }

    /** 去教案编辑页 */
    toEditPage() {
        this.context.getHistory().push({
            pathname: `/lessonplan/edit`
        })
    }

    /** 去案例页面 */
    toExamplesPage() {
        this.context.getHistory().push({
            pathname: `/lessonplan/examples`
        })
    }

    render() {
        return (
            <div className="plan-display">
                <div className="detail-fun-btngroup">
                    <Button
                        type="primary"
                        icon="form"
                        className="detail-fun-btn"
                        onClick={this.toEditPage.bind(this)}
                    >编辑</Button>
                    <Button
                        type="primary"
                        icon="export"
                        className="detail-fun-btn"
                    // onClick={this.toDisplayPage.bind(this)}
                    >导出</Button>
                    <Button
                        className="detail-fun-btn example"
                        onClick={this.toExamplesPage.bind(this)}
                    >查看案例</Button>
                </div>
                <div className="plan-display-tree">
                    <Collapse bordered={false} defaultActiveKey={["1", "2", "3"]}>
                        <Panel header="1. 课程介绍 Course Description" key="1">
                            <Collapse bordered={false} defaultActiveKey={["1.1", "1.2", "1.3", "1.4", "1.5"]}>
                                <Panel header="1.1 课程内容 Context" key="1.1">
                                    课程内容 Context
                                </Panel>
                                <Panel header="1.2 课程简介 Course overview" key="1.2">
                                    课程简介 Course overview
                                    </Panel>
                                <Panel header="1.3 先修要求 Prerequisites" key="1.3">
                                    先修要求 Prerequisites
                                    </Panel>
                                <Panel header="1.4 参考教材 Textbook" key="1.4">
                                    参考教材 Textbook
                                    </Panel>
                                <Panel header="1.5 其他阅读材料 Other Reading Materials" key="1.5">
                                    其他阅读材料 Other Reading Materials
                                    </Panel>
                            </Collapse>
                        </Panel>
                        <Panel header="2. 学习目标 Learning Outcomes" key="2">
                            <Collapse bordered={false} defaultActiveKey={["2.1", "2.2", "2.3", "2.4"]}>
                                <Panel header="2.1 计划学习成果 Intended Learning Outcomes" key="2.1">
                                    计划学习成果 Intended Learning Outcomes
                                    </Panel>
                                <Panel header="2.2 课程目标 Course specific objectives" key="2.2">
                                    课程目标 Course specific objectives
                                    </Panel>
                                <Panel header="2.3 评估/评分标准 Assessment/Grading Details" key="2.3">
                                    评估/评分标准 Assessment/Grading Details
                                    </Panel>
                                <Panel header="2.4 学术诚信与剽窃 Academic Honesty and Plagiarism" key="2.4">
                                    学术诚信与剽窃 Academic Honesty and Plagiarism
                                    </Panel>
                            </Collapse>
                        </Panel>
                        <Panel header="3. 主题、教学与测评时间 Topics, Teaching and AssessmentSchedule" key="3">
                            主题、教学与测评时间 Topics, Teaching and AssessmentSchedule
                            </Panel>
                    </Collapse>
                </div>
            </div >
        );
    }
}

export default DisplayPlan;