/*
 * @Description:
 * @LastEditTime: 2022-10-12 15:22:21
 * @LastEditors: liqun 13212226759@163.com
 */
import BaseWebApi from './BaseWebApi'

const ObjToQuery = (obj) => {
  let result = '?'
  for (let [key, value] of Object.entries(obj)) {
    result += key + '=' + value + '&'
  }
  result = result.slice(0, result.length - 1)
  return result
}

class FinanceSubLibraryApi extends BaseWebApi {
  constructor() {
    super()
    this._api = this.gate.financeSub
  }

  // 财务子库首页获取公司列表
  async getCompList(current, limit) {
    let resp = await this._api.postForm('listed_company/list_company_info', {
      current,
      limit
    })
    return resp.data
  }

  // 获取指标项
  async getIndicator({ corp_id, year, belong_to, report_type }) {
    let resp = await this._api.post(
      `finance/getFinanceData${ObjToQuery({
        corp_id,
        year,
        belong_to,
        report_type
      })}`
    )
    return resp.data
  }

  // 获取指标项表
  async getIndicatorTerm() {
    let resp = await this._api.get('finance/getIndicatorTerm')
    return resp.data
  }

  // 根据id获取公司信息
  async getInfoById(id) {
    let resp = await this._api.post(`listed_company/get_by_id?id=${id}`)
    return resp.data
  }

  // 根据关键字搜索相关公司
  async getInfoByName(name, { current = 1, limit = 10 } = {}) {
    const params = {
      name_or_stock_symbol: name,
      current,
      limit
    }
    let resp = await this._api.postForm(
      `listed_company/getInfoByNameOrStockSymbol`,
      params
    )
    resp.data.data.businessRegistrationInfo.forEach((item) => {
      item.name = item.org_name
      item.stock_symbol = item.security_code
    })
    return resp.data
  }
}

export default FinanceSubLibraryApi
