import React, { Component } from 'react'
import _ from "lodash"
import { Progress } from 'antd';
import "./ResultCard.scss"

class ResultCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            keyword: this.props.keyword,
            cardTitle: props.cardTitle || {//卡片头部信息
                /*progressPercent: 62,//可信度
                titleSubject: "万达",//主体
                titlePredicate: "董事会",//属性
                titleObject: "王思聪",//值*/
            },
            dataListLen: 0,//列表数量
            dataListServer: props.dataList || [//原始列表数据
                /*{
                    title: "王思聪的5亿练手费快亏完了，真要回家继承亿万资产？_新浪财经_新浪网",
                    time: "2019年8月8日",
                    source: "www.jiemian.com"
                }*/
            ],
            dataList: [],//裁剪后的数据，用于显示
        }
    }

    componentDidMount() {
        this.dealData()
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            keyword: nextProps.keyword
        }, () => {
            this.dealData()
        })

    }

    /**处理数据 */
    dealData() {
        const _this = this
        let dataListServer = _this.state.dataListServer
        let dataListLen = dataListServer.length

        let dataList = _.cloneDeep(dataListServer)
        if (dataListLen > 6) {
            dataList = _.slice(dataList, 0, 6)
        }

        _this.setState({
            dataListLen, dataList
        })
    }

    /**关闭卡片 */
    closeCard() {
        if (this.props.fnCloseCard !== undefined)
            this.props.fnCloseCard()

    }

    /**进一步搜索 */
    deepSearch(event) {
        let searchVal = event.currentTarget.innerText
        if (this.props.fnCloseCard !== undefined)
            this.props.fnDeepSearch(searchVal)
    }

    render() {
        let progressPercent = this.state.cardTitle.progressPercent
        let color = progressPercent >= 90 ? "#CCEBA4" : progressPercent >= 50 ? "#FFDEA6" : "#FFB3A6";
        return (
            <div className="result-card">
                <div className="result-card-header"
                    onClick={this.closeCard.bind(this)}
                >
                    <div className="result-card-header_left">
                        <ul>
                            <li>
                                <span className="card-header-title">SUBJECT</span>
                                <span title={this.state.cardTitle.titleSubject}>{this.state.cardTitle.titleSubject}</span>
                            </li>
                            <li>
                                <span className="card-header-title">PREDICATE</span>
                                <span title={this.state.cardTitle.titlePredicate}>{this.state.cardTitle.titlePredicate}</span>
                            </li>
                            {
                                this.state.cardTitle.titleTime ?
                                    (
                                        <li>
                                            <span className="card-header-title">TIME</span>
                                            <span title={this.state.cardTitle.titleTime}>{this.state.cardTitle.titleTime}</span>
                                        </li>
                                    ) : null
                            }

                            <li>
                                <span className="card-header-title">OBJECT</span>
                                <span title={this.state.cardTitle.titleObject}>{this.state.cardTitle.titleObject}</span>
                            </li>
                        </ul>
                    </div>

                    <div className="result-card-header_right">
                        <Progress type="circle"
                            className="card-header-progress"
                            percent={progressPercent}
                            width={34}
                            format={percent => `${percent}`}
                            // strokeColor={color}
                            strokeColor='#6BA61E'
                        />
                    </div>

                </div>
                <div className="result-card-content">
                    <p className="card-content-mark">学习自以下等 {this.state.dataListLen} 组上下文 ∙ 可信评分为 {progressPercent}</p>
                    <ul>
                        {
                            this.state.dataList.map((item, idx) => {
                                return (
                                    <li key={idx}>
                                        <a
                                            href={item.source.type === "pdf" ? `/resultSource/${item.source.pdfId}/${this.state.keyword}/${item.source.lightId ? item.source.lightId : ""}` : item.source.webSite}
                                            target="_blank"
                                        >
                                            <h5 className="content-item-title" title={item.title}>{item.title}</h5>
                                            <div className="content-item-info">
                                                <cite>{item.source.type === "pdf" ? "" : item.source.webSite}</cite>
                                                <time>{item.time}</time>
                                            </div>
                                        </a>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
                <div className="result-card-footer">
                    <ul>
                        <li>进一步搜索</li>
                        <li>
                            <button
                                className="card-footer-btn"
                                onClick={this.deepSearch.bind(this)}
                                title={`${this.state.cardTitle.titleSubject} ${this.state.cardTitle.titlePredicate}`}
                            >
                                {this.state.cardTitle.titleSubject} {this.state.cardTitle.titlePredicate}
                            </button>
                        </li>
                        <li>
                            <button
                                className="card-footer-btn"
                                onClick={this.deepSearch.bind(this)}
                                title={this.state.cardTitle.titleObject}
                            >
                                {this.state.cardTitle.titleObject}
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default ResultCard;