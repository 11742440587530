import React, {useEffect} from 'react';
import { Route, Switch, Redirect } from 'react-router-dom'
import "./index.scss"
import {Button} from 'antd';
import stores from "../../../store";
import {observer} from 'mobx-react';
import ScrollBar from "../../common/ScrollBar";
import Breadcrumb from "../../common/Breadcrumb/Breadcrumb";
import ReportList from "./List";
import ReportEdit from "./Edit";
import ReportInfo from "./Info";

function ReportIndex(props) {
    const url = props.match.url;

    const {crumbList, pageType} = stores.reportStore;

    const addAction = () =>{
        props.history.push({
            pathname: '/syllabus/createReport'
        })
    };

    return (
        <div className="report-page">
            <div className="report-header">
                <Breadcrumb history={props.history} crumbList={stores.reportStore.crumbList.slice()}/>
                {
                    pageType==='list' ? <Button type="primary" style={{height: 30,padding: '0 30px'}} onClick={addAction}>新建</Button> : null
                }
            </div>
            <ScrollBar className="report-main">
                <Switch>
                    <Route path={`${url}/list`} render={props => <ReportList {...props}/>}/>
                    <Route path={`${url}/edit/:id`} render={props => <ReportEdit {...props}/>}/>
                    <Route path={`${url}/info/:id`} render={props => <ReportInfo {...props}/>}/>
                    <Redirect from={url} to={`${url}/list`} />
                </Switch>
            </ScrollBar>
        </div>
    )
}

export default observer(ReportIndex)
