import React from 'react'
import {webSdk} from "../../../../api/WebSdk";
import {Icon, Spin, Empty, Anchor, Affix, message} from 'antd'
import _ from 'lodash'
import InfoItemList from "./InfoItemList";
import './CompanyBaseInfo.scss'
import InfoItemTable from "./InfoItemTable";

const {Link} = Anchor;

class CompanyBaseInfo extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            id: props.match.params.id || '',
            menu: [
                {
                    label: '企业工商信息',
                    key: 'businessInfo',
                    type: 'list',
                    data: []
                },
                {
                    label: '企业股东',
                    key: 'shareholder',
                    type: 'table',
                    data: []
                },
                {
                    label: '企业管理层',
                    key: 'management',
                    type: 'list',
                    data: []
                }
            ],
            loading: false,
        }
    }

    componentDidMount() {
        this.props.fnSetType('companyList', this.state.keyword || '');
        this.getBaseInfo()
    }

    getBaseInfo = () => {
        this.setState({
            loading: true
        }, () => {
            let postData = {
                id: this.state.id
            }
            webSdk.search.getCompanyInfo(postData).then(res => {
                this.setState({
                    loading: false
                })
                if (!res.errCode || res.errCode === 0) {
                    let tableData = res.shareholders || [];
                    let businessData = [
                        {
                            label: '公司名称',
                            value: res.companyName || ''
                        },
                        {
                            label: '所属行业',
                            value: ''
                        },
                        {
                            label: '成立时间',
                            value: res.foundedTime || ''
                        },
                        {
                            label: '公司网址',
                            value: res.homeSite || ''
                        },
                        {
                            label: '注册资本',
                            value: res.registeredCap?res.registeredCap+'万元':''
                        },
                        {
                            label: '经营时间',
                            value: res.operatingTime || ''
                        },
                        {
                            label: '控股股东',
                            value: res.shareholder || ''
                        },
                        {
                            label: '实际控制人',
                            value: res.AcController || ''
                        },
                        {
                            label: '最终受益人',
                            value: res.finalBen || ''
                        },
                        {
                            label: '董事长',
                            value: res.chairman || ''
                        },
                        {
                            label: '董事长秘书',
                            value: res.secretary || ''
                        },
                        {
                            label: '法人代表',
                            value: res.legalRep || ''
                        },
                        {
                            label: '公司电话',
                            value: res.companyTel || ''
                        },
                        {
                            label: '公司传真',
                            value: res.companyFax || ''
                        },
                        {
                            label: '邮编',
                            value: res.zipCode || ''
                        },
                        {
                            label: '统一社会信用代码',
                            value: res.USCC || ''
                        },
                        {
                            label: '纳税人识别号',
                            value: res.taxpayerId || ''
                        },
                        {
                            label: '公司邮箱',
                            value: res.companyEmail || ''
                        },
                        {
                            label: '办公地址',
                            value: res.officeAddr || ''
                        },
                        {
                            label: '公司股份',
                            value: res.stockType || ''
                        },
                        {
                            label: '证券简称',
                            value: res.stockName || ''
                        },
                        {
                            label: '上市时间',
                            value: res.marketTime || ''
                        },
                        {
                            label: '股票代码',
                            value: res.stockcode || ''
                        }
                    ];
                    let manageData = [
                        {
                            label: '董事长',
                            value: res.chairman || ''
                        },
                        {
                            label: '总经理',
                            value: ''
                        },
                        {
                            label: '财务总监',
                            value: ''
                        },
                        {
                            label: '董事长秘书',
                            value: res.secretary || ''
                        },
                        {
                            label: '董事会成员',
                            value: ''
                        }
                    ];

                    for (let i = 0; i < manageData.length; i++) {
                        if (!manageData[i].value) {
                            manageData.splice(i, 1);
                            i--
                        }
                    }
                    console.log(manageData)


                    let menu = [...this.state.menu];
                    menu[0].data = businessData;
                    menu[1].data = tableData;
                    menu[2].data = manageData;
                    this.setState({
                        menu
                    })
                } else {
                    message.error(res.errMsg || '获取公司信息失败，请稍后重试');
                }
            }).catch(e => {
                this.setState({
                    loading: false
                })
                message.error('获取公司信息失败，请稍后重试');
            })
        })
    }

    /** 左侧菜单点击 */
    changeMenu = e => {
        e.preventDefault();
    }

    render() {

        return (
            <div className="company-info">

                <ul className="left-menu">

                    <Affix offsetTop={100}>
                        <Anchor affix={false} onClick={this.changeMenu} offsetTop={130}>
                            {
                                this.state.menu.map((item, index) => {
                                    return (
                                        <Link key={item.key} href={'#menu_' + item.key} title={item.label}
                                              className="left-menu-item"/>
                                    )
                                })
                            }
                        </Anchor>
                    </Affix>
                </ul>
                <div className="right-info">
                    {
                        this.state.menu.map((item, index) => {
                            return (
                                <div className="info-item" key={item.key} id={'menu_' + item.key}>
                                    <h3 className="info-item-title">{item.label}</h3>
                                    <Spin spinning={this.state.loading}>
                                        <div className="info-item-main">
                                            {
                                                item.type === 'list' && !_.isEmpty(item.data) ? (
                                                    <InfoItemList list={item.data}/>
                                                ) : item.type === 'table' && !_.isEmpty(item.data) ? (
                                                    <InfoItemTable list={item.data}/>
                                                ) : (<Empty/>)
                                            }
                                        </div>
                                    </Spin>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}

export default CompanyBaseInfo