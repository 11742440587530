import stores from '../../../store';
import {webSdk} from "../../../api/WebSdk";
import _ from 'lodash';
import {message} from 'antd';
// import mockSheetData from "./sheetData"

// {homeLeftStore, homeCenterStore, homeRightStore, homeExcelStore, homeCommonStore}
/**
 * 点击缩放按钮事件
 * @param type：当前点击缩放按钮模块 'left'    'center'    'right'     'excel'
 */
function setOpen(type) {
    let {homeLeftStore, homeCenterStore, homeRightStore, homeExcelStore, homeCommonStore} = stores;
    let {leftOpen} = homeLeftStore,
        {centerOpen} = homeCenterStore,
        {rightOpen} = homeRightStore,
        {excelOpen} = homeExcelStore,
        {questionType} = homeCommonStore;
    if (questionType === 0) {   // 三栏模式
        let num = 0;
        if (leftOpen) num++;
        if (rightOpen) num++;
        if (centerOpen) num++;
        if (type === 'left') {
            if (!(num === 1 && leftOpen)) {
                homeLeftStore.setLeftOpen(!leftOpen);
            }
        } else if (type === 'center') {
            if (!(num === 1 && centerOpen)) {
                homeCenterStore.setCenterOpen(!centerOpen);
            }
        } else if (type === 'right') {
            if (!(num === 1 && rightOpen)) {
                homeRightStore.setRightOpen(!rightOpen);
            }
        }
    } else if (questionType === 1) {    // 表格模式
        if (type === "left") {
            if (excelOpen) {
                homeLeftStore.setLeftOpen(!leftOpen);
            }
        } else if (type === "excel") {
            if (leftOpen) {
                homeExcelStore.setExcelOpen(!excelOpen);
            }
        }
    }
}

/**
 * 获取问句分类
 * @param text
 */
function getQuestionType(text) {
    const {homeLeftStore, homeCenterStore, homeRightStore, homeExcelStore, homeCommonStore} = stores;
    homeCommonStore.setQuesClsFalse(true);
    homeCenterStore.setCorpusMatchLoading(true);
    homeRightStore.setQuestionAnaLoading(true);
    homeExcelStore.setExcelLoading(true);
    let postData = {
        content: [text]
    };
    return new Promise((resolve, reject) => {
        webSdk.search.quesCls(postData).then(res => {
            if (!res.errCode || res.errCode === 0) {
                homeCommonStore.setQuesClsFalse(false);
                let type = res.data[0];
                homeCommonStore.setQuesCls(type);
                if (type === 'spo') {   // 问句解析模式
                    homeExcelStore.setExcelLoading(false);
                } else if (type === 'slot' || type === 'st') {      // excel模式。slot知识图谱搜索,st词频搜索
                    homeCenterStore.setCorpusMatchLoading(false);
                    homeRightStore.setQuestionAnaLoading(false);
                }
                resolve(type);
            } else {
                homeCenterStore.setCorpusMatchLoading(false);
                homeRightStore.setQuestionAnaLoading(false);
                homeExcelStore.setExcelLoading(false);
                message.error(res.errMsg || '获取问句分类失败，请重试');
                reject();
            }
        }).catch(e => {
            homeCenterStore.setCorpusMatchLoading(false);
            homeRightStore.setQuestionAnaLoading(false);
            homeExcelStore.setExcelLoading(false);
            console.log(e);
            message.error('获取问句分类失败，请重试');
            reject();
        });
    })

}

/**
 * 获取右侧问题解析/语料解析结果
 * @param type  query:问句解析  sent:语料解析
 * @param text  问句解析传问句text，语料解析传语料id
 */
function questionAndCorpusAna(type, text) {
    const {homeCenterStore, homeRightStore, homeCommonStore} = stores;
    homeRightStore.setQuestionAnaLoading(true);
    homeRightStore.setCorpusAnaLoading(true);
    if (type === "query") {
        homeCenterStore.setCorpusMatchLoading(true);
        homeRightStore.setQuestionAnaFalse(true);
    } else {
        homeRightStore.setCorpusAnaFalse(true);
    }
    let postData = {
        type,
        text: [text]
    };
    webSdk.search.spoParser(postData).then(res => {
        homeRightStore.setQuestionAnaLoading(false);
        homeRightStore.setCorpusAnaLoading(false);
        // homeCenterStore.setCorpusMatchLoading(false);

        if (!res.errCode || res.errCode === 0) {
            if (type === "query") {
                homeRightStore.setQuestionAnaFalse(false);
                let questionAnaList = res[0] && res[0]["text_parse"] || [];
                homeRightStore.setQuestionAnaList(questionAnaList);
                homeCommonStore.setQuestionRes(res);
                if (!_.isEmpty(questionAnaList)) {
                    getCorpusMatch(1, res)
                } else {
                    homeCenterStore.setCorpusMatchList([]);
                    homeCenterStore.setCorpusMatchChecked([]);
                    homeRightStore.setCorpusAnaList([]);
                }
            } else if (type === "sent") {
                homeRightStore.setCorpusAnaList(res || []);
                homeRightStore.setCorpusAnaFalse(false);
            }
        } else {
            message.error(res.errMsg || '获取问题解析列表失败，请重试');
        }
    }).catch(e => {
        homeRightStore.setQuestionAnaLoading(false);
        homeRightStore.setCorpusAnaLoading(false);
        homeCenterStore.setCorpusMatchLoading(false);
        console.log(e);
        message.error('获取问题解析列表失败，请重试');
    });
}

/**
 * 获取中间语料匹配列表
 * @param pageNum  当前页码
 * @param data  问题
 */
function getCorpusMatch(pageNum, data) {
    const {homeCenterStore, homeCommonStore} = stores;
    homeCenterStore.setCorpusMatchLoading(true);
    homeCenterStore.setCorpusMatchFalse(true);
    let postData = {
        "page_size": homeCenterStore.pageSize, //每页结果数
        "page_no": pageNum || homeCenterStore.page, //页码
        "parse_data": data || homeCommonStore.questionRes
    };

    /* {
        "total": 28,
        "page_no": 1,
        "case": [{
            "id": 0,
            "text": "2、优化组织架构,完善人才体系\n2019年,公司因势而变,调整组织架构,优化岗位职责、业务流程,提高员工个人和组织创造价值能力,培养团队专业能力,建立学习型组织、阶梯形人才培养体系,加强引进核心骨干,建立与现代公司治理相适应的管理运作机制。\n报告期内,公司试行绩效考核机制,围绕年度战略目标,定性、定量衡量管理层和全体员工业绩完成情况及对公司的贡献,建设长效人才机制,让人才资源真正实现增值增效。\n3、深化体系建设\n2019年,公司继续深化体系建设,实现项目分层分类、责任清晰,稳步修正和深入推进IPD(Integrated Product Development, 简称IPD,集成产品开发)、ITR(Issue to Resolution,简称ITR,问题到解决)、CRM(Customer Relationship Management,简称CRM,客户关系管理)等管理系统的落地实施,建立立项决策机制,有效管控需求。",
            "kp_id": [2277759, 2277760, 2277761, 2277762, 2277763],
            "art_id": 5121,
            "title": "2019年年度报告"
            },...
        ]
    } */
    webSdk.search.spoSearcher(postData).then(res => {
        homeCenterStore.setCorpusMatchFalse(false);
        homeCenterStore.setCorpusMatchLoading(false);
        if (!res.errCode || res.errCode === 0) {
            let list = !_.isEmpty(res) && res["case"] || [];
            list.forEach(item => {
                item._art_title = item.art_title;
                item._text = item.text;
                if (!item.id) {
                    item.id = (item['kp_id'] && item['kp_id'][0]) || item['art_id']
                }
            });
            homeCenterStore.setPageData(null, res.total);
            homeCenterStore.setCorpusMatchList(list);
            homeCenterStore.setCorpusMatchChecked([]);
            // homeRightStore.setCorpusAnaList([]);
        } else {
            message.error(res.errMsg || '获取语料匹配列表失败，请重试');
        }
    }).catch(e => {
        homeCenterStore.setCorpusMatchLoading(false);
        console.log(e);
        message.error('获取语料匹配列表失败，请重试');
    })
}

/**
 * 获取右侧摘要列表
 */
function getAbstractAna() {
    const {homeCenterStore, homeRightStore} = stores;
    if (homeCenterStore.corpusMatchChecked.length === 0) return;
    homeRightStore.setAbstractAnaLoading(true);
    // setAbstractAnaList([]);

    let postData = [];
    let resData = [];
    homeCenterStore.corpusMatchList.forEach((item, index) => {
        if (homeCenterStore.corpusMatchChecked.includes(item["id"])) {
            resData.push(item);
            postData.push(item["text"]);
        }
    });
    homeRightStore.setAbstractAnaFalse(true);
    webSdk.search.corpusAna(postData).then(res => {
        homeRightStore.setAbstractAnaLoading(false);
        if (!res.errCode || res.errCode === 0) {
            homeRightStore.setAbstractAnaFalse(false);
            res.forEach((item, index) => {
                resData[index].abstract = item;
            });
            homeRightStore.setAbstractAnaList(resData);
        } else {
            message.error(res.errMsg || '获取问题解析列表失败，请重试');
        }
    }).catch(e => {
        homeRightStore.setAbstractAnaLoading(false);
        console.log(e);
        message.error('获取问题解析列表失败，请重试');
    });
}

/**
 * 获取excel模式下数据 --- mock数据
 */
function queryExcelData(item) {
    const {homeCenterStore, homeRightStore, homeExcelStore, homeCommonStore} = stores;
    const type = item.type;
    homeCenterStore.setCenterOpen(false);
    homeRightStore.setRightOpen(false);
    homeRightStore.setQuestionAnaLoading(false);
    homeCenterStore.setCorpusMatchLoading(false);
    homeExcelStore.setExcelLoading(true);
    homeCommonStore.setQuestionType(1);
    homeExcelStore.setExcelOpen(true);
    homeExcelStore.setExcelFalse(true);

    let postData = {};
    let kpSearcher;
    if (type === undefined || type === 'slot') {
        postData.query = item.text;
        kpSearcher = webSdk.search.kpSearcher;
    } else if (type === 'st') {
        kpSearcher = webSdk.search.wordCount;
        postData.query = [item.text];
    }

    kpSearcher(postData).then(res => {
        homeExcelStore.setExcelLoading(false);
        if (!res.errCode || res.errCode === 0) {
            homeExcelStore.setExcelFalse(false);
            let title = item.text;
            setExcelData(title, res);
        } else {
            message.error(res.errMsg || '获取数据失败，请重试');
        }
    }).catch(e => {
        homeExcelStore.setExcelLoading(false);
        console.log(e);
        message.error('获取数据失败，请重试');
    });

    function setExcelData(title, list) {
        let cellData = [];
        cellData.push({
            r: 0,
            c: 0,
            v: {
                ct: {fa: "General", t: "g"},
                mc: {r: 0, c: 0, rs: 1, cs: list.length},
                ht: 0,
                m: title,
                v: title
            }
        });
        for (let i = 0; i < list.length; i++) {
            let data = list[i];
            cellData.push({
                r: 1,
                c: i,
                v: {
                    ct: {fa: "General", t: "g"},
                    m: data.key,
                    v: data.key
                }
            });
            for (let j = 0; j < data["values"].length; j++) {
                cellData.push({
                    r: j + 2,
                    c: i,
                    v: {
                        ct: {fa: "General", t: "g"},
                        m: data["values"][j],
                        v: data["values"][j]
                    }
                })
            }
        }

        let data = {
            "container": 'luckysheet', //luckysheet为容器id
            "title": item.text,
            "lang": "zh", // 设定表格语言
            "data": [       // 表格里数据
                {
                    "name": title,
                    color: "",
                    "status": "1",
                    "order": "0",
                    "celldata": cellData,
                    "config": {
                        "merge": {
                            "0_0": {r: 0, c: 0, rs: 1, cs: list.length}
                        }
                    },
                    "index": 0,
                    "chart": []
                }, {
                    "name": "Sheet2",
                    color: "",
                    "status": "0",
                    "order": "1",
                    "data": [],
                    // "isPivotTable": true,
                    "config": {},
                    "index": 1
                }, {
                    "name": "Sheet3",
                    color: "",
                    "status": "0",
                    "order": "2",
                    "data": [],
                    // "isPivotTable": true,
                    "config": {},
                    "index": 2
                }],
            "plugins": ["chart"],
            // "showtoolbar": false,   //是否显示工具栏
            /*"showtoolbarConfig": {  //自定义配置工具栏
                undoRedo: true, //撤销重做，注意撤消重做是两个按钮，由这一个配置决定显示还是隐藏
                paintFormat: false, //格式刷
                currencyFormat: false, //货币格式
                percentageFormat: false, //百分比格式
                numberDecrease: false, // '减少小数位数'
                numberIncrease: false, // '增加小数位数
                moreFormats: false, // '更多格式'
                font: false, // '字体'
                fontSize: false, // '字号大小'
                bold: false, // '粗体 (Ctrl+B)'
                italic: false, // '斜体 (Ctrl+I)'
                strikethrough: false, // '删除线 (Alt+Shift+5)'
                underline: false, // '下划线 (Alt+Shift+6)'
                textColor: false, // '文本颜色'
                fillColor: false, // '单元格颜色'
                border: false, // '边框'
                mergeCell: false, // '合并单元格'
                horizontalAlignMode: false, // '水平对齐方式'
                verticalAlignMode: false, // '垂直对齐方式'
                textWrapMode: false, // '换行方式'
                textRotateMode: false, // '文本旋转方式'
                image:false, // '插入图片'
                link:false, // '插入链接'
                chart: false, // '图表'（图标隐藏，但是如果配置了chart插件，右击仍然可以新建图表）
                postil:  false, //'批注'
                pivotTable: false,  //'数据透视表'
                function: false, // '公式'
                frozenMode: false, // '冻结方式'
                sortAndFilter: false, // '排序和筛选'
                conditionalFormat: false, // '条件格式'
                dataVerification: false, // '数据验证'
                splitColumn: false, // '分列'
                screenshot: false, // '截图'
                findAndReplace: false, // '查找替换'
                protection:false, // '工作表保护'
                print:false, // '打印'
            },*/
            "showinfobar": false,       //是否显示顶部信息栏
            // "showsheetbar": false,       // 是否显示底部sheet页按钮

        };
        homeExcelStore.setExcelData(data);
    }
}

/**
 * 问题变更，保存问题现有数据及状态
 * @param text：当前显示的问题
 */
function saveQuestionData(text) {
    const {homeCenterStore, homeRightStore, homeExcelStore, homeCommonStore} = stores;
    let savedData = [...homeCommonStore.savedData];
    let hasIn = false;
    let obj;
    if (homeCommonStore.questionType === 1) {  // 表格类型数据
        obj = {
            text,
            type: 1,
            excelData: homeExcelStore.excelFalse ? {} : homeExcelStore.excelData,
            excelFalse: homeExcelStore.excelFalse,
            quesClsFalse: homeCommonStore.quesClsFalse
        };
    } else {
        console.log('homeCommonStore.rightStatus:' + homeCommonStore.rightStatus);
        obj = {
            text,
            rightStatus: homeCommonStore.rightStatus,
            type: 0,
            page: homeCenterStore.page,
            totalNum: homeCenterStore.totalNum,
            questionRes: homeCommonStore.questionRes,       // 问题解析请求到的结果
            corpusMatchList: homeCenterStore.corpusMatchList,       // 中间语料数据
            questionAnaList: homeRightStore.questionAnaList,       // 右侧问题解析数据
            corpusAnaList: homeRightStore.corpusAnaList,           // 右侧语料解析数据
            abstractAnaList: homeRightStore.abstractAnaList,       // 右侧摘要数据
            questionAnaFalse: homeRightStore.questionAnaFalse,
            corpusMatchFalse: homeCenterStore.corpusMatchFalse,
            crtCorpusId: homeCenterStore.crtCorpusId,
            crtCorpusItem: homeCenterStore.crtCorpusItem,
            corpusAnaFalse: homeRightStore.corpusAnaFalse,
            corpusMatchChecked: homeCenterStore.corpusMatchChecked,
            abstractAnaFalse: homeRightStore.abstractAnaFalse,
            quesClsFalse: homeCommonStore.quesClsFalse
        };
    }
    for (let i = 0; i < savedData.length; i++) {
        if (savedData[i]["text"] === text) {
            savedData.splice(i, 1, obj);
            hasIn = true;
            break;
        }
    }
    if (!hasIn) savedData.push(obj);
    homeCommonStore.setSavedData(savedData);
}

/**
 * 判断点击的问题是否在已保存的数据内
 * @param text：当前点击的问题
 * @param index：当前点击问题的index
 * @returns {boolean}：是否有该数据
 */
function reloadQuestionData(text, index) {
    const {homeLeftStore, homeCenterStore, homeRightStore, homeExcelStore, homeCommonStore} = stores;
    let savedData = [...homeCommonStore.savedData];

    let hasIn = false;
    for (let i = 0; i < savedData.length; i++) {
        if (savedData[i]["text"] === text) {
            let data = savedData[i];
            homeLeftStore.setCrtQuestionIndex(index);
            homeLeftStore.setLeftOpen(true);
            console.log(JSON.parse(JSON.stringify(data)))
            if (data.type === 0) {
                homeCommonStore.setQuestionType(0);
                homeCenterStore.setCenterOpen(true);
                homeRightStore.setRightOpen(true);
                homeCommonStore.setQuesClsFalse(data['quesClsFalse']);
                homeCommonStore.setRightStatus(data["rightStatus"]);
                homeCommonStore.setQuestionRes(data["questionRes"]);
                homeCenterStore.setPageData(data["page"], data["totalNum"]);
                homeCenterStore.setCorpusMatchChecked(data['corpusMatchChecked']);
                homeCenterStore.setCrtCorpusId(data["crtCorpusId"]);
                homeCenterStore.setCrtCorpusItem(data['crtCorpusItem']);
                homeCenterStore.setCorpusMatchList(data["corpusMatchList"]);
                homeRightStore.setQuestionAnaList(data["questionAnaList"]);
                homeRightStore.setCorpusAnaList(data["corpusAnaList"]);
                homeRightStore.setAbstractAnaList(data["abstractAnaList"]);
                if (data['quesClsFalse']) {
                    getQuestionType(text);
                }
                else if (data["questionAnaFalse"]) {     // 问题解析获取失败
                    questionAndCorpusAna('query', text)
                } else if (data["corpusMatchFalse"]) {     // 语料获取失败
                    getCorpusMatch(data["page"], data["questionRes"]);
                }
                if (data["rightStatus"] === 1 && data["corpusAnaFalse"]) {  // 获取语料解析失败
                    questionAndCorpusAna('sent', data["crtCorpusId"])
                }
                if (data["rightStatus"] === 2 && data["abstractAnaFalse"]) {    // 生成摘要失败
                    getAbstractAna()
                }
            } else if (data.type === 1) {
                homeCommonStore.setQuestionType(1);
                homeExcelStore.setExcelOpen(true);
                homeExcelStore.setExcelData(data["excelData"]);
                homeExcelStore.setExcelFalse(data["excelFalse"]);
                if (data["excelFalse"]) {
                    queryExcelData(data);
                }
            }
            hasIn = true;
            break;
        }
    }
    return hasIn;
}

function getCorpusPosition(kpIdList) {
    if (!kpIdList) return;
    return new Promise((resolve, reject) => {
        const {homeCenterStore} = stores;
        let postData = {
            kp_id_list: kpIdList
        };
        webSdk.search.getCorpusPosition(postData).then(res => {
            if (!res.errCode || res.errCode === 0) {
                homeCenterStore.setPositionData(res);
                resolve()
            } else {
                message.error(res.errMsg || '获取文章信息失败，请重试');
                reject();
            }
        }).catch(e => {
            console.log(e);
            message.error('获取文章信息失败，请重试');
            reject()
        });
    })
}

export {
    setOpen,
    getQuestionType,
    questionAndCorpusAna,
    getCorpusMatch,
    getAbstractAna,
    queryExcelData,
    saveQuestionData,
    reloadQuestionData,
    getCorpusPosition
}
