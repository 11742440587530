import React, { PureComponent, Fragment } from 'react';
import _ from 'lodash'
import ClipboardJS from 'clipboard'
import AppContext from '../../../AppContext'
import { Button, Table, Modal, message, Form, Input } from 'antd'
import './Examples.scss'

const { TextArea } = Input;

class Examples extends PureComponent {
    static contextType = AppContext

    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            pageSize: 10,
            pageTotal: 0,
            tableLoading: false,
            exampleDialog: {//弹窗
                title: "",
                isShow: false,
                type: "add", // display 查看摘要; add 添加摘要
                displayText: ""
            },
            //表头
            tableColumn: [
                {
                    title: '序号',
                    children: [
                        {
                            title: 'No.',
                            dataIndex: 'number',
                            key: 'number',
                            align: "center",
                            width: 80,
                            render: text => <span>{text}</span>,
                        },
                    ]
                },
                {
                    title: '案例实体',
                    children: [
                        {
                            title: 'Entity',
                            dataIndex: 'entity',
                            key: 'entity',
                            align: "center",
                            width: 100,
                            render: text => <span className="examples-digest">{text}</span>,
                        },
                    ]
                },
                {
                    title: '案例原文链接',
                    children: [
                        {
                            title: 'Link',
                            dataIndex: 'link',
                            key: 'link',
                            align: "center",
                            render: text => <span className="examples-digest link" title={text}>{text}</span>,
                        },
                    ]
                },
                {
                    title: '案例摘要',
                    children: [
                        {
                            title: 'Digest',
                            dataIndex: 'digest',
                            key: 'digest',
                            align: "center",
                            render: text => (<span className="examples-digest" title={text}>{text}</span>),
                        },
                    ]
                },
                {
                    title: '操作',
                    children: [
                        {
                            title: 'ToolBar',
                            key: 'toolBar',
                            dataIndex: 'toolBar',
                            align: "center",
                            width: 200,
                            render: (text, record) => (
                                <span>
                                    <a className="plan-tools" onClick={this.handleDialog.bind(this, "display", true, record)}>查看摘要</a>
                                    <a className="plan-tools clip-board" data-clipboard-text={record.digest}>复制摘要</a>
                                </span>
                            ),
                        },
                    ]
                },
            ],
            //表格数据
            tableData: [
                {
                    number: "1",
                    entity: "万科",
                    link: "https://www.axureshop.com/a/1407.html",
                    digest: "万科企业股份有限公司成立于1984年",
                },
                {
                    number: "2",
                    entity: "华润",
                    link: "https://www.axureshop.com/a/1407.html",
                    digest: "万科企业股份有限公司成立于1984年",
                },
                {
                    number: "3",
                    entity: "万科",
                    link: "https://www.axureshop.com/a/1407.htmlhttps://www.axureshop.com/a/1407.htmlhttps://www.axureshop.com/a/1407.html",
                    digest: "万科企业股份有限公司成立于1984年",
                },
                {
                    number: "4",
                    entity: "华润",
                    link: "https://www.axureshop.com/a/1407.html",
                    digest: "万科企业股份有限公司成立于1984年",
                },
                {
                    number: "5",
                    entity: "万科",
                    link: "https://www.axureshop.com/a/1407.html",
                    digest: "万科企业股份有限公司成立于1984年万科企业股份有限公司成立于1984年万科企业股份有限公司成立于1984年万科企业股份有限公司成立于1984年万科企业股份有限公司成立于1984年万科企业股份有限公司成立于1984年",
                },
                {
                    number: "6",
                    entity: "华润",
                    link: "https://www.axureshop.com/a/1407.html",
                    digest: "万科企业股份有限公司成立于1984年",
                },
            ]
        }
    }

    componentDidMount() {
        const clipboard = new ClipboardJS(".clip-board")

        clipboard.on('success', function (e) {
            message.success('复制成功！');
            e.clearSelection();
        });
    }

    /** 去教案编辑页 */
    toEditPage() {
        this.context.getHistory().push({
            pathname: `/lessonplan/edit`
        })
    }

    /**操作弹窗 */
    handleDialog(type, isShow, item) {
        let exampleDialog = { ...this.state.exampleDialog }
        exampleDialog.isShow = isShow
        if (isShow) {
            if (type === "display") {
                exampleDialog.title = "查看案例"
                exampleDialog.type = "display"
                if (isShow) {
                    exampleDialog.displayText = item.digest
                }

            } else if (type === "add") {
                exampleDialog.title = "添加案例"
                exampleDialog.type = "add"
            }
        }

        this.setState({
            exampleDialog
        })
    }

    render() {
        const { exampleDialog } = this.state
        return (
            <div className="examples-display">
                <div className="examples-btngroup">
                    <Button
                        className="examples-btn update"
                    // onClick={this.backToIndex.bind(this)}
                    >更新案例</Button>
                    <Button
                        type="primary"
                        className="examples-btn"
                        onClick={this.handleDialog.bind(this, "add", true)}
                    >添加案例</Button>
                </div>
                <div className="examples-display-table">
                    <Table
                        className="plan-table"
                        columns={this.state.tableColumn}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={(record, index) => {
                            return index
                        }}
                        pagination={{
                            showSizeChanger: true,
                            showQuickJumper: true,
                            pageSizeOptions: ["10", "15", "20", "25"],
                            current: this.state.page + 1,
                            pageSize: this.state.pageSize,
                            total: this.state.pageTotal,
                            // onChange: (page, pageSize) => this.pageChange(page, pageSize),
                            // onShowSizeChange: (current, size) => this.pageSizeChange(current, size),
                            showTotal: total => `共 ${total} 条`
                        }}
                    />
                </div>
                <Modal
                    title={exampleDialog.title}
                    className="example-modal"
                    visible={exampleDialog.isShow}
                    onCancel={this.handleDialog.bind(this, "display", false)}
                    footer={[
                        <Button
                            key="cancel"
                            className="example-modal-btn cancel"
                            onClick={this.handleDialog.bind(this, "display", false)}
                        >取消</Button>,
                        <Button
                            key="copy"
                            type="primary"
                            className="example-modal-btn copy clip-board"
                            data-clipboard-target="#exampleModalDigest"
                        >复制</Button>,
                    ]}
                >
                    {
                        exampleDialog.type === "display" ? (
                            <p id="exampleModalDigest" className="example-modal-digest">{exampleDialog.displayText}</p>
                        ) : (
                                <Form
                                    className="example-modal-form"
                                    layout="horizontal"
                                >
                                    <Form.Item
                                        className="example-modal-item"
                                        label="案例实体 Entity："
                                        labelCol={{ span: 6 }}
                                        wrapperCol={{ span: 18 }}
                                    >
                                        <Input placeholder="请输入" />
                                    </Form.Item>
                                    <Form.Item
                                        className="example-modal-item"
                                        label="案例原文链接 Link："
                                        labelCol={{ span: 6 }}
                                        wrapperCol={{ span: 18 }}
                                    >
                                        <Input placeholder="请输入" />
                                    </Form.Item>
                                    <Form.Item
                                        className="example-modal-item"
                                        label="案例摘要 Digest："
                                        labelCol={{ span: 6 }}
                                        wrapperCol={{ span: 18 }}
                                    >
                                        <TextArea
                                            // value={value}
                                            // onChange={this.onChange}
                                            placeholder="请输入"
                                            autoSize={{ minRows: 7, maxRows: 10 }}
                                        />
                                    </Form.Item>
                                </Form>
                            )
                    }

                </Modal>
            </div >
        );
    }
}

export default Examples;