/*
 * @Description:
 * @LastEditTime: 2022-09-16 11:25:50
 * @LastEditors: liqun 13212226759@163.com
 */
import BaseWebApi from './BaseWebApi'

const ObjToQuery = (obj) => {
  let result = '?'
  for (let [key, value] of Object.entries(obj)) {
    result += key + '=' + value + '&'
  }
  result = result.slice(0, result.length - 1)
  return result
}

class SubjectGraph extends BaseWebApi {
  constructor() {
    super()
    this._api = this.gate.subjectGraph
  }

  async getGraphNode(subjectId) {
    const resp = await this._api.get(
      `subject/getAllNode?subjectId=${subjectId}`
    )
    return resp.data
  }
}

export default SubjectGraph
