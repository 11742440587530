import React, {useState, useEffect} from 'react';
import ReactEcharts from 'echarts-for-react'
import _ from 'lodash'

function ReportGraph(props) {
    let {type, data, questionData} = props;

    const [options, setOptions] = useState({});

    useEffect(() => {
        if (data.length === 0) return;
        let option = {};
        /*let content = [{
            "id": 40,
            "answer": [
                {
                    "key": "时间",
                    "values": ["2019年", "2018年", "2017年", "2016年", "2015年", "2014年", "2013年", "2012年", "2011年", "2010年"]
                },
                {
                    "key": "万科营收(亿元)",
                    "values": [3679, 2977, 2429, 2405, 1955.5, 1464, 1354, 1031.2, 90, 168]
                },
                {"key": "绿地控股营收(亿元)", "values": [4280.8, 3510.14, 3000, 1080.21, "", "", 11.19, "", "", ""]}
            ]
        }, {
            "id": 41,
            "answer": [
                {
                    "key": "时间",
                    "values": ["2019年", "2018年", "2017年", "2016年", "2015年", "2014年", "2013年", "2012年", "2011年", "2010年"]
                },
                {"key": "万科营收(亿元)", "values": [3679, 2977, 2429, 2405, 1955.5, 1464, 1354, 1031.2, 90, 168]}],
            "keyword": [{"key": "公司", "value": "万科"}, {"key": "时间", "value": "近十年"}, {"key": "属性", "value": "营收"}],
            "tag_ids": null,
            "question": "万科近十年的营收是多少",
            "tag_names": "客观问题/图表/饼图"
        }];
        let content2 = [{
            "id": 58,
            "answer": [
                {
                    "key": "start_date", "values": ["-201901-01"]},
                {
                    "key": "end_date",
                    "values": ["2019-12-31"]
                },
                {"key": "传感器:word_count", "values": [1000]},
                {
                    "key": "传感器:para_count",
                    "values": [815]
                },
                {"key": "传感器:distinct_count", "values": [847]}
            ],
            "keyword": [],
            "tag_ids": null,
            "question": "行业研报中传感器出现了多少次",
            "tag_names": "客观问题/图表/柱状图"
        }]*/

        try {
            let legendData = [];
            let xAxisData = [];
            let series = [];
            if (data.length === 0) {
                setOptions({});
                return;
            } else if (data.length === 1) {
                legendData = data[0].key || '';
                if (type === 'tree') {
                    let obj = {
                        name: data[0].key,
                        children: []
                    };
                    if (data[0].values && data[0].values.length > 0) {
                        for (let i = 0; i < data[0].values.length; i++) {
                            if (i > 50) break;
                            obj.children.push({name: data[0].values[i]})
                        }
                        /*data[0].values.forEach((item,index) =>{
                            obj.children.push({name: item})
                        })*/
                    }
                    series = [{
                        type: 'tree',
                        data: [obj],
                        // left: '2%',
                        // right: '2%',
                        // top: '8%',
                        // bottom: '20%',
                        layout: 'radial',
                        symbol: 'emptyCircle',
                        orient: 'LR',
                        expandAndCollapse: true,
                        zoom: 1, //当前视角的缩放比例
                        roam: true, //是否开启平游或缩放
                        scaleLimit: { //滚轮缩放的极限控制
                            min: 0.5,
                            max: 10
                        },
                        label: {
                            position: 'top',
                            // rotate: -90,
                            verticalAlign: 'middle',
                            // align: 'right',
                            fontSize: 16
                        },
                        leaves: {
                            label: {
                                position: 'right',      // 展开方向
                                // rotate: -90,
                                // verticalAlign: 'middle',
                                align: 'left'
                            }
                        },
                        animationDurationUpdate: 750
                    }]
                }
            } else {
                data.forEach((item, index) => {
                    if (index === 0) {
                        xAxisData = item.values || [];
                    } else {
                        legendData.push(item.key || '');

                        let allNull = true;
                        let obj = {};
                        if (item.values && item.values.length) {
                            item.values = item.values.map((v,vi) =>{
                                if (!v) return '';
                                allNull = false;
                                return v
                            })
                        }
                        if (type === 'line') {
                            obj = {
                                data: item.values || [],
                                name: item.key || '',
                                type
                            };
                        } else if (type === 'bar') {
                            obj = {
                                data: item.values || [],
                                name: item.key || '',
                                type,
                                // barWidth: (1 / (data.length - 1) - 0.05)  +'%'
                            };
                        } else if (type === 'pie') {
                            let seriesData = [];
                            if (data[0].values && data[0].values.length > 0) {
                                data[0].values.forEach((v, vi) => {
                                    seriesData.push({
                                        name: v,
                                        value: item['values'][vi]
                                    })
                                })
                            }
                            let num = data.length - 1;      // 总共图谱数量
                            let center = (2 * index - 1) / num / 2;
                            obj = {
                                name: item.key || '',
                                type,
                                radius: '60%',
                                center: [center * 100 + '%', '50%'],
                                emphasis: {
                                    itemStyle: {
                                        shadowBlur: 10,
                                        shadowOffsetX: 0,
                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                    }
                                },
                                data: seriesData
                            };
                        }
                        if (!allNull && !_.isEmpty(obj)) series.push(obj);
                    }
                });
            }

            if (type === 'line' || type === 'bar') {      // 曲线图
                option = {
                    legend: {
                        data: legendData        // 曲线的名称
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    xAxis: {
                        type: 'category',
                        data: xAxisData
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series
                };
            } else if (type === 'pie') {    // 饼图
                option = {
                    legend: {
                        data: legendData,        // 曲线的名称
                        orient: 'vertical',
                        left: 'left',
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: '{a} <br/>{b} : {c} ({d}%)'
                    },
                    series
                }
            } else if (type === 'tree') {   // 只有一个数据
                option = {
                    tooltip: {
                        trigger: 'item',
                        triggerOn: 'mousemove'
                    },
                    series
                }
            }
            console.log(option)
            if (series.length>0) setOptions(option)
        } catch (e) {
            console.log(e)
        }
    }, [props]);

    return (
        <div className="report-graph-content">
            {
                !_.isEmpty(options) ?
                    <ReactEcharts
                        style={type === 'tree' ? {height: 500} : {}}
                        // ref="echarts_graph_all"
                        option={options}
                        // style={{ height: '100vh' }}
                        // onEvents={this.onclick}
                    /> : null
            }
        </div>

    )

}

export default ReportGraph
