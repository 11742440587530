import React, { useState } from 'react'
import {
  ComposedChart,
  LineChart,
  Line,
  BarChart,
  Bar,
  PieChart,
  Pie,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from 'recharts'
import './Charts.scss'

function formatTableData(tableData) {
  console.log(tableData)
  let { th, tr } = tableData.body
  let data = []
  let dataKey = []
  let flag = false
  for (let i = 0; i < tr.length; i++) {
    let obj = {}
    for (let key in tr[i]) {
      if (key == 0) {
        obj[key] = tr[i][key]['td']
      } else {
        let name = th[key]['name']
        obj[name] = parseFloat(tr[i][key]['td'])
        !flag && dataKey.push(name)
      }
    }
    flag = true
    data.push(obj)
  }
  return {
    data,
    dataKey
  }
}

function Charts({ type, data, dataKey }) {
  // let data = [
  //   {
  //     name: 'Page A',
  //     uv: 4000,
  //     pv: 2400
  //   },
  //   {
  //     name: 'Page B',
  //     uv: 3000,
  //     pv: 1398
  //   },
  //   {
  //     name: 'Page C',
  //     uv: 2000,
  //     pv: 9800
  //   },
  //   {
  //     name: 'Page D',
  //     uv: 2780,
  //     pv: 3908
  //   },
  //   {
  //     name: 'Page E',
  //     uv: 1890,
  //     pv: 4800
  //   },
  //   {
  //     name: 'Page F',
  //     uv: 2390,
  //     pv: 3800
  //   },
  //   {
  //     name: 'Page G',
  //     uv: 3490,
  //     pv: 4300
  //   }
  // ]
  function randomColor() {
    const getRandom = () => Math.floor(Math.random() * 256)
    return `rgb(${getRandom()}, ${getRandom()},${getRandom()})`
  }
  let fillColors = ['#8884d8', '#82ca9d', '#bf2a5c']
  // const renderLegend = (props) => {
  //   const { payload } = props

  //   return (
  //     <ul>
  //       {payload.map((entry, index) => (
  //         <li key={`item-${index}`}>{entry.value}</li>
  //       ))}
  //     </ul>
  //   )
  // }
  if (type === 'Line') {
    return (
      <LineChart
        width={306}
        height={250}
        data={data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <XAxis dataKey="0" />
        <YAxis />
        <Tooltip />
        <Legend iconType="square" />
        {dataKey.map((i, index) => (
          <Line
            type="monotone"
            dataKey={i}
            stroke={fillColors[index] || randomColor()}
            key={i}
          />
        ))}
      </LineChart>
    )
  } else if (type === 'Bar') {
    return (
      <BarChart width={306} height={250} data={data}>
        <XAxis dataKey="0" />
        <YAxis />
        <Tooltip />
        <Legend iconType="rect" />
        {dataKey.map((i, index) => (
          <Bar dataKey={i} fill={fillColors[index] || randomColor()} key={i} />
        ))}
      </BarChart>
    )
  } else if (type === 'Pie') {
    return (
      <PieChart width={306} height={250}>
        {dataKey.map((i, index) => (
          <Pie
            data={data}
            dataKey={i}
            nameKey="0"
            cx="50%"
            cy="50%"
            outerRadius={50}
            fill={fillColors[index] || randomColor()}
            key={i}
          />
        ))}
      </PieChart>
    )
  }
  return null
}

function DialogCharts({ uid, tableData }) {
  let [type, setType] = useState()
  let { data, dataKey } = formatTableData(tableData)

  return (
    <div className="msg clearfix qa-charts" key={uid} mkey={uid}>
      <div className="article">
        您是否要生成：
        <span className="chat-btn" onClick={() => setType('Bar')}>
          柱状图
        </span>
        <span className="chat-btn" onClick={() => setType('Pie')}>
          饼图
        </span>
        <span className="chat-btn" onClick={() => setType('Line')}>
          线性图
        </span>
        <div style={{ display: type ? 'block' : 'none' }} className="chart">
          <Charts type={type} data={data} dataKey={dataKey} />
        </div>
      </div>
    </div>
  )
}

export default React.memo(DialogCharts)
