import React, { Component } from 'react'
import _ from 'lodash'
import { Icon, Drawer, Spin , message} from 'antd'
import './PublicReadList.scss'
import { webSdk } from '../../api/WebSdk';

export class PublicReadList extends Component {

    constructor(props) {
        super(props)

        this.state = {
            readList: {//阅读列表
                isShow: false,//是否展示列表
                currArticleId: 0,//当前文章的id
                list: []
            },
            loading: true
        }
    }

    componentDidMount() {

        const _this = this;

        //监听菜单按钮事件
        let menu = document.querySelector(".header-left")
        menu.addEventListener('click', () => {
            _this.closeReadList()
        }, false)
    }

    componentWillUnmount() {
        // 卸载异步操作设置状态
        this.setState = (state, callback) => {
            return;
        }
    }

    /**打开阅读列表
     * @param {*} flag 是否加入阅读列表
     * @param {*} activeId 文章id
     * @param {*} type 1.企业研报2.行业研报3.企业年报4.招股说明书
     */
    openReadList(flag, activeId, type) {
        const _this = this;
        let readList = _this.state.readList
        readList.isShow = true;

        _this.setState({
            readList,
            loading: true
        }, () => {
            //加载数据
            webSdk.readList.getMyCollections().then(res => {
                this.setState({
                    loading: false
                })
                if (!res.errCode || res.errCode === 0) {
                    readList.list = res.reverse();
                    //高亮当前页的列表数据
                    if (activeId) {
                        let readList = _this.state.readList
                        let list = readList.list

                        //显示新的高亮
                        readList.currArticleId = activeId

                        //加入阅读列表
                        let activePage = _.find(list, { obj_id: Number(activeId) })
                        if (flag) {
                            _this.props.fnUnloadingAdd();
                            if (_.isEmpty(activePage)) {
                                webSdk.readList.addCollection({ activeId, type }).then(res => {
                                    //加载数据
                                    webSdk.readList.getMyCollections().then(res => {
                                        if (!_.isEmpty(res)) {
                                            readList.list = res.reverse();

                                            _this.setState({
                                                readList,
                                                loading: false
                                            })
                                        }
                                    })
                                })
                            } else {
                                _this.setState({
                                    readList,
                                    loading: false
                                })
                            }
                        } else {
                            _this.setState({
                                readList,
                                loading: false
                            })
                        }
                    } else {
                        _this.setState({
                            readList,
                            loading: false
                        })
                    }
                }
                else {
                    message.error(res.errMsg || '请求列表失败，请稍后重试')
                }
            }).catch(e =>{
                message.error('请求列表失败，请稍后重试')
            })
        })

    }

    //关闭阅读列表
    closeReadList() {
        let readList = this.state.readList
        readList.isShow = false;
        window.document.body.style = ""
        this.setState({
            readList
        })
    }

    //阅读列表：进入对应文章
    toArticlePage(item) {
        this.props.fnToArticlePage(item)
        this.closeReadList();

    }

    //阅读列表：删除文章
    deleteArticle(item, event) {
        const _this = this
        let readList = _this.state.readList

        _this.setState({
            loading: true
        }, () => {
            webSdk.readList.removeCollection(item.id).then(res => {

                //加载数据
                webSdk.readList.getMyCollections().then(res => {
                    readList.list = res.reverse();

                    _this.setState({
                        readList,
                        loading: false
                    })
                })
            })
        })



        event.stopPropagation();
    }

    //阅读列表：清空列表
    deleteAllArticle() {
        const _this = this;
        let readList = _this.state.readList

        _this.setState({
            loading: true
        }, () => {
            webSdk.readList.clearMyCollection().then(res => {

                readList.list = [];

                _this.setState({
                    readList,
                    loading: false
                })
            })
        })
    }

    toHome() {
        console.log(123)
        this.context.getHistory().push({
            pathname: `/home`,
        })
    }

    render() {
        return (
            <>
                <Drawer
                    className="public-header-title-drawer"
                    closable={false}
                    visible={this.state.readList.isShow}
                    onClose={this.closeReadList.bind(this)}
                    placement="left"
                    width="400"
                >
                    <div className="drawer-logo">
                        <span className="font_icon header-left"
                            style={{ fontSize: 21 }}
                            onClick={this.closeReadList.bind(this)}
                        >
                            &#xe660;
                        </span>
                        <span className="center-search-logo">
                            <span className="logo-click" />
                            {/* <span className="logo-click">
                                <i class="iconfont logo-click-icon">&#xe607;</i>
                            </span> */}
                            企业信息库
                            </span>
                    </div>
                    <Spin spinning={this.state.loading}>
                        <div className="drawer-head">
                            <span className="drawer-head-title">
                                阅读列表&nbsp;
                            {/* <Icon type="unordered-list" /> */}
                            </span>
                            <div className="drawer-head-btn">
                                <a className="btn" onClick={this.deleteAllArticle.bind(this)}>
                                    清空
                            </a>
                            </div>
                        </div>
                        <div className="drawer-content">
                            <ul>
                                {
                                    this.state.readList.list.map((item, idx) => {
                                        return (
                                            <li className={Number(item.obj_id) === Number(this.state.readList.currArticleId) ? 'drawer-content-item curr-page-color' : 'drawer-content-item'}
                                                key={idx}
                                                onClick={this.toArticlePage.bind(this, item)}
                                            >
                                                <div className="item-title" title={item.title}>
                                                    {`${idx + 1}. ${item.title}`}
                                                </div>
                                                <div className="item-icon"
                                                    onClick={this.deleteArticle.bind(this, item)}
                                                >
                                                    <Icon type="close-circle" />
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </Spin>
                </Drawer>
            </>
        )
    }
}

export default PublicReadList
