// 初次调用，监听PDF是否已加载
function highLightPDF(positionData, modalRef, firstLoading) {
    if (!positionData || !modalRef) return;
    let modalDocument = modalRef.current.contentDocument || null;
    let modalWindow = modalRef.current.contentWindow || null;
    if (!modalDocument || !modalWindow) return;
    waitDoing(getViewer, getViewerChildren).then(() => {
        if (!modalRef && !modalRef.current) return;
        let modalDocument = modalRef.current.contentDocument;
        let viewer = modalDocument.querySelector("#viewer");
        if (!viewer) return;
        let pageArr = viewer.childNodes;
        if (pageArr.length === 0) return;
        // 当前显示窗口对应页码元素  滚动到显示的页码
        let page = positionData && positionData[0] && positionData[0]["pos_list"] && positionData[0]["pos_list"][0] && positionData[0]["pos_list"][0]["page"] ? positionData[0]["pos_list"][0]["page"] : null;
        if (page) {
            let modalPage = pageArr[positionData[0]["pos_list"][0]["page"] - 1];
            modalDocument.querySelector("#viewerContainer").scrollTo(0, modalPage.offsetTop);
        }
        waitDoing(getViewer, () => getPageLoading(page), 800).then(() => {
            // 循环到pdf加载后 高亮元素
            setHighLight(positionData, modalRef, firstLoading);
        }).finally(() =>{
            if (!waitTimer) clearTimeout(waitTimer);
            waitTimer = null;
            waitNum = 0;
        })
    }).finally(e => {
        if (!waitTimer) clearTimeout(waitTimer);
        waitTimer = null;
        waitNum = 0;
    });

    // 用于判断元素是否已加载，元素是否已删除。getViewer存在则pdf未关闭，getViewerChildren大于0则pdf已渲染
    function getViewer() {
        return modalRef && modalRef.current && modalRef.current.contentDocument;
    }
    function getViewerChildren() {
        let viewer = modalDocument.querySelector("#viewer");
        return viewer && viewer.childNodes && viewer.childNodes.length;
    }

    function getPageLoading(page) {
        let viewer = modalDocument.querySelector("#viewer");
        if (!viewer || !viewer.childNodes || !viewer.childNodes.length) return false;
        let pageData = viewer.querySelector(`.page[data-page-number='${page}']`);
        return pageData && pageData.attributes["data-loaded"] && pageData.attributes["data-loaded"].value==="true"
    }
}

// pdf加载/页面滚动  ---》  循环数据，设置高亮区域，如果是第一次加载则居中第一条高亮数据
function setHighLight(positionData, modalRef, firstLoading) {
    if (!modalRef && !modalRef.current) return;
    let modalDocument = modalRef.current.contentDocument;
    let viewer = modalDocument.querySelector("#viewer");
    if (!viewer) return;
    let pageArr = viewer.childNodes;
    if (pageArr.length === 0) return;
    let setCenter = firstLoading;
    for (let i=0;i<positionData.length;i++) {
        let list = positionData[i]["pos_list"] || [];
        /*
        [
            {
                "page": "190",
                "word_range": [
                    2,
                    29
                ],
                "coords": "89.904,531.504,255.65,267.65"
            }
        ]
        * */
        let pageSize = positionData[i]["page_size"] || [];            // [595.32,841.92]
        if (list.length === 0 || pageSize.length === 0) continue;
        // 原始页宽高
        let pdfWidth = pageSize[0];
        let pdfHeight = pageSize[1];
        for (let j=0;j<list.length;j++) {
            // 相对于原始页面坐标 x1 x2 y1 y2
            let coordsArr = list[j]["coords"].split(",");       // [89.904, 531.504, 255.65, 267.65]    x1 x2 y1 y2
            // 文字所在页码
            let pdfPage = list[j]["page"];
            // 计算 文字坐标/原始页宽高 比例
            let scaleArr = [coordsArr[0]/pdfWidth, coordsArr[1]/pdfWidth, coordsArr[2]/pdfHeight, coordsArr[3]/pdfHeight];
            // 当前显示窗口对应页码元素
            let modalPage = pageArr[pdfPage - 1];
            // 页面当前页宽高
            let modalPageWidth = modalPage.style.width.replace("px","");
            let modalPageHeight = modalPage.style.height.replace("px","");
            // 当前页的高亮div
            let highLightBlock = viewer.querySelector(`#high_light_${pdfPage}`);
            if (!highLightBlock) {
                // 创建div
                highLightBlock = modalDocument.createElement("div");
                highLightBlock.id = `high_light_${pdfPage}`;
                highLightBlock.className = "high-light-block";
                modalPage.appendChild(highLightBlock);
            }
            // 当前语句的高亮div
            let highLightEl = modalDocument.querySelector(`#high_light_element_${i}_${j}`);
            if (setCenter) {
                // 将第一个高亮元素居中
                let modalHeight = modalRef.current.clientHeight;
                modalDocument.querySelector("#viewerContainer").scrollTo(0, modalPage.offsetTop + (1 - scaleArr[3]) * modalPageHeight - 0.5 * modalHeight);
                setCenter = false;
            }
            if (highLightEl) continue;
            highLightEl = modalDocument.createElement("div");
            highLightEl.className = "high-light-block-element";
            highLightEl.id = `high_light_element_${i}_${j}`;
            highLightEl.style.position = "absolute";
            highLightEl.style.backgroundColor = "#4d90fe";
            highLightEl.style.opacity = "0.3";
            highLightBlock.appendChild(highLightEl);

            // 当前语句在段落中的位置 [startIndex, endIndex]
            let wordRange = list[j]["word_range"];

            let lineWidth = (scaleArr[1] - scaleArr[0]) * modalPageWidth;
            let lineHeight = (scaleArr[3] - scaleArr[2]) * modalPageHeight;
            let lineLeft = scaleArr[0] * modalPageWidth;
            let lineFontNum = lineWidth / lineHeight;
            let elWidth = (wordRange[1] - wordRange[0]) * lineWidth/lineFontNum;
            let elLeft = lineLeft + wordRange[0] * lineWidth/lineFontNum;
            let elTop = (1 - scaleArr[3]) * modalPageHeight;

            if (elLeft > scaleArr[1] * modalPageWidth) elWidth = 0;
            if (elLeft + elWidth > scaleArr[1] * modalPageWidth) elWidth = scaleArr[1] * modalPageWidth - elLeft;

            // 设置当前语句高亮信息的位置
            highLightEl.style.width = elWidth + 'px';
            highLightEl.style.height = lineHeight + 'px';
            highLightEl.style.top = elTop + 'px';
            highLightEl.style.left = elLeft + 'px';
            highLightBlock.appendChild(highLightEl);
        }
    }

}

let waitTimer = null;
let waitNum = 0;
function waitDoing(conditionFunc, targetFunc, time = 500) {
    // console.log('执行循环' + waitNum);
    let condition = conditionFunc();
    let target = targetFunc();
    return new Promise((resolve, reject) => {
        if (!condition) {
            if (!waitTimer) clearTimeout(waitTimer);
            waitTimer = null;
            waitNum = 0;
            reject();
        }
        if (!target) {
            if (waitNum === 100) {
                if (!waitTimer) clearTimeout(waitTimer);
                waitTimer = null;
                waitNum = 0;
                reject();
            }
            waitNum ++;
            waitTimer = setTimeout(() => {
                waitDoing(conditionFunc, targetFunc).then(res =>{
                    resolve(res)
                }).catch(e => {
                    if (!waitTimer) clearTimeout(waitTimer);
                    waitTimer = null;
                    waitNum = 0;
                    reject();
                })
            }, time)
        } else {
            if (!waitTimer) clearTimeout(waitTimer);
            waitTimer = null;
            waitNum = 0;
            resolve(target);
        }
    })
}

export {highLightPDF, setHighLight}