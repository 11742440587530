import React from 'react'
import {Icon, Radio, Spin, Table, Upload, Button, message, Popconfirm, Progress, Modal, Input} from 'antd';
import './FileManage.scss'
import {webSdk} from "../../../api/WebSdk";
import queryString from 'query-string'
import UploadModal from "./UploadModal";
import ViewArticleModal from "../../common/ViewArticleModal/ViewArticleModal";


/*列表类展示数据*/
class FileManage extends React.PureComponent {
    constructor(props) {
        super(props)

        let searchData = props.location.search || '';
        let page = 1,
            pageSize = 10;
        if (searchData) {
            page = JSON.parse(queryString.parse(searchData)['page'] || '1');
            pageSize = parseInt(queryString.parse(searchData)['pageSize'] || '10');
        }

        this.state = {
            selectItemIndex: [],
            selectItem: [],
            columns: [
                {
                    title: '序号',
                    dataIndex: 'index',
                    render: (text, record, index) => `${index + 1}`,
                    align: 'center',
                    width: 70
                },
                {
                    title: '文档名称',
                    dataIndex: 'name',
                    key: 'name',
                    width: 200,
                    onCell: (record, rowIndex) => {
                        return {
                            style: {
                                cursor: 'pointer'
                            },
                            onClick: () => this.viewResearch(record)
                        }
                    },
                    render: text => text || '-'
                },
                {
                    title: '文档类型',
                    dataIndex: 'doc_type',
                    key: 'doc_type',
                    width: 110,
                    // align: 'center',
                    render: text => text || '-'
                },
                {
                    title: '标签',
                    dataIndex: 'doc_tags',
                    key: 'doc_tags',
                    width: 200,
                    render: (text, record) => {
                        return <div className="table-tag-content">
                            {
                                record.doc_tags && record.doc_tags.length ? record.doc_tags.map((item, index) => <span
                                    className="table-tag-item" key={`table_tag_${index}`}>{item}</span>) : "-"
                            }
                        </div>
                    }
                },
                {
                    title: '解析进度',
                    dataIndex: 'status',
                    key: 'status',
                    width: 220,
                    render: (text, record) => {
                        /*let msg = '';
                        let progress = record.progress || 0;
                        switch (text) {
                            case 1:
                                msg = '未开始';
                                break;
                            case 2:
                                msg = '解析中';
                                break;
                            case 3:
                                progress = 100;
                                msg = '已完成';
                                break;
                            case 4:
                                msg = '解析失败';
                                break;
                            default:
                                msg = '-';
                                break;
                        }*/
                        return (<div style={{display: 'flex'}}><Progress percent={record.progress || 0}
                                                                         format={(percent, successPercent) => {
                                                                             return text === 1 ? '未开始' : text === 4 ? '解析失败' : percent + '%'
                                                                         }} strokeColor="#5B6BAE" strokeWidth={6}
                                                                         status={text === 4 ? 'exception' : text !== 3 ? 'active' : null}/>
                        </div>)
                    }
                    // render: (text,record) =>{
                    //     return (<Progress percent={text} showInfo={false} strokeColor="#5B6BAE" strokeWidth={6} />)
                    // }
                },
                {
                    title: '创建日期',
                    dataIndex: 'create_time',
                    key: 'create_time',
                    width: 100,
                    // align: 'center',
                    render: text => text || '-'
                },
                {
                    title: '修改日期',
                    dataIndex: 'update_time',
                    key: 'update_time',
                    width: 100,
                    // align: 'center',
                    render: text => text || '-'
                },
                {
                    title: '状态',
                    dataIndex: 'cStatus',
                    key: 'cStatus',
                    width: 80,
                    // align: 'center',
                    render: (text, record) => {
                        return (
                            <Spin spinning={this.state.tableSpin === record.art_id}>
                                <div className="table-action-cell" title={record.my_doc === 0 ? "他人文档无法操作" : null}>
                                    <Radio.Group onChange={(ev) => this.changeType(ev, record)} value={record.is_pub}
                                                 disabled={record.my_doc === 0}>
                                        <Radio value={1}>公开</Radio>
                                        <Radio value={0}>私密</Radio>
                                    </Radio.Group>
                                </div>
                            </Spin>
                        )
                    }
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    key: 'action',
                    width: 200,
                    // align: 'center',
                    render: (text, record) => {
                        return (
                            <Spin spinning={this.state.deleteSpin === record.art_id}>
                                <div className="table-action-cell">
                                    <span className="table-file-action"
                                          onClick={() => this.viewResearch(record)}>查看文档</span>
                                    <span
                                        className={record.my_doc === 0 ? "table-file-action disabled" : "table-file-action"}
                                        title={record.my_doc === 0 ? "他人文档无法编辑" : null}
                                        onClick={() => this.editDocInfo(record)}>编辑文档</span>
                                    <Popconfirm title={'确定要删除该文档？'} disabled={record.my_doc === 0}
                                                onConfirm={() => this.deleteFile(record)}
                                                style={{padding: 20}}>
                                        <span
                                            className={record.my_doc === 0 ? "table-file-action disabled" : "table-file-action"}
                                            title={record.my_doc === 0 ? "他人文档无法删除" : null}>删除文档</span>
                                    </Popconfirm>
                                </div>
                            </Spin>
                        )
                    }
                }
            ],
            tableData: [],
            //当前页码
            page: page,
            //页码显示数量
            pageSize: pageSize,
            //总共页码数
            pageTotal: 0,
            tableSpin: -1,
            tableLoading: false,
            addLoading: false,
            uploadLoading: false,
            addListSusBtn: true,//加入阅读列表按钮是否在可视区内
            editBtn: false,     // 批量编辑按钮是否可用
            addListBtn: false,  // 加入阅读列表按钮是否可用
            confirmLoading: false,
            articleModal: false,
            articleModalTitle: '',
            articleModalUrl: ''
        }

        this.getTableData = this.getTableData.bind(this);
        this.customRequest = this.customRequest.bind(this);

        this.viewResearch = this.viewResearch.bind(this);
        this.changeType = this.changeType.bind(this);
        //this.upLoadChange = this.upLoadChange.bind(this);
        this.pageChange = this.pageChange.bind(this);
        this.pageSizeChange = this.pageSizeChange.bind(this);
        this.deleteFile = this.deleteFile.bind(this);
        this.getProcess = this.getProcess.bind(this);
    }

    fileList = [];          // 待上传的文件列表
    tagList = [];           // 上传文件弹窗标签

    componentDidMount() {
        window.document.addEventListener("scroll", this.listerScroll.bind(this))
        this.getTableData(res => {
            this.getProcess();
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.location.search !== nextProps.location.search) {
            let searchData = nextProps.location.search || '';
            let page = 1, pageSize = 10;
            if (searchData) {
                page = JSON.parse(queryString.parse(searchData)['page'] || '1');
                pageSize = parseInt(queryString.parse(searchData)['pageSize'] || '10');
            }

            this.setState({
                page, pageSize
            }, () => {
                this.getTableData();
            })
        }
    }

    componentWillUnmount() {
        this.getProcess = null;
        clearTimeout(this.timer)
        this.timer = null;
        // 卸载异步操作设置状态
        this.setState = (state, callback) => {
            window.document.removeEventListener("scroll", this.listerScroll.bind(this))
            return;
        }
    }

    changeLocation = () => {
        let searchData = `?page=${this.state.page}&pageSize=${this.state.pageSize}`
        this.props.history.push({
            pathname: `${this.props.location.pathname}`,
            search: searchData
        })
    }

    /**监控滚动条 */
    listerScroll(event) {
        let currentTarget = event.target
        let addListBtn = currentTarget.querySelector('div#addListBtn');
        let addListSusBtn = this.state.addListSusBtn

        if (addListBtn && addListBtn.getBoundingClientRect().top < 64) {
            addListSusBtn = false
        }

        if (addListBtn && addListBtn.getBoundingClientRect().top >= 64) {
            addListSusBtn = true
        }

        this.setState({
            addListSusBtn
        })
    }

    timer = null

    /**
     * 获取表格数据
     */
    getTableData(cb) {
        let postData = {
            page: this.state.page,
            page_size: this.state.pageSize
        }
        this.setState({
            tableLoading: true
        }, () => {
            webSdk.search.docList(postData).then(res => {
                try {
                    if (!res.errCode || res.errCode === 0) {
                        if (res.list && res.list.length > 0) {
                            let allDone = true;
                            res.list.forEach(item => {
                                if (item.status === 1 || item.status === 2) {
                                    allDone = false
                                }
                            })
                            if (!allDone) {
                                this.timer = setTimeout(() => {
                                    this.getProcess()
                                }, 5000)
                            }
                        }
                        this.setState({
                            tableData: res.data,
                            pageTotal: res.total_num,
                            tableLoading: false
                        }, () => {
                            cb && cb()
                        })
                    } else {
                        this.setState({
                            tableLoading: false,
                            // tableData: [],
                            // page: 1,
                            // pageTotal: 0
                        })
                        message.error(res.errMsg || '获取文档列表失败，请稍后重试');
                    }
                } catch (e) {
                    this.setState({
                        tableLoading: false,
                        // tableData: [],
                        // page: 1,
                        // pageTotal: 0
                    })
                    message.error('获取文档列表失败，请稍后重试');
                }
            }).catch(e => {
                this.setState({
                    tableLoading: false,
                    // tableData: [],
                    // page: 1,
                    // pageTotal: 0
                })
                message.error('获取文档列表失败，请稍后重试');
            })
        })
    }

    getProcess() {
        let tableData = [...this.state.tableData];
        let postList = [];
        for (let i = 0; i < tableData.length; i++) {
            if (tableData[i].status === 1 || tableData[i].status === 2) {
                postList.push(tableData[i].art_id)
            }
        }
        if (postList.length > 0) {
            let postData = {
                art_ids: postList.join(',')
            }
            webSdk.search.documentProgress(postData).then(res => {
                try {
                    if (!res.errCode || res.errCode === 0) {
                        let list = res.progress_info || [];
                        let allDone = true;
                        for (let i = 0; i < list.length; i++) {
                            if (list[i].status === 1 || list[i].status === 2) {
                                allDone = false
                            }
                            for (let j = 0; j < tableData.length; j++) {
                                if (tableData[j].id === list[i].id) {
                                    tableData[j].progress = list[i].progress;
                                    tableData[j].status = list[i].status;
                                    break;
                                }
                            }
                        }
                        this.setState({
                            tableData
                        }, () => {
                            if (!allDone) {
                                this.timer = setTimeout(() => {
                                    this.getProcess()
                                }, 15000)
                            }
                        })
                    } else {
                        message.error(res.errMsg || '更新文档进度失败，请稍后重试');
                    }
                } catch (e) {
                    message.error('更新文档进度失败，请稍后重试');
                }
            }).catch(e => {
                message.error('更新文档进度失败，请稍后重试');
            })
        }
    }

    /**
     * 文章标题点击事件
     */
    viewResearch(item) {
        if (item.status === 3) {
            if (['doc', 'docx'].includes(item['doc_type'])) {
                this.setState({
                    articleModal: true,
                    articleModalTitle: item.name || '',
                    articleModalUrl: item.url || ''
                })
            } else {
                this.props.history.push({
                    pathname: `/researchResult/${item.type || 1}/${item.art_id}/${item.type || 6}`
                })
            }
        } else {
            let msg = '';
            switch (item.status) {
                case 1:
                    msg = '文件正在等待解析，稍后操作';
                    break;
                case 2:
                    msg = '文件正在解析中，请稍后操作';
                    break;
                case 4:
                    msg = '文件解析失败，请重新上传解析';
                    break;
                default:
                    msg = '文件正在解析中，请稍后操作';
                    break;
            }
            message.error(msg)
        }

    }

    /**
     * 表格更换‘公开’/私密 事件
     */
    changeType(ev, item) {
        let newValue = ev.target.value;
        this.setState({
            tableSpin: item.art_id
        }, () => {
            let postData = {
                doc_id: item.id,
                state: newValue
            }
            webSdk.search.turnPubState(postData).then(res => {
                if (res === undefined || !res.errCode || res.errCode === 0) {
                    item.is_pub = newValue;
                    let tableData = [...this.state.tableData];
                    this.setState({
                        tableData,
                        tableSpin: -1
                    })
                    message.success('操作成功')
                } else {
                    this.setState({
                        tableSpin: -1
                    })
                    message.error(res.errMsg || '操作失败，请稍后重试');
                }
            }).catch(e => {
                this.setState({
                    tableSpin: -1
                })
                message.error('操作失败，请稍后重试');
            })
        })

    }

    /**
     * 加入阅读列表事件
     */
    addToList() {
        let selectItem = [...this.state.selectItem];
        if (selectItem.length > 0) {
            this.setState({
                addLoading: true
            }, () => {
                let ids = [];
                for (let i = 0; i < selectItem.length; i++) {
                    ids.push(selectItem[i].id)
                }
                let postData = {
                    ids: ids.join(','),
                    type: 6
                }
                webSdk.search.addReadList(postData).then(res => {
                    if (res.result) {
                        message.success("加入阅读列表成功");
                        this.setState({
                            addLoading: false,
                            selectItem: [],
                            selectItemIndex: []
                        })
                    } else {
                        this.setState({
                            addLoading: false
                        })
                        message.error(res.errMsg || "加入阅读列表失败,请稍后重试")
                    }
                }).catch(e => {
                    this.setState({
                        addLoading: false
                    })
                    message.error("加入阅读列表失败,请稍后重试")
                })
            })
        }
    }

    editDocInfo(item) {
        let itemList = [];
        if (item) {
            if (item.my_doc === 0) return;
            itemList = [item];
        } else {
            let {selectItemIndex, tableData} = this.state;
            selectItemIndex.sort();
            selectItemIndex.forEach((item, index) => {
                itemList.push(tableData[item] || {});
            })
        }
        if (itemList.length === 0) return;
        let list = [];
        let tagList = [];
        itemList.forEach((item, index) => {
            list.push({
                id: item.id,
                art_id: item.art_id,
                name: item.name,
                type: item.doc_type,
                tags: item.doc_tags
            });
            if (index === 0) tagList = item.doc_tags;
        });
        this.fileList = list;
        this.tagList = tagList;
        return new Promise((resolve, reject) => {
            this.fileModal(list, tagList, true).then(res => {
                resolve();
            });
        })
    }

    beforeUpload(file, fileList, _this) {
        if (file.uid !== fileList[fileList.length - 1].uid) return false;
        let list = [];
        let typeFalse = false;
        fileList.forEach((item, index) => {
            let typeIndex = item.name.lastIndexOf(".");
            let fileName = item.name.substring(0, typeIndex);
            let typeName = item.name.substring(typeIndex + 1, item.name.length);
            if (!['pdf', 'zip', 'rar', 'doc', 'docx'].includes(typeName)) {
                typeFalse = true;
                return;
            }
            list.push({
                name: fileName,
                file: item,
                type: typeName,
                tags: []
            })
        });
        if (typeFalse) {
            message.error("只支持pdf、zip、rar、doc、docx文件");
            return false;
        }
        let tagList = [];
        this.fileList = list;
        this.tagList = tagList;
        return new Promise((resolve, reject) => {
            this.fileModal(list, tagList).then(() => {
                reject();
            }).catch(() => {
                reject();
            });
        })
    }

    fileModal = (list, tagList, isEdit) => {
        let _this = this;
        return new Promise(
            function (resolve, reject) {
                Modal.confirm({
                    className: "upload-modal-confirm",
                    centered: true,
                    icon: null,
                    maskStyle: {background: "transparent"},
                    confirmLoading: _this.state.confirmLoading,
                    content: <UploadModal fileList={list} tagList={tagList} tagListChange={_this.tagListChange}/>,
                    okText: '保存',
                    cancelText: '取消',
                    onOk: () => {
                        return new Promise((resolve1, reject1) => {
                            _this.customRequest(isEdit).then((res) => {
                                Modal.destroyAll();
                                resolve();
                                resolve1();
                            });
                        })
                    },
                    onCancel: () => {
                        _this.fileList = [];
                        _this.tagList = [];
                        Modal.destroyAll();
                        reject();
                    }
                })
            })
    };

    tagListChange = list => {
        this.tagList = list;
    };

    /**
     * 上传文件
     */
    customRequest(isEdit) {
        const _this = this;
        return new Promise((resolve, reject) => {
            let postData, postApi, successTips, errorTips;
            _this.setState({
                // uploadLoading: true,
                confirmLoading: true
            });
            if (!isEdit) {  // 弹窗文件上传
                _this.setState({
                    uploadLoading: true
                });
                const formData = new FormData();
                let nameList = [];
                _this.fileList.forEach((item, index) => {
                    nameList.push(item.name);
                    formData.append('file_list', item.file)
                });
                formData.append("name_list", JSON.stringify(nameList));
                formData.append("tag_list", JSON.stringify(_this.tagList));
                postData = formData;
                postApi = webSdk.search.uploadFile;
                successTips = "上传成功";
                errorTips = "上传失败";
            } else {        // 弹窗文档信息修改
                postData = [];
                _this.fileList.forEach((item, index) => {
                    postData.push({
                        art_id: item.art_id,
                        title: item.name,
                        tags: _this.tagList
                    })
                });
                postApi = webSdk.search.docEdit;
                successTips = "保存成功";
                errorTips = "保存失败";
            }
            postApi(postData).then(res => {
                this.setState({
                    confirmLoading: false,
                    uploadLoading: false
                });
                if (!res.errCode || res.errCode === 0) {
                    message.success(successTips);
                    if (isEdit) {
                        this.getTableData(res => {
                            clearTimeout(this.timer);
                            this.timer = null;
                            this.timer = setTimeout(() => {
                                this.getProcess()
                            }, 15000)
                            _this.topBtnConfig();
                        });
                        resolve();
                    } else {
                        this.setState({
                            page: 1,
                            selectItemIndex: [],
                            selectItem: []
                        }, () => {
                            this.getTableData(res => {
                                clearTimeout(this.timer);
                                this.timer = null;
                                this.timer = setTimeout(() => {
                                    this.getProcess()
                                }, 15000)
                            });
                            resolve();
                        })
                    }
                } else {
                    message.error(res.errMsg || errorTips);
                    reject();
                }
            }).catch(e => {
                this.setState({
                    confirmLoading: false,
                    uploadLoading: false
                });
                message.error(errorTips);
                reject();
            })
        })

    }

    /**
     * 页码变更
     */
    pageChange(page, pageSize) {
        this.setState({
            page,
            selectItemIndex: [],
            selectItem: []
        }, () => {
            this.changeLocation()
        })
    }

    /**
     * 页尺寸变更
     */
    pageSizeChange(current, size) {
        this.setState({
            page: 1,
            pageSize: size,
            selectItemIndex: [],
            selectItem: []
        }, () => {
            this.changeLocation()
        })
    }

    /**
     * 删除按钮事件
     */
    deleteFile(item) {
        if (item.my_doc === 0) return;
        this.setState({
            deleteSpin: item.art_id
        }, () => {
            let postData = {
                art_id: item.art_id
            }
            webSdk.search.docDelete(postData).then(res => {
                if (res === undefined || !res.errCode || res.errCode === 0) {
                    this.setState({
                        deleteSpin: -1,
                        selectItemIndex: [],
                        selectItem: []
                    }, () => {
                        this.getTableData();
                    })
                    message.success('删除成功')
                } else {
                    this.setState({deleteSpin: -1})
                    message.error(res ? res.errMsg || '删除失败，请稍后重试' : '删除失败，请稍后重试');
                }
            }).catch(e => {
                this.setState({deleteSpin: -1})
                message.error('删除失败，请稍后重试');
            })
        })

    }

    topBtnConfig = list => {
        let editBtn = true;
        let addListBtn = true;
        let forList = [];
        if (list) {
            forList = list;
        } else {
            let {selectItemIndex, tableData} = this.state;
            selectItemIndex.sort();
            selectItemIndex.forEach((item, index) => {
                forList.push(tableData[item])
            })
        }
        if (forList.length === 0) {
            editBtn = false;
            addListBtn = false;
        } else if (forList.length === 1) {
            if (forList[0].my_doc === 0) editBtn = false;
            if (forList[0].status !== 3) addListBtn = false;
        } else if (forList.length > 1) {
            let firstTags = [];
            for (let i = 0; i < forList.length; i++) {
                if (i === 0) {
                    firstTags = forList[i].doc_tags || [];
                } else {
                    if (forList[i].my_doc === 0) editBtn = false;
                    if (JSON.stringify(forList[i].doc_tags || []) !== JSON.stringify(firstTags)) {
                        editBtn = false;
                    }
                    if (forList[i].status !== 3) addListBtn = false;
                }
            }
        }
        this.setState({
            editBtn, addListBtn
        })
    };

    setArticleModal = status => {
        this.setState({articleModal: status})
    };

    render() {
        const rowSelection = {
            selectedRowKeys: this.state.selectItemIndex,
            columnWidth: 50,
            onChange: (selectedRowKeys, selectedRows) => {
                this.topBtnConfig(selectedRows);
                this.setState({
                    selectItemIndex: selectedRowKeys,
                    selectItem: selectedRows
                })
            },
            /*getCheckboxProps: (record, i, a,) => ({
                disabled: record.status !== 3 // Column configuration not to be checked
            }),*/
        };
        const {
            articleModal, articleModalTitle, articleModalUrl
        } = this.state;

        return (
            <div className="file-manage">
                <div className="file-manage-btn">
                    <span>文档管理</span>
                    <div>
                        <span
                            className={!this.state.addListSusBtn && this.state.selectItem.length > 0 ? "add-btn-suspend add-btn" : "add-btn"}>
                            <Button type="primary" loading={this.state.addLoading}
                                    disabled={!this.state.addListBtn} onClick={this.addToList.bind(this)}
                                    className="add-btn-button">
                                加入阅读列表<Icon type="plus"/></Button>
                        </span>
                        <span className="edit-btn">
                            <Button disabled={!this.state.editBtn} onClick={() => this.editDocInfo()}>编辑标签</Button>
                        </span>
                        <span className="upload-btn" style={{marginRight: 20}}>
                            <Upload beforeUpload={(file, fileList) => this.beforeUpload(file, fileList, this)}
                                // customRequest={this.customRequest}
                                    openFileDialogOnClick={!this.state.uploadLoading}
                                    showUploadList={false}
                                    fileList={this.state.fileList} multiple={true}>
                                <Button className="upload-txt" type="primary"
                                        loading={this.state.uploadLoading}>上传</Button>
                            </Upload>
                        </span>
                    </div>
                </div>

                <div className="file-manege-main">
                    <div className="file-manage-table">
                        <Spin spinning={this.state.tableLoading}>
                            <Table
                                columns={this.state.columns}
                                dataSource={this.state.tableData}
                                rowSelection={rowSelection}
                                rowClassName={(record, index) => index % 2 === 0 ? 'single-row report-row' : 'double-row report-row'}
                                rowKey={(record, index) => {
                                    return index
                                }}
                                pagination={{
                                    showSizeChanger: true,
                                    showQuickJumper: true,
                                    pageSizeOptions: ["10", "15", "20", "25"],
                                    current: this.state.page,
                                    pageSize: this.state.pageSize,
                                    total: this.state.pageTotal,
                                    onChange: (page, pageSize) => this.pageChange(page, pageSize),
                                    onShowSizeChange: (current, size) => this.pageSizeChange(current, size),
                                    showTotal: total => `共 ${total} 条`
                                }}
                            />
                        </Spin>
                    </div>
                </div>

                <ViewArticleModal articleModal={articleModal} setArticleModal={this.setArticleModal}
                                  articleModalTitle={articleModalTitle} articleModalUrl={articleModalUrl}/>
            </div>
        )
    }
}

export default FileManage
