/*
 * @Description:
 * @LastEditTime: 2022-09-27 10:21:08
 * @LastEditors: 符春玲 fuchunling@perfectcode.tech
 */
import React, { PureComponent } from 'react'
import './index.scss'
import AppContext from '../../../AppContext'
import Breadcrumb from '../../common/Breadcrumb/Breadcrumb'
import stores from '../../../store'
import _ from 'lodash'
import { webSdk } from '../../../api/WebSdk'
import { Spin, Table, Input, message } from 'antd'
import MySearch from '../../common/MySearch/MySearch'
import moment from 'moment';
const { Search } = Input
class BusinessDatabase extends PureComponent {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {
      businessParamsStoreKey: 'businessPageParams',
      loading: false,
      keyword: '',
      tableData: [],
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0
      },
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          render: (text, record, index) => `${index + 1}`,
          align: 'center',
          width: 100
        },
        {
          title: '公司名称',
          dataIndex: 'name',
          key: 'name',
          render: (text) => text || '-'
        },
        {
          title: '法定代表人',
          dataIndex: 'juridical_person',
          key: 'juridical_person',
          render: (text) => text || '-'
        },
        {
          title: '注册地',
          dataIndex: 'registry_addr',
          key: 'registry_addr',
          render: (text) => text || '-'
        },
        {
          title: '注册资本',
          dataIndex: 'registered_capital',
          key: 'registered_capital',
          render: (text) => text || '-'
        },
        {
          title: '注册时间',
          dataIndex: 'establishment_day',
          key: 'establishment_day',
          render: (text) => moment(parseInt(text) ).format("YYYY-MM-DD") || '-'
        }
      ]
    }
  }

  componentDidMount() {
    stores.businessStore.setPageInfo(
      [
        {
          label: '工商数据库',
          key: '/businessDatabase'
        }
      ],
      'list'
    )
    this.init()
  }
  componentWillUnmount() {
    let nextPath = this.context.getHistory().location.pathname
    if (nextPath.indexOf('/businessDatabaseDetail') === -1) {
      this.setLocStorage(this.state.businessParamsStoreKey, '')
    }

    this.setState = (state, callback) => {
      return
    }
  }
  /** 在localStorage中保存值
   * @param {string} key 保存的键名
   * @param {object} obj 保存的对象
   */
  setLocStorage(key, obj) {
    let objJSON = ''
    if (!_.isEmpty(obj)) objJSON = JSON.stringify(obj)
    window.localStorage.setItem(key, objJSON)
  }

  /** 从localStorage中获取值
   * @param {string} key 键名
   */
  getLocStorage(key) {
    let objStr = window.localStorage.getItem(key)
    if (!objStr) return null
    return JSON.parse(objStr)
  }
  init() {
    let pageParams = this.getLocStorage(this.state.businessParamsStoreKey)
    if (pageParams != null) {
      let keyword = pageParams.keyword
      let pagination = pageParams.pagination

      this.setState(
        {
          keyword,
          pagination
        },
        () => {
          this.getBusinessList()
        }
      )
    } else {
      this.getBusinessList()
    }
  }
  // 搜索关键词

  searchByKeyword = (keyword) => {
    let { pagination } = this.state
    this.setState({
      loading: true,
      keyword
    },() => {
        webSdk.BusinessDatabaseApi.getInfoByName({
          pageNum: pagination.page,
          pageSize: pagination.pageSize,
          keyword
        }).then((res) => {
          let resTotal = res.data.total
          let newPagination = {
            page: pagination.page,
            pageSize: pagination.pageSize,
            total: resTotal,
          };
          this.setState({
            loading: false,
            tableData: res.data.businessRegistrationInfo,
            pagination: newPagination
          })
        })
        .catch((e) => {
          this.setState({
            loading: false
          })
        })
    })
  }

  handlePageNum(newPage, newPageSize) {
    let { pageSize, total } = this.state.pagination
    let pagination = {
        page: newPage,
        pageSize: pageSize,
        total
    }
    this.setState({
        pagination
    },() => {
      if(this.state.keyword !== ''){
        this.searchByKeyword(this.state.keyword)
      }else{
        this.getBusinessList()
      }
    })
  };

  handlePageSize(newPage, newPageSize) {
    let { total } = this.state.pagination
    let pagination = {
        page: 1,
        pageSize: newPageSize,
        total
    }
    this.setState({
        pagination
    },() => {
      if(this.state.keyword !== ''){
        this.searchByKeyword(this.state.keyword)
      }else{
        this.getBusinessList()
      }
    })
  };

  getBusinessList() {
    let { pagination, keyword } = this.state
    this.setState(
      {
        loading: true
      },
      () => {
        webSdk.BusinessDatabaseApi.getBusinessList({
          pageNum: pagination.page,
          pageSize: pagination.pageSize,
          keyword
        })
          .then((res) => {
            let resTotal = res.data.total
            let newPagination = {
              page: pagination.page,
              pageSize: pagination.pageSize,
              total: resTotal,
            };
            this.setState({
              loading: false,
              tableData: res.data.businessRegistrationInfo,
              pagination: newPagination
            })
          })
          .catch((e) => {
            message.error('请求出错');
            let newPagination = {
              page: 1,
              pageSize: 10,
              total: 0,
            };
            this.setState({
              loading: false,
              pagination: newPagination
            })
          })
      }
    )
  }

  searchChange(event) {
    let keyword = event.currentTarget.value
    this.setState(
      {
        keyword
      },
      () => {
        if (keyword === '') {
          this.getBusinessList()
        }
      }
    )
  }
  handleRowDetail(record) {
    this.savePageParams2Storage(record)
    this.props.history.push({
      pathname: `/businessDatabaseDetail/${record.id}`
    })
  }
  /**保存页面参数到localStorage */
  savePageParams2Storage(businessItem) {
    let corp_id = businessItem.id
    let businessName = businessItem.name
    let code = businessItem.stock_symbol
    let image = businessItem.image
    let registered_capital = businessItem.registered_capital
    let juridical_person = businessItem.juridical_person
    let establishment_day = businessItem.establishment_day
    let key_personnels = businessItem.key_personnels
    let history_changes = businessItem.history_changes
    let share_holders = businessItem.share_holders
    let { pagination, filterDate, keyword } = this.state
    //保存页面参数到localStorage中
    let pageParams = {
      //页面参数
      pagination,
      filterDate,
      keyword,
      corp_id,
      businessName,
      code,
      image,
      registered_capital,
      juridical_person,
      establishment_day,
      key_personnels,
      history_changes,
      share_holders
    }
    this.setLocStorage(this.state.businessParamsStoreKey, pageParams)
  }
  render() {
    let { loading, keyword, columns, tableData, pagination } = this.state
    return (
      <div className="business-page">
        <div className="business-head">
          <Breadcrumb
            history={this.history}
            crumbList={stores.businessStore.crumbList.slice()}
          />
          <div className="business-filter">
            <MySearch searchByKeyword={this.searchByKeyword} />
            {/* <div className="business-filter-item business-filter-keyword">
                  <span>搜索</span>
                  <Search
                      placeholder="请输入关键词"
                      onSearch={this.searchKeyword.bind(this)}
                      onChange={this.searchChange.bind(this)}
                      onPressEnter={this.searchKeyword.bind(this)}
                      style={{ width: 200 }}
                      value={keyword}
                  />
            </div> */}
          </div>
        </div>
        <div className="business-list">
          <Spin spinning={loading}>
            <div>
              <Table
                className="business-table"
                columns={columns}
                dataSource={tableData}
                rowClassName={(record, index) =>
                  index % 2 === 0 ? 'single-row' : 'double-row'
                }
                rowKey={(record, index) => {
                  return index
                }}
                onRow={(record) => {
                  return {
                    onClick: (event) => {
                      this.handleRowDetail(record)
                    } // 点击行
                  }
                }}
                pagination={{
                  showSizeChanger: true,
                  showQuickJumper: true,
                  pageSizeOptions: ['10', '15', '20', '25'],
                  current: pagination.page,
                  pageSize: pagination.pageSize,
                  total: pagination.total,
                  onChange: (page, pageSize) =>
                    this.handlePageNum(page, pageSize),
                  onShowSizeChange: (current, size) =>
                    this.handlePageSize(current, size),
                  showTotal: (total) => `共 ${total} 条`
                }}
              />
            </div>
          </Spin>
        </div>
      </div>
    )
  }
}

export default BusinessDatabase
