import React from 'react'
import '../Index.scss'
import { DatePicker, Table, Button, Icon, Spin, message, List, Checkbox } from 'antd'
import { webSdk } from "../../../api/WebSdk";
import { MultiCascader } from 'rsuite';
import 'rsuite/dist/styles/rsuite.min.css'
import queryString from 'query-string'
import moment from 'moment';

const { RangePicker } = DatePicker;
const CheckboxGroup = Checkbox.Group;


//向上查找组织树数据
function formatSelectData(id, initData, resultData, currentArr) {

    for (let i = 0; i < initData.length; i++) {
        if (initData[i].value === id) {
            let newArr = []
            if (currentArr && currentArr.length > 0) {
                newArr = [...currentArr]
            }
            newArr.push(id);
            resultData.push(newArr)
            break;
        } else {
            if (initData[i].children && initData[i].children.length > 0) {
                let newArr = []
                if (currentArr && currentArr.length > 0) {
                    newArr = [...currentArr]
                }
                newArr.push(initData[i].value);
                formatSelectData(id, initData[i].children, resultData, newArr)
            }
        }
    }
}

//删除子集
function removeArrChild(result) {
    let copyResult = [...result];
    for (let i = 0; i < result.length; i++) {
        for (let j = 0; j < copyResult.length; j++) {
            let ri = result[i].join(',');
            let rj = copyResult[j].join(',');
            if (ri !== rj) {
                let flag = false;
                flag = copyResult[j].every(val => result[i].includes(val))
                if (flag) {
                    result.splice(i, 1);
                    i = i - 1;
                    break;
                }
            }
        }
    }
}

let labelSelect = '[]';             //当前分类请求数据，用于刷新页面的请求

class GatheringDetailList extends React.PureComponent {

    constructor(props) {
        super(props)

        let searchData = props.location.search || '';
        let page = 0,
            pageSize = 10,
            labelVal = [],
            labelValType = '1',
            date = [],
            showDate = [],
            labelSelect = '[]'
        if (searchData) {
            page = JSON.parse(queryString.parse(searchData)['page'] || '0');
            pageSize = parseInt(queryString.parse(searchData)['pageSize'] || '10');
            labelVal = JSON.parse(queryString.parse(searchData)['labelVal'] || '[]');
            labelValType = queryString.parse(searchData)['labelValType'] || '1';
            labelSelect = queryString.parse(searchData)['labelSelect'] || '[]';
            date = JSON.parse(queryString.parse(searchData)['date'] || '[]');
            if (date.length > 0) {
                date.forEach(item => {
                    item.replace(/-/g, '.')
                })
                showDate = [moment(date[0], 'YYYY.MM.DD'), moment(date[1], 'YYYY.MM.DD')]
            }
        }

        this.state = {
            keyword: props.match.params.keyword || '',
            modelSwitch: true,//模式开关：true列表模式，false摘要模式     
            listSelectItem: [],//列表选择
            timeValue: showDate,
            //日期
            dateFilter: {
                title: '日期',
                titleKey: 'dateFilter',
                value: date
            },
            //标签筛选
            labelFilter: {
                title: '标签筛选',
                titleKey: 'labelFilter',
                selectData: [],
                value: labelVal
            },
            //标签筛选下拉数据
            labelList: [],
            tableLoading: false,
            columns: [
                {
                    title: '序号',
                    dataIndex: 'index',
                    render: (text, record, index) => `${index + 1}`,
                    align: 'center',
                    width: 70
                },
                {
                    title: '文章标题',
                    dataIndex: 'title',
                    key: 'title',
                    render: text => text || '-'
                },
                {
                    title: '来源',
                    dataIndex: 'source',
                    key: 'source',
                    width: 120,
                    render: text => text || '-'
                },
                {
                    title: '作者',
                    dataIndex: 'author',
                    key: 'author',
                    width: 150,
                    render: text => text || '-'
                },
                {
                    title: '发布日期',
                    dataIndex: 'date',
                    key: 'date',
                    width: 150,
                    render: text => text || '-'
                },
                {
                    title: '操作',
                    dataIndex: 'view',
                    key: 'view',
                    width: 90,
                    align: 'center',
                    render: (text, record) => {
                        return (
                            <div className="table-action-cell" onClick={() => this.viewClick(record)}>
                                查看{/*<Icon style={{marginLeft: 5}} type="eye"/>*/}
                            </div>
                        )
                    }
                }
            ],
            tableData: [],
            page: page,
            pageSize: pageSize,
            pageTotal: 0,
            selectItem: [],          //当前选中的对象
            addListSusBtn: true,//加入阅读列表按钮是否在可视区内
            labelLoading: false,
            addLoading: false,
            addGatherLoading: false,//加入数据集状态
            selections: []
        }

        this.getTableData = this.getTableData.bind(this);
        this.getLabelList = this.getLabelList.bind(this);

        this.changeTime = this.changeTime.bind(this);
        this.pageChange = this.pageChange.bind(this);
        this.pageSizeChange = this.pageSizeChange.bind(this);
        this.viewClick = this.viewClick.bind(this);

        this.changeMulSelect = this.changeMulSelect.bind(this)
        this.mulSelectSearch = this.mulSelectSearch.bind(this)

        this.fetch = this.fetch.bind(this);
    }

    componentDidMount() {
        this.props.fnSetType('research', this.state.keyword || '')
        window.document.addEventListener("scroll", this.listerScroll.bind(this))
        this.getTableData();
        this.getLabelList()
        let icons = document.querySelectorAll('.rs-picker-toggle-caret')
        icons.forEach(item => {
            item.innerHTML = `<i aria-label="图标: down" style="color: rgba(0, 0, 0, 0.25);transform: scale(0.8576)" class="anticon anticon-down ant-select-arrow-icon"><svg viewBox="64 64 896 896" class="" data-icon="down" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path></svg></i>`
        })
    }

    componentWillReceiveProps(nextProps) {
        let pathname = nextProps.location.pathname;
        if (this.props.location.search !== nextProps.location.search || this.props.location.pathname !== pathname) {
            let searchData = nextProps.location.search || '';
            let npage = 0,
                npageSize = 10,
                nlabelVal = [],
                ndate = [],
                nshowDate = [],
                labelSelect = '[]';
            if (searchData) {
                npage = JSON.parse(queryString.parse(searchData)['page'] || '0');
                npageSize = parseInt(queryString.parse(searchData)['pageSize'] || '10');
                nlabelVal = JSON.parse(queryString.parse(searchData)['labelVal'] || '[]');
                labelSelect = queryString.parse(searchData)['labelSelect'] || '[]';
                ndate = JSON.parse(queryString.parse(searchData)['date'] || '[]');
                if (ndate.length > 0) {
                    ndate.forEach(item => {
                        item.replace(/-/g, '.')
                    })
                    nshowDate = [moment(ndate[0], 'YYYY.MM.DD'), moment(ndate[1], 'YYYY.MM.DD')]
                }
            }
            let { dateFilter, labelFilter, page, pageSize } = this.state;
            dateFilter.value = ndate;
            labelFilter.value = nlabelVal;
            page = npage;
            pageSize = npageSize;

            this.setState({
                timeValue: nshowDate,
                dateFilter, labelFilter, page, pageSize
            }, () => {
                this.getTableData();
            })
        }
    }

    componentWillUnmount() {
        // 卸载异步操作设置状态
        this.setState = (state, callback) => {
            window.document.removeEventListener("scroll", this.listerScroll.bind(this))
            return;
        }
    }

    changeLocation = () => {
        let labelValSelect = [...this.state.labelFilter.value];
        let result = [];
        if (labelValSelect.length > 0) {
            if (this.state.labelList.length > 0) {
                for (let i = 0; i < labelValSelect.length; i++) {
                    formatSelectData(labelValSelect[i], this.state.labelList, result)
                }
                removeArrChild(result)
            }
        }
        let labelSelect = JSON.stringify(result)
        console.log(labelSelect)

        let searchData = '';
        let date = [];
        let dateFilter = { ...this.state.dateFilter };
        if (dateFilter.value.length === 2 && dateFilter.value[0] && dateFilter.value[1]) {
            date = [(dateFilter.value[0] || '').replace(/\./g, '-'), (dateFilter.value[1] || '').replace(/\./g, '-')]
        }
        searchData = `?page=${this.state.page}&pageSize=${this.state.pageSize}&labelVal=${JSON.stringify(labelValSelect)}&labelSelect=${labelSelect}&date=${JSON.stringify(date)}`
        /*searchData = `?page=${this.state.page}&pageSize=${this.state.pageSize}&labelVal=${JSON.stringify(labelValSelect)}&labelSelect=${labelSelect}&date=${JSON.stringify(date)}`*/
        this.props.history.push({
            pathname: `${this.props.location.pathname}`,
            search: searchData
        })
    }

    /**监控滚动条 */
    listerScroll(event) {
        let currentTarget = event.target
        let addListBtn = currentTarget.querySelector('div#addListBtn');
        let addListSusBtn = this.state.addListSusBtn

        if (addListBtn && addListBtn.getBoundingClientRect().top < 120) {
            addListSusBtn = false
        }

        if (addListBtn && addListBtn.getBoundingClientRect().top >= 120) {
            addListSusBtn = true
        }

        this.setState({
            addListSusBtn
        })
    }

    //获取标签
    getLabelList() {
        this.setState({
            labelList: [],
            labelLoading: true
        }, () => {
            let postData = {
                keyword: this.state.keyword,
                type: 1
            }
            webSdk.search.getClassificationList(postData).then(res => {
                try {
                    if (!res.errCode || res.errCode === 0) {
                        for (let i = 0; i < res.length; i++) {
                            renameData(res[i]);
                        }
                        this.setState({
                            labelList: res,
                            labelLoading: false
                        })
                    } else {
                        this.setState({
                            labelLoading: false
                        })
                        message.error(res.errMsg || "获取标签列表失败")
                    }
                } catch (e) {
                    this.setState({
                        labelLoading: false
                    })
                    console.log(e)
                    message.error("获取标签列表失败")
                }
            }).catch(e => {
                this.setState({
                    labelLoading: false
                })
                message.error("获取标签列表失败")
            })
        })

        function renameData(data) {
            data.label = data.text;
            delete data.text;
            if (data.child && data.child.length > 0) {
                data.children = data.child;
                delete data.child;
                for (let i = 0; i < data.children.length; i++) {
                    renameData(data.children[i])
                }
            }
        }
    }

    /**
     * 获取表格信息
     */
    getTableData() {
        let postData = {
            type: 1,
            keyword: this.state.keyword,
            pageSize: this.state.pageSize,
            pageIndex: this.state.page,
            startdate: (this.state.dateFilter.value[0] || '').replace(/\./g, '-'),
            enddate: (this.state.dateFilter.value[1] || '').replace(/\./g, '-'),
            labelVal: labelSelect
        }

        this.setState({
            tableLoading: true
        }, () => {
            webSdk.search.researchReport(postData).then(res => {
                try {
                    if (!res.errCode || res.errCode === 0) {
                        let pageData = res.pager;
                        let page = pageData.index,
                            pageTotal = pageData.total,
                            tableData = res.reportList;
                        for (let i = 0; i < tableData.length; i++) {
                            //let abstract = tableData[i].sumary || '';
                            let abstract = tableData[i].sumary || tableData[i].abstract || '';
                            let newAbstract = abstract.split('。');
                            if (newAbstract.length > 3) {
                                abstract = newAbstract.splice(0, 3).join('。') + '...'
                            }
                            tableData[i].abstract = abstract
                        }
                        this.setState({
                            page, pageTotal, tableData,
                            tableLoading: false
                        })
                    } else {
                        this.setState({
                            tableLoading: false
                        })
                        message.error(res.errMsg || '获取研报列表失败，请稍后重试');
                    }
                } catch (e) {
                    this.setState({
                        tableLoading: false
                    })
                    message.error('获取研报列表失败，请稍后重试');
                }
            }).catch(e => {
                this.setState({
                    tableLoading: false
                })
                message.error('获取研报列表失败，请稍后重试');
            })
        })
    }

    /**筛选日期 */
    changeTime(date, dateString) {
        let dateFilter = { ...this.state.dateFilter };
        let timeValue = date;
        dateFilter.value = dateString;
        this.setState({
            timeValue,
            dateFilter,
            page: 0
        }, () => {
            this.changeLocation()
        })
    }

    /**加入阅读列表事件 */
    addToList() {
        let selectItem = [...this.state.selectItem];
        if (selectItem.length > 0) {
            this.setState({
                addLoading: true
            }, () => {
                let ids = [];
                for (let i = 0; i < selectItem.length; i++) {
                    ids.push(selectItem[i].id)
                }
                let postData = {
                    ids: ids.join(','),
                    type: 1
                }
                webSdk.search.addReadList(postData).then(res => {
                    if (res.result) {
                        message.success("加入阅读列表成功");
                        this.setState({
                            addLoading: false,
                            selectItem: [],
                            selections: []
                        })
                    } else {
                        this.setState({
                            addLoading: false
                        })
                        message.error(res.errMsg || "加入阅读列表失败,请稍后重试")
                    }
                }).catch(e => {
                    this.setState({
                        addLoading: false
                    })
                    message.error("加入阅读列表失败,请稍后重试")
                })
            })
        }
    }

    /**加入数据集 */
    addToDataGather() {
        let selectItem = [...this.state.selectItem];
        if (selectItem.length > 0) {
            this.setState({
                addGatherLoading: true
            }, () => {
                let ids = [];
                for (let i = 0; i < selectItem.length; i++) {
                    ids.push(selectItem[i].id)
                }

                message.success("加入数据集成功");
                // let postData = {
                //     ids: ids.join(','),
                //     type: 1
                // }
                // webSdk.search.addReadList(postData).then(res => {
                //     if (res.result) {
                //         message.success("加入阅读列表成功");
                //         this.setState({
                //             addLoading: false,
                //             selectItem: [],
                //             selections: []
                //         })
                //     } else {
                //         this.setState({
                //             addLoading: false
                //         })
                //         message.error(res.errMsg || "加入阅读列表失败,请稍后重试")
                //     }
                // }).catch(e => {
                //     this.setState({
                //         addLoading: false
                //     })
                //     message.error("加入阅读列表失败,请稍后重试")
                // })
            })
        }
    }

    /**页码变更 */
    pageChange(page, pageSize) {
        this.setState({
            page: page - 1
        }, () => {
            this.changeLocation()
        })
    }

    /**页尺寸变更 */
    pageSizeChange(current, size) {
        this.setState({
            page: 0,
            pageSize: size
        }, () => {
            this.changeLocation()
        })
    }

    /**表格查看事件 */
    viewClick(record) {
        let selectType = 1
        this.props.history.push({
            pathname: `/researchResult/${selectType}/${record.id}/${selectType}`
        })
    }

    //列表 阅读全文 点击事件
    viewClickList(id) {
        this.viewClick({ id: id })
    }

    industryHasChange = false

    changeMulSelect(valueList) {
        if (!this.industryHasChange) {
            this.industryHasChange = true;
            let labelFilter = { ...this.state.labelFilter };
            labelFilter.value = valueList;
            this.setState({
                labelFilter
            })
        } else {
            this.industryHasChange = false;
            let labelFilter = { ...this.state.labelFilter };
            labelFilter.value = valueList;
            this.setState({
                labelFilter,
                page: 0
            }, () => {
                this.changeLocation();
            })
        }
    }

    mulSelectSearch() {
        if (this.industryHasChange) {
            this.setState({
                page: 0
            }, () => {
                this.changeLocation();
            })

            this.industryHasChange = false;
        }
    }

    clearIndustry() {
        this.industryHasChange = true;
    }

    //改变模式开关
    changeModelSwitch() {
        let modelSwitch = !this.state.modelSwitch
        this.setState({
            modelSwitch
        })
    }

    //列表 多选框 事件
    changeSelectList(checkedValues) {
        this.setState({
            selectItem: checkedValues
        })
    }

    timeout = null

    fetch(value, callback) {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
        this.currentValue = value;

        function fake() {
            //请求下拉数据
            setTimeout(() => {
                let data = [
                    // {
                    //     label: '下拉数据一',
                    //     value: '1'
                    // },
                    // {
                    //     label: '下拉数据二',
                    //     value: '2'
                    // },
                    // {
                    //     label: '下拉数据三',
                    //     value: '3'
                    // },
                    // {
                    //     label: '下拉数据四',
                    //     value: '4'
                    // }
                ];
                callback(data);
            }, 800)
            // const str = querystring.encode({
            //     code: 'utf-8',
            //     q: value,
            // });
            // jsonp(`https://suggest.taobao.com/sug?${str}`)
            //     .then(response => response.json())
            //     .then(d => {
            //         if (currentValue === value) {
            //             const {result} = d;
            //             const data = [];
            //             result.forEach(r => {
            //                 data.push({
            //                     value: r[0],
            //                     text: r[0],
            //                 });
            //             });
            //             callback(data);
            //         }
            //     });
        }

        this.timeout = setTimeout(fake, 300);
    }

    render() {
        const rowSelection = {
            columnWidth: 50,
            selectedRowKeys: this.state.selections,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selections: selectedRowKeys,
                    selectItem: selectedRows
                })
            },
        };

        return (
            <div className="home-page__research-library">
                <div className="company-list__industry-screen__main">
                    <div className="company-list__industry-screen__item">
                        <div
                            className="company-list__industry-screen__item-label">{this.state.labelFilter.title}</div>
                        <div className="company-list__industry-screen__item-input">
                            <MultiCascader cascade={false}
                                onChange={(vals, ev) => {
                                    console.log('change')
                                    this.changeMulSelect(vals)
                                }}
                                onClose={() => {
                                    console.log('close')
                                    this.mulSelectSearch()
                                }}
                                onClean={(ev) => {
                                    console.log('clear')
                                    this.clearIndustry()
                                }}
                                placeholder="查找"
                                style={{ width: 160 }}
                                menuClassName="mult-select-menu"
                                data={this.state.labelList}
                                value={this.state.labelFilter.value}
                                menuWidth={160}
                                block />
                        </div>
                    </div>

                    <div className="company-list__industry-screen__item">
                        <div
                            className="company-list__industry-screen__item-label">{this.state.dateFilter.title}</div>
                        <div className="company-list__industry-screen__item-input">
                            <RangePicker style={{ width: 160, height: 30 }} format={'YYYY.MM.DD'}
                                placeholder={['年-月-日', '年-月-日']}
                                onChange={(date, dateString) => this.changeTime(date, dateString)}
                                value={this.state.timeValue} />
                        </div>
                    </div>
                </div>

                <div className="company-list__table-main pt20">
                    <div className={!this.state.addListSusBtn && this.state.selectItem.length > 0 ? "table-main-list year-add-btn-suspend" : "table-main-list"}>
                        <Button type="primary"
                            loading={this.state.addLoading}
                            disabled={this.state.selectItem.length === 0}
                            onClick={this.addToList.bind(this)}
                            style={{ height: 30, padding: "0 15px", fontSize: 12, verticalAlign: "middle" }}>
                            加入阅读列表<Icon type="plus" />
                        </Button>&nbsp;
                        <Button type="primary"
                            loading={this.state.addLoading}
                            disabled={this.state.selectItem.length === 0}
                            onClick={this.addToDataGather.bind(this)}
                            style={{ height: 30, padding: "0 15px", fontSize: 12 }}>
                            加入数据集<Icon type="plus" />
                        </Button>
                    </div>
                    <div id="addListBtn" style={{ width: "10px", height: "20px", display: "inline-block", }} />

                    <div className="table-main-model">
                        <Button type="primary"
                            onClick={this.changeModelSwitch.bind(this)}
                            style={{ width: 88, height: 30, padding: 0, fontSize: 12 }}>
                            {this.state.modelSwitch ? "摘要模式" : "列表模式"}
                            <Icon type="appstore" />
                        </Button>
                    </div>
                    <Spin spinning={this.state.tableLoading}>
                        <div>
                            {
                                this.state.modelSwitch ?
                                    (
                                        <Table
                                            columns={this.state.columns}
                                            dataSource={this.state.tableData}
                                            rowSelection={rowSelection}
                                            rowClassName={(record, index) => index % 2 === 0 ? 'single-row' : 'double-row'}
                                            rowKey={(record, index) => {
                                                return index
                                            }}
                                            /*onRow={(record, index) => {
                                                return {
                                                    onClick: ev => this.viewClick(record)
                                                }
                                            }}*/
                                            pagination={{
                                                showSizeChanger: true,
                                                showQuickJumper: true,
                                                pageSizeOptions: ["10", "15", "20", "25"],
                                                current: this.state.page + 1,
                                                pageSize: this.state.pageSize,
                                                total: this.state.pageTotal,
                                                onChange: (page, pageSize) => this.pageChange(page, pageSize),
                                                onShowSizeChange: (current, size) => this.pageSizeChange(current, size),
                                                showTotal: total => `共 ${total} 条`
                                            }}
                                        />
                                    ) :
                                    (
                                        <CheckboxGroup
                                            onChange={this.changeSelectList.bind(this)}
                                        >
                                            <List
                                                className="summary-list"
                                                itemLayout="vertical"
                                                size="large"
                                                pagination={{
                                                    showSizeChanger: true,
                                                    showQuickJumper: true,
                                                    pageSizeOptions: ["10", "15", "20", "25"],
                                                    current: this.state.page + 1,
                                                    pageSize: this.state.pageSize,
                                                    total: this.state.pageTotal,
                                                    onChange: (page, pageSize) => this.pageChange(page, pageSize),
                                                    onShowSizeChange: (current, size) => this.pageSizeChange(current, size),
                                                    showTotal: total => `共 ${total} 条`
                                                }}
                                                dataSource={this.state.tableData}
                                                renderItem={(item, idx) => (
                                                    <List.Item
                                                        key={idx}
                                                        className="summary-list-item"
                                                    >
                                                        <Checkbox
                                                            className="summary-list-chk"
                                                            options={this.state.listSelectItem}
                                                            value={item}
                                                        >
                                                            <span className="summary-chk-title">{item.title}</span>
                                                        </Checkbox>

                                                        <ul className="summary-chk-label">
                                                            {
                                                                item.artTags && item.artTags.length > 0 ? item.artTags.map(list => {
                                                                    return (
                                                                        <li key={list.id}>{list.name}</li>
                                                                    )
                                                                }) : null
                                                            }
                                                        </ul>

                                                        <p className="summary-chk-content">{item.abstract}</p>

                                                        <ul className="summary-chk-annotation">
                                                            <li>{item.source}</li>
                                                            <li>{item.author}</li>
                                                            <li>{item.date}</li>
                                                            <li><a
                                                                onClick={this.viewClickList.bind(this, item.id)}>阅读全文</a>
                                                            </li>
                                                        </ul>
                                                    </List.Item>
                                                )}
                                            />
                                        </CheckboxGroup>
                                    )
                            }
                        </div>
                    </Spin>

                </div>
            </div>)
    }
}

export default GatheringDetailList