/*
 * @Description:
 * @LastEditTime: 2022-09-26 18:52:14
 * @LastEditors: 符春玲 fuchunling@perfectcode.tech
 */
import BaseWebApi from './BaseWebApi'

class BusinessDatabaseApi extends BaseWebApi {
  constructor(options) {
    super()
    this._apiE = this.gate.searchE
  }

  /**获取公司列表
   *
   * @param {int} pageNum 页码
   * @param {int} pageSize 每页数量
   * @param {string} keyword 关键词
   * @returns
   */
  async getBusinessList({ pageNum = 1, pageSize = 10, keyword = '' }) {
    let params = {
      current: pageNum,
      limit: pageSize,
      keyword: keyword || ''
    }

    let resp = await this._apiE.postForm('corp/listRegistrationInfo', params)
    return resp.data
  }


  // 根据关键字搜索相关公司
  async getInfoByName({ pageNum = 1, pageSize = 10, keyword = '' }) {
    let params = {
      name_or_stock_symbol:  keyword || '',
      current: pageNum,
      limit: pageSize,
    }
    let resp = await this._apiE.postForm(`corp/getInfoByNameOrStockSymbol`,params)
    return resp.data
  }
}

export default BusinessDatabaseApi
