/*
 * @Author: 符春玲 fuchunling@perfectcode.tech
 * @Date: 2022-09-07 16:15:00
 * @LastEditors: 符春玲 fuchunling@perfectcode.tech
 * @LastEditTime: 2022-10-12 16:41:12
 * @FilePath: \cedb-web\ei-front\src\pages\component\BusinessDatabase\datails\index.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { PureComponent } from 'react';
import _ from 'lodash'
import "./index.scss"
import AppContext from '../../../../AppContext'
import stores from "../../../../store";
import { webSdk } from '../../../../api/WebSdk';
import { Avatar, Descriptions, Table } from 'antd';
import moment from 'moment';
import Breadcrumb from "../../../common/Breadcrumb/Breadcrumb";

class BusinessDetailIdx extends PureComponent {
    static contextType = AppContext
    constructor(props) {
        super(props);
        this.state = {
            businessParamsStoreKey: "businessPageParams",
            corp_id: '',
            businessName:'',
            code: '',
            image: '',
            registered_capital: '',
            juridical_person: '',
            establishment_day: '',
            crewTable: [],
            shareholderTable: [],
            changeRecordTable: [],
            crewColumns:[
                {
                    title: '序号',
                    dataIndex: 'index',
                    render: (text, record, index) => `${index + 1}`,
                    align: 'center',
                    width: '8%'
                },
                {
                    title: '姓名',
                    dataIndex: 'name',
                    key: 'name',
                    align: 'center',
                    width: '23%',
                    render: text => text || '-'
                },
                {
                    title: '职位',
                    dataIndex: 'posts',
                    key: 'posts',
                    align: 'center',
                    width: '23%',
                    render: text => text || '-'
                },
                {
                    title: '持股比例',
                    dataIndex: 'stake',
                    key: 'stake',
                    align: 'center',
                    width: '23%',
                    render: text => text || '-'
                },
                {
                    title: '最终受益股份',
                    dataIndex: 'ultimate_beneficiary_shares',
                    key: 'ultimate_beneficiary_shares',
                    align: 'center',
                    width: '23%',
                    render: text => text || '-'
                }
            ],
            shareholderColumns:[
                {
                    title: '序号',
                    dataIndex: 'index',
                    render: (text, record, index) => `${index + 1}`,
                    align: 'center',
                    width: '8%'
                },
                {
                    title: '股东(发起人)',
                    dataIndex: 'shareholder',
                    key: 'shareholder',
                    align: 'center',
                    width: '30.6%',
                    render: text => text || '-'
                },
                {
                    title: '持股比例',
                    dataIndex: 'stake',
                    key: 'stake',
                    align: 'center',
                    width: '15.3%',
                    render: text => text || '-'
                },
                {
                    title: '最终受益股份',
                    dataIndex: 'ultimate_beneficiary_shares',
                    key: 'ultimate_beneficiary_shares',
                    align: 'center',
                    width: '15.3%',
                    render: text => text || '-'
                },
                {
                    title: '认缴出资额',
                    dataIndex: 'subscribed_capital_contribution',
                    key: 'subscribed_capital_contribution',
                    align: 'center',
                    width: '15.3%',
                    render: text => text || '-'
                },
                {
                    title: '认缴出资日期',
                    dataIndex: 'date',
                    key: 'date',
                    align: 'center',
                    width: '15.3%',
                    render: text => text || '-'
                }
            ],
            changeRecordColumns:[
                {
                    title: '序号',
                    dataIndex: 'index',
                    render: (text, record, index) => `${index + 1}`,
                    align: 'center',
                    width: '8%'
                },
                {
                    title: '变更日期',
                    dataIndex: 'date',
                    key: 'date',
                    align: 'center',
                    render: text => text || '-',
                    width: '12%'
                },
                {
                    title: '变更项目',
                    dataIndex: 'iterm',
                    key: 'iterm',
                    render: text => text || '-',
                    width: '14%'
                },
                {
                    title: '变更前',
                    dataIndex: 'preceding',
                    key: 'preceding',
                    width: '33%',
                    render: (text,record) => {
                        let newText = text
                        text=<div dangerouslySetInnerHTML={{__html: text}} />
                        let index = newText.indexOf('</p>');
                        let moreIndex = 0
                        let num = 0;
                        while (index !== -1) {
                            num++;
                            if(num === 6){
                                moreIndex = index
                            }
                            index = newText.indexOf('</p>', index + 1);
                        }
                        if(num > 5 && !record.isHandleMore){
                            newText = newText.substr(0,moreIndex)
                            newText = <span dangerouslySetInnerHTML={{__html: newText}}></span>
                            return <span>{newText}<p className='more-text' onClick={() =>this.handleMore(record)}></p></span>
                        }else {
                            return text || '-'
                        }
                    }
                },
                {
                    title: '变更后',
                    dataIndex: 'post',
                    key: 'post',
                    width: '33%',
                    render: (text,record) => {
                        let newText = text
                        text=<div dangerouslySetInnerHTML={{__html: text}} />
                        let index = newText.indexOf('</p>');
                        let moreIndex = 0
                        let num = 0;
                        while (index !== -1) {
                            num++;
                            if(num === 6){
                                moreIndex = index
                            }
                            index = newText.indexOf('</p>', index + 1);
                        }
                        if(num > 5 && !record.isHandleMore){
                            newText = newText.substr(0,moreIndex)
                            newText = <span dangerouslySetInnerHTML={{__html: newText}}></span>
                            return <span>{newText}<p className='more-text' onClick={() =>this.handleMore(record)}></p></span>
                        }else {
                            return text || '-'
                        }
                    }
                }
            ]
        }
    }

    componentDidMount() {
        this.init();
    }
    /** 在localStorage中保存值
    * @param {string} key 保存的键名
    * @param {object} obj 保存的对象
    */
     setLocStorage(key, obj) {
        let objJSON = ""
        if (!_.isEmpty(obj))
            objJSON = JSON.stringify(obj)
        window.localStorage.setItem(key, objJSON)
    }

    /** 从localStorage中获取值
     * @param {string} key 键名
     */
    getLocStorage(key) {
        let objStr = window.localStorage.getItem(key)
        if (!objStr) return null;
        return JSON.parse(objStr)
    }

    /**返回到工商数据库列表页面 */
    returnBackListPage() {
        this.context.getHistory().push({
            pathname: `/businessDatabase`
        })
    }

    init() {
        let businessParamsStoreKey = this.state.businessParamsStoreKey
        let pageParams = this.getLocStorage(businessParamsStoreKey)

        if (_.isEmpty(pageParams)) {
            this.returnBackListPage()
            return;
        }
        let businessName = pageParams.businessName;

        stores.businessStore.setPageInfo([
            {
                label: "工商数据库",
                key: "/businessDatabase"
            },
            {
                label: businessName,
                key: ""
            },
        ], 'list');
        let arr = []
        pageParams.history_changes.forEach((item,index) => {
            item.id = index
            item.isHandleMore = false;
            arr.push(item)
        })
        this.setState({
            corp_id: pageParams.corp_id,
            businessName: businessName,
            code: pageParams.code,
            image: pageParams.image,
            registered_capital: pageParams.registered_capital,
            juridical_person: pageParams.juridical_person,
            establishment_day: pageParams.establishment_day,
            crewTable: pageParams.key_personnels,
            changeRecordTable: arr,
            shareholderTable:pageParams.share_holders
        })

    }
    getListKeyPersonnel() {
        webSdk.BusinessDatabaseApi.getListKeyPersonnel(
            this.state.corp_id
        ).then(res => {
            let newData = res["data"]
            let arr = []
            newData.forEach(item => {
              item.key_personnel_data.key = item.key_personnel_data.id = item.id;
                arr.push(item.key_personnel_data)
            })
            this.setState({
                crewTable: arr,
                crewLoading: false
            })

        }).catch(e => {
            this.setState({
                crewLoading: false
            })
        });
    }
    getListShareholder() {
        webSdk.BusinessDatabaseApi.getListShareholder(
           this.state.corp_id
        ).then(res => {
            let newData = res["data"]
            let arr = []
            newData.forEach(item => {
              item.shareholders_data.key = item.shareholders_data.id = item.id;
                arr.push(item.shareholders_data)
            })
            this.setState({
                shareholderTable: arr,
                shareholderLoading: false
            })

        }).catch(e => {
            this.setState({
                shareholderLoading: false
            })
        });
    }
    getListHistoryChanges() {
        webSdk.BusinessDatabaseApi.getListHistoryChanges(
            this.state.corp_id
        ).then(res => {
            let newData = res["data"]
            let arr = []
            newData.forEach(item => {
              item.history_changes_data.key =item.history_changes_data.id = item.id;
                item.isHandleBeforeMore = false;
                item.isHandleAfterMore = false;
                arr.push(item.history_changes_data)
            })
            this.setState({
                changeRecordTable: arr,
                changeRecordLoading: false
            })

        }).catch(e => {
            this.setState({
                changeRecordLoading: false
            })
        });
    }
    handleMore(record){
        let newTable = [...this.state.changeRecordTable]
        newTable.forEach(item => {
            if(item.id === record.id){
                item.isHandleMore = true
            }
        })
        this.setState({
            changeRecordTable: newTable
        })
    }



    render() {
        let { businessName, code, image, registered_capital, juridical_person, establishment_day, crewColumns, crewTable, shareholderColumns, shareholderTable, changeRecordColumns, changeRecordTable } = this.state;
        return (
            <div className="business-detail">
               <Breadcrumb history={this.context.getHistory()} crumbList={stores.businessStore.crumbList.slice()} />
               <div className="business-detail-content">
                    <div className="basic-info business-block">
                        <h4>基本信息</h4>
                        <div className='basic-info-content business-block-content'>
                            <Avatar shape="square" src={image} />
                            <div className='basic-info-text'>
                                <Descriptions title={businessName} size="small" column={2}>
                                    <Descriptions.Item label="股票代码">{code}</Descriptions.Item>
                                    <Descriptions.Item label="注册资本">{registered_capital}</Descriptions.Item>
                                    <Descriptions.Item label="法定代表人">{juridical_person}</Descriptions.Item>
                                    <Descriptions.Item label="创建时间">{moment(parseInt(establishment_day) ).format("YYYY-MM-DD")}</Descriptions.Item>
                                </Descriptions>
                            </div>
                        </div>
                    </div>
                    <div className='key-person business-block'>
                        <h4>主要人员</h4>
                        <Table
                            className='key-person-table'
                            bordered
                            dataSource={crewTable}
                            columns={crewColumns}
                            rowKey={(record, index) => {
                                return index
                            }}
                            pagination={false}
                        />
                    </div>
                    <div className='shareholder-info business-block'>
                        <h4>股东信息</h4>
                        <Table
                            className='shareholder-info-table'
                            bordered
                            dataSource={shareholderTable}
                            columns={shareholderColumns}
                            pagination={false}
                            rowKey={(record, index) => {
                                return index
                            }}
                        />
                    </div>
                    <div className='change-record business-block'>
                        <h4>变更记录</h4>
                        <Table
                            className='change-record-table'
                            bordered
                            dataSource={changeRecordTable}
                            columns={changeRecordColumns}
                            pagination={false}
                            rowKey={(record, index) => {
                                return index
                            }}
                        />
                    </div>
               </div>
            </div>
        );
    }
}

export default BusinessDetailIdx;
