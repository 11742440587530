import React from 'react'
import { Icon, Empty, Menu, Dropdown, Spin, Select, Button, message } from 'antd'
import _ from 'lodash'
import PdfViewer from './PdfViewer'
import './ResearchLibraryBody.scss'
import WordCloudViewer from '../../common/WordCloudViewer'
import { webSdk } from "../../../api/WebSdk";
import RelationGraph from "../RelationGraph";
import { formatDataToGraph } from "../../common/formatDataToGraph";

const { Option } = Select;
let currTr = null;

//颜色组
const colorArr = [['#F7756C', '#F7756C'], ['#4CAF50', '#4CAF50'], ['#6573C3', '#6573C3'], ['#FFC107', '#FFC107'], ['#11B7C1', '#11B7C1'], ['#DD6514', '#DD6514'], ['#A74CAF', '#A74CAF']]

class SearchArtDetailBody extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            baseData: {//基本信息
                id: this.props.articleId || '',//当前文章的id
                type: this.props.articleType || '',//当前文章的类型
                classifier: '',//学科分类
                title: '',//文章标题
                pdfUrl: '',//pdf链接
                resList: [],//服务器返回的高亮数据
            },
            tabs: {//知识点tab头
                activeTabKey: 'eav',//当前tab页的key
                list: [
                    {
                        key: "spo",
                        value: "行为"
                    },
                    {
                        key: "eav",
                        value: "属性"
                    }, {
                        key: "eae",
                        value: "关系"
                    }
                ],
            },
            wordsData: [],//云图数据
            lightData: {//高亮相关数据
                data: [],//高亮数据
                showSite: {//点击列表，跳转到对应的位置
                    coords: '',//坐标信息'x0,x1,y0,y1'
                    page: '1',//页码
                },
                lightColor: {},//知识点颜色对应值
                maxPage: 0,//高亮区最大的页面
            },
            tableInfo: {//table相关数据
                loading: true,
                bordered: false,//是否展示外边框和列边框
                pagination: false,//是否分页
                columns: [],//表头数据
                data: [],//表格数据
            },
            tableClassifier: {//表格 学科分类
                current: '',//当前筛选
                list: [],//列表
            },
            preLightData: {//预处理的指定高亮数据
                data: {},//原始数据
                tab: "",
                dealData: {},//处理后的数据
            },
            showRelation: false,
            graphData: [],
            showGraphData: [],
            graphLoading: false,
        }
        this.getData = this.getData.bind(this)
    }

    componentDidMount() {
        let lightId = this.props.lightId
        if (lightId) {
            //获取指定的高亮数据
            this.getHeightLightData(lightId);
        } else {
            this.getData();
        }

        this.getClassifierData();
    }

    componentWillReceiveProps(nextProps) {
        const _this = this;
        if (_this.state.baseData.id !== nextProps.articleId) {
            let baseData = _this.state.baseData
            baseData.id = nextProps.articleId
            baseData.type = nextProps.articleType
            _this.setState({
                baseData
            }, () => {
                _this.getData();
            })
        }
    }

    /**获取指定的高亮数据 */
    getHeightLightData(lightId) {
        const _this = this;
        webSdk.relibrary.getReportDetail(lightId).then(res => {
            // console.log(res)

            //信息spo；属性eav；关系eae
            let activeKey = 'spo'

            if (!_.isEmpty(res.spo)) {
                activeKey = 'spo'
            } else if (!_.isEmpty(res.eae)) {
                activeKey = 'eae'
            } else {
                activeKey = 'eav'
            }

            //赋值
            let tabs = _this.state.tabs
            let preLightData = _this.state.preLightData
            tabs.activeTabKey = activeKey
            preLightData.data = res
            preLightData.tab = activeKey
            _this.setState({
                tabs, preLightData
            }, () => {
                //处理表格数据
                _this.getData();
            })
        })
    }

    /**获取学科分类 */
    getClassifierData() {
        const _this = this;

        let tableClassifier = _this.state.tableClassifier;
        tableClassifier.current = "";
        tableClassifier.list = [{ key: "", value: "全选" }]

        let postData = {
            id: _this.state.baseData.id,
            type: _this.state.baseData.type,
        }
        webSdk.relibrary.getArtClassifier(postData).then(res => {

            _.forEach(res, item => {
                tableClassifier.list.push({
                    key: item,
                    value: item
                })
            })
        })

        _this.setState({
            tableClassifier
        })
    }

    //获取基础数据
    getData() {
        const _this = this;
        let currClassifier = _this.state.tableClassifier.current;
        let postData = {
            id: _this.state.baseData.id,
            type: _this.state.baseData.type,
            struct_type: _this.state.tabs.activeTabKey,
            classifier: currClassifier
        }

        let tableInfo = _this.state.tableInfo
        tableInfo.loading = true;
        tableInfo.data = []
        //修改加载效果
        _this.setState({
            tableInfo, graphData: []
        }, () => {
            webSdk.relibrary.getPDFDataById(postData).then(res => {
                if (!res.errCode || res.errCode === 0) {
                    let graphData = res.list || [];
                    let title = res.title;//文章标题
                    let dataUrl = res.pdf;//pdf路径
                    if (!_.startsWith(dataUrl, "https")) {
                        dataUrl = dataUrl.replace("http", "https")
                    }

                    let resList = res.list;//高亮数据 resList = data."list" = [{"id":111, "spo":{}, "classifier":[], "eav":{}, "page_size":[], ...}, {...}]
                    // console.log(resList)

                    //处理pdf链接
                    // let dataUrlObj = {}
                    // if (dataUrl) {
                    //     let questionMarkIdx = _.indexOf(dataUrl, "?")
                    //     let paramsStr = "";
                    //     if (questionMarkIdx > -1) {
                    //         paramsStr = _.join(_.slice(dataUrl, questionMarkIdx + 1), "")
                    //         if (paramsStr) {
                    //             let paramsArr = _.split(paramsStr, "&")

                    //             _.forEach(paramsArr, item => {
                    //                 let keyValue = _.split(item, "=")
                    //                 dataUrlObj[keyValue[0]] = keyValue[1]
                    //             })
                    //         }
                    //     }
                    // }
                    // let pdfUrl = dataUrlObj["path"];
                    // console.log(pdfUrl)

                    //给每个高亮点添加组id、获取学科分类集合
                    _.forEach(resList, (item, idx) => {//item = {"id":111, "spo":{}, "classifier":[], "eav":{}, "page_size":[], ...}
                        if (_.isEmpty(item)) return true;

                        //添加组id
                        let spoPosList = item[_this.state.tabs.activeTabKey] //spoPosList = {"predicate":[], "subject":[], "time":[], "object":[]}
                        if (_.isEmpty(spoPosList)) return true;
                        // item['idx'] = item.id;
                        item['idx'] = idx;
                    })


                    //设置数据
                    let baseData = _this.state.baseData;
                    baseData.title = title;
                    baseData.pdfUrl = encodeURIComponent(dataUrl)
                    baseData.resList = resList;

                    _this.setState({
                        baseData, tableInfo, graphData
                    }, () => {
                        //修改父组件导航
                        _this.changeNav()

                        //获取云图数据
                        _this.getWordCloudData()

                        //处理表格
                        _this.handleTableData();

                        //处理高亮数据
                        _this.handleHighlightData();
                    })
                } else {
                    tableInfo.loading = false;
                    this.setState({tableInfo});
                    message.error(res.errMsg || "获取文章信息失败");
                }
            }).catch(e =>{
                tableInfo.loading = false;
                this.setState({tableInfo});
                message.error("获取文章信息失败");
            })
        })
    }

    //处理高亮数据
    handleHighlightData() {
        const _this = this;

        //获取数据
        let resList = _this.state.baseData.resList;//服务器返回的高亮数据 resList = [{"idx":1, "spo":{}, "classifier":[], "eav":{}, "page_size":[], ...}, {...}]
        let activeKey = _this.state.tabs.activeTabKey//当前tab页的key

        //处理高亮数据
        let lightData = [] //lightData = [{"pageNum":1,list:[{"page_size":....},{}]}]
        let properties = []//属性集合
        _.forEach(resList, item => {//item = {"idx":1, "spo":{}, "classifier":[], "eav":{}, "page_size":[], ...}
            let hightlightData = {} //hightlightData = {"page_size":[], "spo_pos_list":{}, "idx":1}
            let spoPosList = item[activeKey]//spoPosList = {"predicate":[...], "subject":[...], "time":[...], ...}
            if (!_.isEmpty(spoPosList)) {
                //组合pdf尺寸、高亮信息
                hightlightData['page_size'] = item['page_size']
                hightlightData['spo_pos_list'] = spoPosList
                hightlightData['idx'] = item['idx']
                // lightData.push(hightlightData)

                //获取属性
                properties = _.concat(properties, _.keys(spoPosList))

                //集合 每页的高亮数据
                _.forOwn(spoPosList, (spoPosVal, spoPosKey) => {//spoPosVal = [{"val":"...", "val_range_list":[...]}, ...]; spoPosKey="predicate"
                    _.forEach(spoPosVal, spoPosValItem => {//spoPosValItem = {"val":"...", "val_range_list":[...]}
                        let valRangeList = spoPosValItem.val_range_list;//valRangeList = [{"page":"1", "word_range":[...], "coords":"..."}, ...]
                        _.forEach(valRangeList, valRange => {//valRange = {"page":"1", "word_range":[...], "coords":"..."}
                            let valRangePageNum = valRange.page;

                            if (!_.isEmpty(lightData) && _.findIndex(lightData, { "pageNum": valRangePageNum }) >= 0) {

                                //获取 对应页面的数据对象
                                let lightDataPage = _.find(lightData, { "pageNum": valRangePageNum }) // lightDataPage = {"pageNum":1, "list":[...]}

                                //获取 该页面下对应的高亮数据 坐标
                                let lightDataIdx = _.findIndex(lightDataPage.list, { "idx": item.idx })

                                //集合中没有，将该坐标的数据加入集合
                                if (lightDataIdx < 0) {
                                    lightDataPage.list.push(hightlightData)
                                }

                            } else {
                                lightData.push({
                                    "pageNum": valRangePageNum,
                                    "list": [hightlightData]
                                })
                            }
                        })
                    })
                })
            }
        })
        properties = Array.from(new Set(properties))

        //根据属性，匹配相对应的颜色
        let lightColor = {};
        let colorArrIdx = 0;
        _.forEach(properties, item => {
            lightColor[item] = colorArr[colorArrIdx++]
        })

        //设置数据
        let stateLightData = _this.state.lightData;
        stateLightData.data = lightData
        stateLightData.lightColor = lightColor
        _this.setState({
            lightData: stateLightData
        }, () => {
            //高亮数据展示
            _this.hightlightInit();
        })
    }

    //初始化高亮数据显示
    hightlightInit() {
        if (this.refs.PdfViewer) {
            this.refs.PdfViewer.hightlightInit();
        }

        //设置表格位置
        this.getHeightlightIdx()
    }

    //修改父组件导航
    changeNav() {
        let title = this.state.baseData.title

        this.props.changeTitle(title);
    }

    //获取云图数据
    getWordCloudData() {
        // console.log("getWordCloudData")
        // let wordsData = [{ "text": "智能控制器", "value": "64" }, { "text": "成本压力", "value": "11" }, { "text": "3.2亿元、4.5亿和6.5亿", "value": "16" }, { "text": "复合增长率", "value": "17" }, { "text": "净利润", "value": "10" }, { "text": "接近30%", "value": "54" }, { "text": "收入复合增长率", "value": "12" }, { "text": "2019年", "value": "77" }, { "text": "得到缓解", "value": "45" }, { "text": "定制化服务", "value": "19" }]

        // this.setState({
        //     wordsData: wordsData
        // })
    }

    //处理表格数据
    handleTableData() {
        const _this = this;
        //获取数据
        let resList = _this.state.baseData.resList;//服务器返回的高亮数据 resList = [{"idx":1, "spo":{}, "classifier":[], "eav":{}, "page_size":[], ...}, {...}]
        let activeKey = _this.state.tabs.activeTabKey;//当前tab页的key

        //表头
        let columns = []
        if (activeKey === 'spo') {//通用结构知识
            columns = [
                {
                    title: '时间',
                    dataIndex: 'time',
                    width: 16.6,
                },
                {
                    title: '主体',
                    dataIndex: 'subject',
                    width: 16.6,
                },
                {
                    title: '动作',
                    dataIndex: 'predicate',
                    width: 16.6,
                },
                {
                    title: '客体',
                    dataIndex: 'object',
                    width: 16.6,
                },
                {
                    title: '参数',
                    dataIndex: 'args',
                    width: 16.6,
                },
                {
                    title: '条件',
                    dataIndex: 'condition',
                    width: 16.6,
                },
                // {
                //     title: '学科',
                //     dataIndex: 'classifier',
                //     width: 14.2,
                //     // searchProps: this.state.tableInfo.classifier.list
                // },
            ]
        } else if (activeKey === 'eav') {
            columns = [
                {
                    title: '时间',
                    dataIndex: 'time',
                    width: 25,
                },
                {
                    title: '主体',
                    dataIndex: 'entity',
                    width: 25,
                },
                {
                    title: '属性',
                    dataIndex: 'attribute',
                    width: 25,
                },
                {
                    title: '值',
                    dataIndex: 'value',
                    width: 25,
                },
                // {
                //     title: '学科',
                //     dataIndex: 'classifier',
                //     width: 20,
                //     // searchProps: this.state.tableInfo.classifier.list
                // },
            ]
        } else if (activeKey === 'eae') {
            columns = [
                {
                    title: '时间',
                    dataIndex: 'time',
                    width: 25,
                },
                {
                    title: '主体',
                    dataIndex: 'entity',
                    width: 25,
                },
                {
                    title: '关系',
                    dataIndex: 'relation',
                    width: 25,
                },
                {
                    title: '主体2',
                    dataIndex: 'entity2',
                    width: 25,
                },
                // {
                //     title: '学科',
                //     dataIndex: 'classifier',
                //     width: 20,
                //     // searchProps: this.state.tableInfo.classifier.list
                // },
            ]
        }

        //表格数据
        let tableData = [];
        let newMaxPage = _this.state.lightData.maxPage;
        _.forEach(resList, resItem => {//resItem = {"idx":1, "spo":{}, "classifier":[], "eav":{}, "page_size":[], ...}
            let data = {}//一行数据

            //表格key
            data['idx'] = resItem.idx;

            //获取学科分类
            let classifier = resItem.classifier;
            let spoData = resItem[activeKey]

            //canvas尺寸
            let pageCanvasSize = resItem.page_size;

            //属性、坐标、页码
            let coords = ""
            let page = 0
            if (!_.isEmpty(spoData)) {
                _.forOwn(spoData, (spoDataVal, spoDataKey) => {
                    let tempSpoDataKey = spoDataKey
                    if (spoDataKey === 'idx') return true;
                    //获取对应结构的数据对象，kg_[spoDataKey]优先选择
                    let spoDataKeyArr = _.split(spoDataKey, "_")
                    if (spoDataKeyArr[0] === "kg") {
                        tempSpoDataKey = spoDataKeyArr[1]
                    } else {
                        if (!_.isEmpty(data[spoDataKey])) return true
                        tempSpoDataKey = spoDataKey
                    }

                    let propertyValArr = []
                    _.forEach(spoDataVal, spoItem => {
                        propertyValArr.push(spoItem.val)
                        _.forEach(spoItem.val_range_list, valRange => {
                            let newCoords = valRange.coords
                            let newPage = valRange.page

                            //获取高亮区的最大页码
                            if (parseFloat(newPage) - parseFloat(newMaxPage) > 0) {
                                newMaxPage = newPage;
                            }

                            //页码
                            if (page > 0) {

                                if (parseFloat(newPage) - parseFloat(page) < 0) {
                                    page = newPage
                                    coords = newCoords
                                } else if (parseFloat(newPage) - parseFloat(page) - 0 < Number.EPSILON) {

                                    //坐标
                                    let coordsY0 = _.split(coords, ",")[2]
                                    let newCoordsY0 = _.split(newCoords, ",")[2]

                                    coords = parseFloat(newCoordsY0) - parseFloat(coordsY0) > 0 ? newCoords : coords;
                                }
                            } else {
                                page = newPage
                                coords = newCoords
                            }
                        })
                    })
                    //属性
                    data[tempSpoDataKey] = propertyValArr
                })
                data['coords'] = coords
                data['page'] = page

                //学科分类
                data['classifier'] = classifier

                //坐标信息
                data['spo_pos'] = spoData
                data['page_size'] = pageCanvasSize

                tableData.push(data)
            }

        });


        // {
        //     key: i,
        //     time: `2019-8-${i}`,
        //     entity: '公司控制器',
        //     attribute: `收入复合增长率 ${i}`,
        //     value: '接近40%',
        //     classifier: '战略管理',
        //     coords: '177.26,553.174,345.409,356.761',
        //     page: '2',
        //     spo_pos: { "idx": 1, "attribute": [{ "val_range_list": [{ "coords": "177.26,553.174,345.409,356.761", "word_range": [7, 9], "page": "2" }], "val": "营收" }], "entity": [{ "val_range_list": [{ "coords": "177.26,553.174,345.409,356.761", "word_range": [0, 4], "page": "2" }], "val": "工业环境" }], "value": [{ "val_range_list": [{ "coords": "177.26,553.174,345.409,356.761", "word_range": [9, 14], "page": "2" }], "val": "5.38亿" }], "time": [] }
        // }

        //赋值
        let tableInfo = _this.state.tableInfo;
        let lightData = _this.state.lightData
        tableInfo.columns = columns;
        tableInfo.data = tableData;
        tableInfo.loading = false;
        lightData.maxPage = newMaxPage
        _this.setState({
            tableInfo, lightData
        }, () => {
            let lightId = _this.props.lightId
            if (lightId) {
                //处理表格到指定的位置
                _this.toTableScrollClick(lightId)

                //处理pdf到指定的位置
                let pointLightData = {}//一行数据

                pointLightData['idx'] = Number(lightId)

                let preLightData = _this.state.preLightData
                let spoData = preLightData.data[preLightData.tab]
                pointLightData['spo_pos'] = spoData
                pointLightData['page_size'] = preLightData.data.page_size

                let coords = ""
                let page = 0
                _.forOwn(spoData, (spoDataVal, spoDataKey) => {

                    _.forEach(spoDataVal, spoItem => {
                        _.forEach(spoItem.val_range_list, valRange => {
                            let newCoords = valRange.coords
                            let newPage = valRange.page

                            //页码
                            if (page > 0) {

                                if (parseFloat(newPage) - parseFloat(page) < 0) {
                                    page = newPage
                                    coords = newCoords
                                } else if (parseFloat(newPage) - parseFloat(page) - 0 < Number.EPSILON) {

                                    //坐标
                                    let coordsY0 = _.split(coords, ",")[2]
                                    let newCoordsY0 = _.split(newCoords, ",")[2]

                                    coords = parseFloat(newCoordsY0) - parseFloat(coordsY0) > 0 ? newCoords : coords;
                                }
                            } else {
                                page = newPage
                                coords = newCoords
                            }
                        })
                    })
                })
                pointLightData['coords'] = coords
                pointLightData['page'] = page
                preLightData.dealData = pointLightData
                // console.log(pointLightData)
                _this.setState({
                    preLightData
                })
                // _this.changeSite(pointLightData);
            }
        })

    }

    //选择学科分类
    handleClassifierFilter(val) {
        const _this = this;
        let tableClassifier = _this.state.tableClassifier;
        tableClassifier.current = val
        _this.setState({
            tableClassifier
        }, () => {
            //请求表格数据接口
            _this.getData();
        })
    };

    //点击表格数据
    clickTable(data, event) {
        // console.log(data)

        //替换样式
        if (currTr) {
            currTr.className = _.replace(currTr.className, 'active', '');
        }
        event.currentTarget.className = `${event.currentTarget.className} active`
        currTr = event.currentTarget;

        //跳转pdf中对应的位置
        this.changeSite(data);
    }

    //跳转到指定高亮区
    changeSite(data) {
        // console.log(`click:${data.page}`)
        //调用子组件改变滚动条方法
        this.refs.PdfViewer.changeScroll(data);
    }

    //切换tab面板，处理表格数据
    changeTab(activeKey) {
        const _this = this;

        let tabs = _this.state.tabs
        let tableInfo = _this.state.tableInfo
        if (activeKey === tabs.activeTabKey) return false;

        tabs.activeTabKey = activeKey
        //赋值
        _this.setState({
            tabs, tableInfo
        }, () => {
            //处理表格数据
            _this.getData();
        })
    }

    //处理表格滚动条
    handleTableScroll(heightlightIdx) {

        if (!heightlightIdx || heightlightIdx < 1) return

        let rightDiv = document.getElementById("bodyRight")
        let table = document.getElementsByClassName('klogger-content')
        if (!_.isEmpty(table)) {
            // let table = document.querySelector('table.klogger-content')
            let tr = table[0].querySelector(`tr[data-idx='${heightlightIdx}']`)
            let trTop = tr.offsetTop
            let kloggerTable = document.getElementsByClassName('klogger-table')
            kloggerTable[0].scrollTop = trTop - rightDiv.offsetHeight * 0.1
        }

    }

    //点击pdf调到指定的表格位置
    toTableScrollClick(idx) {
        this.handleTableScroll(idx);
    }

    //在pdf中获取当前页面的高亮点组id
    getHeightlightIdx() {
        let idx = 0;
        if (this.refs.PdfViewer) {
            idx = this.refs.PdfViewer.getHeightlightIdx();
        }

        this.handleTableScroll(idx)
    }

    //监控滚动条，固定知识点
    listenScroll(event) {
        let currentTarget = event.currentTarget;
        let kloggerAffix = document.getElementById("kloggerAffix")
        let affix = kloggerAffix.querySelector("div.affix-item")

        if (currentTarget.scrollTop >= (kloggerAffix.offsetTop - currentTarget.offsetTop)) {
            kloggerAffix.setAttribute("style", `width:${kloggerAffix.offsetWidth}px;height:${kloggerAffix.offsetHeight}px`);
            affix.setAttribute("style", `position:absolute;top:${currentTarget.offsetTop}px;width:${kloggerAffix.offsetWidth}px;z-index:50;height:${kloggerAffix.offsetHeight}px`);
        }

        if (currentTarget.scrollTop < (kloggerAffix.offsetTop - currentTarget.offsetTop)) {
            kloggerAffix.setAttribute("style", "");
            affix.setAttribute("style", "");
        }
    }

    switchRelationGraph = () => {
        if (this.state.showRelation) {
            this.setState({
                showRelation: false
            })
            return
        }
        this.setState({
            graphLoading: true
        }, () => {
            setTimeout(() => {
                let showGraphData = formatDataToGraph(this.state.graphData, this.state.tabs.activeTabKey);
                this.setState({
                    showRelation: true,
                    showGraphData,
                    graphLoading: false

                })
            }, 500)
        })

    }

    render() {
        return (
            <div className="research-body">
                <div className="body-content">
                    <div className="body-pdf"
                        style={
                            {
                                width: `${(document.body.clientWidth - 20) * 0.6}px`,
                                minWidth: '800px',
                                height: `${window.innerHeight - 140}px`,
                                boxShadow: "0px 0px 10px 1px rgba(237,237,237,1)",
                            }
                        }
                    >
                        {
                            this.state.baseData.pdfUrl ? (
                                <Spin className="klogger-content-loading"
                                    spinning={this.state.tableInfo.loading}
                                >
                                    {
                                        this.state.baseData.pdfUrl ?
                                            (
                                                <PdfViewer
                                                    ref="PdfViewer"
                                                    size={
                                                        {
                                                            width: `${(document.body.clientWidth - 20) * 0.6 >= 800 ? (document.body.clientWidth - 20) * 0.6 : 800}`,
                                                            height: `${window.innerHeight - 140}`
                                                        }
                                                    }
                                                    pointDataList={this.state.lightData.data}
                                                    fileUrl={this.state.baseData.pdfUrl}
                                                    showSite={this.state.lightData.showSite}
                                                    lightColor={this.state.lightData.lightColor}
                                                    maxLightPage={this.state.lightData.maxPage}
                                                    toTableScrollClick={this.toTableScrollClick.bind(this)}
                                                    preLightData={this.state.preLightData.dealData}
                                                />
                                            ) : (
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                            )
                                    }

                                </Spin>
                            ) : null
                        }

                    </div>
                    <div id="bodyRight"
                        className="body-right"
                        onScroll={this.listenScroll.bind(this)}
                        style={
                            {
                                width: `${document.body.clientWidth - ((document.body.clientWidth - 20) * 0.6 >= 800 ? (document.body.clientWidth - 20) * 0.6 : 800) - 60}px`,
                                minWidth: '365px',
                                height: `${window.innerHeight - 140}px`,
                                marginLeft: '20px',
                                // overflow: 'auto',
                                boxShadow: "0px 0px 10px 1px rgba(237,237,237,1),0px 0px 10px 1px rgba(237,237,237,1),0px 0px 0px 0px rgba(237,237,237,1),0px 0px 10px 1px rgba(237,237,237,1)",
                            }
                        }
                    >
                        <div className="right-keyword"
                            style={{
                                height: '250px',
                                display: 'none',
                            }}
                        >
                            <p className="keyword-title">关键词</p>
                            <div className="keyword-cloud">
                                {
                                    !_.isEmpty(this.state.wordsData) ?
                                        (
                                            <WordCloudViewer wordsData={this.state.wordsData}
                                                minSize={['400', `200`]}
                                                options={{
                                                    colors: [
                                                        '#1f77b4',
                                                        '#ff7f0e',
                                                        '#2ca02c',
                                                        '#d62728',
                                                        '#9467bd',
                                                        '#8c564b',
                                                    ],
                                                    enableTooltip: true,
                                                    deterministic: true,
                                                    fontFamily: 'PingFangSC-Regular',
                                                    fontSizes: [10, 36],
                                                    fontStyle: 'normal',
                                                    fontWeight: 'normal',
                                                    padding: 1,
                                                    rotations: 1,
                                                    rotationAngles: [0, 90],
                                                    scale: '√n',
                                                    spiral: 'archimedean',
                                                    transitionDuration: 0,
                                                }}
                                            />
                                        ) : (
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                        )
                                }

                            </div>
                        </div>
                        <div className="right-klogger">

                            <div id="kloggerAffix"
                                style={{
                                    height: "193px"
                                }}
                            >
                                <div className="affix-item">
                                    <div className="klogger-head">
                                        <p className="klogger-title">
                                            知识点
                                            {
                                                ['eav', 'eae'].includes(this.state.tabs.activeTabKey) ? (
                                                    <Button className="btn"
                                                        type="primary"
                                                        disabled={this.state.graphData.length === 0}
                                                        onClick={this.switchRelationGraph}
                                                        style={{ marginLeft: 15, color: '#FFF', cursor: 'pointer', height: 28, padding: "0 15px", fontSize: 12 }}
                                                    >生成图谱</Button>
                                                ) : null
                                            }

                                        </p>
                                        <div className="klogger-select">学科分类&nbsp;&nbsp;&nbsp;&nbsp;
                                        <Select
                                                value={this.state.tableClassifier.current}
                                                onChange={this.handleClassifierFilter.bind(this)}
                                                style={{
                                                    width: 140,
                                                    height: 30
                                                }}>
                                                {
                                                    this.state.tableClassifier.list.map((item, idx) => {
                                                        return (
                                                            <Option key={idx} value={item.key}>{item.value}</Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </div>
                                    </div>

                                    <ul className="klogger-tabs">
                                        {
                                            this.state.tabs.list.map((item, idx) => {
                                                let activeTabKey = this.state.tabs.activeTabKey
                                                return (
                                                    <li key={item.key}
                                                        className={item.key === activeTabKey ? "active" : ""}
                                                        onClick={this.changeTab.bind(this, item.key)}
                                                    >
                                                        {item.value}
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                    <ul className="klogger-table-head">
                                        {
                                            this.state.tableInfo.columns.map((item, idx) => {
                                                let width = item.width && item.width > 0 ? item.width : '20';
                                                let searchProps = item.searchProps

                                                return (
                                                    <li key={idx}
                                                        style={{
                                                            width: `${width}%`
                                                        }}
                                                    >{item.title}</li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="klogger-table"
                                style={{
                                    height: `${window.innerHeight - 140 - 193}px`,
                                }}
                            >
                                <Spin wrapperClassName="klogger-content-loading"
                                    spinning={this.state.tableInfo.loading}
                                >
                                    {
                                        !this.state.tableInfo.data || this.state.tableInfo.data.length <= 0 ?
                                            (
                                                <Empty className="klogger-content-empty" image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                            ) : (
                                                <table
                                                    className="klogger-content"
                                                >
                                                    <tbody>
                                                        {
                                                            this.state.tableInfo.data.map((data, index) => {
                                                                let result = []
                                                                let columns = this.state.tableInfo.columns;
                                                                _.forEach(columns, (column, columnIdx) => {
                                                                    let columnKey = column.dataIndex;
                                                                    // let newKey = 'kg_' + columnKey;
                                                                    let propertyVal = data[columnKey]
                                                                    // if (data[newKey] && Object.prototype.toString.call(data[newKey])==='[object Array]' && data[newKey].length>0) {
                                                                    //     propertyVal = data[newKey]
                                                                    // }
                                                                    let property = []

                                                                    _.forEach(propertyVal, (propItem, propIdx) => {
                                                                        property.push(`${propItem}`)
                                                                        property.push('<br />')
                                                                    })

                                                                    result.push(`<td style="width:${column.width}%">${_.join(property, "")}</td>`)
                                                                })

                                                                return (
                                                                    <tr key={index}
                                                                        data-idx={data.idx}
                                                                        dangerouslySetInnerHTML={{ __html: _.join(result, "") }}
                                                                        onClick={this.clickTable.bind(this, data)}
                                                                    />
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            )
                                    }
                                </Spin>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.showRelation ? <RelationGraph backBtn={this.switchRelationGraph} graphData={this.state.showGraphData} /> : null}
                <Spin spinning={this.state.graphLoading} />

            </div>
        )
    }
}

export default SearchArtDetailBody