/*
 * @Author: 符春玲 fuchunling@perfectcode.tech
 * @Date: 2021-09-02 19:32:54
 * @LastEditors: 符春玲 fuchunling@perfectcode.tech
 * @LastEditTime: 2022-10-21 14:31:33
 * @FilePath: \cedb-web\ei-front\src\pages\component\SearchResultList\ResultSearch\mindModule\LeftMind.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react'
import {message} from 'antd';
import MindGraph from "./MindGraph";
import LeftActionIcon from "../resultCommon/LeftCommon/LeftActionIcon";

class LeftMind extends React.PureComponent {

    constructor(props) {
        super(props)

        this.state = {
            isFullScreen: false,        //是否全屏
            isCursorPoint: false,       //是否处于鼠标选择状态
        };
        window.isCursor = false;
        this.mindGraph = null;
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.keyword !== this.props.keyword) {
            window.isCursor = false;
            this.setState({
                isCursorPoint: false
            })
        }
    }

    componentWillUnmount() {
        window.isCursor = false;
        // 卸载异步操作设置状态
        this.setState = (state, callback) => {
            return;
        }
    }

    switchCursorPoint = () => {
        let newStatus = !this.state.isCursorPoint;
        window.isCursor = newStatus;
        // 处于激活状态，弹出右侧列表
        if (newStatus) {
            message.info('钉住字段，下载数据');
            this.props.clickDownIcon(true)
        } else {
            this.props.clickDownIcon()
        }
        this.setState({
            isCursorPoint: newStatus
        })
    }

    switchFullScreen = () => {
        this.setState({
            isFullScreen: !this.state.isFullScreen
        })
    }

    render() {
        let clsName = this.state.isFullScreen ? "result-search__graph__left full-screen" : "result-search__graph__left";

        return (
            <div className={clsName}>
                <LeftActionIcon
                    isCursorPoint={this.state.isCursorPoint}
                    isFullScreen={this.state.isFullScreen}
                    switchCursorPoint={this.switchCursorPoint}
                    clickDownIcon={this.props.clickDownIcon}
                    switchFullScreen={this.switchFullScreen}/>
                <MindGraph
                    ref={el => this.mindGraph = el}
                    isCursorPoint={this.state.isCursorPoint}
                    loading={this.props.loading}
                    nodeClick={this.props.nodeClick}
                    graphData={this.props.graphData}
                    keyword={this.props.keyword}/>
            </div>
        )
    }
}

export default LeftMind