import React from 'react'
import {Input, Tooltip, Icon, Checkbox, Button, message, Spin} from 'antd'
import './LoginPage.scss'
import {webSdk} from "../../../api/WebSdk";
import md5 from 'js-md5'
import { JSEncrypt } from 'jsencrypt'
import '../../../css/iconfont/iconfont.css'

let timer = null;
class FindPassword extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            userName: '',       //手机号/邮箱
            passWord: '',       //密码
            confirmPassword: '',     //确认密码
            verifyCode: '',     //验证码
            userNameError: false,
            passWordError: false,
            confirmPasswordError: false,
            verifyCodeError: false,
            timerTxt: '获取验证码',
            iconLoading: false,
            active: false,
            btnDisabled: true,
            verifyCodeLoading: false,
            md5Salt: '',
            rsaPublicKey: '',
        }

        this.changeInput = this.changeInput.bind(this);
        this.setActive = this.setActive.bind(this);
        this.getVerifyCode = this.getVerifyCode.bind(this);
        this.btnAction = this.btnAction.bind(this);
        this.inputKeyup = this.inputKeyup.bind(this);
        this.inputBlur = this.inputBlur.bind(this);
        this.toLogin = this.toLogin.bind(this);
    }
    componentDidMount() {
        let hasLogin = window.sessionStorage.getItem('hasLogin') || null;
        let userInfo = window.localStorage.getItem('userInfo') || '';
        if (hasLogin && userInfo) {
            this.props.history.push({
                pathname: '/'
            })
            message.info('已登录');
        }
        //获取加密信息
        webSdk.search.loginKey().then(res => {
            try {
                let {md5Salt , rsaPublicKey} = res;
                this.setState({
                    md5Salt , rsaPublicKey
                })
            }
            catch (e) {
                console.log(e)
            }
        }).catch(e =>{
            message.error('获取基础信息失败，请刷新重试');
        })
    }

    componentWillReceiveProps(nextProps) {

    }
    componentWillUnmount() {
        // 卸载异步操作设置状态
        this.setState = (state, callback) => {
            return;
        }
    }

    /**
     * 获取验证码
     */
    getVerifyCode() {
        if (parseFloat(this.state.timerTxt).toString()==='NaN') {
            let userName = this.state.userName;
            if (!userName) {
                message.error('手机号/邮箱不能为空');
                this.setState({
                    userNameError: true
                })
                return
            }
            let postData = {
                address: userName
            }
            this.setState({
                verifyCodeLoading: true
            },() =>{
                webSdk.search.getVerifyCode(postData).then(res => {
                    this.setState({
                        verifyCodeLoading: false
                    })
                    if (!res.errCode || res.errCode===0) {
                        this.setState({
                            timerTxt: 60
                        },() =>{
                            timer = setInterval(()=>{
                                let time = this.state.timerTxt;
                                if (time === 0) {
                                    clearInterval(timer);
                                    this.setState({
                                        timerTxt: '重新获取验证码'
                                    })
                                }
                                else {
                                    time--;
                                    this.setState({
                                        timerTxt: time
                                    })
                                }
                            },1000)
                        })
                    }
                    else {
                        message.error(res.errMsg || '服务器繁忙，请稍后重试');
                    }
                }).catch(e =>{
                    this.setState({
                        verifyCodeLoading: false
                    })
                    message.error('服务器繁忙，请稍后重试');
                })
            })

        }
    }

    changeInput(ev,type) {
        let val = ev.target.value;
        if (type==='userName') {
            this.setState({
                userName: val
            },() =>{
                this.setActive()
            })
        }
        else if (type==='passWord') {
            this.setState({
                passWord: val
            },() =>{
                this.setActive();
            })
        }
        else if (type==='confirmPassword') {
            this.setState({
                confirmPassword: val
            },() =>{
                this.setActive();
            })
        }
        else if (type==='verifyCode') {
            this.setState({
                verifyCode: val
            },() =>{
                this.setActive()
            })
        }
    }

    setActive () {
        let {userName,passWord,confirmPassword,verifyCode} = this.state;
        if (userName || userName===0 || passWord || passWord===0 || confirmPassword || confirmPassword===0 || verifyCode || verifyCode===0) {
            this.setState({
                active: true
            })
            if((userName || userName===0) && (passWord || passWord===0) && (confirmPassword || confirmPassword===0) && (verifyCode || verifyCode===0)) {
                this.setState({
                    btnDisabled: false
                })
            }
        }
        else {
            this.setState({
                active: false,
                btnDisabled: true
            })
        }
    }

    inputKeyup(ev){
        if (ev.keyCode===13) {
            let {userName,passWord,confirmPassword,verifyCode} = this.state;
            if((userName || userName===0) && (passWord || passWord===0) && (confirmPassword || confirmPassword===0) && (verifyCode || verifyCode===0)) {
                this.btnAction();
            }
        }
    }

    inputBlur(ev,type) {
        let {passWord,confirmPassword} = this.state;
        if (type==='passWord') {
            if((passWord||passWord===0) && (confirmPassword || confirmPassword===0) && passWord!==confirmPassword) {
                message.error('两次密码不一致');
                this.setState({
                    passWordError: true,
                    btnDisabled: true
                })
            }
        }
        else if (type==='confirmPassword') {
            if((passWord||passWord===0) && (confirmPassword || confirmPassword===0) && passWord!==confirmPassword) {
                message.error('两次密码不一致');
                this.setState({
                    confirmPasswordError: true,
                    btnDisabled: true
                })
            }
        }
    }

    btnAction() {
        this.setState({
            iconLoading: true
        },() =>{
            let {userName,passWord,confirmPassword,verifyCode} = this.state;
            if (!userName) {
                this.setState({
                    userNameError: true,
                    iconLoading: false
                })
                message.error('请输入手机号/邮箱');
                return;
            }
            if (!passWord) {
                this.setState({
                    passWordError: true,
                    iconLoading: false
                })
                message.error('请输入新密码');
                return;
            }
            if (!verifyCode) {
                this.setState({
                    verifyCodeError: true,
                    iconLoading: false
                })
                message.error('请输入验证码');
                return;
            }
            if (!confirmPassword) {
                this.setState({
                    confirmPasswordError: true,
                    iconLoading: false
                })
                message.error('请输入确认新密码');
                return;
            }
            if (confirmPassword !== passWord) {
                this.setState({
                    confirmPasswordError: true,
                    iconLoading: false
                })
                message.error('两次密码不相同');
                return;
            }
            if (passWord && passWord.length<6) {
                this.setState({
                    passWordError: true,
                    iconLoading: false
                })
                message.error('密码长度需大于6');
                return;
            }
            //...校验,登录
            //md5加密
            passWord = md5(passWord + this.state.md5Salt,32);
            confirmPassword = md5(confirmPassword + this.state.md5Salt,32);
            let postData = {
                username: userName,
                newpwd: passWord,
                confirmpwd: confirmPassword,
                code: verifyCode
            }
            webSdk.search.resetPassword(postData).then(res => {
                if (!res.errCode || res.errCode===0) {
                    message.success('密码重置成功');
                    this.props.history.push({
                        pathname: '/login'
                    })
                    this.setState({
                        iconLoading: false
                    })
                }
                else {
                    this.setState({
                        iconLoading: false
                    })
                    message.error(res.errMsg);
                }
            }).catch(e =>{
                this.setState({
                    iconLoading: false
                })
                message.error('服务器繁忙，请稍后重试');
            })
        })
    }

    toLogin() {
        this.props.history.push({
            pathname: `/login`
        })
    }

    render() {
        return (
            <div className="login-page">
                {/* <div className="login-page-logo">
                    <p><i class="iconfont login-page-logo-icon">&#xe607;</i></p>
                    <p>企业信息库</p>
                </div> */}
                <div className="login-page-logo">
                    北大汇丰企业信息库
                </div>

                <div className="login-main">
                    <div className={this.state.active?"login-box":"unActive login-box"}>
                        <h2 className="login-title">忘记密码</h2>
                        <div className="input-main">
                            {/*账号*/}
                            <div className="input-item user-name-input">
                                <Input
                                    placeholder="请输入手机号／邮箱"
                                    onFocus={() =>this.setState({userNameError:false})}
                                    onChange={ev =>this.changeInput(ev,'userName')}
                                    onKeyUp={ev =>this.inputKeyup(ev)}
                                    className={this.state.userNameError?'input-error login-input':'login-input'}
                                    value={this.state.userName}
                                    style={{width: '100%',height: 42}}
                                    prefix={<span className="font_family icon-Account" style={this.state.userName?{color:"#333"}:{}}/>}
                                />
                            </div>
                            {/*验证码*/}
                            <div className="input-item">
                                <Input
                                    placeholder="请输入验证码"
                                    onFocus={() =>this.setState({verifyCodeError:false})}
                                    onChange={ev =>this.changeInput(ev,'verifyCode')}
                                    onKeyUp={ev =>this.inputKeyup(ev)}
                                    className={this.state.verifyCodeError?'input-error verify-code-input login-input':'verify-code-input login-input'}
                                    value={this.state.verifyCode}
                                    style={{width: '100%',height: 45}}
                                    prefix={<span className="font_family icon-captcha" style={this.state.verifyCode?{color:"#333"}:{}}/>}
                                    suffix={
                                        <Spin spinning={this.state.verifyCodeLoading}>
                                            <span className={parseFloat(this.state.timerTxt).toString()!=='NaN'?'has-send get-verify-code':"get-verify-code"} onClick={this.getVerifyCode}>{this.state.timerTxt}{parseFloat(this.state.timerTxt).toString()!=='NaN'?'s后重新获取':''}</span>
                                        </Spin>
                                    }
                                />
                            </div>
                            {/*密码*/}
                            <div className="input-item">
                                <Input.Password placeholder="请输入新密码"
                                                onFocus={() =>this.setState({passWordError: false,confirmPasswordError:false})}
                                                onChange={ev =>this.changeInput(ev,'passWord')}
                                                onKeyUp={ev =>this.inputKeyup(ev)}
                                                onBlur={ev =>this.inputBlur(ev,'passWord')}
                                                value={this.state.passWord}
                                                className={this.state.passWordError?'input-error login-input':'login-input'}
                                                style={{width: '100%',height: 42}}
                                                prefix={<span className="font_family icon-password" style={this.state.passWord?{color:"#333"}:{}}/>}/>
                                <span className="input-item-password-tips">密码至少6位</span>
                            </div>
                            {/*确认密码*/}
                            <div className="input-item">
                                <Input.Password placeholder="请再次输入新密码"
                                                onFocus={() =>this.setState({passWordError: false,confirmPasswordError:false})}
                                                onChange={ev =>this.changeInput(ev,'confirmPassword')}
                                                onKeyUp={ev =>this.inputKeyup(ev)}
                                                onBlur={ev =>this.inputBlur(ev,'confirmPassword')}
                                                value={this.state.confirmPassword}
                                                className={this.state.confirmPasswordError?'input-error login-input':'login-input'}
                                                style={{width: '100%',height: 42}}
                                                prefix={<span className="font_family icon-password" style={this.state.confirmPassword?{color:"#333"}:{}}/>}/>
                            </div>
                        </div>
                        <div className="action-btn register-btn">
                            <Button
                                className="btn-main"
                                type="primary"
                                disabled={this.state.btnDisabled}
                                loading={this.state.iconLoading}
                                onClick={this.btnAction}
                            >保存</Button>
                        </div>
                        <div className="to-register-line" onClick={this.toLogin}>登录</div>
                    </div>
                </div>

                <div className="copyright-info">
                    <Icon type="copyright" />深圳市圆代码互联网有限公司技术提供
                </div>
            </div>
        )
    }
}

export default FindPassword
