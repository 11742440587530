/*
 * @Author: 符春玲 fuchunling@perfectcode.tech
 * @Date: 2021-09-02 19:32:54
 * @LastEditors: 符春玲 fuchunling@perfectcode.tech
 * @LastEditTime: 2023-03-09 15:37:14
 * @FilePath: \cedb-web\ei-front\src\store\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import SmartSearchStore from './SmartSearchStore'
import HomeLeftStore from './homeStore/HomeLeftStore'
import HomeCenterStore from './homeStore/HomeCenterStore'
import HomeRightStore from './homeStore/HomeRightStore'
import HomeExcelStore from './homeStore/HomeExcelStore'
import HomeCommonStore from './homeStore/HomeCommonStore'
import SyllabusStore from './SyllabusStore'
import ReportStore from './ReportStore'
import FinanceLibraryStore from './FinanceLibraryStore'
import RegulationFallStore from './RegulationFallStore'
import SubjectGraph from './SubjectGraph'
import { useContext } from 'react'
import { MobXProviderContext } from 'mobx-react'
import BusinessStore from './BusinessStore'

let smartSearchStore = new SmartSearchStore()
let homeLeftStore = new HomeLeftStore(),
  homeCenterStore = new HomeCenterStore(),
  homeRightStore = new HomeRightStore(),
  homeExcelStore = new HomeExcelStore(),
  homeCommonStore = new HomeCommonStore()
let syllabusStore = new SyllabusStore(),
  reportStore = new ReportStore()
let financeStore = new FinanceLibraryStore()
let regulationFallStore = new RegulationFallStore()
let subjectGraphStore = new SubjectGraph()
let businessStore = new BusinessStore()

const stores = {
  smartSearchStore,
  homeCommonStore,
  homeLeftStore,
  homeCenterStore,
  homeRightStore,
  homeExcelStore,
  syllabusStore,
  reportStore,
  financeStore,
  regulationFallStore,
  businessStore,
  subjectGraphStore
}

export default stores

export const useStore = (name) => {
  const store = useContext(MobXProviderContext)
  return store[name]
}
