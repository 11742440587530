import React from 'react'
import { Tabs, Select, AutoComplete, Modal, message } from 'antd'
import Highlighter from 'web-highlighter'
import * as request from './request'
import * as echarts from 'echarts'
import $ from 'jquery'
import go from './global'
import './Parser.scss'

const { TabPane } = Tabs
const { Option } = Select

//问题解析
class Parser extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '问题解析',
      //词槽数组索引号
      word_slot_key: 0,
      //词槽下面word索引号
      word_key: 0,
      //当前选中word
      selected_word: '',
      //当前选中词槽key
      cur_slot_type: 'time',
      //词槽对应的知识推断索引号
      infer_key: 0,
      //自动完成组件
      options: [],

      //关系下拉框是否可用
      sltIsAvailable: false
    }
    this.highlighter = {}
    this.intent = [
      { key: 'query_data', value: '查数据' },
      { key: 'search_doc', value: '找资料' },
      { key: 'chart', value: '图表' },
      { key: 'word_st', value: '统计' },
      { key: 'data_source', value: '选数据源' }
    ]
  }

  //改变意图
  changeIntent = (intent) => {
    this.props.changeCurParserData({
      type: 'intent',
      intent: intent
    })
  }

  /////////////////////高亮划词-组件START/////////////////////
  getPosition = ($node) => {
    let offset = {
      top: 0,
      left: 0
    }
    while ($node) {
      offset.top += $node.offsetTop
      offset.left += $node.offsetLeft
      $node = $node.offsetParent
    }
    return offset
  }

  //选中高亮词
  selectWord = (id) => {
    // console.log('selectWord-id',id)
    let light_ele = $('#highlighter>span[data-highlight-id=' + id + ']')
    // console.log('selectWord-id-length',light_ele.length)

    let word_slot_key = light_ele.attr('word_slot_key')
    let word_key = light_ele.attr('word_key')
    let selected_word = word_slot_key + '_' + word_key
    console.log(selected_word)
    this.setState({ selected_word: selected_word })
  }
  //删除高亮
  removeTag = (id) => {
    console.log(id)
    if (id) {
      $('#highlighter>span.my-remove-tip')
        .filter('#' + id)
        .remove()
      $('#highlighter em.word-slot-attr')
        .filter('#' + id)
        .remove()
    } else {
      $('#highlighter>span.my-remove-tip').remove()
      $('#highlighter em.word-slot-attr').remove()
    }
    this.removeSpace()
  }
  //去除空格
  removeSpace = () => {
    if ($('#highlighter').length) {
      $('#highlighter').html(
        $('#highlighter')
          .html()
          .replace(/[\r\n]/g, '')
      )
    }
  }

  /**
   * 创建删除按钮
   */
  createDelTag = (id) => {
    let light_ele = $('#highlighter>span[data-highlight-id=' + id + ']')

    //重复创建检测
    if (light_ele.find('span').length) {
      return
    }
    //删除按钮
    let html =
      '<span data-id="' +
      id +
      '" id="' +
      id +
      '" class="my-remove-tip">×</span>'
    light_ele.append(html)
  }

  /**
   * 创建删除属性标记
   */
  createAttrTag = (id) => {
    let light_ele = $('#highlighter>span[data-highlight-id=' + id + ']')

    //重复创建检测
    if (light_ele.find('em').length) {
      return
    }
    //添加属性标记
    let type_name = light_ele.attr('type_name')
    let html_attr =
      '<em class="word-slot-attr" id="' + id + '">' + type_name + '</em>'
    light_ele.append(html_attr)
  }

  /**
   * 高亮-删除所有标签
   */
  removeAllTags = () => {
    $('#highlighter .my-remove-tip, #highlighter .word-slot-attr').remove()
  }

  //高亮-是否点击的是高亮区纯文本
  isClickText = (e) => {
    let className = $(e.target).prop('class')
    let classArr = [
      'my-remove-tip',
      'word-slot-attr',
      'highlight-mengshou-wrap'
    ]
    if ($.inArray(className, classArr) === -1) {
      return true
    }
    return false
  }

  /**
   * 高亮-添加所有标签
   */
  addAllTags = () => {
    // let curParserData = this.props.curParserData.value
    // let ckey = this.state.word_slot_key
    // let attr = curParserData.word_slot[ckey].type_name //词槽属性
    let that = this
    $('#highlighter>span').each(function () {
      let spanId = $(this).attr('data-highlight-id')
      //创建删除标签
      that.createDelTag(spanId)

      // //创建属性标记
      that.createAttrTag(spanId)
    })
  }

  //创建高亮
  createLighter = () => {
    console.log('createLighter')
    if ($.isEmptyObject(this.highlighter)) {
      return
    }
    //删除旧
    this.highlighter.removeAll()
    this.removeTag()
    // console.log('this.props.curParserData', this.props.curParserData)
    if ($.isEmptyObject(this.props.curParserData)) {
      return
    }

    //创建新
    let curParserData = this.props.curParserData.value
    // let ckey = this.state.word_slot_key
    let selected_word = this.state.selected_word
    // console.log(curParserData.text,pos)

    if (!curParserData) {
      return
    }

    if (!curParserData.word_slot.length) {
      return
    }

    // let attr = curParserData.word_slot[ckey].type_name //词槽属性
    let word_slot = curParserData.word_slot
    if (curParserData.text === '') {
      return
    }

    // if(!curParserData.word_slot[ckey].words.length){
    //   return
    // }
    // console.log('ckey', ckey)

    //批量创建多个选中标签
    for (let key in word_slot) {
      let words = word_slot[key].words
      let type_name = word_slot[key].type_name
      for (let index in words) {
        if (!words.length) {
          continue
        }
        let pos = words[index].pos

        let txt_start_pos = pos[0] //标记的开始位置
        let txt_end_pos = pos[1] //标记的结束位置
        //参数配置
        let start = {
          parentTagName: 'DIV',
          parentIndex: -2,
          textOffset: txt_start_pos
        }
        let end = {
          parentTagName: 'DIV',
          parentIndex: -2,
          textOffset: txt_end_pos
        }
        let text = words[index].text
        let id = go.uid()
        //创建高亮内容
        this.highlighter.fromStore(start, end, text, id)

        //TODO-剔除内容为空的高亮标签
        let light_ele = $('#highlighter>span[data-highlight-id=' + id + ']')
        $('#highlighter>span.highlight-mengshou-wrap:empty').remove()
        light_ele
          .attr('type_name', type_name)
          .attr('word_slot_key', key)
          .attr('word_key', index)

        //选中
        if (selected_word === key + '_' + index) {
          //是否选中状态
          light_ele.addClass('on')
        }
      }
    }

    //统一创建删除按钮、属性标签(由于组件不允许在高亮标签里面创建子标签,会导致pos偏移...)
    let that = this
    $('#highlighter>span').each(function () {
      let spanId = $(this).attr('data-highlight-id')
      //创建删除标签
      that.createDelTag(spanId)

      // //创建属性标记
      that.createAttrTag(spanId)
    })
  }

  /**
   * 添加新词到数据
   * 备注:此处逻辑与index.js不同之处在于,此处为“划词添加到数据”;而index.js的changeCurParserData word_type类型为“把数据已有的词转到新的词槽类”
   */
  addWordsToData = (obj) => {
    obj = obj ? obj : {}
    let curParserData = go.copy(this.props.curParserData.value) //修改此数据
    // let ckey = this.state.word_slot_key
    let ckey
    let cur_slot_type = this.state.cur_slot_type
    let text = obj.text
    let pos = [obj.startMeta.textOffset, obj.endMeta.textOffset]

    //待添加词
    let newWord = {
      text: text,
      pos: pos,
      infer: []
    }
    // console.log('addWordsToData', curParserData)

    //分为-往现有词槽添加和新创建词槽
    ckey = go.getWordSlotKey(curParserData.word_slot, cur_slot_type)

    if (ckey >= 0) {
      /////修改/////
      //检测重复
      let addedWords = []
      let words = curParserData.word_slot[ckey].words
      for (let key in words) {
        let word = words[key].text
        addedWords.push(word)
      }
      // console.log('addedWords', addedWords)
      if ($.inArray(text, addedWords) !== -1) {
        return
      }

      let word_key = words.length
      let selected_word = ckey + '_' + word_key
      this.setState({ selected_word: selected_word })

      //已有数据添加新词
      curParserData.word_slot[ckey].words.push(newWord)
    } else {
      /////新增/////
      let new_word_slot = {
        type: cur_slot_type,
        type_name: go.getSlotTypeName(
          this.props.curParserData.value.slot,
          cur_slot_type
        ),
        words: [newWord]
      }
      ckey = curParserData.word_slot.length
      let word_key = 0
      let selected_word = ckey + '_' + word_key
      this.setState({ selected_word: selected_word })
      curParserData.word_slot.push(new_word_slot)
    }

    this.props.changeCurParserData({ value: curParserData })
  }

  //从数据删除词
  removeWordsFromData = (id) => {
    let obj = $('#highlighter>span[data-highlight-id=' + id + ']').clone() //删除无用标记
    obj.children().remove()
    let curParserData = go.copy(this.props.curParserData.value) //修改此数据
    // let ckey = this.state.word_slot_key
    let ckey = obj.attr('word_slot_key')
    let text = obj.text()
    // let word_key = obj.attr('word_key')
    console.log('text-', text)

    console.log('---curParserData', curParserData)
    console.log('---ckey', ckey)
    if (!curParserData.word_slot.length) {
      return
    }
    if (!curParserData.word_slot[ckey]) {
      return
    }
    if (!curParserData.word_slot[ckey].words.length) {
      return
    }
    //删除操作
    let words = curParserData.word_slot[ckey].words
    console.log('---words', words)

    for (let key in words) {
      if (words[key].text === text) {
        delete curParserData.word_slot[ckey].words[key]
      }
    }
    //数据添加新词
    this.props.changeCurParserData({ value: curParserData })
  }

  /////////////////////高亮划词-组件END/////////////////////

  //组件挂载后...
  componentDidMount() {
    let curParserData = this.props.curParserData.value
    // let ckey = this.state.word_slot_key
    if (!curParserData.word_slot) {
      return
    }
    if (!curParserData.word_slot.length) {
      return
    }
    /*一些默认值设置*/

    //设置词槽文本显示-默认显示第一个
    this.changeWordSlot(this.state.cur_slot_type)

    //画图
    this.drawGraph()

    /////////////////////高亮划词START/////////////////////
    // let attr = curParserData.word_slot[ckey].type_name //词槽属性
    let highlighter = (this.highlighter = new Highlighter({
      $root: $('#highlighter')[0],
      exceptSelectors: ['span', 'em']
    }))
    highlighter.run()
    let that = this

    //事件-鼠标创建
    highlighter
      .on(Highlighter.event.CREATE, (data) => {
        console.log('create -', data)
        let sources = data.sources
        let type = data.type
        //是否为用户手动输入
        if (type === 'from-input') {
          sources.forEach((s) => {
            $('#highlighter>span.highlight-mengshou-wrap:empty').remove() //删除无用标记
            this.createDelTag(s.id) //创建删除标签
            //创建属性标记
            this.createAttrTag(s.id)
            //替换空格
            this.removeSpace()
            //添加词
            this.addWordsToData(s)
            // //设置选中状态
            console.log('selectWord-id', s.id)
          })
          sources = sources.map((hs) => ({ hs }))
          // store.save(sources);
        }
      })
      .on(Highlighter.event.REMOVE, ({ ids }) => {
        if (!$.isEmptyObject(ids)) {
          console.log('remove -', ids)
          // highlighter.remove(ids[0].id)
          // ids.forEach(id => store.remove(id));
        }
      })
      .on(Highlighter.event.CLICK, ({ id }) => {
        console.log('click -', id)
        this.selectWord(id)
      })
    document.addEventListener('click', (e) => {
      const $ele = e.target
      // delete highlight
      if ($ele.classList.contains('my-remove-tip')) {
        const id = $ele.dataset.id
        console.log('*click remove-tip*', id)
        //这个放上面,不然获取不到元素了
        this.removeWordsFromData(id)
        highlighter.remove(id)
        this.removeTag(id)
      }
    })

    //高亮标签里面添加元素导致位移不准，走后添加内部标签模式
    $(document).on('mousedown', '#highlighter', function (e) {
      // console.log('mousedown')
      if (that.isClickText(e)) {
        that.removeAllTags()
      }
    })
    $(document).on('mouseup', '#highlighter', function (e) {
      // console.log('mouseup')
      if (that.isClickText(e)) {
        that.addAllTags()
      }
    })

    this.createLighter() //创建高亮
    /////////////////////高亮划词END/////////////////////
  }

  //状态更新后...
  componentDidUpdate() {
    console.log('update...')
    console.log('curParserData-key', this.props.curParserData.key)
    console.log('curParserData', this.props.curParserData.value)

    //画图
    this.drawGraph()

    this.createLighter() //创建高亮

    //添加滚动条
    go.addSscrollbar(['#col-resource'])
  }

  /////////////////////自动完成组件START/////////////////////
  //这里加载数据
  onSelectSearch = (searchText) => {
    console.log('searchText', searchText)
    //节点的词槽类型，值可以为entity、attribute、time、value
    let type

    let ckey = this.state.word_slot_key
    let word_slot = this.props.curParserData.value.word_slot
    type = word_slot[ckey].type
    request
      .post('/qa/searchNode', { type: type, keyword: searchText })
      .then((res) => {
        //TODO(这里后台要帮改为传递json)
        if (res.nodes) {
          let data = res.nodes
          let options = data.map((item) => {
            return { value: item }
          })
          //将输入的文本添加到下拉框
          options.unshift({ value: searchText })
          this.setState({ options: options })
        }
      })
  }

  //点击添加节点select组件下拉框
  onOptionSelect = (val) => {
    console.log('onSelect', val)
    //更新数据-将当前节点添加到-词槽对应的知识推断列表里面
    let ckey = this.state.word_slot_key
    let word_key = this.state.word_key
    let word_slot = this.props.curParserData.value.word_slot
    if (!word_slot[ckey].words.length) {
      message.warning('没有数据源，无法操作')
      return
    }
    this.props.changeCurParserData({
      word_slot_key: ckey,
      type: 'infer',
      infer_node: val,
      word_key: word_key
    })

    //更新组件-自动

    //更新图表-自动

    //设置节点关系不可用
    this.setState({ sltIsAvailable: false })
  }

  /////////////////////自动完成组件END/////////////////////

  //切换知识推断tab
  changeKnowledgeTab = (activeKey) => {
    console.log('activeKey', activeKey)
    let keyArr = activeKey.split('_')
    let word_slot_key = keyArr[0]
    let word_key = keyArr[1]
    this.setState({
      word_key: word_key,
      word_slot_key: word_slot_key,
      sltIsAvailable: false
    })
  }

  //关系改变
  handleRelationChange = (value) => {
    console.log(value)
    let curParserData = go.copy(this.props.curParserData.value) //修改此数据
    let ckey = this.state.word_slot_key
    let word_key = this.state.word_key
    let infer_key = this.state.infer_key

    if (!curParserData.word_slot[ckey].words.length) {
      message.warning('没有数据源，无法操作')
      return
    }

    //确认框
    Modal.confirm({
      title: '确认执行此操作吗?',
      okText: '确认',
      cancelText: '取消',
      onOk: () => {
        //根据选择关系修改当前数据
        curParserData.word_slot[ckey].words[word_key].infer[
          infer_key
        ].relation = value
        //删除type类型
        delete curParserData.word_slot[ckey].words[word_key].infer[infer_key]
          .type
        this.props.changeCurParserData({ value: curParserData })
      },
      onCancel() {
        console.log('cancel')
      }
    })
  }

  //切换tag(通过子组件控制父组件数据实现)
  changeTitleTag = (ckey) => {
    console.log('changeTitleTag:', ckey)
    this.props.changeCurParserData({
      key: ckey,
      value: this.props.parserData[ckey]
    })
    go.locateToAsk(ckey) //消息定位
  }

  //关闭标签
  closeTitleTag = (ckey) => {
    console.log('closeTitleTag')
    this.props.changeCurParserData({ type: 'delete', key: ckey })
  }

  //点击词槽切换
  changeWordSlot = (cur_slot_type) => {
    let selected_word = this.state.selected_word
    let selected_word_arr = selected_word.split('_')
    console.log('selected_word_arr', selected_word_arr)

    if (cur_slot_type !== this.state.cur_slot_type) {
      this.setState({ cur_slot_type: cur_slot_type, sltIsAvailable: false })
    }

    //修改当前选中的词的属性
    if (selected_word_arr.length > 1) {
      //词方法返回选中词
      let res = this.props.changeCurParserData({
        type: 'word_type',
        from: {
          word_slot_key: selected_word_arr[0],
          word_key: selected_word_arr[1]
        }, //当前选中的词
        // to:{word_slot_key:key}, //要改变成的属性
        to: { cur_slot_type: cur_slot_type } //要改变成的属性(根据cur_slot_type更新)
      })

      //更新当前选中词
      if (!$.isEmptyObject(res) && res.selected_word) {
        this.setState({ selected_word: res.selected_word })
      }
    }
  }

  //画图
  drawGraph() {
    let dom = document.getElementById('graph')
    console.log('dom', dom)
    if (!dom) {
      return
    }
    let myChart = echarts.init(dom)
    myChart.off('click') // 这里很重要！！！
    let option

    if ($.isEmptyObject(this.props.curParserData)) {
      // console.log(111)
      return null
    }

    if ($.isEmptyObject(this.props.curParserData.value)) {
      // console.log(222)
      return null
    }

    let ckey = this.state.word_slot_key
    let word_slot = this.props.curParserData.value.word_slot
    let word_key = this.state.word_key

    if ($.isEmptyObject(word_slot)) {
      // console.log(333)
      return null
    }

    if ($.isEmptyObject(word_slot[ckey])) {
      // console.log(444)
      return null
    }

    //图表的data数据
    let data = []
    //图表的link数据
    let link = []
    //name重复报错问题
    let nameArr = []

    //列出其他有关系数据
    if (word_slot[ckey].words.length && word_slot[ckey].words[word_key]) {
      let cur_word = word_slot[ckey].words[word_key]
      //第一个为源数据
      data.push({
        name: cur_word.text,
        itemStyle: {
          color: '#30C3EF',
          borderWidth: 4
        }
      })
      nameArr.push(cur_word.text)
      cur_word.infer.map((item, index) => {
        let width = 2
        if (this.state.infer_key === index) {
          width = 4
        }

        //重名问题
        let name = item.node
        if ($.inArray(item.node, nameArr) >= 0) {
          name += ' '
        }

        data.push({
          name: name,
          x: 394,
          y: 210,
          itemStyle: {
            color: '#F5905A',
            borderWidth: 4
          }
        })

        if (item.type && item.type === 'add') {
          //如果是新增进来的,用红色虚线表示
          link.push({
            source: 0,
            target: index + 1,
            label: {
              show: true,
              formatter: '？'
            },
            lineStyle: {
              width: width,
              type: 'dotted',
              color: 'red',
              curveness: 0.2
            }
          })
        } else {
          link.push({
            source: 0,
            target: index + 1,
            label: {
              show: true,
              formatter: item.relation ? item.relation : item.relations
            },
            lineStyle: {
              width: width,
              color: '#A0ABC2',
              curveness: 0.2
            }
          })
        }
        return true
      })
    }

    option = {
      title: {
        text: ''
      },
      grid: {
        containLabel: true
      },
      tooltip: {},
      animationDurationUpdate: 1000,
      animationEasingUpdate: 'quinticInOut',
      series: [
        {
          type: 'graph',
          //图的布局circular、force
          layout: 'circular',
          force: {
            // initLayout:'circular',
            repulsion: 50,
            gravity: 0,
            edgeLength: [100, 180],
            layoutAnimation: false
          },
          symbolSize: 80,
          roam: true, //可拖动
          label: {
            show: true
          },
          edgeSymbol: ['circle', 'arrow'],
          edgeSymbolSize: [4, 10],
          edgeLabel: {
            fontSize: 20
          },
          //IMT
          data: data,
          links: link,
          lineStyle: {
            opacity: 0.9,
            width: 2,
            curveness: 0
          }
        }
      ]
    }

    let that = this
    if (option && typeof option === 'object') {
      console.log('option-', option)
      myChart.setOption(option)

      //开启或关闭推断关系下拉框
      myChart.on('click', function (params) {
        console.log('myChart-click', params)

        //只有点击虚线的时候才展示
        // if(params.dataType === 'edge' && params.data.lineStyle.type === 'dotted'){
        if (params.dataType === 'edge') {
          //下拉选择可用状态、设置知识推断索引号
          that.setState({ sltIsAvailable: true, infer_key: params.dataIndex })
        }
      })
    }
  }

  /////////////////////提交与反馈错误START/////////////////////
  //提交
  doSubmit = (obj) => {
    obj = obj ? obj : {}
    //提交的数据类型type=intent、word_slot、infer
    let type = obj.type
    let submit_type = obj.submit_type ? obj.submit_type : 'submit_tagging'
    let curParserData = this.props.curParserData.value
    let ckey = this.state.word_slot_key
    let word_key = this.state.word_key
    let word_slot = curParserData.word_slot
    let text = curParserData.text
    let param
    console.log('doSubmit-word_slot', word_slot)
    if (type === 'intent') {
      param = {
        submit_type: submit_type,
        type: 'intent',
        text: text,
        intent: curParserData.intent,
        intent_name: curParserData.intent_name
      }
    } else if (type === 'word_slot') {
      let param_word_slot = []

      //生成提交参数
      for (let key in word_slot) {
        if (word_slot[key].words.length) {
          word_slot[key].words.forEach(function (item) {
            param_word_slot.push({
              type: word_slot[key].type,
              text: item.text,
              pos: item.pos
            })
          })
        }
      }

      param = {
        submit_type: submit_type,
        type: 'word_slot',
        text: text,
        word_slot: param_word_slot
      }
    } else if (type === 'infer') {
      let nodes = []
      if (!word_slot[ckey].words.length) {
        message.warning('没有数据可提交')
        return
      }
      let cur_infer_arr = word_slot[ckey].words[word_key].infer
      //构造nodes,infer为add类型不提交
      for (let key in cur_infer_arr) {
        if (cur_infer_arr[key].type === 'add') {
          continue
        }
        nodes.push({
          node: cur_infer_arr[key].node,
          relation: cur_infer_arr[key].relation
        })
      }
      //构造发送数据
      param = {
        text: text,
        infer: {
          type: word_slot[ckey].type,
          text: word_slot[ckey].words[word_key].text,
          pos: word_slot[ckey].words[word_key].pos,
          nodes: nodes
        }
      }
    }

    //确认框
    Modal.confirm({
      title: '确认执行此操作吗?',
      okText: '确认',
      cancelText: '取消',
      onOk: () => {
        //ajax提交数据
        request.post('/qa/submit', param).then((res) => {
          // console.log(res);
          if (!res.errCode) {
            message.success('已提交成功！')
            // console.log('已提交成功！')

            if (
              $.inArray(type, ['intent', 'word_slot']) >= 0 &&
              submit_type === 'submit_tagging'
            ) {
              //直接走解析-联动搜索
              this.props.addChatMsgByParser({
                str: curParserData.text,
                intent: curParserData.intent,
                quote: go.quote,
                parse: res.data,
                type: 'data'
              })
            }
          } else {
            message.error(res.errMsg)
          }
        })
      },
      onCancel() {
        console.log('cancel')
      }
    })
  }

  //反馈错误
  doFeedback = (obj) => {
    obj = obj ? obj : {}
    let type = obj.type
    let curParserData = this.props.curParserData.value
    let ckey = this.state.word_slot_key
    let word_key = this.state.word_key
    let text = curParserData.text

    //基础提交参数
    let param = {
      text: text,
      type: type
    }

    if (type === 'infer') {
      let cur_word_slot = curParserData.word_slot[ckey]
      if (!cur_word_slot.words.length) {
        message.warning('没有数据可提交')
        return
      }
      param.detail = {
        type: cur_word_slot.type,
        text: cur_word_slot.words[word_key].text,
        pos: cur_word_slot.words[word_key].pos
      }
    }

    //确认框
    Modal.confirm({
      title:
        '反馈错误后会有后台人员检查问题解析，持续改进模型效果，确认反馈吗？',
      okText: '确认',
      cancelText: '取消',
      onOk: () => {
        //ajax提交数据
        request.post('/qa/feedback', param).then((res) => {
          // console.log(res);
          if (!res.errCode) {
            message.success('已反馈错误！')
          } else {
            message.error(res.errMsg)
          }
        })
      },
      onCancel() {
        console.log('cancel')
      }
    })
  }
  /////////////////////提交与反馈错误END/////////////////////

  render() {
    let tags = []
    // let words = []
    let intent = '' //当前意图
    let parserData = this.props.parserData
    let curParserData = this.props.curParserData
    // let ckey = this.state.word_slot_key
    // console.log(curParserData)

    if (!parserData || $.isEmptyObject(parserData)) {
      return null
    }

    if (!curParserData || $.isEmptyObject(curParserData.value)) {
      return null
    }
    //区别
    if ($.isEmptyObject(curParserData.value.word_slot)) {
      return null
    }
    // let cdata = curParserData.value
    let word_slot = curParserData.value.word_slot

    //处理tag显示
    console.log('parserData', parserData)
    for (let key in parserData) {
      let tagName = parserData[key].text
      let isHide = parserData[key].isHide
      console.log('key', key)
      // console.log('curkey',curParserData.key)
      // console.log('type:',typeof curParserData.key)
      if (isHide) {
        continue
      }
      let ison = key + '' === curParserData.key + '' ? ' on' : ''
      tags.push(
        <span className={'tag' + ison} key={go.uid()}>
          <b
            onClick={() => {
              this.changeTitleTag(key)
            }}
          >
            {tagName}
          </b>
          <em
            onClick={() => {
              this.closeTitleTag(key)
            }}
          >
            ×
          </em>
        </span>
      )
    }

    //划词数组
    // if(curParserData.value.word_slot.length && curParserData.value.word_slot[ckey].words.length){
    //   words = curParserData.value.word_slot[ckey].words
    // }

    intent = curParserData.value.intent_name
    // console.log('intent:', intent)

    //知识推断tab列表
    let wordsArr = []
    for (let key in word_slot) {
      let words = word_slot[key].words
      for (let index in words) {
        if (!words.length) {
          continue
        }

        //定义fkey为ckey+word_key
        let fkey = key + '_' + index
        wordsArr.push({ text: words[index].text, key: fkey })
      }
    }

    //当前实体
    let cur_word = '无'
    let cur_infer_node = '无'
    if (word_slot[this.state.word_slot_key]) {
      console.log('cur_word111')
      let cur_word_slot = word_slot[this.state.word_slot_key]
      if (cur_word_slot.words[this.state.word_key]) {
        console.log('cur_word222')

        let tmp_word = cur_word_slot.words[this.state.word_key]
        cur_word = tmp_word.text

        if (tmp_word.infer && tmp_word.infer[this.state.infer_key]) {
          console.log('cur_word333')
          cur_infer_node = tmp_word.infer[this.state.infer_key].node
        }
      }
    }
    // console.log('this.state',this.state)
    return (
      <div id="col-m" className="parser-cnt">
        <div className="tag-list mb20">{tags}</div>
        <div className="parser">
          <div className="line-title mb30 mt0">
            <span>问题解析</span>
          </div>

          {/* 意图 */}
          <div className="parser-box mb40">
            <div className="sub-title">
              <span>意图</span>
            </div>
            <div className="tab-list">
              {this.intent.map((item, index) => {
                return (
                  <span
                    className={intent === item.value ? 'tab on' : 'tab'}
                    key={go.uid()}
                    onClick={() => {
                      this.changeIntent(item)
                    }}
                  >
                    {item.value}
                  </span>
                )
              })}
            </div>

            <div className="deal">
              <span
                className="sbtn mr15"
                onClick={() => {
                  this.doSubmit({ type: 'intent' })
                }}
              >
                提交
              </span>
              <span
                className="sbtn warning"
                onClick={() => {
                  this.doSubmit({
                    type: 'intent',
                    submit_type: 'feedback_error'
                  })
                }}
              >
                反馈错误
              </span>
            </div>
          </div>

          {/* 词槽 */}
          <div className="parser-box mb40">
            <div className="sub-title">
              <span>词槽</span>
            </div>
            <div className="tab-list radis-border">
              <div className="tab-inner p15" style={{ lineHeight: 2.5 }}>
                {this.props.curParserData.value.slot.map((item) => {
                  return (
                    <span
                      className={
                        this.state.cur_slot_type === item.type
                          ? 'tab on'
                          : 'tab'
                      }
                      key={go.uid()}
                      onClick={() => {
                        this.changeWordSlot(item.type)
                      }}
                    >
                      {item.type_name}
                    </span>
                  )
                })}
              </div>
              <div className="tab-cnt" id="highlighter">
                {this.props.curParserData.value.text}
              </div>
            </div>

            <div className="deal">
              <span
                className="sbtn mr15"
                onClick={() => {
                  this.doSubmit({ type: 'word_slot' })
                }}
              >
                提交
              </span>
              <span
                className="sbtn warning"
                onClick={() => {
                  this.doSubmit({
                    type: 'word_slot',
                    submit_type: 'feedback_error'
                  })
                }}
              >
                反馈错误
              </span>
            </div>
          </div>

          {/* 知识推断 */}
          <div className="parser-box mb40">
            <div className="sub-title">
              <span>知识推断</span>
            </div>
            <Tabs
              defaultActiveKey={this.state.word_key}
              onChange={(activeKey) => this.changeKnowledgeTab(activeKey)}
            >
              {wordsArr.length &&
                wordsArr.map((item, index) => {
                  return <TabPane tab={item.text} key={item.key}></TabPane>
                })}
            </Tabs>

            <div className="tab-list radis-border mt0">
              <div className="tab-inner p15">
                <div className="inner-title mb15">
                  <div className="opt mr20">
                    <em>实体</em>：{cur_word}
                  </div>
                  <div className="opt mr20">
                    <em className="mr10">关系</em>
                    <Select
                      defaultValue=""
                      style={{ width: 100 }}
                      onChange={(value) => this.handleRelationChange(value)}
                      disabled={this.state.sltIsAvailable ? '' : 'disabled'}
                    >
                      <Option value="">请选择</Option>
                      <Option value="等同">等同</Option>
                      <Option value="无">无</Option>
                      <Option value="指代">指代</Option>
                    </Select>
                  </div>
                  <div className="opt mr20">
                    <em>实体</em>：{cur_infer_node}
                  </div>
                  <div className="opt relative mt10">
                    <em className="mr10">添加节点</em>
                    <AutoComplete
                      options={this.state.options}
                      style={{ width: 150 }}
                      onSelect={this.onOptionSelect}
                      onSearch={this.onSelectSearch}
                      placeholder="输入节点名称"
                      getPopupContainer={() =>
                        document.getElementById('parser-result')
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="deal pl15">
                <span
                  className="sbtn mr15"
                  onClick={() => {
                    this.doSubmit({ type: 'infer' })
                  }}
                >
                  提交
                </span>
                <span
                  className="sbtn warning"
                  onClick={() => {
                    this.doFeedback({ type: 'infer' })
                  }}
                >
                  反馈错误
                </span>
              </div>

              <div className="graph">
                <div id="graph" className="graph-inner"></div>
              </div>
            </div>
          </div>
        </div>
        {/* parse end */}
      </div>
    )
  }
}

export default Parser
