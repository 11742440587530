import _ from 'lodash'
import ApiGate from './ApiGate'

class BaseWebApi {
    constructor() {
        this.gate = new ApiGate()
    }
    /**
     * count - 总页数
     * total - 总条目数
     * index - 当前页码, 从0开始
     * size - 每页显示条目
     * @param {*} pager Example: {count: 100, index: 0, size: 10, total: 997}
     */
    _pagerPrev(pager) {
        return _.mapValues(pager||{}, (val, key) => 
            (key === 'index') ? --val : val
        )
    }
    /**
     * See: this._pagerPrev
     * @param {*} pager 
     */
    _pagerNext(pager) {
        return _.mapValues(pager||{}, (val, key) => 
            (key === 'index') ? ++val : val
        )
    }

    TODO = () => Promise.reject('TODO')
}

export default BaseWebApi