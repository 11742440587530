import dom from './dom.js';
var $node = dom.$node,
    outerHeight = dom.outerHeight;

function nodeOrWin(node) {
  return (node ? $node(node) : document.documentElement) || {};
}

export default {
  'h': function h(node) {
    return nodeOrWin(node).scrollHeight;
  },
  'y': function y(node) {
    return nodeOrWin(node).scrollTop;
  },
  'setY': function setY(y, node) {
    return y != undefined && (nodeOrWin(node).scrollTop = y), this;
  },
  'endY': function endY(y, node) {
    return this.setY(this.h(node) - outerHeight(node || 'body') - y, node);
  },
  'x': function x(node) {
    return nodeOrWin(node).scrollLeft;
  },
  'setX': function setX(x, node) {
    return x != undefined && (nodeOrWin(node).scrollLeft = x), this;
  },
  'to': function to(x, y, node) {
    return this.setX(x, node).setY(y, node);
  },
  outerHeight: outerHeight
};