import React, {useEffect, useState} from 'react';
import stores from "../../../store";
import {observer} from 'mobx-react';
import {Spin, Button} from 'antd';
import ReportGraph from "./ReportGraph";
import ViewArticleModal from "../../common/ViewArticleModal/ViewArticleModal";
import {queryReportInfoApi} from './utils'
import ScrollBar from "../../common/ScrollBar";

function ReportInfo(props) {
    const {reportStore} = stores;
    const [id, setId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [infoData, setInfoData] = useState({questionList: []});

    const [articleModal, setArticleModal] = useState(false);
    const [articleModalTitle, setArticleModalTitle] = useState('');
    const [articleModalUrl, setArticleModalUrl] = useState('');

    let unmounted = true;
    useEffect(() => {
        unmounted = false;
        reportStore.setPageInfo([
            {
                label: "报告管理",
                key: "/report/list"
            },
            {
                label: "查看报告",
                key: "/report/info"
            }
        ], 'info');
        let id = props.match.params.id;
        if (id) {
            setId(id);
            queryReportInfo(id);
        }
        return () => {
            unmounted = true;
        }
    }, [props.match.params.id]);

    const queryReportInfo = _id => {
        setLoading(true);
        queryReportInfoApi(_id || id).then(res => {
            setLoading(false);
            let questionList = [];
            if (res.content) {
                try {
                    questionList = JSON.parse(res.content);
                } catch (e) {
                    console.log(e)
                }
            }
            res.questionList = questionList;
            setInfoData(res);
        }).catch(e => {
            setLoading(false);
        })
    };

    const viewArticle = item => {
        setArticleModal(true);
        setArticleModalTitle(item.art_title || '');
        setArticleModalUrl(item.art_url || '');
    };

    const exportClick = () =>{
        console.log('导出')
    };

    const editClick = () => {
        props.history.push({
            pathname: `/report/edit/${id}`
        })
    };

    const QuestionBlock = (index, item) => {
        return (
            <div className="question-info-block">
                <h4 className="question-info-title">问题{index + 1}</h4>
                <h5 className="question-info-ques">{item.question}{item.art_url ?
                    <span onClick={() => viewArticle(item)}>查看原文</span> : null}
                </h5>
                <div className="question-info-content">{QuestionContent(item)}</div>
            </div>
        )
    };

    const QuestionContent = item => {
        let {tag_names} = item;
        // let lastType = questionType[questionType.length-1];
        let types = tag_names.split('/');
        let lastType = types[types.length - 1];
        if (typeof(item.answer) === 'string') {
            return <p className="question-info-text">{item.answer}</p>
        }
        let type = '';
        switch (lastType) {
            case '折线图':
                type = 'line';
                break;
            case "柱状图":
                type = 'bar';
                break;
            case "饼图":
                type = 'pie';
                break;
            case "散点图":
                type = 'scatter';
                break;
            case "K线图":
                type = 'candlestick';
                break;
            case "雷达图":
                type = 'radar';
                break;
            case "树图":
                type = 'tree';
                break;
            case "关系图":
                type = 'graph';
                break;
            default:
                break;
        }
        return <ReportGraph type={type} data={item.answer} questionData={item}/>
    };

    return (
        <div className="report-info">
            <Spin spinning={loading}>
                <ScrollBar className="report-info-scroll">
                    <ul className="report-info-ul">
                        {
                            infoData['questionList'].map((item, index) => {
                                return (
                                    <li key={`info_${index}`} className="report-info-item">
                                        {QuestionBlock(index, item)}
                                    </li>
                                )
                            })
                        }
                    </ul>
                </ScrollBar>
                <div className="report-info-btn">
                    {/*<Button className="cancel-btn" onClick={exportClick}>导出</Button>*/}
                    <Button className="save-btn" type="primary"
                            onClick={editClick}>编辑</Button>

                </div>
            </Spin>
            <ViewArticleModal articleModal={articleModal} setArticleModal={setArticleModal}
                              articleModalTitle={articleModalTitle} articleModalUrl={articleModalUrl}/>
        </div>
    )
}

export default observer(ReportInfo)
