import _ from 'lodash'
//import { arrOffset, mergeMockApi } from './mockWebApi'
import { mergeMockApi } from './mockWebApi'

import SearchResultApi from './SearchResultApi'

export class SearchResultApiMock extends SearchResultApi {
    constructor(opts) {
        super(opts)
        this.mockData = async (url, delay) => {
            delay = (delay || 10) + _.random(0, 1) // 模拟服务端延迟
            const resp = await this.gate.fake.get(url);
            return await new Promise(resolve => {
                setTimeout(() => {
                    resolve(resp);
                }, delay);
            });
        }
    }

    /**测试接口*/
    async getPDFDataById(opts) {
        const resp = await this.mockData('pdf/search_pdf_heightlight.json');
        return resp.data;
    }
}

export default mergeMockApi(SearchResultApi, SearchResultApiMock)