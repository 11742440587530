import React, {useEffect, useState} from 'react';
import stores from "../../../store";
import {observer} from 'mobx-react'
import {Input, Select, message, Button, Spin} from 'antd';
import {webSdk} from "../../../api/WebSdk";
import ScrollBar from "../../common/ScrollBar";
import {querySyllabusListApi, querySyllabusInfoApi, createReportApi} from './utils'

const {Option} = Select;


function CreateReport(props) {
    const {syllabusStore} = stores;
    const [id, setId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectLoading, setSelectLoading] = useState(false);
    const [syllabusList, setSyllabusList] = useState([]);
    const [questionList, setQuestionList] = useState([]);

    const [syllabusId, setSyllabusId] = useState(null);     // 提纲模板id
    const [syllabusName, setSyllabusName] = useState('');   // 提纲模板名称
    const [reportName, setReportName] = useState('');

    const [createLoading, setCreateLoading] = useState(false);

    let unmounted = true;
    useEffect(() => {
        let id = props.match.params.id;
        unmounted = false;
        let breadcrumb = [];
        if (id) {       // 从提纲模板进入
            setId(Number(id));
            setSyllabusId(Number(id));
            breadcrumb = [
                {
                    label: "提纲模板",
                    key: "/syllabus/list"
                },
                {
                    label: "生成报告",
                    key: "/syllabus/createReport"
                }
            ]
            querySyllabusInfo(id);
        } else {        // 从报告管理进入
            breadcrumb = [
                {
                    label: "报告管理",
                    key: "/report/list"
                },
                {
                    label: "生成报告",
                    key: "/syllabus/createReport"
                }
            ]
        }
        syllabusStore.setPageInfo(breadcrumb, 'createReport');
        querySyllabusList();
        return () => {
            unmounted = true;
        }
    }, []);

    const querySyllabusList = () => {
        setSelectLoading(true);
        querySyllabusListApi().then(res => {
            setSelectLoading(false);
            let list = [];
            if (res && res.data) list = res.data || [];
            setSyllabusList(list);
        }).catch(e => {
            setSelectLoading(false);
        })
    };

    const querySyllabusInfo = id => {
        setLoading(true);
        querySyllabusInfoApi(id).then(res => {
            let name = res.name;
            let list = res.questions;
            setLoading(false);
            setSyllabusName(name);
            setQuestionList(list);
        }).catch(e => {
            setLoading(false);
        });
    };

    const selectChange = (val, item) => {
        setSyllabusId(val);
        querySyllabusInfo(val);
    };

    const inputChange = (ev, item) => {
        let val = ev.target.value;
        if (item.index !== undefined) {
            const {qIndex, index} = item;
            let newQuestionList = [...questionList];
            let oldValue = newQuestionList[qIndex]['keyword'][index]['value'];
            newQuestionList.forEach((obj, oIndex) => {
                obj['keyword'] && obj['keyword'].forEach((objK, kIndex) => {
                    if (objK['key'] === newQuestionList[qIndex]['keyword'][index]['key'] && objK['value'] === oldValue) {
                        objK['value'] = val
                    }
                })
            });
            // newQuestionList[qIndex]['keyword'][index]['value'] = val;
            setQuestionList(newQuestionList);
        } else {
            setReportName(val);
        }
    };

    const cancelClick = () => {
        let pathname = '';
        if (id) {
            pathname = `/syllabus/list`
        } else {
            pathname = '/report/list'
        }
        props.history.push({
            pathname
        })
    };

    const createClick = () => {
        if (!syllabusId) {
            message.error('请选择提纲模板');
            return;
        }
        if (!reportName) {
            message.error('请输入报告标题');
            return;
        }
        let verifyFalse = false;
        questionList.forEach((item, index) => {
            if (item.keyword && item.keyword.length > 0) {
                item.keyword.forEach((keyword, keyIndex) => {
                    if (keyword.value === '') {
                        verifyFalse = true;
                        message.error(`请完善问题${index + 1}的变量名称`);
                        return false;
                    }
                })
            }
        });
        if (verifyFalse) return;
        setCreateLoading(true);
        let postData = {
            outline_id: syllabusId,
            outline_name: syllabusName,
            name: reportName,
            questions: questionList
        };
        createReportApi(postData).then(res => {
            setCreateLoading(false);
            props.history.push({
                pathname: `/report/list`
            })
        }).catch(e => {
            setCreateLoading(false);
        })
    };

    const InfoLine = (props) => {
        const {require, label, content, type, defaultValue, sIndex} = props;
        return (
            <div className={`create-info-line info-line-${type}`} key={`info_line_${sIndex}`}>
                <div className="create-line-label">
                    {
                        require ? <span className="create-line-require">*</span> : null
                    }
                    {label}
                </div>
                <div className={`create-line-content line-content-${type}`}>
                    {
                        type === 'text' ?
                            <span className="create-line-text">{content}</span> : type === 'select' ? (
                                <Select
                                    className="create-line-select"
                                    size='large'
                                    style={{width: 289, height: 40}}
                                    placeholder={`请${label}`}
                                    loading={selectLoading}
                                    value={defaultValue || undefined}
                                    onChange={val => selectChange(val, props)}>
                                    {content.map((item, index) => <Option
                                        key={item['id']} value={item['id']}>{item['name']}</Option>)}
                                </Select>
                            ) : type === 'input' ? (
                                <Input
                                    className="create-line-input"
                                    placeholder={`请输入${label}`}
                                    value={content}
                                    onChange={ev => inputChange(ev, props)}
                                    style={{width: 289, height: 40}}/>
                            ) : null
                    }
                </div>
            </div>
        )
    };

    return (
        <div className="syllabus-create-report">
            <ScrollBar className="syllabus-create-scroll">
                <div className="create-info-block">
                    {InfoLine({
                        require: true,
                        label: '选择模板',
                        type: 'select',
                        content: syllabusList,
                        defaultValue: syllabusId,
                        sIndex: 0
                    })}
                    {InfoLine({require: true, label: '报告标题', type: 'input', sIndex: 1, content: reportName})}
                </div>
                <Spin spinning={loading}>
                    {
                        questionList.map((item, index) =>
                            <div className="create-info-block" key={`info_${index}`}>
                                {InfoLine({
                                    label: `问题${index + 1}`,
                                    type: 'text',
                                    content: item.question,
                                    sIndex: `${index}_1`
                                })}
                                {
                                    item.keyword && item.keyword.length ? item.keyword.map((v, vi) => InfoLine({
                                        label: v.key,
                                        type: 'input',
                                        content: v.value,
                                        qIndex: index,
                                        index: vi,
                                        sIndex: `${index}_2_${vi}`
                                    })) : null
                                }
                                {InfoLine({label: '问题类型', type: 'text', content: item.tag_names, sIndex: `${index}_3`})}
                            </div>
                        )
                    }
                </Spin>
            </ScrollBar>
            <div className="syllabus-create-btn">
                <Button onClick={cancelClick}>取消</Button>
                <Button type='primary' onClick={createClick} loading={createLoading}>生成报告</Button>
            </div>
        </div>
    )
}

export default observer(CreateReport)
