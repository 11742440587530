import React, { PureComponent } from 'react'
import _ from 'lodash'
import { Empty, Spin } from 'antd';
import "./Framework.scss"
import { webSdk } from '../../../../api/WebSdk';

class Framework extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            currentYear: this.props.currentYear,//当前年份
            tickerSymbol: this.props.tickerSymbol,//股票代码
            pageRequestParams: this.props.pageRequestParams,//后端请求所需值
            spinLoading: true,//表格加载状态
            topManage: {//高管
                columns: [
                    {
                        title: '姓名',
                        key: 'name',
                    },
                    {
                        title: '职务',
                        key: 'duty',
                    },
                    {
                        title: '直接持股数',
                        key: 'holdingCount',
                    },
                    {
                        title: '任期',
                        key: 'tenure',
                    },
                    {
                        title: '出生年份',
                        key: 'birthday',
                    },
                    {
                        title: '薪酬',
                        key: 'salary',
                    },
                    {
                        title: '学历',
                        key: 'education',
                    },
                    {
                        title: '国籍',
                        key: 'nationality',
                    },
                ],
                data: [
                    // {
                    //     name: "asdf",
                    //     duty: 'John Brown',
                    //     holdingCount: "32",
                    //     tenure: "10%",
                    //     birthday: "10%",
                    //     age: "10%",
                    //     salary: "10%",
                    //     education: "10%",
                    //     nationality: '2018',
                    // }
                ]
            },
            stockholder: {//股东
                columns: [
                    {
                        title: '参股公司名称',
                        key: 'stockCompanyName',
                        minWidth: 210
                    },
                    {
                        title: '参控关系',
                        key: 'stockRelative',
                    },
                    {
                        title: '参控比例(%)',
                        key: 'stockRate',
                    },
                    {
                        title: '持股数量(万股)',
                        key: 'stockSum',
                    },
                    {
                        title: '股份性质',
                        key: 'stockStyle',
                    },
                ],
                data: [
                    // {
                    // stockCompanyName: "asdf",
                    // stockStyle: 'John Brown',
                    // stockRelative: "10%",
                    // stockRate: "10%",
                    // stockSum: "10%",
                    // beStockProfit: "10%",
                    // isReport: "10%",
                    // beStockBusiness: "10%",
                    // name: "10%",
                    // identity: "10%",
                    // isEmployee: "10%",
                    // }
                ]
            },
            concertPerson: {//一致行动人
                columns: [
                    {
                        title: '股东名称',
                        key: 'stockHolderName',
                    },
                    {
                        title: '持股数量(股)',
                        key: 'stockCount',
                    },
                    {
                        title: '持股比例(%)',
                        key: 'stockRate',
                    },
                ],
                data: [
                    // {
                    // stockHolderName: "asdf",
                    // stockCount: 'John Brown',
                    // stockRate: "32",
                    // }
                ]
            },
            subCompany: {//子公司
                columns: [
                    {
                        title: '被参股公司名称',
                        key: 'beStockCompany',
                        minWidth: 200
                    },
                    {
                        title: '被参股公司代码简称',
                        key: 'beStockCompanyCode',
                    },
                    {
                        title: '参控关系',
                        key: 'controlRelative',
                    },
                    {
                        title: '参控比例(%)',
                        key: 'controlRate',
                    },
                    {
                        title: '投资金额(万元)',
                        key: 'investSum',
                    },
                    {
                        title: '被参控公司净利润(万元)',
                        key: 'beStockCompanyProfit',
                        minWidth: 200
                    },
                    {
                        title: '是否报表合并',
                        key: 'isReportMix',
                    },
                    {
                        title: '参股公司主营业务',
                        key: 'stockCompanyBusiness',
                        minWidth: 500
                    },
                    {
                        title: '被参股公司主营业务',
                        key: 'beStockCompanyBusiness',
                        minWidth: 500
                    },
                ],
                data: [
                    // {
                    // beStockCompany: "asdf",
                    // beStockCompanyCode: 'John Brown',
                    // controlRelative: "32",
                    // controlRate: "32",
                    // investSum: "32",
                    // beStockCompanyProfit: "32",
                    // isReportMix: "32",
                    // stockCompanyBusiness: "32",
                    // beStockCompanyBusiness: "32",
                    // }
                ]
            },
            investment: {//投资
                columns: [
                    {
                        title: '投资公司名称',
                        key: 'investCompany',
                    },
                    {
                        title: '初始投资金额(万元)',
                        key: 'initalInvestSum',
                    },
                    {
                        title: '持股数量(股)',
                        key: 'stockCount',
                    },
                    {
                        title: '占该公司股权比(%)',
                        key: 'stockWeightRate',
                    },
                    {
                        title: '投资公司类型',
                        key: 'investCompanyType',
                    },
                ],
                data: [
                    // {
                    // investCompany: "asdf",
                    // initalInvestSum: 'John Brown',
                    // stockCount: "32",
                    // stockWeightRate: "32",
                    // investCompanyType: "32",
                    // }
                ]
            },
        }
    }

    componentDidMount() {
        this.getData()
    }

    componentWillReceiveProps(nextProps) {
        let newCurrentYear = nextProps.currentYear
        let newTickerSymbol = nextProps.tickerSymbol
        let newPageRequestParams = JSON.stringify(nextProps.pageRequestParams)
        let oldPageRequestParams = JSON.stringify(this.state.pageRequestParams)
        if (newCurrentYear != this.state.currentYear || newTickerSymbol !== this.state.tickerSymbol || newPageRequestParams != oldPageRequestParams) {
            this.setState({
                currentYear: newCurrentYear,
                tickerSymbol: newTickerSymbol,
                pageRequestParams: nextProps.pageRequestParams
            }, () => {
                this.getData()
            })
        }
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        }
    }

    /**获取数据 */
    getData() {
        let topManage = { ...this.state.topManage }
        let stockholder = { ...this.state.stockholder }
        let subCompany = { ...this.state.subCompany }
        let investment = { ...this.state.investment }
        let pageRequestParams = this.state.pageRequestParams
        let spinLoading = true

        this.setState({
            spinLoading
        }, () => {
            spinLoading = false
            let params = {
                tickerSymbol: this.state.tickerSymbol,
                year: this.state.currentYear,
            }

            if (pageRequestParams && pageRequestParams.pageType == "framework" && pageRequestParams.fnType) {
                params.table = pageRequestParams.fnType
                params.data = pageRequestParams.paramData
            }

            webSdk.entersInforApi.getFrameworkInfo(params).then(res => {
                let topManageList = res.topManage
                _.forEach(topManageList, item => {
                    _.forIn(item, (value, key) => {
                        if (!value) {
                            item[key] = "-"
                        }
                    })
                })
                topManage.data = topManageList

                let stockholderList = res.stockholder
                _.forEach(stockholderList, item => {
                    _.forIn(item, (value, key) => {
                        if (!value) {
                            item[key] = "-"
                        }
                    })
                })
                stockholder.data = stockholderList

                let subCompanyList = res.subCompany
                _.forEach(subCompanyList, item => {
                    _.forIn(item, (value, key) => {
                        if (!value) {
                            item[key] = "-"
                        }
                    })
                })
                subCompany.data = subCompanyList

                let investList = res.investment
                _.forEach(investList, item => {
                    _.forIn(item, (value, key) => {
                        if (!value) {
                            item[key] = "-"
                        }
                    })
                })
                investment.data = investList

                this.setState({
                    spinLoading, topManage, stockholder, subCompany, investment
                }, () => {
                    this.toPagePoint()
                })
            })
        })
    }

    /**到页面指定位置 */
    toPagePoint() {
        let pageRequestParams = this.state.pageRequestParams
        if (!pageRequestParams || pageRequestParams.pageType != "framework") return;
        let fnType = pageRequestParams.fnType
        let fnId = ""
        switch (fnType) {
            case "manager":
                fnId = "topManageBlock"
                break;
            case "shareholder":
                fnId = "stockholderBlock"
                break;
            case "actPerson":
                fnId = "concertPersonBlock"
                break;
            case "subCompany":
                fnId = "subCompanyBlock"
                break;
            // case "invest":
            //     fnId = "investmentBlock"
            //     break;
        }

        if (fnId) {
            this.showHightSpot(fnId)
        }
    }

    /**展示高亮块 */
    showHightSpot(id) {
        let pageRequestParams = this.state.pageRequestParams
        if (!pageRequestParams || pageRequestParams.pageType != "framework") return;
        let block = document.getElementById(id)
        if (!block) return;
        let topVal = block.offsetTop
        this.props.fnChangeScroll(topVal)

    }

    render() {
        return (
            <div className="framework">
                <div id="topManageBlock" className="framework-leader">
                    <h3>高管</h3>
                    <div className="framework-leader-tb">
                        <div>
                            <table>
                                <thead>
                                    <tr>
                                        {
                                            this.state.topManage.columns.map((item, idx) => (
                                                <th
                                                    key={item.key}
                                                    style={{
                                                        minWidth: `${item.minWidth}px`
                                                    }}
                                                >{item.title}</th>
                                            ))
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        _.isEmpty(this.state.topManage.data) || this.state.spinLoading ? (
                                            <tr>
                                                <td className="empty" colSpan="7">
                                                    <Spin spinning={this.state.spinLoading}>
                                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                    </Spin>
                                                </td>
                                            </tr>
                                        ) : this.state.topManage.data.map((item, idx) => (
                                            <tr key={idx}>
                                                {
                                                    this.state.topManage.columns.map((colItem, colIdx) => {
                                                        let key = colItem.key
                                                        let value = item[key]
                                                        return (
                                                            <td key={key}>{value}</td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div id="stockholderBlock" className="framework-stockholder">
                    <h3>股东</h3>
                    <div className="framework-stockholder-tb">
                        <div>
                            <table>
                                <thead>
                                    <tr>
                                        {
                                            this.state.stockholder.columns.map((item, idx) => (
                                                <th
                                                    key={item.key}
                                                    style={{
                                                        minWidth: `${item.minWidth}px`
                                                    }}
                                                >
                                                    {item.title}</th>
                                            ))
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        _.isEmpty(this.state.stockholder.data) || this.state.spinLoading ? (
                                            <tr>
                                                <td className="empty" colSpan="6">
                                                    <Spin spinning={this.state.spinLoading}>
                                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                    </Spin>
                                                </td>
                                            </tr>
                                        ) : this.state.stockholder.data.map((item, idx) => (
                                            <tr key={idx}>
                                                {
                                                    this.state.stockholder.columns.map((colItem, colIdx) => {
                                                        let key = colItem.key
                                                        let value = item[key]
                                                        return (
                                                            <td key={key}>{value}</td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div id="concertPersonBlock" className="framework-action">
                    <h3>一致行动人</h3>
                    <div className="framework-action-tb">
                        <div>
                            <table>
                                <thead>
                                    <tr>
                                        {
                                            this.state.concertPerson.columns.map((item, idx) => (
                                                <th
                                                    key={item.key}
                                                    style={{
                                                        minWidth: `${item.minWidth}px`
                                                    }}
                                                >{item.title}</th>
                                            ))
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        _.isEmpty(this.state.concertPerson.data) || this.state.spinLoading ? (
                                            <tr>
                                                <td className="empty" colSpan="3">
                                                    <Spin spinning={this.state.spinLoading}>
                                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                    </Spin>
                                                </td>
                                            </tr>
                                        ) : this.state.concertPerson.data.map((item, idx) => (
                                            <tr key={idx}>
                                                {
                                                    this.state.concertPerson.columns.map((colItem, colIdx) => {
                                                        let key = colItem.key
                                                        let value = item[key]
                                                        return (
                                                            <td key={key}>{value}</td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div id="subCompanyBlock" className="framework-subcompany">
                    <h3>子公司</h3>
                    <div className="framework-subcompany-tb">
                        <div>
                            <table>
                                <thead>
                                    <tr>
                                        {
                                            this.state.subCompany.columns.map((item, idx) => (
                                                <th
                                                    key={item.key}
                                                    style={{
                                                        minWidth: `${item.minWidth}px`
                                                    }}
                                                >{item.title}</th>
                                            ))
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        _.isEmpty(this.state.subCompany.data) || this.state.spinLoading ? (
                                            <tr>
                                                <td className="empty" colSpan="7">
                                                    <Spin spinning={this.state.spinLoading}>
                                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                    </Spin>
                                                </td>
                                            </tr>
                                        ) : this.state.subCompany.data.map((item, idx) => {
                                            return (
                                                <tr key={idx}>
                                                    {
                                                        this.state.subCompany.columns.map((colItem, colIdx) => {
                                                            let key = colItem.key
                                                            let value = item[key]
                                                            let subCompanyHtml = <td key={key}>{value}</td>

                                                            //合并 参股公司主营业务 列数据
                                                            if (key == "stockCompanyBusiness") {
                                                                if (idx == 0) {
                                                                    subCompanyHtml = <td key={key} rowSpan={this.state.subCompany.data.length}>{item.stockCompanyBusiness}</td>
                                                                } else {
                                                                    subCompanyHtml = null
                                                                }
                                                            }
                                                            return (
                                                                subCompanyHtml
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                {/* <div id="investmentBlock" className="framework-investment">
                    <h3>投资</h3>
                    <div className="framework-investment-tb">
                        <div>
                            <table>
                                <thead>
                                    <tr>
                                        {
                                            this.state.investment.columns.map((item, idx) => (
                                                <th
                                                    key={item.key}
                                                    style={{
                                                        minWidth: `${item.minWidth}px`
                                                    }}
                                                >{item.title}</th>
                                            ))
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        _.isEmpty(this.state.investment.data) || this.state.spinLoading ? (
                                            <tr>
                                                <td className="empty" colSpan="5">
                                                    <Spin spinning={this.state.spinLoading}>
                                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                    </Spin>
                                                </td>
                                            </tr>
                                        ) : this.state.investment.data.map((item, idx) => (
                                            <tr key={idx}>
                                                {
                                                    this.state.investment.columns.map(colItem => {
                                                        let key = colItem.key
                                                        let value = item[key]
                                                        return (
                                                            <td key={key}>{value}</td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div> */}
            </div>
        );
    }
}

export default Framework;