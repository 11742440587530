import React, {useEffect, useState, useRef} from 'react'
import {Spin, Checkbox, Button, Pagination, Popover} from 'antd'
import ScrollBar from "../../common/ScrollBar";
import OpenIcon from "./OpenIcon";
import stores from "../../../store";
import {observer} from 'mobx-react'
import {questionAndCorpusAna, getCorpusMatch, getAbstractAna, getCorpusPosition} from './utils'

/*
[{art_date: "2020-06-23"
art_id: 685825
art_title: "非住宅物业管理专题报告之三：出圈城市服务，迈向星辰大海"
art_url: "http://fs.phbs-ai.perfectcode.tech/pdf/researchreport/2020/06/4268762.pdf"
id: 1133002
kp_id: [129315692, 129315693, 129315694, 129315695, 129315696, 129315697, 129315698]
page: 3
pos_list: ""
text: "物管龙头对于..."},...]
* */

/* [{
        "id": 0,
        "text": "2、优化组织架构,完善人才体系\n2019年,公司因势而变,调整组织架构,优化岗位职责、业务流程,提高员工个人和组织创造价值能力,培养团队专业能力,建立学习型组织、阶梯形人才培养体系,加强引进核心骨干,建立与现代公司治理相适应的管理运作机制。\n报告期内,公司试行绩效考核机制,围绕年度战略目标,定性、定量衡量管理层和全体员工业绩完成情况及对公司的贡献,建设长效人才机制,让人才资源真正实现增值增效。\n3、深化体系建设\n2019年,公司继续深化体系建设,实现项目分层分类、责任清晰,稳步修正和深入推进IPD(Integrated Product Development, 简称IPD,集成产品开发)、ITR(Issue to Resolution,简称ITR,问题到解决)、CRM(Customer Relationship Management,简称CRM,客户关系管理)等管理系统的落地实施,建立立项决策机制,有效管控需求。",
        "kp_id": [2277759, 2277760, 2277761, 2277762, 2277763],
        "art_id": 5121,
        "title": "2019年年度报告"
        },...
]*/
let clickFlag = false;

const LeaderLine = window.LeaderLine;       //  https://anseki.github.io/leader-line/
let line;

function HomeCenter(props) {
    const {homeLeftStore, homeCenterStore, homeRightStore, homeCommonStore} = stores;

    let {
        centerOpen, corpusMatchList, corpusMatchLoading, corpusMatchChecked, setCorpusMatchChecked, setCrtCorpusItem,
        crtCorpusId, setCrtCorpusId, page, totalNum, pageSize
    } = homeCenterStore;

    const [showList, setShowList] = useState([]);
    const centerScroll = useRef(null);

    // 查找问题关键字
    useEffect(() => {
        if (corpusMatchList.length) {
            const {questionList, crtQuestionIndex} = homeLeftStore;
            let question = questionList[crtQuestionIndex].text;
            if (question.length === 0) return;
            let newList = JSON.parse(JSON.stringify(corpusMatchList));
            newList.forEach((item, index) => {
                for (let i = 0; i < question.length; i++) {
                    let str = question[i];
                    if (str === '') continue;
                    let resStr = `<span style="color: #FF0000">${str}</span>`;
                    item.art_title = item.art_title.replaceAll(str, resStr);
                    item.text = item.text.replaceAll(str, resStr);
                }
            })
            setShowList(newList);
        }
    }, [corpusMatchList]);

    // 双击右侧摘要，设置滚动位置
    useEffect(() =>{
        if (homeRightStore.dbClickId === null) return;
        if (!centerScroll || !centerScroll.current) return;
        const {dbClickId: id, positionTop: corpusTop} = homeRightStore;
        let center = document.getElementById(`corpus_${id}`);
        if (!center) return;
        let itemPosition = center.getBoundingClientRect() || {};
        let itemPositionTop = itemPosition.top || 0;
        let moveTop = itemPositionTop - corpusTop;
        let oldScrollTop = centerScroll.current._container.scrollTop;
        let resTop = oldScrollTop + moveTop;
        centerScroll.current._container.scrollTop = resTop < 0 ? 0 : resTop;
        homeRightStore.setDbItem(null, null);
    },[homeRightStore.dbClickId]);

    useEffect(() =>{
        if (homeCenterStore.dbClickId === null) {
            if (line) line.position();
        }
    },[homeCenterStore.dbClickId]);

    // 勾选框时间
    const checkedClick = id => {
        let checkedValue = [...corpusMatchChecked];
        let i = checkedValue.indexOf(id);
        if (i !== -1) {
            checkedValue.splice(i, 1);
        } else {
            checkedValue.push(id);
        }
        setCorpusMatchChecked(checkedValue);
    };

    // 语料点击
    const corpusClick = (ev, item, index) => {
        if (clickFlag) { // 双击
            clickFlag = false;
            corpusDbClick(ev, item)
        } else {    // 单击
            clickFlag = true;
            setTimeout(() => {
                if (clickFlag) {
                    homeRightStore.setAttributeModal(false);
                    homeCommonStore.setRightStatus(1);
                    if (crtCorpusId === item["id"]) {
                        if (homeRightStore.corpusAnaFalse) questionAndCorpusAna('sent', item["id"]);
                        return;
                    }
                    setCrtCorpusItem(item);
                    setCrtCorpusId(item["id"]);
                    homeRightStore.setRightOpen(true);
                    homeRightStore.setCorpusAnaList([]);

                    // 获取右侧语料解析数据
                    questionAndCorpusAna("sent", item["id"]);
                }
                clickFlag = false;
            }, 300);
        }
        /*let parentClass = ev.target && ev.target.parentNode && ev.target.parentNode.className || "无父节点class";
        if (ev.target.className === "ant-checkbox-input" || parentClass.indexOf("ant-checkbox-wrapper") !== -1) {

        } else {
            homeRightStore.setAttributeModal(false);
            homeCommonStore.setRightStatus(1);
            if (crtCorpusId === item["id"]) {
                return;
            }
            setCrtCorpusItem(item);
            setCrtCorpusId(item["id"]);
            homeRightStore.setRightOpen(true);
            homeRightStore.setCorpusAnaList([]);

            // 获取右侧语料解析数据
            questionAndCorpusAna("sent", item["id"]);
        }*/
    };

    const corpusDbClick = (ev, item, index) => {
        if (homeCommonStore.rightStatus !== 2) return;
        let center = document.getElementById(`corpus_${item["id"]}`);
        let centerTop = center.getBoundingClientRect() || {};
        let top = centerTop.top || 0;
        homeCenterStore.setDbItem(item.id, top);
    };

    // 页码变更
    const pageChange = (page, pageSize) => {
        homeCenterStore.setPageData(page);
        getCorpusMatch(page)
    };

    // 生成摘要事件
    const createAbstract = () => {
        homeRightStore.setAttributeModal(false);
        homeRightStore.setRightOpen(true);
        homeCommonStore.setRightStatus(2);
        getAbstractAna();
    };

    // 标题点击
    const artTitleClick = (item, ev) => {
        checkedClick(item['id']);
        // 阻止合成事件的冒泡
        ev.stopPropagation();
        // 阻止与原生事件的冒泡
        ev.nativeEvent.stopImmediatePropagation();
    };

    // 标题查看原文事件
    const viewArtModal = (item, ev) => {
        homeCenterStore.setArticleModal(true);
        homeCenterStore.setArticleModalTitle(item['_art_title']);
        homeCenterStore.setArticleModalUrl(item['art_url']);
        // 請求語料位置
        getCorpusPosition(item['kp_id'] || null);
        // 阻止合成事件的冒泡
        ev.stopPropagation();
        // 阻止与原生事件的冒泡
        ev.nativeEvent.stopImmediatePropagation();
    };

    const setStyle = () => {
        let n = 0;
        if (homeLeftStore.leftOpen) n++;
        if (homeCenterStore.centerOpen) n++;
        if (homeRightStore.rightOpen) n++;
        let width = 0;
        if (n === 1) width = 'calc(100% - 120px)';
        if (n === 2) width = 'calc(100% - 44px - 32px)/2';
        if (n === 3) width = 'calc(100% - 32px)/3';
        if (homeCommonStore.questionType === 0) {
            if (centerOpen) {
                return {flex: 1, width}
            } else {
                return {width: 44, flexShrink: 0}
            }
        } else {
            return {display: "none"}
        }
    };

    const corpusMouseEnter = item => {
        if (homeCommonStore.rightStatus !== 2) return;
        let id = item.id || item;
        let start = document.getElementById('corpus_' + id);
        if (!start) return;
        let end = document.getElementById('abstract_' + id);
        if (!end) return;
        homeCenterStore.setHoverId(id);
        homeRightStore.setHoverId(id);
        line = new LeaderLine(
            start,
            end,
            {
                color: '#5B6BAE',       // 线条颜色
                size: 2,                // 线条粗细
                // dash: dash !== undefined,             // 虚线
                gradient: false,
                startPlug: 'disc',        // 开始的图标      //https://anseki.github.io/leader-line/#startplug-endplug
                endPlug: 'disc',          // 末尾的图标
                hide: true
            });
        line.setOptions({startSocket: 'right', endSocket: 'left'});
        line.show('draw', {
            duration: 300,                  // 总时
            timing: [0.5, 0, 0.5, 0.42]       // [x1, y1, x2, y2]作为“定时功能” 的数组
        })
    };

    const corpusMouseLeave = item => {
        homeCenterStore.setHoverId(null);
        homeRightStore.setHoverId(null);
        if (!line) return;
        line.hide();
        line.remove();
        line = null;
    };

    return (
        <div className="new-home-center" style={setStyle()}>
            <div className="home-content">
                <OpenIcon open={centerOpen} title="语料匹配" type='center'/>
                <div className="home-main-content center-content" style={centerOpen ? {} : {display: 'none'}}>
                    <Spin spinning={corpusMatchLoading}>
                        {
                            homeLeftStore.crtQuestionIndex === null ?
                                <div style={{padding: '0 16px'}}>点击左侧问题提纲问题获取匹配语料</div> : (
                                    <>
                                        {corpusMatchList.length === 0 ? null :
                                            <h3 className="corpus-match-title">问题{homeLeftStore.crtQuestionIndex + 1}</h3>
                                        }
                                        <ScrollBar className="corpus-match-scroll-bar" ref={centerScroll}>
                                            <ul className="corpus-match-list">
                                                {
                                                    showList.map((item, index) => {
                                                        let cls = "corpus-match-item";
                                                        if (crtCorpusId === item["id"] && homeCommonStore.rightStatus === 1) cls += ' active-corpus-item';
                                                        if (item.id === homeRightStore.hoverId) cls += " corpus-match-item-line";
                                                        return (
                                                            <li key={item["id"]}
                                                                id={`corpus_${item["id"]}`}
                                                                onMouseEnter={() => corpusMouseEnter(item)}
                                                                onMouseLeave={() => corpusMouseLeave(item)}
                                                                className={cls}
                                                                onClick={ev => corpusClick(ev, item)}>
                                                                <h4 className="corpus-match-item-title"
                                                                    onClick={e => artTitleClick(item, e)}>
                                                                    <Checkbox
                                                                        checked={corpusMatchChecked.indexOf(item["id"]) !== -1}
                                                                        onChange={() => checkedClick(item["id"])}
                                                                        value={item["id"]}>
                                                                    </Checkbox>
                                                                    <div className="corpus-item-title-block">
                                                                        <Popover content={item["_art_title"]} trigger="hover"
                                                                                 placement="bottomLeft"
                                                                                 className="corpus-item-title-text">
                                                                            <span
                                                                                className="abstract-title"
                                                                                dangerouslySetInnerHTML={{__html: item["art_title"]}}/>
                                                                        </Popover>
                                                                        <span className="view-text"
                                                                              onClick={(e) => viewArtModal(item, e)}>查看原文</span>
                                                                    </div>
                                                                </h4>
                                                                <p className="corpus-match-item-content"
                                                                   style={{whiteSpace: 'pre-wrap'}}
                                                                   dangerouslySetInnerHTML={{__html: `${item["art_date"]} ${item.text}`}}/>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </ScrollBar>
                                    </>
                                )
                        }
                        {
                            corpusMatchList.length === 0 ? null : (
                                <div className="home-center-button">
                                    {
                                        totalNum / pageSize <= 1 ? null :
                                            <Pagination className="home-center-page" simple current={page}
                                                        pageSize={pageSize} total={totalNum} onChange={pageChange}/>
                                    }
                                    <div className="home-center-button-action">
                                        <Button loading={homeRightStore.abstractAnaLoading} type="primary"
                                                onClick={createAbstract}>生成摘要</Button>
                                    </div>
                                </div>
                            )
                        }
                    </Spin>
                </div>
            </div>
        </div>

    )
}

export default observer(HomeCenter)
