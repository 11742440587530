import React from 'react'
import { withRouter } from 'react-router-dom'
import './App.css'
import './css/reset.scss'
import { ConfigProvider, message, Spin } from 'antd'
import zhCN from 'antd/lib/locale-provider/zh_CN'
import 'antd/dist/antd.css'
import 'moment/locale/zh-cn'
import AppContext from './AppContext'
import _ from 'lodash'
import { webSdk } from './api/WebSdk'

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasLogin: window.sessionStorage.getItem('hasLogin') || null,
      getHistory: () => {
        return this.props.history
      }
    }
  }

  componentDidMount() {
    // For Debug-In-Console
    global._history = this.props.history
    global._app = this

    let hasLogin = this.state.hasLogin
    let pathname = this.props.location.pathname
    let loginPage =
      pathname === '/login' ||
      pathname === '/register' ||
      pathname === '/findPassword'

    if (!hasLogin) {
      let userName = window.localStorage.getItem('userName') || ''
      let passWord = window.localStorage.getItem('passWord') || ''
      if (!loginPage) {
        if (userName && passWord) {
          let postData = {
            username: userName,
            pwd: passWord
          }
          webSdk.search
            .doLogin(postData)
            .then((res) => {
              if (!res.errCode || res.errCode === 0) {
                this.setState(
                  {
                    hasLogin: '1'
                  },
                  () => {
                    window.sessionStorage.setItem('hasLogin', '1')
                    window.localStorage.setItem(
                      'userInfo',
                      JSON.stringify(res.user)
                    )
                  }
                )
              } else {
                window.sessionStorage.setItem('hasLogin', '')
                window.localStorage.setItem('userInfo', '')
                this.props.history.push({
                  pathname: '/login'
                })
                message.error(res.errMsg || '登录失败，请重新登录')
              }
            })
            .catch((e) => {
              window.sessionStorage.setItem('hasLogin', '')
              window.localStorage.setItem('userInfo', '')
              this.props.history.push({
                pathname: '/login'
              })
              message.error('服务器繁忙，请重新登录')
            })
        } else {
          webSdk.search
            .getRecentSearch({ size: 0 })
            .then((res) => {
              if (res) {
                this.setState(
                  {
                    hasLogin: '1'
                  },
                  () => {
                    window.sessionStorage.setItem('hasLogin', '1')
                  }
                )
              }
            })
            .catch((e) => {
              window.sessionStorage.setItem('hasLogin', '')
              window.localStorage.setItem('userInfo', '')
              this.props.history.push({
                pathname: '/login'
              })
              message.info('未登录')
            })
        }
      }
    } else {
      if (loginPage) {
        this.props.history.push({
          pathname: '/'
        })
      }
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    let { hasLogin } = this.state
    let newHasLogin = window.sessionStorage.getItem('hasLogin') || null
    if (hasLogin !== newHasLogin) {
      this.setState({
        hasLogin: newHasLogin
      })
    }
  }

  render() {
    let pathname = this.props.location.pathname
    let loginPage =
      pathname === '/login' ||
      pathname === '/register' ||
      pathname === '/findPassword'

    return (
      <AppContext.Provider value={this.state}>
        {!this.state.hasLogin && !loginPage ? (
          <Spin tip="自动登录..." wrapperClassName="App-loading-spin">
            <div className="App-loading" />
          </Spin>
        ) : (
          <div className="App">
            <ConfigProvider locale={zhCN}>
              <div className="App__Main">{this.props.children}</div>
            </ConfigProvider>
          </div>
        )}
      </AppContext.Provider>
    )
  }
}

export default withRouter(App)
