//import _ from 'lodash'
import BaseWebApi from './BaseWebApi'
import _ from "lodash"

class EntersInfoApi extends BaseWebApi {
    constructor(options) {
        super()
        this._api = this.gate.search
        this._apiV2 = this.gate.searchV2
    }

    /**获取所有的行业*/
    async getAllIndustry() {
        let resp = await this._apiV2.postForm('search/get_all_industry')
        return resp.data

        // return await this.TODO()
    }

    /**获取企业列表*/
    async getEnterpriseListMock({ keyword, vocation, filterDate, province }) {

        return await this.TODO()
    }


    /**获取所有的行业*/
    async getEnterpriseList({ keyword, vocation, filterDate, province, page }) {
        let params = {
            page,
            keyword,
            industry: vocation,
            date: filterDate,
            province
        }

        let resp = await this._apiV2.postForm('search/company_query', params)
        return resp.data

        // return await this.TODO()
    }

    /**获取企业历史信息*/
    async getEnterHistoryInfo({ tickerSymbol, year, table, data }) {
        // return await this.TODO()
        let params = {
            stockcode: tickerSymbol,
            year,
        }

        if (table) {
            params.table = table
            params.data = data
        }

        let resp = await this._apiV2.postForm('enterdetail/history', params)
        let originData = resp.data
        // let originData = {
        //     mainBusiness: "细胞培养类及与之相关的液体处理类生物实验室一次性塑料耗材研发、生产和销售。",
        //     managingScope: "一般经营项目：塑料薄膜制造；医疗卫生用塑料制品制造；生物分解塑料制品制造；实验分析仪器制造；药物检验仪器制造；泡沫塑料制造；日用塑料制品制造；模具制造；新材料技术开发服务；生物技术开发服务(我国稀有和特有的珍贵优良品种，国家保护的原产于我国的野生动、植物资源开发除外）；化学试剂和助剂销售（监控化学品、危险化学品除外）；药品研发（人体干细胞、基因诊断与治疗技术除外）；非许可类医疗器械经营；医疗用品及器材零售（不含药品及医疗器械）；销售本公司生产的产品（国家法律法规禁止经营的项目除外；涉及许可经营的产品需取得许可证后方可经营）；非金属制品模具设计与制造；普通劳动防护用品制造；房屋租赁；场地租赁（不含仓储）；技术进出口；大气污染治理；货物进出口（涉及外资准入特别管理和许可审批的商品除外）；许可经营项目；新型诊断试剂的开发及生产；医疗实验室设备和器具制造；特种劳动防护用品制造。（法律、法规禁止经营的不得经营，外商投资产业指导目录中限制类、禁止类除外；不涉及国营贸易管理商品，涉及配额、许可证管理商品的，按照国家有关规定办理申请）。",
        //     legalRepresentative: "袁建华",
        //     chairman: "袁建华",
        //     generalManager: "Yuan Ye James",
        //     boardSecretary: "陈长溪",
        //     registeredAddress: "广东省广州经济技术开发区永和经济区斗塘路1号",
        //     establishDate: "2001-04-11",
        //     registeredDate: "2020-01-22",
        //     historyName: [],
        //     staffCount: "",
        //     productType: "",
        //     productName: [],
        //     actualController: "",
        //     relatedParty: [],
        //     nonRelatedParty: []
        // }

        //基本资料
        let baseInfo = {
            mainBusiness: originData.mainBusiness,
            businessScope: originData.managingScope,
            legalPerson: originData.legalRepresentative,
            chairman: originData.chairman,
            generalManager: originData.generalManager,
            chairSecretary: originData.boardSecretary,
            employeeCount: originData.staffCount,
            place: originData.registeredAddress,
            productType: originData.productType,
            establishedTime: originData.establishDate,
            productsName: !_.isElement(originData.productName) ? _.join(originData.productName, ",") : "",
            registerTime: originData.registeredDate,
        }

        //历史沿革
        let historyInfo = []
        _.forEach(originData.historyName, item => {
            historyInfo.push({
                time: item.time,
                name: item.name,
                remark: item.note,
            })
        })

        //实际控制人
        let realControler = originData.actualController

        //关联方/非关联方
        let relative = !_.isElement(originData.relatedParty) ? _.join(originData.relatedParty, ",") : ""
        let irrelative = !_.isElement(originData.nonrelatedParty) ? _.join(originData.nonrelatedParty, ",") : ""

        return { baseInfo, historyInfo, realControler, relative, irrelative }
    }

    /**获取供应链信息*/
    async getSupplyChainInfo({ tickerSymbol, year, table, data }) {
        // return await this.TODO()

        let params = {
            stockcode: tickerSymbol,
            year,
        }

        if (table) {
            params.table = table
            params.data = data
        }

        let resp = await this._apiV2.postForm('enterdetail/supplychain', params)
        let originData = resp.data
        // let originData = {
        //     "downstream": [{ //主要客户/ 供应链下游
        //         "client": "aaa", //客户名称
        //         "product": "bbb", //产品
        //         "sales": "", //销售额
        //         "rate": ""   //占比
        //     }],
        //     "upstream": [{ //主要供应商/ 供应链上游
        //         "supplier": "ccc", //供应商名称
        //         "raw": "", //原材料
        //         "purchase": "", //采购额
        //         "rate": ""   //占比
        //     }],
        //     "businessAnalyse": [
        //         {
        //             "quarter": "2019年第一季度", //会计年度
        //             "industry": [//按行业分类
        //                 {
        //                     "element": "零售金融业务", //主营构成
        //                     "income": '799.73亿', //主营收入
        //                     'incomeRate': '57.97%', //收入比例
        //                     'cost': '278.88亿', //主营成本
        //                     'costRate': '66.18%', //成本比例
        //                     'profit': '520.85亿', //主营利润
        //                     'profitRate': '58.01%', //利润比例
        //                     'grossProfitRatio': '65.13%' //毛利率
        //                 },
        //                 {
        //                     "element": "批发金融业务",
        //                     "income": '519.55亿',
        //                     'incomeRate': '37.66%',
        //                     'cost': '142.54亿',
        //                     'costRate': '',
        //                     'profit': '',
        //                     'profitRate': '',
        //                     'grossProfitRatio': ''
        //                 }
        //             ],
        //             'product': [{
        //                 //按产品分类
        //                 "element": "批发金融业务",
        //                 "income": '519.55亿',
        //                 'incomeRate': '37.66%',
        //                 'cost': '142.54亿',
        //                 'costRate': '',
        //                 'profit': '',
        //                 'profitRate': '',
        //                 'grossProfitRatio': ''
        //             }],
        //             'area': [{
        //                 //按地区分类
        //                 "element": "东区",
        //                 "income": '519.55亿',
        //                 'incomeRate': '37.66%',
        //                 'cost': '142.54亿',
        //                 'costRate': '',
        //                 'profit': '',
        //                 'profitRate': '',
        //                 'grossProfitRatio': ''
        //             }]
        //         }
        //     ],
        //     "guarantee": [{
        //         //担保借贷
        //         "guarantor": "平安银行", //担保方
        //         "secured": "深圳市中小企业信用担保中心有限公司", //被担保方
        //         "mode": "保证额度", //担保方式
        //         "amount": "100000", //担保金额
        //         "money": "人民币", //币种
        //         "timeLimit": "1", //担保期限
        //         "startDate": "2019-08-25", //担保起始日
        //         "endDate": "2020-08-26", //担保终止日
        //         "isFinish": "否", //是否履行完毕
        //         "isRelated": "否", //是否关联交易
        //         "dealDate": "", //交易日期
        //         "explain": "" //担保事件说明
        //     }]
        // }

        //主要客户供应链下游
        let chainFollowTb = []
        _.forEach(originData.downstream, downstreamItem => {
            chainFollowTb.push({
                userName: downstreamItem.client,
                product: downstreamItem.product,
                saleroom: downstreamItem.sales,
                scale: downstreamItem.rate
            })
        })

        //主要供应商/供应链上游
        let chainBelongTb = []
        _.forEach(originData.upstream, upstreamItem => {
            chainBelongTb.push({
                supplier: upstreamItem.supplier,
                meterial: upstreamItem.raw,
                purchase: upstreamItem.purchase,
                scale: upstreamItem.rate
            })
        })

        //主要构成分析
        let analyse = []
        _.forEach(originData.businessAnalyse, analyseItem => {
            //按行业分类
            let industry = []
            _.forEach(analyseItem.industry, industryItem => {
                industry.push({
                    subTitle: industryItem.element,//主营构成
                    mainIncome: industryItem.income,//主营收入
                    incomeRate: industryItem.incomeRate,//收入比例
                    mainCost: industryItem.cost,//主营成本
                    costRate: industryItem.costRate,//成本比例
                    mainProfit: industryItem.profit,//主营利润
                    profitRate: industryItem.profitRate,//利润比例
                    gross: industryItem.grossProfitRatio,//毛利率
                })
            })

            //按产品分类
            let product = []
            _.forEach(analyseItem.product, productItem => {
                product.push({
                    subTitle: productItem.element,
                    mainIncome: productItem.income,
                    incomeRate: productItem.incomeRate,
                    mainCost: productItem.cost,
                    costRate: productItem.costRate,
                    mainProfit: productItem.profit,
                    profitRate: productItem.profitRate,
                    gross: productItem.grossProfitRatio,
                })
            })

            //按地区分类
            let area = []
            _.forEach(analyseItem.area, areaItem => {
                area.push({
                    subTitle: areaItem.element,
                    mainIncome: areaItem.income,
                    incomeRate: areaItem.incomeRate,
                    mainCost: areaItem.cost,
                    costRate: areaItem.costRate,
                    mainProfit: areaItem.profit,
                    profitRate: areaItem.profitRate,
                    gross: areaItem.grossProfitRatio
                })
            })

            analyse.push({
                quarterTime: analyseItem.quarter,
                industry, product, area
            })
        })

        //担保借贷
        let chainLoanTb = []
        _.forEach(originData.guarantee, guaranteeItem => {
            chainLoanTb.push({
                cautioner: guaranteeItem.guarantor,//担保方
                beCautioner: guaranteeItem.secured,//被担保方
                way: guaranteeItem.mode,//担保方式
                money: guaranteeItem.amount,//担保金额
                moneyType: guaranteeItem.money,//币种
                limit: guaranteeItem.timeLimit,//担保期限
                startDay: guaranteeItem.startDate,//担保起始日
                endDay: guaranteeItem.endDate,//担保终止日
                isFinish: guaranteeItem.isFinish,//是否履行完毕
                isExchange: guaranteeItem.isRelated,//是否关联交易
                exchangeDate: guaranteeItem.dealDate,//交易日期
                guarEvent: guaranteeItem.explain,//担保事件说明
            })
        })

        let resData = { chainFollowTb, chainBelongTb, analyse, chainLoanTb }
        return resData
    }

    /**获取组织架构信息*/
    async getFrameworkInfo({ tickerSymbol, year, table, data }) {
        // return await this.TODO()

        let params = {
            stockcode: tickerSymbol,
            year,
        }

        if (table) {
            params.table = table
            params.data = data
        }

        let resp = await this._apiV2.postForm('enterdetail/structure', params)
        let originData = resp.data
        // let originData = {
        //     "manager": [{//高管
        //         "name": "袁建华", //姓名
        //         "job": "董事长", //职务
        //         "share": "", //直接持股数
        //         "term": "", //任期
        //         "birthday": "男", //出生年份
        //         "age": "", //年龄
        //         "salary": "", //薪酬
        //         "edu": "硕士研究生", //学历
        //         "nationality": "" //国籍
        //     }],
        //     "shareholder": [{//股东
        //         "name": "袁建华", //参股公司名称
        //         "code": "", //参股公司代码
        //         "alias": "", //参股公司代码简称
        //         "relation": "", //参控关系
        //         "ratio": "31", //参控比例
        //         "amount": ""  //投资金额
        //     }],
        //     "actPerson": [{//一致行动人
        //         'name': '', //股东名称
        //         'share': '', //持股数量
        //         'ratio': ''  //持股比例
        //     }],
        //     "subCompany": [{//子公司
        //         'name': '', //被参股公司名称
        //         'alias': '', //被参股公司代码简称
        //         'ratio': '', //参控比例
        //         'amount': '', //投资金额
        //         'isconsolidate': '', //是否报表合并
        //         'mainbus': '', //参股公司主营业务
        //         'relation': '', //参控关系
        //         'netprofit': '', //被参控公司净利润
        //         'submainbus': '' //被参股公司主营业务
        //     }],
        //     "invest": [{// 投资
        //         "company": "", //投资公司名称
        //         "initial": "", //初始投资金额
        //         "share": "", //持股数量
        //         "ratio": "", //占该公司股权比
        //         "type": ""    //投资公司类型
        //     }]
        // }

        //高管
        let topManage = []
        _.forEach(originData.manager, managerItem => {
            topManage.push({
                name: managerItem.name,
                duty: managerItem.job,
                holdingCount: managerItem.share,
                tenure: managerItem.term,
                birthday: managerItem.birth,
                age: managerItem.age,
                salary: managerItem.salary,
                education: managerItem.edu,
                nationality: managerItem.nationality
            })
        })

        //股东
        let stockholder = []
        _.forEach(originData.shareholder, shareholderItem => {
            stockholder.push({
                stockCompanyName: shareholderItem.name,
                stockStyle: shareholderItem.property,
                stockRelative: shareholderItem.relation,
                stockRate: shareholderItem.ratio,
                stockSum: shareholderItem.holding,
            })
        })

        //一致行动人
        let concertPerson = []
        _.forEach(originData.actPerson, actPersonItem => {
            concertPerson.push({
                stockHolderName: actPersonItem.name,
                stockCount: actPersonItem.share,
                stockRate: actPersonItem.ratio,
            })
        })

        //子公司
        let subCompany = []
        _.forEach(originData.subCompany, subCompanyItem => {
            subCompany.push({
                beStockCompany: subCompanyItem.name,
                beStockCompanyCode: subCompanyItem.alias,
                controlRelative: subCompanyItem.relation,
                controlRate: subCompanyItem.ratio,
                investSum: subCompanyItem.amount,
                beStockCompanyProfit: subCompanyItem.netprofit,
                isReportMix: subCompanyItem.isconsolidate,
                stockCompanyBusiness: subCompanyItem.mainbus,
                beStockCompanyBusiness: subCompanyItem.submainbus,
            })
        })

        //投资
        let investment = []
        _.forEach(originData.invest, investItem => {
            investment.push({
                investCompany: investItem.company,
                initalInvestSum: investItem.initial,
                stockCount: investItem.share,
                stockWeightRate: investItem.ratio,
                investCompanyType: investItem.type,
            })
        })

        let resData = { topManage, stockholder, concertPerson, subCompany, investment }
        return resData
    }

    /**获取商业模式信息*/
    async getBusinessModelInfo({ tickerSymbol, year, table, data }) {
        // return await this.TODO()

        let params = {
            stockcode: tickerSymbol,
            year,
        }

        if (table) {
            params.table = table
            params.data = data
        }

        let resp = await this._apiV2.postForm('enterdetail/business', params)
        let originData = resp.data
        // let originData = {
        //     "purchase": [{//收购重组
        //         'targetName1': '', //交易标的简称
        //         'targetName2': '', //交易标的名称
        //         'PB': '', //PB
        //         'PE': '', //PE
        //         'targetIndustry': '', //标的所属行业
        //         'targetNature': '', //标的企业性质
        //         'buyer': '', //买方
        //         'buyerCode': '', //买方代码
        //         'seller': '', //卖方
        //         'sellerCode': '', //卖方代码
        //         'tradeValue': '', //交易价值
        //         'pay': '', //现金支付金
        //         'transferRatio': ''  //股权转让比例
        //     }],
        //     "otherRel": [{//其他关系
        //         'company': '', //公司名称
        //         'relation': '', //关系
        //         'targetIndustry': '', //标的所属行业
        //         'targetNature': ''   //标的企业性质
        //     }],
        //     "businessBrand": [{//业务品牌项目
        //         'alias': '', //公司简称
        //         'company': '', //公司名称
        //         'relation': '', //关系
        //         'entity': '' //实体
        //     }]
        // }

        //收购重组
        let rebuild = []
        _.forEach(originData.purchase, purchaseItem => {
            rebuild.push({
                transTargetHash: purchaseItem.targetName1,
                transTargetName: purchaseItem.targetName2,
                pb: purchaseItem.PB,
                pe: purchaseItem.PE,
                targetIndustry: purchaseItem.targetIndustry,
                targetProp: purchaseItem.targetNature,
                buyer: purchaseItem.buyer,
                buyerCode: purchaseItem.buyerCode,
                seller: purchaseItem.seller,
                sellerCode: purchaseItem.sellerCode,
                exchangeValue: purchaseItem.tradeValue,
                cashSum: purchaseItem.pay,
                stockTransRate: purchaseItem.transferRatio,
            })
        })

        //其他关系
        let otherRelative = []
        _.forEach(originData.otherRel, otherRelItem => {
            otherRelative.push({
                companyName: otherRelItem.company,
                relative: otherRelItem.relation,
                targetIndustry: otherRelItem.targetIndustry,
                targetProp: otherRelItem.targetNature,
            })
        })

        //业务品牌项目
        let brand = []
        _.forEach(originData.businessBrand, businessBrandItem => {
            brand.push({
                companyHash: businessBrandItem.alias,
                companyName: businessBrandItem.company,
                relative: businessBrandItem.relation,
                entity: businessBrandItem.entity,
            })
        })

        let resData = { rebuild, otherRelative, brand }
        return resData
    }

    /**页面内搜索*/
    async getPageSearchData({ stockcode, keyword, pageno }) {
        let params = { stockcode, keyword, pageno }

        let resp = await this._apiV2.postForm('enterdetail/detailsearch', params)
        return resp.data
    }
}

export default EntersInfoApi