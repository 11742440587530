import React from 'react'
import {
    //Affix,
    Icon,
    Button,
    Collapse,
    Input,
    Select,
    DatePicker,
    //Checkbox,
    Table,
    Tooltip,
    //Cascader,
    Spin,
    message,
    Tabs
} from 'antd'

import ReactEcharts from 'echarts-for-react'
import './KnowledgeResult.scss'
import {webSdk} from "../../../api/WebSdk";
import _ from 'lodash'
import {MultiCascader} from 'rsuite';
import 'rsuite/dist/styles/rsuite.min.css'

const InputGroup = Input.Group;
const {Panel} = Collapse;
const {RangePicker} = DatePicker;
const {Option} = Select;
const {TabPane} = Tabs;


let date = new Date();
let thisYear = date.getFullYear();

//数组合并去重方法
function MergeArray(arr1, arr2) {
    let _arr = [];
    for (let i = 0; i < arr1.length; i++) {
        _arr.push(arr1[i]);
    }
    for (let i = 0; i < arr2.length; i++) {
        let flag = true;
        for (let j = 0; j < arr1.length; j++) {
            if (arr2[i] === arr1[j]) {
                flag = false;
                break;
            }
        }
        if (flag) {
            _arr.push(arr2[i]);
        }
    }
    return _arr;
}

// json数组去重
function listRemoveRepeat(arr, key) {
    let result = [];
    for (let i = 0; i < arr.length; i++) {
        let flag = true;
        let temp = arr[i];
        for (let j = 0; j < result.length; j++) {
            // 普通数组 (temp === result[j])
            if (temp[key] === result[j][key]) {
                flag = false;
                break;
            }
        }
        if (flag) {
            result.push(temp);
        }
    }
    return result;
}

// json数组去重
function listRemoveRepeat2(arr, key1, key2) {
    let result = [];
    for (let i = 0; i < arr.length; i++) {
        let flag = true;
        let temp = arr[i];
        for (let j = 0; j < result.length; j++) {
            // 普通数组 (temp === result[j])
            if (temp[key1] === result[j][key1] && temp[key2] === result[j][key2]) {
                flag = false;
                break;
            }
        }
        if (flag) {
            result.push(temp);
        }
    }
    return result;
}


//向上查找组织树数据
function formatSelectData(id, initData, resultData, currentArr) {
    for (let i = 0; i < initData.length; i++) {
        if (initData[i].value === id) {
            let newArr = []
            if (currentArr && currentArr.length > 0) {
                newArr = [...currentArr]
            }
            newArr.push(id);
            resultData.push(newArr)
            break;
        } else {
            if (initData[i].children && initData[i].children.length > 0) {
                let newArr = []
                if (currentArr && currentArr.length > 0) {
                    newArr = [...currentArr]
                }
                newArr.push(initData[i].value);
                formatSelectData(id, initData[i].children, resultData, newArr)
            }
        }
    }
}

//删除子集
function removeArrChild(result) {
    let copyResult = [...result];
    for (let i=0;i<result.length;i++) {
        for(let j=0;j<copyResult.length;j++) {
            let ri = result[i].join(',');
            let rj = copyResult[j].join(',');
            if(ri!==rj) {
                let flag = false;
                flag = copyResult[j].every(val => result[i].includes(val))
                if(flag) {
                    result.splice(i,1);
                    i = i-1;
                    break;
                }
            }
        }
    }
}

class KnowledgeResultIndex extends React.PureComponent {

    //属性结构表头数据
    attributeColumns = [
        {
            title: '时间',
            dataIndex: 'time',
            key: 'time',
            onCell: () => {
                return {
                    style: {
                        whiteSpace: 'pre-line'
                    }
                }
            },
            render: text => text||'-'
        },
        {
            title: '主体',
            dataIndex: 'entity',
            key: 'entity',
            onCell: () => {
                return {
                    style: {
                        whiteSpace: 'pre-line'
                    }
                }
            },
            render: text => text||'-'
        },
        {
            title: '属性',
            dataIndex: 'attribute',
            key: 'attribute',
            onCell: () => {
                return {
                    style: {
                        whiteSpace: 'pre-line'
                    }
                }
            },
            render: text => text||'-'
        },
        {
            title: '值',
            dataIndex: 'value',
            key: 'value',
            onCell: () => {
                return {
                    style: {
                        whiteSpace: 'pre-line'
                    }
                }
            },
            render: text => text||'-'
        },
        {
            title: '学科',
            dataIndex: 'classifier',
            key: 'classifier',
            render: text => text||'-',
        },
        {
            title: '企业',
            dataIndex: 'enterprise',
            key: 'enterprise',
            render: text => text||'-',
        },
        {
            title: '所属行业',
            dataIndex: 'showTheme',
            key: 'showTheme',
            render: text => text||'-'
        },
        {
            title: '来源',
            dataIndex: 'dataFrom',
            key: 'dataFrom',
            onCell: () => {
                return {
                    style: {
                        maxWidth: 130,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        cursor: 'pointer'
                    }
                }
            },
            render: (text, record) => <div onClick={() => this.dataFromClick(record)}><Tooltip placement="topLeft"
                                                                                               title={(record.source||'-') + ' ' + record.title}>{(record.source||'-') + ' ' + record.title}</Tooltip>
            </div>
        },
        {
            title: '来源发布时间',
            dataIndex: 'date',
            key: 'date',
            render: text => text||'-',
        }

    ]

    //通用结构表头数据
    generalColumns = [
        {
            title: '时间',
            dataIndex: 'time',
            key: 'time',
            onCell: () => {
                return {
                    style: {
                        whiteSpace: 'pre-line'
                    }
                }
            },
            render: text => text||'-'
        },
        {
            title: '主体',
            dataIndex: 'subject',
            key: 'subject',
            onCell: () => {
                return {
                    style: {
                        whiteSpace: 'pre-line'
                    }
                }
            },
            render: text => text||'-'
        },
        {
            title: '动作',
            dataIndex: 'predicate',
            key: 'predicate',
            onCell: () => {
                return {
                    style: {
                        whiteSpace: 'pre-line'
                    }
                }
            },
            render: text => text||'-'
        },
        {
            title: '客体',
            dataIndex: 'object',
            key: 'object',
            onCell: () => {
                return {
                    style: {
                        whiteSpace: 'pre-line'
                    }
                }
            },
            render: text => text||'-'
        },
        {
            title: '学科',
            dataIndex: 'classifier',
            key: 'classifier',
            render: text => text||'-',
        },
        {
            title: '企业',
            dataIndex: 'enterprise',
            key: 'enterprise',
            render: text => text||'-',
        },
        {
            title: '所属行业',
            dataIndex: 'showTheme',
            key: 'showTheme',
            render: text => text||'-'
        },
        {
            title: '来源',
            dataIndex: 'dataFrom',
            key: 'dataFrom',
            onCell: () => {
                return {
                    style: {
                        maxWidth: 130,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        cursor: 'pointer'
                    }
                }
            },
            render: (text, record) => <div onClick={() => this.dataFromClick(record)}><Tooltip placement="topLeft"
                                                                                               title={(record.source||'-') + ' ' + record.title}>{(record.source||'-') + ' ' + record.title}</Tooltip>
            </div>
        },
        {
            title: '来源发布时间',
            dataIndex: 'date',
            key: 'date',
            render: text => text||'-',
        }
    ]

    //关系结构表头数据
    relationColumns = [
        {
            title: '时间',
            dataIndex: 'time',
            key: 'time',
            onCell: () => {
                return {
                    style: {
                        whiteSpace: 'pre-line'
                    }
                }
            },
            render: text => text||'-'
        },
        {
            title: '主体1',
            dataIndex: 'entity',
            key: 'entity',
            onCell: () => {
                return {
                    style: {
                        whiteSpace: 'pre-line'
                    }
                }
            },
            render: text => text||'-'
        },
        {
            title: '关系',
            dataIndex: 'relation',
            key: 'relation',
            onCell: () => {
                return {
                    style: {
                        whiteSpace: 'pre-line'
                    }
                }
            },
            render: text => text||'-'
        },
        {
            title: '主体2',
            dataIndex: 'entity2',
            key: 'entity2',
            onCell: () => {
                return {
                    style: {
                        whiteSpace: 'pre-line'
                    }
                }
            },
            render: text => text||'-'
        },
        {
            title: '学科',
            dataIndex: 'classifier',
            key: 'classifier',
            render: text => text||'-',
        },
        {
            title: '企业',
            dataIndex: 'enterprise',
            key: 'enterprise',
            render: text => text||'-',
        },
        {
            title: '所属行业',
            dataIndex: 'showTheme',
            key: 'showTheme',
            render: text => text||'-'
        },
        {
            title: '来源',
            dataIndex: 'dataFrom',
            key: 'dataFrom',
            onCell: () => {
                return {
                    style: {
                        maxWidth: 130,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        cursor: 'pointer'
                    }
                }
            },
            render: (text, record) => <div onClick={() => this.dataFromClick(record)}><Tooltip placement="topLeft"
                                                                                               title={(record.source||'-') + ' ' + record.title}>{(record.source||'-') + ' ' + record.title}</Tooltip>
            </div>
        },
        {
            title: '来源发布时间',
            dataIndex: 'date',
            key: 'date',
            render: text => text||'-',
        }
    ]


    constructor(props) {
        super(props)

        this.state = {
            keyword: props.match.params.keyword || '',
            flod: false,        //是否折叠，true折叠，false未折叠，默认未折叠
            selectType: 'generalStructure',     //通用结构知识2，属性结构1，关系结构3
            //通用结构知识
            generalStructure: {
                title: '通用结构',
                titleKey: 'generalStructure',
                label: ['主体', '动作', '客体'],
                keys: ['subject', 'predicate', 'object'],
                subject: [],
                predicate: [],
                object: [],
                //tips: '温馨提示:使用空格输入多个标签,如"万科 碧桂园"'
            },
            //属性结构知识
            attributeInfo: {
                title: '属性知识',
                titleKey: 'attributeInfo',
                label: ['主体', '属性', '值'],
                keys: ['entity', 'attribute', 'value'],
                entity: [],
                attribute: [],
                value: ['', '']
            },
            //关系结构知识
            relationInfo: {
                title: '关系知识',
                titleKey: 'relationInfo',
                label: ['主体1', '关系', '主体2'],
                keys: ['entity', 'relation', 'entity2'],
                entity: [],
                relation: [],
                entity2: []
            },
            //时间
            time: {
                title: '知识点时间',
                titleKey: 'time',
                selectTime: []
            },
            //学科
            discipline: {
                title: '学科',
                titleKey: 'discipline',
                itemList: [],
                selectData: []
            },
            //行业
            industry: {
                title: '行业',
                titleKey: 'industry',
                //分类标准
                classificationStandard: [
                    {
                        label: '申万行业分类',
                        value: '1'
                    },
                    {
                        label: '中证指数',
                        value: '2'
                    },
                    {
                        label: 'CSRC标准分类',
                        value: '3'
                    }
                ],
                classification: [],
                selectStandard: '1',
                selectClass: ['']
            },
            industryLoading: false,
            //数据来源
            dataFrom: {
                title: '数据来源',
                titleKey: 'dataFrom',
                selectData: [],
                dataFrom: []
            },
            //来源发布时间
            dataFromTime: {
                title: '来源发布时间',
                titleKey: 'dataFromTime',
                selectTime: []
            },
            tableLoading: false,
            //右侧表格表头数据
            columns: this.generalColumns,
            //右侧表格表格数据
            tableData: [],
            //当前页码
            page: 0,
            //页码显示数量
            pageSize: 10,
            //总共页码数
            pageTotal: 0,
            options: {},
            graphLoading: false,        //生成知识图谱按钮加载
            showRelation: false,     //是否显示关系图
            currentSelectCard: 'attributeInfo',
            pointData: null,
            linksData: null,
            classificationSelect: [],            //行业分类下拉数据
            backSelectData: [],     //行业分类输入框数据
            disciplineList: [],     //学科下拉数据
            backDiscipline: [],     //学科输入框数据

            timeMode: ['year', "year"],
            selectYear: false,
            yearOpen: false,
            yearSelectValue: [],
            backSelectValue: [],
            yearShowIndex: 2,


        }

        this.getSearchResult = this.getSearchResult.bind(this);
        this.getClassificationList = this.getClassificationList.bind(this);
        this.getDataFromList = this.getDataFromList.bind(this);

        this.changeSelectData = this.changeSelectData.bind(this)
        this.changeLeftFlod = this.changeLeftFlod.bind(this)
        this.flodItemChange = this.flodItemChange.bind(this)
        this.changeTime = this.changeTime.bind(this)
        this.changeSelectOption = this.changeSelectOption.bind(this)
        this.changeSelectClass = this.changeSelectClass.bind(this)
        this.changeCascaderSelect = this.changeCascaderSelect.bind(this)
        this.changeTypeCard = this.changeTypeCard.bind(this)

        this.changeMulSelect = this.changeMulSelect.bind(this)
        this.mulSelectSearch = this.mulSelectSearch.bind(this)
        this.clearIndustry = this.clearIndustry.bind(this)

        this.changeDiscipline = this.changeDiscipline.bind(this)
        this.disciplineSearch = this.disciplineSearch.bind(this)
        this.clearDiscipline = this.clearDiscipline.bind(this)

        this.dataFromClick = this.dataFromClick.bind(this)

        //canvas点击事件
        this.onclick = {
            'click': this.clickPoint.bind(this)
        }

        this.showYearSelect = this.showYearSelect.bind(this);
        this.setClass = this.setClass.bind(this);
        this.YearSelect = this.YearSelect.bind(this);
        this.preYear = this.preYear.bind(this);
        this.nextYear = this.nextYear.bind(this);
        this.selectItem = this.selectItem.bind(this);
        this.yearChange = this.yearChange.bind(this);

    }

    componentDidMount() {
        this.getSearchResult();
        this.getClassificationList();
        this.getDataFromList();
        let root = document.getElementById('root')
        root.addEventListener('click', () => {
            let yearSelectValue = [...this.state.yearSelectValue];
            let backSelectValue = [...this.state.backSelectValue]
            if (yearSelectValue.length < 2) {
                yearSelectValue = []
            }
            if(JSON.stringify(yearSelectValue) !== JSON.stringify(backSelectValue)) {
                yearSelectValue = backSelectValue;
            }
            this.setState({
                yearOpen: false,
                yearShowIndex: 2,
                yearSelectValue
            })
        })
        let icons = document.querySelectorAll('.rs-picker-toggle-caret')
        icons.forEach(item =>{
            item.innerHTML = `<i aria-label="图标: down" style="color: rgba(0, 0, 0, 0.25);transform: scale(0.8576)" class="anticon anticon-down ant-select-arrow-icon"><svg viewBox="64 64 896 896" class="" data-icon="down" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path></svg></i>`
        })
    }

    componentWillReceiveProps(nextProps) {
        let keyword = nextProps.match.params.keyword || '',
            oldWord = this.state.keyword;
        if (keyword !== oldWord) {
            let generalStructure = {...this.state.generalStructure},
                attributeInfo = {...this.state.attributeInfo},
                relationInfo = {...this.state.relationInfo};
            generalStructure.subject = [];
            generalStructure.predicate = [];
            generalStructure.object = [];
            attributeInfo.entity = [];
            attributeInfo.attribute = [];
            attributeInfo.value = ['', ''];
            relationInfo.entity = [];
            relationInfo.relation = [];
            relationInfo.entity2 = [];
            this.setState({
                keyword,
                page: 0,
                generalStructure, attributeInfo, relationInfo
            }, () => {
                this.getSearchResult()
            })
        }
    }

    componentWillUnmount() {
        // 卸载异步操作设置状态
        this.setState = (state, callback) => {
            return;
        }
    }


    /**
     * 获取表格数据
     */
    getSearchResult() {
        let hadInfo = true;
        let postData = {
            type: null,
            //keyword: this.state.keyword,
            pageIndex: this.state.page,
            pageSize: this.state.pageSize,
            excepts: {}
        };
        switch (this.state.selectType) {
            case 'attributeInfo':
                let attributeInfo = this.state.attributeInfo;
                postData.excepts.entity = attributeInfo.entity || [];
                postData.excepts.attribute = attributeInfo.attribute || [];
                postData.excepts.value = {};
                postData.excepts.value.start = attributeInfo.value[0] || '';
                postData.excepts.value.end = attributeInfo.value[1] || '';
                postData.type = 1;
                if (_.isEmpty(postData.excepts.entity) && _.isEmpty(postData.excepts.attribute) && _.isEmpty(postData.excepts.value.start) && _.isEmpty(postData.excepts.value.end)) {
                    postData.keyword = this.state.keyword;
                    hadInfo = false;
                }
                break;
            case 'generalStructure':
                let generalStructure = this.state.generalStructure;
                postData.excepts.subject = generalStructure.subject || [];
                postData.excepts.predicate = generalStructure.predicate || [];
                postData.excepts.object = generalStructure.object || [];
                postData.type = 2;
                if (_.isEmpty(postData.excepts.subject) && _.isEmpty(postData.excepts.predicate) && _.isEmpty(postData.excepts.object)) {
                    postData.keyword = this.state.keyword;
                    hadInfo = false;
                }
                break;
            case 'relationInfo':
                let relationInfo = this.state.relationInfo;
                postData.excepts.entity = relationInfo.entity || [];
                postData.excepts.relation = relationInfo.relation || [];
                postData.excepts.entity2 = relationInfo.entity2 || [];
                postData.type = 3;
                if (_.isEmpty(postData.excepts.entity) && _.isEmpty(postData.excepts.relation) && _.isEmpty(postData.excepts.entity2)) {
                    postData.keyword = this.state.keyword;
                    hadInfo = false;
                }
                break;
            default:
                break;
        }

        postData.excepts.source = this.state.dataFrom.dataFrom;       //消息来源
        postData.excepts.classifier = this.state.discipline.selectData;   //学科
        //postData.excepts.enterprise = this.state.industry.selectClass;   //企业名称
        postData.excepts.date = {};         //发表日期
        postData.excepts.date.start = this.state.dataFromTime.selectTime[0] || '';
        postData.excepts.date.end = this.state.dataFromTime.selectTime[1] || '';
        postData.excepts.time = {};         //知识点时间
        postData.excepts.time.start = this.state.yearSelectValue[0] || '';
        postData.excepts.time.end = this.state.yearSelectValue[1] || '';
        postData.excepts.theme = {};


        /** 格式化行业分类数据 */
        let result = [];
        let selectClass = [...this.state.industry.selectClass];     //已选择的数据
        if (selectClass.length > 0) {
            for (let i = 0; i < selectClass.length; i++) {
                formatSelectData(selectClass[i], this.state.classificationSelect, result)
            }
            //console.log(JSON.stringify(result))

            removeArrChild(result)
            console.log(JSON.stringify(result))
        }


        switch (this.state.industry.selectStandard) {
            case '1':
                postData.excepts.theme['sw_classifier'] = result;
                break;
            case '2':
                postData.excepts.theme['cics_classifier'] = result;
                break;
            case '3':
                postData.excepts.theme['csrc_classifier'] = result;
                break;
            default:
                postData.excepts.theme['sw_classifier'] = result;
                break;
        }

        postData.excepts = JSON.stringify(postData.excepts)
        console.log(postData);
        this.setState({
            tableLoading: true
        }, () => {
            webSdk.search.searchKnowledge(postData).then(res => {
                try {
                    if (!res.errCode || res.errCode === 0) {
                        if (JSON.stringify(res) === '[]') {
                            this.setState({
                                tableLoading: false,
                                tableData: [],
                                page: 0,
                                pageTotal: 0
                            })
                            return
                        }
                        let pageData = res.pager;
                        let page = pageData.index,
                            pageTotal = pageData.total;
                        let list = res.reportList;
                        let resultData = [];

                        if (!hadInfo) {
                            switch (this.state.selectType) {
                                case 'attributeInfo':
                                    let attributeInfo = this.state.attributeInfo;
                                    let eav = res.cons.eav || {};
                                    if (!_.isEmpty(eav)) {
                                        attributeInfo.entity = eav.entity || [];
                                        attributeInfo.attribute = eav.attribute || [];
                                        attributeInfo.value = eav.value || ['', ''];
                                        this.setState({
                                            attributeInfo
                                        })
                                    }
                                    break;
                                case 'generalStructure':
                                    let generalStructure = this.state.generalStructure;
                                    let spo = res.cons.spo || {};
                                    if (!_.isEmpty(spo)) {
                                        generalStructure.subject = spo.subject || [];
                                        generalStructure.predicate = spo.predicate || [];
                                        generalStructure.object = spo.object || [];
                                        this.setState({
                                            generalStructure
                                        })
                                    }

                                    break;
                                case 'relationInfo':
                                    let relationInfo = this.state.relationInfo;
                                    let eae = res.cons.eae || {};
                                    if (!_.isEmpty(eae)) {
                                        relationInfo.entity = eae.entity || [];
                                        relationInfo.relation = eae.relation || [];
                                        relationInfo.entity2 = eae.entity2 || [];
                                        this.setState({
                                            relationInfo
                                        })
                                    }
                                    break;
                                default:
                                    break;
                            }
                        }


                        for (let i = 0; i < list.length; i++) {
                            if (!_.isEmpty(list[i])) {
                                let obj = list[i];
                                obj.classifier = obj.classifier.join('\n');
                                //obj.showTheme = '';
                                this.setShowTheme(obj)

                                if (this.state.selectType === 'attributeInfo') {        //时间主体属性值
                                    let eav = obj.eav;
                                    obj.time = [];
                                    obj.entity = [];
                                    obj.attribute = [];
                                    obj.value = [];
                                    if (!_.isEmpty(eav)) {
                                        obj.time = getArrStr(eav.time || []);
                                        obj.entity = getArrStr(eav.entity || []);
                                        obj.attribute = getArrStr(eav.attribute || []);
                                        obj.value = getArrStr(eav.value || []);
                                    }
                                } else if (this.state.selectType === 'generalStructure') {     //主体动作客体
                                    let spo = obj.spo;
                                    obj.time = [];
                                    obj.subject = [];
                                    obj.predicate = [];
                                    obj.object = [];
                                    if (!_.isEmpty(spo)) {
                                        obj.time = getArrStr(spo.time || []);
                                        obj.subject = getArrStr(spo.subject || []);
                                        obj.predicate = getArrStr(spo.predicate || []);
                                        obj.object = getArrStr(spo.object || []);
                                    }
                                } else if (this.state.selectType === 'relationInfo') {     //主体1关系主体2
                                    let eae = obj.eae;
                                    obj.time = [];
                                    obj.entity = [];
                                    obj.relation = [];
                                    obj.entity2 = [];
                                    if (!_.isEmpty(eae)) {
                                        obj.time = getArrStr(eae.time || []);
                                        obj.entity = getArrStr(eae.entity || []);
                                        obj.relation = getArrStr(eae.relation || []);
                                        obj.entity2 = getArrStr(eae.entity2 || []);
                                    }
                                }
                                resultData.push(obj)
                            }
                        }
                        this.setState({
                            tableData: resultData,
                            page: page,
                            pageTotal: pageTotal,
                            tableLoading: false
                        })
                    } else {
                        this.setState({
                            tableLoading: false,
                            tableData: [],
                            page: 0,
                            pageTotal: 0
                        })
                        message.error(res.errMsg || '获取知识点列表失败，请稍后重试');
                    }
                } catch (e) {
                    console.log(e)
                    this.setState({
                        tableLoading: false,
                        tableData: [],
                        page: 0,
                        pageTotal: 0
                    })
                    message.error('获取知识点列表失败，请稍后重试');
                }
            }).catch(e => {
                this.setState({
                    tableLoading: false,
                    tableData: [],
                    page: 0,
                    pageTotal: 0
                })
                message.error('获取知识点列表失败，请稍后重试');
            })
        })

        function getArrStr(arr) {
            let newArr = [];
            for (let i = 0; i < arr.length; i++) {
                newArr.push(arr[i].val)
            }
            return newArr.join('\n')
        }
    }

    /**
     * 获取学科、数据来源
     */
    getDataFromList() {
        //let hadInfo = true;
        let postData = {
            type: null,
            //keyword: this.state.keyword,
            pageIndex: this.state.page,
            pageSize: this.state.pageSize,
            excepts: {}
        };
        switch (this.state.selectType) {
            case 'attributeInfo':
                let attributeInfo = this.state.attributeInfo;
                postData.excepts.entity = attributeInfo.entity || [];
                postData.excepts.attribute = attributeInfo.attribute || [];
                postData.excepts.value = {};
                postData.excepts.value.start = attributeInfo.value[0] || '';
                postData.excepts.value.end = attributeInfo.value[1] || '';
                postData.type = 1;
                if (_.isEmpty(postData.excepts.entity) && _.isEmpty(postData.excepts.attribute) && _.isEmpty(postData.excepts.value.start) && _.isEmpty(postData.excepts.value.end)) {
                    postData.keyword = this.state.keyword;
                    //hadInfo = false;
                }
                break;
            case 'generalStructure':
                let generalStructure = this.state.generalStructure;
                postData.excepts.subject = generalStructure.subject || [];
                postData.excepts.predicate = generalStructure.predicate || [];
                postData.excepts.object = generalStructure.object || [];
                postData.type = 2;
                if (_.isEmpty(postData.excepts.subject) && _.isEmpty(postData.excepts.predicate) && _.isEmpty(postData.excepts.object)) {
                    postData.keyword = this.state.keyword;
                    //hadInfo = false;
                }
                break;
            case 'relationInfo':
                let relationInfo = this.state.relationInfo;
                postData.excepts.entity = relationInfo.entity || [];
                postData.excepts.relation = relationInfo.relation || [];
                postData.excepts.entity2 = relationInfo.entity2 || [];
                postData.type = 3;
                if (_.isEmpty(postData.excepts.entity) && _.isEmpty(postData.excepts.relation) && _.isEmpty(postData.excepts.entity2)) {
                    postData.keyword = this.state.keyword;
                    //hadInfo = false;
                }
                break;
            default:
                break;
        }
        postData.excepts.source = this.state.dataFrom.dataFrom;       //消息来源
        postData.excepts.classifier = this.state.discipline.selectData;   //学科
        //postData.excepts.enterprise = this.state.industry.selectClass;   //企业名称
        postData.excepts.date = {};         //发表日期
        postData.excepts.date.start = this.state.dataFromTime.selectTime[0] || '';
        postData.excepts.date.end = this.state.dataFromTime.selectTime[1] || '';
        postData.excepts.time = {};         //知识点时间
        postData.excepts.time.start = this.state.yearSelectValue[0] || '';
        postData.excepts.time.end = this.state.yearSelectValue[1] || '';
        postData.excepts.theme = {};

        /** 格式化行业分类数据 */
        let result = [];
        let selectClass = [...this.state.industry.selectClass];     //已选择的数据
        if (selectClass.length > 0) {
            for (let i = 0; i < selectClass.length; i++) {
                formatSelectData(selectClass[i], this.state.classificationSelect, result)
            }
            removeArrChild(result)
            console.log(JSON.stringify(result))
        }

        switch (this.state.industry.selectStandard) {
            case '1':
                postData.excepts.theme['sw_classifier'] = result;
                break;
            case '2':
                postData.excepts.theme['cics_classifier'] = result;
                break;
            case '3':
                postData.excepts.theme['csrc_classifier'] = result;
                break;
            default:
                postData.excepts.theme['sw_classifier'] = result;
                break;
        }

        postData.excepts = JSON.stringify(postData.excepts)
        webSdk.search.knowledgeCons(postData).then(res => {
            try {
                if (!res.errCode || res.errCode === 0) {
                    let dataFrom = { ...this.state.dataFrom };
                    dataFrom.selectData = res.source || [];
                    let classifier = res['classifier'];
                    for (let i = 0; i < classifier.length; i++) {
                        this.renameDis(classifier[i])
                    }
                    let disciplineList = classifier || [];
                    this.setState({
                        dataFrom,
                        disciplineList,
                    })
                } else {
                    message.error('获取数据来源列表失败，请稍后重试');
                }
            } catch (e) {
                message.error('获取数据来源列表失败，请稍后重试');
            }
        }).catch(e => {
            message.error('获取数据来源列表失败，请稍后重试');
        })
    }

    renameDis(data) {
        data.label = data.name;
        data.value = data.name;
        if (data.child && data.child.length > 0) {
            data.children = data.child;
            delete data.child;
            for (let i = 0; i < data.children.length; i++) {
                this.renameDis(data.children[i])
            }
        }
    }

    setShowTheme(data) {
        let themeObj = data.theme || {};
        data.showTheme = [];
        switch (this.state.industry.selectStandard) {
            case '1':
                data.showTheme = themeObj['swClassifier'] || []
                break;
            case '2':
                data.showTheme = themeObj['cicsClassifier'] || []
                break;
            case '3':
                data.showTheme = themeObj['csrcClassifier'] || []
                break;
            default:
                data.showTheme = themeObj['swClassifier'] || []
                break;
        }
        data.showTheme = data.showTheme.join('/')
    }

    /**
     * 获取行业分类列表
     */
    getClassificationList() {
        let classificationSelect = [];
        let industry = {...this.state.industry};
        industry.selectClass = []
        let backSelectData = [];
        this.setState({
            classificationSelect,
            industryLoading: true,
            industry,
            backSelectData
        }, () => {
            let postData = {
                type: this.state.industry.selectStandard
            }
            webSdk.search.getClassificationList(postData).then(res => {
                try {
                    if (!res.errCode || res.errCode === 0) {
                        for (let i = 0; i < res.length; i++) {
                            renameData(res[i]);
                        }
                        let classificationSelect = res || [];
                        this.setState({
                            industryLoading: false,
                            classificationSelect
                        })
                    } else {
                        this.setState({
                            industryLoading: false
                        })
                        message.error(res.errMsg || "获取行业分类列表失败")
                    }
                } catch (e) {
                    this.setState({
                        industryLoading: false
                    })
                    console.log(e)
                    message.error("获取行业分类列表失败")
                }
            }).catch(e => {
                this.setState({
                    industryLoading: false
                })
            })
        })

        function renameData(data) {
            data.label = data.text;
            delete data.text;
            if (data.child && data.child.length > 0) {
                data.children = data.child;
                delete data.child;
                for (let i = 0; i < data.children.length; i++) {
                    renameData(data.children[i])
                }
            }
        }
    }

    /**
     * 节点点击事件----用于展开/收缩该节点
     */
    clickPoint(item) {
        if (item.dataType === 'node') {
            if ((item.data.level === 2 && item.data.child > 4) || (item.data.level > 2 && item.data.child > 0)) {
                //当前显示的数据
                let showOptions = this.state.options,
                    showData = showOptions.series[0].data,
                    showLinks = showOptions.series[0].links;
                //获取到的原始数据 oldOptions = options,
                let oldData = this.state.pointData,
                    oldLinks = this.state.linksData;
                //新的显示数据
                let newData = [...showData],
                    newLinks = [...showLinks];

                let deleteName = [];        //删除的节点名称暂存
                let findChild = (findName) => {
                    for (let i = 0; i < newLinks.length; i++) {
                        if (newLinks[i].source === findName) {
                            deleteName.push(newLinks[i].target);
                            findChild(newLinks[i].target);
                        }
                    }
                }
                //先查询该节点再原始数据中是否有子节点
                //循环原始data
                for (let i = 0; i < oldData.length; i++) {
                    //找到对应的节点获取当前节点信息
                    if (oldData[i].name === item.name) {
                        //如果该节点的层级大于等于2，并且有子级  ======二级节点收缩/展开+三级节点展开
                        let deleteItems = [];    //子节点对象暂存
                        //循环当前显示的links数据
                        for (let j = 0; j < showLinks.length; j++) {
                            //获取起点为当前节点的线
                            if (showLinks[j].source === item.name) {
                                deleteItems.push(showLinks[j]);
                            }
                        }
                        //循环结束后判断删除对象的length，0为已收缩，大于0为展开
                        //第二节点点击情况
                        if (item.data.level === 2) {
                            if (deleteItems.length > 4) {
                                //展开情况，删除4条数据后面的数据
                                for (let k = 4; k < deleteItems.length; k++) {
                                    //查找data里的数据，删除该节点
                                    for (let kk = 0; kk < newData.length; kk++) {
                                        if (newData[kk].name === deleteItems[k].target) {
                                            newData.splice(kk, 1);
                                            break;
                                        }
                                    }
                                    //查找links里的数据，删除该线数据
                                    for (let kkk = 0; kkk < newLinks.length; kkk++) {
                                        if (newLinks[kkk].target === deleteItems[k].target) {
                                            newLinks.splice(kkk, 1);
                                            break;
                                        }
                                    }
                                }
                                //newData和newLinks为新的展示数据和线
                                for (let n = 0; n < newData.length; n++) {
                                    if (newData[n].name === item.name) {
                                        newData[n].currentChild = 4;
                                        break;
                                    }
                                }
                                //console.log(newData,'///////////',newLinks);
                                this.setPointData(newData, newLinks)
                            } else {
                                //收缩的情况，把余下的数据展示出来
                                let currentAllData = [],
                                    currentAllLinks = [];
                                //获取所有的连线数据
                                for (let k = 0; k < oldLinks.length; k++) {
                                    if (oldLinks[k].source === item.name) currentAllLinks.push(oldLinks[k])
                                }
                                //根据连线数据添加所有节点
                                for (let kk = 0; kk < currentAllLinks.length; kk++) {
                                    for (let kkk = 0; kkk < oldData.length; kkk++) {
                                        if (oldData[kkk].name === currentAllLinks[kk].target) {
                                            if (oldData[kkk].name === item.name) {
                                                oldData[kkk].currentChild = oldData[kkk].child
                                            }
                                            currentAllData.push(oldData[kkk]);
                                            break;
                                        }
                                    }
                                }
                                //合并去重
                                newData = MergeArray(newData, currentAllData);
                                newLinks = MergeArray(newLinks, currentAllLinks);
                                for (let n = 0; n < newData.length; n++) {
                                    if (newData[n].name === item.name) {
                                        newData[n].currentChild = newData[n].child;
                                        break;
                                    }
                                }
                                //console.log(newData,newLinks);
                                this.setPointData(newData, newLinks)
                            }
                            break;
                        }
                        //除第二节点点击情况外
                        if (item.data.level > 2) {
                            //未显示情况---显示处理
                            if (deleteItems.length === 0) {
                                let currentAllData = [],
                                    currentAllLinks = [];
                                //获取所有的连线数据
                                for (let k = 0; k < oldLinks.length; k++) {
                                    if (oldLinks[k].source === item.name) currentAllLinks.push(oldLinks[k])
                                }
                                //根据连线数据添加所有节点
                                for (let kk = 0; kk < currentAllLinks.length; kk++) {
                                    for (let kkk = 0; kkk < oldData.length; kkk++) {
                                        if (oldData[kkk].name === currentAllLinks[kk].target) {
                                            if (oldData[kkk].name === item.name) {
                                                oldData[kkk].currentChild = oldData[kkk].child
                                            }
                                            currentAllData.push(oldData[kkk]);
                                            break;
                                        }
                                    }
                                }
                                for (let x = 0; x < currentAllData.length; x++) {
                                    currentAllData[x].currentChild = 0
                                }
                                //合并去重
                                newData = MergeArray(newData, currentAllData);
                                newLinks = MergeArray(newLinks, currentAllLinks);
                                for (let n = 0; n < newData.length; n++) {
                                    if (newData[n].name === item.name) {
                                        newData[n].currentChild = newData[n].child;
                                        break;
                                    }
                                }
                                //console.log(newData,newLinks);
                                this.setPointData(newData, newLinks)
                            }
                            //已显示情况---收缩处理
                            else {
                                deleteName = [];        //删除的节点名称暂存
                                findChild(item.name);   //查找除所有的子节点名称
                                for (let i = 0; i < deleteName.length; i++) {
                                    for (let ii = 0; ii < newData.length; ii++) {
                                        if (newData[ii].name === deleteName[i]) {
                                            newData.splice(ii, 1);
                                            ii--;
                                            break;
                                        }
                                    }
                                    for (let iii = 0; iii < newLinks.length; iii++) {
                                        if (newLinks[iii].source === deleteName[i] || newLinks[iii].target === deleteName[i]) {
                                            newLinks.splice(iii, 1);
                                            iii--;
                                            break;
                                        }
                                    }
                                }
                                this.setPointData(newData, newLinks)
                            }
                        }
                    }
                }
            }
        }
    }

    /**
     * 设置options数据
     */
    setPointData(data, links) {
        let options = {
            title: {
                text: '',
                subtext: '',
                top: 'bottom',
                left: 'right'
            },
            tooltip: {},        //用于设置hover节点显示
            animationDuration: 500,
            animationEasingUpdate: 'quinticInOut',
            series: [
                {
                    name: '',     //名称
                    type: 'graph',              //类型
                    hoverAnimation: false,
                    legendHoverLink: false,     //hover 时的联动高亮
                    layout: 'force',             //图像布局  none不适用任何布局,circular环形布局，force引力布局
                    force: {
                        repulsion: 800,
                        gravity: 0.05,
                        symbolSize: 10000,
                        edgeLength: 100         //
                    },
                    roam: true,         //是否开启鼠标缩放和平移漫游。默认不开启。如果只想要开启缩放或者平移，可以设置成 'scale' 或者 'move'。设置成 true 为都开启
                    focusNodeAdjacency: false,   //是否在鼠标移到节点上的时候突出显示节点以及节点的边和邻接节点。
                    itemStyle: {         //节点图形样式
                        color: {
                            // 径向渐变，前三个参数分别是圆心 x, y 和半径，取值同线性渐变
                            type: 'radial',
                            colorStops: [{
                                offset: 0,
                                color: '#1890FF' // 0% 处的颜色
                            }, {
                                offset: 1,
                                color: '#1890FF' // 100% 处的颜色
                            }],
                            global: false, // 缺省为 false
                        },
                        borderWidth: 8,
                        borderColor: {
                            type: 'radial',
                            colorStops: [
                                {
                                    offset: 0, color: 'rgba(24,144,255,0.1)' // 0% 处的颜色
                                },
                                {
                                    offset: 1, color: 'rgba(24,144,255,0.3)' // 100% 处的颜色
                                }
                            ],
                            global: false // 缺省为 false
                        }
                    },
                    //节点图形大小
                    symbolSize: (value, params) => {
                        //console.log(params);
                        let data = params.data;
                        if (data.level === 1) {
                            return 100
                        }
                        if (data.level >= 2 && data.child > 0) {
                            return 30
                        } else {
                            return 50
                        }
                    },
                    lineStyle: {        //线条样式
                        color: '#fff',
                        width: 2
                    },
                    label: {            //图形上的文本标签，可用于说明图形的一些数据信息，比如值，名称等
                        show: true,
                        color: '#fff',
                        fontWeight: 'bold',
                        formatter: item => {
                            //console.log(item)
                            //在未筛选的情况下
                            if (this.state.currentSelect === 'all') {
                                //如果该节点的子节点大于4个，做+/-显示
                                if (item.data.child > 4 && item.data.level > 1) {
                                    if (item.data.currentChild !== item.data.child) {
                                        return '+'
                                    } else {
                                        return '-'
                                    }
                                }
                                //如果节点子节点大于0并且小于等于4个，做空显示
                                else if (item.data.child > 0 && item.data.child <= 4 && item.data.level > 1) {
                                    return item.name
                                }
                                //没有子节点，默认显示该节点名称
                                else {
                                    return item.name
                                }
                            }
                            //筛选的情况下不做数据处理
                            else {
                                //如果该节点的子节点大于4个，做+/-显示
                                if (item.data.child > 4 && item.data.level > 1) {
                                    return '-'
                                }
                                //如果节点子节点大于0并且小于等于4个，做空显示
                                else if (item.data.child > 0 && item.data.child <= 4 && item.data.level > 1) {
                                    return item.name
                                }
                                //没有子节点，默认显示该节点名称
                                else {
                                    return item.name
                                }
                            }
                        }
                    },
                    //格式化hover数据
                    tooltip: {
                        formatter: item => {
                            if (item.dataType === 'node') {
                                return item.data.name + `<br/>·数据来源：` + item.data.dataFrom + `<br/>·时   间：` + item.data.recordTime
                            }
                        }
                    },
                    data: data,
                    links: links
                }
            ]
        }
        this.setState({
            options
        })
    }

    /**
     * 结构知识筛选输入框
     */
    changeSelectData(itemKey, key, value) {
        let item = {...this.state[itemKey]};
        item[key] = value;
        this.setState({
            selectType: itemKey,
            [itemKey]: item,
            page: 0
        }, () => {
            this.getSearchResult()
        })
    }

    setLeftItem(item) {
        return (
            <div className="knowledge-result__left-collapse">
                {
                    item.keys.map((keyItem, keyIndex) => {
                        if (keyItem === 'value') {
                            return (
                                <div key={keyIndex} className="knowledge-result__select-item">
                                    {item.label && item.label.length > 0 ? (<div
                                        className="knowledge-result__select-item-label">{item.label[keyIndex]}</div>) : null}
                                    <div className="knowledge-result__select-item-select">
                                        <InputGroup compact>
                                            <Input style={{width: 84, textAlign: 'center', borderRadius: '2px'}}
                                                   placeholder="最小值" onBlur={ev => this.valueInput(ev, 'min')}
                                                   disabled={this.state.tableLoading}/>
                                            <Input
                                                disabled
                                                style={{
                                                    width: 22,
                                                    border: 'none',
                                                    pointerEvents: 'none',
                                                    color: '#333',
                                                    background: 'transparent',
                                                    padding: 0,
                                                    textAlign: 'center',
                                                    fontSize: 12
                                                }}
                                                value="~"
                                            />
                                            <Input style={{width: 84, textAlign: 'center', borderRadius: '2px'}}
                                                   placeholder="最大值" onBlur={ev => this.valueInput(ev, 'max')}
                                                   disabled={this.state.tableLoading}/>
                                        </InputGroup>
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div key={keyIndex} className="knowledge-result__select-item">
                                    {item.label && item.label.length > 0 ? (<div
                                        className="knowledge-result__select-item-label">{item.label[keyIndex]}</div>) : null}
                                    <div className="knowledge-result__select-item-select">
                                        <Select
                                            mode="tags"
                                            allowClear={true}
                                            //disabled={this.state.tableLoading}
                                            style={{width: 190}}
                                            value={item[keyItem]}
                                            //tokenSeparators={[' ']}
                                            //open={false}
                                            dropdownStyle={{display: 'none'}}
                                            placeholder={'请输入' + (item.label[keyIndex] || item.title)}
                                            onChange={value => this.changeSelectData(item.titleKey, keyItem, value)}
                                        >

                                        </Select>
                                    </div>
                                </div>
                            )
                        }
                    })
                }
                {item.tips ? (<p className="knowledge-result__left-collapse__item-tips">{item.tips}</p>) : null}
            </div>
        )
    }

    /**
     * 属性结构知识模块--值输入框最大值最小值数据变更
     */
    valueInput(ev, type) {
        let value = ev.target.value;
        let attributeInfo = {...this.state.attributeInfo};
        if (type === 'min') {
            if (attributeInfo.value[0] !== value) {
                attributeInfo.value[0] = value;
                this.setState({
                    attributeInfo
                }, () => {
                    this.getSearchResult()
                })
            }
        } else if (type === 'max') {
            if (attributeInfo.value[1] !== value) {
                attributeInfo.value[1] = value;
                this.setState({
                    attributeInfo
                }, () => {
                    this.getSearchResult()
                })
            }
        }
    }

    /*<RangePicker format={'YYYY年'}
                                             placeholder={['起始年', '结束年']}
                                             allowClear={true}
                                             mode={this.state.timeMode}
                                             onPanelChange={(value, mode) =>this.yearSelectChange(value, mode)}
                                             onChange={val =>this.setValue(val)}
                                             value={item.selectTime}
                                             open={this.state.selectYear}
                                             onOpenChange={(status) => {
                                                 if(status){
                                                     this.setState({selectYear: true})
                                                 } else {
                                                     this.setState({selectYear: false})
                                                 }
                                             }}
                                />*/

    /**
     * 时间选择框
     */
    setLeftTime(item) {
        return (
            <Collapse key={item.titleKey}
                      expandIconPosition="right"
                      defaultActiveKey={['0']}
                      className="time-item"
                      expandIcon={({isActive}) => (<span><span className="font_icon" style={{
                          fontSize: 10,
                          transform: 'rotate(' + (isActive ? 0 : 180) + 'deg)',
                          display: 'block'
                      }}>&#xe654;</span></span>)}>
                <Panel header={item.title} key="0">
                    <div className="knowledge-result__left-collapse time-item">
                        {
                            item.titleKey === 'time'
                                ? (
                                    <div onClick={(ev) => this.showYearSelect(ev)} className="year-select">

                                        <Select style={{width: '100%',textAlign: 'center'}}
                                                value={this.state.yearSelectValue.length === 2 ? `${this.state.yearSelectValue[0]} 年 - ${this.state.yearSelectValue[1]} 年` : undefined}
                                                onChange={val =>this.yearChange(val)}
                                                suffixIcon={<><Icon type="calendar" /></>}
                                                allowClear={true}
                                                placeholder={'起始年份 - 结束年份'}
                                                dropdownRender={(menuNode, props) => this.YearSelect}
                                                open={this.state.yearOpen}
                                        />
                                    </div>

                                )
                                : (
                                    <RangePicker format={'YYYY-MM-DD'} placeholder={['年-月-日', '年-月-日']}
                                                 onChange={(date, dateString) => this.changeTime(date, dateString, item)}/>
                                )
                        }
                    </div>
                </Panel>
            </Collapse>
        )
    }

    /**
     * 年选择框数据变更---用做清除事件
     */
    yearChange(val) {
        let yearSelectValue = [...this.state.yearSelectValue];
        let backSelectValue = [...this.state.backSelectValue];
        if(!_.isEmpty(backSelectValue) && !_.isEmpty(yearSelectValue)) {
            this.setState({
                yearSelectValue: [],
                backSelectValue: []
            },() =>{
                this.getSearchResult();
            })
        }
    }

    /**
     * 点击输入框显示时间选择框
     */
    showYearSelect(ev) {
        ev.nativeEvent.stopImmediatePropagation();
        this.setState({yearOpen: true})
    }

    /**
     * 下拉框显示内容
     */
    YearSelect() {
        let box1 = [];
        let box2 = [];
        let box3 = [];
        for (let i = 1990; i < 2002; i++) {
            box1.push(i)
        }
        for (let i = 2002; i < 2014; i++) {
            box2.push(i)
        }
        for (let i = 2014; i < 2026; i++) {
            box3.push(i)
        }
        return (
            <div className="select-year-box">
                <h3 className="select-year-title">
                    <span className={this.state.yearShowIndex===0?'disabled-icon pre-icon':"pre-icon"} onClick={this.preYear}><Icon type="double-left" /></span>
                    <span>{this.state.yearSelectValue.length === 2 ? `${this.state.yearSelectValue[0]}年 - ${this.state.yearSelectValue[1]}年` : this.state.yearSelectValue.length === 1 ? `${this.state.yearSelectValue[0]}年` : null}</span>
                    <span className={this.state.yearShowIndex===2?'disabled-icon next-icon':"next-icon"} onClick={this.nextYear}><Icon type="double-right" /></span>
                </h3>
                <div className="select-year-main" style={{marginLeft: -this.state.yearShowIndex * 232 + 'px'}}>
                    <ul className="select-year-list">
                        {
                            box1.map(item => {
                                return (
                                    <li key={item} onClick={(ev) => this.selectItem(item, ev)}
                                        className={this.setClass(item)}>
                                        <span>{item}</span></li>
                                )
                            })
                        }
                    </ul>
                    <ul className="select-year-list">
                        {
                            box2.map(item => {
                                return (
                                    <li key={item} onClick={(ev) => this.selectItem(item, ev)}
                                        className={this.setClass(item)}>
                                        <span>{item}</span></li>
                                )
                            })
                        }
                    </ul>
                    <ul className="select-year-list">
                        {
                            box3.map(item => {
                                return (
                                    <li key={item} onClick={(ev) => this.selectItem(item, ev)}
                                        className={this.setClass(item)}>
                                        <span>{item}</span></li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        )
    }

    /**
     * 设置每个年份的class名称
     */
    setClass(item) {
        let yearSelectValue = [...this.state.yearSelectValue];
        let className = 'select-year-item';
        if (item === yearSelectValue[0]) {
            className += ' start-year';
            if (item === yearSelectValue[1]) {
                className += ' end-year'
            }
            if (yearSelectValue.length === 1) {
                className += ' open-select-year'
            }
        } else if (item === yearSelectValue[1]) {
            className += ' end-year'
        } else {
            if (item > yearSelectValue[0] && item < yearSelectValue[1]) {
                className += ' between-year'
            }
        }
        if (item === thisYear) {
            className += ' current-year'
        }
        if (item > thisYear) {
            className += ' disabled-year'
        }
        return className
    }

    /**
     * 上一页事件
     */
    preYear() {
        let yearShowIndex = this.state.yearShowIndex;
        if (yearShowIndex === 0) return
        this.setState({
            yearShowIndex: yearShowIndex - 1
        })
    }

    /**
     * 下一页事件
     */
    nextYear() {
        let yearShowIndex = this.state.yearShowIndex;
        if (yearShowIndex === 2) return
        this.setState({
            yearShowIndex: yearShowIndex + 1
        })
    }

    /**
     * 下拉框中选择年份事件
     */
    selectItem(item, ev) {
        ev.stopPropagation();
        if (item > thisYear) return;
        let yearSelectValue = [...this.state.yearSelectValue];
        if (yearSelectValue.length === 0) {
            yearSelectValue.push(item);
            this.setState({
                yearSelectValue
            })
        } else if (yearSelectValue.length === 1) {
            if (item > yearSelectValue[0]) {
                yearSelectValue.push(item)
            } else {
                yearSelectValue.unshift(item)
            }
            console.log(yearSelectValue)
            this.setState({
                yearOpen: false,
                yearSelectValue: yearSelectValue,
                backSelectValue: yearSelectValue,
                yearShowIndex: 2
            }, () => {
                this.getSearchResult()
            })
        } else if (yearSelectValue.length === 2) {
            let backSelectValue = [...yearSelectValue];
            yearSelectValue = [item];
            this.setState({
                yearSelectValue,
                backSelectValue
            })
        }
    }

    disciplineIsChange = false

    changeDiscipline(vals) {
        if (!this.disciplineIsChange) {
            this.disciplineIsChange = true;
            let discipline = {...this.state.discipline};
            discipline.selectData = vals;
            this.setState({
                discipline
            })
        } else {
            this.disciplineIsChange = false;
            let discipline = {...this.state.discipline};
            discipline.selectData = vals;
            this.setState({
                discipline
            }, () => {
                this.getSearchResult();
            })
        }
    }

    disciplineSearch() {
        if (this.disciplineIsChange) {
            this.getSearchResult();
            this.disciplineIsChange = false;
        }
    }

    clearDiscipline() {
        this.disciplineIsChange = true;
    }

    /**
     * 多选框
     */
    setLeftCheck(item) {
        return (
            <Collapse className="check-item-collapse" key={item.titleKey} expandIconPosition="right"
                      defaultActiveKey={['0']} expandIcon={({isActive}) => (<span><span className="font_icon" style={{
                fontSize: 10,
                transform: 'rotate(' + (isActive ? 0 : 180) + 'deg)',
                display: 'block'
            }}>&#xe654;</span></span>)}>
                <Panel header={item.title} key="0">
                    <div className="knowledge-result__left-collapse checked-item">
                        <MultiCascader cascade={false}
                                       onChange={(vals, ev) => {
                                           console.log('change')
                                           this.changeDiscipline(vals)
                                       }}
                                       onClose={() => {
                                           console.log('close')
                                           this.disciplineSearch()
                                       }}
                                       onClean={(ev) => {
                                           console.log('clear')
                                           this.clearDiscipline()
                                       }}
                                       menuClassName="mult-select-menu"
                                       data={this.state.disciplineList}
                                       value={item.selectData}
                                       block
                                       placeholder="请选择学科"/>
                    </div>
                </Panel>
            </Collapse>
        )
    }

    industryHasChange = false

    changeMulSelect(valueList) {
        if (!this.industryHasChange) {
            this.industryHasChange = true;
            let industry = {...this.state.industry};
            industry.selectClass = valueList;
            this.setState({
                industry
            })
        } else {
            this.industryHasChange = false;
            let industry = {...this.state.industry};
            industry.selectClass = valueList;
            this.setState({
                industry
            }, () => {
                this.getSearchResult();
            })
        }
    }

    mulSelectSearch() {
        if (this.industryHasChange) {
            this.getSearchResult();
            this.industryHasChange = false;
        }
    }

    clearIndustry() {
        this.industryHasChange = true;
    }

    /**
     * 行业模块
     */
    setLeftIndustry(item) {
        return (
            <Collapse key={item.titleKey} expandIconPosition="right" defaultActiveKey={['0']}
                      expandIcon={({isActive}) => (<span><span className="font_icon" style={{
                          fontSize: 10,
                          transform: 'rotate(' + (isActive ? 0 : 180) + 'deg)',
                          display: 'block'
                      }}>&#xe654;</span></span>)}>
                <Panel header={item.title} key="0">
                    <div className="knowledge-result__left-collapse industry-item">
                        {/* 行业分类标准 */}
                        <div className="industry-item-classification">
                            <div className="industry-item-title">行业分类标准:</div>
                            <Select style={{width: '100%', height: 32}}
                                    value={this.state.industry.selectStandard}
                                    onChange={(val) => this.changeSelectClass(val, item)}
                                    placeholder="请选择行业分类标准">
                                {
                                    item.classificationStandard.map((sub, index) => {
                                        return (
                                            <Option value={sub.value} key={index}>{sub.label}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </div>
                        {/* 行业分类 */}
                        <div className="industry-item-classification">
                            <div className="industry-item-title">行业分类:</div>
                            <MultiCascader cascade={false}
                                           onChange={(vals, ev) => {
                                               console.log('change')
                                               this.changeMulSelect(vals)
                                           }}
                                           onClose={() => {
                                               console.log('close')
                                               this.mulSelectSearch()
                                           }}
                                           onClean={(ev) => {
                                               console.log('clear')
                                               this.clearIndustry()
                                           }}
                                           menuClassName="mult-select-menu"
                                           data={this.state.classificationSelect}
                                           value={item.selectClass}
                                           block
                                           placeholder="请选择行业分类"
                                           disabled={this.state.industryLoading}/>
                        </div>
                    </div>
                </Panel>
            </Collapse>
        )
    }

    /**
     * 数据来源模块
     */
    setDataFrom(item) {
        return (
            <Collapse key={item.titleKey} expandIconPosition="right" defaultActiveKey={['0']} className="time-item"
                      expandIcon={({isActive}) => (<span><span className="font_icon" style={{
                          fontSize: 10,
                          transform: 'rotate(' + (isActive ? 0 : 180) + 'deg)',
                          display: 'block'
                      }}>&#xe654;</span></span>)}>
                <Panel header={item.title} key="0">
                    <div className="knowledge-result__left-collapse dataFrom-item">
                        <Select style={{width: '100%', minHeight: 32}}
                                onChange={(val) => this.changeSelectOption(val, item)}
                                mode="multiple"
                                allowClear={true}
                                optionFilterProp="children"
                                showArrow={true}
                                placeholder="请选择数据来源">
                            {
                                item.selectData.map((sub, index) => {
                                    return (
                                        <Option value={sub} key={index}>{sub}</Option>
                                    )
                                })
                            }
                        </Select>
                    </div>
                </Panel>
            </Collapse>
        )
    }

    /**
     * 数据来源变更
     */
    changeSelectOption(val, item) {
        item.dataFrom = val;
        let key = item.titleKey;
        this.setState({
            [key]: item
        }, () => {
            this.getSearchResult()
        })
    }

    /**
     * 更改行业分类标准
     */
    changeSelectClass(val, item) {
        //debugger
        item.selectStandard = val;
        let key = item.titleKey;
        this.setState({
            [key]: item
        }, () => {
            //this.getSearchResult()
            //重新格式化数据，显示所属行业
            let tableData = [...this.state.tableData];
            for (let i = 0; i < tableData.length; i++) {
                this.setShowTheme(tableData[i])
            }
            this.setState({
                tableData
            })
            this.getClassificationList()
        })
    }

    /**
     * 更改行业分类
     */
    changeCascaderSelect(vals, item) {
        //debugger
        item.selectClass = vals;
        let key = item.titleKey;
        this.setState({
            [key]: item
        }, () => {
            this.getSearchResult()
        })
    }

    /**
     * 时间选择器更改事件
     */
    changeTime(date, dateString, item) {
        let key = item.titleKey;
        item.selectTime = dateString;
        this.setState({
            [key]: item
        }, () => {
            this.getSearchResult()
        })
    }

    /**
     * 折叠展开按钮
     */
    changeLeftFlod() {
        this.setState({
            flod: !this.state.flod
        })
    }

    /**
     * 折叠状态下点击菜单
     */
    flodItemChange(item) {
        if (item.titleKey !== this.state.selectType) {
            this.setState({
                selectType: item.titleKey
            }, () => {
                this.getSearchResult()
            })
        }
    }

    /**
     * 生成知识图谱
     */
    showRelationGraph() {
        //const _this = this;
        //将页码重置为第一页
        //this.pageChange(1,this.state.pageSize);
        this.setState({
            graphLoading: true
            //showRelation: true
        }, () => {

            //let hadInfo = true;
            let postData = {
                type: null,
                //keyword: this.state.keyword,
                pageIndex: this.state.page,
                pageSize: this.state.pageSize,
                excepts: {}
            };
            switch (this.state.selectType) {
                case 'attributeInfo':
                    let attributeInfo = this.state.attributeInfo;
                    postData.excepts.entity = attributeInfo.entity || [];
                    postData.excepts.attribute = attributeInfo.attribute || [];
                    postData.excepts.value = {};
                    postData.excepts.value.start = attributeInfo.value[0] || '';
                    postData.excepts.value.end = attributeInfo.value[1] || '';
                    postData.type = 1;
                    if (_.isEmpty(postData.excepts.entity) && _.isEmpty(postData.excepts.attribute) && _.isEmpty(postData.excepts.value.start) && _.isEmpty(postData.excepts.value.end)) {
                        postData.keyword = this.state.keyword;
                        //hadInfo = false;
                    }
                    break;
                case 'generalStructure':
                    let generalStructure = this.state.generalStructure;
                    postData.excepts.subject = generalStructure.subject || [];
                    postData.excepts.predicate = generalStructure.predicate || [];
                    postData.excepts.object = generalStructure.object || [];
                    postData.type = 2;
                    if (_.isEmpty(postData.excepts.subject) && _.isEmpty(postData.excepts.predicate) && _.isEmpty(postData.excepts.object)) {
                        postData.keyword = this.state.keyword;
                        //hadInfo = false;
                    }
                    break;
                case 'relationInfo':
                    let relationInfo = this.state.relationInfo;
                    postData.excepts.entity = relationInfo.entity || [];
                    postData.excepts.relation = relationInfo.relation || [];
                    postData.excepts.entity2 = relationInfo.entity2 || [];
                    postData.type = 3;
                    if (_.isEmpty(postData.excepts.entity) && _.isEmpty(postData.excepts.relation) && _.isEmpty(postData.excepts.entity2)) {
                        postData.keyword = this.state.keyword;
                        //hadInfo = false;
                    }
                    break;
                default:
                    break;
            }


            postData.excepts.source = this.state.dataFrom.dataFrom;       //消息来源
            postData.excepts.classifier = this.state.discipline.selectData;   //学科
            //postData.excepts.enterprise = this.state.industry.selectClass;   //企业名称
            postData.excepts.date = {};         //发表日期
            postData.excepts.date.start = this.state.dataFromTime.selectTime[0] || '';
            postData.excepts.date.end = this.state.dataFromTime.selectTime[1] || '';
            postData.excepts.time = {};         //知识点时间
            postData.excepts.time.start = this.state.yearSelectValue[0] || '';
            postData.excepts.time.end = this.state.yearSelectValue[1] || '';
            postData.excepts.theme = {};

            /** 格式化行业分类数据 */
            let result = [];
            let selectClass = [...this.state.industry.selectClass];     //已选择的数据
            if (selectClass.length > 0) {
                for (let i = 0; i < selectClass.length; i++) {
                    formatSelectData(selectClass[i], this.state.classificationSelect, result)
                }
                removeArrChild(result)
                console.log(JSON.stringify(result))
            }

            switch (this.state.industry.selectStandard) {
                case '1':
                    postData.excepts.theme['sw_classifier'] = result;
                    break;
                case '2':
                    postData.excepts.theme['cics_classifier'] = result;
                    break;
                case '3':
                    postData.excepts.theme['csrc_classifier'] = result;
                    break;
                default:
                    postData.excepts.theme['sw_classifier'] = result;
                    break;
            }

            postData.excepts = JSON.stringify(postData.excepts)
            webSdk.search.knowledgeGraphData(postData).then(res => {
                let resultPoint = [],
                    resultLinks = [];
                for (let i = 0; i < res.length; i++) {
                    let links = [];
                    let newPoint = [];
                    //let newLinks = [];
                    getChildNum(res[i])
                    getPoint(res[i], links)
                    formatCanvasData(res[i], links, newPoint);
                    resultPoint = resultPoint.concat(newPoint);
                    resultLinks = resultLinks.concat(links);
                }
                //debugger
                resultPoint = listRemoveRepeat(resultPoint, 'name')
                resultLinks = listRemoveRepeat2(resultLinks, 'source', 'target')
                this.setState({
                    pointData: resultPoint,
                    linksData: resultLinks
                }, () => {
                    let newData = [...resultPoint],
                        newLinks = [...resultLinks];
                    let outArr = [];        //超过3层不显示数据
                    let outFourChild = [];
                    for (let i = 0; i < newData.length; i++) {
                        if (newData[i].level > 1 && newData[i].level <= 3) {
                            if (newData[i].child > 4) {
                                let n = 0;
                                for (let j = 0; j < newLinks.length; j++) {
                                    if (newLinks[j].source === newData[i].name) {
                                        if (n === 4) {
                                            outFourChild.push(newLinks[j]);
                                            newLinks.splice(j, 1);
                                            newData[i].currentChild--;
                                            j--;
                                        } else {
                                            n++
                                        }
                                    }
                                }
                            }
                        } else if (newData[i].level > 3) {
                            outArr.push(newData[i]);
                            newData.splice(i, 1);
                            i--
                        }
                    }

                    for (let k = 0; k < outFourChild.length; k++) {
                        for (let l = 0; l < newData.length; l++) {
                            if (newData[l].name === outFourChild[k].target) {
                                newData.splice(l, 1);
                                break;
                            }
                        }
                    }

                    for (let j = 0; j < outArr.length; j++) {
                        for (let n = 0; n < newLinks.length; n++) {
                            if (newLinks[n].target === outArr[j].name) {
                                for (let k = 0; k < newData.length; k++) {
                                    if (newData[k].name === newLinks[n].source) {
                                        newData[k].currentChild--
                                    }
                                }
                                newLinks.splice(n, 1);
                                n--;
                            }
                        }
                    }
                    this.setPointData(newData, newLinks);
                })


                //let resultOpts = 
                this.setState({
                    graphLoading: false,
                    showRelation: true
                })
            }).catch(e => {
                this.setState({
                    graphLoading: false
                })
                message.error('生成知识图谱失败，请稍后重试')
            })
        })

        /**
         * 格式化初始数据，获取data和links
         */
        function formatCanvasData(pointData, linksData, newPointData) {
            linksData.forEach((item, index) => {
                if (item.showLabel) {
                    item.label = {
                        show: true,
                        formatter: item.showLabel
                    }
                }
            });
            newPointData.push({
                name: pointData.name,
                level: pointData.level,
                child: pointData.child,
                currentChild: pointData.child,
                dataFrom: pointData.dataFrom,
                recordTime: pointData.recordTime,
                itemStyle: {
                    color: 'red',
                    borderWidth: 8,
                    borderColor: {
                        type: 'radial',
                        colorStops: [
                            {
                                offset: 0, color: 'rgba(255,0,0,0.1)' // 0% 处的颜色
                            },
                            {
                                offset: 1, color: 'rgba(255,0,0,0.3)' // 100% 处的颜色
                            }
                        ],
                        global: false // 缺省为 false
                    }
                },
                label: {
                    fontSize: 16
                }
            })

            if (pointData.children) {
                getNewData(pointData.children);
            }

            /**
             * 循环获取各个节点
             */
            function getNewData(item) {
                for (let i = 0; i < item.length; i++) {
                    let obj = {
                        name: item[i].name,
                        level: item[i].level,
                        child: item[i].child,
                        dataFrom: item[i].dataFrom,
                        recordTime: item[i].recordTime
                    };
                    if (item[i].child !== 0) {
                        obj.currentChild = item[i].child;
                        newPointData.push(obj);
                        getNewData(item[i].children)
                    } else {
                        newPointData.push(obj);
                    }
                }
            }
        }

        /**
         * 获取point信息
         */
        function getPoint(data, links) {
            if (data.children && data.children.length > 0) {
                for (let i = 0; i < data.children.length; i++) {
                    let item = data.children[i];
                    let obj = {
                        source: data.name,
                        target: item.name,
                    }
                    if (item.label) {
                        obj.showLabel = item.label
                    } else {
                        if (item.children && item.children.length > 1) {
                            obj.showLabel = item.name
                        }
                    }
                    links.push(obj)
                    if (item.children && item.children.length > 0) {
                        getPoint(item, links)
                    }
                }
            }
        }

        /**
         * 计算节点child数量
         */
        function getChildNum(data) {
            if (data.children && data.children.length > 0) {
                data.child = data.children.length;
                for (let i = 0; i < data.children.length; i++) {
                    getChildNum(data.children[i]);
                }
            } else {
                data.child = 0
            }
        }
    }

    /**
     * 知识点导出按钮
     */
    exportData() {
        let hadInfo = true;
        let type = null,
            keyword = '',
            //keyword = this.state.keyword,
            excepts = {};
        switch (this.state.selectType) {
            case 'attributeInfo':
                let attributeInfo = this.state.attributeInfo;
                excepts.entity = attributeInfo.entity || [];
                excepts.attribute = attributeInfo.attribute || [];
                excepts.value = {};
                excepts.value.start = attributeInfo.value[0] || '';
                excepts.value.end = attributeInfo.value[1] || '';
                type = 1;
                if (_.isEmpty(excepts.entity) && _.isEmpty(excepts.attribute) && _.isEmpty(excepts.value.start) && _.isEmpty(excepts.value.end)) {
                    keyword = this.state.keyword;
                    hadInfo = false;
                }
                break;
            case 'generalStructure':
                let generalStructure = this.state.generalStructure;
                excepts.subject = generalStructure.subject || [];
                excepts.predicate = generalStructure.predicate || [];
                excepts.object = generalStructure.object || [];
                type = 2;
                if (_.isEmpty(excepts.subject) && _.isEmpty(excepts.predicate) && _.isEmpty(excepts.object)) {
                    keyword = this.state.keyword;
                    hadInfo = false;
                }
                break;
            case 'relationInfo':
                let relationInfo = this.state.relationInfo;
                excepts.entity = relationInfo.entity || [];
                excepts.relation = relationInfo.relation || [];
                excepts.entity2 = relationInfo.entity2 || [];
                type = 3;
                if (_.isEmpty(excepts.entity) && _.isEmpty(excepts.relation) && _.isEmpty(excepts.entity2)) {
                    keyword = this.state.keyword;
                    hadInfo = false;
                }
                break;
            default:
                break;
        }
        excepts.source = this.state.dataFrom.dataFrom;       //消息来源
        excepts.classifier = this.state.discipline.selectData;   //学科
        //excepts.enterprise = this.state.industry.selectClass;   //企业名称
        excepts.date = {};         //发表日期
        excepts.date.start = this.state.dataFromTime.selectTime[0] || '';
        excepts.date.end = this.state.dataFromTime.selectTime[1] || '';
        excepts.time = {};         //知识点时间
        excepts.time.start = this.state.yearSelectValue[0] || '';
        excepts.time.end = this.state.yearSelectValue[1] || '';
        excepts.theme = {};


        /** 格式化行业分类数据 */
        let result = [];
        let selectClass = [...this.state.industry.selectClass];     //已选择的数据
        if (selectClass.length > 0) {
            for (let i = 0; i < selectClass.length; i++) {
                formatSelectData(selectClass[i], this.state.classificationSelect, result)
            }
            removeArrChild(result)
            console.log(JSON.stringify(result))
        }

        switch (this.state.industry.selectStandard) {
            case '1':
                excepts.theme['sw_classifier'] = result;
                break;
            case '2':
                excepts.theme['cics_classifier'] = result;
                break;
            case '3':
                excepts.theme['csrc_classifier'] = result;
                break;
            default:
                excepts.theme['sw_classifier'] = result;
                break;
        }

        excepts = JSON.stringify(excepts)
        if (hadInfo) {
            const w = window.open('about:blank');
            //w.location.href = `http://192.168.0.142:8081/api/search/knowledgeExport?type=${type}&excepts=${excepts}`
            w.location.href = `http://demo-search.phbs.wezuzhi.com/api/search/knowledgeExport?type=${type}&excepts=${excepts}`
        } else {
            const w = window.open('about:blank');
            //w.location.href = `http://192.168.0.142:8081/api/search/knowledgeExport?type=${type}&keyword=${keyword}&excepts=${excepts}`
            w.location.href = `http://demo-search.phbs.wezuzhi.com/api/search/knowledgeExport?type=${type}&keyword=${keyword}&excepts=${excepts}`
        }

    }

    /**
     * 关闭知识图谱
     */
    fullScreenExit() {
        this.setState({
            showRelation: false
        })
    }

    /**
     * 页码变更
     */
    pageChange(page, pageSize) {
        this.setState({
            page: page - 1
        }, () => {
            this.getSearchResult()
        })
    }

    /**
     * 页尺寸变更
     */
    pageSizeChange(current, size) {
        this.setState({
            page: current - 1,
            pageSize: size
        }, () => {
            this.getSearchResult()
        })
    }

    /**
     * 更改类型
     */
    changeTypeCard(key) {
        if (key !== this.state.selectType) {
            let columns = []
            switch (key) {
                case 'generalStructure':
                    columns = this.generalColumns;
                    break;
                case 'attributeInfo':
                    columns = this.attributeColumns;
                    break;
                case 'relationInfo':
                    columns = this.relationColumns;
                    break;
                default:
                    columns = this.attributeColumns;
                    break;
            }
            let dataFrom = {...this.state.dataFrom};
            dataFrom.dataFrom = [];
            this.setState({
                columns,
                selectType: key,
                dataFrom,
                page: 0
            }, () => {
                this.getSearchResult()
            })
        }
    }

    dataFromClick(item) {
        this.props.history.push({
            pathname: `/researchResult/${item.type}/${item.objId}/100`
        })
    }

    render() {
        return (<div className="knowledge-result-page">
            <div className="knowledge-result-main">
                {/* 左侧栏 */}
                <div className="knowledge-result__left"
                     style={!this.state.flod ? {width: 270} : {width: 150, marginRight: 34}}>
                    <div className="knowledge-result__left-icon">
                        <span className="font_icon"
                              style={this.state.flod ? {display: 'block', width: 24, transform: 'rotate(180deg)'} : {}}
                              onClick={this.changeLeftFlod}>&#xe65f;</span>
                    </div>
                    <div className="knowledge-result__left-main">
                        {
                            this.state.flod ? (
                                /* 折叠样式 */
                                <ul className="left-fload-style">
                                    <li className={this.state.selectType === 'generalStructure' ? 'left-fload-item select-fload-item' : "left-fload-item"}
                                        onClick={() => this.flodItemChange(this.state.generalStructure)}>{this.state.generalStructure.title}</li>
                                    <li className={this.state.selectType === 'attributeInfo' ? 'left-fload-item select-fload-item' : "left-fload-item"}
                                        onClick={() => this.flodItemChange(this.state.attributeInfo)}>{this.state.attributeInfo.title}</li>
                                    <li className={this.state.selectType === 'relationInfo' ? 'left-fload-item select-fload-item' : "left-fload-item"}
                                        onClick={() => this.flodItemChange(this.state.relationInfo)}>{this.state.relationInfo.title}</li>
                                </ul>
                            ) : (
                                <div className="left-unfload-style">
                                    <div className="type-card-item">
                                        <Tabs onChange={this.changeTypeCard} type="card">
                                            <TabPane tab={this.state.generalStructure.title}
                                                     key={this.state.generalStructure.titleKey}>
                                                {this.setLeftItem(this.state.generalStructure)}
                                            </TabPane>
                                            <TabPane tab={this.state.attributeInfo.title}
                                                     key={this.state.attributeInfo.titleKey}>
                                                {this.setLeftItem(this.state.attributeInfo)}
                                            </TabPane>
                                            <TabPane tab={this.state.relationInfo.title}
                                                     key={this.state.relationInfo.titleKey}>
                                                {this.setLeftItem(this.state.relationInfo)}
                                            </TabPane>
                                        </Tabs>
                                    </div>

                                    {this.setLeftTime(this.state.time)}
                                    {this.setLeftCheck(this.state.discipline)}
                                    {this.setLeftIndustry(this.state.industry)}
                                    {this.setDataFrom(this.state.dataFrom)}
                                    {this.setLeftTime(this.state.dataFromTime)}
                                </div>
                            )
                        }
                    </div>
                </div>

                {/* 右侧栏 */}
                <div className="knowledge-result__right">
                    <div className="knowledge-result__right-createMap-btn">
                        <Button type="primary" onClick={this.showRelationGraph.bind(this)}
                                style={{padding: '0 15px', fontSize: 12}} loading={this.state.graphLoading}>生成知识图谱<Icon type="picture" /></Button>
                        <Button type="primary" onClick={this.exportData.bind(this)}
                                style={{width: 100, height: 32, padding: 0, fontSize: 12, marginLeft: 21}}>知识点导出<Icon
                            type="cloud-download"/></Button>
                    </div>

                    <Spin spinning={this.state.tableLoading}>
                        <div className="knowledge-result__right-main">
                            <Table
                                columns={this.state.columns}
                                dataSource={this.state.tableData}
                                rowClassName={(record, index) => index % 2 === 0 ? 'single-row' : 'double-row'}
                                rowKey={(record, index) => {
                                    return index
                                }}
                                pagination={{
                                    showSizeChanger: true,
                                    showQuickJumper: true,
                                    pageSizeOptions: ["10", "15", "20", "25"],
                                    current: this.state.page + 1,
                                    pageSize: this.state.pageSize,
                                    total: this.state.pageTotal,
                                    onChange: (page, pageSize) => this.pageChange(page, pageSize),
                                    onShowSizeChange: (current, size) => this.pageSizeChange(current, size),
                                    showTotal: total => `共 ${total} 条`
                                }}
                            />
                        </div>
                    </Spin>

                </div>
            </div>

            {/*全屏显示知识图谱*/}
            {
                this.state.showRelation ? (
                    <div className="show-full-screen">
                        <div className="company-echarts-canvas">
                            <ReactEcharts
                                ref="echarts_graph_all"
                                option={this.state.options}
                                style={{height: '100vh'}}
                                onEvents={this.onclick}
                            />
                        </div>
                        <div className="full-screen-exit"><Button onClick={this.fullScreenExit.bind(this)}
                                                                  type="primary">返回</Button></div>
                    </div>
                ) : null
            }

        </div>)
    }
}

export default KnowledgeResultIndex