import React from 'react'
import './Index.scss'
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4plugins_forceDirected from "@amcharts/amcharts4/plugins/forceDirected";

import {Button} from 'antd'

const mockData = [
    {
        name: "Core",
        children: [
            {
                name: "First",
                children: [
                    {name: "A1", value: 400},
                    {name: "A2", value: 400}
                ]
            },
            {
                name: "Second",
                children: [
                    {name: "B1", value: 400},
                    {name: "B2", value: 400}
                ]
            },
            {
                name: "Third",
                children: [
                    {
                        name: "C1",
                        children: [
                            {name: "EE1", value: 300},
                            {name: "EE2", value: 300},
                            {name: "EE3", value: 300}
                        ]
                    },
                    {name: "C2", value: 400},
                    {
                        name: "C3", children: [
                            {name: "CC1", value: 300},
                            {name: "CC2", value: 300}
                        ]
                    },
                    {name: "C4", value: 400}
                ]
            },
            {
                name: "Fourth",
                children: [
                    {name: "D1", value: 400},
                    {name: "D2", value: 400},
                    {name: "D3", value: 400}
                ]
            },
            {
                name: "Fifth",
                children: [
                    {
                        name: "E1",
                        children: [
                            {name: "EE1", value: 300},
                            {name: "EE2", value: 300},
                            {name: "EE3", value: 300}
                        ]
                    },
                    {
                        name: "E2",
                        value: 400
                    }
                ]
            }

        ]
    },
];

const colors = ['#1ED8A9','#765EFA','#8913D6','#76CA50','#F87F52'];

let chart = null;
let networkSeries = null;

class RelationGraph extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            graphData: props.graphData!==undefined?props.graphData:mockData,
            backgroundColor: props.backgroundColor || '#fff',
            /*isSearchPage: props.isSearchPage || false,          //是否搜索结果页面
            isCursorPoint: props.isCursorPoint || false,        // 搜索结果页面-是否处于选择添加节点状态*/
        }

        this.chartClick = this.chartClick.bind(this)
        this.nodeClick = this.nodeClick.bind(this)
    }

    componentDidMount() {
        this.setGraph();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        /*if (this.state.isCursorPoint !== nextProps.isCursorPoint) {
            if (nextProps.isCursorPoint) {
                networkSeries.nodes.template.togglable = false;
                this.chartClick();
            }
            else {
                networkSeries.nodes.template.togglable = true;
            }
            //debugger
            this.setState({
                isCursorPoint: nextProps.isCursorPoint
            })
        }*/
    }

    componentWillUnmount() {
        // 卸载异步操作设置状态
        this.setState = (state, callback) => {
            return;
        }
    }

    setGraph = () => {
        /**
         * API      https://www.amcharts.com/docs/v4/chart-types/force-directed/
         */
        am4core.useTheme(am4themes_animated);
// Themes end

        chart = am4core.create("chart-div", am4plugins_forceDirected.ForceDirectedTree);
        networkSeries = chart.series.push(new am4plugins_forceDirected.ForceDirectedSeries())

        chart.data = this.state.graphData;

        /*networkSeries.draggable = 1;
        networkSeries.resizable = 1;
        networkSeries.nonScaling = 1;            //是否可缩放
        networkSeries.nonScalingStroke = 1;      //节点和文字是否也缩放
        networkSeries.swipeable = 1;            //是否可拖动*/

        // 颜色
        /*networkSeries.colors.list = [
            am4core.color("#1ED8A9"),
            am4core.color("#765EFA"),
            am4core.color("#8913D6"),
            am4core.color("#76CA50"),
            am4core.color("#F87F52")
        ];*/
        //规定字段
        networkSeries.dataFields.value = "value";           //圆的大小，有子集可以不设置（根据子集自动设置，大小为子集value的和），无子集必须设置
        networkSeries.dataFields.name = "name";
        networkSeries.dataFields.children = "children";
        networkSeries.dataFields.collapsed = 'children';          //默认是否展开节点
        // networkSeries.nodes.template.expandAll = false;      //点击只显示下一级


        //圆形上的文字
        networkSeries.nodes.template.label.text = "{name}"
        networkSeries.fontSize = 15;            //字体大小
        /*if (this.state.isSearchPage) {
            networkSeries.nodes.template.label.fill = "#333"        //节点字体颜色
        }*/
        // networkSeries.minRadius = 15;
        // networkSeries.maxRadius = 40;
//debugger
//         networkSeries.nodes.template.circle.disabled = true;
        networkSeries.nodes.template.circle.nonScaling = true;             //禁止节点缩放
        networkSeries.nodes.template.outerCircle.fill = "#130D37";         //外节点颜色
        networkSeries.nodes.template.outerCircle.fill = this.state.backgroundColor;         //外节点颜色
        networkSeries.nodes.template.outerCircle.nonScaling = true;        //禁止外节点缩放
        //networkSeries.nodes.template.outerCircle.fill = "transparent";


        networkSeries.nodes.template.tooltipText = "{label}：{name}";        //鼠标移入时显示的文字
        networkSeries.nodes.template.fillOpacity = 1;
        networkSeries.tooltip.getFillFromObject = false;
        networkSeries.tooltip.background.fill = am4core.color("#CEB1BE");

        networkSeries.links.template.strokeWidth = 5;       //连线的宽度
        networkSeries.links.template.strokeOpacity = 0.5;     //连线的透明度
        //networkSeries.links.template.distance = 0.2;        //两主节点距离，默认1.5
        networkSeries.manyBodyStrength = -14;               //主节点与次节点圆点之间的距离
        //debugger
        //networkSeries.nodes.template.tooltipColorSource = '#ffffff';
        //networkSeries.nodes.template.tooltipDataItem.sprites = ['#ffffff'];

        networkSeries.links.template.strength = 0.3;
        networkSeries.minRadius = am4core.percent(2);

        /*chart.seriesContainer.events.on("hit", function(ev) {
            debugger
            console.log(ev.target.baseSprite);
        });*/
        //this.chartClick();

        setTimeout(() =>{
            this.chartClick();
        },2000)



        console.log(networkSeries)
    }

    chartClick(){
        const _this = this;
        let div = document.querySelector('.chart-div');
        if (!div) return;
        let arrG = div.querySelectorAll('g');
        if (arrG.length===0) return;
        arrG.forEach(item =>{
            if (item.attributes.role && item.attributes.role.value==="menuitem") {
                let tspan = item.querySelector('tspan');
                let text = tspan?tspan.textContent:null;
                item.onclick= function() {
                    _this.nodeClick(item,text)
                }
            }
        });
    }

    nodeClick(item,text,e) {
        if (this.state.isCursorPoint) {
            this.props.addGraphText(text)
        }

    }

    backBtn = () => {
        this.props.backBtn();
    }

    render() {
        return (<div className="relation-graph">
            <Button className="relation-graph-back" onClick={this.backBtn}>返回</Button>
            <div className="chart-div"/>
        </div>)
    }
}

export default RelationGraph