export const addEvent = (eventType, fn, { dom } = {}) => {
  function resize(...args) {
    try {
      fn(...args)
    } catch {}
  }

  dom = dom || window
  return {
    on() {
      dom.addEventListener(eventType, resize)
      return this
    },
    off() {
      dom.removeEventListener(eventType, resize)
    }
  }
}
