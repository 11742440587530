import React from 'react'
import {webSdk} from "../../../../api/WebSdk";
import {DatePicker, Select} from 'antd'
import './RightHotModule.scss'
const { RangePicker } = DatePicker;
const Option = Select.Option;

class RightConditionFilter extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            keyword: props.keyword,
            currentCard: props.currentCard,
            dataFromList: [],   //数据来源列表
            reportFromList: [], //研报筛选列表
            dataFrom: [],       //当前选择的数据来源
            reportFrom: [],     //当前选择的研报筛选
            choiceTime: '',

        }

        this.getBaseInfo = this.getBaseInfo.bind(this);
        this.choiceTime = this.choiceTime.bind(this);
        this.changeSelect = this.changeSelect.bind(this);
        this.blurSelect = this.blurSelect.bind(this);
    }

    componentWillMount() {
        this.getBaseInfo()
    }

    componentWillReceiveProps(nextProps) {

    }

    //获取获取来源、研报数据
    getBaseInfo(){
        let postData = {
            currentCard: this.state.currentCard,
            p: this.state.keyword
        }
        webSdk.search.getCompanyBase(postData).then(res => {
            this.setState({
                dataFromList: res.dataFrom,
                reportFromList: res.reportFrom
            })
        })
    }

    //选择时间
    choiceTime(date, dateString){
        this.setState({
            choiceTime: dateString[0] + '-' + dateString[1]
        })
        this.props.fnChoiceTime(dateString[0] + '-' + dateString[1])
    }


    /**
     * 数据来源、研报筛选删除选择项做请求
     * @param vals
     * @param type
     */
    changeSelect(vals,type){
        if (type==='dataFrom') {
            if (vals.length < this.state.dataFrom.length) {
                this.setState({
                    dataFrom: vals
                })
                this.props.fnChangeSelect(vals,type)
            }
        }
        else if (type==='reportFrom') {
            if (vals.length < this.state.reportFrom.length) {
                this.setState({
                    reportFrom: vals
                })
                this.props.fnChangeSelect(vals,type)
            }
        }
    }

    /**
     * 数据来源、研报筛选失去焦点做筛选结果请求
     * @param vals  当前选择的值
     * @param type  当前选择的对象     dataFrom：数据来源；reportFrom：研报筛选
     */
    blurSelect(vals,type) {
        if (type==='dataFrom') {
            //判断是否变更了选择数据
            if (vals.toString()!==this.state.dataFrom.toString()) {
                this.setState({
                    dataFrom: vals
                })
                this.props.fnBlurSelect(vals,type)
            }
        }
        else if (type==='reportFrom') {
            if (vals.toString()!==this.state.reportFrom.toString()) {
                this.setState({
                    reportFrom: vals
                })
                this.props.fnBlurSelect(vals,type)
            }
        }
    }

    render() {
        return (
            <div className="right-item">
                <h3 className="right-item-title">条件筛选</h3>
                <div className="right-item-content right-item-filter">

                    <div className="right-item-select">
                        <div className="select-label">研报统计时间</div>
                        <RangePicker style={{width: 236, minHeight: 32, textAlign: 'left'}} size="default"
                                     format='YYYY/MM/DD' placeholder={['开始', '结束']}
                                     onChange={this.choiceTime}/>
                    </div>

                    <div className="right-item-select">
                        <div className="select-label">来源筛选</div>
                        <Select mode="multiple" style={{width: 236, minHeight: 32}} onChange={vals => {
                            this.changeSelect(vals, 'dataFrom')
                        }} onBlur={vals => {
                            this.blurSelect(vals, 'dataFrom')
                        }} placeholder="请选择数据来源">
                            {
                                this.state.dataFromList.map((item, index) => {
                                    return <Option value={item.value} key={index}>{item.label}</Option>
                                })
                            }
                        </Select>
                    </div>

                    <div className="right-item-select">
                        <div className="select-label">研报筛选</div>
                        <Select mode="multiple" style={{width: 236, minHeight: 32}} onChange={vals => {
                            this.changeSelect(vals, 'reportFrom')
                        }} onBlur={vals => {
                            this.blurSelect(vals, 'reportFrom')
                        }} placeholder="请选择研报类型">
                            {
                                this.state.reportFromList.map((item, index) => {
                                    return <Option value={item.value} key={index}>{item.label}</Option>
                                })
                            }
                        </Select>
                    </div>
                </div>
            </div>
        )
    }
}

export default RightConditionFilter