import XLSX from 'xlsx'

// view https://www.cnblogs.com/recode-hyh/p/13168226.html

function sheetDownload(luckysheet) {
    let allSheetData = luckysheet.getluckysheetfile();
    let fileName = '';
    // let downOriginDatas = [];
    // let sheet1 = allSheetData[0];
    // let downOriginData = sheet1.data;

// 该方法会返回一个数组结构，其中包含了我们所创建的所有的sheet数据
// allSheetData = [{sheet1},{sheet2},{sheet3}]

// 获取单元格的背景色
    let percentageReg = /%$/;

    function setBackground(row, col, bg) {
        var colA = luckysheet.luckysheetchatatABC(col);
        var key = colA + (row + 1);
        bgConfig[key] = bg.replace(/\#?/, '');
    }

// 判断值类型是否为百分比 %
    function isPercentage(value) {
        return percentageReg.test(value.m) && value.ct && value.ct.t === 'n'
    }

    function formatSheetData(downOriginData) {
// 获取二维数组
        let arr = [];  // 所有的单元格数据组成的二维数组
        let bgConfig = {};
        let cellValue = null;
        for (let row = 0; row < downOriginData.length; row++) {
            let arrRow = [];
            for (let col = 0; col < downOriginData[row].length; col++) {
                if (cellValue = downOriginData[row][col]) {
                    // 处理单元格的背景颜色
                    if (cellValue.bg) {
                        setBackground(row, col, cellValue.bg)
                    }
                    if (cellValue.ct != null && cellValue.ct.t == 'd') {
                        //  d为时间格式  2019-01-01   或者2019-01-01 10:10:10
                        arrRow.push(new Date(cellValue.m.replace(/\-/g, '/'))) //兼容IE
                    } else if (cellValue.m && isPercentage(cellValue)) {
                        //百分比问题
                        arrRow.push(cellValue.m)
                    } else {
                        arrRow.push(cellValue.v)
                    }
                }
            }
            arr.push(arrRow)
        }

        // 第二步：通过SheetJs将数据转化为excel格式数据
        let opts = {
            dateNF: 'm/d/yy h:mm',
            cellDates: true,
            cellStyles: true
        }
        let ws = XLSX.utils.aoa_to_sheet(arr, opts)

        // 第三步：设置单元格的类型以及单元格样式
        let reg = /[\u4e00-\u9fa5]/g;
        for (let key in ws) {
            let item = ws[key]
            if (item.t === 'd') {
                if (item.w) {
                    //时间格式的设置
                    let arr = item.w.split(' ')
                    if (arr[1] && arr[1] == '0:00') {
                        ws[key].z = 'm/d/yy'
                    } else {
                        item.z = 'yyyy/m/d h:mm:ss'
                    }
                }
            } else if (item.t === 's') {
                //百分比设置格式
                if (item.v && !item.v.match(reg) && item.v.indexOf('%') > -1) {
                    item.t = 'n'
                    item.z = '0.00%'
                    item.v = Number.parseFloat(item.v) / 100
                } else if (item.v && item.v.match(reg)) {
                    //含有中文的设置居中样式
                    item['s'] = {
                        alignment: {vertical: 'center', horizontal: 'center'}
                    }
                }
            }
            // 设置单元格样式
            if (bgConfig[key]) {
                ws[key]['s'] = {
                    alignment: {vertical: 'center', horizontal: 'center'},
                    fill: {bgColor: {indexed: 32}, fgColor: {rgb: bgConfig[key]}},
                    border: {
                        top: {style: 'thin', color: {rgb: '999999'}},
                        bottom: {style: 'thin', color: {rgb: '999999'}},
                        left: {style: 'thin', color: {rgb: '999999'}},
                        right: {style: 'thin', color: {rgb: '999999'}}
                    }
                }
            }
        }

        return ws
    }

    let sheetNames = [];    // sheet名称
    let sheets = {};        // sheet内容
    allSheetData.forEach((item, index) => {
        let downOriginData = item.data;
        let sheetName = item["name"];
        if (index === 0) {
            let time = getTimeStr();
            fileName = sheetName + time;
        }
        sheetNames.push(sheetName);
        let ws = formatSheetData(downOriginData);
        sheets[sheetName] = Object.assign({}, ws)//内容
    });

    // 第四步：组装下载数据格式
    let tmpWB = {
        SheetNames: sheetNames, //保存的表标题
        Sheets: sheets
    }

    // 第六步：写入文件
// sheetjs js-xlsx 的方法 ，不能设置单元格格式
    XLSX.writeFile(tmpWB, fileName + ".xlsx");
}

function getTimeStr() {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();
    if (month < 10) month = '0' + month;
    if (day < 10) day = '0' + day;
    if (hour < 10) hour = '0' + hour;
    if (minute < 10) minute = '0' + minute;
    if (second < 10) second = '0' + second;

    let time = year + '' + month + day + hour + minute + second;
    return time;
}


export default sheetDownload
