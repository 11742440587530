import React from 'react'
import './index.scss'
import Markmap from "../../../../common/markMap";
import './MindGraph.scss'
import _ from 'lodash'
import {Empty} from 'antd'

// let graphMap = null;

class MindGraph extends React.PureComponent {

    constructor(props) {
        super(props)

        this.state = {
            keyword: props.keyword || '',
            graphData: props.graphData || [],
            treeData: {}
        }
        this.graphMap = null;
    }

    componentDidMount() {
        if (this.state.graphData.length > 0) {
            this.drawGraph()
        }
    }

    componentWillReceiveProps(nextProps) {
        /*if (nextProps.graphData.length === 0) {
            let map = document.getElementById('mindmap');
            if (map) {
                map.innerHTML = null;
            }
        }*/
        if (!_.isEqual(this.state.graphData, nextProps.graphData)) {
            this.setState({
                graphData: nextProps.graphData,
                keyword: nextProps.keyword
            }, () => {
                if (nextProps.graphData.length > 0) {
                    this.drawGraph()
                }
            })

            if (this.state.keyword !== nextProps.keyword) {
                this.setState({
                    keyword: nextProps.keyword
                })
            }
        }
    }

    componentWillUnmount() {
        // 卸载异步操作设置状态
        this.setState = (state, callback) => {
            return;
        }
    }

    drawGraph = () => {
        let graphData = [...this.state.graphData];
        let treeData = {
            name: 0,
            key: 'keyword',
            keyLabel: '关键词：' + this.state.keyword,
            children: graphData
        };
        this.setState({
            treeData
        })
        this.graphMap = Markmap('svg#mindmap', treeData, {
            preset: 'default', // or default/colorful
            linkShape: 'diagonal' // or bracket
        }, this.props.nodeClick);
    }


    render() {
        let clsName = this.props.isCursorPoint ? "result-search__mind cursor-status" : "result-search__mind";
        return (<div id="mind-map-content"
                     className={clsName}>
            {
                this.state.graphData.length === 0 && !this.props.loading ? (<Empty/>) : (
                    <svg id="mindmap" className="mindmap" style={{width: '100%', height: '100%'}}/>
                )
            }
        </div>)
    }
}

export default MindGraph