/*
 * @Author: 符春玲 fuchunling@perfectcode.tech
 * @Date: 2021-09-02 19:32:54
 * @LastEditors: 符春玲 fuchunling@perfectcode.tech
 * @LastEditTime: 2023-03-09 19:47:59
 * @FilePath: \cedb-web\ei-front\src\api\WebSdk.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/**
页面开发流程：
1. 前后端开发协商数据接口名称、参数和返回数据格式；
2. 在/static/mockData/moduleName/xxx.json按商定的数据格式编写静态模拟数据；
3. 在ModuleNameApi.js和ModuleNameApiMock.js都编写商定的接口名和参数；
4. ModuleNameApi.js的接口 return await this.TODO()；
5. 前端开发通过ModuleNameApiMock.js的返回数据完成页面数据展示和交互逻辑；
6. 后台完成接口开发后，到ModuleNameApi.js完成对接；
7. 项目正式上线时，去除对ModuleNameApiMock.js的引用；
*/
import ApiGate from './ApiGate'

// import SearchApi from './SearchApi'
import SearchApi from './SearchApiMock' // For Dev
// import {SearchApiMock as SearchApi} from './SearchApiMock' // For Dev, only use mockData

// import ResearchLibraryApi from './ResearchLibraryApi'
import ResearchLibraryApi from './ResearchLibraryMock' // For Dev
// import {ResearchLibraryMock as ResearchLibraryApi} from './ResearchLibraryMock' // For Dev, only use mockData

// import ReadListApi from './ReadListApi'
import ReadListApi from './ReadListMock' // For Dev

import SearchResultApi from './SearchResultApiMock'
import SearchGraphResultApi from './SearchGraphResultApi' // For Dev

// import EntersInfoApi from "./EntersInfoApi"; // For Dev
import EntersInfoApi from './EntersInfoApiMock' // For mock

import SmartSearchApi from './SmartSearchApi'
import FinanceLibraryApi from './FinanceLibraryApi'
import BusinessDatabaseApi from './BusinessDatabaseApi'

import FinanceSubLibraryApi from './FinanceSubLibraryApi'
import SubjectGraph from './SubjectGraph'
import PolicyApi from './PolicyApi'
import ApiCenter from './ApiCenter'

class WebSdk {
  constructor() {
    this.gate = new ApiGate()
    this.search = new SearchApi()
    this.relibrary = new ResearchLibraryApi()
    this.readList = new ReadListApi()
    this.searchResultApi = new SearchResultApi()
    this.searchGraphResultApi = new SearchGraphResultApi()
    this.entersInforApi = new EntersInfoApi()
    this.smartSearchApi = new SmartSearchApi()
    this.financeLibraryApi = new FinanceLibraryApi()
    this.financeSubLibraryApi = new FinanceSubLibraryApi()
    this.BusinessDatabaseApi = new BusinessDatabaseApi()
    this.subjectGraph = new SubjectGraph()
    this.policyApi = new PolicyApi()
    this.apiCenter = new ApiCenter()
  }
}

export default WebSdk
export const webSdk = new WebSdk()

global.webSdk = webSdk
