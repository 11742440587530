import { observable, action } from 'mobx'
import _ from 'lodash'

class SmartSearchStore {
    //问题数据
    @observable questionList = [
        // {
        //     id: 12,
        //     text: "问题"
        // }
    ];

    /** 从Storage中，获取问题数据 */
    @action getQuestion4Stor = () => {
        if (sessionStorage.getItem("cedbSmartQuestionArray")) {
            this.questionList = JSON.parse(sessionStorage.getItem("cedbSmartQuestionArray"))
        } else {
            this.setQuestion2Stor();
        }
    }

    /** 将问题数据放入Storage中 */
    @action setQuestion2Stor = () => {
        if (_.isEmpty(this.questionList)) {
            sessionStorage.setItem("cedbSmartQuestionArray", "");
        } else {
            sessionStorage.setItem("cedbSmartQuestionArray", JSON.stringify(this.questionList));
        }
    }

    /** 获取问题数据 */
    @action getQuestionList() {
        this.getQuestion4Stor()
        return this.questionList
    }

    /** 设置问题数据 */
    @action setQuestionList(array) {
        this.questionList = array
        this.setQuestion2Stor()
    }
}

export default SmartSearchStore