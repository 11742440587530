import React, { Component } from 'react';
import AppContext from '../../../../AppContext'
import { Spin, Empty, } from 'antd';
import stores from "../../../../store";
import { observer } from 'mobx-react';

class FinanceDetailArticle extends Component {
    static contextType = AppContext

    constructor(props) {
        super(props);
        this.state = {
            articleId: props.articleId,
            loading: false,
            content: "",
        }
    }

    componentWillReceiveProps(nextState) {
        let { articleId } = this.state
        let newArticleId = nextState.articleId
        if (articleId !== newArticleId) {
            this.setState({
                articleId: newArticleId
            }, () => {
                this.getContent();
            })
        }
    }

    // 获取内容
    getContent() {
        let { articleId } = this.state
        if (articleId == null || articleId == undefined) return;

        this.setState({
            loading: true
        }, () => {
            webSdk.financeLibraryApi.getArticleContent(articleId).then(res => {

                let content = res['art_content'];

                this.setState({
                    loading: false,
                    content,
                })

            }).catch(e => {
                this.setState({
                    loading: false
                })
            });
        })
    };

    render() {
        let { loading, content } = this.state

        return (
            <div className="finance-detail-html">
                {
                    content ? (
                        <Spin spinning={loading}>
                            <div className="detail-html" dangerouslySetInnerHTML={{ __html: content }} />
                        </Spin>
                    ) : (<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />)
                }

            </div>
        );
    }
}

export default FinanceDetailArticle;