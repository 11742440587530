import React, {useState, useEffect} from 'react'
import HomeLeft from "./HomeLeft";
import HomeCenter from "./HomeCenter";
import HomeRight from "./HomeRight";
import './Home.scss'
import HomeExcel from "./HomeExcel";
import ViewArticleModal from "../../common/ViewArticleModal/ViewArticleModal";
import stores from "../../../store";
import {observer} from 'mobx-react'

function Home(props) {
    const {homeLeftStore, homeCenterStore, homeRightStore, homeExcelStore, homeCommonStore} = stores;
    useEffect(() => {
        let body = document.getElementsByTagName('body')[0];
        body.style.overflowY = 'hidden';
        body.style.height = '100vh';

        return () => {
            homeCommonStore.unmountAction();
            homeLeftStore.unmountAction();
            homeCenterStore.unmountAction();
            homeRightStore.unmountAction();
            homeExcelStore.unmountAction();
            body.style.overflowY = 'auto';
            body.style.height = 'auto';
        }
    }, [])
    return (
        <div className="home-page__new-home">
            <h2 className='new-home-title'>智能问答</h2>
            <div className="new-home-content">
                <HomeLeft/>
                <HomeCenter/>
                <HomeRight/>
                <HomeExcel/>
            </div>
            <ViewArticleModal articleModal={homeCenterStore.articleModal} setArticleModal={homeCenterStore.setArticleModal}
                              articleModalTitle={homeCenterStore.articleModalTitle} articleModalUrl={homeCenterStore.articleModalUrl} positionData={homeCenterStore.positionData}/>
        </div>
    )
}

export default observer(Home)
