import BaseWebApi from './BaseWebApi'

class PolicyApi extends BaseWebApi {
    constructor(options) {
        super()
        this._apiPolicy = this.gate.policySearch
    }

    /**获取文章列表
     * 
     * @param {int} pageNum 页码
     * @param {int} pageSize 每页数量
     * @param {string} pageSize 关键词
     * @param {array[string]} pageSize 文章发布时间
     * @returns 
     */

    // 政策法规数据获取
    async advPolicesSearch(opts) {
        let resp = await this._apiPolicy.get('list',{
            params:{
                ...opts
            }
        });
        return resp.data
    }


    /**获取文章内容
     * 
     * @param {int} id 文章id
     * @returns 
     */
    async getArticleContent(id) {

        let resp = await this._apiPolicy.get('show', {
            params:{
                _id:id
            }
        })
        return resp.data
    }

    /**获取知识点
     * 
     * @param {int} id 文章id
     * @returns 
     */
    async getArticleKnowledge(id) {
        let params = {
            _id:id
        }

        // let resp = await this._apiPolicy.get('show', params)
        // return resp.data
    }
}

export default PolicyApi
