import React from 'react'
import _ from 'lodash'
import { Icon } from 'antd'
import "./LeftFnCollect.scss"
import ResultCard from "./ResultCard";
import AppContext from "../../../../../../AppContext"

class LeftFnCollect extends React.PureComponent {
    static contextType = AppContext

    constructor(props) {
        super(props)

        this.state = {
            keyword: this.props.keyword,
            dataServer: this.props.collectData || [],//原始数据
            displayKey: this.props.displayKey || "",//数据来源：value 、subject
            setItems: [//重组后的数据
                // {
                //     title: 'DCF',
                //     titleSubject: 'DCF',
                //     titlePredicate: '标签1',
                //     titleObject: '估值模型1',
                //     show: false,
                //     progressPercent: 92,
                //     dataListLen: 8,
                //     list: [
                //         {
                //             title: '科创板看市梦率，什么是估值？I 界面新闻',
                //             time: "2019年8月8日",
                //             source: {
                //                 type: 1,//来源类型：1 PDF；2 网页
                //                 pdfId: 106576,
                //                 lightId: 2513951,
                //                 webSite: "www.jiemian.com"
                //             }
                //         },
                //     ]
                // }
            ],
        }

    }
    componentDidMount() {
        this.dealData()
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            keyword: nextProps.keyword,
            dataServer: nextProps.dataServer || [],
            displayKey: nextProps.displayKey || "",
        }, () => {
            this.dealData()
        })
    }

    componentWillUnmount() {
        // 卸载异步操作设置状态
        this.setState = (state, callback) => {
            return;
        }
    }

    /**处理数据 */
    dealData() {
        const _this = this
        let dataServer = _this.state.dataServer
        let displayKey = _this.state.displayKey
        let setItems = []

        _.forEach(dataServer, item => {

            let dataItem = {
                id: item.value_id,
                title: !_.isEmpty(item[displayKey]) ? item[displayKey] : item.value,
                titleSubject: item.subject,
                titlePredicate: item.predicate,
                titleObject: item.value,
                titleTime: item.time,
                show: false,
                progressPercent: _.round(item.score),
                list: []
            }

            let itemList = item.art_list
            let list = []
            _.forEach(itemList, cellItem => {
                list.push({
                    title: cellItem.title,
                    time: cellItem.art_date,
                    source: {
                        type: cellItem.art_type,//来源类型：pdf；news 网页
                        pdfId: cellItem.art_id,
                        webSite: cellItem.art_url,
                        lightId: cellItem.kp_list ? _.join(cellItem.kp_list, "-") : "",
                    }
                })
            })
            dataItem.list = list

            setItems.push(dataItem)
        })

        _this.setState({
            setItems
        }, () => {
            //画连线
            _this.reloadLinkAll();
        })
    }

    itemClick = item => {
        let setItems = [...this.state.setItems];
        setItems.forEach(it => {
            if (it.title !== item.title) {
                it.show = false;
            }
        })
        item.show = true;
        this.setState({
            setItems
        })
    }

    closeItem = item => {
        let setItems = [...this.state.setItems];
        item.show = false;
        this.setState({
            setItems
        })
    }

    /**卡片搜索 */
    cardSearch(keyword) {
        this.context.getHistory().push({
            pathname: encodeURI(`/resultList/${keyword}/list`),
        })
    }

    /**重新加载所有连线 */
    reloadLinkAll() {
        this.props.fnReloadLinkAll()
    }

    render() {

        return (
            <div className="leftset-collect">
                <div className="title">
                    <p className="title-legend"><span className="list-tips">仅显示较可信的部分结果，并非完整集合，点击可显示详情。</span></p>
                </div>
                <div id="collectList" className="set-list">

                    <div id="setListMainCollect" className="set-list-main">
                        <ul className="list">
                            {
                                this.state.setItems.map((item, index) => {
                                    let className = item.show ? "item item-active" : "item";
                                    let color = item.progressPercent >= 90 ? "#CCEBA4" : item.progressPercent >= 50 ? "#FFDEA6" : "#FFB3A6";
                                    return (
                                        <li key={index}
                                            className={className}
                                            style={!item.show ? { background: color, borderRadius: 4 } : { width: '100%' }}
                                            id={`collect_${item.id}`}
                                        >
                                            <div className={item.show ? "item-title hidden" : "item-title"} onClick={() => this.itemClick(item)}>{item.title}</div>
                                            <div className={item.show ? "item-info" : "item-info hidden"}>
                                                {/*列表样式*/}
                                                <ResultCard
                                                    keyword={this.state.keyword}
                                                    cardTitle={item}
                                                    dataList={item.list}
                                                    fnCloseCard={() => this.closeItem(item)}
                                                    fnDeepSearch={this.cardSearch.bind(this)}
                                                />
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default LeftFnCollect