import React, { PureComponent } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Input } from 'antd';
import "./index.scss"
import AppContext from '../../../../AppContext'
import EnterHistory from './EnterHistory'
import SupplyChain from './SupplyChain'
import Framework from './Framework'
import BusinessModel from './BusinessModel'

class EnterDetail extends PureComponent {
    static contextType = AppContext

    constructor(props) {
        super(props);
        this.state = {
            toTimeLineFirst: true,
            enterinfoParamsStorKey: "enterinfoPageParams",//页面参数在Storage中的key
            pageType: "",//页面类型
            pageRequestParams: {//后端所需的每个页面请求参数
                // pageType: "",
                // fnType: "",
                // paramData: "",
            },
            currentYear: "",//当前年份
            tickerSymbol: "",//股票代码
            companyName: "",//当前公司名称
            leftNavList: [
                {
                    name: "企业历史",
                    key: "enterHistory",
                },
                {
                    name: "供应链",
                    key: "supplyChain",
                },
                {
                    name: "组织架构",
                    key: "framework",
                },
                {
                    name: "商业模式及战略",
                    key: "businessModel",
                },
            ],
            rightTimeLine: {//右侧时间轴
                // current: this.props.location.state.currentYear,//已选年份
                currentProfix: 0,
                currList: [//当前年份列表，10个为一组
                    // {
                    //     key: 201,//年份前三位
                    //     list: []
                    // }
                ],
            },
        }
    }

    componentDidMount() {
        this.init()
        this.keyBoardNone()
        window.onresize = () => this.windowResize()
    }

    componentWillReceiveProps(nextProps) {
        this.init()
    }

    componentWillUnmount() {
        window.onresize = null
        this.setState = () => {
            return;
        }
    }

    /** 在localStorage中保存值
    * @param {string} key 保存的键名
    * @param {object} obj 保存的对象
    */
    setLocStorage(key, obj) {
        let objJSON = ""
        if (!_.isEmpty(obj))
            objJSON = JSON.stringify(obj)
        window.localStorage.setItem(key, objJSON)
    }

    /** 从localStorage中获取值
     * @param {string} key 键名
     */
    getLocStorage(key) {
        let objStr = window.localStorage.getItem(key)
        if (!objStr) return null;
        return JSON.parse(objStr)
    }

    /**修改localStorage中的pageType或currentYear参数 */
    changeStorByObj({ pageType, currentYear }) {
        let enterinfoParamsStorKey = this.state.enterinfoParamsStorKey
        let enterinfoParams = this.getLocStorage(enterinfoParamsStorKey)
        enterinfoParams.pageType = pageType || enterinfoParams.pageType
        enterinfoParams.currentYear = currentYear || enterinfoParams.currentYear
        this.setLocStorage(enterinfoParamsStorKey, enterinfoParams)
    }

    /**初始化页面 */
    init() {
        let enterinfoParamsStorKey = this.state.enterinfoParamsStorKey
        let pageRequestParams = {}
        let pageParams = this.getLocStorage(enterinfoParamsStorKey)

        if (_.isEmpty(pageParams)) {
            this.returnEnterListPage()
            return;
        }

        let pageType = pageParams.pageType,
            currentYear = pageParams.currentYear,
            tickerSymbol = pageParams.tickerSymbol,
            companyName = pageParams.companyName

        if (this.props.location.state) {
            let fnType = this.props.location.state.fnType
            let paramData = this.props.location.state.paramData
            if (fnType) {
                pageRequestParams.pageType = pageType
                pageRequestParams.fnType = fnType
                pageRequestParams.paramData = paramData
            }
        }

        this.setState({
            pageType, currentYear, tickerSymbol, pageRequestParams, companyName
        }, () => {
            this.getTimeLine()
        })
    }

    /**检测窗口大小变动 */
    windowResize() {
        let centerdetailRight = document.getElementById("centerdetailRight")
        let timelineContent = document.getElementById("timelineContent")
        let timelineContainer = document.getElementById("timelineContainer")
        let ulList = timelineContainer.querySelectorAll("ul")

        if (centerdetailRight) {
            //可见容器的宽度
            let timelineContentWidth = timelineContent.offsetWidth

            //设置每段时间线的宽度，并找出当前展示的时间线
            let currentUl = null
            _.forEach(ulList, item => {
                item.style.width = `${timelineContentWidth}px`
                let itemClassName = item.className
                if (itemClassName && itemClassName.indexOf("event-open") > -1) {
                    currentUl = item
                }
            })

            //找到当前展示时间线的left值
            if (currentUl) {
                let currentUlLeft = currentUl.offsetLeft
                timelineContent.scrollLeft = currentUlLeft
            }
        }
    }

    /**表格不接受键盘事件 */
    keyBoardNone() {
        let timelineContent = document.getElementById("timelineContent")
        timelineContent.onmousewheel = (event) => {
            let shiftKey = event.shiftKey
            if (shiftKey) {
                return false;
            }
        }
    }

    /**根据当前年份，获取3组10个年份 */
    getTimeLine() {
        let currentYear = this.state.currentYear
        let rightTimeLine = { ...this.state.rightTimeLine }
        let currentProfix = 0
        let list = []
        if (!currentYear) {
            currentYear = moment().format("YYYY")
        }

        //分别获取年份的前3位数字和最后1位数字
        let profix = Number(`${currentYear}`.substring(0, 3))
        let suffix = Number(`${currentYear}`.substring(3, 4))

        //若最后1位数字为0，则前3位数需要减1
        //若最后1位数字不为0，则可直接使用前3位数
        let listProfix = profix
        if (suffix === 0) {
            --listProfix;
        }
        currentProfix = listProfix
        rightTimeLine.currentProfix = currentProfix

        //创建时间列表
        listProfix -= 2
        for (let j = -1; j <= 1; j++) {
            ++listProfix;
            let itemList = []
            for (let i = 1; i < 10; i++) {
                itemList.push(Number(`${listProfix}${i}`))
            }
            itemList.push(Number(`${listProfix + 1}0`))
            list.push({
                key: listProfix,
                list: itemList
            })
        }

        rightTimeLine.currList = list
        this.setState({
            rightTimeLine,
            currentYear
        }, () => {
            this.windowResize()
            this.toTimeLine(currentProfix)
        })
    }

    /**跳转到指定时间线 */
    toTimeLine(profix) {
        let timelineContent = document.getElementById("timelineContent")
        let timelineContainer = document.getElementById("timelineContainer")
        let currentUl = timelineContainer.querySelector(`ul[data-key='${profix}']`)
        let currentUlLeft = currentUl.offsetLeft
        //滚动条到当前时间轴的距离
        let distance = timelineContent.scrollLeft - currentUlLeft
        let rate = Math.abs(distance) / 50
        let toTimeLineFirst = this.state.toTimeLineFirst

        if (!toTimeLineFirst) {
            let interval = setInterval(() => {

                if (distance < 0) {
                    timelineContent.scrollLeft += rate
                    if (timelineContent.scrollLeft >= currentUlLeft) {
                        timelineContent.scrollLeft = currentUlLeft
                        clearInterval(interval)
                    }
                } else {
                    timelineContent.scrollLeft -= rate
                    if (timelineContent.scrollLeft <= currentUlLeft) {
                        timelineContent.scrollLeft = currentUlLeft
                        clearInterval(interval)
                    }
                }

            }, 20)
        } else {
            timelineContent.scrollLeft = currentUlLeft
            this.setState({
                toTimeLineFirst: false
            })
        }

        //移出事件阻止样式，并对当前时间线添加事件样式
        --profix;
        for (let i = 0; i < 3; i++) {
            let item = timelineContainer.querySelector(`ul[data-key='${profix}']`)
            if (item) {
                let itemClass = item.className
                item.className = itemClass.replace(" event-open", "")
            }
            ++profix;
        }
        currentUl.className += " event-open"

    }

    /**切换导航 */
    changeNav(nav) {
        this.changeStorByObj({ pageType: nav })

        this.context.getHistory().push({
            pathname: "/enterdetail",
        })
    }

    /**修改时间 */
    changeTime(time, currentProfix) {
        let rightTimeLine = { ...this.state.rightTimeLine }
        let currentYear = time
        rightTimeLine.currentProfix = currentProfix

        //修改localStorage中的参数
        this.changeStorByObj({ currentYear })

        this.setState({
            currentYear,
            rightTimeLine
        })
    }

    /**切换时间轴 */
    changeTimeList(dirc) {
        let rightTimeLine = { ...this.state.rightTimeLine }
        let currList = rightTimeLine.currList
        let originProfix = rightTimeLine.currentProfix
        let currentProfix = originProfix

        //移动时间轴
        switch (dirc) {
            case "left":
                --currentProfix;

                //向时间轴数据最前方插入新的时间轴
                let beforeProfix = currentProfix - 1
                let beforeItem = _.find(currList, { key: beforeProfix })
                if (!beforeItem) {
                    let itemList = []
                    for (let i = 1; i < 10; i++) {
                        itemList.push(Number(`${beforeProfix}${i}`))
                    }
                    itemList.push(Number(`${beforeProfix + 1}0`))
                    let list = [{
                        key: beforeProfix,
                        list: itemList
                    }]

                    rightTimeLine.currList = _.concat(list, currList)
                }

                break;
            case "right":
                ++currentProfix;
                let nextProfix = currentProfix + 1
                let nextItem = _.find(currList, { key: nextProfix })
                if (!nextItem) {
                    let itemList = []
                    for (let i = 1; i < 10; i++) {
                        itemList.push(Number(`${nextProfix}${i}`))
                    }
                    itemList.push(Number(`${nextProfix + 1}0`))
                    let list = [{
                        key: nextProfix,
                        list: itemList
                    }]

                    rightTimeLine.currList = _.concat(currList, list)
                }
                break;
        }

        rightTimeLine.currentProfix = currentProfix

        this.setState({
            rightTimeLine,
            toTimeLineFirst: true,
        }, () => {
            this.windowResize()
            this.toTimeLine(originProfix)
            this.setState({
                toTimeLineFirst: false,
            }, () => {
                this.toTimeLine(currentProfix)
            })
        })
    }

    /**页面内搜索 */
    pageSearch(val) {
        if (!val) return;

        this.context.getHistory().push({
            pathname: `/entersearch/${val}`,
        })
    }

    /**返回到企业列表页面 */
    returnEnterListPage() {
        this.context.getHistory().push({
            pathname: `/entersinfo`
        })
    }

    /**修改滚动条位置 */
    changeScroll(topVal) {
        let enterdetail = document.getElementById("enterdetail")
        if (enterdetail) {
            enterdetail.scrollTop = topVal - 70
        }
    }

    render() {
        return (
            <div id="enterdetail" className="enterdetail">
                <div className="enterdetail-left">
                    <div className="enterdetail-left-nav">
                        <ul>
                            {
                                this.state.leftNavList.map((item, idx) => (
                                    <li
                                        key={idx}
                                        className={this.state.pageType === item.key ? "active" : ""}
                                        onClick={this.changeNav.bind(this, item.key)}
                                    >{item.name}</li>
                                ))
                            }
                        </ul>
                    </div>
                    <div className="enterdetail-left-return">
                        <div
                            className="enterdetail-left-return__btn"
                            onClick={this.returnEnterListPage.bind(this)}
                        >
                            <span className="font_family icon icon-returnx"></span>
                            返回
                        </div>
                    </div>
                    <div className="enterdetail-left-search">
                        <Input.Search
                            placeholder="请输入关键词"
                            onSearch={this.pageSearch.bind(this)}
                            allowClear={true}
                        />
                    </div>
                </div>

                <div id="centerdetailRight" className="enterdetail-right">
                    <div className="detailright-timeline">
                        <span
                            className="timeline-point left iconfont_mei iconjiantou"
                            onClick={this.changeTimeList.bind(this, "left")}
                        ></span>
                        <span
                            className="timeline-point right iconfont_mei iconarrow-right"
                            onClick={this.changeTimeList.bind(this, "right")}
                        ></span>
                        <div id="timelineContent" className="detailright-timeline-content">
                            <div id="timelineContainer" className="detailright-timeline-container">
                                {
                                    this.state.rightTimeLine.currList.map((item, idx) => {
                                        return (
                                            <ul
                                                key={idx}
                                                data-key={item.key}
                                            >
                                                {
                                                    item.list.map((timeItem, timeIdx) => {
                                                        let active = this.state.currentYear == timeItem ? "active" : ""
                                                        return (
                                                            <li
                                                                key={timeIdx}
                                                                className={`${active}`}
                                                                onClick={this.changeTime.bind(this, timeItem, item.key)}
                                                            >{timeItem}年</li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>

                    <div className="detailright-content">
                        {this.state.pageType === "enterHistory" ? (
                            <EnterHistory
                                pageType={this.state.pageType}
                                pageRequestParams={this.state.pageRequestParams}
                                tickerSymbol={this.state.tickerSymbol}
                                currentYear={this.state.currentYear}
                                companyName={this.state.companyName}
                                fnChangeScroll={this.changeScroll.bind(this)}
                            />
                        ) : null}
                        {this.state.pageType === "supplyChain" ? (
                            <SupplyChain
                                pageType={this.state.pageType}
                                pageRequestParams={this.state.pageRequestParams}
                                tickerSymbol={this.state.tickerSymbol}
                                currentYear={this.state.currentYear}
                                companyName={this.state.companyName}
                                fnChangeScroll={this.changeScroll.bind(this)}
                            />
                        ) : null}
                        {this.state.pageType === "framework" ? (
                            <Framework
                                pageType={this.state.pageType}
                                pageRequestParams={this.state.pageRequestParams}
                                tickerSymbol={this.state.tickerSymbol}
                                currentYear={this.state.currentYear}
                                companyName={this.state.companyName}
                                fnChangeScroll={this.changeScroll.bind(this)}
                            />
                        ) : null}
                        {this.state.pageType === "businessModel" ? (
                            <BusinessModel
                                pageType={this.state.pageType}
                                pageRequestParams={this.state.pageRequestParams}
                                tickerSymbol={this.state.tickerSymbol}
                                currentYear={this.state.currentYear}
                                companyName={this.state.companyName}
                                fnChangeScroll={this.changeScroll.bind(this)}
                            />
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

export default EnterDetail;