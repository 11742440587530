import React, {useState} from 'react'
import {Icon, message} from 'antd'
import ScrollBar from "../../common/ScrollBar";
import OpenIcon from "./OpenIcon";
import stores from "../../../store";
import {observer} from 'mobx-react'
import {
    getQuestionType,
    saveQuestionData,
    reloadQuestionData,
    questionAndCorpusAna,
    getCorpusMatch,
    getAbstractAna,
    queryExcelData
} from './utils'


function HomeLeft(props) {
    const [newQuestion, setNewQuestion] = useState("");     // 新问题输入框文字

    const {homeLeftStore, homeCenterStore, homeRightStore, homeExcelStore, homeCommonStore} = stores;

    const {
        leftOpen,
        questionList,
        setQuestionList,
        crtQuestionIndex,
        setCrtQuestionIndex
    } = homeLeftStore;

    // 问题编辑按钮点击
    const editQuestionStatus = (e, index) => {
        let newQList = [...questionList];
        newQList[index].edit = true;
        setQuestionList(newQList);
        // 阻止合成事件的冒泡
        e.stopPropagation();
        // 阻止与原生事件的冒泡
        e.nativeEvent.stopImmediatePropagation();
    };
    // 问题编辑更改文字
    const editQuestion = (ev, index) => {
        let newQList = [...questionList];
        newQList[index].text = ev.target.value;
        setQuestionList(newQList);
    };
    // 最下问题输入框更改
    const newQuestionChange = ev => {
        let value = ev.target.value;
        setNewQuestion(value);
    };
    // 问题输入框按下回车键
    const textAreaEnter = (ev, index) => {
        let keyCode = ev.keyCode;
        let newQList = [...questionList];
        if (keyCode === 13) {
            ev.returnValue = false;
            let newV = '';
            if (index === undefined) {
                newV = newQuestion;
            } else {
                newV = newQList[index].text;
            }
            let values = newV.split("\n");
            // newV = newV.replace(/\n/g,"");
            let newItems = [];      // 输入框输入的问题 按换行符进行分割问题
            for (let i = 0; i < values.length; i++) {
                if (values[i] === "") continue;
                newItems.push({
                    'text': values[i],
                    'edit': false
                })
            }
            if (index === undefined) {
                // 添加
                if (newItems.length > 0) {
                    if (newQList.length === 0) {
                        setQuestionList(newItems);
                        editQuestionReload(newItems[0], 0, newItems)
                    } else {
                        newQList = newQList.concat(newItems);
                        setQuestionList(newQList);
                    }
                } else {
                    // 提示不能为空
                    message.error('问题不能为空')
                }
            } else {
                // 修改
                if (newItems.length > 0) {
                    newItems.unshift(index, 1);
                    Array.prototype.splice.apply(newQList, newItems);
                    if (index === crtQuestionIndex) {       // 当前修改项为当前激活的问题
                        // 当前点击项做修改了---清空中间和右侧数据 --- 重新请求语料和问题解析
                        editQuestionReload(newQList[index], index, newQList)
                    } else if (index < crtQuestionIndex) {      // 当前修改项为激活问题的前面问题 -- 更改激活问题的index
                        setCrtQuestionIndex(crtQuestionIndex + newItems.length - 1);
                        setQuestionList(newQList);
                    }
                } else {
                    // 删除
                    newQList.splice(index, 1);
                    // 判断中、左是否为当前项
                    if (crtQuestionIndex === index) {
                        editQuestionReload(newQList[index], index, newQList);
                    }
                }
            }
            setNewQuestion('');
        }
    };
    // 问题输入框按下回车键
    const textAreaKeyUp = ev => {
        if (ev.keyCode === 13) setNewQuestion('');
    };

    // 问题点击
    const questionClick = (item, index) => {
        homeRightStore.setAttributeModal(false);
        homeCenterStore.setCenterOpen(true);
        homeRightStore.setRightOpen(true);
        if (index === crtQuestionIndex) {
            if (homeCommonStore.quesClsFalse) {
                queryType(item.text, index);
                return;
            }
            if (homeCommonStore.questionType === 0) {
                homeCommonStore.setRightStatus(0);
                if (homeRightStore.questionAnaFalse) questionAndCorpusAna('query', item.text);
                // if (homeCenterStore.corpusMatchFalse) getCorpusMatch();
                // if (homeRightStore.corpusAnaFalse) questionAndCorpusAna('sent', homeCenterStore.crtCorpusId);
                // if (homeRightStore.abstractAnaFalse) getAbstractAna();
            } else if (homeCommonStore.questionType === 1) {
                if (homeExcelStore.excelFalse) queryExcelData(item);
            }
            return;
        }
        if (crtQuestionIndex !== null) saveQuestionData(questionList[crtQuestionIndex].text);    // 保存点击前数据
        let hasIn = reloadQuestionData(item.text, index);        // 载入现有匹配数据
        if (hasIn) return;
        homeCommonStore.setRightStatus(0);
        setCrtQuestionIndex(index);
        homeCenterStore.setCrtCorpusId(null);
        queryType(item, index);
    };
    // 请求问题类型  ---  再根据问题类型请求响应结果
    const queryType = (item, index, list) => {
        getQuestionType(item.text).then(type => {
            let newQuestionList = list || [...questionList];
            newQuestionList[index].type = type;
            setQuestionList(newQuestionList);
            item.type = type;
            if (type === 'spo') {
                homeCommonStore.setQuestionType(0);
                homeExcelStore.setExcelOpen(false);
                homeCenterStore.setPageData(1, 0);   // 重置语料页码
                // 获取中间语料匹配、右侧问题解析
                questionAndCorpusAna('query', item.text)
            } else if (type === 'slot' || type === 'st') {
                queryExcelData(item);
            }
        }).catch(e => {
            if (list) setQuestionList(list);
        });
    }
    // 改变当前问题内容，重新请求
    const editQuestionReload = (item, index, list) => {
        homeRightStore.setAttributeModal(false);
        homeCenterStore.setCenterOpen(true);
        homeRightStore.setRightOpen(true);
        homeCommonStore.setRightStatus(0);
        setQuestionList(list);
        setCrtQuestionIndex(index);
        queryType(item, index, list);
    };

    const QuestionCards = () => {
        if (!questionList || questionList.length === 0) return [];
        return questionList.map((item, index) => {
            return (
                <li key={index}
                    title="点击可查看该问题匹配语料及问题解析"
                    className="home-left-question-li"
                    style={item.edit ? {color: "rgba(0, 0, 0, 0.8)"} : crtQuestionIndex === index ? {
                        color: "#5B6BAE",
                        cursor: "pointer"
                    } : {cursor: "pointer"}}
                    onClick={() => {
                        return item.edit ? null : questionClick(item, index)
                    }}>
                    {
                        item.edit ? (
                            <textarea className="question-area" placeholder='请输入问题'
                                      value={item.text} onChange={ev => editQuestion(ev, index)}
                                      onKeyDown={ev => textAreaEnter(ev, index)}/>
                        ) : (
                            <>
                                <h3 className="question-li-title">问题{index + 1}</h3>
                                <Icon className="question-li-edit" type="edit"
                                      onClick={e => editQuestionStatus(e, index)}/>
                                <p className="question-text">{item.text}</p>
                            </>
                        )
                    }
                </li>
            )
        })
    };

    return (
        <div className="new-home-left" style={leftOpen ? {flex: 1} : {width: 44, flexShrink: 0}}>
            <div className="home-content">
                <OpenIcon open={leftOpen} title='问题提纲' type='left'/>
                <ScrollBar>
                    <div className="home-main-content" style={leftOpen ? {} : {display: 'none'}}>
                        <ul className="home-left-question-ul">
                            {QuestionCards()}
                            <li key='add-area' className="home-left-question-li">
                            <textarea className="question-area" placeholder='请输入问题' value={newQuestion}
                                      onChange={newQuestionChange} onKeyDown={textAreaEnter} onKeyUp={textAreaKeyUp}/>
                            </li>
                        </ul>
                    </div>
                </ScrollBar>

            </div>
        </div>
    )
}

export default observer(HomeLeft)
