import React, { PureComponent } from 'react'
import _ from 'lodash'
import { Empty, Spin } from 'antd';
import "./BusinessModel.scss"
import { webSdk } from '../../../../api/WebSdk';

class BusinessModel extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            currentYear: this.props.currentYear,//当前年份
            tickerSymbol: this.props.tickerSymbol,//股票代码
            pageRequestParams: this.props.pageRequestParams,//后端请求所需值
            spinLoading: true,//表格加载状态
            rebuild: {//收购重组
                columns: [
                    {
                        title: '交易标的简称',
                        key: 'transTargetHash',
                    },
                    {
                        title: '交易标的名称',
                        key: 'transTargetName',
                    },
                    {
                        title: 'PB',
                        key: 'pb',
                    },
                    {
                        title: 'PE',
                        key: 'pe',
                    },
                    {
                        title: '标的所属行业',
                        key: 'targetIndustry',
                    },
                    {
                        title: '标的企业性质',
                        key: 'targetProp',
                    },
                    {
                        title: '买方',
                        key: 'buyer',
                    },
                    {
                        title: '买方代码',
                        key: 'buyerCode',
                    },
                    {
                        title: '卖方',
                        key: 'seller',
                    },
                    {
                        title: '卖方代码',
                        key: 'sellerCode',
                    },
                    {
                        title: '交易价值',
                        key: 'exchangeValue',
                    },
                    {
                        title: '现金支付金',
                        key: 'cashSum',
                    },
                    {
                        title: '股权转让比例',
                        key: 'stockTransRate',
                    },

                ],
                data: [
                    // {
                    // transTargetHash: "asdf",
                    // transTargetName: "asdf",
                    // pb: "asdf",
                    // pe: "asdf",
                    // targetIndustry: "asdf",
                    // targetProp: "asdf",
                    // buyer: "asdf",
                    // buyerCode: "asdf",
                    // seller: "asdf",
                    // sellerCode: "asdf",
                    // exchangeValue: "asdf",
                    // cashSum: "asdf",
                    // stockTransRate: "asdf",
                    // }
                ]
            },
            otherRelative: {//其他关系
                columns: [
                    {
                        title: '公司名称',
                        key: 'companyName',
                    },
                    {
                        title: '关系',
                        key: 'relative',
                    },
                    {
                        title: '标的所属行业',
                        key: 'targetIndustry',
                    },
                    {
                        title: '标的企业性质',
                        key: 'targetProp',
                    },

                ],
                data: [
                    // {
                    // companyName: "asdf",
                    // relative: "asdf",
                    // targetIndustry: "asdf",
                    // targetProp: "asdf",
                    // }
                ]
            },
            brand: {//业务品牌项目
                columns: [
                    // <th>公司y体</th>
                    {
                        title: '公司简称',
                        key: 'companyHash',
                    },
                    {
                        title: '公司名称',
                        key: 'companyName',
                    },
                    {
                        title: '关系',
                        key: 'relative',
                    },
                    {
                        title: '实体',
                        key: 'entity',
                    },

                ],
                data: [
                    // {
                    // companyHash: "asdf",
                    // companyName: "asdf",
                    // relative: "asdf",
                    // entity: "asdf",
                    // }
                ]
            },
        }
    }

    componentDidMount() {
        this.getData()
    }

    componentWillReceiveProps(nextProps) {
        let newCurrentYear = nextProps.currentYear
        let newTickerSymbol = nextProps.tickerSymbol
        let newPageRequestParams = JSON.stringify(nextProps.pageRequestParams)
        let oldPageRequestParams = JSON.stringify(this.state.pageRequestParams)
        if (newCurrentYear != this.state.currentYear || newTickerSymbol !== this.state.tickerSymbol || newPageRequestParams != oldPageRequestParams) {
            this.setState({
                currentYear: newCurrentYear,
                tickerSymbol: newTickerSymbol,
                pageRequestParams: nextProps.pageRequestParams
            }, () => {
                this.getData()
            })
        }
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        }
    }

    /**获取数据 */
    getData() {
        let rebuild = { ...this.state.rebuild }
        let otherRelative = { ...this.state.otherRelative }
        let brand = { ...this.state.brand }
        let pageRequestParams = this.state.pageRequestParams
        let spinLoading = true

        this.setState({
            spinLoading
        }, () => {
            let params = {
                tickerSymbol: this.state.tickerSymbol,
                year: this.state.currentYear
            }
            if (pageRequestParams && pageRequestParams.pageType == "businessModel" && pageRequestParams.fnType) {
                params.table = pageRequestParams.fnType
                params.data = pageRequestParams.paramData
            }
            webSdk.entersInforApi.getBusinessModelInfo(params).then(res => {
                spinLoading = false

                _.forEach(res.rebuild, item => {
                    _.forIn(item, (value, key) => {
                        if (!value) {
                            item[key] = "-"
                        }
                    })
                })
                rebuild.data = res.rebuild

                _.forEach(res.otherRelative, item => {
                    _.forIn(item, (value, key) => {
                        if (!value) {
                            item[key] = "-"
                        }
                    })
                })
                otherRelative.data = res.otherRelative

                _.forEach(res.brand, item => {
                    _.forIn(item, (value, key) => {
                        if (!value) {
                            item[key] = "-"
                        }
                    })
                })
                brand.data = res.brand

                this.setState({
                    spinLoading, rebuild, otherRelative, brand
                }, () => {
                    this.toPagePoint()
                })
            })
        })
    }

    /**到页面指定位置 */
    toPagePoint() {
        let pageRequestParams = this.state.pageRequestParams
        if (!pageRequestParams || pageRequestParams.pageType != "businessModel") return;
        let fnType = pageRequestParams.fnType
        let fnId = ""
        switch (fnType) {
            case "purchase":
                fnId = "rebuildBlock"
                break;
            case "otherRel":
                fnId = "otherRelativeBlock"
                break;
            case "businessBrand":
                fnId = "brandBlock"
                break;
        }

        if (fnId) {
            this.showHightSpot(fnId)
        }
    }

    /**展示高亮块 */
    showHightSpot(id) {
        let pageRequestParams = this.state.pageRequestParams
        if (!pageRequestParams || pageRequestParams.pageType != "businessModel") return;
        let block = document.getElementById(id)
        if (!block) return;
        let topVal = block.offsetTop
        this.props.fnChangeScroll(topVal)

    }

    render() {
        return (
            <div className="bussmodel">
                <div id="rebuildBlock" className="bussmodel-rebuild">
                    <h3>收购重组</h3>
                    <div className="bussmodel-rebuild-tb">
                        <div>

                            <table>
                                <thead>
                                    <tr>
                                        {
                                            this.state.rebuild.columns.map((item, idx) => (
                                                <th
                                                    key={item.key}
                                                    style={{
                                                        minWidth: `${item.minWidth}px`
                                                    }}
                                                >{item.title}</th>
                                            ))
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        _.isEmpty(this.state.rebuild.data) || this.state.spinLoading ? (
                                            <tr>
                                                <td className="empty" colSpan="7">
                                                    <Spin spinning={this.state.spinLoading}>
                                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                    </Spin>
                                                </td>
                                            </tr>
                                        ) : this.state.rebuild.data.map((item, idx) => (
                                            <tr key={idx}>
                                                {
                                                    this.state.rebuild.columns.map(colItem => {
                                                        let key = colItem.key
                                                        let value = item[key]
                                                        return (
                                                            <td key={key}>{value}</td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div id="otherRelativeBlock" className="bussmodel-other">
                    <h3>合作与竞争</h3>
                    <div className="bussmodel-other-tb">
                        <div>
                            <table>
                                <thead>
                                    <tr>
                                        {
                                            this.state.otherRelative.columns.map((item, idx) => (
                                                <th
                                                    key={item.key}
                                                    style={{
                                                        minWidth: `${item.minWidth}px`
                                                    }}
                                                >{item.title}</th>
                                            ))
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        _.isEmpty(this.state.otherRelative.data) || this.state.spinLoading ? (
                                            <tr>
                                                <td className="empty" colSpan="4">
                                                    <Spin spinning={this.state.spinLoading}>
                                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                    </Spin>
                                                </td>
                                            </tr>
                                        ) : this.state.otherRelative.data.map((item, idx) => (
                                            <tr key={idx}>
                                                {
                                                    this.state.otherRelative.columns.map(colItem => {
                                                        let key = colItem.key
                                                        let value = item[key]
                                                        return (
                                                            <td key={key}>{value}</td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div id="brandBlock" className="bussmodel-brand">
                    <h3>业务品牌项目</h3>
                    <div className="bussmodel-brand-tb">
                        <div>
                            <table>
                                <thead>
                                    <tr>
                                        {
                                            this.state.brand.columns.map((item, idx) => (
                                                <th
                                                    key={item.key}
                                                    style={{
                                                        minWidth: `${item.minWidth}px`
                                                    }}
                                                >{item.title}</th>
                                            ))
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        _.isEmpty(this.state.brand.data) || this.state.spinLoading ? (
                                            <tr>
                                                <td className="empty" colSpan="4">
                                                    <Spin spinning={this.state.spinLoading}>
                                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                    </Spin>
                                                </td>
                                            </tr>
                                        ) : this.state.brand.data.map((item, idx) => (
                                            <tr key={idx}>
                                                {
                                                    this.state.brand.columns.map(colItem => {
                                                        let key = colItem.key
                                                        let value = item[key]
                                                        return (
                                                            <td key={key}>{value}</td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BusinessModel;