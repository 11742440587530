import React, { PureComponent } from 'react';
import { Input, Icon, Pagination, Spin, Empty } from 'antd';
import _ from 'lodash'
import AppContext from '../../../../AppContext'
import "./SearchResult.scss"
import { webSdk } from '../../../../api/WebSdk';

class SearchResult extends PureComponent {
    static contextType = AppContext

    constructor(props) {
        super(props);
        this.state = {
            pageKeyword: this.props.match.params.pageKeyword || "",//页面内关键词
            enterinfoParamsStorKey: "enterinfoPageParams",//页面参数在Storage中的key
            searchTotal: 0,//搜索结果总数
            companyName: "",//公司名称
            resData: {},//原始数据
            tableSpining: true,//表格加载
            tableInfo: {
                column: [
                    {
                        title: "关联标签",
                        key: "title",
                        // width: "80"
                    },
                    {
                        title: "关联内容",
                        key: "content",
                        // width: ""
                    },
                ],
                list: [
                    // {
                    //     title: "关联人物1",
                    //     content: {
                    //         subtitle: "<em>张旭</em>",
                    //         excerpt: "在会上,执行副总裁<em>张旭</em>表示,在下半年,万科表示万科没有固定数字计划,但将'每天出售建筑物的好日子'作为基本策略,坚持促销,积极偿还资金在会上,执行副总裁<em>张旭</em>表示,在下半年,万科表示万科没有固定数字计划,但将'每天出售建筑物的好日子'作为基本策略,坚持促销,积极偿还资金"
                    //     }
                    // },
                ]
            },
            pagination: {
                pageSize: 10,
                current: 1,
                total: 0
            }
        }
    }

    componentDidMount() {
        this.init()
    }

    componentWillReceiveProps(nextProps) {
        let newPageKeyword = nextProps.match.params.pageKeyword
        if (newPageKeyword != this.state.pageKeyword) {
            this.setState({
                pageKeyword: newPageKeyword
            }, () => {
                this.init()
            })
        }
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        }
    }

    /** 在localStorage中保存值
    * @param {string} key 保存的键名
    * @param {object} obj 保存的对象
    */
    setLocStorage(key, obj) {
        let objJSON = ""
        if (!_.isEmpty(obj))
            objJSON = JSON.stringify(obj)
        window.localStorage.setItem(key, objJSON)
    }

    /** 从localStorage中获取值
     * @param {string} key 键名
     */
    getLocStorage(key) {
        let objStr = window.localStorage.getItem(key)
        if (!objStr) return null;
        return JSON.parse(objStr)
    }

    /**初始化页面 */
    init() {
        let pagination = { ...this.state.pagination }
        pagination.current = 1
        this.setState({
            pagination
        }, () => {
            this.getData()
        })
    }

    /**获取数据 */
    getData() {
        let pageKeyword = this.state.pageKeyword
        let pagination = this.state.pagination
        let currentPage = pagination.current
        let enterinfoParamsStorKey = this.state.enterinfoParamsStorKey
        let pageParams = this.getLocStorage(enterinfoParamsStorKey)
        let stockcode = pageParams.tickerSymbol
        let resData = {}

        this.setState({
            tableSpining: true
        }, () => {
            let params = {
                stockcode,
                keyword: pageKeyword,
                pageno: currentPage
            }

            webSdk.entersInforApi.getPageSearchData(params).then(res => {

                this.setState({
                    resData: res
                }, () => {
                    this.handleData()
                })
            })
        })
    }

    /**整理数据 */
    handleData() {
        let resData = this.state.resData
        let resList = resData.result
        let companyName = resData.orgname
        let searchTotal = resData.total
        let pagination = { ...this.state.pagination }
        pagination.total = resData.total
        let tableInfo = { ...this.state.tableInfo }

        let tableList = []
        _.forEach(resList, resListItem => {
            tableList.push({
                title: resListItem.label,
                content: {
                    subtitle: resListItem.item,
                    excerpt: resListItem.text,
                },
                pageType: resListItem.page,
                fnType: resListItem.table,
                paramData: resListItem.data,
            })
        })
        tableInfo.list = tableList

        this.setState({
            tableSpining: false,
            companyName, searchTotal, pagination, tableInfo
        })
    }

    /**返回 */
    returnEnterDetailPage() {
        let enterinfoParamsStorKey = this.state.enterinfoParamsStorKey
        let pageParams = this.getLocStorage(enterinfoParamsStorKey)
        let keyword = pageParams.keyword

        this.context.getHistory().push({
            pathname: `/enterdetail${keyword ? `/${keyword}` : ""}`,
        })
    }

    /**搜索页面内关键词 */
    searchPageKeyword({ target: { value } }) {
        if (!value) return;

        this.context.getHistory().push({
            pathname: `/entersearch/${value}`,
        })
    }

    /**改变页码 */
    changePage(nav) {
        let pagination = { ...this.state.pagination }
        pagination.current = nav
        this.setState({
            pagination
        }, () => {
            this.getData()
        })
    }

    /**进入详情页面 */
    toDetailPage(item) {
        let enterinfoParamsStorKey = this.state.enterinfoParamsStorKey
        let pageParams = this.getLocStorage(enterinfoParamsStorKey)
        let keyword = pageParams.keyword
        pageParams.pageType = item.pageType

        this.setLocStorage(enterinfoParamsStorKey, pageParams)

        this.context.getHistory().push({
            pathname: `/enterdetail${keyword ? `/${keyword}` : ""}`,
            state: {
                fnType: item.fnType,
                paramData: item.paramData,
            }
        })
    }

    render() {
        return (
            <div className="enter-search">
                <div className="entersearch-head">
                    <div className="entersearch-head-fn">
                        <div className="entersearch-head-fn-return"
                            onClick={this.returnEnterDetailPage.bind(this)}
                        >
                            <span className="return-icon iconfont iconjiantou"></span>
                            <span className="return-txt">搜索"<span className="light">{this.state.pageKeyword}</span>"共有{this.state.searchTotal}条记录</span>
                        </div>
                        <div className="entersearch-head-fn-input">
                            <Input
                                allowClear={true}
                                placeholder="企业内搜索"
                                prefix={<Icon type="search" />}
                                onPressEnter={this.searchPageKeyword.bind(this)}
                            />
                        </div>
                    </div>
                    <div className="entersearch-head-title">
                        <h3>{this.state.companyName}</h3>
                    </div>
                </div>

                <div className="entersearch-content">
                    <table>
                        <thead>
                            <tr>
                                {
                                    this.state.tableInfo.column.map((colItem, colIdx) => {
                                        return (
                                            <th key={colIdx}
                                                style={{
                                                    width: `${colItem.width}px`
                                                }}
                                            >{colItem.title}</th>
                                        )
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.tableSpining || _.isEmpty(this.state.tableInfo.list) ? (
                                    <tr className="none-active">
                                        <td colSpan="2">
                                            <Spin spinning={this.state.tableSpining}>
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                            </Spin>
                                        </td>
                                    </tr>
                                ) :
                                    this.state.tableInfo.list.map((item, idx) => (
                                        <tr key={idx} onClick={this.toDetailPage.bind(this, item)}>
                                            {
                                                this.state.tableInfo.column.map((colItem, colIdx) => {
                                                    let columnKey = colItem.key
                                                    let itemObj = item[columnKey]
                                                    if (typeof itemObj == null || typeof itemObj == "string") {
                                                        return (
                                                            <td key={colIdx}>
                                                                {itemObj}
                                                            </td>
                                                        )
                                                    } else if (typeof itemObj == "object") {
                                                        return (
                                                            <td key={colIdx}>
                                                                <p
                                                                    className="subtitle"
                                                                    dangerouslySetInnerHTML={{ __html: itemObj.subtitle }}
                                                                ></p>
                                                                <p
                                                                    className="content"
                                                                    dangerouslySetInnerHTML={{ __html: itemObj.excerpt }}
                                                                ></p>
                                                            </td>
                                                        )
                                                    }
                                                })
                                            }
                                        </tr>
                                    ))
                            }
                        </tbody>
                    </table>
                </div>
                <div className="entersearch-pagination">
                    <Pagination
                        showQuickJumper={true}
                        pageSize={this.state.pagination.pageSize}
                        total={this.state.pagination.total}
                        current={this.state.pagination.current}
                        onChange={this.changePage.bind(this)}
                    />
                </div>
            </div>
        );
    }
}

export default SearchResult;