import React, { PureComponent, Fragment } from 'react';
import _ from 'lodash'
import { Descriptions, Button } from 'antd'
import "./DetailHead.scss"

class DetailHead extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            pageType: props.pageType,
            titleList: [
                {
                    key: "edit",
                    title: "编辑教纲"
                },
                {
                    key: "display",
                    title: "查看教纲"
                },
                {
                    key: "examples",
                    title: "案例列表"
                },
            ]
        }
    }

    componentWillMount() {
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            pageType: nextProps.pageType
        })
    }

    render() {
        const { pageType, titleList } = this.state

        return (
            <div className="plan-detail-head">
                <div className="detail-fun">
                    {
                        titleList.map(item => {
                            if (item.key === pageType) {
                                return (
                                    <h5 key={item.key}>{item.title}</h5>
                                )
                            }
                        })
                    }

                </div>

                <div className="plan-head-info">
                    <Descriptions
                        layout="vertical"
                        colon={false}
                        column={9}
                    >
                        <Descriptions.Item
                            label={
                                <Fragment>
                                    <span>类别</span>
                                    <span>Catagory</span>
                                </Fragment>
                            }>MA</Descriptions.Item>
                        <Descriptions.Item
                            label={
                                <Fragment>
                                    <span>课程名称</span>
                                    <span>Course</span>
                                </Fragment>
                            }>管理学</Descriptions.Item>
                        <Descriptions.Item
                            label={
                                <Fragment>
                                    <span>课程代码</span>
                                    <span>Course Code</span>
                                </Fragment>
                            }>3389022</Descriptions.Item>
                        <Descriptions.Item
                            span={2}
                            label={
                                <Fragment>
                                    <span>模块</span>
                                    <span>Module</span>
                                </Fragment>
                            }>1st Module, Fall semester, 20181st Module, Fall semester, 2018</Descriptions.Item>
                        <Descriptions.Item
                            label={
                                <Fragment>
                                    <span>教师</span>
                                    <span>Instructor</span>
                                </Fragment>
                            }>刘澜</Descriptions.Item>
                        <Descriptions.Item
                            label={
                                <Fragment>
                                    <span>办公室</span>
                                    <span>Office</span>
                                </Fragment>
                            }>626</Descriptions.Item>
                        <Descriptions.Item
                            span={2}
                            label={
                                <Fragment>
                                    <span>邮箱</span>
                                    <span>Email</span>
                                </Fragment>
                            }>liulan@pku.edu.cn</Descriptions.Item>
                    </Descriptions>
                </div>
            </div>
        );
    }
}

export default DetailHead;