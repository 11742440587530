import React from 'react'
import {Route,Redirect, Switch} from "react-router-dom"
import {webSdk} from "../../../../../api/WebSdk";
import BaseIndex from './base/BaseIndex'
import FinancialIndex from './financial/FinancialIndex'
import IndustryIndex from './industry/IndustryIndex'
import ResearchIndex from './research/ResearchIndex'
import '../../../../../css/searchResult.scss'


class SearchCompanyResult extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            keyword: props.match.params.keyword || '',        //当前搜索词
            currentCard: "baseInfo",            //当前顶部菜单栏选择
            menuList: []                        //顶部菜单数据
        }
        this.getBaseInfo = this.getBaseInfo.bind(this);
        this.changeMenu = this.changeMenu.bind(this);
    }

    componentDidMount() {
        this.props.fnSetType('companyList',this.state.keyword || '')
        this.getBaseInfo(res =>{
            let currentCard = "";
            let pathname = this.props.location.pathname;
            res.menu.forEach((item,index) =>{
                if (pathname.indexOf(item.key) !== -1) {
                    currentCard = item.key;
                }
            });
            this.setState({
                menuList: res.menu,
                currentCard: currentCard || "baseInfo"
            })
        })
    }

    /**
     * 获取顶部菜单基本信息
     */
    getBaseInfo(cb){
        const _this = this;
        let postData = {
            type: "companyInfo",
            p: _this.state.keyword
        };
        webSdk.search.searchBaseInfo(postData).then(res => {
            cb && cb(res)
        })
    }

    /**
     * 改变顶部菜单栏
     */
    changeMenu(item){
        if (this.state.currentCard===item.key) return;
        this.setState({
            currentCard: item.key
        },() =>{
            //this.context.getHistory().push({
            this.props.history.push({
                pathname:`${this.props.match.url}/${item.key}`,
                state:{
                    keyword: this.state.keyword
                }
            })
        })
    }


    componentWillReceiveProps(nextProps) {
        let pathname = nextProps.location.pathname;
        let pathnames = pathname.split('/');
        let currentPath = pathnames[pathnames.length-1];
        this.setState({
            currentCard : currentPath
        })
    }

    render() {
        let match = this.props.match;
        return (
            <div className="company-result">
                {/*顶部菜单栏*/}
                <ul className="top-menu">
                    {this.state.menuList.map((item,index) =>{
                        return (
                            <li key={index} onClick={()=>this.changeMenu(item)} className={this.state.currentCard===item.key?"choice-top-menu":''}>{item.label}</li>
                        )
                    })}
                </ul>
                {/*主内容部分*/}
                <div className="bottom-content">
                    <Switch>
                        {/*公司基本面*/}
                        {/*<DynRoute path={`${match.url}/baseInfo`} component={() => import('./CompanyBaseInfo')}/>
                        利润概况
                        <DynRoute path={`${match.url}/financialAnalysis`} component={() => import('./CompanyProfitInfo')}/>
                        行业分析
                        <DynRoute path={`${match.url}/industryAnalysis`} component={() => import('./CompanyIndustryAnalysis')} />
                        研报摘要
                        <DynRoute path={`${match.url}/researchAbstract`} component={() => import('./CompanyResearchAbstract')}/>*/}
                        <Route path={`/companyResult/${this.state.keyword}/base`} component={BaseIndex}/>
                        {/*利润概况*/}
                        <Route path={`/companyResult/${this.state.keyword}/financial`} component={FinancialIndex}/>
                        {/*行业分析*/}
                        <Route path={`/companyResult/${this.state.keyword}/industry`} component={IndustryIndex} />
                        {/*研报摘要*/}
                        <Route path={`/companyResult/${this.state.keyword}/research`} component={ResearchIndex}/>

                        <Redirect exact from={`/companyResult/${this.state.keyword}`} to={`/companyResult/${this.state.keyword}/base`}/>
                    </Switch>
                </div>
            </div>
        )
    }
}

export default SearchCompanyResult