import {webSdk} from "../../../api/WebSdk";
import {message} from 'antd';

// 获取提纲模板列表
const querySyllabusListApi = (page, pageSize) => {
    return new Promise((resolve, reject) => {
        let postData = {};
        if (page && pageSize) {
            postData = {
                page: page,
                page_size: pageSize
            };
        }
        webSdk.search.querySyllabusList(postData).then(res => {
            if (!res.errCode || res.errCode === 0) {
                resolve(res);
            } else {
                message.error(res.errMsg || "获取提纲模板列表失败，请稍后重试");
                reject();
            }
        }).catch(e => {
            message.error("获取提纲模板列表失败，请稍后重试");
            reject(e);
        })
    })
};

// 删除模板
const deleteSyllabusApi = item => {
    return new Promise((resolve, reject) => {
        let postData = {
            outline_id: item.id
        };
        webSdk.search.deleteSyllabus(postData).then(res => {
            if (res === undefined || !res.errCode || res.errCode === 0) {
                message.success("删除成功");
                resolve(true);
            } else {
                message.error(res.errMsg || "删除提纲模板失败，请稍后重试");
                reject();
            }
        }).catch(e => {
            message.error("删除提纲模板失败，请稍后重试");
            reject(e);
        })
    })

};

// 获取模板详情
const querySyllabusInfoApi = id => {
    return new Promise((resolve, reject) => {
        let postData = {outline_id: id};
        webSdk.search.querySyllabusInfo(postData).then(res => {
            if (!res.errCode || res.errCode === 0) {
                resolve(res);
            } else {
                message.error(res.errMsg || "获取提纲模板详情失败，请稍后重试");
                reject();
            }
        }).catch(e => {
            message.error("获取提纲模板详情失败，请稍后重试");
            reject(e);
        })
    })
};

// 获取标签树
const queryTagTreeApi = () => {
    return new Promise((resolve, reject) => {
        webSdk.search.queryTagTree().then(res => {
            if (res === undefined || !res.errCode || res.errCode === 0) {
                resolve(res);
            } else {
                message.error(res.errMsg || "获取标签树失败，请稍后重试");
                reject();
            }
        }).catch(e => {
            message.error("获取标签树失败，请稍后重试");
            reject(e);
        })
    })
};

// 新建模板
const saveSyllabusApi = postData => {
    return new Promise((resolve, reject) => {
        webSdk.search.saveSyllabus(postData).then(res => {
            if (res === undefined || !res.errCode || res.errCode === 0) {
                resolve(res);
            } else {
                message.error(res.errMsg || "保存模板失败，请稍后重试");
                reject();
            }
        }).catch(e => {
            message.error("保存模板失败，请稍后重试");
            reject(e);
        })
    })
};

// 编辑模板
const updateSyllabusApi = postData => {
    return new Promise((resolve, reject) => {
        webSdk.search.updateSyllabus(postData).then(res => {
            if (res === undefined || !res.errCode || res.errCode === 0) {
                resolve(res);
            } else {
                message.error(res.errMsg || "编辑模板失败，请稍后重试");
                reject();
            }
        }).catch(e => {
            message.error("编辑模板失败，请稍后重试");
            reject(e);
        })
    })
};

// 生成报告
const createReportApi = postData => {
    return new Promise((resolve, reject) => {
        webSdk.search.createReport(postData).then(res => {
            if (res === undefined || !res.errCode || res.errCode === 0) {
                message.success("生成报告成功");
                resolve(res);
            } else {
                message.error(res.errMsg || "生成报告失败，请稍后重试");
                reject();
            }
        }).catch(e => {
            message.error("生成报告失败，请稍后重试");
            reject(e);
        })
    })
};

export {
    querySyllabusListApi,
    deleteSyllabusApi,
    querySyllabusInfoApi,
    queryTagTreeApi,
    saveSyllabusApi,
    updateSyllabusApi,
    createReportApi
}
