/**
 * 生成摘要
 */
import React, {useEffect} from 'react'
import {Icon, Popover, Spin} from 'antd'
import stores from "../../../store";
import {observer} from 'mobx-react'

/*
[{art_date: "2020-06-23"
art_id: 685825
art_title: "非住宅物业管理专题报告之三：出圈城市服务，迈向星辰大海"
art_url: "http://fs.phbs-ai.perfectcode.tech/pdf/researchreport/2020/06/4268762.pdf"
id: 1133002
kp_id: [129315692, 129315693, 129315694, 129315695, 129315696, 129315697, 129315698]
page: 3
pos_list: ""
text: "物管龙头对于...",
abstract: "",       // 新添加的摘要内容
},...]
* */

const LeaderLine = window.LeaderLine;       //  https://anseki.github.io/leader-line/
let line;
let textareaRef = null;

function HomeRightAbstract(props) {
    const {homeRightStore, homeCommonStore, homeCenterStore} = stores;

    let {rightStatus} = homeCommonStore,
        {abstractAnaList, setAbstractAnaList, abstractAnaLoading} = homeRightStore;

    useEffect(() =>{
        if (homeRightStore.dbClickId === null) {
            if (line) line.position();
        }
    },[homeRightStore.dbClickId]);

    const textAreaBlur = (ev, index) => {
        let newList = [...abstractAnaList];
        let newValue = ev.target.innerText;
        if (newValue === "") {
            ev.target.innerText = newList[index];
        } else {
            newList[index]["abstract"] = newValue;
        }
        newList[index]["edit"] = false;
        setAbstractAnaList(newList);
    };

    const textAreaEnter = (ev, index) => {
        if (ev.keyCode === 13) {
            textAreaBlur(ev, index);
        }
    };

    const editAbstractStatus = (ev, index) => {
        let newList = [...abstractAnaList];
        newList[index].edit = true;
        setAbstractAnaList(newList);
        setTimeout(() => {
            textareaRef.focus();
        }, 10);
        // 阻止合成事件的冒泡
        ev.stopPropagation();
        // 阻止与原生事件的冒泡
        ev.nativeEvent.stopImmediatePropagation();
    };

    const abstractMouseEnter = item =>{
        let start = document.getElementById('abstract_' + item.id);
        if (!start) return;
        let end = document.getElementById('corpus_' + item.id);
        if (!end) return;
        homeRightStore.setHoverId(item.id);
        homeCenterStore.setHoverId(item.id);
        line = new LeaderLine(
            start,
            end,
            {
                color: '#5B6BAE',       // 线条颜色
                size: 2,                // 线条粗细
                // dash: dash !== undefined,             // 虚线
                gradient: false,
                startPlug: 'disc',        // 开始的图标      //https://anseki.github.io/leader-line/#startplug-endplug
                endPlug: 'disc',          // 末尾的图标
                hide: true
            });
        line.setOptions({startSocket: 'left', endSocket: 'right'});
        line.show('draw', {
            duration: 300,                  // 总时
            timing: [0.5, 0, 0.5, 0.42]       // [x1, y1, x2, y2]作为“定时功能” 的数组
        })
    };

    const abstractMouseLeave = item => {
        homeCenterStore.setHoverId(null);
        homeRightStore.setHoverId(null);
        if (!line) return;
        line.hide();
        line.remove();
        line = null;
    };

    const abstractDoubleClick = item => {
        let rightItem = document.getElementById(`abstract_${item["id"]}`);
        let itemPosition = rightItem.getBoundingClientRect() || {};
        let top = itemPosition.top || 0;
        homeRightStore.setDbItem(item.id, top);
    };

    return (
        <div className="home-right-module home-right-abstract" style={rightStatus === 2 ? null : {height: 0}}>
            <div className="home-right-module-content">
                <Spin spinning={abstractAnaLoading}>
                    {
                        abstractAnaList.map((item, index) => {
                            let cls = "right-abstract-item";
                            if (item.edit) cls += " active-abstract-item";
                            if (item.id === homeCenterStore.hoverId) cls += " right-abstract-item-line";
                            return (
                                <div key={`abstract_${item["id"]}`}
                                     id={`abstract_${item["id"]}`}
                                     onMouseEnter={() => abstractMouseEnter(item)}
                                     onMouseLeave={() => abstractMouseLeave(item)}
                                     onDoubleClick={() => abstractDoubleClick(item)}
                                     className={cls}>
                                    <h3 className="home-right-module-title">
                                        <Popover content={item["art_title"]} trigger="hover" placement="bottomLeft">
                                            <span className="abstract-title">{item["art_title"]}</span>
                                        </Popover>
                                        <Icon className="abstract-edit" type="edit"
                                              onClick={e => editAbstractStatus(e, index)}/>
                                    </h3>
                                    <div className="home-right-card">
                                        <div className="right-abstract-text" contentEditable={item.edit}
                                             style={{whiteSpace: 'pre-wrap'}}
                                             suppressContentEditableWarning="true"
                                             onBlur={ev => textAreaBlur(ev, index)}
                                             onKeyDown={ev => textAreaEnter(ev, index)}
                                             ref={el => item.edit ? textareaRef = el : null}>
                                            {item["abstract"]}
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Spin>
            </div>
        </div>
    )
}

export default observer(HomeRightAbstract)
