import React, { PureComponent, Fragment } from 'react';
import "./SummaryList.scss"
import { Collapse, Button, Icon, Input } from 'antd';

const { Panel } = Collapse;
const { TextArea } = Input;

class SummaryList extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            summaryList: [//摘要列表
                {
                    question: "万科地产股份",
                    summarry: "正忙于解决负债，引入战投的泰禾集团(000732)8月14日晚间发布半年报。公司上半年实现营收24.63亿元，同比减少83.02%；净利润亏损15.82亿元，去年同期盈利15.61亿元。正忙于解决负债，引入战投的泰禾集团(000732)8月14日晚间发布半年报。公司上半年实现营收24.63亿元，同比减少83.02%；净利润亏损15.82亿元，去年同期盈利15.61亿元。正忙于解决负债，引入战投的泰禾集团(000732)8月14日晚间发布半年报。公司上半年实现营收24.63亿元，同比减少83.02%；净利润亏损15.82亿元，去年同期盈利15.61亿元。正忙于解决负债，引入战投的泰禾集团(000732)8月14日晚间发布半年报。公司上半年实现营收24.63亿元，同比减少83.02%；净利润亏损15.82亿元，去年同期盈利15.61亿元。正忙于解决负债，引入战投的泰禾集团(000732)8月14日晚间发布半年报。",
                    _tempSummary: "",//临时保存的文本
                    isEditing: false,
                },
                {
                    question: "万科地产股份",
                    summarry: "正忙于解决负债，引入战投的泰禾集团(000732)8月14日晚间发布半年报。公司上半年实现营收24.63亿元，同比减少83.02%；净利润亏损15.82亿元，去年同期盈利15.61亿元。正忙于解决负债，引入战投的泰禾集团(000732)8月14日晚间发布半年报。公司上半年实现营收24.63亿元，同比减少83.02%；净利润亏损15.82亿元，去年同期盈利15.61亿元。正忙于解决负债，引入战投的泰禾集团(000732)8月14日晚间发布半年报。公司上半年实现营收24.63亿元，同比减少83.02%；净利润亏损15.82亿元，去年同期盈利15.61亿元。正忙于解决负债，引入战投的泰禾集团(000732)8月14日晚间发布半年报。公司上半年实现营收24.63亿元，同比减少83.02%；净利润亏损15.82亿元，去年同期盈利15.61亿元。正忙于解决负债，引入战投的泰禾集团(000732)8月14日晚间发布半年报。",
                    isEditing: false,
                },
            ]
        }
    }

    /** 改变文本域的值 */
    changeArea(idx, event) {
        let content = event.target.value
        let summaryList = [...this.state.summaryList]
        summaryList[idx]._tempSummary = content

        this.setState({
            summaryList
        })
    }

    /** 操作编辑 */
    handleEdit(isEdit, idx, event) {
        event.stopPropagation();

        let summaryList = [...this.state.summaryList]
        summaryList[idx].isEditing = isEdit

        if (isEdit) {
            summaryList[idx]._tempSummary = summaryList[idx].summarry
        }

        this.setState({
            summaryList
        })
    }

    /** 保存编辑 */
    saveEdit(idx, event) {
        event.stopPropagation()
        let summaryList = [...this.state.summaryList]
        summaryList[idx].summarry = summaryList[idx]._tempSummary

        this.handleEdit(false, idx, event)

        this.setState({
            summaryList
        })
    }

    /** 返回案例列表 */
    backToExampleList() {
        this.props.history.push({
            pathname: "/smartsearch/example"
        })
    }

    render() {
        const { summaryList } = this.state

        return (
            <div className="summary-list">
                <div className="summary-panel">
                    <Collapse
                        defaultActiveKey={summaryList.map((item, idx) => idx)}
                        expandIconPosition="right"
                        bordered={false}
                        expandIcon={({ isActive }) =>
                            <Icon type="down" className="summary-panel-icon" rotate={isActive ? 180 : 0} />
                        }
                    // onChange={callback}
                    >
                        {
                            summaryList.map((item, idx) => {
                                return (
                                    <Panel
                                        key={idx}
                                        header={
                                            <div className="summary-panel-head">
                                                <h5 className="summary-panel-title">{`${idx + 1}、${item.question}`}</h5>
                                                {
                                                    item.isEditing ? (
                                                        <Fragment>
                                                            <Button
                                                                className="summary-btn panel-inner back"
                                                                onClick={this.handleEdit.bind(this, false, idx)}
                                                            >取消</Button>
                                                            <Button
                                                                type="primary"
                                                                className="summary-btn panel-inner"
                                                                onClick={this.saveEdit.bind(this, idx)}
                                                            >保存</Button>
                                                        </Fragment>
                                                    ) : (
                                                            <Button
                                                                type="primary"
                                                                icon="edit"
                                                                className="summary-btn panel-inner"
                                                                onClick={this.handleEdit.bind(this, true, idx)}
                                                            >编辑</Button>
                                                        )
                                                }
                                            </div>
                                        }
                                    >
                                        {
                                            item.isEditing ? (
                                                <TextArea
                                                    autoFocus
                                                    placeholder="请粘贴或手动输入语句、段落"
                                                    autoSize={{ minRows: 5 }}
                                                    value={item._tempSummary}
                                                    onChange={this.changeArea.bind(this, idx)}
                                                />
                                            ) : (
                                                    <p className="summary-panel-text">{item.summarry}</p>
                                                )
                                        }
                                    </Panel>
                                )
                            })
                        }
                    </Collapse>
                </div >
                <div className="summary-btngroup">
                    <Button
                        className="summary-btn back"
                        onClick={this.backToExampleList.bind(this)}
                    >返回上一步</Button>
                    <Button
                        type="primary"
                        className="summary-btn"
                    // onClick={this.createSummary.bind(this)}
                    >导出答案</Button>
                </div>
            </div >
        );
    }
}

export default SummaryList;