/*
 * @Author: 符春玲 fuchunling@perfectcode.tech
 * @Date: 2021-09-02 19:32:54
 * @LastEditors: 符春玲 fuchunling@perfectcode.tech
 * @LastEditTime: 2022-10-21 15:36:07
 * @FilePath: \cedb-web\ei-front\src\pages\component\SearchResultList\ResultSearch\graphModule\LeftGraph.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react'
import {message} from 'antd';
import D3ForceChart from '../../../../common/D3ForceChart'
import LeftActionIcon from "../resultCommon/LeftCommon/LeftActionIcon";

class LeftGraph extends React.PureComponent {

    constructor(props) {
        super(props)

        this.state = {
            isFullScreen: false,        //是否全屏
            isCursorPoint: false,       //是否处于鼠标选择状态
        }
        window.isCursor = false;
        this.graphRef = null;
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.keyword !== this.props.keyword) {
            window.isCursor = false;
            this.setState({
                isCursorPoint: false
            })
        }
    }

    componentWillUnmount() {
        window.isCursor = false;
        // 卸载异步操作设置状态
        this.setState = (state, callback) => {
            return;
        }
    }

    switchCursorPoint = () => {
        let newStatus = !this.state.isCursorPoint;
        window.isCursor = newStatus;
        // 处于激活状态，弹出右侧列表
        if (newStatus) {
            message.info('钉住字段，下载数据');
            this.props.clickDownIcon(true)
        } else {
            this.props.clickDownIcon(false)
        }
        this.setState({
            isCursorPoint: newStatus
        })
    }

    switchFullScreen = () => {
        this.setState({
            isFullScreen: !this.state.isFullScreen
        })
    }

    render() {
        let clsName = 'result-search__graph__left';
        if (this.state.isFullScreen) clsName += ' full-screen';
        if (this.state.isCursorPoint) clsName += ' cursor-module';

        return (<div className={clsName} id="graph_content">
            <LeftActionIcon
                isCursorPoint={this.state.isCursorPoint}
                isFullScreen={this.state.isFullScreen}
                switchCursorPoint={this.switchCursorPoint}
                clickDownIcon={this.props.clickDownIcon}
                switchFullScreen={this.switchFullScreen}/>
            {/* echarts版本 */}
            {/*<GraphTemplate ref="GraphTemplate"
                           graphData={this.props.graphData}
                           isCursorPoint={this.state.isCursorPoint}
                           addGraphText={this.addGraphText}/>*/}
            <D3ForceChart
                ref={el => this.graphRef = el}
                treeData={this.props.graphData}
                isCursorPoint={this.state.isCursorPoint}
                loading={this.props.loading}
                nodeClick={this.props.nodeClick}/>
        </div>)
    }
}

export default LeftGraph