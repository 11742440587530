import React from 'react';
import {Empty, message} from 'antd'
import * as d3 from 'd3js';
import './index.scss';
import _ from 'lodash';
import forceAttract from './forceAttract';
import {webSdk} from "../../../api/WebSdk";

// d3-force-cluster   d3-force-attract


const cheackNodesDiff = (nodes, newNodes) => {
    return nodes.length < newNodes.length ? 'enter' : 'exit';
}

/** 获取元素属性 */
const getElementStyle = (el, name) => {
    if (window.getComputedStyle) {
        let style = window.getComputedStyle(el, null);
        return style[name];
    } else {
        return el.currentStyle[name];
    }
}

// 格式化数据
/** 初始化树形结构数据格式化成所有的nodes，edges数据 */
const setInitData = (initData, nodes, edges, parentNode, isShow) => {
    if (initData.length > 0) {
        let currentParentNode = {...parentNode}
        for (let i = 0; i < initData.length; i++) {
            let item = initData[i];
            let hasChildren = !!(item.children && item.children.length > 0);
            let node = {
                _id: item._id,
                id: item._id,                // 节点唯一标识
                key: item.key,              // 节点的字段
                keyLabel: item.keyLabel,    // 节点的字段显示名称
                label: item.label,          // 节点中的显示文字
                // level: item.level,          // 节点的层级
                level: item.level,          // 节点的层级
                parent: currentParentNode,
                parentList: item.parentList || [],
                children: item.children || null,
                // isShow: item.level === 1,   // 是否显示该节点，默认加载进来只显示第一层的节点
                isShow: isShow || item.level === 1,   // 是否显示该节点，默认加载进来只显示第一层的节点
                showChildren: isShow || false,        // 是否显示子级
                hasChildren: hasChildren,                // 节点是否有子级
                requested: item.requested
            };
            if (item.artIds) node.artIds = item.artIds;
            if (item._node) node._node = item._node;
            if (item._nodes) node._nodes = item._nodes;
            if (item._time) node._time = item._time;
            nodes.push(node);
            if (!_.isEmpty(currentParentNode)) {
                edges.push({
                    //id: `${currentParentNode.id}-${item.id}`,       // 连线唯一标识
                    source: currentParentNode.id,                   // 连线的起始节点
                    target: node.id,                                // 连线的末节点
                    isShow: isShow || false,                                  // 连线是否显示。默认不显示连线
                    hasChildren: hasChildren,
                })
            }
            if (hasChildren) {
                setInitData(item.children, nodes, edges, node, isShow)
            }
        }
    }
}

/** 将nodes、edges数据进行遍历，提取isShow显示的元素 */
const toShowData = (showNodes, showEdges, allNodes, allEdges) => {
    // let showNodes = [],
    //     showEdges = [];
    if (allNodes.length > 0) {
        for (let i = 0; i < allNodes.length; i++) {
            if (allNodes[i].isShow) showNodes.push(allNodes[i]);
        }
    }
    if (allEdges.length > 0) {
        for (let i = 0; i < allEdges.length; i++) {
            if (allEdges[i].isShow) showEdges.push(allEdges[i]);
        }
    }
    // showData = {
    //     nodes: showNodes,
    //     edges: showEdges
    // }
}

/** 点击节点时做节点展开/收缩操作 */
const switchChildrenStatus = (cliNode, allNodes, allEdges) => {
    if (!cliNode.hasChildren) return;
    cliNode.showChildren = !cliNode.showChildren;
    let status = cliNode.showChildren;              // 当前的操作方式：true为展开，false为收缩
    changeStatus(cliNode.id, status)

    function changeStatus(id, status) {
        let nodeArr = [];
        for (let i = 0; i < allEdges.length; i++) {
            let item = allEdges[i];
            if (item.source.id === id || item.source === id) {
                item.isShow = status;
                nodeArr.push(item.target.id ? item.target.id : item.target);
                if (item.hasChildren) changeStatus(item.target.id || item.target, status)
            }
        }
        if (nodeArr.length > 0) {
            for (let i = 0; i < allNodes.length; i++) {
                if (nodeArr.includes(allNodes[i].id)) {
                    allNodes[i].isShow = status;
                    allNodes[i].showChildren = status;
                }
            }
        }
    }
}
// 格式化数据


/** 设置画布的宽高和中心 */
const setStyle = () => {
    let el = document.getElementById('graph_content');
    if (!el) return;
    WIDTH = getElementStyle(el, 'width').replace(/px/g, '');
    HEIGHT = getElementStyle(el, 'height').replace(/px/g, '');
    centerLocation = {              // 默认中心点 容器中间位置 -> Object{}
        x: WIDTH / 2,
        y: HEIGHT / 2
    }
}
/** 窗口大小发生改变时 */
const changeChartWidth = () => {
    let el = document.getElementById('graph_content');
    if (!el) return;
    WIDTH = getElementStyle(el, 'width').replace(/px/g, '');
    HEIGHT = getElementStyle(el, 'height').replace(/px/g, '');
    d3.select('#theChart').select('svg').attr('width', WIDTH).attr('height', HEIGHT);
}

let WIDTH = null;
let HEIGHT = null;
let strength = -600;                // 默认节点间的作用力 -> Number
let distanceMax = -100;             // 默认节点间的最大距离 -> Number
let dragStartAlphaTarget = 0.5;     // dragStartAlphaTarget 开始拖拽系数
let dragEndAlphaTarget = 0;         // dra gEndAlphaTarget 结束拖拽系数
let scaleExtent = [1 / 10, 10];    // 默认缩放比例 [1 / 10, 10] -> Array[]
let centerLocation = {};             // 默认中心点 容器中间位置 -> Object{}
let hoverBox = null;
let singleClick = true;   // 鼠标移入节点是否显示tips
let longPress = false;

/** 鼠标移入显示hover */
const showHover = (d) => {
    if (!hoverBox) {
        hoverBox = d3.select('#theChart')
            .append('div')
            .attr('class', 'd3-force-hover')
    }

    let x = d3.event.x;
    let y = d3.event.y;
    hoverBox.style('top', y - 80 + 'px')
        .style('left', x + 'px')
        .style('display', 'block')
        .html(`${d.keyLabel}：${d.label}`)
}

/** 缩放 */
const zoom = g => {
    return d3
        .zoom()
        .scaleExtent(scaleExtent)
        .on('start', function () {
        })
        .on('zoom', function () {
            g.attr('transform', d3.event.transform);
        })
        .on('end', function () {
        })
}

/** 拖拽 */
const drag = (force, isCursorPoint) => {
    return d3
        .drag()
        .on('start', function (d) {
            longPress = true;
            if (!isCursorPoint) {
                if (hoverBox) {
                    hoverBox.style('display', 'none')
                }
                singleClick = false;
                if (!d3.event.active) {
                    force
                        /*设置衰减系数 它是节点位置移动过程中的模拟，数值越高移动就越快，范围[0,1]*/
                        .alphaTarget(0.3)
                        .restart();
                }
                d.fx = d.x;
                d.fy = d.y;
            }
        })
        .on('drag', function (d) {
            if (!isCursorPoint) {
                if (hoverBox) {
                    hoverBox.style('display', 'none')
                }
                //clearTimeout(longPressTImer);
                d.fx = d3.event.x;
                d.fy = d3.event.y;
            }
        })
        .on('end', function (d) {
            longPress = false;
            if (!isCursorPoint) {
                if (hoverBox) {
                    hoverBox.style('display', 'none')
                }
                singleClick = true
                if (!d3.event.active) {
                    force.alphaTarget(0);
                }
                d.fx = null;
                d.fy = null;
            }
        });
}

/** 创建力模型 */
const createForce = (nodes, edges) => {
    /*return d3
        .forceSimulation(nodes)
        .alphaDecay(0.1)
        .force('link', d3.forceLink(edges).id(d => { return d.id }))
        .force('center', d3.forceCenter(centerLocation.x, centerLocation.y))
        .force('attract', forceAttract().strength(0.02))      // 居中插件，数值越小，间距越大
        .force(
            'charge',
            d3
                .forceManyBody()
                .strength(strength)
                .distanceMax(distanceMax)
        );*/
    return d3.forceSimulation(nodes)
        .force("link", d3.forceLink(edges).id(d => d.id))
        .force('center', d3.forceCenter(centerLocation.x, centerLocation.y))
        .force("charge", d3.forceManyBody().strength(strength))
        .force("x", d3.forceX())
        .force("y", d3.forceY());
}

/** 创建线 */
const createLine = (edge_group, edges) => {
    return edge_group
        .selectAll('line')
        .data(edges)
        .enter()
        .append('line')
        .style('stroke', '#929292')
        .style('stroke-width', 2);
}

/** 创建节点 */
const createNode = (node_group, nodes, force, isCursorPoint) => {
    return node_group
        .selectAll('circle')
        .data(nodes)
        .enter()
        .append('circle')
        .attr('r', d => {
            if (d.level) {
                if (d.level === 1) return 30;
                if (d.level === 2) return 25;
                if (d.level === 3) return 20;
                if (d.level === 4) return 15;
                if (d.level === 5) return 10;
            }
            return 20;
        })
        .attr('class', d => {
            let name = 'node-circle';
            if (d.loading) {
                if (['entity1', 'relation', 'entity2'].includes(d.key)) {
                    name += ' relation-node-loading'
                } else {
                    name += ' value-node-loading';
                }
            }
            if (d.artIds) {
                name += ' ' + d.artIds.join(' ');
            }
            return name;
        })
        .style('fill', d => {
            if (['entity1', 'relation', 'entity2'].includes(d.key)) {
                return 'rgba(255,0,0)'
            }
            return '#1890FF'
        }) // 填充颜色
        .style('stroke', d => {
            if (['entity1', 'relation', 'entity2'].includes(d.key)) {
                return 'rgba(255,0,0,0.3)'
            }
            return '#7ecef4'
        }) // 边框颜色
        // .style('stroke-width', 2) // 边框粗细
        .call(drag(force, isCursorPoint));
}

/** 创建文字 */
const createText = (svg, nodes) => {
    return svg
        .select('g')
        .selectAll('text')
        .data(nodes)
        .enter()
        .append('text')
        .attr('dy', '.3em') // 偏移量
        .attr('id', d => {
            return 'text-' + d.id
        })
        .attr('text-anchor', 'middle') // 节点名称放在圆圈中间位置
        .style('fill', 'black') // 颜色
        .style('pointer-events', 'none') // 禁止鼠标事件
        .style("font-size", 18)
        .text((d) => { // 文字内容
            return d && d.label; // 遍历nodes每一项，获取对应的name
        })
}


class D3ForceChart extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            keyword: props.keyword || '',
            treeData: props.treeData || [],
            allNodes: [],           // 所有节点数据
            allEdges: [],           // 所有连线数据
            nodeData: [],           // 显示节点数据
            edgesData: [],          // 显示连线数据
            newNodes: {},
            newEdges: {},
        };
    }

    componentDidMount() {
        window.onresize = () => {
            changeChartWidth()
        };
        setStyle()

        if (this.state.treeData.length === 0) return;
        this.draw();
    }

    componentWillReceiveProps(nextProps) {
        if (!_.isEqual(this.state.treeData, nextProps.treeData)) {
            this.setState({
                treeData: nextProps.treeData
            })
            if (!_.isEmpty(nextProps.treeData)) {
                setStyle();
                this.draw();
            } else {
                d3.select('#theChart').select('svg').remove();
                hoverBox = null;
            }
        }
    }

    draw = () => {
        let treeData = [...this.state.treeData];
        if (treeData.length === 0) return;
        let allNodes = [],
            allEdges = [],
            showNodes = [],
            showEdges = [];
        setInitData(treeData, allNodes, allEdges, {});
        toShowData(showNodes, showEdges, allNodes, allEdges);
        this.setState({
            allNodes, allEdges,
            nodeData: showNodes,
            edgesData: showEdges,
        }, () => {
            this.forceChart();
        })

    }

    forceChart = () => {
        let force, g;
        let nodes = this.state.nodeData;
        let edges = this.state.edgesData;
        let isCursorPoint = this.props.isCursorPoint;
        let that = this;
        /*力*/
        force = createForce(nodes, edges);

        /*创建svg和g*/
        let svg = d3
            .select('#theChart')
            .append('svg')
            .attr('width', WIDTH)
            .attr('height', HEIGHT);

        g = svg.append('g').attr('class', 'graph_g');
        svg.call(zoom(g));
        let edge_group = g.append('g').attr('class', 'edge_group');
        let node_group = g.append('g').attr('class', 'node_group');

        /*创建线*/
        let svg_edges = createLine(edge_group, edges);

        /*创建节点*/
        let svg_nodes = createNode(node_group, nodes, force, isCursorPoint);

        /*创建字*/
        let svg_texts = createText(svg, nodes);

        /*点击节点*/
        svg_nodes.on('click', (d, i) => {
            if (singleClick) {
                if (hoverBox) hoverBox.style('display', 'none')
                this.nodeClick(d, nodes, edges, restart);
            }
        });

        svg_nodes.on('mouseover', d => showHover(d));
        svg_nodes.on('mousemove', d => showHover(d));
        svg_nodes.on('mouseout', d => {
            if (hoverBox) hoverBox.style('display', 'none')
        });

        // 点击节点后更新图谱
        function restart(newNodes, newEdges, changeData) {
            that.setState({newNodes, newEdges});
            let diff = cheackNodesDiff(nodes, newNodes);
            nodes = newNodes;
            edges = newEdges;
            if (diff === 'enter') {
                /*添加线*/
                svg_edges = edge_group
                    .selectAll('line')
                    .data(edges)
                    .enter()
                    .append('line')
                    .style('stroke', '#929292')
                    .style('stroke-width', 2)
                    .merge(svg_edges);
            } else if (diff === 'exit') {
                /*移除线*/
                svg_edges = edge_group
                    //.select('g')
                    .selectAll('line')
                    .data(edges)
                    .exit()
                    .remove()
                    .merge(svg_edges);
            }
            /*移除节点*/
            svg_nodes.remove();
            svg_nodes = createNode(node_group, nodes, force, isCursorPoint);
            /*移除文字*/
            svg_texts.remove();
            svg_texts = createText(svg, nodes);
            svg_texts.merge(svg_texts);

            force.nodes(nodes);
            force.force('link').links(edges);

            //force.alphaTarget(0.4).restart();
            // 延迟处理，使节点聚拢
            if (diff === 'enter') {
                force.alphaTarget(0.6).restart();
                setTimeout(() => {
                    force.alphaTarget(0)
                }, 1000)

            } else {
                force.alphaTarget(0.4).restart();
                setTimeout(() => {
                    force.alphaTarget(0)
                }, 600)
            }

            /*点击节点*/
            svg_nodes.on('click', (d, i) => {
                if (singleClick) {
                    if (hoverBox) hoverBox.style('display', 'none')
                    that.nodeClick(d, nodes, edges, restart);
                }
            });
            svg_nodes.on('mouseover', d => showHover(d));
            svg_nodes.on('mousemove', d => showHover(d));
            svg_nodes.on('mouseout', d => {
                if (hoverBox) hoverBox.style('display', 'none')
            });
        }

        force.on('tick', function () {
            /*更新连线*/
            svg_edges
                .attr('x1', d => d.source.x)
                .attr('y1', d => d.source.y)
                .attr('x2', d => d.target.x)
                .attr('y2', d => d.target.y)
                .attr('d', d => `M${d.source.x}${d.source.y}L${d.target.x}${d.target.y}`);

            /*更新节点*/
            svg_nodes
                .attr('cx', d => d.x)
                .attr('cy', d => d.y)
                .attr("role", d => d.role);

            /*更新文字*/
            svg_texts
                .attr('x', d => d.x)
                .attr('y', d => d.y);
        });
    }

    setNodeLoading = (node, type) => {
        let status = false;
        if (type === 'show') status = true;
        let allNodes = [...this.state.allNodes];
        let allEdges = [...this.state.allEdges];
        allNodes.forEach(d => {
            if (d._id === node.id) {
                d.loading = status;
            }
        })
        let showNodes = [];
        let showEdges = [];
        toShowData(showNodes, showEdges, allNodes, allEdges);
        this.setState({
            allNodes,
            allEdges
        })
        return {
            allNodes, allEdges, showNodes, showEdges
        }
    }


    nodeClick = (clickNode, nodes, edges, restart) => {
        console.log(clickNode);
        // 如果是锚点状态
        if (this.props.isCursorPoint) {
            this.props.nodeClick(clickNode, 'add')
        } else {
            if (!clickNode.requested && ['attribute', 'time', 'relation'].includes(clickNode.key)) {
                if (clickNode.loading) return;
                let {showNodes: newNode, showEdges: newEdges} = this.setNodeLoading(clickNode, 'show');
                restart(newNode, newEdges);
                this.props.nodeClick(clickNode, 'request', res => {
                    clickNode.loading = false;
                    let {showNodes, showEdges, allNodes, allEdges} = this.setNodeLoading(clickNode, 'hidden');
                    if (res) {
                        let {artIds, children} = res;
                        clickNode.requested = true;
                        clickNode.hasChildren = true;
                        clickNode.children = children;
                        clickNode.artIds = artIds;
                        /*let allNodes = [...this.state.allNodes];
                        let allEdges = [...this.state.allEdges];*/
                        let entityNode = children[0].parentList[0];
                        let parentArtIds = artIds.map(artId => 'p' + artId);
                        // 将点击节点的父节点添加artIds
                        for (let i = 0; i < allNodes.length; i++) {
                            if (allNodes[i].id === entityNode.id) {
                                if (allNodes[i].artIds) {
                                    allNodes[i].artIds = [...allNodes[i].artIds, ...parentArtIds]
                                } else {
                                    allNodes[i].artIds = parentArtIds
                                }
                                break;
                            }
                        }
                        // 在所有连线数据中查找点击的节点，并把该连线数据的是否有子级设置为true
                        for (let i = 0; i < allEdges.length; i++) {
                            if (allEdges[i].target.id === clickNode.id || allEdges[i].target === clickNode.id) {
                                allEdges[i].hasChildren = true;
                            }
                        }
                        // 将所有获取到的子级做展示
                        setInitData(children, allNodes, allEdges, clickNode, true);

                        let showNodes = [];
                        let showEdges = [];
                        toShowData(showNodes, showEdges, allNodes, allEdges);
                        this.setState({
                            allNodes,
                            allEdges
                        })
                        restart(showNodes, showEdges)
                    } else {
                        restart(showNodes, showEdges);
                    }
                })
            } else {
                if (clickNode.hasChildren) {
                    let allNodes = [...this.state.allNodes];
                    let allEdges = [...this.state.allEdges];
                    switchChildrenStatus(clickNode, allNodes, allEdges);
                    let showNodes = [];
                    let showEdges = [];
                    toShowData(showNodes, showEdges, allNodes, allEdges);
                    this.setState({
                        allNodes,
                        allEdges
                    })
                    restart(showNodes, showEdges)
                } else {
                    if (['value', 'entity2'].includes(clickNode.key)) {
                        this.props.nodeClick(clickNode, 'feedBack')
                    }
                }
            }

        }

    }

    /*addId = (list) =>{
        if (Object.prototype.toString.call(list) === '[object Array]' && !_.isEmpty(list)) {
            list.forEach(item =>{
                item.id = idNumber;
                idNumber++;
                if (item.children) {
                    this.addId(item.children)
                }
            })
        }
    }*/

    render() {
        return (
            <React.Fragment>
                {
                    this.state.treeData.length === 0 && !this.props.loading ? (<div className="graph-empty"><Empty/></div>):
                    <div className="theChart" id="theChart" ref="theChart"/>
                }
            </React.Fragment>
        );
    }
}

export default D3ForceChart;
