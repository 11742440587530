/*
 * @Description:
 * @LastEditTime: 2023-03-30 10:35:11
 * @LastEditors: liqun 13212226759@163.com
 */
import BaseWebApi from './BaseWebApi'
import qs from 'qs'
import { message } from 'antd'

function delay(time) {
  return new Promise((resolve) => {
    setTimeout(resolve, time)
  })
}

class ApiCenter extends BaseWebApi {
  constructor() {
    super()
    this._api = this.gate.apiCenter
  }

  async getModelResult({ _req_id, uuid, poll_Interval }) {
    const params = { _req_id, uuid, poll_Interval }
    let count = 0
    while (true) {
      count++
      let resp = await this._api.get(
        `word_st/model_result?${qs.stringify(params)}`
      )
      if (resp.data.code === -201) {
        message.error(resp.data.message)
        return resp.data
      }
      if (
        (resp &&
          resp.data &&
          resp.data.data &&
          resp.data.data.result &&
          resp.data.data.result.answer) ||
        count >= 30
      ) {
        return resp.data
      }
    }
  }

  async getProgress({ _req_id, poll_Interval = 1 }) {
    const params = { _req_id, poll_Interval }
    let res = await this._api.get(
      `word_st/model_result?${qs.stringify(params)}`
    )
    // console.log(res)
    if (res.data.code === -201) {
      message.error(res.data.message)
    }
    return res.data.data.result
  }
}

export default ApiCenter
