import React, { PureComponent } from 'react'
import _ from 'lodash'
import { Empty, Spin } from 'antd';
import "./SupplyChain.scss"
import { webSdk } from '../../../../api/WebSdk';
import TreeMap from "./TreeMap"

class SupplyChain extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            currentYear: this.props.currentYear,//当前年份
            tickerSymbol: this.props.tickerSymbol,//股票代码
            companyName: this.props.companyName,//公司名称
            pageRequestParams: this.props.pageRequestParams,//后端请求所需值
            spinLoading: true,//表格加载状态
            chainFollowTb: {//主要客户供应链下游
                columns: [
                    {
                        title: '客户名称',
                        key: 'userName',
                    },
                    {
                        title: '产品',
                        key: 'product',
                    },
                    {
                        title: '销售额(元)',
                        key: 'saleroom',
                    },
                    {
                        title: '占比',
                        key: 'scale',
                    },
                ],
                data: [
                    // {
                    //     userName: '2018',
                    //     product: 'John Brown',
                    //     saleroom: "32",
                    //     scale: "10%",
                    // }
                ]
            },
            chainBelongTb: {//主要供应商/供应链上游
                columns: [
                    {
                        title: '供应商名称',
                        key: 'supplier',
                    },
                    {
                        title: '原材料',
                        key: 'meterial',
                    },
                    {
                        title: '采购额(元)',
                        key: 'purchase',
                    },
                    {
                        title: '占比',
                        key: 'scale',
                    },
                ],
                data: [
                    // {
                    //     supplier: '2018',
                    //     meterial: 'John Brown',
                    //     purchase: "32",
                    //     scale: "10%",
                    // }
                ]
            },
            analyse: {//主要构成分析
                columns: [
                    {
                        title: '时间',
                        key: 'quarterTime',
                        minWidth: 150,
                        maxWidth: 300,
                    },
                    {
                        title: '主营构成',
                        key: 'subTitle',
                        minWidth: 150,
                        maxWidth: 300,
                    },
                    {
                        title: '主营收入(元)',
                        key: 'mainIncome',
                        minWidth: 150,
                        maxWidth: 300,
                    },
                    {
                        title: '收入比例',
                        key: 'incomeRate',
                        minWidth: 150,
                        maxWidth: 300,
                    },
                    {
                        title: '主营成本(元)',
                        key: 'mainCost',
                        minWidth: 150,
                        maxWidth: 300,
                    },
                    {
                        title: '成本比例',
                        key: 'costRate',
                        minWidth: 150,
                        maxWidth: 300,
                    },
                    {
                        title: '主营利润(元)',
                        key: 'mainProfit',
                        minWidth: 150,
                        maxWidth: 300,
                    },
                    {
                        title: '利润比例',
                        key: 'profitRate',
                        minWidth: 150,
                        maxWidth: 300,
                    },
                    {
                        title: '毛利率(%)',
                        key: 'gross',
                        minWidth: 150,
                        maxWidth: 300,
                    },
                ],
                data: [
                    // {
                    // quarterTime: "",
                    // industry: [//行业
                    //     // {
                    //     //     subTitle: "零售金融业务",
                    //     //     mainIncome: "",
                    //     //     incomeRate: "",
                    //     //     mainCost: "",
                    //     //     costRate: "",
                    //     //     mainProfit: "",
                    //     //     profitRate: "",
                    //     //     gross: "",
                    //     // }
                    // ],
                    // product: [],//产品
                    // area: []//地区

                    // }
                ]
            },
            chainLoanTb: {//担保借贷
                columns: [
                    {
                        title: '担保方',
                        key: 'cautioner',
                    },
                    {
                        title: '被担保方',
                        key: 'beCautioner',
                        minWidth: 200
                    },
                    {
                        title: '担保方式',
                        key: 'way',
                    },
                    {
                        title: '担保金额(万元)',
                        key: 'money',
                    },
                    {
                        title: '币种',
                        key: 'moneyType',
                    },
                    {
                        title: '担保期限(年)',
                        key: 'limit',
                    },
                    {
                        title: '担保起始日',
                        key: 'startDay',
                    },
                    {
                        title: '担保终止日',
                        key: 'endDay',
                    },
                    {
                        title: '是否履行完毕',
                        key: 'isFinish',
                    },
                    {
                        title: '是否关联交易',
                        key: 'isExchange',
                    },
                    {
                        title: '交易日期',
                        key: 'exchangeDate',
                    },
                    {
                        title: '担保事件说明',
                        key: 'guarEvent',
                    },
                ],
                data: [
                    // {
                    //     cautioner: '2018',
                    //     beCautioner: 'John Brown',
                    //     way: "32",
                    //     money: "10%",
                    //     moneyType: "币种1",
                    //     limit: "",
                    //     startDay: "",
                    //     endDay: "",
                    //     isFinish: "",
                    //     isExchange: "",
                    //     exchangeDate: "",
                    //     guarEvent: "",
                    //     explanasion: "",
                    // },
                ]
            },
            treeData: {//数结构数据
                // "name": "供应链",
                // "children": [
                //     {
                //         "name": "主要供应商/供应链上游",//主要客户供应链下游
                //         "direction": "upstream",//downstream
                //         "children": [
                //             {
                //                 "direction": "upstream",
                //                 "name": "万科"
                //             },
                //         ]
                //     },
                // ]
            }
        }
    }

    componentDidMount() {
        this.getData()
    }

    componentWillReceiveProps(nextProps) {
        let newCurrentYear = nextProps.currentYear
        let newTickerSymbol = nextProps.tickerSymbol
        let newCompanyName = nextProps.companyName
        let newPageRequestParams = JSON.stringify(nextProps.pageRequestParams)
        let oldPageRequestParams = JSON.stringify(this.state.pageRequestParams)
        if (newCurrentYear != this.state.currentYear || newTickerSymbol !== this.state.tickerSymbol || newPageRequestParams != oldPageRequestParams || newCompanyName != this.state.companyName) {

            this.setState({
                currentYear: newCurrentYear,
                tickerSymbol: newTickerSymbol,
                companyName: newCompanyName,
                pageRequestParams: nextProps.pageRequestParams
            }, () => {
                this.getData()
            })
        }
    }

    componentWillUnmount() {
        let chainTreeMap = document.getElementById("chainTreeMap")
        chainTreeMap.innerHTML = null
        this.setState = () => {
            return;
        }
    }

    /**获取数据 */
    getData() {
        let tickerSymbol = this.state.tickerSymbol
        let year = this.state.currentYear
        let chainFollowTb = { ...this.state.chainFollowTb }
        let chainBelongTb = { ...this.state.chainBelongTb }
        let chainLoanTb = { ...this.state.chainLoanTb }
        let analyse = { ...this.state.analyse }
        let pageRequestParams = this.state.pageRequestParams
        let spinLoading = true
        let treeData = { "name": this.state.companyName ? this.state.companyName : "供应链" }
        let treeDataChildren = []

        if (!tickerSymbol || !year) {
            return;
        }

        this.setState({
            spinLoading
        }, () => {
            spinLoading = false
            let params = {
                tickerSymbol,
                year
            }
            if (pageRequestParams && pageRequestParams.pageType == "supplyChain" && pageRequestParams.fnType) {
                params.table = pageRequestParams.fnType
                params.data = pageRequestParams.paramData
            }
            webSdk.entersInforApi.getSupplyChainInfo(params).then(res => {
                let followTbList = res.chainFollowTb
                _.forEach(followTbList, item => {
                    _.forIn(item, (value, key) => {
                        if (!value) {
                            item[key] = "-"
                        }
                    })
                })
                chainFollowTb.data = followTbList

                let belongTbList = res.chainBelongTb
                _.forEach(belongTbList, item => {
                    _.forIn(item, (value, key) => {
                        if (!value) {
                            item[key] = "-"
                        }
                    })
                })
                chainBelongTb.data = belongTbList

                let loanTbList = res.chainLoanTb
                _.forEach(loanTbList, item => {
                    _.forIn(item, (value, key) => {
                        if (!value) {
                            item[key] = "-"
                        }
                    })
                })
                chainLoanTb.data = loanTbList

                //临时树数据
                //上游
                let belongTreeData = {
                    "name": "主要供应商/供应链上游",
                    "direction": "upstream",
                }
                let belongChildren = []
                _.forEach(belongTbList, item => {
                    belongChildren.push({
                        "direction": "upstream",
                        "name": item.supplier,
                        "point": item.point || false,//高亮展示
                    })
                })
                belongTreeData.children = belongChildren
                treeDataChildren.push(belongTreeData)
                //下游
                let followTreeData = {
                    "name": "主要供应商/供应链下游",
                    "direction": "downstream",
                }
                let followChildren = []
                _.forEach(followTbList, item => {
                    followChildren.push({
                        "direction": "downstream",
                        "name": item.userName,
                        "point": item.point || false,//高亮展示
                    })
                })
                followTreeData.children = followChildren
                treeDataChildren.push(followTreeData)

                treeData.children = treeDataChildren
                if (_.isEmpty(belongChildren) && _.isEmpty(followChildren)) {
                    treeData = []
                }

                let analyseData = res.analyse
                _.forEach(analyseData, analyseItem => {
                    _.forIn(analyseItem, analyseVal => {
                        _.forEach(analyseVal, item => {
                            _.forIn(item, (value, key) => {
                                if (!value) {
                                    item[key] = "-"
                                }
                            })
                        })
                    })
                })
                analyse.data = analyseData

                this.setState({
                    spinLoading, chainFollowTb, chainBelongTb, chainLoanTb, analyse, treeData
                }, () => {
                    this.createTreeMap()
                    this.toPagePoint()
                })
            })
        })
    }

    /**到页面指定位置 */
    toPagePoint() {
        let pageRequestParams = this.state.pageRequestParams
        if (!pageRequestParams || pageRequestParams.pageType != "supplyChain") return;
        let fnType = pageRequestParams.fnType
        let fnId = ""
        switch (fnType) {
            case "downstream":
            case "upstream":
                fnId = "chainTreeMap"
                break;
            case "businessAnalyse":
                fnId = "analyseBlock"
                break;
            case "guarantee":
                fnId = "chainLoanTbBlock"
                break;
        }

        if (fnId) {
            this.showHightSpot(fnId)
        }
    }

    /**展示高亮块 */
    showHightSpot(id) {
        let pageRequestParams = this.state.pageRequestParams
        if (!pageRequestParams || pageRequestParams.pageType != "supplyChain") return;
        let block = document.getElementById(id)
        if (!block) return;
        let topVal = block.offsetTop
        this.props.fnChangeScroll(topVal)

    }

    /**创建树结构 */
    createTreeMap() {
        let supplyChain = document.getElementById("supplyChain")
        let chainTreeMap = document.getElementById("chainTreeMap")
        chainTreeMap.innerHTML = null

        let treeData = this.state.treeData
        if (!_.isEmpty(treeData)) {
            let params = {
                data: this.state.treeData,
                width: supplyChain.offsetWidth
            }
            new TreeMap("chainTreeMap", params)
        }
    }

    render() {
        return (
            <div id="supplyChain" className="supplychain">

                <div id="chainTreeMap" className="chain-treemap"></div>

                {/* <div className="chain-following">
                    <h3>主要客户供应链下游</h3>
                    <div className="chain-following-tb">
                        <div>
                            <table>
                                <thead>
                                    <tr>
                                        {
                                            this.state.chainFollowTb.columns.map((item, idx) => (
                                                <th
                                                    key={item.key}
                                                    style={{
                                                        minWidth: `${item.minWidth}px`
                                                    }}
                                                >{item.title}</th>
                                            ))
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        _.isEmpty(this.state.chainFollowTb.data) || this.state.spinLoading ? (
                                            <tr>
                                                <td className="empty" colSpan="4">
                                                    <Spin spinning={this.state.spinLoading}>
                                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                    </Spin>
                                                </td>
                                            </tr>
                                        ) : this.state.chainFollowTb.data.map((item, idx) => (
                                            <tr key={idx}>
                                                {
                                                    this.state.chainFollowTb.columns.map((colItem, colIdx) => {
                                                        let key = colItem.key
                                                        let value = item[key]
                                                        return (
                                                            <td key={colItem.key}>{value}</td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="chain-belong">
                    <h3>主要供应商/供应链上游</h3>
                    <div className="chain-belong-tb">
                        <div>
                            <table>
                                <thead>
                                    <tr>
                                        {
                                            this.state.chainBelongTb.columns.map((item, idx) => (
                                                <th
                                                    key={item.key}
                                                    style={{
                                                        minWidth: `${item.minWidth}px`
                                                    }}
                                                >{item.title}</th>
                                            ))
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        _.isEmpty(this.state.chainBelongTb.data) || this.state.spinLoading ? (
                                            <tr>
                                                <td className="empty" colSpan="4">
                                                    <Spin spinning={this.state.spinLoading}>
                                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                    </Spin>

                                                </td>
                                            </tr>
                                        ) : this.state.chainBelongTb.data.map((item, idx) => (
                                            <tr key={idx}>
                                                {
                                                    this.state.chainBelongTb.columns.map((colItem, colIdx) => {
                                                        let key = colItem.key
                                                        let value = item[key]
                                                        return (
                                                            <td key={colItem.key}>{value}</td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div> */}

                <div id="analyseBlock" className="chain-analyse">
                    <h3>主要构成分析</h3>
                    <div className="chain-analyse-tb">
                        <div>
                            {
                                _.isEmpty(this.state.analyse.data) || this.state.spinLoading ? (
                                    <table>
                                        <thead>
                                            <tr>
                                                {
                                                    this.state.analyse.columns.map((colItem, colIdx) => {
                                                        return <th
                                                            key={colIdx}
                                                            style={{
                                                                minWidth: `${colItem.minWidth}px`,
                                                                maxWidth: `${colItem.maxWidth}px`
                                                            }}
                                                        >
                                                            {colItem.title}</th>
                                                    })
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="empty" colSpan="7">
                                                    <Spin spinning={this.state.spinLoading}>
                                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                    </Spin>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                ) : (
                                        <table>
                                            <tbody>
                                                {
                                                    this.state.analyse.data.map((analyseItem, analyseIdx) => {
                                                        return ([
                                                            <tr key={analyseIdx} className="row-title">
                                                                {

                                                                    this.state.analyse.columns.map(colItem => {
                                                                        return <td
                                                                            key={colItem.key}
                                                                            style={{
                                                                                minWidth: `${colItem.minWidth}px`,
                                                                                maxWidth: `${colItem.maxWidth}px`
                                                                            }}
                                                                        >
                                                                            {colItem.title}</td>
                                                                    })
                                                                }
                                                            </tr>,
                                                            analyseItem.industry.map((item, idx) => {
                                                                let mainTitle = null
                                                                if (idx === 0) {
                                                                    let count = analyseItem.industry.length
                                                                    // mainTitle = <td className="col-title" rowSpan={count}>按行业分类</td>
                                                                    mainTitle = <td key={idx} className="col-title" rowSpan={count}>{analyseItem.quarterTime}</td>
                                                                }
                                                                return (
                                                                    <tr key={idx}
                                                                        className={idx == analyseItem.industry.length - 1 ? "table-last" : ""}
                                                                    >
                                                                        {
                                                                            this.state.analyse.columns.map(colItem => {
                                                                                let key = colItem.key
                                                                                let value = item[key]

                                                                                if (key == "quarterTime")
                                                                                    return (mainTitle);
                                                                                if (key == "subTitle")
                                                                                    return <td
                                                                                        key={key}
                                                                                        className="col-title"
                                                                                    >{value}</td>

                                                                                return (
                                                                                    <td key={key}>{value}</td>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tr>
                                                                )
                                                            }),
                                                            analyseItem.product.map((item, idx) => {
                                                                let mainTitle = null
                                                                if (idx === 0) {
                                                                    let count = analyseItem.product.length
                                                                    // mainTitle = <td className="col-title" rowSpan={count}>按产品分类</td>
                                                                    mainTitle = <td key={idx}
                                                                        className="col-title"
                                                                        rowSpan={count}>
                                                                        {analyseItem.quarterTime}</td>
                                                                }
                                                                return (
                                                                    <tr key={idx}
                                                                        className={idx == analyseItem.industry.length - 1 ? "table-last" : ""}
                                                                    >
                                                                        {
                                                                            this.state.analyse.columns.map(colItem => {
                                                                                let key = colItem.key
                                                                                let value = item[key]

                                                                                if (key == "quarterTime")
                                                                                    return (mainTitle);
                                                                                if (key == "subTitle")
                                                                                    return <td
                                                                                        key={key}
                                                                                        className="col-title"
                                                                                    >{value}</td>

                                                                                return (
                                                                                    <td key={key}>{value}</td>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tr>
                                                                )
                                                            }),
                                                            analyseItem.area.map((item, idx) => {
                                                                let mainTitle = null
                                                                if (idx === 0) {
                                                                    let count = analyseItem.area.length
                                                                    // mainTitle = <td className="col-title" rowSpan={count}>按地区分类</td>
                                                                    mainTitle = <td
                                                                        key={idx}
                                                                        className="col-title"
                                                                        rowSpan={count}>
                                                                        {analyseItem.quarterTime}</td>
                                                                }
                                                                return (
                                                                    <tr
                                                                        key={idx}
                                                                        className={idx == analyseItem.industry.length - 1 ? "table-last" : ""}
                                                                    >
                                                                        {
                                                                            this.state.analyse.columns.map(colItem => {
                                                                                let key = colItem.key
                                                                                let value = item[key]

                                                                                if (key == "quarterTime")
                                                                                    return (mainTitle);
                                                                                if (key == "subTitle")
                                                                                    return <td
                                                                                        key={key}
                                                                                        className="col-title"
                                                                                    >{value}</td>

                                                                                return (
                                                                                    <td key={key}>{value}</td>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tr>
                                                                )
                                                            })
                                                        ])
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    )
                            }
                        </div>
                    </div>
                </div>

                <div id="chainLoanTbBlock" className="chain-loan">
                    <h3>担保借贷</h3>
                    <div className="chain-loan-tb">
                        <div>
                            <div>
                                <table>
                                    <thead>
                                        <tr>
                                            {
                                                this.state.chainLoanTb.columns.map((item, idx) => (
                                                    <th
                                                        key={item.key}
                                                        style={{
                                                            minWidth: `${item.minWidth}px`
                                                        }}
                                                    >{item.title}</th>
                                                ))
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            _.isEmpty(this.state.chainLoanTb.data) || this.state.spinLoading ? (
                                                <tr>
                                                    <td className="empty" colSpan="7">
                                                        <Spin spinning={this.state.spinLoading}>
                                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                        </Spin>
                                                    </td>
                                                </tr>
                                            ) : this.state.chainLoanTb.data.map((item, idx) => (
                                                <tr key={idx}>
                                                    {
                                                        this.state.chainLoanTb.columns.map((colItem, colIdx) => {
                                                            let key = colItem.key
                                                            let value = item[key]
                                                            return (
                                                                <td key={colItem.key}>{value}</td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default SupplyChain;