import React from "react";
import {Input,Icon} from "antd";
import "./AddTagComponent.scss"

let inputRef = null;
function AddTagComponent(props) {

    let {
        list,       // 当前列表
        setList,    // 设置列表方法
        item,       // 当前组件所在的列表中的对象
        index,      // 当前组件在列表中的index
        // inputRef,   // 当前组件input框ref
        placeholder // 输入框默认提示
    } = props;

    // 点击添加按钮
    const changeAddStatus = () => {
        let newList = [...list];
        newList[index].addStatus = true;
        setList(newList);
        setTimeout(() => {
            inputRef.focus();
        }, 10)
    };
    // 新增标签输入框回车
    const addInputKeyUp = ev => {
        let keyCode = ev.keyCode;
        if (keyCode === 13) {
            setListValue(ev)
        }
    };
    // 新增标签输入框失去焦点
    const addInputBlur = ev => {
        setListValue(ev)
    };

    const setListValue = (ev) =>{
        let value = ev.target.value;
        let newList = [...list];
        if (value !== '') {
            if (newList[index].value) {
                newList[index].value.push(value);
            } else if (newList[index].tags) {
                newList[index].tags.push(value);
            }
        }
        newList[index].addStatus = false;
        setList(newList);
    };

    return (
        <li className="add-tag">
            {
                item["addStatus"] ? (
                    <Input className="input-status"
                           placeholder={placeholder}
                           onKeyUp={ev => addInputKeyUp(ev)}
                           onBlur={ev => addInputBlur(ev)}
                           ref={el => inputRef = el}/>
                ) : (
                    <div className="btn-status"
                         onClick={changeAddStatus}>
                        <Icon type="plus" className="add-icon"/>添加
                    </div>
                )
            }
        </li>
    )
}

export default AddTagComponent
