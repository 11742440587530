import React from 'react'
import {webSdk} from "../../../../../../../api/WebSdk";
import BarAndGraph from "../../../../../../graphComponent/BarAndGraph";
import {Table, DatePicker,Icon ,Spin, Select } from 'antd';
import './FinancialCaseSheet.scss'

const { RangePicker } = DatePicker;
const { Option } = Select;

class FinancialCaseSheet extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            keyword: props.keyword || '',
            choiceTime: '',       //当前选择的时间
            selectTime: [
                {
                    label: '2013年',
                    key: '2013'
                },
                {
                    label: '2014年',
                    key: '2014'
                },
                {
                    label: '2015年',
                    key: '2015'
                },
                {
                    label: '2016年',
                    key: '2016'
                },
                {
                    label: '2017年',
                    key: '2017'
                },
                {
                    label: '2018年',
                    key: '2018'
                }
            ],
            pieItem: [
                {
                    title: '经营活动产生的现金流量',
                    key: 'businessData'
                },
                {
                    title: '投资活动产生的现金流量',
                    key: 'investmentData'
                },
                {
                    title: '筹资活动产生的现金流量',
                    key: 'raiseData'
                }
            ],
            businessData: {},
            investmentData: {},
            raiseData: {},
            //表格表头
            columns: [
                {
                    "title": "项目",
                    "dataIndex": "project",
                    //"align": "center"
                },
                {
                    "title": "本期金额",
                    "dataIndex": "currentAmount",
                    "align": "center"
                },
                {
                    "title": "上期金额",
                    "dataIndex": "lastAmount",
                    "align": "center"
                },
                /*{
                    "title": "补充项目",
                    "dataIndex": "supplementProject",
                    //"align": "center"
                },
                {
                    "title": "金额",
                    "dataIndex": "amount",
                    "align": "center"
                }*/
            ],
            tableTitle: '',
            //左侧表格数据
            tableData: [],
            //右侧表格数据
            supplementTableData: []
        }
        this.getBaseInfo = this.getBaseInfo.bind(this);
    }

    componentDidMount() {
        this.getBaseInfo()
    }

    componentWillReceiveProps(nextProps) {

    }

    /**
     * 获取页面信息
     */
    getBaseInfo() {
        const _this = this;
        let postData = {
            style: 'caseSheet',    //当前菜单
            p: this.state.keyword, //当前搜索词
            time: this.state.choiceTime,               //当前筛选的起止时间
        };

        webSdk.search.getFinancialInfo(postData).then(res => {
            //格式化pie图像数据
            this.setState({
                businessData: setOptions(res.business,'business'),
                investmentData: setOptions(res.investment,'investment'),
                raiseData: setOptions(res.raise,'raise'),
                tableData: res.tableData,
                tableTitle: res.tableTitle
            })

            function setOptions(data,type) {
                let showLegend = {};
                let color = [];
                switch (type) {
                    case 'business':
                        color = ['#78ACFC','#2371FA'];
                        break;
                    case 'investment':
                        color = ['#FFEAA7','#FECB2F'];
                        break;
                    case 'raise':
                        color = ['#8D2CD5','#C67AFF'];
                        break;
                    default:
                        color = ['#8D2CD5','#C67AFF'];
                }
                return {
                    tooltip: {
                        trigger: 'item',
                        formatter: "{b} : {c} 亿元({d}%)"
                    },
                    legend: {
                        orient: 'horizontal',
                        right: 'right',
                        top: 'middle',
                        data: legendData(data),
                        selected: showLegend,
                        icon: "circle",
                        formatter: name =>{
                            let total = 0;
                            let currentVal = 0;
                            for (let i=0;i<data.length;i++) {
                                total += parseFloat(data[i].value);
                                if (data[i].name === name) {
                                    currentVal = parseFloat(data[i].value)
                                }
                            }
                            let val = ((currentVal/total)*100).toFixed(2) + '%'
                            return `${name}\n${currentVal}亿元\n${val}`
                        }
                    },
                    series: [
                        {
                            type: 'pie',
                            radius: ['60%','80%'],
                            center: ['30%', '50%'],
                            data: data,
                            label: {
                                show: false,
                                //position: 'inside'
                            },
                            itemStyle: {
                                emphasis: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            },
                            animationDelay(idx) {
                                return idx * 100
                            },
                            animationDelayUpdate(idx) {
                                return idx * 50
                            },
                        }
                    ],
                    color: color
                };

                function legendData(data) {
                    _this.sortData(data,'value');
                    let nameData = []
                    for (let i = 0; i < data.length; i++) {
                        nameData.push(data[i].name);
                        showLegend[data[i].name] = i <= 3;
                    }
                    return nameData
                }
            }
        })
    }

    /**
     * 数据排序处理
     * @param data：当前的所有数据
     * @param sortKey：排序关键词
     * @param type：排序方式：  down：降序，up：升序
     */
    sortData(data,sortKey,type="down"){
        if (type==='down') {
            for (let i = 0; i < data.length; i++) {
                for (let j = 0; j < data.length - 1 - i; j++) {
                    if (parseFloat(data[j][sortKey]) < parseFloat(data[j+1][sortKey])) {        //相邻元素两两对比
                        let temp = data[j+1];        //元素交换
                        data[j+1] = data[j];
                        data[j] = temp;
                    }
                }
            }
        }
        else if (type === 'up') {
            for (let i = 0; i < data.length; i++) {
                for (let j = 0; j < data.length - 1 - i; j++) {
                    if (parseFloat(data[j][sortKey]) > parseFloat(data[j+1][sortKey])) {        //相邻元素两两对比
                        let temp = data[j+1];        //元素交换
                        data[j+1] = data[j];
                        data[j] = temp;
                    }
                }
            }
        }

    }

    /*choiceTime(date, dateString){
        this.setState({
            choiceTime: dateString[0] + '-' + dateString[1],
            businessData: {},
            investmentData: {},
            raiseData: {},
            tableData: []
        },() =>{
            this.getBaseInfo();
        })
    }*/
    choiceTime(value) {
        this.setState({
            choiceTime: value,
            businessData: {},
            investmentData: {},
            raiseData: {},
            tableData: []
        },() =>{
            this.getBaseInfo();
        })
    }

    render() {
        return (
            <div className="financial-case-sheet">
                <div className="caseSheet-time-choice">
                    <span>选择统计时间</span>
                    <Select style={{ width: 120,marginLeft: 10 }} defaultValue={this.state.selectTime[this.state.selectTime.length-1].key} onChange={this.choiceTime.bind(this)}>
                        {
                            this.state.selectTime.map((item,index) =>{
                                return (
                                    <Option key={index} value={item.key}>{item.label}</Option>
                                )
                            })
                        }
                    </Select>
                    {/*<RangePicker style={{width: 220,height: 32,marginLeft: 10}} size="default" format='YYYY/MM/DD' placeholder={['开始','结束']} onChange={this.choiceTime.bind(this)}/>*/}
                </div>
                <div className="case-sheet-pie">
                    {
                        this.state.pieItem.map((item, index) => {
                            return (
                                <div key={index} className="pie-item">
                                    <div className="pie-item-title">{item.title}</div>
                                    {
                                        !this.state[item.key] || JSON.stringify(this.state[item.key]) === '{}'?(<Spin size="large"/>):''
                                    }
                                    <div>
                                        {
                                            this.state[item.key] && JSON.stringify(this.state[item.key]) !== '{}' ? (
                                                <BarAndGraph options={this.state[item.key]} minHeight={183}/>
                                            ) : ''
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="case-sheet-table">
                    <div className="header-title">
                        现金流量表
                        <span className="download-table-button">下载报表<Icon className="download-icon" type="cloud-download" /></span>
                    </div>
                    <div className="table-title">{this.state.tableTitle}<span className="table-title-unit">（单位：元）</span></div>
                    <div className="table-main">
                        <Table
                            columns={this.state.columns}
                            dataSource={this.state.tableData}
                            rowClassName={(record, index) => {
                                let row = index % 2 === 0 ? 'first-row' : 'second-row';
                                let color = record.setColor ? ' red-color-style' : '';
                                let level = ` level-${record.level}`;
                                return  row + color + level
                            }}
                            rowKey={(record, index) => {
                                return index
                            }}
                            pagination={false}
                        />
                    </div>

                </div>
            </div>
        )
    }
}

export default FinancialCaseSheet