import React from 'react'
import './Error404.css'

class Error404 extends React.Component {
    render() {
        return (
        <div className="Error404">
            页面未找到!
        </div>
        );
    }
}
  
export default Error404;
  