/**
 * 参考文档 @see https://github.com/axios/axios
 * Example:
 * Get Request: axios.get(url, {params: {k:v}})
 * Post Json: axios.post(url, {k:v})
 * Post Form: axios.post(url, URLSearchParams)
 *  URLSearchParams: https://developer.mozilla.org/zh-CN/docs/Web/API/URLSearchParams
 *  参考：AxiosTest.vue queryIp
 */
import _ from 'lodash'
import axiosCreate from './axiosCreate'
import { message } from 'antd'
import { webSdk } from './WebSdk'

window.reLoginFlag = false

const reLogin = () => {
  if (window.reLoginFlag) return
  window.reLoginFlag = true
  let userName = window.localStorage.getItem('userName') || ''
  let passWord = window.localStorage.getItem('passWord') || ''
  if (userName && passWord) {
    let postData = {
      username: userName,
      pwd: passWord
    }
    webSdk.search
      .doLogin(postData)
      .then((res) => {
        window.reLoginFlag = false
        if (!res.errCode || res.errCode === 0) {
          window.sessionStorage.setItem('hasLogin', '1')
          window.localStorage.setItem('userInfo', JSON.stringify(res.user))
          window.location.reload() //刷新页面
        } else {
          window.sessionStorage.setItem('hasLogin', '')
          window.localStorage.setItem('userInfo', '')
          window.location.href = '/login'
          message.error(res.errMsg || '登录失败，请重新登录')
        }
      })
      .catch((e) => {
        window.reLoginFlag = false
        window.sessionStorage.setItem('hasLogin', '')
        window.localStorage.setItem('userInfo', '')
        window.location.href = '/login'
        message.error('服务器繁忙，请重新登录')
      })
  } else {
    window.reLoginFlag = false
    window.sessionStorage.setItem('hasLogin', '')
    window.location.href = '/login'
    message.info('未登录')
  }
}

class ApiGate {
  constructor(opts = {}) {
    let { errorHandler } = opts
    this.errorHandler = errorHandler || console.log.bind(console, 'Api Error: ')
  }

  /* 本地模拟数据 */
  fake = axiosCreate({
    baseURL: '/mockData/'
  })

  search = axiosCreate({
    //baseURL: '/api/search/',
    baseURL: '/api/',
    transformResponse: [
      function (data) {
        try {
          data = JSON.parse(data)
        } catch (e) {
          console.log('transformResponse error', data, e)
        }
        let errCode = _.get(data, 'errCode')
        let _log = _.get(data, '_log')
        if (errCode) {
          let errMsg = _.get(data, 'errMsg')
          console.log(errMsg, _log || '')
          // throw data;
        }
        _log && console.log('api log:', _log)

        if (errCode === 100) {
          /*message.error('未登录',5);
                window.localStorage.setItem('eiLogin', '');
                window.localStorage.setItem('userInfo', '');
                setTimeout(() =>{
                    window.location.href = '/login'
                },800)*/
          reLogin()

          return
        }
        if (errCode === 102) {
          message.error('无此模块权限')
          window.location.href = '/'
          return
        }
        return errCode === 0 ? data.data : data
      }
    ]
  })
  searchAida2 = axiosCreate({
    baseURL: '/aida2-api/',
    transformResponse: [
      function (data) {
        try {
          data = JSON.parse(data)
        } catch (e) {
          console.log('transformResponse error', data, e)
        }
        let errCode = _.get(data, 'errCode')
        let _log = _.get(data, '_log')
        if (errCode) {
          let errMsg = _.get(data, 'errMsg')
          console.log(errMsg, _log || '')
          // throw data;
        }
        _log && console.log('api log:', _log)

        if (errCode === 100) {
          /*message.error('未登录',5);
                window.localStorage.setItem('eiLogin', '');
                window.localStorage.setItem('userInfo', '');
                setTimeout(() =>{
                    window.location.href = '/login'
                },800)*/
          reLogin()

          return
        }
        if (errCode === 102) {
          message.error('无此模块权限')
          window.location.href = '/'
          return
        }
        return errCode === 0 ? data.data : data
      }
    ]
  })

  searchV2 = axiosCreate({
    //baseURL: '/api/search/',
    baseURL: '/api_v2/',
    transformResponse: [
      function (data) {
        try {
          data = JSON.parse(data)
        } catch (e) {
          console.log('transformResponse error', data, e)
        }
        let errCode = _.get(data, 'errCode')
        let _log = _.get(data, '_log')
        if (errCode) {
          let errMsg = _.get(data, 'errMsg')
          console.log(errMsg, _log || '')
          // throw data;
        }
        _log && console.log('api log:', _log)

        if (errCode === 100) {
          /*message.error('未登录',5);
                window.localStorage.setItem('eiLogin', '');
                window.localStorage.setItem('userInfo', '');
                setTimeout(() =>{
                    window.location.href = '/login'
                },800)*/
          reLogin()

          return
        }
        if (errCode === 102) {
          message.error('无此模块权限')
          window.location.href = '/'
          return
        }
        return errCode === 0 ? data.data : data
      }
    ]
  })

  kgSearch = axiosCreate({
    baseURL: '/api_v2/',
    transformResponse: [
      function (data) {
        try {
          data = JSON.parse(data)
        } catch (e) {
          console.log('transformResponse error', data, e)
        }
        let errCode = _.get(data, 'errCode')
        let _log = _.get(data, '_log')
        if (errCode) {
          let errMsg = _.get(data, 'errMsg')
          console.log(errMsg, _log || '')
          // throw data;
        }
        _log && console.log('api log:', _log)

        if (errCode === 100) {
          reLogin()

          return
        }
        if (errCode === 102) {
          message.error('无此模块权限')
          window.location.href = '/'
          return
        }
        return errCode === 0 ? data.data : data
      }
    ]
  })

  subjectGraph = axiosCreate({
    baseURL: '/api-subject-kg/',
    transformResponse: [
      function (data) {
        try {
          data = JSON.parse(data)
        } catch (e) {
          console.log('transformResponse error', data, e)
        }
        let errCode = _.get(data, 'errCode')
        let _log = _.get(data, '_log')
        if (errCode) {
          let errMsg = _.get(data, 'errMsg')
          console.log(errMsg, _log || '')
          // throw data;
        }
        _log && console.log('api log:', _log)

        if (errCode === 100) {
          /*message.error('未登录',5);
                window.localStorage.setItem('eiLogin', '');
                window.localStorage.setItem('userInfo', '');
                setTimeout(() =>{
                    window.location.href = '/login'
                },800)*/
          reLogin()

          return
        }
        if (errCode === 102) {
          message.error('无此模块权限')
          window.location.href = '/'
          return
        }
        return errCode === 0 ? data.data : data
      }
    ]
  })

  policySearch = axiosCreate({
    baseURL: '/api-policy/',
    transformResponse: [
      function (data) {
        try {
          data = JSON.parse(data)
        } catch (e) {
          console.log('transformResponse error', data, e)
        }
        let errCode = _.get(data, 'errCode')
        let _log = _.get(data, '_log')
        if (errCode) {
          let errMsg = _.get(data, 'errMsg')
          console.log(errMsg, _log || '')
          // throw data;
        }
        _log && console.log('api log:', _log)

        if (errCode === 100) {
          /*message.error('未登录',5);
                window.localStorage.setItem('eiLogin', '');
                window.localStorage.setItem('userInfo', '');
                setTimeout(() =>{
                    window.location.href = '/login'
                },800)*/
          reLogin()

          return
        }
        if (errCode === 102) {
          message.error('无此模块权限')
          window.location.href = '/'
          return
        }
        return errCode === 0 ? data.data : data
      }
    ]
  })

  financeSub = axiosCreate({
    //baseURL: '/api/search/',
    baseURL: '/api-finance-corp/',
    transformResponse: [
      function (data) {
        try {
          data = JSON.parse(data)
        } catch (e) {
          console.log('transformResponse error', data, e)
        }
        let errCode = _.get(data, 'errCode')
        let _log = _.get(data, '_log')
        if (errCode) {
          let errMsg = _.get(data, 'errMsg')
          console.log(errMsg, _log || '')
          // throw data;
        }
        _log && console.log('api log:', _log)

        if (errCode === 100) {
          /*message.error('未登录',5);
                window.localStorage.setItem('eiLogin', '');
                window.localStorage.setItem('userInfo', '');
                setTimeout(() =>{
                    window.location.href = '/login'
                },800)*/
          reLogin()

          return
        }
        if (errCode === 102) {
          message.error('无此模块权限')
          window.location.href = '/'
          return
        }
        return errCode === 0 ? data.data : data
      }
    ]
  })
  searchE = axiosCreate({
    baseURL: '/api-finance-corp/',
    transformResponse: [
      function (data) {
        try {
          data = JSON.parse(data)
        } catch (e) {
          console.log('transformResponse error', data, e)
        }
        let errCode = _.get(data, 'errCode')
        let _log = _.get(data, '_log')
        if (errCode) {
          let errMsg = _.get(data, 'errMsg')
          console.log(errMsg, _log || '')
          // throw data;
        }
        _log && console.log('api log:', _log)

        if (errCode === 100) {
          /*message.error('未登录',5);
                window.localStorage.setItem('eiLogin', '');
                window.localStorage.setItem('userInfo', '');
                setTimeout(() =>{
                    window.location.href = '/login'
                },800)*/
          reLogin()

          return
        }
        if (errCode === 102) {
          message.error('无此模块权限')
          window.location.href = '/'
          return
        }
        return errCode === 0 ? data.data : data
      }
    ]
  })
  apiCenter = axiosCreate({
    baseURL: '/api-center/',
    transformResponse: [
      function (data) {
        try {
          data = JSON.parse(data)
        } catch (e) {
          console.log('transformResponse error', data, e)
        }
        let errCode = _.get(data, 'errCode')
        let _log = _.get(data, '_log')
        if (errCode) {
          let errMsg = _.get(data, 'errMsg')
          console.log(errMsg, _log || '')
          // throw data;
        }
        _log && console.log('api log:', _log)

        if (errCode === 100) {
          /*message.error('未登录',5);
                window.localStorage.setItem('eiLogin', '');
                window.localStorage.setItem('userInfo', '');
                setTimeout(() =>{
                    window.location.href = '/login'
                },800)*/
          reLogin()

          return
        }
        if (errCode === 102) {
          message.error('无此模块权限')
          window.location.href = '/'
          return
        }
        return errCode === 0 ? data.data : data
      }
    ]
  })
}

global.ApiGate = ApiGate

export default ApiGate
