import React from 'react'
import _ from 'lodash'
import {message, Spin} from "antd"
import {webSdk} from "../../../../../api/WebSdk";
import LeftMind from "./LeftMind";
import RightTabs from "../resultCommon/RightCommon/Index";
import './index.scss'

const formatOriginData = (list, keys, keyIndex = 0, parentList = [], number) => {
    let curKey = keys[keyIndex];
    let result = [];
    for (let i = 0; i < list.length; i++) {
        let item = list[i];
        if (_.isEmpty(item)) continue;
        let temp = {
            key: curKey.key,
            keyLabel: curKey.label,
            label: item[curKey.key],
            parentList,
            requested: false
        };
        let _id = '';
        if (!_.isEmpty(parentList)) {
            if (number !== undefined) {
                _id = parentList[parentList.length - 1]._id + '_' + (i + number);
            } else {
                _id = parentList[parentList.length - 1]._id + '_' + i;
            }
        } else {
            _id = i+'';
        }
        temp._id = _id;
        if (curKey.key === 'relation') {
            temp.count = item.count;
        } else if (curKey.key === 'time') {
            let str = '';
            if (item.year) str += `${item.year}年`;
            if (item.month) str += `${item.month}月`;
            if (item.date) str += `${item.date}日`;
            temp.label = str;
            temp._time = item;
        }
        if (item[keys[keyIndex + 1].key]) {
            if (Object.prototype.toString.call(item[keys[keyIndex + 1].key]) === '[object Array]' && item[keys[keyIndex + 1].key].length) {
                temp.requested = true;
                let newParent = [...parentList];
                newParent.push({
                    key: curKey.key,
                    keyLabel: curKey.label,
                    value: item[curKey.key],
                    _id: _id
                });
                temp.children = formatOriginData(item[keys[keyIndex + 1].key], keys, keyIndex + 1, newParent)
            }
        }
        result.push(temp)
    }
    return result;
}

/**
 * 点击节点，获取数据后处理数据
 * @param res   请求到的数据
 *              {
 *                  kg_list: [],
 *                  kg_type: 'eav',
 *                  source: {}
 *              }
 * @param oldSourceData 原始学习来源数据{}
 * @param treeData
 * @param curNode
 * @param artIdTotal
 * @param sourceList
 */
const formatNodeClickData = (res, oldSourceData, treeData, curNode, artIdTotal = {}) => {
    let list = res.kg_list || [];
    let keyList = [
        {
            key: 'time',
            label: '时间'
        },
        {
            key: 'value',
            label: '值'
        }
    ];
    if (res.kg_type === 'eav') {
        if (curNode.key === 'time') {
            keyList = [
                {
                    key: 'date',
                    label: '日期'
                },
                {
                    key: 'value',
                    label: '值'
                }];
        }
        list.forEach(item => {
            item._node = {...item};
            item._entity = item.entity;
            item._attribute = item.attribute;
            item._time = item.time;
            item._value = item.value;
            //item.entity = item.entity.reduce((pre,cur) => cur.name);
            item.attribute = item.attribute.join(',');
            item.value = item.value.join(',');
            let newEntity = item.entity.map(entityItem => entityItem.name);
            item.entity = newEntity.join(',');
            item.date = item.time[0].month ? item.time[0].month + '月' : '' + item.time[0].date ? item.time[0].date + '日' : '';
            let newTime = item.time.map(timeItem => {
                let str = '';
                if (timeItem.year) str += `${timeItem.year}年`;
                if (timeItem.month) str += `${timeItem.month}月`;
                if (timeItem.date) str += `${timeItem.date}日`;
                return str
            })
            item.time = newTime.join(',');
        })

    } else if (res.kg_type === 'eae') {
        keyList = [
            {
                key: 'entity2',
                label: '实体2'
            }
        ];
        list.forEach(item => {
            item._node = {...item};
            item._entity = item.entity;
            item._relation = item.relation;
            item._entity2 = item.entity2;
            item.entity = item.entity.name;
            item.entity2 = item.entity2.name;
        })
    }
    let children = mergeChild(list, keyList, 0, curNode);
    deleteEmptyNode(children);
    // 集合所有文章来源节点数据，用于添加到点击节点及其父节点
    let artIds = [];
    if (Object.prototype.toString.call(children) === '[object Array]' && !_.isEmpty(children)) {
        artIds = setArtId(children, artIdTotal);
        artIds = artIds.map(item => {
            return 'p' + item
        })
    }
    // 查找树，点击节点及父节点做artIds的添加，子节点做parentList的添加
    findInTree(treeData, curNode, children, artIds);

    // 处理学习来源
    let sourceList = [];
    let source = res.source || {};
    oldSourceData = {...oldSourceData, ...source};       // 获取的所有学习来源数据
    for (let artId in artIdTotal) {
        if (oldSourceData[artId]) {
            let art = oldSourceData[artId];
            art.number = artIdTotal[artId];
            sourceList.push(art)
        }
    }
    return {sourceList, artIds, children}

    /** 合并子集 */
    function mergeChild(list, keys, keyIndex = 0, parentNode) {
        let resultList = [];
        if (Object.prototype.toString.call(list) === '[object Array]' && !_.isEmpty(list)) {
            const key = keys[keyIndex];
            let keyTemp = {};
            list.forEach(item => {
                if (keyTemp[item[key.key]]) {
                    keyTemp[item[key.key]].push(item);
                } else {
                    keyTemp[item[key.key]] = [item]
                }
            });
            let num = 0;
            for (let k in keyTemp) {
                let obj = {
                    key: key.key,
                    keyLabel: key.label,
                    label: keyTemp[k][0][key.key],
                    requested: true,
                    _id: parentNode._id + '_' + num
                    //children: []
                };
                num++;
                if (keys[keyIndex + 1]) {
                    obj.children = mergeChild(keyTemp[k], keys, keyIndex + 1, obj)
                } else {
                    keyTemp[k].forEach(item => {
                        if (obj._nodes) {
                            obj._nodes.push(item)
                        } else {
                            obj._nodes = [item]
                        }
                    })
                }
                resultList.push(obj);

            }
        }
        return resultList;
    }

    /** 将节点数据为空的子集上移一层 */
    function deleteEmptyNode(list) {
        if (Object.prototype.toString.call(list) === '[object Array]' && !_.isEmpty(list)) {
            for (let i=0;i<list.length;i++) {
                let children = list[i].children || [];
                if (list[i].label==='') {
                    list.splice(i,1);
                    i--;
                    children.forEach(item =>{
                        list.push(item)
                    })
                } else if (children.length>0) {
                    deleteEmptyNode(children)
                }
            }
        }
    }

    /** 查找原始树，找出点击节点添加artIds，并添加合并后的children */
    function findInTree(list, clickNode, children, artIds, index = 0) {
        if (Object.prototype.toString.call(list) === '[object Array]' && !_.isEmpty(list)) {
            let parentList = clickNode.parentList;
            let parent = parentList[index];
            for (let i = 0; i < list.length; i++) {
                let item = list[i];
                if (parent._id === item._id) {
                    let p = '';
                    for (let n = 0; n < parentList.length - index; n++) {
                        p += 'p'
                    }
                    let newArtIds = artIds.map(a => p + a);
                    if (typeof item.artIds === 'undefined') {
                        item.artIds = newArtIds;
                    } else {
                        item.artIds = item.artIds.concat(newArtIds)
                    }

                    if (parentList.length > index + 1) {
                        findInTree(item.children, clickNode, children, artIds, index + 1)
                    } else {
                        /** 如果是父级的最后一级，则对当前点击层做处理 */
                        let itemChildren = item.children || [];
                        for (let j = 0; j < itemChildren.length; j++) {
                            let child = item.children[j];
                            if (child._id === curNode._id) {
                                let newParent = [...parentList];
                                newParent.push({
                                    key: child.key,
                                    label: child.label,
                                    _id: child._id
                                })
                                addParentList(children, newParent);
                                child.requested = true;
                                child.loading = false;
                                child.children = children;
                                child.artIds = artIds;
                                break;
                            }
                        }
                    }
                }
            }
        }
    }

    /** 每个节点添加parentList */
    function addParentList(list, parentList) {
        list.forEach(item => {
            item.parentList = parentList;
            if (item.children && item.children.length) {
                let newParentList = [...parentList];
                newParentList.push({
                    key: item.key,
                    label: item.label,
                    _id: item._id
                })
                addParentList(item.children, newParentList)
            }
        })
    }

    /** 添加art-id到节点 */
    function setArtId(list, artIdTotal) {
        let result = [];
        for (let i = 0; i < list.length; i++) {
            if (Object.prototype.toString.call(list[i].children) === '[object Array]' && !_.isEmpty(list[i].children)) {
                let artIds = setArtId(list[i].children, artIdTotal);
                let finalIds = [];
                for (let j = 0; j < artIds.length; j++) {
                    finalIds.push('p' + artIds[j])
                }
                list[i].artIds = finalIds;
                result = result.concat(finalIds);
            } else {
                if (list[i]._nodes && list[i]._nodes.length) {
                    let nodes = list[i]._nodes;
                    let artIds = [];
                    for (let j = 0; j < nodes.length; j++) {
                        artIds.push(nodes[j].art_id);
                    }
                    artIds = [...new Set(artIds)];

                    let finalIds = [];
                    for (let j = 0; j < artIds.length; j++) {
                        let artId = artIds[j];
                        if (artIdTotal[artId]) {
                            artIdTotal[artId]++;
                        } else {
                            artIdTotal[artId] = 1;
                        }
                        finalIds.push(artId + '_' + artIdTotal[artId])
                    }
                    list[i].artIds = finalIds;
                    result = result.concat(finalIds);
                }
            }
        }
        return result;
    }
}

class MindModuleIndex extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            tableData: [],      //获取到的总共数据（原始数据）
            eaeList: [],        //关系数据列表
            sourceList: [],         //右侧文章列表
            loading: false,
        }

        this.sourceData = {};       //获取到的原始学习来源
        this.sourceCount = {};      //对学习来源进行统计数量
        this.leftRef = null;
        this.rightRef = null;       //右侧模块ref

    }

    componentDidMount() {
        document.getElementsByTagName('html')[0].style.overflowY = 'hidden';
        this.props.setType('mind');
        this.getGraphData()
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.keyword !== this.props.keyword) {
            this.setState({
                tableData: [],
                eaeList: [],        //关系数据列表
                sourceList: [],         //右侧文章列表
            }, () => {
                this.getGraphData();
            })
        }
    }

    componentWillUnmount() {
        document.getElementsByTagName('html')[0].style.overflowY = 'auto';
        // 卸载异步操作设置状态
        this.setState = (state, callback) => {
            return;
        }
    }

    getGraphData = () => {
        this.setState({
            loading: true
        }, () => {
            let postData = {
                keyword: this.props.keyword,
            };
            webSdk.searchGraphResultApi.kgSearch(postData).then(res => {
                if (!res.errCode || res.errCode === 0) {
                    let originData = res || [];
                    if (originData.length) {
                        let eavKeys = [
                                {
                                    key: 'entity',
                                    label: '实体'
                                },
                                {
                                    key: 'attribute',
                                    label: '属性'
                                },
                                {
                                    key: 'time',
                                    label: '时间'
                                },
                                {
                                    key: 'value',
                                    label: '值'
                                }
                            ],
                            eaeKeys = [
                                {
                                    key: 'entity',
                                    label: '实体'
                                },
                                {
                                    key: 'relation',
                                    label: '关系'
                                },
                                {
                                    key: 'entity2',
                                    label: '实体2'
                                }
                            ];
                        let eavResult = formatOriginData(originData, eavKeys, 0, [], 0);
                        let eaeResult = formatOriginData(originData, eaeKeys, 0, [], eavResult.length + 1);
                        if (eavResult.length && eavResult.length === eaeResult.length) {
                            for (let i = 0; i < eavResult.length; i++) {
                                let eavChildren = eavResult[i].children || [],
                                    eaeChildren = eaeResult[i].children || [];
                                if (eavChildren.length) {
                                    eavResult[i].children = [...eavChildren, ...eaeChildren];
                                } else {
                                    eavResult[i].children = eaeChildren;
                                }
                            }
                        }
                        this.getInitChildrenData(eavResult, 1);
                        console.log(eavResult);
                        /*debugger
                        this.setState({
                            tableData: eavResult
                        },() =>{
                            //this.getInitChildrenData(eavResult, 1);
                        })*/
                    }else{
                        this.setState({
                            loading: false
                        });
                    }
                } else {
                    this.setState({
                        loading: false
                    });
                    message.error(res.errMsg || '获取数据失败，请稍后重试');
                }
            }).catch(e => {
                console.log(e);
                this.setState({
                    loading: false
                })
                message.error('获取数据失败，请稍后重试');
            })
        })
    };

    getInitChildrenData = (list, num) => {
        let childrenList = [];
        getChildrenList(list);
        let tableData = [...list];
        if (childrenList.length) {
            let resNum = 0;
            let sourceList = [];
            let oldSourceList = [...this.state.sourceList];
            for (let i = 0; i < childrenList.length; i++) {
                let item = childrenList[i];
                getData(item).then(res => {
                    let {sourceList: newSource} = formatNodeClickData(res, this.sourceData, list, item, this.sourceCount);
                    sourceList = [...oldSourceList, ...newSource];
                    resNum++;
                    if (childrenList.length === resNum) {
                        console.log('list', list);
                        this.setState({
                            loading: false,
                            tableData: list,
                            sourceList
                        }, () => {
                            // this.leftRef.mindGraph.drawGraph()
                        })
                    }
                }).catch(e => {
                    this.setState({
                        loading: false,
                        tableData
                    });
                    console.log(e)
                    message.error('获取数据失败，请稍后重试');
                })
            }
        } else {
            this.setState({
                loading: false,
                tableData
            })
        }

        function getChildrenList(list) {
            if (list.length) {
                for (let i = 0; i < list.length; i++) {
                    if (num === 0) break;
                    let children = list[i].children || list[i]._children || [];
                    if (children.length) {
                        getChildrenList(children)
                    } else {
                        childrenList.push(list[i]);
                        num--;
                    }
                }
            }
        }

        function getData(item) {
            return new Promise((resolve, reject) => {
                let postData = {};
                let temp = {};
                for (let i = 0; i < item.parentList.length; i++) {
                    temp[item.parentList[i].key] = item.parentList[i].value;
                }
                if (!item.time) {
                    temp.time = {"year": "", "month": "", "date": ""};
                }
                temp[item.key] = item.label;
                if (item.key === 'time') {
                    temp.time = item._time;
                }
                postData.kg_type = item.key === 'relation' ? 'eae' : 'eav';

                postData.data = JSON.stringify(temp);
                webSdk.searchGraphResultApi.getKg(postData).then(res => {
                    if (!res.errCode || res.errCode === 0) {
                        resolve(res)
                    } else {
                        reject(res.errMsg || '获取数据失败，请稍后重试')
                    }
                }).catch(e => {
                    console.log(e);
                    message.error('获取数据失败，请稍后重试');
                    reject('获取数据失败，请稍后重试')
                })
            })
        }
    }

    clickNodeGetData = (node, cb) => {
        if (!(!node.requested && (node.key === 'attribute' || node.key === 'relation' || node.key === 'time'))) return;
        node.requested = true;
        let _this = this;
        let {tableData} = this.state;
        let postVal = {};
        for (let i = 0; i < node.parentList.length; i++) {
            postVal[node.parentList[i].key] = node.parentList[i].value;
        }
        postVal[node.key] = node.label;
        let kg_type = 'eav';
        if (node.key === 'relation') {
            kg_type = 'eae';
            postVal.count = node.count
        } else {
            postVal.time = {"year": "", "month": "", "date": ""}
        }
        if (node.key === 'time') {
            postVal.time = node._time;
        }
        postVal = JSON.stringify(postVal);
        let postData = {
            kg_type,
            data: postVal
        };

        webSdk.searchGraphResultApi.getKg(postData).then(res => {
            if (!res.errCode || res.errCode === 0) {
                let oldSourceList = [...this.state.sourceList];
                let {sourceList, artIds, children} = formatNodeClickData(res, _this.sourceData, tableData, node, _this.sourceCount);
                sourceList = [...oldSourceList, ...sourceList]
                this.setState({
                    tableData, sourceList
                }, () => {
                    cb && cb({sourceList, artIds, children})
                })
            } else {
                node.requested = false;
                node.loading = false;
                message.error(res.errMsg || '获取数据失败，请稍后重试');
                cb && cb(false)
            }
        }).catch(e => {
            node.loading = false;
            node.requested = false;
            console.log(e);
            message.error('获取数据失败，请稍后重试');
            cb && cb(false)
        })
    }

    clickDownIcon = () => {
        this.rightRef.changeStatus('value', false);
        this.rightRef.changeStatus('table');
    };

    nodeClick = (node, type, cb) => {
        // type:当前节点点击类型。add: 锚点添加节点, request:  请求子级数据,feedBack: 最后的value或entity2, 缺省：默认展开收缩
        if (type === 'add') {
            this.rightRef.tableList.addNodeClick(node)
        } else if (type === 'request') {
            this.clickNodeGetData(node, cb)
        } else if (type === 'feedBack') {
            this.rightRef.changeStatus('value', true);
            this.rightRef.valueList.getValueList(node)
        }
    }

    render() {

        return (<div className="result-search__graph">
            <Spin spinning={this.state.loading}>
                <div className="result-search__graph-content">
                    <LeftMind
                        ref={el => this.leftRef = el}
                        addGraphText={this.addKey}
                        clickDownIcon={this.clickDownIcon}
                        nodeClick={this.nodeClick}
                        clickValueNode={this.clickValueNode}
                        clickNodeGetData={this.clickNodeGetData}
                        keyword={this.props.keyword}
                        loading={this.state.loading}
                        graphData={this.state.tableData}/>
                    <RightTabs
                        ref={el => this.rightRef = el}
                        keyword={this.props.keyword}
                        loading={this.state.loading}
                        sourceList={this.state.sourceList}/>
                </div>

            </Spin>
        </div>)
    }
}

export default MindModuleIndex