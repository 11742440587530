import {action, observable} from 'mobx'

/**
 * 首页左侧
 */
export default class HomeRightStore {
    @observable rightOpen = false;      // 左侧展开收缩

    @observable questionAnaLoading = false;
    @observable questionAnaList = [];       // 问题解析列表

    @observable corpusAnaLoading = false;
    @observable corpusAnaList = [];         // 语料解析列表

    @observable abstractAnaLoading = false;
    @observable abstractAnaList = [];       // 生成摘要列表

    @observable questionAnaFalse = false;       // 问句解析错误
    @observable corpusAnaFalse = false;         // 语料解析错误
    @observable abstractAnaFalse = false;       // 生成摘要错误

    @observable attributeModal = false;     // 问题解析模式下，是否显示属性弹窗

    @observable submitLoading = false;        // 右侧提交训练loading

    @observable hoverId = null;             // 生成摘要模式下鼠标移入的id，用作连线高亮

    @observable dbClickId = null;           // 生成的摘要双击id
    @observable positionTop = null;         // 生成的摘要双击item的top

    @action unmountAction = () =>{
        this.rightOpen = false;
        this.questionAnaLoading = false;
        this.questionAnaList = [];
        this.corpusAnaLoading = false;
        this.corpusAnaList = [];
        this.abstractAnaLoading = false;
        this.abstractAnaList = [];
        this.questionAnaFalse = false;
        this.corpusAnaFalse = false;
        this.abstractAnaFalse = false;
        this.attributeModal = false;
        this.submitLoading = false;
        this.hoverId = null;
        this.dbClickId = null;
        this.positionTop = null;
    };

    @action setRightOpen = status =>{
        this.rightOpen = status;
    };

    @action setQuestionAnaLoading = status =>{
        this.questionAnaLoading = status;
    };

    @action setQuestionAnaList = list =>{
        this.questionAnaList = list;
    };

    @action setCorpusAnaLoading = status =>{
        this.corpusAnaLoading = status;
    };

    @action setCorpusAnaList = list =>{
        this.corpusAnaList = list;
    };

    @action setAbstractAnaLoading = status =>{
        this.abstractAnaLoading = status;
    };

    @action setAbstractAnaList = list =>{
        this.abstractAnaList = list;
    };

    @action setQuestionAnaFalse = status =>{
        this.questionAnaFalse = status;
    };

    @action setCorpusAnaFalse = status =>{
        this.corpusAnaFalse = status;
    };

    @action setAbstractAnaFalse = status =>{
        this.abstractAnaFalse = status;
    };

    @action setAttributeModal = status =>{
        this.attributeModal = status;
    };

    @action setSubmitLoading = status =>{
        this.submitLoading = status;
    };

    @action setHoverId = id => {
        this.hoverId = id;
    };

    @action setDbItem = (id, top) =>{
        this.dbClickId = id;
        this.positionTop = top;
    }
}
