import React from 'react'
import _ from 'lodash'
import '../Index.scss'
import { Select, DatePicker, Table, Button, Icon, Spin, message, Input, Modal, Empty } from 'antd'
import queryString from 'query-string'
import { webSdk } from "../../../api/WebSdk";
import { MultiCascader } from 'rsuite';
import AdvancedSearch from '../../common/AdvancedSearch/AdvancedSearch'
import AdvancedSearchContent from '../../common/AdvancedSearchContent/AdvancedSearchContent'
import 'rsuite/dist/styles/rsuite.min.css'

const { RangePicker } = DatePicker;
const { Option } = Select;


//向上查找组织树数据
function formatSelectData(id, initData, resultData, currentArr) {

    for (let i = 0; i < initData.length; i++) {
        if (initData[i].value === id) {
            let newArr = []
            if (currentArr && currentArr.length > 0) {
                newArr = [...currentArr]
            }
            newArr.push(id);
            resultData.push(newArr)
            break;
        } else {
            if (initData[i].children && initData[i].children.length > 0) {
                let newArr = []
                if (currentArr && currentArr.length > 0) {
                    newArr = [...currentArr]
                }
                newArr.push(initData[i].value);
                formatSelectData(id, initData[i].children, resultData, newArr)
            }
        }
    }
}

//删除子集
function removeArrChild(result) {
    let copyResult = [...result];
    for (let i = 0; i < result.length; i++) {
        for (let j = 0; j < copyResult.length; j++) {
            let ri = result[i].join(',');
            let rj = copyResult[j].join(',');
            if (ri !== rj) {
                let flag = false;
                flag = copyResult[j].every(val => result[i].includes(val))
                if (flag) {
                    result.splice(i, 1);
                    i = i - 1;
                    break;
                }
            }
        }
    }
}

let queryTheme = '[]';
class YearReport extends React.PureComponent {

    constructor(props) {
        super(props)

        let searchData = props.location.search || '';
        let page = 1,
            theme = [],
            themeType = '1',
            pageSize = 10,
            annualType = '',
            year = '',
            timeValue = [];
        queryTheme = '[]'
        if (searchData) {
            page = JSON.parse(queryString.parse(searchData)['page'] || '1');
            pageSize = parseInt(queryString.parse(searchData)['pageSize'] || '10');
            theme = JSON.parse(queryString.parse(searchData)['theme'] || '[]');
            queryTheme = queryString.parse(searchData)['queryTheme'] || '[]'
            themeType = queryString.parse(searchData)['themeType'] || '1';
            annualType = queryString.parse(searchData)['annualType'] || '';
            year = queryString.parse(searchData)['year'] || '';
            if (year) {
                timeValue = [`${year}-01-01`, `${year}-12-31`]
            }
        }

        this.state = {
            keyword: props.match.params.keyword || '',
            //统计时间
            statisticalTime: {
                title: '年报时间',
                titleKey: 'noticeTime',
                list: [],
                value: timeValue,//传入接口参数
                timeValue: year,//展示的时间
            },
            //数据来源
            dataFrom: {
                title: '年报分类',
                titleKey: 'dataFrom',
                selectData: [
                    {
                        label: '全部',
                        value: ''
                    },
                    {
                        label: '半年报',
                        value: '半年报'
                    },
                    {
                        label: '第一季度报',
                        value: '第一季度报'
                    },
                    {
                        label: '年报',
                        value: '年报'
                    },
                    {
                        label: '第三季度报',
                        value: '第三季度报'
                    },
                ],
                value: annualType
            },
            //公司筛选
            /*company: {
                title: '公司筛选',
                titleKey: 'company',
                selectData: [],
                value: ''
            },*/
            //行业分类标准
            industryClassification: {
                title: '行业分类标准',
                titleKey: 'industryClassification',
                selectData: [
                    {
                        label: '申万行业分类',
                        value: '1'
                    },
                    /*{
                        label: '中证指数',
                        value: '2'
                    },*/
                    {
                        label: 'CSRC标准分类',
                        value: '3'
                    }
                ],
                value: themeType
            },
            //行业分类
            industryScreen: {
                title: '行业分类',
                titleKey: 'industryScreen',
                selectData: [],
                value: theme
            },
            //行业分类下拉数据
            classificationSelect: [],

            tableLoading: false,
            columns: [
                {
                    title: '序号',
                    dataIndex: 'index',
                    render: (text, record, index) => `${index + 1}`,
                    align: 'center',
                    width: 70
                },
                {
                    title: '年报标题',
                    dataIndex: 'title',
                    key: 'title',
                    render: text => text || '-'
                },
                {
                    title: '简称',
                    dataIndex: 'company',
                    key: 'company',
                    width: 120,
                    render: text => text || '-'
                },
                {
                    title: '证券代码',
                    dataIndex: 'stockcode',
                    key: 'stockcode',
                    width: 150,
                    render: text => text || '-'
                },
                {
                    title: '年报时间',
                    dataIndex: 'date',
                    key: 'date',
                    width: 150,
                    render: text => text || '-'
                },
                {
                    title: '操作',
                    dataIndex: 'view',
                    key: 'view',
                    width: 90,
                    align: 'center',
                    render: (text, record) => {
                        return (
                            <div className="table-action-cell" onClick={() => this.viewClick(record)}>查看</div>
                        )
                    }
                }
            ],
            tableData: [],
            page: page,
            pageSize: pageSize,
            pageTotal: 0,
            selectItem: [],          //当前选中的对象
            addListSusBtn: true,//加入阅读列表按钮是否在可视区内
            industryLoading: false,
            addLoading: false,
            filterSwitch: true,
            selections: [],
            isShowAdvancedSearch: false,
            projectList: [],
            isShowProjectModal: false,
            projectId: -1,
            filterData: []
        }

        this.getTableData = this.getTableData.bind(this);

        this.changeTime = this.changeTime.bind(this);
        this.changeSelectDataFrom = this.changeSelectDataFrom.bind(this);
        this.changeSelectClass = this.changeSelectClass.bind(this);
        this.pageChange = this.pageChange.bind(this);
        this.pageSizeChange = this.pageSizeChange.bind(this);
        this.viewClick = this.viewClick.bind(this);

        this.changeMulSelect = this.changeMulSelect.bind(this)
        this.mulSelectSearch = this.mulSelectSearch.bind(this)
        this.searchByKeyword = this.searchByKeyword.bind(this)
        this.searchClick = this.searchClick.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
        this.handleOk = this.handleOk.bind(this)
        this.changeFilterData = this.changeFilterData.bind(this)
    }

    componentDidMount() {
        this.props.fnSetType('yearReport', this.state.keyword || '')
        window.document.addEventListener("scroll", this.listerScroll.bind(this))
        //this.getDataFromList();
        this.getClassificationList();
        this.getTableData();
        let icons = document.querySelectorAll('.rs-picker-toggle-caret')
        icons.forEach(item => {
            item.innerHTML = `<i aria-label="图标: down" style="color: rgba(0, 0, 0, 0.25);transform: scale(0.8576)" class="anticon anticon-down ant-select-arrow-icon"><svg viewBox="64 64 896 896" class="" data-icon="down" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path></svg></i>`
        })

        let statisticalTime = { ...this.state.statisticalTime }
        let date = new Date();
        let year = date.getFullYear();
        let list = [];
        for (let i = 0; i < 10; i++) {
            list.push(year);
            year--
        }
        statisticalTime.list = list;
        let postData = {
            proj_id: ''
        };
        webSdk.search.queryProjectList(postData).then(res => {
            this.setState({
                projectList: res || []
            })
        })
        this.setState({
            statisticalTime
        })
        webSdk.search.queryProjectFilter({type: 'annual'}).then(res => {
            let data = res || [];
            this.setState({
                filterData: data
            })
        })

    }

    componentWillReceiveProps(nextProps) {
        /*if ((nextProps.match.params.keyword!==undefined && this.state.keyword && nextProps.match.params.keyword !== this.state.keyword) || (nextProps.match.params.keyword===undefined && this.state.keyword)) {
            console.log("11")
            this.setState({
                keyword: nextProps.match.params.keyword || '',
                page: 0,
                pageTotal: 0
            }, () => {
                this.getTableData()
            })
        }*/
        if (this.props.location.search !== nextProps.location.search) {
            let searchData = nextProps.location.search || '';
            let npage = 1, ntheme = [], nthemeType = '1', npageSize = 10, nannualType = '', nyear = '', ntimeValue = [];
            queryTheme = '[]';
            if (searchData) {
                npage = JSON.parse(queryString.parse(searchData)['page'] || '1');
                npageSize = parseInt(queryString.parse(searchData)['pageSize'] || '10');
                ntheme = JSON.parse(queryString.parse(searchData)['theme'] || '[]');
                queryTheme = queryString.parse(searchData)['queryTheme'] || '[]'
                nthemeType = queryString.parse(searchData)['themeType'] || '1';
                nannualType = queryString.parse(searchData)['annualType'] || '';
                nyear = queryString.parse(searchData)['year'] || '';
                if (nyear) {
                    ntimeValue = [`${nyear}-01-01`, `${nyear}-12-31`]
                }
            }
            let { statisticalTime, dataFrom, industryClassification, industryScreen, page, pageSize } = this.state;
            statisticalTime.value = ntimeValue;
            statisticalTime.timeValue = nyear;
            dataFrom.value = nannualType;
            industryClassification.value = nthemeType;
            industryScreen.value = ntheme;
            page = npage;
            pageSize = npageSize;

            this.setState({
                statisticalTime, dataFrom, industryClassification, industryScreen, page, pageSize
            }, () => {
                this.getTableData();
            })
        }
    }

    componentWillUnmount() {
        // 卸载异步操作设置状态
        this.setState = (state, callback) => {
            window.document.removeEventListener("scroll", this.listerScroll.bind(this))
            return;
        }
    }

    changeLocation = () => {
        let themeSelect = [...this.state.industryScreen.value];
        let result = [];
        if (themeSelect.length > 0) {
            if (this.state.classificationSelect.length > 0) {
                for (let i = 0; i < themeSelect.length; i++) {
                    formatSelectData(themeSelect[i], this.state.classificationSelect, result)
                }
                removeArrChild(result)
            }
        }
        queryTheme = JSON.stringify(result)
        console.log(queryTheme)

        let searchData = `?page=${this.state.page}&pageSize=${this.state.pageSize}&themeType=${this.state.industryClassification.value}&theme=${JSON.stringify(themeSelect)}&queryTheme=${queryTheme}&annualType=${this.state.dataFrom.value}&year=${this.state.statisticalTime.timeValue}`
        this.props.history.push({
            pathname: `${this.props.location.pathname}`,
            search: searchData
        })
    }

    /**监控滚动条 */
    listerScroll(event) {
        let currentTarget = event.target
        let addListBtn = currentTarget.querySelector('div#addListBtn');
        let addListSusBtn = this.state.addListSusBtn

        if (addListBtn && addListBtn.getBoundingClientRect().top < 64) {
            addListSusBtn = false
        }

        if (addListBtn && addListBtn.getBoundingClientRect().top >= 64) {
            addListSusBtn = true
        }

        this.setState({
            addListSusBtn
        })
    }

    /**
     * 获取数据来源
     */
    // getDataFromList() {
    //     let postData = {
    //         keyword: this.state.keyword,
    //         type: 1
    //     }
    //     webSdk.search.getResearchSourceList(postData).then(res => {
    //         try {
    //             if (!res.errCode || res.errCode === 0) {
    //                 let dataFrom = {...this.state.dataFrom};
    //                 dataFrom.selectData = res;
    //                 this.setState({
    //                     dataFrom
    //                 })
    //             } else {
    //                 message.error(res.errMsg || '获取数据来源列表失败，请稍后重试');
    //             }
    //         } catch (e) {
    //             message.error('获取数据来源列表失败，请稍后重试');
    //         }
    //     }).catch(e => {
    //         message.error('获取数据来源列表失败，请稍后重试');
    //     })
    // }

    /**
     * 获取表格信息
     */
    getTableData() {
        let { filterData } = this.state
        let comp_name = '',  // 公司名称
            block = '', //板块
            query_type = '';
        if(filterData.length > 0) {
            for(let i = 0; i< filterData.length; i++) {
                if(filterData[i].key === 'query_type' && filterData[i].hasOwnProperty('selected') && filterData[i].selected.length > 0) {
                    query_type = filterData[i].selected.join(',')
                }else if(filterData[i].key === 'secname' && filterData[i].hasOwnProperty('selected') && filterData[i].selected.length > 0) {
                    comp_name = filterData[i].selected.join(',')
                }else if(filterData[i].key === 'block' && filterData[i].hasOwnProperty('selected') && filterData[i].selected.length > 0) {
                    block = filterData[i].selected.join(',')
                }
            }
        }
        let postData = {
            //type: 3,       //查询类型：1.企业研报2.行业研报
            art_type: 'annual',

            keyword: this.state.keyword,
            annual_type: this.state.dataFrom.value,
            //theme: this.state.industryScreen.value,
            page_size: this.state.pageSize,
            page: this.state.page,
            query_type: query_type,
            comp_name: comp_name,
            start_date: this.state.statisticalTime.value[0] || '',
            end_date: this.state.statisticalTime.value[1] || '',
            theme_id: queryTheme.replace(/\[|]/g,'')
            //enterprise: JSON.stringify([this.state.company.value])
        }

        /*let result = [];
        let themeSelect = [...this.state.industryScreen.value];
        if (themeSelect.length > 0) {
            if (this.state.classificationSelect.length>0) {
                for (let i = 0; i < themeSelect.length; i++) {
                    formatSelectData(themeSelect[i], this.state.classificationSelect, result)
                }
                removeArrChild(result)
                console.log(JSON.stringify(result))
            }
            else {      //页面刷新，还未获取到下拉数据
                result = JSON.parse(queryTheme)
            }
        }
        postData.theme = JSON.stringify(result);

        queryTheme = JSON.stringify(result)

        this.props.history.push({
            pathname: `${this.props.location.pathname}`,
            search: `?page=${this.state.page}&pageSize=${this.state.pageSize}&themeType=${this.state.industryClassification.value}&theme=${JSON.stringify(themeSelect)}&queryTheme=${queryTheme}&annualType=${this.state.dataFrom.value}&year=${this.state.statisticalTime.timeValue}`
        })*/

        this.setState({
            tableLoading: true
        }, () => {
            webSdk.search.advLocalSearch(postData).then(res => {
                console.log(res)
                try {
                    if (res.status === 200) {
                        let pageTotal = res.data.pageNum || 0,
                            tableData = res.data.list;

                        //获取年报时间
                        /*let yearDate = []
                        _.forEach(res.reportList, item => {
                            let date = item.date
                            if (date) {
                                yearDate.push(Number(date.substring(0,4)))
                            }
                        })
                        yearDate = _.sortedUniq(yearDate)*/

                        /*let statisticalTime = this.state.statisticalTime
                        if (_.isEmpty(statisticalTime.list)) {
                            statisticalTime.list=yearDate
                        }*/

                        this.setState({
                            pageTotal, tableData,
                            tableLoading: false,
                        })
                    } else {
                        this.setState({
                            tableLoading: false
                        })
                        message.error(res.errMsg || '获取企业年报列表失败，请稍后重试');
                    }
                } catch (e) {
                    this.setState({
                        tableLoading: false
                    })
                    message.error('获取企业年报列表失败，请稍后重试');
                }
            }).catch(e => {
                this.setState({
                    tableLoading: false
                })
                message.error('获取企业年报列表失败，请稍后重试');
            })
        })

    }

    /**
     * 获取行业分类列表
     */
    getClassificationList() {
        let industryScreen = { ...this.state.industryScreen };
        industryScreen.value = [];
        this.setState({
            industryScreen,
            classificationSelect: [],
            industryLoading: true
        }, () => {
            let postData = {
                type: this.state.industryClassification.value
            }
            webSdk.search.getClassificationList(postData).then(res => {
                try {
                    if (!res.errCode || res.errCode === 0) {
                        for (let i = 0; i < res.length; i++) {
                            renameData(res[i]);
                        }
                        //let industryScreen = { ...this.state.industryScreen };
                        //industryScreen.selectData = res;
                        this.setState({
                            //industryScreen,
                            classificationSelect: res,
                            industryLoading: false
                        })
                    } else {
                        this.setState({
                            industryLoading: false
                        })
                        message.error(res.errMsg || "获取行业分类列表失败")
                    }
                } catch (e) {
                    this.setState({
                        industryLoading: false
                    })
                    console.log(e)
                    message.error("获取行业分类列表失败")
                }
            }).catch(e => {
                this.setState({
                    industryLoading: false
                })
                message.error("获取行业分类列表失败")
            })
        })


        function renameData(data) {
            data.label = data.text;
            delete data.text;
            if (data.child && data.child.length > 0) {
                data.children = data.child;
                delete data.child;
                for (let i = 0; i < data.children.length; i++) {
                    renameData(data.children[i])
                }
            }
        }
    }

    /**
     * 修改研报统计时间
     */
    changeTime(year) {
        let statisticalTime = { ...this.state.statisticalTime };

        let date = [`${year}-01-01`, `${year}-12-31`]
        if (typeof (year) == "undefined") {
            statisticalTime.value = [];
            statisticalTime.timeValue = undefined
        } else {
            statisticalTime.value = date;
            statisticalTime.timeValue = year
        }

        this.setState({
            statisticalTime,
            page: 1
        }, () => {
            this.changeLocation()
        })
    }

    /**
     * 修改来源筛选
     */
    changeSelectDataFrom(val) {
        let dataFrom = { ...this.state.dataFrom };
        dataFrom.value = val;
        this.setState({
            dataFrom,
            page: 1
        }, () => {
            this.changeLocation()
        })
    }

    /**
     * 修改行业分类标准
     */
    changeSelectClass(val) {
        let industryClassification = { ...this.state.industryClassification };
        industryClassification.value = val;
        let industryScreen = { ...this.state.industryScreen };
        industryScreen.value = [];
        this.setState({
            industryClassification,
            industryScreen
        }, () => {
            this.getClassificationList()
        })
    }

    /**
     * 加入阅读列表事件
     */
    addToList() {
        console.log(this.state.selectItem);
        let selectItem = [...this.state.selectItem];
        if (selectItem.length > 0) {
            this.setState({
                addLoading: true
            }, () => {
                let ids = [];
                for (let i = 0; i < selectItem.length; i++) {
                    ids.push(selectItem[i].id)
                }
                let postData = {
                    ids: ids.join(','),
                    type: this.state.selectType
                }
                webSdk.search.addReadList(postData).then(res => {
                    console.log(res)
                    if (res.result) {
                        message.success("加入阅读列表成功");
                        this.setState({
                            addLoading: false,
                            selectItem: [],
                            selections: []
                        })
                    } else {
                        this.setState({
                            addLoading: false
                        })
                        message.error(res.errMsg || "加入阅读列表失败,请稍后重试")
                    }
                }).catch(e => {
                    this.setState({
                        addLoading: false
                    })
                    message.error("加入阅读列表失败,请稍后重试")
                })
            })
        }

    }

    // 高级搜索筛选词改变
    changeFilterData(data) {
        this.setState({
            filterData: data
        })
    }

    //  搜索关键词
    searchByKeyword(keyword) {
        this.setState({
            keyword: keyword,
            page: 1
        }, () => {
            this.getTableData();
        })
    }

    /**
     * 页码变更
     */
    pageChange(page, pageSize) {
        this.setState({
            page: page
        }, () => {
            this.changeLocation()
        })
    }

    /**
     * 页尺寸变更
     */
    pageSizeChange(current, size) {
        this.setState({
            page: 1,
            pageSize: size
        }, () => {
            this.changeLocation()
        })
    }

    /**
     * 表格查看事件
     */
    viewClick(record) {
        this.props.history.push({
            pathname: `/researchResult/3/${record.id}/3`
        })
    }

    industryHasChange = false

    changeMulSelect(valueList) {
        if (!this.industryHasChange) {
            this.industryHasChange = true;
            let industryScreen = { ...this.state.industryScreen };
            industryScreen.value = valueList;
            this.setState({
                industryScreen
            })
        } else {
            this.industryHasChange = false;
            let industryScreen = { ...this.state.industryScreen };
            industryScreen.value = valueList;
            this.setState({
                industryScreen,
                page: 1
            }, () => {
                this.changeLocation();
            })
        }
    }

    mulSelectSearch() {
        if (this.industryHasChange) {
            this.setState({
                page: 1
            }, () => {
                this.changeLocation();
            })

            this.industryHasChange = false;
        }
    }

    clearIndustry() {
        this.industryHasChange = true;
    }

    searchClick() {
        this.setState({
            isShowAdvancedSearch: true
        })
    }

    handleOk() {
        let selectItem = [...this.state.selectItem];
        if (selectItem.length > 0) {
            this.setState({
                addLoading: true
            }, () => {
                let postData = {
                    article: []
                }
                for (let i = 0; i < selectItem.length; i++) {
                    let articleObj = {}
                    let type = selectItem[i].route.substring(selectItem[i].route.lastIndexOf(".")+1)
                    articleObj.title = selectItem[i].title
                    articleObj.doc_type = type
                    if(type === 'PDF' || type === 'pdf') {
                        articleObj.file_content = ''
                    }
                    articleObj.doc_url = selectItem[i].route
                    articleObj.publish_time = selectItem[i].date
                    postData.article.push(articleObj)
                }
                webSdk.search.createDoc(postData,this.state.projectId).then(res => {
                    if (res.result) {
                        message.success("加入项目成功");
                        this.setState({
                            addLoading: false,
                            isShowProjectModal: false,
                            selectItem: [],
                            selections: []
                        })
                    } else {
                        this.setState({
                            addLoading: false,
                            isShowProjectModal: false
                        })
                        message.error(res.errMsg || "加入项目失败,请稍后重试")
                    }
                }).catch(e => {
                    this.setState({
                        addLoading: false,
                        isShowProjectModal: false
                    })
                    message.error("加入项目失败,请稍后重试")
                })
            })
        }

    }

    handleCancel() {
        this.setState({
            isShowProjectModal: false,
            selectItem: [],
            selections: []
        })
    }

    render() {
        const rowSelection = {
            columnWidth: 50,
            selectedRowKeys: this.state.selections,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selections: selectedRowKeys,
                    selectItem: selectedRows
                })
            },
        };

        /*const CompanyOption = this.state.company.selectData.map((item,index) =>{
            return (
                <Option key={item.value}>{item.label}</Option>
            )
        })*/

        return (
            <div className="home-page__research-library">
                <div className="company-list__industry-screen__main">

                    <div className="company-list__industry-screen__item">
                        <div
                            className="company-list__industry-screen__item-label">{this.state.industryClassification.title}</div>
                        <div className="company-list__industry-screen__item-input">
                            <Select style={{ width: 160, height: 30 }}
                                value={this.state.industryClassification.value ? this.state.industryClassification.value : undefined}
                                onChange={(val) => this.changeSelectClass(val)}
                                placeholder="请选择行业分类标准">
                                {
                                    this.state.industryClassification.selectData.map((item, index) => {
                                        return (
                                            <Option value={item.value} key={index}>{item.label}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </div>
                    </div>


                    <div className="company-list__industry-screen__item">
                        <div
                            className="company-list__industry-screen__item-label">{this.state.industryScreen.title}</div>
                        <div className="company-list__industry-screen__item-input">
                            {/* <Cascader style={{ width: 200, height: 32 }}
                                value={this.state.industryScreen.value}
                                expandTrigger="hover"
                                disabled={this.state.industryLoading}
                                changeOnSelect={true}
                                options={this.state.industryScreen.selectData}
                                onChange={val => this.changeCascaderSelect(val)}
                                placeholder="请选择行业分类" /> */}
                            <MultiCascader cascade={false}
                                onChange={(vals, ev) => {
                                    console.log('change')
                                    this.changeMulSelect(vals)
                                }}
                                onClose={() => {
                                    console.log('close')
                                    this.mulSelectSearch()
                                }}
                                onClean={(ev) => {
                                    console.log('clear')
                                    this.clearIndustry()
                                }}
                                placeholder="请选择行业分类"
                                style={{ width: 160 }}
                                menuClassName="mult-select-menu"
                                data={this.state.classificationSelect}
                                value={this.state.industryScreen.value}
                                menuWidth={160}
                                block />
                            {/*disabled={this.state.industryLoading} />*/}
                        </div>
                    </div>

                    <div className="company-list__industry-screen__item">
                        <div className="company-list__industry-screen__item-label">{this.state.dataFrom.title}</div>
                        <div className="company-list__industry-screen__item-input">
                            <Select style={{ width: 160, height: 30 }}
                                //mode="multiple"
                                showArrow={true}
                                value={this.state.dataFrom.value}
                                onChange={(val) => this.changeSelectDataFrom(val)}
                                placeholder="请选择年报分类">
                                {
                                    this.state.dataFrom.selectData.map((item, index) => {
                                        return (
                                            <Option value={item.value} key={item.value}>{item.label}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </div>
                    </div>

                    <div className="company-list__industry-screen__item">
                        <div
                            className="company-list__industry-screen__item-label">{this.state.statisticalTime.title}</div>
                        <div className="company-list__industry-screen__item-input">
                            {/* <RangePicker style={{ width: 160, height: 30 }} format={'YYYY-MM-DD'}
                                placeholder={['年-月-日', '年-月-日']}
                                onChange={(date, dateString) => this.changeTime(date, dateString)}
                                value={this.state.timeValue} /> */}

                            <Select style={{ width: 160, height: 30 }}
                                showArrow={true}
                                allowClear={true}
                                value={this.state.statisticalTime.timeValue || undefined}
                                onChange={(val) => this.changeTime(val)}
                                placeholder="请选择年报时间">
                                {
                                    this.state.statisticalTime.list.map((item, index) => {
                                        return (
                                            <Option value={item} key={index}>{item}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </div>
                    </div>
                    <AdvancedSearch searchByKeyword={this.searchByKeyword}  searchClick={this.searchClick} />



                    {/*<div className="company-list__industry-screen__item">
                            <div
                                className="company-list__industry-screen__item-label">{this.state.company.title}</div>
                            <div className="company-list__industry-screen__item-input">
                                <Select
                                    showSearch
                                    value={this.state.company.value?this.state.company.value:undefined}
                                    placeholder='请输入公司名称'
                                    style={{width: 200}}
                                    allowClear={true}
                                    //style={this.props.style}
                                    defaultActiveFirstOption={false}
                                    showArrow={false}
                                    filterOption={false}
                                    onSearch={value => this.companySearch(value)}
                                    onChange={value => this.companyChange(value)}
                                    notFoundContent={null}
                                >
                                    {CompanyOption}
                                </Select>
                            </div>
                        </div>*/}


                </div>
                {
                    this.state.isShowAdvancedSearch && <AdvancedSearchContent data={this.state.filterData} filterDataChange={this.changeFilterData} />
                }

                <div className="company-list__table-main pt20">
                    <div className={!this.state.addListSusBtn && this.state.selectItem.length > 0 ? "table-main-list year-add-btn-suspend" : "table-main-list"}>
                        <Button type="primary"
                            loading={this.state.addLoading}
                            disabled={this.state.selectItem.length === 0}
                            onClick={() => this.setState({isShowProjectModal: true})}
                            style={{ height: 30, padding: "0 15px", fontSize: 12, verticalAlign: "middle", marginRight: '20px' }}

                        >添加到我的项目<Icon type="plus" /></Button>
                        <Button type="primary"
                            loading={this.state.addLoading}
                            disabled={this.state.selectItem.length === 0}
                            onClick={this.addToList.bind(this)}
                            style={{ height: 30, padding: "0 15px", fontSize: 12, verticalAlign: "middle" }}>
                            加入阅读列表<Icon type="plus" />
                        </Button>
                    </div>
                    <div id="addListBtn" style={{
                        width: "10px",
                        height: "20px",
                        display: "inline-block",
                    }}></div>
                    <Spin spinning={this.state.tableLoading}>
                        <div>
                            <Table
                                columns={this.state.columns}
                                dataSource={this.state.tableData}
                                rowSelection={rowSelection}
                                rowClassName={(record, index) => index % 2 === 0 ? 'single-row' : 'double-row'}
                                rowKey={(record, index) => {
                                    return index
                                }}
                                /*onRow={(record, index) => {
                                    return {
                                        onClick: ev => this.viewClick(record)
                                    }
                                }}*/
                                pagination={{
                                    showSizeChanger: true,
                                    showQuickJumper: true,
                                    pageSizeOptions: ["10", "15", "20", "25"],
                                    current: this.state.page,
                                    pageSize: this.state.pageSize,
                                    total: this.state.pageTotal,
                                    onChange: (page, pageSize) => this.pageChange(page, pageSize),
                                    onShowSizeChange: (current, size) => this.pageSizeChange(current, size),
                                    showTotal: total => `共 ${total} 条`
                                }}
                            />
                        </div>
                    </Spin>

                </div>
                <Modal className='project-modal' width={400} title="将“已选文档”添加到…" visible={this.state.isShowProjectModal} onCancel={this.handleCancel} footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            取消
                        </Button>,
                        <Button key="submit" style={{backgroundColor:'#5B6BAE', color:'#fff'}} onClick={this.handleOk}>
                            添加
                        </Button>
                    ]}>
                    <ul className='project-wrapper'>
                        {
                            this.state.projectList.length > 0 ? this.state.projectList.map((item) => {
                                return <li key={item.proj_id} onClick={() => this.setState({projectId: item.proj_id})} className={item.proj_id === this.state.projectId ? "active" : null }>{item.proj_name}</li>
                            }):
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        }
                    </ul>
                </Modal>
            </div>)
    }
}

export default YearReport