import React from 'react'
import {Spin, Empty, message, Icon} from 'antd'
import _ from 'lodash'
import ScrollBar from "../../../../../common/ScrollBar";
import FeedBackModal from "./FeedBackModal";
import {webSdk} from "../../../../../../api/WebSdk";

class ValueList extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            modal: false,
            list: [],
            sourceData: {},
            node: {},        // 当前点击的节点
        }
    }

    componentWillUnmount() {
        // 卸载异步操作设置状态
        this.setState = (state, callback) => {
            return;
        }
    }

    // ...获取学习来源数据
    getValueList = (item) => {
        if (_.isEmpty(item)) return;
        let type = 'eav';
        let curData = {};       // 请求数据
        let curNodeData = item._nodes[0];       // 节点原始数据
        if (item.key === 'value') {
            let entityArr = curNodeData._entity.map(entity => entity.name);
            curData = {
                entity: entityArr || [''],
                attribute: curNodeData._attribute || [''],
                time: [curNodeData.time] || [''],
                value: curNodeData._value || ['']
            };
        } else if (item.key === 'entity2') {
            type = 'eae';
            curData = {
                entity: curNodeData.entity || '',
                relation: curNodeData.relation || '',
                entity2: curNodeData.entity2 || ''
            };
        }
        let postData = {
            kg_type: type,
            kg_data: JSON.stringify(curData)
        };
        this.setState({
            loading: true,
            node: item,
            list: []
        }, () => {
            webSdk.searchGraphResultApi.kgSource(postData).then(res => {
                this.setState({loading: false});
                if (!res.errCode || res.errCode === 0) {
                    let list = res.source || [];
                    this.setState({list});
                } else {
                    message.error(res.errMsg || '获取学习来源列表失败，请稍后重试');
                }

            }).catch(e => {
                this.setState({loading: false});
                console.log(e);
                message.error('获取学习来源列表失败，请稍后重试');
            })
        })
    }

    feedBack = item => {
        this.setState({
            modal: true,
            sourceData: item
        })
    }

    render() {
        const {loading, modal, list, sourceData, node} = this.state;
        return (
            <>
                <div className="value-block-close" onClick={() => this.props.changeValueListStatus('value', false)}>
                    <Icon type="close"/></div>
                <Spin spinning={loading} wrapperClassName="right-value-loading">
                    <div className="value-info-block">
                        {
                            list.length === 0 ? <Empty/> : (
                                <ScrollBar>
                                    {/*<RightValueInfo list={this.state.valueInfoList}
                                                feedBack={this.valueFeedBack}/>*/}
                                    <div className="right-value-info">
                                        <ul>
                                            {
                                                list.map((item, index) => {
                                                    return (
                                                        <li key={index} className="right-value-item">
                                                            <h4 className="right-value-title">{item.art_title || ''}</h4>
                                                            <p className="right-value-time">{item.art_date || ''}</p>
                                                            <p className="right-value-content">{item.text || ''}<span
                                                                className="right-value-last"/><span
                                                                className="right-value-button"
                                                                onClick={() => this.feedBack(item)}>反馈错误</span></p>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                </ScrollBar>
                            )
                        }
                    </div>
                </Spin>
                {
                    modal ? <FeedBackModal node={node} sourceData={sourceData}
                                           closeModal={() => this.setState({modal: false})}/> : null
                }
            </>
        )
    }
}

export default ValueList