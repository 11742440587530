import React from 'react'

/** 脑图、知识图谱 右侧图形三个按钮 */
function LeftActionIcon(props) {
    return (
        <ul className="graph-btn">
            <li
                onClick={props.switchCursorPoint}
                style={props.isCursorPoint ? { background: '#5B6BAE', color: '#fff' } : {}} title="选择字段">
                <span className="font_icon" style={{ fontSize: 16 }}>&#xe672;</span>
            </li>
            <li
                onClick={props.clickDownIcon}
                title="保存">
                <span className="font_icon" style={{ fontSize: 16 }}>&#xe673;</span>
            </li>
            <li
                onClick={props.switchFullScreen}
                title={props.isFullScreen ? "退出全屏" : "全屏"}>
                <span className={props.isFullScreen ? "font_icon icon-suoxiaox" : "font_icon icon-fangdax"} style={{ fontSize: 16 }} />
            </li>
        </ul>
    )
}

export default LeftActionIcon