import React from 'react'
import TableResult from "../../../../../../graphComponent/TableResult";
import './CostIncome.scss'
import { DatePicker} from 'antd';
import BarAndGraph from "../../../../../../graphComponent/BarAndGraph";
import {webSdk} from "../../../../../../../api/WebSdk";
import { Spin } from 'antd';
const { RangePicker } = DatePicker;

class CostIncome extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            keyword: props.keyword,
            currentLeftMenu: 'totalIncome',            //当前左侧菜单栏  cost总成本  spend总收入

            /*曲线部分*/
            companyName: '',
            engCompanyName: '',
            selectTime: '',

            graphDataId: '',
            options: {},

            /*表格部分*/
            tableDataId: '',
            columns: [],
            tableData: [],
            pageData:{},
            isExport: true
        }
    }
    componentDidMount() {
        this.getBaseInfo(res =>{
            this.setState({
                companyName: res.companyName,
                engCompanyName: res.engCompanyName,
                columns: res.columns,
                tableData: [],
                graphDataId: res.graphDataId,
                tableDataId: res.tableDataId,
                isExport: res.isExport
            },() => {
                this.getGraphData(this.state.graphDataId,graphRes => {
                    this.formatData(graphRes,'graph',resData =>{
                        this.setState({
                            options: resData
                        })
                    })
                });
            })
        })
    }

    componentWillReceiveProps(nextProps) {
        // if (this.state.currentLeftMenu===nextProps.currentLeftMenu) return;
        // this.setState({
        //     currentLeftMenu: nextProps.currentLeftMenu,
        //     options: {},
        //     columns: [],
        //     tableData: [],
        //     tableDataId: '',
        //     pageData: {}
        // },() =>{
        //     this.getBaseInfo(res =>{
        //         this.setState({
        //             companyName: res.companyName,
        //             engCompanyName: res.engCompanyName,
        //             columns: res.columns,
        //             graphDataId: res.graphDataId,
        //             tableDataId: res.tableDataId,
        //             isExport: res.isExport
        //         },() => {
        //             this.getGraphData(this.state.graphDataId,graphRes => {
        //                 this.formatData(graphRes,'graph',resData =>{
        //                     this.setState({
        //                         options: resData
        //                     })
        //                 })
        //             });
        //         })
        //     })
        // });
    }

    /**
     * 获取页面基本信息
     * @param cb
     */
    getBaseInfo(cb){
        const _this = this;
        let postData = {
            style: this.state.currentLeftMenu,    //当前菜单
            p: _this.state.keyword,              //当前搜索词
            time: ''                             //当前筛选的时间
        }


        webSdk.search.getFinancialInfo(postData).then(res => {
            cb && cb(res)
        })
    }

    /**
     * 获取图像数据
     * @param dataId
     * @param cb
     */
    getGraphData(dataId,cb){
        let postData = {
            type: "companyInfo",
            p: this.state.keyword,
            dataId: dataId
        }
        webSdk.search.getGraphData(postData).then(res => {
            cb && cb(res)
        })
    }

    /**
     * 格式化数据
     */
    formatData(item,type,cb){
        //新数据
        let obj = {};
        //图形类数据
        if (type==='graph') {
            //数据格式化
            this.formatGraph(item,res =>{
                cb && cb(res)
            })
        }
        //表格类数据
        else if (type==='table') {
            obj.tableData = item.tableData;
            obj.pageData = item.pageData;
            cb && cb(obj)
        }
        //列数据展示
        else if (type==='list') {
            cb && cb(item)
        }
    }

    /**
     * 格式化graph图数据
     * @param item：当前的数据
     * @param cb：返回格式化后的options数据
     */
    formatGraph(item,cb){
        let options = {
            xAxis: [],
            yAxis: [],
            series: []
        };
        options.legend = {           //指示标签的数据位置及内容
            bottom: 0,
            right: 0,
            data: []      //获取的数据
        };
        options.tooltip = {trigger: 'axis'};
        /**
         * 先对数据进行排序，再提取数据
         */
            //找出所有数据类型
        let keys = [];
        for (let key in item.data[0]) {
            if (key!=='label') keys.push(key);
        }

        let graphData = {
            labels: []
        };
        item.data.forEach((dataItem,dataIndex) =>{
            graphData["labels"].push(dataItem.label);
            for (let i=0;i<keys.length;i++) {
                let keyName = keys[i];
                if (!graphData[keyName]) {
                    graphData[keyName] = []
                }
                graphData[keyName].push(dataItem[keyName])
            }
        });

        //设置X轴数据
        options.xAxis.push({
            type: 'category',
            boundaryGap: true,
            data: graphData.labels,      //获取的数据
            axisTick:{
                alignWithLabel: true
            }
        })

        //设置series、Y轴数据
        item.opt.forEach((optItem,optIndex) =>{
            options.legend.data.push(optItem.name);

            //是否已经含有该Y轴信息
            let hasY = false;
            for (let i=0;i<options.yAxis.length;i++) {
                if (options.yAxis[i].name===optItem.showName) {
                    hasY = true;
                    break;
                }
            }
            if (!hasY) {
                options.yAxis.push({
                    type: 'value',
                    scale: true,
                    name: optItem.showName,      //获取的数据
                    splitNumber: 5         //预估分割段数
                });
            }

            options.series.push({
                name: optItem.name,      //获取的数据
                type: optItem.type,        //类型柱形
                yAxisIndex: optItem.yAxisIndex,
                animationEasing: 'elasticOut',
                animationDelay(idx) {
                    return idx * 100
                },
                animationDelayUpdate(idx) {
                    return idx * 50
                },
                barWidth: '30%',
                data: graphData[optItem.key],      //获取的数据
            })
        })
        cb && cb(options)
    }



    render() {
        return (
            <div className="profit-cost-spend">
                <div className="total-view-box">
                    {
                        !this.state.options || JSON.stringify(this.state.options)==='{}'?(<Spin size="large"/>):''
                    }
                    <h3 className="total-title">
                        <div className="company-name">
                            <span className="company-allName">{this.state.companyName}</span>
                            <span className="company-engName">{this.state.engCompanyName?'（' + this.state.engCompanyName + '）':''}</span>
                        </div>
                        <div className="search-time">
                            <span className="search-time-label">起止时间：</span>
                            <RangePicker style={{width: 220,height: 32}} size="default" format='YYYY/MM/DD' placeholder={['开始','结束']}/>
                        </div>
                    </h3>
                    <div className="graph-content">
                        <div className="graph-top-card"><span>{this.state.currentLeftMenu==='totalCost'?'营业总成本':this.state.currentLeftMenu==='totalIncome'?'营业总收入':'营业总成本'}</span></div>
                        <div className="graph-content-box">
                            <BarAndGraph options={this.state.options}/>
                        </div>
                    </div>
                </div>
                <div className="total-view-table">
                    {
                        this.state.tableDataId?(
                            <TableResult
                                title={this.state.currentLeftMenu==='totalCost'?'营业总成本列表':this.state.currentLeftMenu==='totalIncome'?'营业总收入列表':'营业总成本列表'}
                                columns={this.state.columns}
                                dataId={this.state.tableDataId}
                                isExport={this.state.isExport}/>
                        ):''
                    }

                </div>
            </div>
        )
    }
}

export default CostIncome