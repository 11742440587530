import React from 'react'
import _ from 'lodash'
import '../Index.scss'
import './RegulationFall.scss'
import AppContext from '../../../AppContext'
import { Select, DatePicker, Cascader, Table, Button, Icon, Spin, message, Input, Modal, Empty } from 'antd'
import queryString from 'query-string'
import { webSdk } from "../../../api/WebSdk";
import { MultiCascader } from 'rsuite';
import stores from "../../../store";
import AdvancedSearch from '../../common/AdvancedSearch/AdvancedSearch'
import AdvancedSearchContent from '../../common/AdvancedSearchContent/AdvancedSearchContent'
import 'rsuite/dist/styles/rsuite.min.css'
import more from '../../../assets/svg/policy_more.svg'
import select from '../../../assets/svg/policy_select.svg'
import more_active from '../../../assets/svg/policy_more_active.svg'
import select_active from '../../../assets/svg/policy_select_active.svg'
import download from '../../../assets/svg/policy_download.svg'
import arrow from '../../../assets/svg/policy_arrow.svg'
import addProject from '../../../assets/svg/policy_add_project.svg'
import addCollect from '../../../assets/svg/policy_add_collect.svg'
const { Option } = Select;

class RegulationFall extends React.PureComponent {
    static contextType = AppContext

    constructor(props) {
        super(props)

        this.state = {
            regulationFallParamsStorKey: "regulationFallPageParams",
            publishYear: null,
            pickerShow: false,
            reportType: ['公告公报','政策文件','政策法规','规划文本','政府报告','国际政策解读','政策解读','建议提案','领导讲话'],
            selectReportType: '',
            isShowAdvancedSearch: false,
            addListSusBtn: true,//加入阅读列表按钮是否在可视区内
            selectItem: [],    //当前选中的对象
            tableData: [],     
            selections:[],
            addLoading: false,
            tableLoading: false,
            pagination: {
                page: 1,
                pageSize: 10,
                total: 0,
            },
            columns: [
                {
                    title: '序号',
                    dataIndex: 'index',
                    render: (text, record, index) => `${index + 1}`,
                    align: 'center',
                    width: 70
                },
                {
                    title: '标题',
                    dataIndex: 'title',
                    key: 'title',
                    ellipsis: true,
                    render: text => text || '-'
                },
                {
                    title: '操作',
                    dataIndex: 'operation',
                    key: 'operation',
                    width: 100,
                    render: (text, record, index) => <div className='operation'><img src={record.isSelected ? select_active : select} alt='' onClick={(e) =>this.selectRow(e,record,index,false)}/>&nbsp;&nbsp;&nbsp;<img src={record.isSelected ? more_active : more} alt='' onClick={(e) =>this.selectRow(e,record,index,true)}/>
                        {
                            record.isMore && <ul className='more-wrapper'>
                                {
                                    this.state.moreList.map(item => {
                                        return <li className='more-list' key={item.name} onClick={(e) => this.moreListClick(e,item)}>
                                            <img src={item.src} alt=''/>
                                            <span>{item.name}</span>
                                            {
                                                item.right && <img className='arrow' src={item.right} alt=''/>
                                            }
                                            {
                                                item.children.length > 0 && <ul className='download-list'>
                                                    {
                                                        item.children.map(i => {
                                                            return <li key={i}>{i}</li>
                                                        })
                                                    }
                                                </ul>
                                            }
                                        </li>
                                    })
                                }
                            </ul>
                        }
                    </div>
                },
                {
                    title: '报告类型',
                    dataIndex: 'announcement_type',
                    key: 'announcement_type',
                    width: 150,
                    render: text => text || '-'
                },
                {
                    title: '发布机构',
                    dataIndex: 'publish_department',
                    key: 'publish_department',
                    width: 200,
                    render: text => text || '-'
                },
                {
                    title: '发布年份',
                    dataIndex: 'publish_year',
                    key: 'publish_year',
                    width: 70,
                    render: text => text || '-'
                }
            ],
            projectList: [],
            isShowProjectModal: false,
            projectId: -1,
            filterData: [],
            isMore: false,
            moreList: [
                { src: download, name: '下载为', right: arrow, children: ['Word','PDF'] },
                { src: addProject, name: '添加到我的项目', right: null, children: [] },
                { src: addCollect, name: '加入阅读列表', right: null, children: [] }
            ]
        }
    }

    componentDidMount() {
        window.document.addEventListener("scroll", this.listerScroll.bind(this))
        stores.regulationFallStore.setPageInfo([
            {
                label: "政策法规",
                key: "/regulationFall"
            }
        ], 'list');
        this.init();
        let postData = {
            proj_id: ''
        };
        webSdk.search.queryProjectList(postData).then(res => {
            this.setState({
                projectList: res || []
            })
        })
    }

    componentDidUpdate(nextProps, nextState) {

        let newPage = nextState.pagination.page;
        let newPageSize = nextState.pagination.pageSize;
        let { page, pageSize } = this.state.pagination;
        if (newPage != page || newPageSize != pageSize) {
            this.getTableData();
        }
    }

    componentWillUnmount() {
        // 卸载异步操作设置状态
        this.setState = (state, callback) => {
            window.document.removeEventListener("scroll", this.listerScroll.bind(this))
            return;
        }
        
    }

    /**监控滚动条 */
    listerScroll(event) {
        let currentTarget = event.target
        let addListBtn = currentTarget.querySelector('div#addListBtn');
        let addListSusBtn = this.state.addListSusBtn

        if (addListBtn && addListBtn.getBoundingClientRect().top < 64) {
            addListSusBtn = false
        }

        if (addListBtn && addListBtn.getBoundingClientRect().top >= 64) {
            addListSusBtn = true
        }

        this.setState({
            addListSusBtn
        })
    }

    setLocStorage(key, obj) {
        let objJSON = ""
        if (!_.isEmpty(obj))
            objJSON = JSON.stringify(obj)
        window.localStorage.setItem(key, objJSON)
    }

    getLocStorage(key) {
        let objStr = window.localStorage.getItem(key)
        if (!objStr) return null;
        return JSON.parse(objStr)
    }

    init() {
        let pageParams = this.getLocStorage(this.state.regulationFallParamsStorKey)

        if (pageParams != null) {
            let keyword = pageParams.keyword
            let pagination = pageParams.pagination

            this.setState({
                keyword, pagination
            }, () => {
                this.getTableData();
            })
        } else {
            this.getTableData();
        }

    }

    /**
     * 获取表格信息
     */
     getTableData() {
        let postData = {
            publish_year: this.state.publishYear === null ? null : this.state.publishYear.format('YYYY'),
            announcement_type: this.state.selectReportType,
            publish_department: '',
            keywords: '',
            page: this.state.pagination.page,
            page_size: this.state.pagination.pageSize
        }

        this.setState({
            tableLoading: true
        }, () => {
            webSdk.policyApi.advPolicesSearch(postData).then(res => {
                try {
                    if (res.code === 0) {
                        let newPagination = {
                            page: this.state.pagination.page,
                            pageSize: this.state.pagination.pageSize,
                            total: res.data.pageModel.total || 0,
                        };
                        res.data.pageModel.records.forEach(item => {
                            item.isSelected = false
                            item.isMore = false
                        })
                        let tableData = res.data.pageModel.records;


                        this.setState({
                            tableData,
                            pagination: newPagination,
                            tableLoading: false,
                        })
                    } else {
                        this.setState({
                            tableLoading: false
                        })
                        message.error(res.errMsg || '获取政策法规列表失败，请稍后重试');
                    }
                } catch (e) {
                    this.setState({
                        tableLoading: false
                    })
                    message.error('获取政策法规列表失败，请稍后重试');
                }
            }).catch(e => {
                this.setState({
                    tableLoading: false
                })
                message.error('获取政策法规列表失败，请稍后重试');
            })
        })

    }

    selectRow = (event,record,index,isMore) => {
        event.stopPropagation();
        event.preventDefault();
        let {selectItem,selections,tableData} = this.state
        let newSelectItem = [...selectItem], newSelections = [...selections], newTableData = [...tableData]
        if(isMore) {
            let i = newSelections.indexOf(index)
            if(newSelections.length > 0) {

                if(i === -1) {
                    newSelections = []
                    newSelectItem = []
                    newTableData.forEach(item => {
                        item.isSelected = false
                        item.isMore = false
                    })
                }else {
                    newTableData.forEach(item => {
                        if(item._id !== record._id) {
                            item.isMore = false
                        }
                    })
                }
            }
            if(record.isMore) {
                record.isMore = false
            }else {
                record.isMore = true
            }
            if(i === -1) {
                newSelections.push(index)
                newSelectItem.push(record)
            }
            record.isSelected = true
        }else {
            if(newSelections.length > 0) {
                let i = newSelections.indexOf(index)
                if(i === -1) {
                    record.isSelected = true
                    newSelections.push(index)
                    newSelectItem.push(record)
                }else {
                    record.isSelected = false
                    newSelections.splice(i,1)
                    newSelectItem.splice(i,1)
                }
            }else{
                record.isSelected = true
                newSelections.push(index)
                newSelectItem.push(record)
            }
        }
        newTableData.forEach(item => {
            if(item._id === record._id) {
                item = record
            }
        })
        this.setState({
            selectItem: newSelectItem,
            selections: newSelections,
            tableData: newTableData
        })
    }

    moreListClick = (event,item) => {
        event.stopPropagation();
        event.preventDefault();
        if(item.name === '添加到我的项目') {
            this.setState({isShowProjectModal: true})
        }else if(item.name === '加入阅读列表'){
            this.addToList()
        }
    }

    /**保存页面参数到localStorage */
    savePageParams2Storage(regulationFallItem) {
        let regulationFallId = regulationFallItem._id;
        let regulationFallTitle = regulationFallItem.title
        let { pagination, keyword } = this.state
        //保存页面参数到localStorage中
        let pageParams = {//页面参数
            pagination, keyword, regulationFallId, regulationFallTitle
        }
        
        this.setLocStorage(this.state.regulationFallParamsStorKey, pageParams)
    }

    changePublishYear = (val) => {
        console.log(val)
        this.setState({
            publishYear: val,
            pickerShow: false
        })
    }

    changePicker = (status) => {
        console.log(status)
        if(status) {
            this.setState({
                pickerShow: true
            })
        }else {
            this.setState({
                pickerShow: false
            })
        }
    }
    changeReportType = (val) => {
        console.log(val)
        this.setState({
            selectReportType: val
        })
    }

    changeYear = () => {
        this.setState({
            publishYear: null
        })
    }

    /**
     * 加入阅读列表事件
     */
     addToList() {
        let selectItem = [...this.state.selectItem];
        let tableData = [...this.state.tableData];
        if (selectItem.length > 0) {
            this.setState({
                addLoading: true
            }, () => {
                let ids = [];
                for (let i = 0; i < selectItem.length; i++) {
                    ids.push(selectItem[i].id)
                }
                let postData = {
                    ids: ids.join(','),
                    type: this.state.selectType
                }
                webSdk.search.addReadList(postData).then(res => {
                    console.log(res)
                    if (res.result) {
                        message.success("加入阅读列表成功");
                        tableData.forEach(item => {
                            item.isSelected = false
                            item.isMore = false
                        })
                        this.setState({
                            addLoading: false,
                            selectItem: [],
                            selections: [],
                            tableData: tableData
                        })
                    } else {
                        this.setState({
                            addLoading: false
                        })
                        message.error(res.errMsg || "加入阅读列表失败,请稍后重试")
                    }
                }).catch(e => {
                    this.setState({
                        addLoading: false
                    })
                    message.error("加入阅读列表失败,请稍后重试")
                })
            })
        }

    }

    searchClick = () => {
        this.setState({
            isShowAdvancedSearch: true
        })
    }

    // 高级搜索筛选词改变
    changeFilterData = (data) => {
        this.setState({
            filterData: data
        })
    }

     /**
     * 页码变更
     */
    
      pageChange(newPage, newPageSize) {
        let { pageSize, total } = this.state.pagination
        let pagination = {
            page: newPage,
            pageSize: pageSize,
            total
        }
        this.setState({
            pagination
        })
    }

    /**
     * 页尺寸变更
     */
    pageSizeChange(newPage, newPageSize) {
        let { total } = this.state.pagination
        let pagination = {
            page: 1,
            pageSize: newPageSize,
            total
        }
        this.setState({
            pagination
        })
    }

    handleRowDetail = (item) => {
        this.savePageParams2Storage(item);
        this.props.history.push({
            pathname: `/regulationFallDetail/${item._id}`
        })
    }

    handleOk() {
        let selectItem = [...this.state.selectItem];
        console.log("handleOk")
        console.log(this.state.selectItem);
        if (selectItem.length > 0) {
            this.setState({
                addLoading: true
            }, () => {
                let postData = {
                    article: []
                }
                for (let i = 0; i < selectItem.length; i++) {
                    let articleObj = {}
                    articleObj.title = selectItem[i].title
                    articleObj.doc_type = selectItem[i].type
                    articleObj.file_content = selectItem[i].content
                    articleObj.doc_url = selectItem[i].sourceLink
                    articleObj.publish_time = selectItem[i].publicTime
                    postData.article.push(articleObj)
                }
                webSdk.search.createDoc(postData,this.state.projectId).then(res => {
                    if (res.result) {
                        message.success("加入项目成功");
                        let tableData = [...this.state.tableData]
                        tableData.forEach(item => {
                            item.isSelected = false
                            item.isMore = false
                        })
                        this.setState({
                            addLoading: false,
                            isShowProjectModal: false,
                            selectItem: [],
                            selections: [],
                            tableData: tableData
                        })
                    } else {
                        this.setState({
                            addLoading: false,
                            isShowProjectModal: false
                        })
                        message.error(res.errMsg || "加入项目失败,请稍后重试")
                    }
                }).catch(e => {
                    this.setState({
                        addLoading: false,
                        isShowProjectModal: false
                    })
                    message.error("加入项目失败,请稍后重试")
                })
            })
        }

    }

    handleCancel() {
        let tableData = [...this.state.tableData]
        tableData.forEach(item => {
            item.isSelected = false
            item.isMore = false
        })
        this.setState({
            isShowProjectModal: false,
            selectItem: [],
            selections: [],
            tableData: tableData
        })
    }


    render() {
        return (
            <div className="home-page__research-library">
                <div className="company-list__industry-screen__main">

                    <div className="company-list__industry-screen__item">
                        <div
                            className="company-list__industry-screen__item-label">发布年份</div>
                        <div className="company-list__industry-screen__item-input">
                            <DatePicker mode="year" allowClear={true} style={{ width: 160, height: 30 }} format='YYYY' value={this.state.publishYear} placeholder="选择年份" open={this.state.pickerShow} onPanelChange={this.changePublishYear} onChange={this.changeYear} onOpenChange={this.changePicker} />
                        </div>
                    </div>
                    <div className="company-list__industry-screen__item">
                        <div
                            className="company-list__industry-screen__item-label">报告类型</div>
                        <div className="company-list__industry-screen__item-input">
                            <Select style={{ width: 160, height: 30 }}
                                    showArrow={true}
                                    allowClear={true}
                                    value={this.state.selectReportType || undefined}
                                    onChange={this.changeReportType}
                                    placeholder="选择类型">
                                    {
                                        this.state.reportType.map((item, index) => {
                                            return (
                                                <Option value={item} key={index}>{item}</Option>
                                            )
                                        })
                                    }
                            </Select>
                        </div>
                    </div>
                    <div className="company-list__industry-screen__item">
                        <div
                            className="company-list__industry-screen__item-label">发布机构</div>
                        <div className="company-list__industry-screen__item-input">
                        <Cascader
                                defaultValue={['zhejiang', 'hangzhou', 'xihu']}
                                style={{ width: 160, height: 30 }}
                                // options={options}
                                // onChange={onChange}
                            />
                        </div>
                    </div>
                    {/* <div className="company-list__industry-screen__item">
                        <Button type="primary"
                            style={{ width: 60, height: 30, padding: 0, fontSize: 12 }}>
                            查询
                        </Button>
                    </div> */}

                    <AdvancedSearch searchByKeyword={this.searchByKeyword}  searchClick={this.searchClick} />

                </div>

                {
                    this.state.isShowAdvancedSearch && <AdvancedSearchContent data={this.state.filterData} filterDataChange={this.changeFilterData} />
                }

                <div className="company-list__table-main pt20">
                    <div className={!this.state.addListSusBtn && this.state.selectItem.length > 0 ? "table-main-list year-add-btn-suspend" : "table-main-list"}>
                        <Button type="primary"
                            loading={this.state.addLoading}
                            disabled={this.state.selectItem.length === 0}
                            onClick={() => this.setState({isShowProjectModal: true})}
                            style={{ height: 30, padding: "0 15px", fontSize: 12, verticalAlign: "middle", marginRight: '20px' }}

                        >添加到我的项目<Icon type="plus" /></Button>
                        <Button type="primary"
                            loading={this.state.addLoading}
                            disabled={this.state.selectItem.length === 0}
                            onClick={this.addToList.bind(this)}
                            style={{ height: 30, padding: "0 15px", fontSize: 12, verticalAlign: "middle" }}>
                            加入阅读列表<Icon type="plus" />
                        </Button>
                    </div>
                    <div id="addListBtn" style={{
                        width: "10px",
                        height: "20px",
                        display: "inline-block",
                    }}></div>
                    <Spin spinning={this.state.tableLoading}>
                        <div className='regulation-fall-table'>
                            <Table
                                columns={this.state.columns}
                                dataSource={this.state.tableData}
                                rowClassName={(record, index) => index % 2 === 0 && (record.isSelected || record.isMore) ? 'single-row row-active' :
                                index % 2 === 0 && !record.isSelected && !record.isMore ? 'single-row' : index % 2 !== 0 && (record.isSelected || record.isMore) ?
                                 'double-row row-active': 'double-row'}
                                rowKey={(record, index) => {
                                    return index+Date.now()
                                }}
                                onRow={(record) => {
                                    return {
                                      onClick: (event) => {
                                        this.handleRowDetail(record)
                                      } // 点击行
                                    }
                                  }}
                                pagination={{
                                    showSizeChanger: true,
                                    showQuickJumper: true,
                                    pageSizeOptions: ["10", "15", "20", "25"],
                                    current: this.state.pagination.page,
                                    pageSize: this.state.pagination.pageSize,
                                    total: this.state.pagination.total,
                                    onChange: (page, pageSize) => this.pageChange(page, pageSize),
                                    onShowSizeChange: (current, size) => this.pageSizeChange(current, size),
                                    showTotal: total => `共 ${total} 条`
                                }}
                            />
                        </div>
                    </Spin>

                </div>
                <Modal className='project-modal' width={400} title="将“已选文档”添加到…" visible={this.state.isShowProjectModal} onCancel={this.handleCancel.bind(this)} footer={[
                        <Button key="back" onClick={this.handleCancel.bind(this)}>
                            取消
                        </Button>,
                        <Button key="submit" style={{backgroundColor:'#5B6BAE', color:'#fff'}} onClick={this.handleOk.bind(this)}>
                            添加
                        </Button>
                    ]}>
                    <ul className='project-wrapper'>
                        {
                            this.state.projectList.length > 0 ? this.state.projectList.map((item) => {
                                return <li key={item.proj_id} onClick={() => this.setState({projectId: item.proj_id})} className={item.proj_id === this.state.projectId ? "active" : null }>{item.proj_name}</li>
                            }):
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        }
                    </ul>
                </Modal>
            </div>)
    }
}

export default RegulationFall