import React from 'react'
import { Radio } from 'antd';
import './GraphCard.scss'
import BarAndGraph from "../../../../../../graphComponent/BarAndGraph";
import {webSdk} from "../../../../../../../api/WebSdk";
import { Spin } from 'antd';

/*标题+卡片类型*/
class GraphCard extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            title: props.title,
            cardList: props.cardList,
            currentCard: props.currentCard,    //当前卡片选择项
            choiceRadio: props.choiceRadio,    //当前图标展示条件
            radioList: props.radioList,
            dataId: props.dataId,
            options: {},
            initData: {}
        }

        this.getOptions = this.getOptions.bind(this);
        this.changeCard = this.changeCard.bind(this)
    }
    componentDidMount() {
        let postData = {
            dataId: this.state.dataId
        };
        webSdk.search.getGraphData(postData).then(res => {
            this.setState({
                initData: res
            },()=>{
                let initData = this.state.initData;
                this.sortData(initData.data,this.state.choiceRadio,'down');
                this.getOptions(initData)
            })
        })
    }

    /**
     * 改变卡片类型
     * @param item
     */
    changeCard(item){
        this.setState({
            currentCard: item.key
        })
        //....axios
    }

    componentWillReceiveProps(nextProps) {

    }

    /**
     * 改变显示数据方式
     */
    changeRadioStyle(ev){
        this.setState({
            choiceRadio: ev.target.value
        },() => {
            let initData = this.state.initData;
            if (ev.target.value==='down') {
                this.sortData(initData.data,'point','down');
            }
            else if (ev.target.value==='up') {
                this.sortData(initData.data,'point','up');
            }
            this.getOptions(initData)
        });
    }

    //格式化数据，计算出图形options
    getOptions(item){
        let options = {
            xAxis: [],
            yAxis: [],
            series: []
        };
        options.legend = {           //指示标签的数据位置及内容
            bottom: 0,
            right: 0,
            data: []      //获取的数据
        };
        //options.legend = [];
        options.tooltip = {
            trigger: 'axis',
            formatter: params =>{
                let str = params[0].name;
                for (let i=0;i<params.length;i++) {
                    if (params[i].seriesName==='原持股比例' || params[i].seriesName==='增持后持股比例') {
                        params[i].value += '%'
                    }
                    str += `<br/>${setColor(params[i].color)}${params[i].seriesName}:${params[i].value}`
                }
                return str

                function setColor(color) {
                    return `<span style="display: inline-block;width: 10px;height: 10px;border-radius: 50%;margin-right: 4px;background-color: ${color}"></span>`
                }
            }
        };
        /**
         * 先对数据进行排序，再提取数据
         */
            //找出所有数据类型
        let keys = [];
        for (let key in item.data[0]) {
            if (key!=='label') keys.push(key);
        }

        let graphData = {
            labels: []
        };
        item.data.forEach((dataItem,dataIndex) =>{
            graphData["labels"].push(dataItem.label);
            for (let i=0;i<keys.length;i++) {
                let keyName = keys[i];
                if (!graphData[keyName]) {
                    graphData[keyName] = []
                }
                graphData[keyName].push(dataItem[keyName])
            }
        });
        //设置X轴数据
        options.xAxis.push({
            type: 'category',
            boundaryGap: true,
            data: graphData.labels,      //获取的数据
            axisTick:{
                alignWithLabel: false
            },
            axisLabel: {
                interval:0,
                rotate:10//角度顺时针计算的
            }
        })
        //设置series、Y轴数据
        item.opt.forEach((optItem,optIndex) =>{
            if (!optItem.isHide) {
                options.legend.data.push(optItem.name);
                options.yAxis.push({
                    type: 'value',
                    scale: true,
                    name: optItem.showName,      //获取的数据
                    splitNumber: 5         //预估分割段数
                });
            }
            /*let legendObj = {
                show: !optItem.isHide,
                data: optItem.name
            }*/
            options.series.push({
                name: optItem.name,      //获取的数据
                type: optItem.type,        //类型柱形
                showSymbol: !optItem.isHide,
                yAxisIndex: optItem.yAxisIndex,
                animationEasing: 'elasticOut',
                animationDelay(idx) {
                    return idx * 100
                },
                animationDelayUpdate(idx) {
                    return idx * 50
                },
                barWidth: !optItem.isHide?'30%':'-2%',
                data: graphData[optItem.key],      //获取的数据
            })
        })
        //console.log(JSON.stringify(options))
        this.setState({
            options: options
        })
    }

    /**
     * 数据排序处理
     * @param data：当前的所有数据
     * @param sortKey：排序关键词
     * @param type：排序方式：  down：降序，up：升序
     */
    sortData(data,sortKey,type="down"){
        if (type==='down') {
            for (let i = 0; i < data.length; i++) {
                for (let j = 0; j < data.length - 1 - i; j++) {
                    if (parseFloat(data[j][sortKey]) < parseFloat(data[j+1][sortKey])) {        //相邻元素两两对比
                        let temp = data[j+1];        //元素交换
                        data[j+1] = data[j];
                        data[j] = temp;
                    }
                }
            }
        }
        else if (type === 'up') {
            for (let i = 0; i < data.length; i++) {
                for (let j = 0; j < data.length - 1 - i; j++) {
                    if (parseFloat(data[j][sortKey]) > parseFloat(data[j+1][sortKey])) {        //相邻元素两两对比
                        let temp = data[j+1];        //元素交换
                        data[j+1] = data[j];
                        data[j] = temp;
                    }
                }
            }
        }

    }

    render() {
        return (
            <div className="title-card-list">
                {
                    !this.state.options || JSON.stringify(this.state.options)==='{}'?(<Spin size="large"/>):''
                }
                <h3 className="title-card-list-title">{this.state.title}</h3>
                {
                    this.state.cardList && this.state.cardList.length>0?(
                        <ul className="title-list">
                            {this.state.cardList.map((item,index) =>{
                                return <li key={index} onClick={() => this.changeCard(item)} className={this.state.currentCard===item.key?'card-list-style choice-card-list':'card-list-style'}>{item.label}</li>
                            })}
                        </ul>
                    ):''
                }

                <div className="title-card-content">
                    <div className="choice-radio">
                        <Radio.Group onChange={this.changeRadioStyle.bind(this)} value={this.state.choiceRadio}>
                            {
                                this.state.radioList.map((item,index) => {
                                    return <Radio value={item.key} key={index}>{item.label}</Radio>
                                })
                            }
                        </Radio.Group>
                    </div>
                    <div className="show-content">
                        {
                            this.state.options?(
                                <>
                                    <BarAndGraph options={this.state.options}/>
                                </>
                            ):''
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default GraphCard