import React, { PureComponent } from 'react';
import _ from 'lodash'
import { Drawer, Input, Button, Tooltip } from 'antd';
import './PublicCycleAnswer.scss'
import AppContext from '../../AppContext'
import { webSdk } from '../../api/WebSdk';

const { TextArea } = Input;

class PublicCycleAnswer extends PureComponent {
    static contextType = AppContext

    constructor(props) {
        super(props);
        this.state = {
            isShowCycleQues: false,//多伦问答是否展示
            isShowCycleSendPop: false,//多伦问答错误提示框是否展示
            cycleAreaVal: "",//多伦问答输入框中的值
            cycleAnswers: [//多伦问答对话列表
                // {
                //     type: 1,//语句类型：1问；2答；3无结果
                //     answerArr: [//回答列表，当type==2时
                //         {
                //             isSearch: true,//是否为搜索内容
                //             sentence: "企业估值模型"//句子内容
                //         }
                //     ],
                //     questionSentence: "万科的营业收入",//问题，当type==1时
                // },
            ],
        }
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {

    }

    /**初始化数据 */
    initData(searchValue) {
        const _this = this
        if (searchValue) {
            let cycleAnswers = [{
                type: 1,
                questionSentence: searchValue,
            }]

            _this.setState({
                cycleAnswers
            }, () => {
                //设置多伦问答的sessionStorage
                _this.setCycleAnswerStor(cycleAnswers)
                //获取多伦问答数据
                _this.getCycleAnswerData(searchValue)
            })
        }
        else {
            _this.setCycleAnswerStor([]);
            _this.setState({
                cycleAnswers: []
            })
        }
    }

    /**设置多伦回答数据 */
    setData() {
        const _this = this
        let cycleAnswers = _this.getCycleAnswerFromStor()

        _this.setState({
            cycleAnswers
        })
    }

    /**操作多伦问答弹层显隐 */
    handleCycleQuestion() {
        let isShowCycleQues = this.state.isShowCycleQues

        this.setState({
            isShowCycleQues: !isShowCycleQues
        }, () => {
            isShowCycleQues = this.state.isShowCycleQues
            if (isShowCycleQues) {
                document.getElementById("cyclequeArea") ? document.getElementById("cyclequeArea").focus() : null
            } else {
                this.props.fnHandleCycleQuestionAuto(false)
            }
        })
    }

    /**设置多伦问答到sessionStorage */
    setCycleAnswerStor(obj) {
        if (_.isEmpty(obj)) {
            sessionStorage.setItem("cycleAnswers", "")
            this.setState({
                cycleAnswers: []
            })
        } else {
            let objStr = JSON.stringify(obj)
            sessionStorage.setItem("cycleAnswers", objStr)
        }
    }

    /**从sessionStorage中获取多伦问答对象 */
    getCycleAnswerFromStor() {
        let resStor = sessionStorage.getItem("cycleAnswers")
        if (resStor) {
            return JSON.parse(resStor)
        }
        else {
            return []
        }
    }

    /**发送多伦问答 */
    sendCycleQuestion() {
        const _this = this
        let cyclequeExhibition = document.getElementById("cyclequeExhibition")
        let cycleAreaVal = _this.state.cycleAreaVal

        //输入内容为空时，弹出提示
        if (!cycleAreaVal) {
            _this.setState({
                isShowCycleSendPop: true
            }, () => {
                setTimeout(() => {
                    _this.setState({
                        isShowCycleSendPop: false
                    })
                }, 1000)
            })
            return false
        }
        let cycleAnswers = [..._this.state.cycleAnswers]

        cycleAnswers.push(
            {
                type: 1,
                questionSentence: cycleAreaVal,
            }
        )

        _this.setState({
            cycleAnswers,
            cycleAreaVal: ""
        }, () => {
            //设置多伦问答到sessionStorage
            _this.setCycleAnswerStor(cycleAnswers)

            //将展示区的滚动条移到最底部
            if (cyclequeExhibition) {
                cyclequeExhibition.scrollTop = cyclequeExhibition.scrollHeight
            }

            //获取多伦问答数据
            this.getCycleAnswerData(cycleAreaVal)
        })
    }

    /**获取多伦问答数据 */
    getCycleAnswerData(question) {
        const _this = this
        let cyclequeExhibition = document.getElementById("cyclequeExhibition")
        let cycleAnswers = [..._this.state.cycleAnswers]
        let resAnswer = question;

        //请求数据
        let params = {
            keyword: resAnswer
        }

        webSdk.searchResultApi.getCycleAnswer(params).then(res => {
            let msgData = res

            let toPage = {}

            if (!_.isEmpty(msgData)) {

                _.forEach(msgData, (item, idx) => {
                    let pageType = item.key || "listTb"
                    let sentence = item.text
                    let answerArr = []

                    // sentence = "<a>万科的营收入</a>"
                    if (sentence) {
                        if (sentence.indexOf("<a>") > -1) {
                            let sentArr = sentence.split("<a>")
                            _.forEach(sentArr, sentItem => {
                                if (sentItem.indexOf("</a>") === -1) {
                                    answerArr.push({
                                        isSearch: false,
                                        sentence: sentItem
                                    })
                                } else {
                                    let endAIdx = sentItem.indexOf("</a>")
                                    let sentSearch = sentItem.substring(0, endAIdx)
                                    answerArr.push({
                                        isSearch: true,
                                        sentence: sentSearch,
                                        pageType
                                    })
                                    let sentNotSearch = sentItem.substring(endAIdx + 4)
                                    answerArr.push({
                                        isSearch: false,
                                        sentence: sentNotSearch
                                    })
                                }
                            })

                            if (idx == 0) {//临时使用一个值
                                let tempAnswer = _.find(answerArr, { isSearch: true })
                                toPage.pageType = pageType
                                toPage.sentence = tempAnswer.sentence
                            }
                        } else {
                            answerArr = [{
                                isSearch: false,
                                sentence
                            }]
                        }
                        cycleAnswers.push({
                            type: 2,
                            answerArr,
                        })
                    }
                })

            } else {
                cycleAnswers.push({
                    type: 3,
                    sentence: "请换一种说法",
                })
            }

            _this.setState({
                cycleAnswers
            }, () => {
                //设置多伦问答到sessionStorage
                _this.setCycleAnswerStor(cycleAnswers)

                //将展示区的滚动条移到最底部
                if (cyclequeExhibition) {
                    cyclequeExhibition.scrollTop = cyclequeExhibition.scrollHeight
                }

                //临时功能：返回的是链接，则跳转到相应的页面
                if (!_.isEmpty(toPage)) {
                    this.context.getHistory().push({
                        pathname: `/resultList/${toPage.sentence}/${toPage.pageType}`
                    })
                }
            })
        })
    }

    /**改变多伦问答文本域值 */
    changeCycleArea(event) {
        this.setState({
            cycleAreaVal: event.target.value
        })
    }

    /**进一步搜索 */
    searchDeepth(answer) {
        const _this = this
        let cycleAnswers = [..._this.state.cycleAnswers]

        _this.setCycleAnswerStor(cycleAnswers)
        _this.context.getHistory().push({
            pathname: `/resultList/${answer.sentence}/${answer.pageType}`,
        })
    }

    /**禁止文本域换行 */
    checkEnter(e) {
        var et = e || window.event;
        var keycode = et.charCode || et.keyCode;
        if (keycode == 13) {
            if (window.event) {
                window.event.returnValue = false;
            } else {
                e.preventDefault(); //for firefox
            }
        }
    }

    render() {
        return (
            <div className="public-cyc">
                <Drawer
                    className={`home-cycleque-drawer ${!this.state.isShowCycleQues ? "home-cycleque-drawer__hide" : ""}`}
                    title="聊天记录"
                    placement="right"
                    mask={false}
                    onClose={this.handleCycleQuestion.bind(this)}
                    visible={this.state.isShowCycleQues}
                >
                    <div id="cyclequeExhibition" className="home-cycleque-exhibition">
                        <ul>
                            {
                                this.state.cycleAnswers.map((item, idx) => {
                                    if (item.type === 1) {//问
                                        return (
                                            <li key={idx} className="cycleque-exhibition-item cycleque-exhibition-questioner">{item.questionSentence}</li>
                                        )
                                    } else if (item.type === 2) {//答
                                        return (
                                            <li key={idx} className="cycleque-exhibition-item cycleque-exhibition-answerer">
                                                {
                                                    !_.isEmpty(item.answerArr) ?
                                                        item.answerArr.map((answerItem, answerIdx) => {
                                                            if (answerItem.isSearch) {
                                                                return <a
                                                                    key={answerIdx}
                                                                    onClick={this.searchDeepth.bind(this, answerItem)}>{answerItem.sentence}</a>
                                                            } else {
                                                                return <span
                                                                    key={answerIdx}
                                                                >{answerItem.sentence}</span>
                                                            }
                                                        }) : null
                                                }
                                            </li>
                                        )
                                    } else {//无结果
                                        return (
                                            <li key={idx} className="cycleque-exhibition-item cycleque-exhibition-answerer">{item.sentence}
                                            </li>
                                        )
                                    }
                                })
                            }
                        </ul>
                    </div>
                    <div className="home-cycleque-expresser">
                        <TextArea id="cyclequeArea"
                            value={this.state.cycleAreaVal}
                            onChange={this.changeCycleArea.bind(this)}
                            className="cycleque-expresser-area"
                            placeholder="请输入关键信息"
                            onPressEnter={this.sendCycleQuestion.bind(this)}
                            onKeyDown={this.checkEnter.bind(this)}
                            rows={3}
                        />
                        <Tooltip placement="topLeft" title="发送内容不能为空" visible={this.state.isShowCycleSendPop}>
                            <Button className="cycleque-expresser-btn" onClick={this.sendCycleQuestion.bind(this)} >发送</Button>
                        </Tooltip>
                    </div>
                </Drawer>
            </div >
        );
    }
}

export default PublicCycleAnswer;