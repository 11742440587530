import React, { PureComponent } from 'react';
import _ from "lodash"
import './RightList.scss'

const LeaderLine = window.LeaderLine;       //  https://anseki.github.io/leader-line/
let lineArr = [];
let interArr = [];
let currentLineObj = null;//当前连线

class RightList extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            keyword: this.props.keyword || "",
            dataServer: this.props.dataServer || [],//服务端原始数据
            itemList: [//重组后的数据
                // {
                //     title: '华富安鑫债券(000028)基金基本情况_基金档案',
                //     time: "2019年11月1日",
                //     source: "www.souhu.com",
                //     id: 'r1',
                //     ids: [1, 2, 3]
                // },
            ]
        }
    }

    componentDidMount() {
        //滚动事件
        // this.scrollListener();
        this.dealData()
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            keyword: nextProps.keyword,
            dataServer: nextProps.dataServer
        }, () => {
            this.dealData()
        })
    }

    componentWillUnmount() {
        // 卸载异步操作设置状态
        this.setState = (state, callback) => {
            return;
        }
    }

    /**滚动事件 */
    scrollListener() {
        const _this = this
        document.addEventListener("scroll", () => {
            _this.reloadLink()
        })

        let searchRightContent = document.getElementById("searchRightContent")
        searchRightContent.addEventListener("scroll", () => {
            _this.reloadLink()
        })
    }

    /**处理数据 */
    dealData() {
        const _this = this
        let dataServer = _this.state.dataServer
        let itemList = []
        _.forEach(dataServer, dataServerItem => {
            let item = {
                title: dataServerItem.title,
                time: dataServerItem.art_date,
                source: dataServerItem.art_url,
                sourceType: dataServerItem.art_type,
                id: dataServerItem.art_id,
                lightId: dataServerItem.kp_list ? _.join(dataServerItem.kp_list, "-") : "",
                ids: []
            }

            //## 组装链接对象id
            let ids = []
            //# 集合对象
            let dataServerCollectIds = dataServerItem.set
            _.forEach(dataServerCollectIds, collectIdsItem => {
                ids.push(`collect_${collectIdsItem}`)
            })
            //# 属性对象
            let dataServerPropertyIds = dataServerItem.attr
            _.forEach(dataServerPropertyIds, propertyIdsItem => {
                ids.push(`property_${propertyIdsItem}`)
            })
            //# 描述对象
            let dataServerDescribeIds = dataServerItem.desc
            _.forEach(dataServerDescribeIds, describeIdsItem => {
                ids.push(`describe_${describeIdsItem}`)
            })
            item.ids = ids

            itemList.push(item)
        })

        _this.setState({
            itemList
        }, () => {
            _this.drawLink();
        })
    }

    /**画连接线 */
    drawLink() {
        let { itemList } = this.state;

        _.forEach(lineArr, lineObj => {
            let lineList = lineObj.lineList
            _.forEach(lineList, line => {
                try {
                    line.remove()
                } catch (e) {

                }
            })
        })

        lineArr = [];
        let inter = setInterval(() => {

            itemList.forEach(item => {
                let majoring = false;
                let start = document.getElementById(item.id);

                if (item.ids && item.ids.length > 0) {

                    //在lineArr中找到/创建这条连线
                    let lineObj = _.find(lineArr, { startId: item.id })
                    if (_.isEmpty(lineObj)) {
                        lineObj = { startId: item.id }
                    }

                    //连线集合
                    let lineList = []
                    item.ids.forEach(id => {
                        let idArr = _.split(id, "_")
                        let divName = `${idArr[0]}List`
                        let divNode = document.getElementById(divName)
                        if (divNode) {
                            //清除定时器
                            _.forEach(interArr, item => {
                                clearInterval(item)
                            })
                            interArr = []

                            let end = document.getElementById(id);
                            if (end) {

                                let line = new LeaderLine(LeaderLine.mouseHoverAnchor(start, 'draw', {
                                    animOptions: {                      // 画线       https://anseki.github.io/leader-line/#animation-options
                                        duration: 300,                  // 总时
                                        timing: [0.5, 0, 0.5, 0.42]       // [x1, y1, x2, y2]作为“定时功能” 的数组
                                    },
                                    style: {
                                        background: '#FAFBFC'
                                    },
                                    hoverStyle: {
                                        background: '#FAFBFC'
                                    }
                                }),
                                    end,
                                    {
                                        color: '#7987BE',       // 线条颜色
                                        size: 2,                // 线条粗细
                                        //outline: true,        // 是否显示虚线
                                        //endPlugOutline: true, // 箭头外虚线
                                        //endPlugSize: 1.5});   // 箭头尺寸
                                        //startPlugColor: '#1a6be0',  // 渐变颜色开始
                                        //endPlugColor: '#1efdaa',    // 渐变颜色结束
                                        gradient: true,
                                        //startPlug: 'disc',        // 开始的图标      //https://anseki.github.io/leader-line/#startplug-endplug
                                        endPlug: 'disc',          // 末尾的图标
                                        hide: true
                                    });
                                //debugger
                                //line.animOptions = {duration: 500, timing: [0.58, 0, 0.42, 1]}
                                lineList.push(line)
                            } else {
                                if (!majoring) {
                                    majoring = true
                                    end = divNode
                                    let line = new LeaderLine(LeaderLine.mouseHoverAnchor(start, 'draw', {
                                        animOptions: {                      // 画线       https://anseki.github.io/leader-line/#animation-options
                                            duration: 300,                  // 总时
                                            timing: [0.5, 0, 0.5, 0.42]       // [x1, y1, x2, y2]作为“定时功能” 的数组
                                        },
                                        style: {
                                            background: '#FAFBFC'
                                        },
                                        hoverStyle: {
                                            background: '#FAFBFC'
                                        }
                                    }),
                                        end,
                                        {
                                            color: '#7987BE',       // 线条颜色
                                            size: 2,                // 线条粗细
                                            dash: true,             // 虚线
                                            //outline: true,        // 是否显示虚线
                                            //endPlugOutline: true, // 箭头外虚线
                                            //endPlugSize: 1.5});   // 箭头尺寸
                                            //startPlugColor: '#1a6be0',  // 渐变颜色开始
                                            //endPlugColor: '#1efdaa',    // 渐变颜色结束
                                            gradient: true,
                                            //startPlug: 'disc',        // 开始的图标      //https://anseki.github.io/leader-line/#startplug-endplug
                                            endPlug: 'disc',          // 末尾的图标
                                            hide: true
                                        });
                                    //debugger
                                    //line.animOptions = {duration: 500, timing: [0.58, 0, 0.42, 1]}
                                    lineList.push(line)
                                }
                            }
                        }
                    })
                    lineObj.lineList = lineList

                    lineArr.push(lineObj)
                }
            })
        }, 200);
        interArr.push(inter)
    }

    /**展示连线 */
    showLink(id) {

        let lineObj = _.find(lineArr, { startId: id })
        currentLineObj = lineObj
        this.reloadLink()

    }

    /**重新加载单一连线 */
    reloadLink() {
        if (_.isEmpty(currentLineObj)) return;
        let lineList = currentLineObj.lineList
        _.forEach(lineList, item => {
            try {
                item.position()
            } catch (e) {

            }

        })
    }

    /**重新加载所有连线 */
    reloadLinkAll() {

        if (_.isEmpty(lineArr)) return;
        _.forEach(lineArr, lineObj => {
            let lineList = lineObj.lineList
            _.forEach(lineList, line => {
                try {
                    line.remove()
                } catch (e) {

                }

            })
        })
        this.drawLink()
    }

    render() {
        return (
            <div className="search-rightlist">
                <div id="searchRightContent" className="search-rightlist-content">
                    <h4 className="rightlist-title">主要学习来源</h4>
                    <ul>
                        {
                            this.state.itemList.map((item, index) => {
                                return (
                                    <li
                                        key={index}
                                        id={item.id}
                                        className="rightlist-item"
                                        onMouseEnter={this.showLink.bind(this, item.id)}
                                    >
                                        <a
                                            href={item.sourceType === "pdf" ? `/resultSource/${item.id}/${this.state.keyword}/${item.lightId ? item.lightId : ""}` : item.source}
                                            target="_blank"
                                        >
                                            <div className="title" title={item.title}>{item.title}</div>
                                            <div className="info">
                                                {
                                                    item.sourceType === "news" ? (
                                                        <span className="source" title={item.source} >{item.source}</span>
                                                    ) : null
                                                }
                                                <span className="time" title={item.time}>{item.time}</span>
                                            </div>
                                        </a>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        );
    }
}

export default RightList;