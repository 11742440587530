/*
 * @Description:
 * @LastEditTime: 2023-02-24 10:05:59
 * @LastEditors: liqun 13212226759@163.com
 */
import React, { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react'
import { Tabs } from 'antd'
import './index.scss'
import * as echarts from 'echarts'
import { addEvent } from '../../../tools/toolFunc'
import { useStore } from '../../../store'
import { webSdk } from '../../../api/WebSdk'

const root = {
  deep: 1,
  id: '0',
  name: '管理学',
  hasHideChild: false,
  symbolSize: 36,
  itemStyle: {
    borderColor: 'rgba(238,176,128,0.36)',
    borderWidth: 4,
    color: '#FE9863'
  },
  relation: '',
  isShow: true,
  parent: null,
  children: [
    {
      deep: 2,
      id: '1',
      name: '古典管理阶段',
      hasHideChild: false,
      symbolSize: 26,
      itemStyle: {
        borderColor: '#8795CF',
        borderWidth: 2,
        color: '#fff'
      },
      relation: '工厂管理',
      isShow: true,
      parent: null,
      children: []
    },
    {
      deep: 2,
      id: '2',
      name: '定义模型',
      hasHideChild: false,
      symbolSize: 26,
      itemStyle: {
        borderColor: '#8795CF',
        borderWidth: 2,
        color: '#fff'
      },
      relation: '理论模型',
      isShow: true,
      parent: null,
      children: [
        {
          deep: 3,
          id: '3',
          name: '替代品的威胁',
          hasHideChild: false,
          symbolSize: 26,
          itemStyle: {
            borderColor: '#8795CF',
            borderWidth: 2,
            color: '#fff'
          },
          relation: '物理模型',
          isShow: true,
          parent: null,
          children: []
        },
        {
          deep: 3,
          id: '4',
          name: '企业自身的竞争优势',
          hasHideChild: false,
          symbolSize: 26,
          itemStyle: {
            borderColor: '#8795CF',
            borderWidth: 0,
            color: '#8795CF'
          },
          relation: 'SWOT分析法',
          isShow: true,
          parent: null,
          children: []
        }
      ]
    }
  ]
}

const { TabPane } = Tabs

const RightItem = ({ title, attrs }) => {
  return (
    <div className="attrs-container">
      {title && <span className="key">{title}</span>}
      {attrs.map((attr) => (
        <div key={attr.key}>
          <span className="key">{attr.key}：</span>
          {attr.value}
        </div>
      ))}
    </div>
  )
}

const attrs1 = [
  {
    key: '属性一',
    value:
      '特五力模型将大量不同的因素汇集在一个渐变的模型中，一次分析这个函数工业的太少'
  },
  {
    key: '属性二',
    value: '特五力模型将大量不同的因素汇'
  },
  {
    key: '属性三',
    value: '特五力模型将大量不同的因素汇集在一个渐变'
  }
]

const mockNodes = require('./resp.json')
console.log(mockNodes)

function SubjectGraph() {
  const subjectGraphStore = useStore('subjectGraphStore')
  console.log(subjectGraphStore.nodeTree)
  const [selectedTab, setSelectedTab] = useState('经济学')
  const [chart, setChart] = useState()
  const [currentNode, setCurrentNode] = useState({})
  const attr = useMemo(() => {
    let arr = []
    const tansCN = {
      author: '作者',
      fileName: '文件名称',
      // fileUrl: '文件地址',
      nodeType: '节点类型',
      preface: '简介',
      storagePath: '存储路径'
    }
    for (let [key, value] of Object.entries(currentNode)) {
      if (tansCN[key]) {
        arr.push({
          key: tansCN[key],
          value
        })
      }
    }
    return arr
  }, [currentNode])

  // 图谱节点事件监听
  useEffect(() => {
    if (!chart) return
    let eventList = []
    let zoom = 1
    eventList.push(addEvent('resize', chart.resize).on())
    eventList.push(
      addEvent(
        'mousewheel',
        (e) => {
          if (e.wheelDelta > 0) {
            //当滑轮向上滚动时
            zoom += 0.5
          } else if (e.wheelDelta < 0) {
            //当滑轮向下滚动时
            zoom -= 0.5
          }
          zoom = Math.max(0.5, zoom)
          zoom = Math.min(8, zoom)
          chart.setOption({ series: [{ zoom }] })
        },
        { dom: document.getElementById('graph-main') }
      ).on()
    )
    chart.on('click', (item) => {
      console.log(item)
      setCurrentNode(item.data.originData)
      subjectGraphStore.collapse({
        // deep: item.data.deep,
        id: item.data.id
        // hasHideChild: item.data.hasHideChild
      })
    })
    return () => {
      eventList.forEach((event) => event.off())
    }
  }, [chart, subjectGraphStore])

  // 图谱初始化
  useEffect(() => {
    const curChart =
      chart || echarts.init(document.getElementById('graph-main'))
    setChart(curChart)

    let option = {
      tooltip: {},
      series: [
        {
          type: 'graph',
          layout: 'force',
          data: [],
          draggable: true,
          animation: false,
          links: [],
          // zoom: 3,
          roam: 'move', //是否开启鼠标缩放和平移漫游
          edgeSymbol: ['circle', 'arrow'],
          edgeSymbolSize: [4, 10],
          edgeLabel: {
            show: true,
            formatter: (params) => {
              return params.data.relation
            },
            align: 'center',
            fontSize: 12,
            verticalAlign: 'middle',
            color: '#535561'
            // backgroundColor: 'rgba(255,255,255,1)',
            // padding: [8, 8, 8, 8]
            // width: 50,
            // overflow: 'truncate',
            // ellipsis: '...'
          },
          label: {
            show: true,
            position: 'bottom',
            formatter: '{b}',
            color: '#535561'
            // width: 50,
            // overflow: 'truncate',
            // ellipsis: '...'
          },
          labelLayout: {
            // 注意：设置后会出现 鼠标平移漫游时 连接线上的 label文字不动的bug
            // hideOverlap: false //是否隐藏重叠的标签。
            // moveOverlap: 'shiftX' //在标签重叠的时候是否挪动标签位置以防止重叠。
          },
          // scaleLimit: { min: 0.8, max: 5 }, //最小的缩放值 最大的缩放值
          lineStyle: { color: '#C7CBD4', curveness: 0.2 }, //curveness:边的曲度，支持从 0 到 1 的值，值越大曲度越大。
          force: {
            edgeLength: [230, 120], //边的两个节点之间的距离
            repulsion: [150, 600], //节点之间的斥力因子,值越大则斥力越大
            gravity: 0.05 //中心的引力因子。该值越大节点越往中心点靠拢。 0.1
          }
        }
      ]
    }
    curChart.setOption(option)
  }, [chart])

  // 图谱数据变化后重新渲染
  useEffect(() => {
    const { data, links } = subjectGraphStore.getNodeEdge

    let option = {
      series: [
        {
          data,
          links
        }
      ]
    }
    console.log(option)
    chart && chart.setOption(option)
  }, [chart, subjectGraphStore.nodeTree])

  // 触发图谱数据变化
  useEffect(() => {
    webSdk.subjectGraph.getGraphNode(0).then((res) => {
      // console.log(res, mockNodes)
      res = mockNodes
      subjectGraphStore.updateNodeTree({
        edges: res.data.edge,
        rootNode: res.data.rootNode
      })
      setCurrentNode(res.data.rootNode[0])
    })
  }, [selectedTab])

  return (
    <div className="subject-graph">
      <Tabs
        defaultActiveKey={selectedTab}
        onChange={(key) => setSelectedTab(key)}
      >
        <TabPane tab="管理学" key="管理学"></TabPane>
        <TabPane tab="经济学" key="经济学"></TabPane>
        <TabPane tab="金融学" key="金融学"></TabPane>
      </Tabs>
      <div className="subject-graph-body">
        <div id="graph-main"></div>
        <div className="graph-right">
          <h4
            style={{
              fontWeight: 'bold',
              marginTop: '12px',
              marginBottom: '12px'
            }}
          >
            {selectedTab}
          </h4>
          {/* <h4>定义模型</h4>
          <RightItem attrs={attrs1} /> */}
          <h4 className="rela-node">相关节点</h4>
          <RightItem attrs={attr} title={currentNode.name} />
          {/* <RightItem attrs={attrs1} />
          <RightItem attrs={attrs1} /> */}
        </div>
      </div>
    </div>
  )
}

export default observer(SubjectGraph)
