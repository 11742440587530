/*
 * @Description:
 * @LastEditTime: 2022-09-27 15:34:21
 * @LastEditors: liqun 13212226759@163.com
 */
import React, { useRef, useState } from 'react'
import { Input, Empty } from 'antd'
import './MySearch.scss'

const { Search } = Input

function MySearch({ searchByKeyword, delay = 1000, searchClick }) {
  const [keyword, setKeyword] = useState('')
  const [list, setList] = useState([])
  const [isHideSelect, setIsHideSelect] = useState(true)
  let timer = useRef()
  // 点击搜索按钮
  const searchKeyword = (word, item) => {
    console.log(word)
    searchClick && searchClick(word, item)
  }

  const searchChange = (event) => {
    const key = event.target.value
    setKeyword(key)
    if (searchByKeyword) {
      if (timer.current) {
        clearTimeout(timer.current)
      }
      timer.current = setTimeout(async () => {
        const newList = await searchByKeyword(key)
        if (!Array.isArray(newList)) {
          return console.error('searchByKeyword 函数需要返回数组')
        }
        setList(newList)
        setIsHideSelect(false)
      }, delay)
    }
  }

  // 选择下拉列表的某一个
  const chooseOne = (item) => {
    setKeyword(item.name)
    setIsHideSelect(true)
    searchKeyword(item.name, item)
  }

  return (
    <div className="my-search">
      <span>搜索</span>
      <Search
        placeholder="请输入关键词"
        onSearch={searchKeyword}
        onChange={searchChange}
        onPressEnter={searchKeyword}
        style={{ width: 200 }}
        value={keyword}
        onBlur={() => setTimeout(() => setIsHideSelect(true), 200)}
      />
      {!isHideSelect && (
        <ul className="relate-select">
          {list.length ? (
            list.map((item, index) => {
              return (
                <li
                  key={index}
                  className="select-item"
                  title={item.name}
                  onClick={() => chooseOne(item)}
                >
                  <span className="select-item-name">{item.name}</span>
                  <span>{item.stock_symbol}</span>
                </li>
              )
            })
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </ul>
      )}
    </div>
  )
}

export default MySearch
