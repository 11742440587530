import React from 'react'
import {Pagination, Spin, Anchor, message, Empty, Affix} from 'antd';
import {webSdk} from "../../../api/WebSdk";
import './SearchResultList.scss'
import RightHotSearch from "../CompanyList/result/RightHotSearch";
import RightHistorySearch from "../CompanyList/result/RightHistorySearch";
import queryString from 'query-string'
import _ from 'lodash'

const {Link} = Anchor;

class SearchResultList extends React.PureComponent {
    constructor(props) {
        super(props)

        let searchData = props.location.search || '';
        let page1 = 0,
            pageSize1 = 10,
            page2 = 0,
            pageSize2 = 10;
        if (searchData) {
            page1 = JSON.parse(queryString.parse(searchData)['page1'] || '0');
            pageSize1 = parseInt(queryString.parse(searchData)['pageSize1'] || '10');
            page2 = JSON.parse(queryString.parse(searchData)['page2'] || '0');
            pageSize2 = parseInt(queryString.parse(searchData)['pageSize2'] || '10');
        }

        this.state = {
            keyword: props.match.params.keyword || '',
            resultMenu: [
                {
                    label: '相关企业列表',
                    key: 'company'
                },
                {
                    label: '相关文章列表',
                    key: 'article'
                }
            ],
            companyList: [],
            companyPage: page1,
            companyTotal: 0,
            companySize: pageSize1,
            companyLoading: false,
            articleList: [],
            articlePage: page2,
            articleTotal: 0,
            articleSize: pageSize2,
            articleLoading: false,
        }

        this.getCompanyList = this.getCompanyList.bind(this);
        this.getArticleList = this.getArticleList.bind(this);
    }

    componentWillMount() {
        this.props.fnSetType('home', this.state.keyword || '');
        this.getArticleList();
        this.getCompanyList()
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.keyword !== this.state.keyword) {
            this.props.fnSetType('home', nextProps.match.params.keyword || '');
            this.setState({
                keyword: nextProps.match.params.keyword || '',
                articlePage: 0,
                articleTotal: 0,
                articleSize: 10,
                companyPage: 0,
                companyTotal: 0,
                companySize: 10,
            }, () => {
                this.getArticleList();
                this.getCompanyList()
            })
        }
        else if (nextProps.match.params.keyword === this.state.keyword && this.props.location.search!==nextProps.location.search) {
            let searchData = nextProps.location.search || '';
            let page1 = 0, pageSize1 = 10, page2 = 0, pageSize2 = 10;
            if (searchData) {
                page1 = JSON.parse(queryString.parse(searchData)['page1'] || '0');
                pageSize1 = parseInt(queryString.parse(searchData)['pageSize1'] || '10');
                page2 = JSON.parse(queryString.parse(searchData)['page2'] || '0');
                pageSize2 = parseInt(queryString.parse(searchData)['pageSize2'] || '10');
            }
            let oSsearchData = this.props.location.search || '';
            let opage1 = 0, opageSize1 = 10, opage2 = 0, opageSize2 = 10;
            if (searchData) {
                opage1 = JSON.parse(queryString.parse(oSsearchData)['page1'] || '0');
                opageSize1 = parseInt(queryString.parse(oSsearchData)['pageSize1'] || '10');
                opage2 = JSON.parse(queryString.parse(oSsearchData)['page2'] || '0');
                opageSize2 = parseInt(queryString.parse(oSsearchData)['pageSize2'] || '10');
            }
            this.setState({
                companyPage: page1,
                companySize: pageSize1,
                articlePage: page2,
                articleSize: pageSize2,
            }, () => {
                if (opage1 !== page1 || opageSize1 !== pageSize1) {
                    this.getCompanyList()
                }
                if (opage2 !== page2 || opageSize2 !== pageSize2) {
                    this.getArticleList();
                }
            })
        }
    }

    changeLocation = () =>{
        let searchData = `?page1=${this.state.companyPage}&pageSize1=${this.state.companySize}&page2=${this.state.articlePage}&pageSize2=${this.state.articleSize}`
        this.props.history.push({
            pathname: `${this.props.location.pathname}`,
            search: searchData
        })
    }

    /** 获取相关文章列表数据 */
    getArticleList() {
        this.setState({
            articleLoading: true
        }, () => {
            let postData = {
                keyword: this.state.keyword,
                pageIndex: this.state.articlePage,
                pageSize: this.state.articleSize
            }
            webSdk.search.getArticleList(postData).then(res => {
                this.setState({
                    articleLoading: false
                })
                if (!res.errCode || res.errCode === 0) {
                    let pageData = res.pager,
                        articleList = res.artlist;
                    this.setState({
                        articleList,
                        articleTotal: pageData.total,
                    })
                } else {
                    message.error(res.errMsg || '获取相关文章列表失败，请稍后重试');
                }
            }).catch(e => {
                this.setState({
                    articleLoading: false
                })
                message.error('获取相关文章列表失败，请稍后重试');
            })
        })
    }

    /** 获取相关企业列表 */
    getCompanyList() {
        this.setState({
            companyLoading: true
        }, () => {
            let postData = {
                type: 5,
                keyword: this.state.keyword,
                pageIndex: this.state.companyPage,
                pageSize: this.state.companySize
            }
            webSdk.search.researchReport(postData).then(res => {
                try {
                    if (!res.errCode || res.errCode === 0) {
                        let pageData = res.pager;
                        let pageTotal = pageData.total,
                            companyList = res.reportList;
                        if (companyList.length>0) {
                            companyList.forEach(item =>{
                                for (let key in item) {
                                    if (typeof item[key] ==='string') {
                                        item[key] = item[key].replace(postData.keyword,`<em hl>${postData.keyword}</em>`)
                                    }
                                }
                            })
                        }
                        this.setState({
                            companyList,
                            companyTotal: pageTotal || 0,
                            companyLoading: false
                        })
                    } else {
                        this.setState({
                            companyLoading: false
                        })
                        message.error(res.errMsg || '获取相关企业列表失败，请稍后重试');
                    }
                } catch (e) {
                    this.setState({
                        companyLoading: false
                    })
                    message.error('获取相关企业列表失败，请稍后重试');
                }
            }).catch(e => {
                this.setState({
                    companyLoading: false
                })
                message.error('获取相关企业列表失败，请稍后重试');
            })

        })
    }

    //顶部菜单栏变更事件
    changeMenu = e => {
        e.preventDefault();
    }

    /** 公司名称点击 */
    viewCompany = item => {
        this.props.history.push({
            pathname: `/companyInfo/${item.id}`
        })
    }

    /** 公司信息网站点击 */
    webSiteClick = item => {
        console.log(item)
        const w = window.open('about:blank');
        let website = item.homeSite.replace(/<.*?>/g, "") || '';
        if (website && !(/^http.*?/g).test(website)) {
            website = 'http://' + website
        }
        w.location.href = website
    }

    /** 公司模块页码变更 */
    changeCompanyPage = (page, pageSize) => {
        this.setState({
            companyPage: page - 1
        }, () => {
            this.changeLocation()
        })
    }

    /** 公司模块页尺寸变更 */
    changeCompanyPageSize = (current, size) => {
        this.setState({
            companySize: size,
            companyPage: 0
        }, () => {
            this.changeLocation()
        })
    }

    /** 文章列表点击 */
    viewArticle = item => {
        this.props.history.push({
            pathname: `/researchResult/${item.type}/${item.id}/101/${item.text[0].id}/${this.state.keyword}`
        })
    }

    /** 页码变更 */
    changeArticlePage = (page, pageSize) => {
        this.setState({
            articlePage: page - 1
        }, () => {
            this.changeLocation()
        })
    }

    /**
     * @current： 当前页
     * @size：修改后的尺寸
     */
    changeArticlePageSize = (current, size) => {
        this.setState({
            articleSize: size,
            articlePage: 0
        }, () => {
            this.changeLocation()
        })
    }

    rightItemClick = item => {
        this.props.history.push({
            pathname: `/resultList/${item.content}`
        })
    }

    render() {
        let CompanyModule = (
            <Spin spinning={this.state.companyLoading}>
                <div className="module-main">
                    {
                        this.state.companyList.length === 0 ? (
                            <Empty/>
                        ) : (
                            <>
                                <ul className="module-content">
                                    {
                                        this.state.companyList.map((item, index) => {
                                            return (
                                                <li key={index} className="company-item">
                                                    <div className="left-img">
                                                        <div className="company-item-img" style={{
                                                            background: `url("${item.titlePic}") center center no-repeat`,
                                                            backgroundSize: '100% 100%'
                                                        }}>
                                                            {
                                                                !item.titlePic?'暂无图片':null
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="right-info">
                                                        <div className="company-name"
                                                             onClick={() => this.viewCompany(item)}
                                                             dangerouslySetInnerHTML={{__html: item.title}}
                                                             title={item.title.replace(/<.*?>/g, "")}/>
                                                        <p className="company-person-info">
                                                            {
                                                                item.legalRep ? (
                                                                    <span className="company-item-info-item">
                                                                    <span className="info-item-label">法定代表人：</span>
                                                                    <span className="info-item-txt"
                                                                          title={item.legalRep.replace(/<.*?>/g, "")}
                                                                          dangerouslySetInnerHTML={{__html: item.legalRep}}/>
                                                                </span>) : null
                                                            }
                                                            {
                                                                item.regAssets ? (
                                                                    <span className="company-item-info-item">
                                                                    <span className="info-item-label">注册资本：</span>
                                                                    <span className="info-item-txt"
                                                                          title={item.regAssets.replace(/<.*?>/g, "")}
                                                                          dangerouslySetInnerHTML={{__html: item.regAssets}}/>
                                                                </span>) : null
                                                            }
                                                            {
                                                                item.foundedTime ? (
                                                                    <span className="company-item-info-item">
                                                                    <span className="info-item-label">成立时间：</span>
                                                                    <span className="info-item-txt"
                                                                          title={item.foundedTime.replace(/<.*?>/g, "")}
                                                                          dangerouslySetInnerHTML={{__html: item.foundedTime}}/>
                                                                </span>) : null
                                                            }
                                                        </p>
                                                        <p className="company-address">
                                                            {
                                                                item.officeAddr ? (
                                                                    <span className="company-item-info-item dizhi">
                                                                    <span className="info-item-label">办公地址：</span>
                                                                    <span className="info-item-txt"
                                                                          title={item.officeAddr.replace(/<.*?>/g, "")}
                                                                          dangerouslySetInnerHTML={{__html: item.officeAddr}}/>
                                                                </span>) : null
                                                            }
                                                            {
                                                                item.homeSite ? (
                                                                    <span className="company-item-info-item wangzhi">
                                                                    <span className="info-item-label">公司网址：</span>
                                                                    <span className="info-item-txt webSite-info"
                                                                          title={item.homeSite.replace(/<.*?>/g, "")}
                                                                          onClick={() => this.webSiteClick(item)}
                                                                          dangerouslySetInnerHTML={{__html: item.homeSite}}/>
                                                                </span>) : null
                                                            }
                                                        </p>
                                                        {
                                                            item.relateInfo ? (
                                                                <p className="company-more-info">
                                                                <span className="company-item-info-item">
                                                                    <span className="info-item-label">相关信息：</span>
                                                                    <span className="info-item-txt"
                                                                          title={item.relateInfo.replace(/<.*?>/g, "")}
                                                                          dangerouslySetInnerHTML={{__html: item.relateInfo}}/>
                                                                </span>
                                                                </p>) : null
                                                        }
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>

                                <Pagination
                                    showSizeChanger
                                    showQuickJumper
                                    total={this.state.companyTotal}
                                    showTotal={total => `共 ${total} 条`}
                                    pageSize={this.state.companySize}
                                    pageSizeOptions={['10', '15', '20', '25']}
                                    current={this.state.companyPage + 1}
                                    onChange={this.changeCompanyPage}
                                    onShowSizeChange={this.changeCompanyPageSize}
                                />
                            </>
                        )
                    }
                </div>
            </Spin>
        )
        let ArticleModule = (
            <Spin spinning={this.state.articleLoading}>
                <div className="module-main">
                    {
                        this.state.articleList.length === 0 ? (
                            <Empty/>
                        ) : (
                            <>
                                <ul className="module-content">
                                    {
                                        this.state.articleList.map((item, index) => {
                                            return (
                                                <li key={item.id} className="article-item"
                                                    onClick={() => this.viewArticle(item)}>
                                                    <h4 className="article-item-title"
                                                        dangerouslySetInnerHTML={{__html: item.title || '-'}}/>
                                                    <p className="article-item-text"
                                                       dangerouslySetInnerHTML={{__html: item.text[0].text || '-'}}/>
                                                    <div className="article-item-footer">
                                                        <div
                                                            className="article-item-dataFrom">来源： {item.source || '-'}</div>
                                                        <div className="article-item-author"><span
                                                            style={{marginRight: 20}}>作者</span>{item.author || '-'}
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>

                                <Pagination
                                    showSizeChanger
                                    showQuickJumper
                                    total={this.state.articleTotal}
                                    showTotal={total => `共 ${total} 条`}
                                    pageSize={this.state.articleSize}
                                    pageSizeOptions={['10', '15', '20', '25']}
                                    current={this.state.articlePage + 1}
                                    onChange={this.changeArticlePage}
                                    onShowSizeChange={this.changeArticlePageSize}
                                />
                            </>
                        )
                    }
                </div>
            </Spin>
        )
        return (
            <div className="result-list">
                {/*顶部*/}
                <div className="result-list-topMenu">
                    <Anchor affix={false} onClick={this.changeMenu} offsetTop={130}>
                        {
                            this.state.resultMenu.map((item, index) => {
                                return (
                                    <Link key={index} href={'#menu_' + item.key} title={item.label}
                                          className="top-menu-item"/>
                                )
                            })
                        }
                    </Anchor>
                </div>

                <div className="result-list-main">
                    {/*左侧*/}
                    <div className="list-result-left">
                        <div className="left-module" id='menu_company'>
                            <h4 className="module-title">相关企业列表</h4>
                            {CompanyModule}
                        </div>
                        <div className="left-module" id='menu_article'>
                            <h4 className="module-title">相关文章列表</h4>
                            {ArticleModule}
                        </div>
                    </div>

                    {/*右侧*/}
                    <div className="list-result-right">
                        <Affix offsetTop={132}>
                            <RightHotSearch fnItemClick={this.rightItemClick}/>

                            <RightHistorySearch fnItemClick={this.rightItemClick}/>
                        </Affix>
                    </div>
                </div>
            </div>
        )
    }
}

export default SearchResultList