import React from 'react'
import {Menu} from 'antd';
import {Route,Redirect, Switch} from "react-router-dom"
import ProfitBaseInfo from './profit/ProfitBaseInfo'           //利润概况总览
import CostIncome from './profit/CostIncome'
import FinancialCaseSheet from "./cash/FinancialCaseSheet";
import FinancialAssetsInfo from "./assets/FinancialAssetsInfo";
import FinancialLiabilityInfo from "./assets/FinancialLiabilityInfo";
import FinancialOwnerInterests from "./assets/FinancialOwnerInterests";
import FinancialAssetsLiability from "./assets/FinanciAlassetsLiability";
import FinancialView from "./FinancialView/FinancialView";                     //利润概况-成本/支出
import './FinancialIndex.scss'

const {SubMenu} = Menu;

class FinancialIndex extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            leftMenu: [
                {
                    "label": "财务分析",
                    "key": "financialView"
                },
                {
                    "label": "财务利润表",
                    "key": "profitInfo",
                    "children": [
                        {
                            "label": "利润概况",
                            "key": "profitView"
                        },
                        {
                            "label": "利润表",
                            "key": "profitTable"
                        }
                    ]
                },
                {
                    "label": "资产负债概况",
                    "key": "assetInfo",
                    "children": [
                        {
                            "label": "资产情况",
                            "key": "assetView"
                        },
                        {
                            "label": "负债情况",
                            "key": "liabilitiesView"
                        },
                        {
                            "label": "所有者权益",
                            "key": "ownerEquities"
                        }
                    ]
                },
                {
                    "label": "财务现金流",
                    "key": "cashFlow",
                    "children": [
                        {
                            "label": "现金收入",
                            "key": "cashIncome"
                        },
                        {
                            "label": "现金支出",
                            "key": "cashOutlay"
                        }
                    ]
                }
            ],           //右侧菜单
            keyword: props.match.params.keyword || '',
            currentMenu: '',
            openMenu: []
        }

        this.changeMenu = this.changeMenu.bind(this);
        this.openChange = this.openChange.bind(this);
    }

    componentDidMount() {
        let pathname = this.props.location.pathname;
        let pathArr = pathname.split('/');
        let currentMenu = '';
        let openMenu = []
        if(pathname.indexOf('/financial/financialView')!==-1) {
            currentMenu = 'financialView'
        }
        else if(pathname.indexOf('/financial/profitView')!==-1) {
            currentMenu = 'profitView';
            openMenu = ['profitInfo']
        }
        else if(pathname.indexOf('/financial/profitTable')!==-1) {
            currentMenu = 'profitTable';
            openMenu = ['profitInfo']
        }
        else if(pathname.indexOf('/financial/assetView')!==-1) {
            currentMenu = 'assetView';
            openMenu = ['assetInfo']
        }
        else if(pathname.indexOf('/financial/liabilitiesView')!==-1) {
            currentMenu = 'liabilitiesView';
            openMenu = ['assetInfo']
        }
        else if(pathname.indexOf('/financial/ownerEquities')!==-1) {
            currentMenu = 'ownerEquities';
            openMenu = ['assetInfo']
        }
        else if(pathname.indexOf('/financial/cashIncome')!==-1) {
            currentMenu = 'cashIncome';
            openMenu = ['cashFlow']
        }
        else if(pathname.indexOf('/financial/cashOutlay')!==-1) {
            currentMenu = 'cashOutlay';
            openMenu = ['cashFlow']
        }
        else {
            currentMenu = 'financialView'
        }
        this.setState({
            keyword: pathArr[2],
            currentMenu,
            openMenu
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({

        });
    }

    changeMenu(ev) {
        //debugger
        let key = ev.key;
        let keyPath = ev.keyPath;
        if(keyPath.length>1) {
            keyPath.splice(0,1)
        }
        let newSet = new Set();
        this.state.openMenu.forEach(item =>{
            newSet.add(item)
        })
        keyPath.forEach(item =>{
            newSet.add(item)
        })
        this.setState({
            currentMenu: key,
            openMenu: [...newSet]
        })
        this.props.history.push({
            pathname: `/companyResult/${this.state.keyword}/financial/${key}`
        })
    }

    openChange(openKeys) {
        this.setState({
            openMenu: openKeys
        })
    }

    render() {
        let match = this.props.match;
        return (
            <div className="financial-result">
                <div className="left-menu">
                    <Menu
                        onClick={this.changeMenu}
                        style={{width: 220}}
                        selectedKeys={[this.state.currentMenu]}
                        openKeys={this.state.openMenu}
                        onOpenChange={(openKeys) =>this.openChange(openKeys)}
                        mode="inline"
                    >
                        {
                            this.state.leftMenu.map((item, index) => {
                                if (item.children && item.children.length > 0) {
                                    return (
                                        <SubMenu
                                            key={item.key}
                                            title={
                                                <span>{item.label}</span>
                                            }
                                        >
                                            {
                                                item.children.map((child, _index) => {
                                                    if (child.children && child.children.length > 0) {
                                                        return (
                                                            <SubMenu
                                                                key={child.key}
                                                                title={
                                                                    <span>{child.label}</span>
                                                                }
                                                            >
                                                                {
                                                                    child.children.map((_item, _index) => {
                                                                        return (
                                                                            <Menu.Item key={_item.key}>{_item.label}</Menu.Item>
                                                                        )
                                                                    })
                                                                }
                                                            </SubMenu>
                                                        )
                                                    } else {
                                                        return (
                                                            <Menu.Item key={child.key}>{child.label}</Menu.Item>
                                                        )
                                                    }})
                                            }
                                        </SubMenu>
                                    )
                                }
                                else {
                                    return (
                                        <Menu.Item key={item.key}>{item.label}</Menu.Item>
                                    )
                                }})
                        }
                    </Menu>
                </div>
                <div className="financial-right-main" style={{paddingLeft: 234}}>
                    <Switch>
                        <Route path={`${match.url}/financialView`} component={FinancialView}/>
                        <Route path={`${match.url}/profitView`} component={ProfitBaseInfo}/>
                        <Route path={`${match.url}/profitTable`} component={FinancialCaseSheet} />
                        <Route path={`${match.url}/assetView`} component={FinancialAssetsInfo}/>
                        <Route path={`${match.url}/liabilitiesView`} component={FinancialLiabilityInfo}/>
                        <Route path={`${match.url}/ownerEquities`} component={FinancialOwnerInterests}/>
                        <Route path={`${match.url}/cashIncome`} component={CostIncome}/>
                        <Route path={`${match.url}/cashOutlay`} component={CostIncome}/>

                        <Redirect exact from={`${match.url}`} to={`${match.url}/financialView`}/>
                    </Switch>
                </div>
            </div>
        )
    }
}

export default FinancialIndex