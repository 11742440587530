import _ from 'lodash'
import moment from 'moment'
//import { arrOffset, mergeMockApi } from './mockWebApi'
import { mergeMockApi } from './mockWebApi'

import EntersInfoApi from './EntersInfoApi'

export class EntersInfoApiMock extends EntersInfoApi {
    constructor(opts) {
        super(opts)
        this.mockData = async (url, delay) => {
            delay = (delay || 10) + _.random(0, 1) // 模拟服务端延迟
            const resp = await this.gate.fake.get(url);
            return await new Promise(resolve => {
                setTimeout(() => {
                    resolve(resp);
                }, delay);
            });
        }
    }

    /**获取所有的行业*/
    async getAllIndustry() {
        let resp = await this.mockData('entersInfo/index/get_all_industry.json', 300);
        return resp.data
    }

    /**获取企业列表*/
    async getEnterpriseListMock({ keyword, vocation, filterDate, province }) {
        let resp = await this.mockData('entersInfo/index/get_all_enters.json', 300);
        let resData = resp.data.data
        let respKeyword = []
        let respVocation = []
        let respDate = []
        let respProvince = []

        //关键词筛选
        if (keyword) {
            if (keyword.indexOf("平安") > -1 || keyword.indexOf("平安银行") > -1 || keyword.indexOf("平安银行股份有限公司") > -1) {
                let paKey = await this.mockData('entersInfo/index/get_all_enters_pa.json');
                respKeyword = _.concat(respKeyword, paKey.data.data)
            }
            if (keyword.indexOf("万科") > -1 || keyword.indexOf("万科A") > -1 || keyword.indexOf("万科企业") > -1 || keyword.indexOf("万科企业股份有限公司") > -1) {
                let wkKey = await this.mockData('entersInfo/index/get_all_enters_wk.json');
                respKeyword = _.concat(respKeyword, wkKey.data.data)
            }
            if (keyword.indexOf("大族") > -1 || keyword.indexOf("大族激光") > -1 || keyword.indexOf("大族激光科技") > -1 || keyword.indexOf("大族激光科技产业集团股份有限公司") > -1) {
                let wdzKey = await this.mockData('entersInfo/index/get_all_enters_dz.json');
                respKeyword = _.concat(respKeyword, wdzKey.data.data)
            }
            resData = _.intersectionBy(resData, respKeyword, "name")
        }


        //行业筛选
        console.log(vocation)
        if (vocation) {
            if (vocation.indexOf("金融业") != -1) {
                let paVoca = await this.mockData('entersInfo/index/get_all_enters_pa.json');
                respVocation = _.concat(respVocation, paVoca.data.data)
            }
            if (vocation.indexOf("房地产业") != -1) {
                let wkVoca = await this.mockData('entersInfo/index/get_all_enters_wk.json');
                respVocation = _.concat(respVocation, wkVoca.data.data)
            }
            if (vocation.indexOf("制造业") != -1) {
                let dzVoca = await this.mockData('entersInfo/index/get_all_enters_dz.json');
                respVocation = _.concat(respVocation, dzVoca.data.data)
            }

            resData = _.intersectionBy(resData, respVocation, "name")
        }

        //过滤时间
        if (filterDate) {
            let formateStyle = "YYYY/MM/DD"
            let startTimeStr = _.split(filterDate, ",")[0]
            let endTimeStr = _.split(filterDate, ",")[1]
            if (startTimeStr && startTimeStr) {

                let startTime = moment(startTimeStr, formateStyle)
                let endTime = moment(endTimeStr, formateStyle)
                let paTime = moment("1987/12/22", formateStyle)
                let wkTime = moment("1984/05/30", formateStyle)
                let dzTime = moment("1999/03/04", formateStyle)

                if (moment.min(startTime, paTime) == startTime && moment.max(endTime, paTime) == endTime) {
                    let paResp = await this.mockData('entersInfo/index/get_all_enters_pa.json');
                    respDate = _.concat(respDate, paResp.data.data)
                }

                if (moment.min(startTime, wkTime) == startTime && moment.max(endTime, wkTime) == endTime) {
                    let wkResp = await this.mockData('entersInfo/index/get_all_enters_wk.json');
                    respDate = _.concat(respDate, wkResp.data.data)
                }

                if (moment.min(startTime, dzTime) == startTime && moment.max(endTime, dzTime) == endTime) {
                    let dzResp = await this.mockData('entersInfo/index/get_all_enters_dz.json');
                    respDate = _.concat(respDate, dzResp.data.data)
                }
            }
            resData = _.intersectionBy(resData, respDate, "name")
        }


        //过滤地区
        if (province) {
            if (province.indexOf("广东") != -1) {
                resp = await this.mockData('entersInfo/index/get_all_enters_pa.json');
                respProvince = _.concat(respProvince, resp.data.data)
            }
            if (province.indexOf("广东") != -1) {
                resp = await this.mockData('entersInfo/index/get_all_enters_wk.json');
                respProvince = _.concat(respProvince, resp.data.data)
            }
            if (province.indexOf("广东") != -1) {
                resp = await this.mockData('entersInfo/index/get_all_enters_dz.json');
                respProvince = _.concat(respProvince, resp.data.data)
            }
            resData = _.intersectionBy(resData, respProvince, "name")
        }

        return {
            total: resData.length,
            data: resData
        }
    }

    /**获取企业历史*/
    async getEnterHistoryInfo({ tickerSymbol, year }) {

        let resp = await this.mockData('entersInfo/detail/get_enter_history.json');
        return resp.data
    }

    /**获取供应链信息*/
    async getSupplyChainInfo({ tickerSymbol, year }) {

        let resp = await this.mockData('entersInfo/detail/get_supply_chain.json');
        return resp.data
    }

    /**获取组织架构信息*/
    async getFrameworkInfo({ tickerSymbol, year }) {

        let resp = await this.mockData('entersInfo/detail/get_framework.json');
        return resp.data
    }

    /**获取商业模式信息*/
    async getBusinessModelInfo({ tickerSymbol, year }) {

        let resp = await this.mockData('entersInfo/detail/get_business_model.json');
        return resp.data
    }
}

export default mergeMockApi(EntersInfoApi, EntersInfoApiMock)