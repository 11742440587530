import React, { PureComponent, useEffect, useState } from 'react'
import _ from 'lodash'
import go from './rightFnGroup/global'
import './RightList.scss'
import { Input, Table, Modal } from 'antd'
import * as request from './rightFnGroup/request'
import DialogRobot from './rightFnGroup/DialogRobot'
import DialogCharts from './rightFnGroup/Charts'
import DialogDownload from './rightFnGroup/DialogDownload'
import Parser from './rightFnGroup/Parser.js'
import { webSdk } from '@/api/WebSdk'
import { Progress } from 'antd'

// const { Search } = Input
var moment = require('moment')
require('moment/locale/zh-cn')

let temp = {}
function Loading({ uid, progress_key }) {
  let [progress, setProgress] = useState(0)
  let [loading, setLoading] = useState(true)
  let timer = setInterval(() => {
    if (temp[uid]) {
      clearInterval(timer)
      setLoading(false)
    }
  }, 1000)
  useEffect(() => {
    function updateProgress() {
      webSdk.apiCenter
        .getProgress({
          _req_id: progress_key
        })
        .then((result) => {
          if (!result) {
            setProgress(0.99)
            setTimeout(() => setProgress(1), 200)
          } else {
            setProgress(result.progress / result.total)
            updateProgress()
          }
        })
    }
    updateProgress()
  }, [])
  return progress !== 1 ? (
    <div style={{ marginTop: '20px' }}>
      解析进度：
      <Progress
        strokeColor={{
          '0%': '#108ee9',
          '100%': '#87d068'
        }}
        percent={progress.toFixed(2) * 100}
      />
    </div>
  ) : loading ? (
    <div className="loading-wrapper">
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
    </div>
  ) : null
}
class RightList extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      /////聊天数据S////
      chatItems: [],
      //引用内容
      quote: '',
      //是否隐藏引用-默认是
      isHideQuote: 'hide',
      //待发送内容
      sendText: '',
      //当前点击的详情按钮uid key(包含搜索结果和问题解析)
      curDetailBtnKey: -1,
      /////聊天数据E////

      //搜索结果数据-累积
      searchData: {},
      //当前搜索项
      curSearchData: {},
      //解析结果数据
      parserData: {},
      //当前搜解析项
      curParserData: {},
      //文章列表数据
      artList: [],
      pageName: 'search',
      visible: false
      // pageName:'parser'
    }
  }
  componentDidMount() {
    this.addChatInitMsg()
    setImmediate(() => this.addChatInitQuestion())
  }
  componentDidUpdate(nextProps, nextState) {}
  //追加聊天内容-初始化内容
  addChatInitMsg = (obj) => {
    obj = obj ? obj : {}
    obj.str = (
      <p>
        您好，我是小智！
        <br />
        欢迎使用智能知识库！您可以向我咨询各种问题。
      </p>
    )
    console.log(moment.locale())
    let dom = (
      <DialogRobot
        keyId={go.uid()}
        content={obj.str}
        time={moment().format('lll')}
      />
    )
    this.setChat({ items: dom })
  }
  searchInitQuestion = (sendText) => {
    this.setState(
      {
        sendText,
        isHideQuote: 'hide',
        quote: ''
      },
      () => this.sendContent()
    )
  }
  //追加聊天内容-初始化内容-没有问题时显示预置问题
  addChatInitQuestion = (obj) => {
    obj = obj ? obj : {}
    obj.str = (
      <div className="init-question">
        <p className="title">您问的问题是不是：</p>
        {[
          '地理信息产业分析',
          '地理信息产业链分析',
          '地理产业竞争分析',
          '产业规模'
        ].map((item) => {
          return (
            <p
              key={item}
              className="question"
              onClick={() => this.searchInitQuestion(item)}
            >
              {item}
              <br />
            </p>
          )
        })}
      </div>
    )
    let dom = (
      <DialogRobot
        keyId={go.uid()}
        content={obj.str}
        time={moment().format('lll')}
      />
    )
    // (
    //   <div className="msg clearfix robot" key={go.uid()}>
    //     <img src={require('./rightFnGroup/img/robot.png')} className="avatar" />
    //     <p className="robot-name">智能小助手</p>
    //     <div className="article">{obj.str}</div>
    //     <div className="time">{moment().format('lll')}</div>
    //   </div>
    // )
    this.setChat({ items: dom })
  }
  //引用
  byQuote = (event) => {
    //获取引用内容
    let text = $(event.target).closest('.msg').find('.article').text()
    //更新全局
    go.quote = text

    this.setState({ isHideQuote: '', quote: text })
  }
  //隐藏引用
  hideQuote = (event) => {
    this.setState({ isHideQuote: 'hide', quote: '' })
  }
  //创建搜索结果数据-Chat组件使用
  createSearchData = (key, value) => {
    // console.log(key,value)
    let items = go.copy(this.state.searchData)
    // console.log(items)
    items[key] = value
    this.setState({
      searchData: items,
      curSearchData: { key: key, value: value }
    })
  }
  //创建文章列表数据(添加)
  createArtListData = (obj) => {
    obj = obj ? obj : {}
    obj.type = obj.type ? obj.type : 'change' //默认是切换,add是滚动加载
    let data = obj.data //data即curSearchData
    let artList = []
    //分为添加和初始化创建
    if (obj.type === 'add') {
      //添加
      let tmpArtList = go.copy(data.answer.artList)
      let cur_artList = go.copy(this.state.artList)
      artList = cur_artList.concat(tmpArtList)
    } else {
      //初始化
      if (!$.isEmptyObject(data.answer)) {
        artList = go.copy(data.answer.artList)
      }
    }
    this.setState({ artList: artList })
  }
  //设置聊天数据
  setChat = (obj) => {
    obj = obj ? obj : {}
    obj.type = obj.type ? obj.type : 'add'
    console.log('setChat', obj.items)

    //聊天数据新增
    if (obj.type === 'add') {
      let items = go.copy(this.state.chatItems)
      items.push(obj.items)
      this.setState({ chatItems: items })
    }
  }
  sendContent = () => {
    //发送Neri
    let str = this.state.sendText
    let quote = this.state.quote
    let uid = go.uid()
    if (str === '') {
      message.warning('发送内容不能为空')
      return
    }

    //发送内容
    this.addChatMsgBySend({ str: str, uid: uid })

    //获取问题解析
    this.addChatMsgByParser({ str: str, quote: quote, uid: uid })

    //清空输入框
    this.setState({ sendText: '' })

    //删除引用
    if (quote !== '') {
      this.hideQuote()
    }
  }
  //追加聊天内容-发送内容
  addChatMsgBySend = (obj) => {
    obj = obj ? obj : {}
    let uid = obj.uid ? obj.uid : go.uid()
    let domUid = 'ask' + uid

    let dom = (
      <div className="add-chat">
        <div className="msg ask fr userself" key={domUid} mkey={uid}>
          <div className="article">{obj.str}</div>
          <span
            className="quote"
            onClick={(e) => {
              this.byQuote(e)
            }}
          >
            引用
          </span>
        </div>
        <div className="time">{moment().format('lll')}</div>
      </div>
    )
    this.setChat({ items: dom })
    go.scrollToBottom()
  }
  //追加聊天内容-搜索结果
  addChatMsgBySearchRes = (obj) => {
    obj = obj ? obj : {}
    let uid = obj.uid ? obj.uid : go.uid()

    //为了将搜索结果tab标题替换为问题解析的标题
    let val_format = obj.parse.text ? obj.parse.text : ''
    let params = { content: obj.str, quote: obj.quote, parse: obj.parse }
    if (obj.intent) {
      params.intent = obj.intent
    }
    request.post('/qa/searcher', params).then(async (res1) => {
      if (!res1) return
      if (!res1.errCode && !$.isEmptyObject(res1.data)) {
        console.log('searcher:key', res1.data)

        let res
        if (res1.data.progress_key) {
          // 异步获取结果
          this.setChat({
            items: <Loading uid={uid} progress_key={res1.data.progress_key} />
          })
          res = await webSdk.apiCenter.getModelResult({
            _req_id: res1.data.resp_key,
            poll_Interval: 10
          })
          temp[uid] = true
          console.log('searcher:res.data', res.data)
          res = {
            data: res.data.result
          }
        } else {
          res = res1
        }

        // let resText = res.data.answer.text.val
        let domUid = 'search' + uid
        res.data.answer.text.val_format = val_format
        const dataSource = res.data.answer.table.body.tr.map((item) => {
          let obj = {}
          for (let key in item) {
            obj[key] = item[key]['td']
          }
          return obj
        })
        const width =
          Math.floor(100 / res.data.answer.table.body.th.length) + '%'
        const columns = res.data.answer.table.body.th.map((item) => {
          return {
            key: item.key,
            title: item.name,
            dataIndex: item.key,
            width
          }
        })

        let dom = (
          <div className="clearfix" key={domUid} mkey={domUid}>
            <div>{res.data.answer.table.head.title}</div>
            <Table
              pagination={false}
              columns={columns}
              dataSource={dataSource}
              size="small"
              rowKey={(record, index) => {
                return index
              }}
            />
          </div>
          // <div className="msg clearfix" key={domUid} mkey={domUid}>
          //   <div className="article">
          //     <p>{resText}</p>
          //     <div className={'ana-detail mbtn'}>
          //       <span
          //         onClick={() => {
          //           this.showSearchPage(uid)
          //         }}
          //       >
          //         查看搜索结果
          //       </span>
          //     </div>
          //   </div>
          // </div>
        )
        this.setChat({
          items: (
            <DialogRobot
              keyId={go.uid()}
              content={dom}
              time={moment().format('lll')}
            />
          )
        })
        if (res.data.answer.table.body.tr.length) {
          setImmediate(() => {
            let uid = 'ask' + go.uid()
            let dom = (
              <DialogCharts uid={uid} tableData={res.data.answer.table} />
            )
            this.setChat({
              items: dom
            })
            go.scrollToBottom()
          })
        }
        let data_source = res.data.answer.data_source
        if (data_source) {
          if (data_source.source_type === 'file') {
            setImmediate(() => {
              let uid = 'download' + go.uid()
              let dom = <DialogDownload uid={uid} dataSource={data_source} />
              this.setChat({
                items: dom
              })
              go.scrollToBottom()
            })
          }
        }
        this.createSearchData(uid, res.data)
        this.createArtListData({ data: res.data }) //创建搜索页面显示的文章
        go.scrollToBottom()
      }
    })
  }

  //创建解析结果数据-Chat组件使用
  createParserData = (key, value) => {
    // console.log(key,value)
    let items = go.copy(this.state.parserData)
    // console.log(items)
    items[key] = value
    this.setState({
      parserData: items,
      curParserData: { key: key, value: go.copy(value) }
    })
  }

  //追加聊天内容-问题解析
  addChatMsgByParser = (obj) => {
    obj = obj ? obj : {}
    obj.type = obj.type ? obj.type : 'api' //api:api添加,data:从给定数据添加
    let intent = obj.intent ? obj.intent : ''
    let uid = obj.uid ? obj.uid : go.uid()

    let dom
    if (obj.type === 'data') {
      //这里是submit后执行,从给定数据添加
      if (!$.isEmptyObject(obj.parse)) {
        //发送内容
        this.addChatMsgBySend({ str: obj.str, uid: uid })

        dom = this.addChatMsgByParserData(obj.parse, uid)
        this.setChat({ items: dom }) //添加问答内容-解析
        this.createParserData(uid, obj.parse) //创建页面解析数据-新增

        //获取搜索结果
        this.addChatMsgBySearchRes({
          str: obj.str,
          intent: intent,
          quote: obj.quote,
          parse: obj.parse,
          uid: uid
        })

        this.setState({ curDetailBtnKey: uid }) //详情按钮显示隐藏
        go.scrollToBottom()
      }
    } else {
      //从api接口数据添加
      request
        .post('/qa/parser', { content: obj.str, quote: obj.quote })
        .then((res) => {
          // console.log(res);
          if (!res.errCode && !$.isEmptyObject(res.data)) {
            dom = this.addChatMsgByParserData(res.data, uid)
            this.setChat({ items: dom })
            this.createParserData(uid, res.data)

            //获取搜索结果
            this.addChatMsgBySearchRes({
              str: obj.str,
              intent: intent,
              quote: obj.quote,
              parse: res.data,
              uid: uid
            })
            go.scrollToBottom()
          }
        })
    }
  }

  //显示解析页面
  showParserPage = (uid) => {
    let parserData = go.copy(this.state.parserData)
    parserData[uid].isHide = false
    this.setState({
      pageName: 'parser',
      parserData: parserData,
      curParserData: { key: uid, value: go.copy(parserData[uid]) },
      visible: true
    })
  }

  //从给定的解析数据设置聊天内容
  addChatMsgByParserData = (parserData, uid) => {
    let dom = null
    let domUid = 'parser' + uid

    if (!$.isEmptyObject(parserData)) {
      let data = parserData
      let wslot = []
      if (data.word_slot && data.word_slot.length) {
        let slotData = data.word_slot
        //TODO这里最多展示三个
        for (let skey = 0; skey < 3; skey++) {
          if (slotData[skey] && slotData[skey].words.length) {
            slotData[skey].words.every((item) => {
              wslot.push(
                <div className="ana-list" key={go.uid()}>
                  <span className="tag">{slotData[skey].type_name}</span>
                  <span className={'tag-value ' + slotData[skey].type}>
                    {item.text}
                  </span>
                </div>
              )
              return false
            })
          }
        }
      }

      dom = (
        <>
          <div className="ana-result" key={domUid} mkey={domUid}>
            <div className="ana-item">
              <div className="ana-head">
                <span className="snote">解析结果：</span>
                <span className="sbnt mbtn">
                  {data.intent_name ? data.intent_name : ''}
                </span>
              </div>
              <div className="ana-content">{wslot}</div>
              <div
                className={
                  this.state.curDetailBtnKey === uid
                    ? 'ana-detail mbtn hide'
                    : 'ana-detail mbtn'
                }
                mkey={uid}
              >
                <span
                  onClick={(ele) => {
                    this.showParserPage(uid)
                  }}
                >
                  问题反馈
                </span>
              </div>
            </div>
          </div>
        </>
      )
    }
    return dom
  }
  //输入框变化
  handleChange = (event) => {
    let val = event.target.value
    this.setState({ sendText: val })
  }
  //修改当前解析数据项-SearchResult组件使用
  changeCurParserData = (obj) => {
    obj = obj ? obj : {}
    let ckey = obj.key ? obj.key : this.state.curParserData.key
    // console.log(ckey)

    //修改知识推断,必传参数key、value、type、infer_node
    if (obj.type === 'infer') {
      let curParserData = go.copy(this.state.curParserData)
      let cur_infer =
        curParserData.value.word_slot[obj.word_slot_key].words[obj.word_key]
          .infer
      //去重
      if (!$.isEmptyObject(cur_infer)) {
        for (let key in cur_infer) {
          if (
            !$.isEmptyObject(cur_infer[key]) &&
            cur_infer[key].node === obj.infer_node
          ) {
            return
          }
        }
      }

      //更新当前
      // console.log(obj.word_slot_key)
      cur_infer.push({ node: obj.infer_node, relation: '？', type: 'add' })

      //更新累加
      let items = go.copy(this.state.parserData)
      items[ckey] = curParserData.value

      this.setState({ parserData: items, curParserData: curParserData })
    } else if (obj.type === 'word_type') {
      //////修改词的属性(词槽)///////
      let curParserData = go.copy(this.state.curParserData)
      let from = obj.from
      let to = obj.to
      let word_slot = curParserData.value.word_slot

      if (!curParserData.value) {
        console.log('word_type-log0')
        return
      }

      if (!obj.from) {
        console.log('word_type-log000')
        return
      }

      if (!word_slot.length) {
        console.log('word_type-log111')
        return
      }

      if (!word_slot[from.word_slot_key]) {
        console.log('word_type-log111')
        return
      }

      if (!word_slot[from.word_slot_key].words[from.word_key]) {
        console.log('word_type-log222')
        return
      }

      /*处理添加或者修改*/
      //获取word_slot_key
      let to_word_key = 0
      let from_word = word_slot[from.word_slot_key].words[from.word_key].text
      let to_word_slot_key = go.getWordSlotKey(word_slot, to.cur_slot_type)
      if (to_word_slot_key >= 0) {
        /////修改(并入已有词槽)/////
        console.log('word_type-log555')

        //如果属性相同,不动
        if (to_word_slot_key === from.word_slot_key) {
          console.log('word_type-log333')
          return
        }

        //已存在则不动
        let is_repeat = false
        word_slot[to_word_slot_key].words.forEach(function (value) {
          if (value.text === from_word) {
            is_repeat = true
            return
          }
        })
        if (is_repeat) {
          console.log('word_type-log444')
          return
        }

        //复制
        to_word_key = word_slot[to_word_slot_key].words.length //新词index
        word_slot[to_word_slot_key].words.push(
          word_slot[from.word_slot_key].words[from.word_key]
        )
      } else {
        /////新增词槽/////
        to_word_slot_key = curParserData.value.word_slot.length //新添加词槽index

        //待添加词
        let newWord = {
          text: from_word,
          pos: word_slot[from.word_slot_key].words[from.word_key].pos,
          infer: []
        }

        let new_word_slot = {
          type: to.cur_slot_type,
          type_name: go.getSlotTypeName(
            curParserData.value.slot,
            to.cur_slot_type
          ),
          words: [newWord]
        }
        curParserData.value.word_slot.push(new_word_slot)
      }

      //删除当前
      delete word_slot[from.word_slot_key].words[from.word_key]

      //更新累加
      // let items = go.copy(this.state.parserData)
      // items[ckey] = curParserData.value
      // this.setState({parserData:items,curParserData:curParserData})

      //历史数据不变
      this.setState({ curParserData: curParserData })
      //返回数据-词位置
      return { selected_word: to_word_slot_key + '_' + to_word_key }
    } else if (obj.type === 'intent') {
      //////修改意图///////
      let curParserData = go.copy(this.state.curParserData)
      //更新当前
      curParserData.value.intent_name = obj.intent.value
      curParserData.value.intent = obj.intent.key
      //更新累加
      let items = go.copy(this.state.parserData)
      // items[ckey] = curParserData.value
      // this.setState({parserData:items,curParserData:curParserData})

      //历史数据不变
      console.log('--tag11--', items[ckey].intent_name)
      this.setState({ curParserData: curParserData })
    } else if (obj.type === 'delete') {
      //////删除tab///////
      //如果只有一个tab,不允许删除
      let count = 0
      for (let k in this.state.parserData) {
        if (!this.state.parserData[k].isHide) {
          count++
        }
        if (k) {
        }
      }
      console.log('count', count)
      // return

      if (count < 2) {
        return
      }

      let items = go.copy(this.state.parserData)
      console.log('items', items)
      // return

      //删除累加数据-软删除
      // delete items[ckey]
      items[ckey].isHide = true

      //替换当前数据
      let curData = {}
      for (let index in items) {
        if (!items[index].isHide) {
          curData = { key: index, value: items[index] }
          break
        }
      }
      // console.log('ckey', ckey)

      this.setState({ parserData: items, curParserData: curData })
    } else {
      //可以只传递一个value过来更新
      //更新累加
      let items = go.copy(this.state.parserData)
      items[ckey] = obj.value
      this.setState({
        parserData: items,
        curParserData: { key: ckey, value: go.copy(obj.value) }
      })
    }
  }
  render() {
    const enterButton = (
      <img
        onClick={this.sendContent}
        className="send-img"
        src={require('./rightFnGroup/img/send.png')}
      />
    )
    return (
      <div className="search-rightlist">
        <div id="col-l">
          <div
            className="col-l-t"
            ref={(el) => {
              go.chatbox = el
            }}
          >
            <div className="content">
              <div className="bubble" id="Chat">
                {this.state.chatItems}
              </div>
            </div>
          </div>
          <div className="col-l-b">
            <div className="faq-input-content">
              {this.state.quote ? (
                <div className={'cite ' + this.state.isHideQuote}>
                  <span className="cut">引用：{this.state.quote}</span>
                  <em
                    className="close-btn"
                    onClick={() => {
                      this.hideQuote()
                    }}
                  >
                    ×
                  </em>
                </div>
              ) : null}
              <Input
                className="input"
                id="faqInput"
                placeholder="请输入问题..."
                autoComplete="off"
                style={{ color: this.state.sendText ? 'black' : '#90959d' }}
                onChange={(e) => {
                  this.handleChange(e)
                }}
                onPressEnter={this.sendContent}
                value={this.state.sendText}
                suffix={enterButton}
              />
            </div>
          </div>
        </div>
        <Modal
          title=""
          visible={this.state.visible}
          onCancel={() => this.setState({ visible: false })}
          footer={null}
          width={678}
          wrapClassName="modal-wrapper"
        >
          <Parser
            parserData={this.state.parserData}
            curParserData={this.state.curParserData}
            changeCurParserData={this.changeCurParserData}
            // changeCurParserData={() => {}}
            addChatMsgBySearchRes={this.addChatMsgBySearchRes}
            addChatMsgByParser={this.addChatMsgByParser}
          />
        </Modal>
      </div>
    )
  }
}

export default RightList
