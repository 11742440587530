import React from 'react'
import { Table } from 'antd';
import './TableResult.scss'
import {webSdk} from "../../api/WebSdk";

/*表格显示*/
class TableResult extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            title: props.title,
            columns: props.columns,
            tableData: props.tableData || [],
            pageData: props.pageData || {},
            isExport: props.isExport,
            dataId: props.dataId
        }
        this.getTableData = this.getTableData.bind(this)
    }

    componentWillMount() {
        this.getTableData()
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            columns: nextProps.columns,
            title: nextProps.title,
            dataId: nextProps.dataId,
            tableData: [],
            pageData: {}
        },() =>{
            this.getTableData()
        })
    }

    getTableData(){
        let columns = [...this.state.columns];
        columns.forEach((columnsItem,columnsIndex) =>{
            if (columnsItem.sorter) {
                columnsItem.sorter = (a, b) => parseFloat(a[columnsItem.dataIndex]) - parseFloat(b[columnsItem.dataIndex])
            }
        })
        if (columns[0].dataIndex !== 'index') {
            columns.unshift({
                title: '序号',
                dataIndex: 'index',
                render:(text,record,index)=>`${index+1}`,
                align: 'center'
            })
        }
        let postData = {
            dataId: this.state.dataId,
            columns: columns
        };
        webSdk.search.getGraphData(postData).then(res => {
            this.setState({
                tableData: res.tableData,
                pageData: res.pageData
            })
        })
    }

    render() {
        return (
            <div className="table-result">
                <h3 className="table-result-title">{this.state.title}{this.state.isExport?(<div className="right-down">下载列表</div>):''}</h3>
                <div className="table-result-main">
                    {
                        this.state.tableData && this.state.pageData?(
                            <>
                                <Table
                                    columns={this.state.columns}
                                    dataSource={this.state.tableData}
                                    rowClassName={(record, index) => index%2===0?'first-row':'second-row'}
                                    rowKey={(record,index) => {return index}}

                                    pagination={{
                                        pageSize: this.state.pageData.pageSize,
                                        total: this.state.pageData.total
                                    }}
                                />
                            </>
                        ):''
                    }

                </div>
            </div>
        )
    }
}

export default TableResult