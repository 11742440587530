import React from 'react'
import { Pagination } from 'antd'
import './FinancialView.scss'

class FinancialView extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            companyName: '阿里巴巴集团控股有限公司',
            cardList: [
                {
                    label: "杜邦分析",
                    key: "dupont"
                },
                {
                    label: "EVA分析",
                    key: "eva"
                },
                {
                    label: "盈利能力",
                    key: "profitability"
                },
                {
                    label: "偿还能力",
                    key: "repay"
                },
                {
                    label: "资产运营能力",
                    key: "asset"
                },
                {
                    label: "成长能力",
                    key: "growth"
                }
            ],
            currentCard: 'dupont',
            dataList: [
                {
                    title: '阿里巴巴IPO深度报告：从盈利本质看阿里昨天今天和明天',
                    key: ['万科','地产'],
                    abstract: '从另一个角度来看，阿里巴巴零售业务收入来源于平台商户的盈利，因此阿里巴巴货币化率（营业收入/GMV）很大程度上取决于平台商户的利润率水平。',
                    dataFrom: '格隆汇',
                    author: '格隆汇小编',
                    date: '2014-09-03'
                },
                {
                    title: '阿里巴巴IPO深度报告：从盈利本质看阿里昨天今天和明天',
                    key: ['万科','地产'],
                    abstract: '从另一个角度来看，阿里巴巴零售业务收入来源于平台商户的盈利，因此阿里巴巴货币化率（营业收入/GMV）很大程度上取决于平台商户的利润率水平。',
                    dataFrom: '格隆汇',
                    author: '格隆汇小编',
                    date: '2014-09-03'
                },
                {
                    title: '阿里巴巴IPO深度报告：从盈利本质看阿里昨天今天和明天',
                    key: ['万科','地产'],
                    abstract: '从另一个角度来看，阿里巴巴零售业务收入来源于平台商户的盈利，因此阿里巴巴货币化率（营业收入/GMV）很大程度上取决于平台商户的利润率水平。',
                    dataFrom: '格隆汇',
                    author: '格隆汇小编',
                    date: '2014-09-03'
                },
                {
                    title: '阿里巴巴IPO深度报告：从盈利本质看阿里昨天今天和明天',
                    key: ['万科','地产'],
                    abstract: '从另一个角度来看，阿里巴巴零售业务收入来源于平台商户的盈利，因此阿里巴巴货币化率（营业收入/GMV）很大程度上取决于平台商户的利润率水平。',
                    dataFrom: '格隆汇',
                    author: '格隆汇小编',
                    date: '2014-09-03'
                },
                {
                    title: '阿里巴巴IPO深度报告：从盈利本质看阿里昨天今天和明天',
                    key: ['万科','地产'],
                    abstract: '从另一个角度来看，阿里巴巴零售业务收入来源于平台商户的盈利，因此阿里巴巴货币化率（营业收入/GMV）很大程度上取决于平台商户的利润率水平。',
                    dataFrom: '格隆汇',
                    author: '格隆汇小编',
                    date: '2014-09-03'
                },
                {
                    title: '阿里巴巴IPO深度报告：从盈利本质看阿里昨天今天和明天',
                    key: ['万科','地产'],
                    abstract: '从另一个角度来看，阿里巴巴零售业务收入来源于平台商户的盈利，因此阿里巴巴货币化率（营业收入/GMV）很大程度上取决于平台商户的利润率水平。',
                    dataFrom: '格隆汇',
                    author: '格隆汇小编',
                    date: '2014-09-03'
                }
            ],
            total: 32,
            page: 1

        }

        this.changeCard = this.changeCard.bind(this);
        this.changePage = this.changePage.bind(this);

    }
    componentDidMount() {

    }

    changeCard(item) {
        this.setState({
            currentCard: item.key
        })
    }

    changePage(page, pageSize) {
        this.setState({
            page
        })
    }

    render() {
        return (
            <div className="financial-view">
                <div className="top-info">
                    <h3 className="top-info-title">{this.state.companyName}</h3>
                    <div className="top-info-main">
                        <ul className="top-info-card">
                            {
                                this.state.cardList.map(item =>{
                                    return (
                                        <li key={item.key} className={this.state.currentCard===item.key?"top-info-card-item select-card-item":"top-info-card-item"} onClick={() =>this.changeCard(item)}>
                                            {item.label}
                                        </li>
                                    )
                                })
                            }
                        </ul>
                        <div className="top-info-graph">

                        </div>
                    </div>
                </div>
                <div className="relevant-data">
                    <h3 className="relevant-data-title">相关数据信息</h3>
                    <div className="relevant-data-main">
                        <ul className="relevant-data-list">
                            {
                                this.state.dataList.map((item,index) =>{
                                    return (
                                        <li key={index} className="relevant-data-item">
                                            <h4 className="relevant-data-item-title">{item.title}</h4>
                                            <div className="relevant-data-item-key">
                                                {
                                                    item.key.map((_item,_index) =>{
                                                        return (
                                                            <span key={_index}>{_item}</span>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <p className="relevant-data-item-abstract">{item.abstract}</p>
                                            <div className="relevant-data-item-from">{item.dataFrom?<span>来自{item.dataFrom}</span>:null}<span>{item.author}</span><span>{item.date}</span></div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                        <Pagination
                            total={this.state.total}
                            showTotal={total => `共 ${total} 条`}
                            pageSize={20}
                            current={this.state.page}
                            onChange={(page, pageSize) => this.changePage(page, pageSize)}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default FinancialView