import React from 'react'

function DialogRobot({ keyId, content, time }) {
  return (
    <div className="msg clearfix robot" key={keyId}>
      <img src={require('./img/robot.png')} className="avatar" />
      <p className="robot-name">智能小助手</p>
      <div className="article">{content}</div>
      <div className="time">{time}</div>
    </div>
  )
}

export default React.memo(DialogRobot)
