import React from 'react'
import _ from 'lodash'
import { Spin } from 'antd';
let pdfjs = null
let addInter = []
let intInter = []
let scrollInter = []
let scrollInter2 = []
let activeLighthightList = []

//创建iframe，并渲染pdf
const PdfIframe = React.memo(function PdfIframe({ fileUrl, size }) {
    let page = '1';
    let src = `/pdfjs/web/viewer.html?file=${fileUrl}#page=${page}`
    return <iframe title="pdf-viewer" name="iframe-pdfjs" ref={el => pdfjs = el} src={src} frameBorder="0" width={size && size.width ? size.width : '300'} height={size && size.height ? size.height : '300'}></iframe>
})

/**
 * 后端的canvas坐标转换为窗口坐标
 * @param {*} canvas canvas对象
 * @param {*} dbCanvas 后端的canvas数值{width,height}
 * @param {*} coordStr 后端的坐标'x0,x1,y0,y1'
 */
function dbCanvasToWindow(canvas, dbCanvas, coordStr) {
    //获取本地canvas参数
    let cbox = canvas.getBoundingClientRect();

    //算出比例
    let xRate = cbox.width / dbCanvas.width;
    let yRate = cbox.height / dbCanvas.height;

    //抽出坐标
    let x0 = _.split(coordStr, ',')[0];
    let x1 = _.split(coordStr, ',')[1];
    let y0 = _.split(coordStr, ',')[2];
    let y1 = _.split(coordStr, ',')[3];

    return {
        x0: x0 * xRate,
        x1: x1 * xRate,
        y0: (dbCanvas.height - y0 - (y1 - y0)) * yRate,
        y1: (dbCanvas.height - y0) * yRate,
        winWidth: cbox.width,
        winHeight: cbox.height,
        winTop: cbox.top,
        winLeft: cbox.left
    }
}

/**
 * 鼠标移动到高亮区事件
 * @param {*} event 事件
 */
function mousePointEnter(event, perKloggerColors) {
    // let node = event.target;
    // let nodeChild = node.children;
    // _.forEach(nodeChild, (child, idx) => {
    //     child.style.backgroundColor = perKloggerColors[1];
    // })

}

/**
 * 鼠标移出高亮区事件
 * @param {*} event 鼠标事件
 */
function mousePointLeave(event, perKloggerColors) {
    // let node = event.target;
    // let nodeChild = node.children;
    // _.forEach(nodeChild, (child, idx) => {
    //     let isActive = child.getAttribute("data-active")
    //     if (isActive == 'active') {
    //         return;
    //     }
    //     // child.style.backgroundColor = perKloggerColors[0];
    //     child.style.backgroundColor = "transparent";
    //     child.style.borderColor = perKloggerColors[0];
    //     child.style.borderWidth = "2px"
    //     child.style.borderStyle = "solid"
    // })
}

class PdfViewer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            dbCanvas: {},
            loading: false,
            clickFlag: false
        }

    }

    componentDidMount() {
        const _this = this;
        _this.clearInterval();
    }

    clearInterval() {

        _.forEach(addInter, item => {
            clearInterval(_.split(item, "-")[1])
        })
        addInter = [];
        _.forEach(intInter, item => {
            clearInterval(item)
        })
        intInter = [];
        _.forEach(scrollInter, item => {
            clearInterval(item)
        })
        scrollInter = [];
        _.forEach(scrollInter2, item => {
            clearInterval(item)
        })
        scrollInter2 = [];

    }

    //初始化
    hightlightInit() {
        const _this = this;
        let _iframe = pdfjs.contentWindow;
        _this.clearInterval();

        //清除高亮层
        activeLighthightList = []
        if (_iframe) {
            let hightlightDivArr = _iframe.document.getElementsByClassName("lighthight-div")
            if (hightlightDivArr) {
                _.forEach(hightlightDivArr, item => {
                    item.innerHTML = ''
                })
            }
        }

        //初始化
        if (!_.isEmpty(_this.props.pointDataList)) {

            intInter.push(
                setInterval(() => {
                    // console.log(`intInter:${intInter}`)

                    if (_iframe.PDFViewerApplication && _iframe.PDFViewerApplication.eventBus) {

                        //清除定时器
                        _.forEach(intInter, item => {
                            clearInterval(item)
                        })
                        intInter = [];

                        //监听pdf页面加载
                        _iframe.PDFViewerApplication.eventBus.on('pagerendered', function (event) {
                            // console.log(`event:${event.source.id}`)
                            _this.addHightlight(event)
                        })

                        if (_iframe.PDFViewerApplication.pdfDocument) {
                            let PDFViewerApplication = _iframe.PDFViewerApplication;
                            for (let i = 1; i < 1 + PDFViewerApplication.pdfDocument.numPages; i++) {
                                PDFViewerApplication.pdfDocument.getPage(i).then((page) => {
                                    if (!page.annotationsIntent) return;
                                    let idx = page.pageIndex + 1

                                    let canvas = _iframe.document.getElementById(`page${idx}`);
                                    _this.addHightlight({
                                        source: {
                                            id: idx,
                                            canvas: canvas
                                        }
                                    })
                                });
                            }
                        }
                    }
                }, 100)
            )
        }
    }

    //添加高亮
    addHightlight(event) {

        let _this = this;
        let _iframe = pdfjs.contentWindow
        let canvas = event.source.canvas
        let id = event.source.id;
        // console.log(id);

        let addInterId = setInterval(() => {
            // console.log(`addInter:${addInter}`)
            // let pageArr = _iframe.document.getElementsByClassName('page')
            let viewer = _iframe.document.getElementById("viewer")
            let pdfPageDiv = viewer.querySelector(`div[data-page-number='${id}']`)
            if (pdfPageDiv) {

                //根据页码，获取对应的pdf页码层
                let textLayer = pdfPageDiv.getElementsByClassName("textLayer")[0]

                if (textLayer && textLayer.children && textLayer.children.length > 0) {

                    //清除定时器
                    _.forEach(addInter, item => {
                        let intervalIdArr = _.split(item, "-")
                        if (intervalIdArr[0] == id) {
                            clearInterval(intervalIdArr[1])
                            _.pull(addInter, item)
                        }
                    })

                    //处理数据
                    let pointDataArr = _this.props.pointDataList//pointDataArr = [{"pageNum":"1", "list":[...]}, ...]
                    if (!_.isEmpty(pointDataArr) && pointDataArr.length > 0) {

                        //获取页码对应的高亮数据
                        let pointDataPage = _.find(pointDataArr, { "pageNum": `${id}` })//pointDataPage = {"pageNum":"1", "list":[...]}
                        // console.log(`${id}:${pointDataPage}`)
                        if (!_.isEmpty(pointDataPage)) {
                            let pointDataList = pointDataPage.list;//pointDataList = [{"page_size":[...], "spo_pos_list":{...}, "idx":1}, ...]

                            //获取高亮颜色对应数据
                            //lightColor = {"predicate":[...], "subject":[...], "time":[...], "object":[...]}
                            let lightColor = _this.props.lightColor;

                            //循环 数据
                            //pointData = {"page_size":[...], "spo_pos_list":{...}, "idx":1}
                            _.forEach(pointDataList, (pointData, pointDataIdx) => {
                                //spoPosList = {"predicate":[...], "subject":[...], "time":[...], "object":[...]}
                                let spoPosList = pointData.spo_pos_list;
                                let dbCanvas = {
                                    width: pointData.page_size[0],
                                    height: pointData.page_size[1]
                                }

                                //获取每组的id
                                let perKloggerIdx = pointData.idx;

                                //循环 知识点
                                //perKloggerKey = "predicate" ; perKloggerVal = [{"val":"...", "val_range_list":[...]}, ...]
                                _.forOwn(spoPosList, (perKloggerVal, perKloggerKey) => {
                                    //获取该知识点对应的颜色
                                    //perKloggerColors = ["#A7F2AA","#4CAF50"]
                                    let perKloggerColors = lightColor[perKloggerKey];

                                    //循环 知识点的高亮坐标信息
                                    //perKlogLightData = {"val":"...", "val_range_list":[...]}
                                    _.forEach(perKloggerVal, (perKlogLightData, perKlogLightDataIdx) => {

                                        //循环 坐标信息列表
                                        //valRangeList = [{"page":"1", "word_range":[...], "coords":"..."}, ...]
                                        let valRangeList = perKlogLightData.val_range_list;
                                        //valRange = {"page":"1", "word_range":[...], "coords":"..."}
                                        _.forEach(valRangeList, (valRange, valRangeIdx) => {

                                            //同一种属性的高亮区域
                                            let lightArea = null;
                                            let pointSiteX = 0;//一行字符的下标
                                            //wordRange = [25,27]
                                            let wordRange = valRange.word_range;//每行的高亮下标位置
                                            let pageNum = valRange.page;//页码

                                            //筛选出本页码的高亮点
                                            if (id == pageNum) {
                                                let winCoord = null;

                                                //创建高亮层
                                                let lightDivArr = textLayer.getElementsByClassName("lighthight-div")
                                                let lightDiv = null;
                                                if (!lightDivArr || !lightDivArr[0]) {
                                                    lightDiv = document.createElement('div');
                                                    lightDiv.setAttribute("class", "lighthight-div")
                                                } else {
                                                    lightDiv = lightDivArr[0]
                                                }

                                                //创建高亮区域
                                                if (!lightArea) {
                                                    lightArea = document.createElement('div');
                                                    lightArea.setAttribute("data-idx", perKloggerIdx)
                                                    lightArea.onmouseenter = (event) => {//添加鼠标移入事件
                                                        mousePointEnter(event, perKloggerColors)
                                                    }
                                                    lightArea.onmouseleave = (event) => {//添加鼠标移出事件
                                                        mousePointLeave(event, perKloggerColors)
                                                    }
                                                    lightArea.onclick = (event) => {
                                                        _this.mousePointClick(event, perKloggerIdx)
                                                    }
                                                }

                                                //循环div画布中的span节点
                                                let lightLeftStart = 0;
                                                let lightLeftEnd = 0;
                                                let lightSpanList = []

                                                _.forEach(textLayer.children, (spanChild, spanChildIdx) => {

                                                    let spanOffsetTop = spanChild.offsetTop;
                                                    let spanOffsetLeft = spanChild.offsetLeft;

                                                    // 计算相对应的窗口坐标
                                                    winCoord = dbCanvasToWindow(canvas, dbCanvas, valRange.coords)

                                                    //判断y坐标相差小于2，并且x坐标在一定范围内，便是高亮文字
                                                    if (Math.abs(spanOffsetTop - winCoord.y0) < 10 && spanOffsetLeft + 3 > winCoord.x0 && spanOffsetLeft < winCoord.x1) {

                                                        let spanTXT = spanChild.innerText//span的文本
                                                        let spanHTML = [];
                                                        //将高亮区域的文本的每个字符镶嵌上span节点
                                                        _.forEach(_.split(spanTXT, ''), (str, idx) => {
                                                            spanHTML.push(`<span class='span_node'>${str}</span>`)
                                                        })

                                                        spanChild.innerHTML = _.join(spanHTML, '')
                                                        lightSpanList.push(spanChild)
                                                    }
                                                })


                                                //获取高亮区域的开始、结束位置
                                                let spanWidth = 0;
                                                _.forEach(lightSpanList, (lightSpan, idx) => {
                                                    let lightSpanLeft = lightSpan.offsetLeft//span的left位置

                                                    _.forEach(lightSpan.children, (span, spanIdx) => {
                                                        spanWidth = span.offsetWidth
                                                        if (/[\s◆■\uf06e\uf0d8]/.test(span.innerText)) {
                                                            return true;
                                                        }

                                                        if (pointSiteX == wordRange[0]) {
                                                            // lightLeftStart = span.offsetLeft + lightSpanLeft
                                                            lightLeftStart = span.getBoundingClientRect().left - winCoord.winLeft

                                                        }
                                                        if (pointSiteX == wordRange[1] - 1) {
                                                            // lightLeftEnd = span.offsetLeft + lightSpanLeft + spanWidth
                                                            lightLeftEnd = span.getBoundingClientRect().left - winCoord.winLeft + spanWidth
                                                        }

                                                        pointSiteX++;
                                                    })
                                                })
                                                if (lightLeftEnd <= 0) {
                                                    lightLeftEnd = lightLeftStart + spanWidth
                                                }

                                                //创建高亮点
                                                if (lightLeftStart > 0 || lightLeftEnd > 0) {
                                                    let lightPoint = document.createElement('div');
                                                    lightPoint.setAttribute("data-property", perKloggerKey)
                                                    lightPoint.style.position = "absolute";
                                                    lightPoint.style.left = `${lightLeftStart}px`;
                                                    lightPoint.style.top = `${winCoord.y0}px`;
                                                    lightPoint.style.width = `${lightLeftEnd - lightLeftStart}px`;

                                                    lightPoint.style.height = `${winCoord.y1 - winCoord.y0}px`;
                                                    lightPoint.style.borderColor = `${perKloggerColors[0]}`
                                                    lightPoint.style.borderWidth = "2px"
                                                    lightPoint.style.borderStyle = "solid"

                                                    lightPoint.style.cursor = `pointer`;

                                                    lightArea.appendChild(lightPoint);
                                                    lightDiv.appendChild(lightArea);
                                                    textLayer.appendChild(lightDiv);
                                                    textLayer.style.mixBlendMode = "multiply"
                                                    textLayer.style.opacity = "1"
                                                }
                                            }
                                        })
                                    })
                                })
                            })
                        }

                        //处理指定的数据
                        let preLightData = _this.props.preLightData
                        let clickFlag = _this.state.clickFlag
                        if (!_.isEmpty(preLightData) && !clickFlag) {
                            _this.changeScroll(preLightData)
                        }
                    }
                }
            }
        }, 10)

        addInter.push(`${id}-${addInterId}`)
    }

    //改变滚动条的位置
    //{"idx":1, "predicate":[], "subject":[], "time":[], "coords":"", "page":"1", "classifier":[], "spo_pos":{}, "page_size":[]}
    changeScroll({ page, coords, spo_pos, page_size, idx }) {
        const _this = this;
        //获取高亮颜色对应数据
        //lightColorObj = {"predicate":[...], "subject":[...], "time":[...], "object":[...]}
        let lightColorObj = _this.props.lightColor

        //展示加载状态和当前页面
        _this.setState({
            loading: true,
            clickFlag: true
        })

        //清除旧的高亮区域
        if (!_.isEmpty(activeLighthightList) && lightColorObj) {
            _.forEach(activeLighthightList, item => {
                _.forEach(item.children, lighthight => {
                    let dataProperty = lighthight.getAttribute("data-property");
                    lighthight.setAttribute('data-active', '');
                    lighthight.style.zIndex = 1
                    // lighthight.style.backgroundColor = lightColorObj[dataProperty][0];
                    lighthight.style.backgroundColor = "transparent";
                    lighthight.style.borderColor = lightColorObj[dataProperty][0]
                    lighthight.style.borderWidth = "2px"
                    lighthight.style.borderStyle = "solid"
                })
            })

        }
        activeLighthightList = [];

        //操作页面
        let _iframe = pdfjs.contentWindow;

        scrollInter.push(setInterval(() => {
            // console.log(`scrollInter:${scrollInter}`)

            if (page && coords && spo_pos) {
                //操作iframe
                let viewerContainer = _iframe.document.getElementById("viewerContainer");
                let viewer = _iframe.document.getElementById("viewer");//获取对应的div

                if (viewer && viewer.children && viewer.children.length > 0) {
                    //清除定时器
                    _.forEach(scrollInter, item => {
                        clearInterval(item)
                    })
                    scrollInter = []

                    //算出滚动条高度
                    let viewerChild = viewer.querySelector(`div[data-page-number='${page}']`);
                    let avgPageHeight = viewerChild.offsetHeight;//每页的高度
                    let heightlightY1 = Number(_.split(coords, ",")[3]);//高亮区域的canva高度
                    let hightRate = 1 - heightlightY1 / Number(page_size[1])//算出html上高亮的高度比
                    let height = avgPageHeight * hightRate//算出html上高亮的高度

                    let scrollTop = viewerChild.offsetTop + height;
                    viewerContainer.scrollTop = scrollTop > (avgPageHeight * 0.1) ? scrollTop - (avgPageHeight * 0.1) : scrollTop
                    // viewerContainer.scrollTop = viewerChild.offsetTop

                    //改变背景颜色
                    //循环高亮点数据
                    let time = 0;
                    scrollInter2.push(setInterval(() => {
                        // console.log(`scrollInter2:${scrollInter2}`)
                        for (let i = -1; i <= 1; i++) {
                            let pageNo = Number(page) + i
                            if (pageNo < 1) continue

                            //获取有 data-page-number 层
                            viewerChild = viewer.querySelector(`div[data-page-number='${pageNo}']`);
                            if (!viewerChild) break

                            //获取 lighthight-div 层
                            let hightlightDiv = viewerChild.querySelector('div.lighthight-div')
                            if (!(hightlightDiv && hightlightDiv.children && hightlightDiv.children.length > 0)) {
                                if (pageNo != page) {
                                    continue
                                } else {
                                    // console.log(`hightlightDiv:null`)
                                    if (time >= 100) {
                                        // console.log(`pageNo-${pageNo}:time-${time}`)
                                        let canvas = _iframe.document.getElementById(`page${pageNo}`);
                                        _this.addHightlight({
                                            source: {
                                                id: pageNo,
                                                canvas: canvas
                                            }
                                        })
                                        time = 0;
                                    }
                                    time++;
                                    break
                                }
                            }

                            //清除定时器
                            _.forEach(scrollInter2, item => {
                                clearInterval(item)
                            })
                            scrollInter2 = []

                            //禁止 加载状态
                            _this.setState({
                                loading: false
                            })

                            //获取指定的高亮节点
                            let lightDivArr = hightlightDiv.querySelectorAll(`div[data-idx='${idx}']`)
                            _.forEach(lightDivArr, lightDiv => {
                                if (!(lightDiv && lightDiv.children)) return true;

                                //修改高亮区的样式
                                _.forEach(lightDiv.children, lighthight => {

                                    //修改高亮颜色
                                    let dataProperty = lighthight.getAttribute("data-property");
                                    lighthight.setAttribute('data-active', 'active');
                                    lighthight.style.zIndex = 99
                                    lighthight.style.backgroundColor = lightColorObj[dataProperty][1];
                                })
                                activeLighthightList.push(lightDiv)
                            })
                        }
                    }, 10))
                }
            }
        }, 10))
    }

    //获取当前页面的高亮组id
    getHeightlightIdx() {
        let _this = this;
        let _iframe = pdfjs.contentWindow
        let idx = 0
        // _iframe.PDFViewerApplication.pdfViewer._location

        if (!_iframe.PDFViewerApplication) return 0

        //获取当前页的相关数据
        let pdfViewer = _iframe.PDFViewerApplication.pdfViewer
        let location = pdfViewer._location

        if (!location) return;

        //当前页码
        let pageNum = location.pageNumber
        //当前滚动条的高度
        let scrollTop = pdfViewer.container.scrollTop
        //当前页的总高度
        let pageHeight = pdfViewer.viewer.querySelector(`div[data-page-number='${pageNum}']`).offsetTop
        //当前位置的高度在页面中的比例
        let pageHeightRate = (scrollTop - pageHeight) / pageHeight

        //获取高亮数据中对应页码的数据
        let pointDataArr = _this.props.pointDataList//pointDataArr = [{"pageNum":"1", "list":[...]}, ...]
        if (_.isEmpty(pointDataArr)) return 0
        let pointDataPage = {}
        let num = pageNum
        let i = 1;

        //查找本页面或上一页是否有高亮点
        while (true) {
            pointDataPage = _.find(pointDataArr, { "pageNum": `${num}` })//pointDataPage = {"pageNum":"1", "list":[...]}

            if (!_.isEmpty(pointDataPage) && num === pageNum) {
                //本页面有高亮点，找出显示位置的idx
                _.forEach(pointDataPage.list, item => {//item = {"page_size":[...], "spo_pos_list":{...}, "idx":1}
                    let canvasHeight = item.page_size[1]//该页面在服务器的canvas总高度
                    let heightlightCanvas = "";//高亮点在服务器的中，该页面的canvas高度
                    let posList = item.spo_pos_list

                    //获取高亮点的canvas高度
                    _.forOwn(posList, (val, key) => {
                        _.forEach(val, posItem => {
                            _.forEach(posItem.val_range_list, rangeItem => {
                                if (Number(rangeItem.page) === num && rangeItem.coords) {
                                    heightlightCanvas = _.split(rangeItem.coords, ",")[2]
                                    if (heightlightCanvas) {
                                        return false
                                    }
                                }
                            })
                            if (heightlightCanvas) {
                                return false
                            }
                        })
                        if (heightlightCanvas) {
                            return false
                        }
                    })

                    //算出canvas比例值
                    let canvaseRate = Number(heightlightCanvas) / Number(canvasHeight)

                    //如果高亮点大于当前页面的显示位置，则返回idx
                    if (canvaseRate > pageHeightRate) {
                        idx = item.idx
                        return false;
                    }
                })

                if (idx > 0) {
                    break
                }

            } else if (!_.isEmpty(pointDataPage) && num !== pageNum) {
                //上面的页面，获取有数据的最后一个idx
                let list = pointDataPage.list;
                let last = _.last(list)
                idx = last.idx
                break

            } else if (num < 1) {
                idx = 0;
                break
            }
            //为空，则获取下一页的数据
            num--;
        }

        return idx
    }

    //鼠标点击事件
    mousePointClick(event, perKloggerIdx) {
        this.props.toTableScrollClick(perKloggerIdx)
    }

    render() {
        return (
            <Spin spinning={this.state.loading}>
                <PdfIframe fileUrl={this.props.fileUrl} size={this.props.size}></PdfIframe>
            </Spin>
        )
    }
}

export default PdfViewer
