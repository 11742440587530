import React, {useEffect, useState} from 'react'
import {Empty} from 'antd'

const LeaderLine = window.LeaderLine;       //  https://anseki.github.io/leader-line/
let lineArr = [];
let endArr = [];
/** 创建连线的方法 */
const creatLine = (start, end, dash) => {
    let line = new LeaderLine(
        start,
        end,
        {
            color: '#7987BE',       // 线条颜色
            size: 2,                // 线条粗细
            dash: dash !== undefined,             // 虚线
            gradient: false,
            //startPlug: 'disc',        // 开始的图标      //https://anseki.github.io/leader-line/#startplug-endplug
            endPlug: 'disc',          // 末尾的图标
            hide: true
        });
    lineArr.push(line)
};
/** 将创建连线做显示处理 */
const allLineShow = () => {
    lineArr.forEach(line => {
        line.show('draw', {
            duration: 300,                  // 总时
            timing: [0.5, 0, 0.5, 0.42]       // [x1, y1, x2, y2]作为“定时功能” 的数组
        })
    })
};
const itemEnter = item => {
    let start = document.getElementById('art_' + item.art_id);
    if (!start) return;
    if (item.number && item.number > 0) {
        let number = item.number;
        // 知识点的文章连线
        for (let i = 1; i <= number; i++) {
            let curId = `${item.art_id}_${i}`;
            let end = document.getElementsByClassName(curId)[0];
            if (end) {
                if (end.classList && end.classList.value && !endArr.includes(end.classList.value)) {
                    endArr.push(end.classList.value);
                    creatLine(start, end);
                }
            } else {
                for (let k = 0; k < 3; k++) {
                    curId = 'p' + curId;
                    //name = `${item.art_id}_${i}_${name}`;
                    end = document.getElementsByClassName(curId)[0];
                    if (end) {
                        if (end.classList && end.classList.value && !endArr.includes(end.classList.value)) {
                            endArr.push(end.classList.value);
                            creatLine(start, end, true);
                        }
                        break;
                    }
                }
            }
        }
    }
    if (lineArr.length > 0) {
        allLineShow()
    }
};
const itemLeave = item => {
    if (lineArr.length === 0) return;
    lineArr.forEach(line => {
        line.hide();
        line.remove()
    })
    lineArr = [];
    endArr = [];
};

function SourceList(props) {
    useEffect(() =>{
        return () =>{
            if (lineArr.length) {
                lineArr.forEach(line => {
                    line.remove()
                })
            }
        }
    })

    return (
        <div className="graph-rightlist">
            <div id="searchRightContent" className="search-rightlist-content">
                <h4 className="rightlist-title">主要学习来源</h4>
                {props.sourceList.length === 0 && !props.loading ? (<Empty/>) : (
                    <ul>
                        {props.sourceList.map((item, index) => {
                            let href = item.art_type === "pdf" ? `/resultSource/${item.art_id}/${props.keyword}/${item.lightId ? item.lightId : ""}` : item.source;
                            return (
                                <li
                                    key={index}
                                    id={'art_' + item.art_id}
                                    className="rightlist-item"
                                    onMouseEnter={() => itemEnter(item)}
                                    onMouseLeave={() => itemLeave(item)}
                                >
                                    <a href={href} target="_blank">
                                        <div className="title" title={item.art_title}>{item.art_title}</div>
                                        <div className="info">
                                            {item.sourceType === "news" ? (
                                                <span className="source" title={item.source}>{item.source}</span>
                                            ) : null}
                                            <span className="time"
                                                  title={item.art_date}>{item.art_date}</span>
                                        </div>
                                    </a>
                                </li>
                            )
                        })}
                    </ul>
                )}
            </div>
        </div>
    )
}

export default SourceList