//import _ from 'lodash'
import BaseWebApi from './BaseWebApi'

class SearchResultApi extends BaseWebApi {
    constructor(options) {
        super()
        this._api = this.gate.search
        this._apiV2 = this.gate.searchV2
    }

    /**测试接口*/
    async getPDFDataById({ id, kpid }) {
        let params = {
            art_id: id,
            kp_list: kpid
        }
        let resp = await this._apiV2.postForm('search/get_url', params)
        return resp.data
    }

    async getKeywordSearchData({ keyword, page }) {
        let params = {
            keyword, page
        }
        let resp = await this._apiV2.postForm('search/keyword_search', params)
        return resp.data
    }

    async getLearnSource({ keyword }) {
        let resp = await this._apiV2.postForm('search/learn_source', { keyword })
        return resp.data
    }

    async queryParser({ keyword }) {
        let resp = await this._apiV2.postForm('search/query_parser', { keyword })
        return resp.data
    }

    async getLearnSourceTwin({ data }) {
        let resp = await this._apiV2.postJson('search/_learn_source', { data })
        return resp.data
    }

    /**搜素表格-表格数据 */
    async getSearchTableData({ keyword, page, pageNum }) {
        let params = { keyword, page, pageNum }
        let resp = await this._apiV2.postForm('search/table_data', params)
        return resp.data
    }

    /**多伦问答答案 */
    async getCycleAnswer({ keyword }) {
        let params = { keyword }
        let resp = await this._apiV2.postForm('search/ques_answer', params)
        return resp.data
    }
}

export default SearchResultApi