import { action, observable } from 'mobx'

export default class BusinessStore {
    @observable crumbList = [];
    @observable pageType = 'list';

    @action setPageInfo = (list, type) => {
        this.crumbList = list;
        this.pageType = type;
    }
}


