/*
 * @Author: 符春玲 fuchunling@perfectcode.tech
 * @Date: 2021-09-02 19:32:54
 * @LastEditors: 符春玲 fuchunling@perfectcode.tech
 * @LastEditTime: 2022-10-21 14:36:54
 * @FilePath: \cedb-web\ei-front\src\pages\component\SearchResultList\ResultSearch\resultCommon\RightCommon\Index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, {useEffect, useState, useRef} from 'react'
import ValueList from "./ValueList";
import SourceList from "./SourceList";
import TableList from "./TableList";
import './Index.scss'
import ScrollBar from "../../../../../common/ScrollBar";

class RightIndex extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            valueModule: false,
            tableModule: false,
        }

        this.tableList = null;
        this.valueList = null;
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.keyword !== nextProps.keyword) {
            this.setState({
                valueModule: false,
                tableModule: false,
            })
        }
    }

    changeStatus = (type, status) => {
        let key = type + 'Module';
        if (typeof status !== 'undefined') {
            this.setState({
                [key]: status
            })
        } else {
            status = !this.state[key];
            this.setState({
                [key]: status
            })
        }
    }

    render() {
        let {valueModule,tableModule} = this.state;
        return (
            <div className="result-search__mind__right" style={{width: 420}}>
                {
                    valueModule ?
                        // 点击节点显示的该条数据学习来源
                        <ValueList
                            ref={el => {this.valueList = el}}
                            node={this.props.node}
                            changeValueListStatus={this.changeStatus}/> :
                        (
                            <>
                                <div className="graph__right-shrink-icon" onClick={() =>this.changeStatus('table')}
                                     style={tableModule ? {left: 0} : {left: 410}}><span
                                    className={tableModule ? "font_icon icon-Groupx transform" : "font_icon icon-Groupx"}/>
                                </div>
                                {/*锚点添加节点到右侧列表*/}
                                <div style={tableModule ? {} : {width: 0, opacity: 0}} className="selected-content">
                                    <ScrollBar>
                                        <TableList keyword={this.props.keyword} ref={el => {this.tableList = el}}/>
                                    </ScrollBar>
                                </div>
                                {/*默认显示所有的学习来源*/}
                                <div className="position-list">
                                    <ScrollBar>
                                        <div className="position-list-content">
                                            <SourceList keyword={this.props.keyword} sourceList={this.props.sourceList} loading={this.props.loading}/>
                                        </div>
                                    </ScrollBar>
                                </div>
                            </>
                        )
                }
            </div>
        )
    }
}

export default RightIndex