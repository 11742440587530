/*
 * @Description:
 * @LastEditTime: 2023-02-23 15:30:02
 * @LastEditors: liqun 13212226759@163.com
 */
import { action, observable } from 'mobx'
import _ from 'lodash'

const ITEM_STYLE = {
  root: {
    borderColor: 'rgba(238,176,128,0.36)',
    borderWidth: 4,
    color: '#FE9863'
  },
  collapse: {
    borderColor: '#8795CF',
    borderWidth: 0,
    color: '#8795CF'
  },
  notCollapse: {
    borderColor: '#8795CF',
    borderWidth: 2,
    color: '#fff'
  }
}
class Node {
  constructor(
    id,
    name,
    originData,
    links = new Set(),
    relation = {},
    deep = null,
    parentNodes = new Set(),
    children = new Set(),
    hasHideChild = false,
    isShow = {},
    symbolSize = 26,
    itemStyle = ITEM_STYLE.notCollapse
  ) {
    this.id = String(id)
    this.name = name
    this.originData = originData
    this.links = links
    this.relation = relation
    this.deep = deep
    this.parentNodes = parentNodes
    this.children = children
    this.hasHideChild = hasHideChild
    this.isShow = isShow
    this.symbolSize = symbolSize
    this.itemStyle = itemStyle
  }
}

const root = {
  deep: 1,
  id: '0',
  name: '管理学',
  hasHideChild: false,
  symbolSize: 36,
  itemStyle: {
    borderColor: 'rgba(238,176,128,0.36)',
    borderWidth: 4,
    color: '#FE9863'
  },
  relation: '',
  isShow: true,
  parent: null,
  children: [
    {
      deep: 2,
      id: '1',
      name: '古典管理阶段',
      hasHideChild: false,
      symbolSize: 26,
      itemStyle: {
        borderColor: '#8795CF',
        borderWidth: 2,
        color: '#fff'
      },
      relation: '工厂管理',
      isShow: true,
      parent: null,
      children: []
    },
    {
      deep: 2,
      id: '2',
      name: '定义模型',
      hasHideChild: false,
      symbolSize: 26,
      itemStyle: {
        borderColor: '#8795CF',
        borderWidth: 2,
        color: '#fff'
      },
      relation: '理论模型',
      isShow: true,
      parent: null,
      children: [
        {
          deep: 3,
          id: '3',
          name: '替代品的威胁',
          hasHideChild: false,
          symbolSize: 26,
          itemStyle: {
            borderColor: '#8795CF',
            borderWidth: 2,
            color: '#fff'
          },
          relation: '物理模型',
          isShow: true,
          parent: null,
          children: []
        },
        {
          deep: 3,
          id: '4',
          name: '企业自身的竞争优势',
          hasHideChild: false,
          symbolSize: 26,
          itemStyle: {
            borderColor: '#8795CF',
            borderWidth: 0,
            color: '#8795CF'
          },
          relation: 'SWOT分析法',
          isShow: true,
          parent: null,
          children: [
            new Node({
              deep: 4,
              id: '5',
              name: '保健因素，激励因素',
              relation: '双因素理论'
            })
          ]
        }
      ]
    }
  ]
}

const step1 = (edges) => {
  let obj = {}
  const addNode = (startNode, endNode, relation = '') => {
    let startId = startNode.id
    let endId = endNode.id
    if (!obj[startId]) {
      obj[startId] = new Node(startId, startNode.name, startNode)
    }
    obj[startId].links.add(endId)
    relation && (obj[startId].relation[endId] = relation)
    if (!obj[endId]) {
      obj[endId] = new Node(endId, endNode.name, endNode)
    }
    obj[endId].parentNodes.add(obj[startId])
  }
  for (let { endNode, id, relation, startNode } of edges) {
    addNode(startNode, endNode, relation)
  }
  return obj
}

const step2 = (obj, rootNodes) => {
  const fn = (node, deep, type) => {
    if (node.deep && node.deep <= deep) return node
    let { relation, links, parentNodes } = node
    node.deep = deep
    node.symbolSize = deep === 1 ? 36 : 26
    node.itemStyle = deep === 1 ? ITEM_STYLE.root : ITEM_STYLE.notCollapse
    if (!type || type === 'child') {
      for (let childId of links) {
        let child = fn(obj[childId], deep + 1, 'child')
        if (child) node.children.add(child)
      }
    }
    if (!type || type === 'parent') {
      for (let parentNode of parentNodes) {
        fn(parentNode, deep + 1, 'parent')
      }
    }
    return node
  }
  return rootNodes.map((rootNode) => fn(obj[rootNode.id], 1))
}

class SubjectGraph {
  @observable nodeTree = []
  @observable deep = null
  @observable idMapNode = {}

  @action collapse({ id }) {
    // let ids = new Set().add(id)
    const node = this.idMapNode[id]
    const childList = [...node.parentNodes]
      .concat([...node.children])
      .filter((item) => item.deep > node.deep)
    let hideChildList = childList.filter((item) => item.isShow.isShow === false)
    if (hideChildList.length) {
      // 展开
      function spread(node) {
        let deep = node.deep
        for (let childNode of node.children) {
          if (deep >= childNode.deep) continue
          if (!childNode.isShow.isShow) {
            childNode.isShow = {
              isShow: true,
              controlNodeId: node.id
            }
            if (childNode.itemStyle === ITEM_STYLE.notCollapse) {
              spread(childNode)
            }
          }
        }
        for (let parentNode of node.parentNodes) {
          if (deep >= parentNode.deep) continue
          if (!parentNode.isShow.isShow) {
            parentNode.isShow = {
              isShow: true,
              controlNodeId: node.id
            }
            if (parentNode.itemStyle === ITEM_STYLE.notCollapse) {
              spread(parentNode)
            }
          }
        }
      }
      spread(node)
      if (node.deep !== 1) {
        node.itemStyle = ITEM_STYLE.notCollapse
      }
    } else {
      // 折叠 -> 释放 controlNodeId
      function collapse(node) {
        let deep = node.deep
        for (let childNode of node.children) {
          if (deep >= childNode.deep) continue
          if (
            childNode.isShow.isShow &&
            childNode.isShow.controlNodeId !== node.id
          )
            continue
          childNode.isShow = {
            isShow: false,
            controlNodeId: null
          }
          collapse(childNode)
        }
        for (let parentNode of node.parentNodes) {
          if (deep >= parentNode.deep) continue
          if (
            parentNode.isShow.isShow &&
            parentNode.isShow.controlNodeId !== node.id
          )
            continue
          parentNode.isShow = {
            isShow: false,
            controlNodeId: null
          }
          collapse(parentNode)
        }
      }
      if (childList.length) {
        collapse(node)
        if (node.deep !== 1) {
          node.itemStyle = ITEM_STYLE.collapse
        }
      }
    }
    this.nodeTree = [...this.nodeTree] // 改变 nodeTree 的值才会触发getters 重新计算 getNodeEdge
  }

  @action updateNodeTree({ edges, rootNode }) {
    let obj = step1(edges)
    console.log(obj)
    this.idMapNode = obj
    this.nodeTree = step2(obj, rootNode)
    console.log(this.nodeTree)
  }

  get getNodeEdge() {
    let ids = new Set()
    let data = new Set()
    let links = []
    const t = (array, type) => {
      for (let i of array) {
        if (ids.has(i.id)) continue
        ids.add(i.id)
        if (_.isEmpty(i.isShow) || i.isShow.isShow) {
          data.add(i)
          if (!type || type === 'child') {
            for (let childNode of i.children) {
              links.push({
                source: i.id,
                target: childNode.id,
                relation: i.relation[childNode.id],
                value: (childNode.deep - 1) * 30
              })
            }
            t(i.children, 'child')
          }
          if (!type || type === 'parent') {
            for (let parentNode of i.parentNodes) {
              links.push({
                source: parentNode.id,
                target: i.id,
                relation: parentNode.relation[i.id],
                value: (i.deep - 1) * 30
              })
            }
            t(i.parentNodes, 'parent')
          }
        }
      }
    }
    t(this.nodeTree)

    return {
      data: Array.from(data),
      links
    }
  }
}

export default SubjectGraph
