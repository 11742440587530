import {action, observable} from 'mobx'

/**
 * 首页左侧
 */
export default class HomeExcelStore {
    @observable excelOpen = false;      // 左侧展开收缩

    @observable excelLoading = false;
    @observable excelData = {};

    @observable excelFalse = false;

    @action unmountAction = () =>{
        this.excelOpen = false;
        this.excelLoading = false;
        this.excelData = {};
        this.excelFalse = false;
    };

    @action setExcelOpen = status => {
        this.excelOpen = status;
    };

    @action setExcelLoading = status => {
        this.excelLoading = status;
    };

    @action setExcelData = data => {
        this.excelData = data;
    };

    @action setExcelFalse = status =>{
        this.excelFalse = status;
    }
}
