import React from 'react'
import axios from 'axios'
import { message } from 'antd'

const convertRes2Blob = (response, filename) => {
  // 将二进制流转为blob
  const blob = new Blob([response.data], { type: 'application/octet-stream' })

  // 创建新的URL并指向File对象或者Blob对象的地址
  const blobURL = window.URL.createObjectURL(blob)
  // 创建a标签，用于跳转至下载链接
  const tempLink = document.createElement('a')
  tempLink.style.display = 'none'
  tempLink.href = blobURL
  tempLink.setAttribute('download', decodeURI(filename))
  // 兼容：某些浏览器不支持HTML5的download属性
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank')
  }
  // 挂载a标签
  document.body.appendChild(tempLink)
  tempLink.click()
  document.body.removeChild(tempLink)
  // 释放blob URL地址
  window.URL.revokeObjectURL(blobURL)
}

function DialogDownload({ uid, dataSource }) {
  const download = (url) => {
    if (!url) return console.error(dataSource, 'file_url不存在')
    let protocol = window.location.protocol
    axios
      .request({
        url: protocol + url,
        method: 'get',
        responseType: 'blob'
      })
      .then((res) => {
        console.log(res)
        if (res) {
          convertRes2Blob(res, dataSource.file_name + '.xlsx')
        }
      })
      .catch((e) => {
        message.error('下载请求出错')
      })
  }
  return (
    <div className="msg clearfix " key={uid} mkey={uid}>
      <div className="article">
        您是否要下载excel文件：
        <div className="btn-container">
          <span
            className="chat-btn"
            onClick={() => download(dataSource.file_url)}
            style={{ width: '100px' }}
          >
            {`点击下载\n（统计结果）`}
          </span>
          <span
            className="chat-btn"
            onClick={() => download(dataSource.file_url_content)}
            style={{ width: '100px' }}
          >
            {`点击下载\n（原句内容）`}
          </span>
        </div>
      </div>
    </div>
  )
}

export default React.memo(DialogDownload)
