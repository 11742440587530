import React, {useEffect, useState} from 'react';
import {Button, Icon, message} from 'antd'
import _ from 'lodash'
import {webSdk} from "../../../../../../api/WebSdk";

/** 取消已选择的内容 */
const clearSelect = () => {
    if ("getSelection" in window) {
        window.getSelection().removeAllRanges();
    } else {
        document.selection.empty();
    }
};

/** 设置初始已选择的数据 */
const initSelectContent = (values, text, classList) => {
    let result = {};
    for (let k in values) {
        let items = values[k];
        for (let i=0;i<items.length;i++) {
            let item = items[i];
            let index = text.indexOf(item.val);
            if (index > -1) {
                result[index] = {
                    start: index,
                    end: index + item.val.length - 1,
                    val: item.val,
                    label: classList[classList.findIndex(cls => cls.key === k) || 0].label,
                    key: classList[classList.findIndex(cls => cls.key === k) || 0].key,
                };
            }
        }

    }
    return result;
};

const eavKeys = [
    {
        key: 'entity',
        label: '实体'
    },
    {
        key: 'attribute',
        label: '属性'
    },
    {
        key: 'time',
        label: '时间'
    },
    {
        key: 'value',
        label: '值'
    }
];
const eaeKeys = [
    {
        key: 'entity',
        label: '实体'
    },
    {
        key: 'relation',
        label: '关系'
    },
    {
        key: 'entity2',
        label: '实体2'
    }
];

/**
 * @param props：
 *              node:  图谱点击的节点数据
 *              sourceData： 当前学习来源数据
 *              @closeModal： 关闭此弹窗方法
 *
 * 首先备份已有的数据-----进行修改后再保存
 */
function FeedBackModal(props) {
    let {node,sourceData} = props;
    // 1.判断当前任务时eav还是eae，设置词槽列表
    let classList = eavKeys;     // 词槽列表
    if (node.key==='entity2') {
        classList = eaeKeys;
    }
    let content = sourceData.text || '';
    let contentArr = content.split('');     // 文本转为数组，单个次显示
    let clickFlag = false;      // 用于检测选择文本的开始

    //let task_id = null;     // 备份后生成的task_id
    // 2.将已有的数据，写到_selectContent已选择的数据
    let initSelectData = initSelectContent(sourceData['origin_kg'], content, classList) || {};

    // 顶部list当前选择项
    const [_selectListIndex, changeListIndex] = useState(0);
    // 已选择的数据
    const [_selectContent, setSelectContent] = useState(initSelectData);
    // 当前状态 编辑/删除  默认删除
    const [_status, changeStatus] = useState('delete');
    // 编辑状态当前选择的字块
    const [_editContent, changeEditContent] = useState(null);
    // 备份后生成的task_id
    const [task_id, changeTaskId] = useState(null);
    // 控制提交按钮是否可操作
    const [saveLoading, changeSaveLoading] = useState(false);
    const [ignoreLoading, changeIgnoreLoading] = useState(false);
    const [actionLoading, changeActionLoading] = useState(false);

    useEffect(() => {
        /** 选择文字 */
        document.querySelector('body').addEventListener('mouseup', mouseupFn);
        /** 监听快捷键 */
        document.querySelector('body').addEventListener('keyup', keyupFn);
        return () => {
            document.querySelector('body').removeEventListener('mouseup', mouseupFn)
            document.querySelector('body').removeEventListener('keyup', keyupFn)
        }
    })

    /** 鼠标松开时，判断是否在选择文字 */
    let mouseupFn = (e) => {
        if (clickFlag) {
            clickFlag = false;
            selectText();
            e.stopImmediatePropagation();
        }
    }

    /** 处理选择的文字 */
    let selectText = () => {
        let selectBlock = window.getSelection() ? window.getSelection() : document.selection.createRange().text || '';
        if (selectBlock.baseNode && selectBlock.extentNode && !selectBlock.isCollapsed) {  // selectContent.isCollapsed为true未选择，false选择了文字
            changeEditContent(null);
            let startId = selectBlock.baseNode.id || selectBlock.baseNode.parentNode.id;
            let endId = selectBlock.extentNode.id || selectBlock.extentNode.parentNode.id;
            if (!startId || !endId) {
                clearSelect();
                return;
            }
            let startIndex = parseInt(startId.replace(/text_/, ''));
            let endIndex = parseInt(endId.replace(/text_/, ''));
            let selectStartIndex = startIndex,
                selectEndIndex = endIndex;
            if (selectStartIndex > selectEndIndex) {
                selectStartIndex = endIndex;
                selectEndIndex = startIndex;
            }
            // 判断是否已有选择的数据 如果已选择了里面的内容，则取消选择
            let selectContent = {..._selectContent};
            if (!_.isEmpty(selectContent)) {
                for (let key in selectContent) {
                    let start = selectContent[key].start;
                    let end = selectContent[key].end;
                    if ((selectStartIndex >= start && selectStartIndex <= end) || (selectEndIndex >= start && selectEndIndex <= end) || (start >= selectStartIndex && start <= selectEndIndex) || (end >= selectStartIndex && end <= selectEndIndex)) {
                        clearSelect();
                        return;
                    }
                }
            }
            // 当前选择的label
            let label = classList[_selectListIndex].label;
            let key = classList[_selectListIndex].key;
            selectContent[selectStartIndex] = {
                start: selectStartIndex,
                end: selectEndIndex,
                val: contentArr.slice(selectStartIndex, selectEndIndex + 1).join(''),
                label,
                key
            };
            setSelectContent(selectContent);
            clearSelect();
        }
    }

    /** 键盘快捷键 */
    let keyupFn = (e) => {
        let key = e.key;
        let keyNum = parseInt(key);
        if (keyNum >= 1 && keyNum <= 9 && keyNum <= classList.length) {
            changeListIndex(keyNum - 1);
            if (_status === 'edit' && _editContent) {
                let selectContent = {..._selectContent};
                selectContent[_editContent].label = classList[keyNum - 1].label;
                setSelectContent(selectContent);
            }

        } else if (key === 'e' || key === 'E') {
            changeStatus('edit')
        } else if (key === 'd' || key === 'D') {
            changeStatus('delete');
            if (_status === 'edit' && _editContent) {
                changeEditContent(null);
            }
        } else if (key==='Escape') {
            props.closeModal()
        }
    }

    /** 已选择的次点击 */
    let clickMark = (index) => {
        let selectContent = {..._selectContent};
        if (_status === 'delete') {
            delete selectContent[index]
            setSelectContent(selectContent)
        } else {
            changeEditContent(index)
        }
    }

    /** 改变词槽事件 */
    let changeClass = index => {
        changeListIndex(index);
        if (_status === 'edit' && _editContent) {
            let selectContent = {..._selectContent};
            selectContent[_editContent].label = classList[index].label;
            setSelectContent(selectContent);
        }
    }

    /** 编辑/删除 按钮事件 */
    let editBtnClick = type => {
        changeStatus(type);
        changeEditContent(null);
    }

    /** 忽略 */
    let ignoreTask = () =>{
        props.closeModal()
    }

    /** 保存 */
    let saveTask = () =>{
        changeSaveLoading(true);
        changeActionLoading(true);
        let temp = {};      // 已标注的数据
        for (let k in _selectContent) {
            let key = _selectContent[k].key;
            if (temp[key]) {
                temp[key].push(_selectContent[k].val)
            }
            else {
                temp[key] = [_selectContent[k].val]
            }
        }

        let postData = {
            kg_type: sourceData.kg_type,
            kg_id: sourceData.kg_id,
            kp_id: sourceData.kp_id,
            art_id: sourceData.art_id,
            // origin_kg: item.origin_kg,
            // modify_kg: temp,
            origin_kg: JSON.stringify(sourceData.origin_kg),
            modify_kg: JSON.stringify(temp),
            text: sourceData.text
        };
        webSdk.searchGraphResultApi.saveTask(postData).then(res => {
            changeSaveLoading(false);
            changeActionLoading(false);
            if (res===undefined || !res.errCode || res.errCode === 0) {
                message.success('已保存');
                props.closeModal()
            } else {
                message.error(res.errMsg || '保存任务失败，请重试');
            }
        }).catch(e => {
            changeSaveLoading(false);
            changeActionLoading(false);
            message.error('保存任务失败，请重试');
        })
    }

    let tempSelectData = {};
    return (
        <div className="feed-back-modal">
            <div className="feed-back-block">
                <div className="content">
                    <div className="content-top">
                        <ul>
                            {
                                classList.map((item, index) => {
                                    return <li key={item.key} onClick={() => changeClass(index)}
                                               className={index === _selectListIndex ? "class-list-item active" : "class-list-item"}>{item.label}<span
                                        className="quick-num">{index + 1}</span></li>
                                })
                            }
                        </ul>
                    </div>
                    <div className="content-main">
                        <div className="content-text" onMouseDown={() => clickFlag = true}>
                            {
                                contentArr.map((item, index) => {
                                    if (_selectContent[index]) {
                                        tempSelectData = _selectContent[index];
                                        let start = tempSelectData.start,
                                            end = tempSelectData.end;
                                        return (
                                            <mark key={index} className="text-mark" onClick={() => clickMark(index)}>
                                                {
                                                    contentArr.map((markItem, markIndex) => {
                                                        if (markIndex >= start && markIndex <= end) {
                                                            return (
                                                                <span className="select-text" key={markIndex}
                                                                      id={`text_${markIndex}`}>{markItem}</span>
                                                            )
                                                        }
                                                    })
                                                }
                                                <span className="select-text mark-label"><em>{tempSelectData.label}</em></span>
                                                {/*添加按钮*/}
                                                <span
                                                    className="delete-merge-icon"
                                                    style={_status === 'edit' && _editContent === index ? {display: 'block'} : {}}>
                                                    {
                                                        _status === 'delete' ?
                                                            <Icon type="close"/> :
                                                            <Icon type="edit"/>
                                                    }
                                                </span>
                                            </mark>
                                        )
                                    } else {
                                        if (index >= tempSelectData.start && index <= tempSelectData.end) {
                                            if (index === tempSelectData.end) tempSelectData = {};
                                            return null;
                                        } else {
                                            return (
                                                <span key={index} id={`text_${index}`}>{item}</span>
                                            )
                                        }
                                    }
                                })
                            }
                        </div>
                        <div className="content-btns">
                            <div className="left-edit-btns">
                                <span className={_status === 'edit' ? "edit-btn active" : "edit-btn"}
                                      onClick={() => editBtnClick('edit')}>编辑</span>
                                <span className={_status === 'delete' ? "edit-btn active" : "edit-btn"}
                                      onClick={() => editBtnClick('delete')}>删除</span>
                                <span className="clear-btn" onClick={() => setSelectContent({})}><Icon type="delete"/>清空</span>
                            </div>
                            <div className="right-save-btns">
                                <Button
                                    onClick={() =>saveTask()}
                                    loading={saveLoading}
                                    disabled={actionLoading}>保存</Button>
                                <Button
                                    // onClick={() =>ignoreTask()}
                                    onClick={props.closeModal}
                                    loading={ignoreLoading}
                                    disabled={actionLoading}>忽略</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="close" onClick={props.closeModal}><Icon type="close"/></div>
            </div>
        </div>
    )
}

export default FeedBackModal