import React from 'react'
import { Input, Tooltip, Icon, Checkbox, Button, message } from 'antd'
import './LoginPage.scss'
import { webSdk } from "../../../api/WebSdk";
import md5 from 'js-md5'
import { JSEncrypt } from 'jsencrypt'
import '../../../css/iconfont/iconfont.css'
import '../../../css/iconfont2/iconfont.css'

class LoginPage extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            userName: '',
            passWord: '',
            userNameError: false,
            passWordError: false,
            md5Salt: '',
            rsaPublicKey: '',
            rememberPassword: false,
            iconLoading: false,
            active: false,
            btnDisabled: true
        }

        this.changeInput = this.changeInput.bind(this);
        this.rememberKeyWord = this.rememberKeyWord.bind(this);
        this.btnAction = this.btnAction.bind(this);
        this.forgetPassword = this.forgetPassword.bind(this);
        this.inputKeyup = this.inputKeyup.bind(this);
        this.toRegister = this.toRegister.bind(this);
        this.setActive = this.setActive.bind(this);
    }
    componentDidMount() {
        let hasLogin = window.sessionStorage.getItem('hasLogin') || null;
        if (hasLogin) {
            this.props.history.push({
                pathname: '/'
            })
            message.info('已登录');
        }
        //获取记住的密码
        let userName = window.localStorage.getItem('userName') || '';
        let passWord = window.localStorage.getItem('passWord') || '';
        if (userName) {
            this.setState({
                userName,
                active: true
            })
            if (passWord) {
                this.setState({
                    passWord,
                    btnDisabled: false,
                    rememberPassword: true
                })
            }
        }

        //获取加密信息
        webSdk.search.loginKey().then(res => {
            try {
                let { md5Salt, rsaPublicKey } = res;
                this.setState({
                    md5Salt, rsaPublicKey
                })
            }
            catch (e) {
                console.log(e)
            }
        }).catch(e => {
            message.error('获取基础信息失败，请刷新重试');
        })
    }

    componentWillReceiveProps(nextProps) {

    }
    componentWillUnmount() {
        // 卸载异步操作设置状态
        this.setState = (state, callback) => {
            return;
        }
    }

    /**
     * 记住密码
     */
    rememberKeyWord(ev) {
        let val = ev.target.checked;        //  true/false
        this.setState({
            rememberPassword: val
        })
    }

    changeInput(ev, type) {
        let val = ev.target.value;
        if (type === 'userName') {
            this.setState({
                userName: val
            }, () => {
                this.setActive()
            })
        }
        else if (type === 'passWord') {
            this.setState({
                passWord: val
            }, () => {
                this.setActive()
            })
        }
    }

    setActive() {
        let { userName, passWord } = this.state;
        if (userName || userName === 0 || passWord || passWord === 0) {
            this.setState({
                active: true
            })
            if ((userName || userName === 0) && (passWord || passWord === 0)) {
                this.setState({
                    btnDisabled: false
                })
            }
        }
        else {
            this.setState({
                active: false,
                btnDisabled: true
            })
        }
    }

    inputKeyup(ev) {
        if (ev.keyCode === 13) {
            this.btnAction();
        }
    }

    btnAction() {
        this.setState({
            iconLoading: true
        }, () => {
            let userName = this.state.userName,
                passWord = this.state.passWord;
            let localPassword = window.localStorage.getItem('passWord') || '';

            if (!userName) {
                this.setState({
                    userNameError: true,
                    iconLoading: false
                })
                message.error('请输入手机号/邮箱');
                return;
            }
            if (!passWord) {
                this.setState({
                    passWordError: true,
                    iconLoading: false
                })
                message.error('请输入密码');
                return;
            }

            if (localPassword !== passWord) {
                //MD5加密
                passWord = md5(passWord + this.state.md5Salt, 32);

                //RSA加密
                let encrypt = new JSEncrypt();
                encrypt.setPublicKey(this.state.rsaPublicKey);
                passWord = encrypt.encrypt(passWord);
            }

            let postData = {
                username: userName,
                pwd: passWord
            }
            webSdk.search.doLogin(postData).then(res => {
                if (!res.errCode || res.errCode === 0) {
                    window.sessionStorage.setItem('hasLogin', '1');
                    //window.localStorage.setItem('eiLogin', '1');
                    window.localStorage.setItem('userInfo', JSON.stringify(res.user));
                    window.localStorage.setItem('userName', userName);
                    if (this.state.rememberPassword) {
                        window.localStorage.setItem('passWord', passWord);
                    }
                    else {
                        window.localStorage.setItem('passWord', '');
                    }
                    this.props.history.push({
                        pathname: '/'
                    })
                    message.success('登录成功');
                    this.setState({
                        iconLoading: false
                    })
                }
                else {
                    this.setState({
                        iconLoading: false
                    })
                    message.error(res.errMsg || '登录失败，请稍后重试');
                }
            }).catch(e => {
                this.setState({
                    iconLoading: false
                })
                message.error('服务器繁忙，请稍后重试');
            })
        })
    }

    forgetPassword() {
        this.props.history.push({
            pathname: `/findPassword`
        })
    }

    toRegister() {
        this.props.history.push({
            pathname: `/register`
        })
    }

    render() {
        let active = this.state.active;
        return (
            <div className="login-page">
                {/* <div className="login-page-logo">
                    <p><i class="iconfont login-page-logo-icon">&#xe607;</i></p>
                    <p>企业信息库</p>
                </div> */}
                <div className="login-page-logo">
                    北大汇丰企业信息库
                </div>

                <div className="login-main">
                    <div className={active ? "login-box" : "unActive login-box"}>
                        <h2 className="login-title">账号登录</h2>
                        <div className="input-main">
                            <div className="input-item">
                                <Input
                                    placeholder="请输入手机号／邮箱"
                                    onFocus={() => this.setState({ userNameError: false })}
                                    onChange={ev => this.changeInput(ev, 'userName')}
                                    className={this.state.userNameError ? 'input-error login-input' : 'login-input'}
                                    value={this.state.userName}
                                    style={{ width: '100%', height: 42 }}
                                    prefix={<span className="font_family icon-Account" style={this.state.userName ? { color: "#333" } : {}} />}
                                />
                            </div>
                            <div className="input-item">
                                <Input.Password placeholder="请输入密码"
                                    onFocus={() => this.setState({ passWordError: false })}
                                    onChange={ev => this.changeInput(ev, 'passWord')}
                                    onKeyUp={ev => this.inputKeyup(ev)}
                                    value={this.state.passWord}
                                    className={this.state.passWordError ? 'input-error login-input' : 'login-input'}
                                    style={{ width: '100%', height: 42 }}
                                    prefix={<span className="font_family icon-password" style={this.state.passWord ? { color: "#333" } : {}} />} />
                            </div>
                        </div>
                        <div className="forget-line">
                            <span className="remember-checked"><Checkbox onChange={this.rememberKeyWord} checked={this.state.rememberPassword}>记住密码</Checkbox></span>
                            <span className="forget-password" onClick={this.forgetPassword}>忘记密码?</span>
                        </div>
                        <div className="action-btn">
                            <Button
                                className="btn-main"
                                type="primary"
                                disabled={this.state.btnDisabled}
                                loading={this.state.iconLoading}
                                onClick={this.btnAction}
                            >登录</Button>
                        </div>
                        <div className="to-register-line" onClick={this.toRegister}>没有账号，去注册</div>
                    </div>
                </div>

                <div className="copyright-info">
                    <Icon type="copyright" />深圳市圆代码互联网有限公司技术提供
                </div>
            </div>
        )
    }
}

export default LoginPage
