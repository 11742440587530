import React from 'react'
import './ProfitIndex.scss'
import ProfitInfoGraph from './ProfitInfoGraph'
import { DatePicker ,Select,Spin} from 'antd';
import {webSdk} from "../../../../../../../api/WebSdk";
const { RangePicker } = DatePicker;
const Option = Select.Option;

class ProfitBaseInfo extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            keyword: props.keyword,
            companyName: '',
            engCompanyName: '',
            selectTime: '',
            dataFromList: [],
            //下方图像数据
            cardList: [],       //顶部的卡片选择数据，包含对应的dataId和type
            currentList: 'netProfit',
            //dataIds: {},        //暂存三个标签的dataId
            options: {}
        }
        this.changeSecondCard = this.changeSecondCard.bind(this);
        this.getBaseInfo = this.getBaseInfo.bind(this);
    }
    componentDidMount() {
        this.getBaseInfo(data =>{
            this.setState({
                companyName: data.companyName,
                engCompanyName: data.engCompanyName,
                dataFromList: data.dataFromList,
                cardList: data.cardList,
                currentList: data.currentList
            },() =>{
                this.getGraphData(this.state.cardList[0].dataId,graphRes => {
                    this.formatData(graphRes,this.state.cardList[0].type,resData =>{
                        this.setState({
                            options: resData
                        })
                    })
                })
            })
        })
    }

    /**
     * 获取当前菜单的基本信息
     * @param cb
     */
    getBaseInfo(cb){
        let postData = {
            style: 'profitView',    //当前菜单
            p: this.state.keyword, //当前搜索词
            time: '',               //当前筛选的起止时间
            dataFrom: ''            //当前筛选的数据来源
        }

        webSdk.search.getFinancialInfo(postData).then(res => {
            cb && cb(res)
        })
    }

    /**
     * 获取图像数据
     * @param dataId
     * @param cb
     */
    getGraphData(dataId,cb){
        let postData = {
            type: 3,
            p: this.state.keyword,
            dataId: dataId
        }
        webSdk.search.getGraphData(postData).then(res => {
            cb && cb(res)
        })
    }

    /**
     * 格式化数据
     */
    formatData(item,type,cb){
        //新数据
        let obj = {};
        //图形类数据
        if (type==='graph') {
            //数据格式化
            this.formatGraph(item,res =>{
                cb && cb(res)
            })
        }
        //表格类数据
        else if (type==='table') {
            obj.tableData = item.tableData;
            obj.pageData = item.pageData;
            cb && cb(obj)
        }
        //列数据展示
        else if (type==='list') {
            cb && cb(item)
        }
    }

    /**
     * 格式化graph图数据
     * @param item：当前的数据
     * @param cb：返回格式化后的options数据
     */
    formatGraph(item,cb){
        let options = {
            xAxis: [],
            yAxis: [],
            series: []
        };
        options.legend = {           //指示标签的数据位置及内容
            bottom: 0,
            right: 0,
            data: []      //获取的数据
        };
        options.tooltip = {trigger: 'axis'};
        /**
         * 先对数据进行排序，再提取数据
         */
            //找出所有数据类型
        let keys = [];
        for (let key in item.data[0]) {
            if (key!=='label') keys.push(key);
        }

        let graphData = {
            labels: []
        };
        item.data.forEach((dataItem,dataIndex) =>{
            graphData["labels"].push(dataItem.label);
            for (let i=0;i<keys.length;i++) {
                let keyName = keys[i];
                if (!graphData[keyName]) {
                    graphData[keyName] = []
                }
                graphData[keyName].push(dataItem[keyName])
            }
        });

        //设置X轴数据
        options.xAxis.push({
            type: 'category',
            boundaryGap: true,
            data: graphData.labels,      //获取的数据
            axisTick:{
                alignWithLabel: true
            }
        })

        //设置series、Y轴数据
        item.opt.forEach((optItem,optIndex) =>{
            options.legend.data.push(optItem.name);

            //是否已经含有该Y轴信息
            let hasY = false;
            for (let i=0;i<options.yAxis.length;i++) {
                if (options.yAxis[i].name===optItem.showName) {
                    hasY = true;
                    break;
                }
            }
            if (!hasY) {
                options.yAxis.push({
                    type: 'value',
                    scale: true,
                    name: optItem.showName,      //获取的数据
                    splitNumber: 5         //预估分割段数
                });
            }

            options.series.push({
                name: optItem.name,      //获取的数据
                type: optItem.type,        //类型柱形
                yAxisIndex: optItem.yAxisIndex,
                animationEasing: 'elasticOut',
                animationDelay(idx) {
                    return idx * 100
                },
                animationDelayUpdate(idx) {
                    return idx * 50
                },
                barWidth: '30%',
                data: graphData[optItem.key],      //获取的数据
            })
        })
        cb && cb(options)
    }

    /**
     * select改变数据事件
     */
    changeSelect(){

    }

    /**
     * 改变下方卡片展示
     */
    changeSecondCard(item){
        if (item.key===this.state.currentList) return;
        this.setState({
            options: {}
        },() =>{
            this.getGraphData(item.dataId,graphRes => {
                this.formatData(graphRes,item.type,resData =>{
                    this.setState({
                        options: resData,
                        currentList: item.key
                    })
                })
            })
        })
    }
    render() {
        return (
            <div className="top-box">
                {
                    !this.state.options || JSON.stringify(this.state.options)==='{}'?(<Spin size="large"/>):''
                }
                <div>
                    <div className="search-main__data-table__title-line">
                        <h2 className="search-main__data-table__left-title">
                            <span className="search-main__data-table__left-title-CHN" title={this.state.companyName}>{this.state.companyName}</span>
                            <span className="search-main__data-table__left-title-ENG" title={this.state.engCompanyName}>{this.state.engCompanyName?'（' + this.state.engCompanyName + '）':''}</span>
                        </h2>
                        <div className="search-main__data-table__right-time">
                            <div className="search-main__data-table__right-time-time">
                                <span>起止时间：</span>
                                <RangePicker style={{width: 220,height: 32}} size="default" format='YYYY/MM/DD' placeholder={['开始','结束']}/>
                            </div>
                            <div className="search-main__data-table__right-time-select">
                                <span>数据来源：</span>
                                <Select style={{ width: 220,height: 32 }} onChange={this.changeSelect.bind(this)} placeholder="请选择数据来源">
                                    {
                                        this.state.dataFromList.map((item,index) =>{
                                            return <Option value={item.value} key={index} title={item.label}>{item.label}</Option>
                                        })
                                    }
                                </Select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="profit-graph">
                    <ProfitInfoGraph cardList={this.state.cardList} currentList={this.state.currentList} options={this.state.options} fnChangelist={this.changeSecondCard} />
                </div>
            </div>
        )
    }
}

export default ProfitBaseInfo