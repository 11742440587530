import React, { PureComponent } from 'react';
import _ from 'lodash'
import { Button, Table, Upload } from 'antd'
import './index.scss'

class LessonPlanIndex extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            pageSize: 10,
            pageTotal: 0,
            tableLoading: false,
            //表头
            tableColumn: [
                {
                    title: '类别',
                    children: [
                        {
                            title: 'Catagory',
                            dataIndex: 'catagory',
                            key: 'catagory',
                            align: "center",
                            render: text => <span>{text}</span>,
                        },
                    ]
                },
                {
                    title: '课程名称',
                    children: [
                        {
                            title: 'Course',
                            dataIndex: 'course',
                            key: 'course',
                            align: "center",
                            render: text => <span>{text}</span>,
                        },
                    ]
                },
                {
                    title: '课程代码',
                    children: [
                        {
                            title: 'Course Code',
                            dataIndex: 'courseCode',
                            key: 'courseCode',
                            align: "center",
                            render: text => <span>{text}</span>,
                        },
                    ]
                },
                {
                    title: '模块',
                    children: [
                        {
                            title: 'Module',
                            dataIndex: 'module',
                            key: 'module',
                            align: "center",
                            render: text => (<span>{text}</span>),
                        },
                    ]
                },
                {
                    title: '操作',
                    children: [
                        {
                            title: 'ToolBar',
                            key: 'toolBar',
                            dataIndex: 'toolBar',
                            align: "center",
                            width: 200,
                            render: (text, record) => (
                                <span>
                                    <a className="plan-tools" onClick={this.toPlanDisplayPage.bind(this)}>查看</a>
                                    <a className="plan-tools">导出</a>
                                    <a className="plan-tools" onClick={this.toEditPlanPage.bind(this)}>编辑</a>
                                    <a className="plan-tools" onClick={this.toExamplesDisplayPage.bind(this)}>案例</a>
                                </span>
                            ),
                        },
                    ]
                },
            ],
            //表格数据
            tableData: [
                {
                    catagory: "MA",
                    course: "金融学",
                    courseCode: "04712540",
                    module: "1st Module, Fall semester, 2018",
                },
                {
                    catagory: "PhD",
                    course: "高级宏观经济学",
                    courseCode: "04712555",
                    module: "1st Module, Fall semester, 2018",
                },
                {
                    catagory: "MA",
                    course: "金融学",
                    courseCode: "04712540",
                    module: "1st Module, Fall semester, 2018",
                },
                {

                    catagory: "PhD",
                    course: "高级宏观经济学",
                    courseCode: "04712555",
                    module: "1st Module, Fall semester, 2018",
                },
                {
                    catagory: "MA",
                    course: "金融学",
                    courseCode: "04712540",
                    module: "1st Module, Fall semester, 2018",
                },
                {
                    catagory: "PhD",
                    course: "高级宏观经济学",
                    courseCode: "04712555",
                    module: "1st Module, Fall semester, 2018",
                },
            ]
        }
    }

    /** 去创建页面 */
    toCreatePage() {
        this.props.history.push({
            pathname: `/lessonplan/create`
        })
    }

    /** 去查看教案页面 */
    toPlanDisplayPage() {
        this.props.history.push({
            pathname: `/lessonplan/display`
        })
    }

    /** 去编辑教案页面 */
    toEditPlanPage() {
        this.props.history.push({
            pathname: `/lessonplan/edit`
        })
    }

    /** 去案例页面 */
    toExamplesDisplayPage() {
        this.props.history.push({
            pathname: `/lessonplan/examples`
        })
    }

    render() {
        return (
            <div className="lesson-plan">
                <div className="lesson-plan-head">
                    <h5 className="plan-head-title">我的教纲</h5>
                    <div className="plan-head-btngroup">
                        <Upload
                            name='file'
                        >
                            <Button
                                icon="download"
                                className="plan-head-btn import"
                            >导入</Button>
                        </Upload>
                        <Button
                            type="primary"
                            icon="plus"
                            className="plan-head-btn"
                            onClick={this.toCreatePage.bind(this)}
                        >创建</Button>
                    </div>
                </div>
                <div className="lesson-plan-content">
                    <Table
                        className="plan-table"
                        columns={this.state.tableColumn}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={(record, index) => {
                            return index
                        }}
                        pagination={{
                            showSizeChanger: true,
                            showQuickJumper: true,
                            pageSizeOptions: ["10", "15", "20", "25"],
                            current: this.state.page + 1,
                            pageSize: this.state.pageSize,
                            total: this.state.pageTotal,
                            // onChange: (page, pageSize) => this.pageChange(page, pageSize),
                            // onShowSizeChange: (current, size) => this.pageSizeChange(current, size),
                            showTotal: total => `共 ${total} 条`
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default LessonPlanIndex;