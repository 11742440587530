/**
 * 语料解析
 */
import React, {useEffect, useState, useRef} from 'react'
import {Icon, Popover} from 'antd'
import "./ViewArticleModal.scss"
import {highLightPDF, setHighLight} from "./HighLightPDF"

function ViewArticleModal(props) {
    const {articleModal, setArticleModal, articleModalTitle, articleModalUrl, positionData} = props;

    const [url, setUrl] = useState('');
    const modalRef = useRef(null);

    useEffect(() => {
        return () => {
            if (!modalRef || !modalRef.current) return;
            let modalDocument = modalRef.current.contentDocument;
            let modalWindow = modalRef.current.contentWindow;
            if (!modalDocument || !modalWindow) return;
            let viewerContainer = modalDocument.querySelector('#viewerContainer');
            if(!viewerContainer) return;
            viewerContainer.removeEventListener("scroll", onScroll)
        }
    }, []);

    useEffect(() =>{
        if (articleModalUrl) {
            let index = articleModalUrl.lastIndexOf(".");
            let type = articleModalUrl.substr(index+1);
            if (type === 'pdf' || type === 'PDF') {
                let src = `/pdfjs/web/viewer.html?file=${articleModalUrl}#page=1`;
                setUrl(src);
            } else {
                setUrl(articleModalUrl)
            }
        } else {
            setUrl('');
        }
    },[articleModalUrl]);

    useEffect(() =>{
        if (positionData && modalRef) {
            highLightPDF(positionData, modalRef, true);
            let modalDocument = modalRef.current.contentDocument;
            let modalWindow = modalRef.current.contentWindow;
            if (!modalDocument || !modalWindow) return;
            let viewerContainer = modalDocument.querySelector('#viewerContainer');
            if(!viewerContainer) return;
            viewerContainer.addEventListener("scroll", onScroll)
        }
    }, [positionData]);

    const onScroll = () =>{
        setHighLight(positionData, modalRef)
    };

    return (
        <div className="home-view-article-modal" style={articleModal?{}:{display: 'none'}}>
            <div className="view-modal-content">
                <h4 className="view-modal-title">
                    <Popover content={articleModalTitle || '查看原文'} trigger="hover" placement="bottomLeft">
                        <span className="abstract-title">{articleModalTitle || '查看原文'}</span>
                    </Popover>
                    <div className="view-modal-close" onClick={() => setArticleModal(false)}><Icon type="close"/></div>
                </h4>
                <div className="view-modal-text">
                    {
                        url ? <iframe
                            id="pdf-block"
                            ref={modalRef}
                            title="pdf-content"
                            src={url || ''}
                            width='100%'
                            height='100%'/> : null
                    }

                </div>
            </div>
        </div>
    )
}

export default ViewArticleModal
