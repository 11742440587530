import React from 'react';
import './Breadcrumb.scss'

function Breadcrumb(props) {
    const {crumbList, history} = props;

    const crumbClick = (item, index) => {
        let isLast = crumbList[index + 1] === undefined;
        if (isLast) return;
        history.push({
            pathname: item.key
        })
    };

    return (
        <ul className="breadcrumb-nav">
            {
                crumbList.map((item, index) => {
                    let isLast = crumbList[index + 1] === undefined;
                    return (
                        <li key={`breadcrumb_${index}`} className="breadcrumb-item">
                            <span onClick={() => crumbClick(item, index)} className={isLast?"breadcrumb-label breadcrumb-label-last":"breadcrumb-label"}>{item.label}</span>
                            {!isLast ? <span className="breadcrumb-sep">/</span> : null}
                        </li>
                    )
                })
            }
        </ul>
    )
}

export default Breadcrumb
