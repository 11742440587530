import React from 'react'
import {webSdk} from "../../../../api/WebSdk";
import { Icon, Spin , Empty , message} from 'antd'
import './RightHotModule.scss'

class RightHotSearch extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            hotwords: [],
            loading: false
        }
        this.getHotWord = this.getHotWord.bind(this);
        this.reloadHotword = this.reloadHotword.bind(this);
        this.clickHotWord = this.clickHotWord.bind(this);
    }

    componentDidMount() {
        this.getHotWord();

    }

    getHotWord(){
        this.setState({
            loading: true
        },() =>{
            let postData = {
                size: 8
            }
            webSdk.search.getHotkeywords(postData).then(hotwords => {
                if (Object.prototype.toString.call(hotwords) === '[object Array]') {
                    this.setState({
                        hotwords,
                        loading: false
                    })
                }
                else {
                    this.setState({
                        loading: false
                    })
                    message.error('获取热门搜索词失败，请重试')
                }
            }).catch(e =>{
                this.setState({
                    loading: false
                })
                message.error('获取热门搜索词失败，请重试')
            })
        })
    }

    reloadHotword(){
        this.getHotWord();
    }

    clickHotWord(item){
        this.props.fnItemClick(item)
    }

    render() {
        return (
            <div className="right-item">
                <Spin spinning={this.state.loading}>
                    <h3 className="right-item-title">
                        热门搜索
                        <div className="item-title-right"
                             onClick={this.reloadHotword}>
                            换一批<Icon className="reload-icon" type="reload" />
                        </div>
                    </h3>
                    <div className="right-item-content two-columns-content">
                        {
                            this.state.hotwords && this.state.hotwords.length>0 ?
                                this.state.hotwords.map((item,index) =>{
                                    return (<div className="two-columns-item"
                                                 key={index}
                                                 title={item.content}
                                                 onClick={() =>this.clickHotWord(item)}>{item.content}</div>)
                                }):
                                (
                                    <Empty/>
                                )
                        }
                    </div>
                </Spin>
            </div>
        )
    }
}

export default RightHotSearch