import React from 'react'
import {Table} from 'antd'
import _ from 'lodash'

class InfoItemTable extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            columns: [
                {
                    title: '序号',
                    dataIndex: 'index',
                    render:(text,record,index)=>`${index+1}`,
                    width: 90,
                    align: 'center'
                },
                {
                    title: '股东名称',
                    dataIndex: 'name'
                },
                {
                    title: '持股数量（股）',
                    dataIndex: 'num'
                },
                {
                    title: '持股比例',
                    dataIndex: 'rate',
                    render: text =>{
                        return text||'-' + '%'
                    }
                },
                {
                    title: '股份性质',
                    dataIndex: 'type'
                }
            ],
            tableData: props.list
        }
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (JSON.stringify(this.state.tableData)!==JSON.stringify(nextProps.list)) {
            console.log('table更新')
            this.setState({
                tableData: nextProps.list
            })
        }
    }

    render() {
        return (
            <div className="info-item-list">
                <Table
                    columns={this.state.columns}
                    dataSource={this.state.tableData}
                    style={{width: '100%'}}
                    rowClassName={(record, index) => {
                        return index % 2 === 0 ? 'single-row' : 'double-row'
                    }}
                    rowKey={(record, index) => {
                        return index
                    }}
                    pagination={false}
                />
            </div>
        )
    }
}

export default InfoItemTable