import $ from 'jquery'
let go = {}
go.num = 1
go.quote = '' //引用
go.uid = () => {
  // console.log(go.num)
  // return Date.now () + go.num++
  return go.num++
}

//复制一个对象-旧版
/*go.copy = (obj)=>{
    //方案1
    // return JSON.parse(JSON.stringify(obj))
    //方案2
    // return Object.create(obj)

    //方案3
    var newObj = {};
    if (obj instanceof Array) {
        newObj = [];
    }
    for (var key in obj) {
        var val = obj[key];
        newObj[key] = val;
    }
    return newObj;
}
*/

//复制一个对象-新版
go.copy = (obj) => {
  if (obj instanceof Array) {
    return [...obj]
  }
  if ($.isPlainObject(obj)) {
    return { ...obj }
  }
  return obj
}

//添加滚动条
go.addSscrollbar = (items, height) => {
  if (!height) {
    height = $('#root').height() - 65
  }

  //批量添加
  items.forEach((item) => {
    let itemHeight = $(item).outerHeight()
    console.log('itemHeight', itemHeight)
    console.log('rootHeight', height)
    if (itemHeight > height) {
      $(item).parent().addClass('scroll-y')
    }
  })
}

//滑动到底部
go.scrollToBottom = () => {
  console.log('go.chatbox', go.chatbox)
  if (go.chatbox) {
    let scrollHeight = go.chatbox.scrollHeight //里面div的实际高度  2000px
    let height = go.chatbox.clientHeight //网页可见高度  200px
    let maxScrollTop = scrollHeight - height
    // go.chatbox.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0
    go.chatbox.scrollTo({
      top: maxScrollTop > 0 ? maxScrollTop : 0,
      behavior: 'smooth'
    })
    //如果实际高度大于可见高度，说明是有滚动条的，则直接把网页被卷去的高度设置为两个div的高度差，实际效果就是滚动到底部了。
  }
}

//获取词槽类型名称
go.getSlotTypeName = (slotArr, type) => {
  let res = ''
  if (!slotArr.length) {
    return res
  }

  //批量添加
  slotArr.forEach((item) => {
    if (item.type === type) {
      res = item.type_name
    }
  })
  return res
}

/**
 * 获取词槽key
 * @param array word_slot 词槽数据
 * @param string slot_type 词槽类型名称
 * @returns int
 */
go.getWordSlotKey = (word_slot, slot_type) => {
  let res = -1
  if (!word_slot.length) {
    return res
  }

  //批量添加
  word_slot.forEach((item, index) => {
    if (item.type === slot_type) {
      res = index
    }
  })
  return res
}

/**
 * 获取词槽key
 * @param string container 滚动区域
 * @param string mkey 滑动到的元素
 * @return viod
 */
go.locateToAsk = (mkey, container = '.col-l-t') => {
  var mainContainer = $(container),
    scrollToContainer = mainContainer.find('.ask[mkey=' + mkey + ']') //滑动到的元素
  if (!scrollToContainer.length) {
    console.log('ask msg dom not found:', mkey)
    return
  }
  //非动画效果
  //mainContainer.scrollTop(
  //  scrollToContainer.offset().top - mainContainer.offset().top + mainContainer.scrollTop()
  //);
  //动画效果
  mainContainer.animate(
    {
      scrollTop:
        scrollToContainer.offset().top -
        mainContainer.offset().top +
        mainContainer.scrollTop()
    },
    500
  )
}

export default go
