import React, { PureComponent } from 'react'
import _ from 'lodash'
import { Empty, Spin } from 'antd';
import "./EnterHistory.scss"
import { webSdk } from '../../../../api/WebSdk';

class EnterHistory extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            currentYear: this.props.currentYear,//当前年份
            tickerSymbol: this.props.tickerSymbol,//股票代码
            pageRequestParams: this.props.pageRequestParams,//后端请求所需值
            baseInfo: {//基本资料
                mainBusiness: "",//主营业务
                businessScope: "",//经营范围
                legalPerson: "",//法人代表
                chairman: "",//董事长
                generalManager: "",//总经理
                chairSecretary: "",//董秘
                employeeCount: "",//员工数
                place: "",//所属地
                productType: "",//产品类型
                establishedTime: "",//成立时间
                productsName: "",//产品名称
                registerTime: "",//注册时间
            },
            historyInfo: {
                loading: true,//加载状态
                columns: [
                    {
                        title: '时间',
                        key: 'time',
                    },
                    {
                        title: '名称',
                        key: 'name',
                    },
                    {
                        title: '备注',
                        key: 'remark',
                    },
                ],
                data: [//历史沿革
                    // {
                    //     time: "",//时间
                    //     name: "",//名称
                    //     remark: "",//备注
                    // }
                ]
            },
            realControler: "",//实际控制人
            relative: "",//关联方
            irrelative: "",//非关联方
        }
    }

    componentDidMount() {
        this.getData()

        window.onresize = () => {
            this.fixRelactiveStyle();
        }
    }

    componentWillReceiveProps(nextProps) {
        let newCurrentYear = nextProps.currentYear
        let newTickerSymbol = nextProps.tickerSymbol
        let newPageRequestParams = JSON.stringify(nextProps.pageRequestParams)
        let oldPageRequestParams = JSON.stringify(this.state.pageRequestParams)
        if (newCurrentYear != this.state.currentYear || newTickerSymbol !== this.state.tickerSymbol || newPageRequestParams != oldPageRequestParams) {
            this.setState({
                currentYear: newCurrentYear,
                tickerSymbol: newTickerSymbol,
                pageRequestParams: nextProps.pageRequestParams
            }, () => {
                this.getData()
            })
        }
    }

    componentWillUnmount() {
        window.onresize = null
        this.setState = () => {
            return;
        }
    }

    /**获取数据 */
    getData() {
        let tickerSymbol = this.state.tickerSymbol
        let year = this.state.currentYear
        let historyInfo = { ...this.state.historyInfo }
        let pageRequestParams = this.state.pageRequestParams
        if (!tickerSymbol || !year) {
            return;
        }

        historyInfo.loading = true
        this.setState({
            historyInfo
        }, () => {
            let params = {
                tickerSymbol: tickerSymbol,
                year: year,
            }
            if (pageRequestParams && pageRequestParams.pageType == "enterHistory" && pageRequestParams.fnType) {
                params.table = pageRequestParams.fnType
                params.data = pageRequestParams.paramData
            }
            webSdk.entersInforApi.getEnterHistoryInfo(params).then(res => {
                let baseInfo = res.baseInfo
                _.forIn(baseInfo, (value, key) => {
                    if (!value) {
                        baseInfo[key] = "-"
                    }
                })

                let historyInfoList = res.historyInfo
                _.forEach(historyInfoList, item => {
                    _.forIn(item, (value, key) => {
                        if (!value) {
                            item[key] = "-"
                        }
                    })
                })
                historyInfo.data = historyInfoList
                historyInfo.loading = false

                let realControler = res.realControler
                if (!realControler) {
                    realControler = "-"
                }

                let relative = res.relative
                if (!relative) {
                    relative = "-"
                }

                let irrelative = res.irrelative
                if (!irrelative) {
                    irrelative = "-"
                }

                this.setState({
                    baseInfo, historyInfo, realControler, relative, irrelative
                }, () => {
                    this.fixRelactiveStyle();
                    this.toPagePoint()
                })
            })
        })
    }

    /**修改联系方/非联系方表格大小 */
    fixRelactiveStyle() {
        let historyOtherRelativeArr = document.getElementsByClassName("history-other-relative")
        if (_.isEmpty(historyOtherRelativeArr)) return;

        let historyOtherRelative = historyOtherRelativeArr[0]
        if (_.isEmpty(historyOtherRelative)) return;

        let boxTitleArr = historyOtherRelative.querySelectorAll("span.box-title")
        let height = 0
        _.forEach(boxTitleArr, item => {
            item.style.height = null
            let itemHeight = item.offsetHeight
            height = itemHeight > height ? itemHeight : height

            if (height <= 0) return true;
            item.style.height = `${height}px`
        })
    }

    /**到页面指定位置 */
    toPagePoint() {
        let pageRequestParams = this.state.pageRequestParams
        if (!pageRequestParams || pageRequestParams.pageType != "enterHistory") return;
        let fnType = pageRequestParams.fnType
        let fnId = ""
        switch (fnType) {
            case "mainBusiness":
            case "managingScope":
            case "legalRepresentative":
            case "chairman":
            case "generalManager":
            case "boardSecretary":
            case "staffCount":
            case "registeredAddress":
            case "productType":
            case "establishDate":
            case "productName":
            case "registeredDate":
                fnId = "baseInfoBlock"
                break;
            case "historyName":
                fnId = "historyInfoBlock"
                break;
            case "actualController":
            case "relatedParty":
            case "nonrelatedParty":
                fnId = "controlBlock"
                break;
        }

        if (fnId) {
            this.showHightSpot(fnId)
        }
    }

    /**展示高亮块 */
    showHightSpot(id) {
        let pageRequestParams = this.state.pageRequestParams
        if (!pageRequestParams || pageRequestParams.pageType != "enterHistory") return;
        let block = document.getElementById(id)
        if (!block) return;
        let topVal = block.offsetTop
        this.props.fnChangeScroll(topVal)

    }

    render() {
        return (
            <div className="enterhistory">
                <div id="baseInfoBlock" className="history-baseinfo">
                    <h3>基本资料</h3>
                    <div className="history-baseinfo-tb">
                        <table>
                            <tbody>
                                <tr>
                                    <td className="baseinfo-tb-title">主营业务</td>
                                    <td className="baseinfo-tb-content">{this.state.baseInfo.mainBusiness}</td>
                                    <td className="baseinfo-tb-title">经营范围</td>
                                    <td className="baseinfo-tb-content">{this.state.baseInfo.businessScope}</td>
                                </tr>
                                <tr>
                                    <td className="baseinfo-tb-title">法人代表</td>
                                    <td className="baseinfo-tb-content">{this.state.baseInfo.legalPerson}</td>
                                    <td className="baseinfo-tb-title">董事长</td>
                                    <td className="baseinfo-tb-content">{this.state.baseInfo.chairman}</td>
                                </tr>
                                <tr>
                                    <td className="baseinfo-tb-title">总经理</td>
                                    <td className="baseinfo-tb-content">{this.state.baseInfo.generalManager}</td>
                                    <td className="baseinfo-tb-title">董秘</td>
                                    <td className="baseinfo-tb-content">{this.state.baseInfo.chairSecretary}</td>
                                </tr>
                                <tr>
                                    <td className="baseinfo-tb-title">员工数</td>
                                    <td className="baseinfo-tb-content">{this.state.baseInfo.employeeCount}</td>
                                    <td className="baseinfo-tb-title">所属地</td>
                                    <td className="baseinfo-tb-content">{this.state.baseInfo.place}</td>
                                </tr>
                                <tr>
                                    <td className="baseinfo-tb-title" rowSpan="2">产品名称</td>
                                    <td className="baseinfo-tb-content" rowSpan="2">{this.state.baseInfo.productsName}</td>
                                    <td className="baseinfo-tb-title">成立时间</td>
                                    <td className="baseinfo-tb-content">{this.state.baseInfo.establishedTime}</td>
                                </tr>
                                <tr>
                                    {/* <td className="baseinfo-tb-title">产品名称</td>
                                    <td className="baseinfo-tb-content">{this.state.baseInfo.productsName}</td> */}
                                    <td className="baseinfo-tb-title">注册时间</td>
                                    <td className="baseinfo-tb-content">{this.state.baseInfo.registerTime}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div id="historyInfoBlock" className="history-line">
                    <h3>历史沿革</h3>
                    <div className="history-line-tb">
                        <table>
                            <thead>
                                <tr>
                                    {
                                        this.state.historyInfo.columns.map((item, idx) => (
                                            <th
                                                key={item.key}
                                                style={{
                                                    minWidth: `${item.minWidth}px`
                                                }}
                                            >{item.title}</th>
                                        ))
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    _.isEmpty(this.state.historyInfo.data) || this.state.historyInfo.loading ? (
                                        <tr>
                                            <td className="empty" colSpan="3">
                                                <Spin spinning={this.state.historyInfo.loading}>
                                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                </Spin>
                                            </td>
                                        </tr>
                                    ) : this.state.historyInfo.data.map((item, idx) => (
                                        <tr key={idx}>
                                            {
                                                this.state.historyInfo.columns.map((colItem, colIdx) => {
                                                    let key = colItem.key
                                                    let value = item[key]
                                                    return (
                                                        <td key={colItem.key}>{value}</td>
                                                    )
                                                })
                                            }
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

                <div id="controlBlock" className="history-other">
                    <div className="history-other-box box-realcontrol">
                        <span className="box-title">实际控制人</span>
                        <span className="box-content">{this.state.realControler}</span>
                    </div>
                    <div className="history-other-relative">
                        <div className="history-other-box">
                            <span className="box-title">关联方</span>
                            <span className="box-content">{this.state.relative}</span>
                        </div>
                        <div className="history-other-box">
                            <span className="box-title">非关联方</span>
                            <span className="box-content">{this.state.irrelative}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default EnterHistory;