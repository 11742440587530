import React, {useEffect, useRef, useState} from 'react';
import stores from "../../../store";
import {observer} from 'mobx-react';
import {Button, Icon, Input, message, Spin} from 'antd';
import EditModal from "./EditModal";
import ScrollBar from "../../common/ScrollBar";
import {querySyllabusInfoApi, queryTagTreeApi, saveSyllabusApi, updateSyllabusApi} from './utils'

function SyllabusEdit(props) {
    const {syllabusStore} = stores;

    const [loading, setLoading] = useState(false);
    const [moduleName, setModuleName] = useState('');        // 模板名称
    const [questionList, setQuestionList] = useState([]);   // 问题列表
    const [saveLoading, setSaveLoading] = useState(false);
    const [crtChangeIndex, setCrtChangeIndex] = useState(null);     // 修改问题类型当前index
    const [tagTree, setTagTree] = useState([]);     // 标签树
    const [defaultValue, setDefaultValue] = useState([]);   // 标签树默认选择项
    const [id, setId] = useState(null);

    const leftScroll = useRef(null);
    const rightScroll = useRef(null);


    // 选择问题类型弹窗
    const [editModal, setEditModal] = useState(false);

    let unmounted = true;

    useEffect(() => {
        unmounted = false;
        let breadcrumb = [];
        let type = '';
        let id = props.match.params.id;
        if (id) {    // 编辑
            breadcrumb = [
                {
                    label: "提纲模板",
                    key: "/syllabus/list"
                },
                {
                    label: "提纲模板详情",
                    key: `/syllabus/info/${id}`
                },
                {
                    label: "编辑提纲",
                    key: "/syllabus/edit"
                }
            ];
            type = 'edit';
            setId(Number(id));
            querySyllabusInfo(Number(id));
        } else {
            breadcrumb = [
                {
                    label: "提纲模板",
                    key: "/syllabus/list"
                },
                {
                    label: "新增提纲",
                    key: "/syllabus/add"
                }
            ];
            type = 'add';
            let newList = [{
                question: '',
                tag_names: ''
            }];
            setQuestionList(newList);
        }
        syllabusStore.setPageInfo(breadcrumb, type);
        queryTagTreeApi().then(res => {
            setTagTree(res);
        });
        return () => {
            unmounted = true;
        }
    }, []);

    const querySyllabusInfo = id => {
        setLoading(true);
        querySyllabusInfoApi(id).then(res => {
            if (unmounted) return;
            let name = res.name;
            let list = res.questions || [];
            setLoading(false);
            setModuleName(name);
            setQuestionList(list);
            calculateStyle(list);
        }).catch(e => {
            if (unmounted) return;
            setLoading(false);
        });
    };

    const nameChange = ev => {
        let val = ev.target.value;
        setModuleName(val)
    };

    const questionChange = (ev, index) => {
        let newList = [...questionList];
        newList[index]['question'] = ev.target.value;
        setQuestionList(newList);

        // todo
        // 格式出 变量
    };

    const questionBlur = (ev, index) => {
        let value = ev.target.value;
        if (value.length > 0) {
            let newQuestionList = [...questionList];
            let oldList = [];
            if (newQuestionList[index].keyword) oldList = [...newQuestionList[index].keyword];
            // console.log(newList)
            newQuestionList[index].keyword = setKeywordFromQuestion(value, oldList);
            setQuestionList(newQuestionList);
            calculateStyle(newQuestionList);
        }
    };

    const setKeywordFromQuestion = (text, oldList) => {
        if (text.length === 0) return [];
        let list = [];
        let res = [];
        for (let i = 0; i < text.length; i++) {
            if (text[i] === '{') {
                let crt = '';
                let hasFound = false;
                for (let j = i + 1; j < text.length; j++) {
                    if (text[j] === '}') {
                        crt = text.substring(i + 1, j);
                        i = j;
                        hasFound = true;
                        break;
                    }
                }
                if (!hasFound) break;
                if (crt) list.push(crt);
            }
        }
        for (let i = 0; i < list.length; i++) {
            let hasIn = false;
            for (let j = 0; j < oldList.length; j++) {
                if (oldList[j].value === list[i]) {
                    hasIn = true;
                    res.push(oldList[j]);
                    oldList.splice(j,1);
                    break;
                }
            }
            if (!hasIn) res.push({
                key: '',
                value: list[i]
            })
        }
        return res;
    };

    const variableChange = (ev, qIndex, index) => {
        let newList = [...questionList];
        newList[qIndex]['keyword'][index]['key'] = ev.target.value;
        setQuestionList(newList);
    };

    const addClick = () => {
        let newList = [...questionList];
        newList.push({
            question: '',
            tag_names: ''
        });
        setQuestionList(newList);
        calculateStyle(newList);
    };

    const cancelClick = () => {
        let id = props.match.params.id;
        let pathname = '';
        if (id) {
            pathname = `/syllabus/info/${id}`
        } else {
            pathname = '/syllabus/list'
        }
        props.history.push({
            pathname
        })
    };

    const saveClick = () => {
        if (!moduleName) {
            message.error("请输入模板名称");
            return;
        }
        let questions = [];     // 提交的问题
        let verifyFalse = false;
        questionList.forEach((item, index) => {
            if (item.question) {
                if (!item.tag_names) {
                    message.error(`请设置问题${index + 1}的问题类型`);
                    verifyFalse = true;
                    return;
                }
                questions.push(item);
            }
        });
        if (verifyFalse) return;
        if (questions.length === 0) {
            message.error('请输入问题');
            return;
        }
        let postData = {
            "name": moduleName,
            "questions": questions
        };
        setSaveLoading(true);
        if (id) {
            postData.id = id;
            updateSyllabusApi(postData).then(res => {
                setSaveLoading(false);
                cancelClick();
            }).catch(e => {
                setSaveLoading(false);
            })
        } else {
            saveSyllabusApi(postData).then(res => {
                setSaveLoading(false);
                cancelClick();
            }).catch(e => {
                setSaveLoading(false);
            })
        }
    };

    const editQuestionType = index => {
        setCrtChangeIndex(index);
        let values = [];
        if (questionList[index] && questionList[index].tag_names) {
            values = questionList[index].tag_names.split('/')
        }
        setDefaultValue(values);
        setEditModal(true);
    };

    // 选择问题类型弹窗确认返回数据
    const setModalValue = values => {
        let nCrtIndex = crtChangeIndex;
        setEditModal(false);
        setCrtChangeIndex(null);
        if (!values.length || values[values.length - 1] === '图表') return;
        let newList = [...questionList];
        newList[nCrtIndex].tag_names = values.join('/');
        setQuestionList(newList)
    };

    const calculateStyle = list => {
        if (list.length === 0) return;
        setTimeout(() => {
            if (!leftScroll || !leftScroll.current || !rightScroll || !rightScroll.current) return;
            let rightBlock = document.querySelector('#right-variable-list');
            if (!rightBlock) return;
            rightBlock.style.height = leftScroll.current._container.scrollHeight + 'px';
            list.forEach((item, index) => {
                if (item.keyword && item.keyword.length > 0) {
                    let lBlock = document.getElementById(`question_item_${index}`);
                    let rBlock = document.getElementById(`variable_item_${index}`);
                    if (!lBlock || !rBlock) return;
                    rBlock.style.top = lBlock.offsetTop + 35 + 'px';
                    rBlock.style.display = 'flex';
                }
            });
        }, 300)
    };
    const leftOnScroll = ev => {
        if (!rightScroll || !rightScroll.current) return;
        rightScroll.current._container.scrollTop = ev.scrollTop;
    };
    const rightOnScroll = ev => {
        if (!leftScroll || !leftScroll.current) return;
        leftScroll.current._container.scrollTop = ev.scrollTop;
    };

    return (
        <div className="syllabus-edit">
            <Spin spinning={loading}>
                <ScrollBar className="syllabus-edit-left" ref={leftScroll} onScrollY={ev => leftOnScroll(ev)}>
                    <div className="edit-left-name">
                        <p className="edit-left-name-label"><span className="edit-left-name-icon">*</span>模板名称</p>
                        <p className="edit-left-name-input">
                            <Input className="edit-left-name-input-main" value={moduleName} onChange={nameChange}
                                   placeholder="请输入模板名称"/>
                        </p>
                    </div>
                    <ul className="edit-left-question-list">
                        {
                            questionList.map((item, index) => {
                                return (
                                    <li className="edit-left-question-item" key={`question_item_${index}`}
                                        id={`question_item_${index}`}>
                                        <div className="question-item-title">
                                            <div className="question-item-index">问题{index + 1}</div>
                                            <div className="question-item-type">
                                                <span>{item.tag_names || ''}</span>
                                                <span className="question-item-type-edit"
                                                      onClick={() => editQuestionType(index)}>修改</span>
                                            </div>
                                        </div>
                                        <div className="question-area">
                                            <textarea value={item.question} className="question-area-content"
                                                      onBlur={ev => questionBlur(ev, index)}
                                                      onChange={(ev) => questionChange(ev, index)}/>
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                    <div className="edit-left-btn">
                        <Button className="add-btn" type="dashed" onClick={addClick}><Icon type="plus"/>添加问题</Button>
                        <div>
                            <Button className="cancel-btn" onClick={cancelClick}>取消</Button>
                            <Button className="save-btn" type="primary" loading={saveLoading}
                                    onClick={saveClick}>保存</Button>
                        </div>
                    </div>
                </ScrollBar>
                <ScrollBar className="syllabus-edit-right" ref={rightScroll} onScrollY={ev => rightOnScroll(ev)}>
                    <ul className="right-variable-list" id="right-variable-list">
                        {
                            questionList.map((item, index) => {
                                if (item.keyword && item.keyword.length > 0) {
                                    return (
                                        <li className="right-variable-item" key={`variable_item_${index}`}
                                            id={`variable_item_${index}`}>
                                            <div className="variable-default-value">
                                                <div className="efault-value-value">默认值</div>
                                                <div className="efault-value-value">变量名</div>
                                            </div>
                                            <ScrollBar className="variable-item-scroll">
                                                <ul className="variable-item-label">
                                                    {
                                                        item.keyword.map((v, vi) => {
                                                            return (
                                                                <li className="variable-label-li"
                                                                    key={`variable-label_${vi}`}>
                                                                    <div className="variable-label-name">{v.value}</div>
                                                                    <div className="variable-label-value">
                                                                        <Input value={v.key}
                                                                               onChange={ev => variableChange(ev, index, vi)}/>
                                                                    </div>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </ScrollBar>
                                        </li>
                                    )
                                }
                                return null;
                            })
                        }
                    </ul>
                </ScrollBar>
            </Spin>
            <EditModal modal={editModal} setOpen={setEditModal} modalOk={setModalValue} tagTree={tagTree}
                       defaultValue={defaultValue}/>
        </div>
    )
}

export default observer(SyllabusEdit)
