import React, {useState, useEffect, useRef} from 'react'
import {Icon} from 'antd'
import ScrollBar from "../../common/ScrollBar";
import OpenIcon from "./OpenIcon";
import {Button, message} from 'antd'
import HomeRightQuestion from "./HomeRightQuestion";
import HomeRightCorpus from "./HomeRightCorpus";
import HomeRightAbstract from "./HomeRightAbstract";
import stores from "../../../store";
import {observer} from 'mobx-react'
import {webSdk} from "../../../api/WebSdk";
import {questionAndCorpusAna} from './utils'


function HomeRight(props) {
    const {homeLeftStore, homeRightStore, homeCommonStore, homeCenterStore} = stores;

    let {
        rightOpen, questionAnaList, corpusAnaList, abstractAnaList, submitLoading, setSubmitLoading
    } = homeRightStore;

    let {rightStatus} = homeCommonStore;

    let rightScroll = useRef(null)

    useEffect(() =>{
        if (homeCenterStore.dbClickId === null) return;
        if (!rightScroll || !rightScroll.current) return;
        const {dbClickId: id, positionTop: corpusTop} = homeCenterStore;
        let rightItem = document.getElementById(`abstract_${id}`);
        if (!rightItem) return;
        let itemPosition = rightItem.getBoundingClientRect() || {};
        let itemPositionTop = itemPosition.top || 0;
        let moveTop = itemPositionTop - corpusTop;
        let oldScrollTop = rightScroll.current._container.scrollTop;
        let resTop = oldScrollTop + moveTop;
        rightScroll.current._container.scrollTop = resTop < 0 ? 0 : resTop;
        homeCenterStore.setDbItem(null, null)
    },[homeCenterStore.dbClickId]);

    const showButton = () => {
        return !((rightStatus === 0 && questionAnaList.length === 0) || (rightStatus === 1 && corpusAnaList.length === 0) || (rightStatus === 2 && abstractAnaList.length === 0));
    };

    /** 导出word */
    const saveAsWord = () => {
        let ht = "";
        abstractAnaList.forEach((item, index) => {
            if (!ht) {
                ht +=
                    `<div>
                    <h3>${item["art_title"]}</h3>
                    <p style="white-space: pre-wrap">${item["abstract"]}</p>
                </div>`;
            } else {
                ht += `
                <br/>
                <div>
                    <h3>${item["art_title"]}</h3>
                    <p style="white-space: pre-wrap">${item["abstract"]}</p>
                </div>`;
            }

        });
        ht = `<div>${ht}</div>`;

        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let hour = date.getHours();
        let minute = date.getMinutes();
        let second = date.getSeconds();
        let time = year + '' + month + day + hour + minute + second;
        let name = homeLeftStore.questionList[homeLeftStore.crtQuestionIndex].text.substring(0, 10) + time;


        let blob = new Blob([ht], {
            // type: "application/msword"
            type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        });
        let url = URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.setAttribute("href", url);
        link.setAttribute("download", `${name}.docx`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    /**
     * 提交训练事件 type:   parse：文本解析   corpus：语料解析   abstract：摘要
     * */
    const submitTrain = () => {
        let postData = [];
        if (rightStatus === 0) {
            let obj = {};
            obj.origin = homeLeftStore.questionList[homeLeftStore.crtQuestionIndex]["text"];
            obj.type = "parse";
            let feed_back = [];
            questionAnaList.forEach((item, index) => {
                feed_back.push({
                    attribute: item.attribute,
                    value: item.value
                })
            });
            obj["feed_back"] = feed_back;
            postData = [obj];
        } else if (rightStatus === 1) {
            corpusAnaList.forEach((item, index) => {
                let obj = {};
                obj.origin = item["text"];
                obj.type = "classifier";
                obj.feed_back = item.tags;
                postData.push(obj);
            })
        } else if (rightStatus === 2) {
            abstractAnaList.forEach((item, index) => {
                let obj = {};
                obj.origin = item["text"];
                obj.type = "abstract";
                obj.feed_back = {
                    id: item["id"],
                    text: item["abstract"]
                };
                postData.push(obj);
            });
        }

        /*let postData = {
            // origin: "",         // 原始文本
            // type,
            // feed_back: [
            //    {"attribute":"属性名1","value":[value1,value2]},...
            // ]
        };*/
        console.log(postData);
        setSubmitLoading(true);
        webSdk.search.submitTrain(postData).then(res => {
            setSubmitLoading(false);
            if (!res.errCode || res.errCode === 0) {
                message.success("提交成功");
                if (rightStatus === 0) {
                    questionAndCorpusAna('query', homeLeftStore.questionList[homeLeftStore.crtQuestionIndex].text);
                }
            } else {
                message.error(res.errMsg || '提交训练失败，请重试');
            }
        }).catch(e => {
            setSubmitLoading(false);
            console.log(e);
            message.error('提交训练失败，请重试');
        })
    };


    let hiddenBtn = !rightOpen || homeCommonStore.questionType === 1 || (rightStatus === 0 && questionAnaList.length === 0) || (rightStatus === 1 && corpusAnaList.length === 0) || (rightStatus === 2 && abstractAnaList.length === 0);

    const setStyle = () => {
        if (homeCommonStore.questionType === 0) {
            if (rightOpen) {
                return {flex: 1}
            } else {
                return {width: 44, flexShrink: 0}
            }
        } else {
            return {display: "none"}
        }
    };

    return (
        <div className="new-home-right" style={setStyle()}>
            <div className="home-content">
                <OpenIcon open={rightOpen}
                          title={rightStatus === 0 ? "问题解析" : rightStatus === 1 ? "语料解析" : rightStatus === 2 ? "生成摘要" : "问题解析"}
                          type='right'/>
                <ScrollBar className="home-main-content" style={rightOpen ? {} : {display: 'none'}} ref={rightScroll}>
                    <HomeRightQuestion/>
                    <HomeRightCorpus/>
                    <HomeRightAbstract/>
                </ScrollBar>
                {
                    hiddenBtn ? null : (
                        <div className="home-right-submit">
                            {
                                showButton() ? (
                                    <>
                                        {rightStatus === 2 ? (
                                            <Button type="primary" className="home-right-submit-btn"
                                                    onClick={saveAsWord}><Icon
                                                type="file-word"/>生成Word</Button>
                                        ) : null}
                                        <Button loading={submitLoading} onClick={submitTrain}
                                                className="home-right-submit-btn">提交训练</Button>
                                    </>
                                ) : null
                            }

                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default observer(HomeRight)
