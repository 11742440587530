import React from 'react'
import { Spin , Empty , message} from 'antd'
import {webSdk} from "../../../../api/WebSdk";
import './RightHotModule.scss'

class RightHistorySearch extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            historyWords: [],
            loading: false
        }
        this.getHistoryWord = this.getHistoryWord.bind(this);
        this.clickHistoryWord = this.clickHistoryWord.bind(this);
    }

    componentDidMount() {
        this.getHistoryWord();
    }

    //获取搜索记录
    getHistoryWord(){
        this.setState({
            loading: true
        },() =>{
            let postData = {
                size: 8
            }
            webSdk.search.getRecentSearch(postData).then(historyWords => {
                if (Object.prototype.toString.call(historyWords) === '[object Array]') {
                    this.setState({
                        historyWords,
                        loading: false
                    })
                }
                else {
                    this.setState({
                        loading: false
                    })
                    message.error('获取搜索记录词失败，请重试')
                }
            }).catch(e =>{
                this.setState({
                    loading: false
                })
                message.error('获取搜索记录词失败，请重试')
            })
        })
    }

    clickHistoryWord(item){
        this.props.fnItemClick(item)
    }

    render() {
        return (
            <div className="right-item">

                <Spin spinning={this.state.loading}>
                    <h3 className="right-item-title">搜索记录</h3>
                    <div className="right-item-content">
                        {
                            this.state.historyWords && this.state.historyWords.length>0?(
                                this.state.historyWords.map((item,index) =>{
                                    return (<div className="two-columns-item"
                                                 key={index}
                                                 title={item.content}
                                                 onClick={() =>this.clickHistoryWord(item)}>{item.content}</div>)
                                })
                            ): (<Empty/>)
                        }
                    </div>
                </Spin>
            </div>
        )
    }
}

export default RightHistorySearch