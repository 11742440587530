/*
 * @Description:
 * @LastEditTime: 2022-10-12 19:05:39
 * @LastEditors: liqun 13212226759@163.com
 */
import React, { useEffect, useState } from 'react'
import MySearch from '../../../common/MySearch/MySearch'
import Breadcrumb from '../../../common/Breadcrumb/Breadcrumb'
import { withRouter } from 'react-router-dom'
import '../index.scss'
import { Tabs } from 'antd'
import MainIndicator from './MainIndicator'
import { webSdk } from '../../../../api/WebSdk'

const { TabPane } = Tabs

const tabpanes = [
  {
    tab: '主要指标',
    key: '0',
    content: 'a',
    content: <MainIndicator type={3} />
  },
  {
    tab: '资产负债表',
    key: '1',
    content: <MainIndicator type={1} />
  },
  {
    tab: '利润表',
    key: '2',
    content: <MainIndicator type={0} />
  },
  {
    tab: '现金流量表',
    key: '3',
    content: <MainIndicator type={2} />
  }
]

function ListDetail({ history }) {
  // console.log(history.location.pathname)
  const corp_id = window.location.pathname.split('/').at(-1)
  const [companyInfo, setCompanyInfo] = useState({})

  useEffect(() => {
    webSdk.financeSubLibraryApi.getInfoById(corp_id).then((res) => {
      console.log(res)
      setCompanyInfo(res.data.listedCompanyBaseInfo)
    })
  }, [corp_id])

  const searchByKeyword = async (keyword) => {
    const res = await webSdk.financeSubLibraryApi.getInfoByName(keyword)
    console.log(res)
    return res.data.businessRegistrationInfo || []
  }

  const searchClick = async (keyword, item) => {
    console.log(item)
    if (item) {
      history.push(`/fincSubLibraryDetail/${item.id}`)
    } else {
      const res = await webSdk.financeSubLibraryApi.getInfoByName(keyword)
      console.log(res)
    }
  }

  return (
    <div className="finc-sublibrary-page">
      <div className="finc-sublibrary-head">
        <Breadcrumb
          history={history}
          crumbList={[
            { key: '/fincSubLibrary', label: '财务子库' },
            { key: '', label: companyInfo.org_name }
          ]}
        />
        <div className="finc-sublibrary-filter">
          <MySearch
            searchByKeyword={searchByKeyword}
            searchClick={searchClick}
          />
        </div>
      </div>
      <div className="list-detail">
        <div className="finc-sublibrary-card">
          <div className="card-img">
            <img src={companyInfo.image}></img>
          </div>
          <div className="card-info">
            <div className="card-title">
              简称：{companyInfo.security_name_abbr}
            </div>
            <div className="card-title">全称：{companyInfo.org_name}</div>
            <div>股票代码：{companyInfo.secucode}</div>
            <div>注册地址：{companyInfo.reg_address}</div>
            <div>
              网址：
              <span style={{ color: '#3F7EF3' }}>
                {companyInfo.org_web || '无'}
              </span>
            </div>
          </div>
        </div>
        <div className="finc-sublibrary-tabs">
          <Tabs type="card">
            {tabpanes.map((tabpane) => (
              <TabPane tab={tabpane.tab} key={tabpane.key}>
                {tabpane.content}
              </TabPane>
            ))}
          </Tabs>
        </div>
      </div>
    </div>
  )
}

export default withRouter(({ history }) => <ListDetail history={history} />)
