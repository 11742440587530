import _ from 'lodash'
import {arrOffset, mergeMockApi} from './mockWebApi'

import SearchApi from './SearchApi'

export class SearchApiMock extends SearchApi {
    constructor(opts) {
        super(opts)
        this.mockData = async (url, delay) => {
            delay = (delay || 10) + _.random(0, 1) // 模拟服务端延迟
            const resp = await this.gate.fake.get(url);
            return await new Promise(resolve => {
                setTimeout(() => {
                    resolve(resp);
                }, delay);
            });
        }
    }

    login(opts) {
        let {userName,passWord} = opts;
        return userName === 'admin' && passWord === '123';
    }

    //获取热搜词
    async hotKeywords() {
        const resp = await this.mockData('search/hotKeywords.json');
        return resp.data;
    }

    //搜索词输入获取候选词
    async getAutoSearchWord(opts) {
        //val
        const resp = await this.mockData('search/hotKeywords.json');
        return resp.data;
    }

    //搜索事件
    async searchWord(opts={}){
        let {p} = opts;
        const resp = await this.mockData('search/searchWordType.json', 100);
        resp.data.type = "list"
        if (p.indexOf('腾讯股票走势')!==-1 || '腾讯股票走势'.indexOf(p)!==-1) {
            resp.data.type = "dataTable"
            //
        }
        //检索广泛类型信息
        if (p.indexOf('科创板政府补助')!==-1 || '科创板政府补助'.indexOf(p)!==-1) {
            resp.data.type = "widelyData"
        }
        //检索单一企业
        /*if (p.indexOf('腾讯股票走势')!==-1 || '腾讯股票走势'.indexOf(p)!==-1) {
            resp.data.type = "companyInfo"
        }*/
        return resp.data
    }

    //获取页面基本信息，数据表、广泛类型直接获取各个节点基本信息。企业模块获取顶部菜单信息
    async searchBaseInfo(opts={}){
        //opts = type,p
        let {type} = opts;
        let resp;
        switch (type) {
            case "dataTable":
                resp = await this.mockData('search/result_dataTable.json', 300);
                break;
            case "widelyData":
                resp = await this.mockData('search/result_widelyInfo.json', 300);
                break;
            case "companyInfo":
                resp = await this.mockData('search/result_companyMenu.json', 300);
                break;
            default:
                resp = await this.mockData('search/result_companyMenu.json', 300);
                break;
        }
        return resp.data
    }

    //企业模块获取基本信息---左侧菜单栏
    async getCompanyBase(opts){
        //opts = currentCard,p
        let {currentCard} = opts;
        let resp;
        switch (currentCard) {
            case "baseInfo":
                resp = await this.mockData('search/result_companyBase.json', 500);
                break;
            case "financial":
                resp = await this.mockData('search/result_companyProfit.json', 500);
                break;
            case "industry":
                resp = await this.mockData('search/result_industryAndAbstractInfo.json',500);
                break;
            case "research":
                resp = await this.mockData('search/result_AbstractInfoDataFrom.json',500);
                break;
            default:
                resp = await this.mockData('search/result_companyBase.json', 500);
                break;
        }
        return resp.data
    }

    //获取公司模块，利润模块信息
    async getFinancialInfo (opts) {
        //opts = style,p,time,dataFrom
        let {style} = opts;
        let resp;
        switch (style) {
            /*概况总览数据*/
            case "profitView":
                resp = await this.mockData('search/profit_viewInfo.json', 500);
                break;
            case "totalCost":
                resp = await this.mockData('search/profit_totalCost.json', 500);
                break;
            case "totalIncome":
                resp = await this.mockData('search/profit_totalIncome.json', 500);
                break;
            case "assetsLiability":
                resp = await this.mockData('search/financial_assetsLiability.json',500);
                break;
            case "caseSheet":
                resp = await this.mockData('search/financial_caseSheet.json',500);
                break;
            case "assetsInfo":
                resp = await this.mockData('search/financial_assetsInfo.json',500);
                break;
            case "liabilityInfo":
                resp = await this.mockData('search/financial_liabilityInfo.json',500);
                break;
            case "ownerInterests":
                resp = await this.mockData('search/financial_ownerInterests.json',500);
                break;
            default:
                resp = await this.mockData('search/profit_viewInfo.json', 500);
                break;
        }
        return resp.data;
    }

    //获取图形图像表格信息
    async getGraphData(opts){
        //opts = type,p,dataId,time,dataFrom,dataType
        let {dataId,dataType} = opts;
        let resp;
        switch (dataId) {
            //数据表
            case '001':
                switch (dataType) {
                    case 'year':
                        resp = await this.mockData('search/gupiao_year.json', 800);
                        break;
                    case 'sixMonth':
                        resp = await this.mockData('search/gupiao_sixMonth.json', 800);
                        break;
                    case 'oneMonth':
                        resp = await this.mockData('search/gupiao_oneMonth.json', 800);
                        break;
                    default:
                        resp = await this.mockData('search/gupiao_year.json', 800);
                        break;
                }
                break;
            //广泛类型政府补贴 曲线图
            case '002':
                resp = await this.mockData('search/graph_widelyGraph.json', 800);
                break;
            //广泛类型政府补贴  表格
            case '003':
                resp = await this.mockData('search/table_widely.json', 800);
                break;
            //企业-公司基本面  公司基本信息
            case '004':
                resp = await this.mockData('search/list_company.json', 800);
                break;
            //企业-公司基本面  企业画像
            case '005':
                resp = await this.mockData('search/companyRelation.json', 800);
                break;
            //企业-公司基本面  股东增持/减持计划
            case '006':
                resp = await this.mockData('search/graph_companyBaseInfo.json', 800);
                break;
            //企业-公司基本面  仲裁事件
            case '007':
                resp = await this.mockData('search/table_zhongcai.json', 800);
                break;
            //企业-公司基本面  政府补助
            case '008':
                resp = await this.mockData('search/table_widely.json', 800);
                break;
            //企业-利润情况   利润概况
            case '020':
                resp = await this.mockData('search/graph_netProfit.json', 800);
                break;
            //企业-利润情况   eps
            case '021':
                resp = await this.mockData('search/graph_eps.json', 800);
                break;
            //企业-利润情况   营业收入
            case '022':
                resp = await this.mockData('search/graph_income.json', 800);
                break;
            //企业-利润情况   营业总成本--图形数据
            case '023':
                resp = await this.mockData('search/graph_totalCost.json', 800);
                break;
            //企业-利润情况   营业总成本--表格数据
            case '024':
                resp = await this.mockData('search/table_totalCost.json', 800);
                break;
            //企业-利润情况   营业总收入--图形数据
            case '025':
                resp = await this.mockData('search/graph_totalIncome.json', 800);
                break;
            //企业-利润情况   营业总收入--表格数据
            case '026':
                resp = await this.mockData('search/table_totalIncome.json', 800);
                break;
            //资产情况--流动资产
            case '030':
                resp = await this.mockData('search/graph_assetsInfo_flowAssets.json', 800);
                break;
            //资产情况--非流动资产
            case '031':
                resp = await this.mockData('search/graph_assetsInfo_unFlowAssets.json', 800);
                break;
            //资产情况--资产表详情
            case '032':
                resp = await this.mockData('search/table_assetInfo.json', 800);
                break;
            //负债情况-流动负债
            case '035':
                resp = await this.mockData('search/graph_liabilityInfo_flowLiability.json', 800);
                break;
            //负债情况--非流动负债
            case '036':
                resp = await this.mockData('search/graph_liabilityInfo_unFlowLiability.json', 800);
                break;
            //负债情况--负债表详情
            case '037':
                resp = await this.mockData('search/table_liabilityInfo.json', 800);
                break;
            default:
                resp = '暂无记录'
        }
        return resp.data
    }

    //行业分析、研报摘要--热搜词
    async getHotWord(opts){
        //let {type,p} = opts;
        let resp = await this.mockData('search/rightSearch_hotword.json', 800);
        resp.data.sort(() =>{
            return .5 - Math.random();
        })
        return resp.data
    }

    //行业分析、研报摘要--搜索记录
    async getHistoryWord(opts){
        let resp = await this.mockData('search/rightSearch_historyWord.json', 800);
        return resp.data
    }

    //行业分析、研报摘要--第一行数据
    async getIndustryAndAbstractFirst(opts){
        // type,p,time,dataFrom,reportFrom
        let {type} = opts;
        let resp;
        if (type==='industry') {
            resp = await this.mockData('search/industry_industryPerform.json', 800);
        }
        else if (type==='research') {
            resp = await this.mockData('search/abstract_reportPrediction.json', 800);
        }
        return resp.data
    }

    //行业分析、研报摘要--第二行数据
    async getIndustryAndAbstractSecond(opts){
        // type,p,time,dataFrom,reportFrom
        let {type} = opts;
        let resp;
        if (type==='industry') {
            resp = await this.mockData('search/industry_industryAnalysis.json', 800);
            resp.data = resp.data[Math.floor(Math.random()*resp.data.length)]
        }
        else if (type==='research') {
            resp = await this.mockData('search/abstract_investmentRating.json', 800);
        }
        return resp.data
    }

    //行业分析、研报摘要--第三行数据
    async getIndustryAndAbstractThird(opts){
        // type,p,time,dataFrom,reportFrom
        let {type} = opts;
        let resp;
        if (type==='industry') {
            resp = await this.mockData('search/industry_industryBulletin.json', 800);
        }
        else if (type==='research') {
            resp = await this.mockData('search/abstract_reportAbstract.json', 800);
            resp.data = resp.data[Math.floor(Math.random()*resp.data.length)]
        }
        return resp.data
    }

    async getAbstractReportFrom(opts){
        // p,time,dataFrom,reportFrom
        let resp = await this.mockData('search/abstract_reportDataFrom.json', 800);
        return resp.data
    }

    //关键词搜索---相关公司企业列表
    async searchListCompany(opts) {
        //p,page
        let resp = await this.mockData('search/listResult_company.json',600);
        return resp.data
    }

    //关键词搜索---相关数据信息
    async searchListData(opts) {
        //p,page
        let resp = await this.mockData('search/listResult_dataInfo.json',600);
        return resp.data
    }

    //关键词搜索--获取页面菜单
    async getResultList(opts) {
        let resp = await this.mockData('search/listResult_menu.json',600);
        return resp.data
    }

    //解析文件---获取表格数据
    async getAnalysisData(opts) {
        // name,type
        let {type} = opts;
        let resp;
        if (type==='attribute') {
            resp = await this.mockData('search/fileAnalysis_attributeTable.json',600);
        }
        else {
            resp = await this.mockData('search/fileAnalysis_relationTable.json',600);
        }
        return resp.data
    }


    //新版本-知识图谱生成
    async knowledgeGraphData(opts) {
        let resp = await this.mockData('search/knowledgeRelation.json', 800);
        return resp.data
    }

}

export default mergeMockApi(SearchApi, SearchApiMock)