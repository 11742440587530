import _ from 'lodash'
import BaseWebApi from './BaseWebApi'

class SearchGraphResultApi extends BaseWebApi {
    constructor(options) {
        super()
        this._api = this.gate.kgSearch;

        this.mockData = async (url, delay) => {
            delay = (delay || 10) + _.random(0, 1) // 模拟服务端延迟
            const resp = await this.gate.fake.get(url);
            return await new Promise(resolve => {
                setTimeout(() => {
                    resolve(resp);
                }, delay);
            });
        }
    }

    /**获取基础搜索结果接口*/
    async kgSearch(opts) {
        /*let {keyword} = opts;
        let resp = await this._api.get('kg_search',{
            params: {
                keyword,
                page: 1
            }
        })
        return resp.data*/

        opts.page = 1;
        // let resp = await this._api.postForm('search/kg_search',opts)
        let resp = await this._api.postForm('search/kg_attr_rel',opts)
        return resp.data
        /*const resp = await this.mockData('graph/kgSearch.json',800);
        return resp.data;*/
    }

    /** 根据点击的节点获取下级数据 */
    async getKg(opts) {
        opts.page = 1;
        let resp = await this._api.postForm('search/get_kg',opts);
        return resp.data
    }

    // 获取知识点的文章来源
    async kgSource(opts) {
        let resp = await this._api.postForm('search/kg_source',opts)
        return resp.data;
    }

    // 备份知识点数据
    async feedBackTask(opts) {
        let resp = await this._api.postJson('task/feedBackTask',opts)
        return resp.data;
    }

    // 忽略标注内容
    async ignoreTask(opts) {
        let resp = await this._api.postForm('task/ignoreTask',opts)
        return resp.data;
    }

    // 保存标注内容
    async saveTask(opts) {
        // let resp = await this._api.postJson('task/saveTask',opts)
        let resp = await this._api.postJson('feedback/modify_kg',opts)
        return resp.data;
    }
}

export default SearchGraphResultApi