import React, { PureComponent } from 'react';
import _ from 'lodash'
import { Form, Button, Input, Select } from 'antd'
import './CreatePlan.scss'

class CreatePlan extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {}
    }

    /** 回到主页面 */
    backToIndex() {
        this.props.history.push({
            pathname: `/lessonplan`
        })
    }

    /** 生成草稿 */
    createDraft() {
        this.props.history.push({
            pathname: `/lessonplan/edit`
        })
    }

    render() {
        return (
            <div className="lesson-plan-create">
                <div className="plan-create-head">
                    <h5>编辑教纲</h5>
                </div>
                <div className="plan-create-content">
                    <Form
                        className="create-content-form"
                        layout="horizontal"
                    >
                        <div className="content-form-group">
                            <Form.Item
                                className="content-form-item"
                                label="类别 Catagory "
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 17 }}
                            >
                                <Select placeholder="请选择">
                                    <Select.Option value="MA">MA</Select.Option>
                                    <Select.Option value="PhD">PhD</Select.Option>
                                    <Select.Option value="MBA">MBA</Select.Option>
                                    <Select.Option value="EMBA">EMBA</Select.Option>
                                </Select>
                            </Form.Item>
                        </div>

                        <div className="content-form-group">
                            <Form.Item
                                className="content-form-item"
                                label="课程名称 Course "
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 17 }}
                                required={true}
                            >
                                <Input placeholder="请输入" />
                            </Form.Item>
                            <Form.Item
                                className="content-form-item"
                                label="课程代码 Course Code "
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 17 }}
                            >
                                <Input placeholder="请输入" />
                            </Form.Item>
                            <Form.Item
                                className="content-form-item"
                                label="模块 Module "
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 17 }}
                            >
                                <Input placeholder="请输入" />
                            </Form.Item>
                        </div>

                        <div className="content-form-group">
                            <Form.Item
                                className="content-form-item"
                                label="教师 Instructor "
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 17 }}
                            >
                                <Input placeholder="请输入" />
                            </Form.Item>
                            <Form.Item
                                className="content-form-item"
                                label="办公室 Office "
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 17 }}
                            >
                                <Input placeholder="请输入" />
                            </Form.Item>
                            <Form.Item
                                className="content-form-item"
                                label="邮箱 Email "
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 17 }}
                            >
                                <Input placeholder="请输入" />
                            </Form.Item>
                        </div>

                        <div className="content-form-btngroup">
                            <Form.Item wrapperCol={{ span: 17, offset: 7 }}>
                                <Button
                                    className="content-form-btn cancel"
                                    onClick={this.backToIndex.bind(this)}
                                >取消</Button>
                                <Button
                                    type="primary"
                                    className="content-form-btn"
                                    onClick={this.createDraft.bind(this)}
                                >生成草稿</Button>
                            </Form.Item>
                        </div>
                    </Form>

                </div>
            </div >
        );
    }
}

export default CreatePlan;