import React, {useEffect, useState} from 'react';
import stores from "../../../store";
import {observer} from 'mobx-react';
import {Spin, Table, message} from 'antd';
import {querySyllabusInfoApi} from "./utils";

function SyllabusInfo(props) {
    const {syllabusStore} = stores;
    const [id, setId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [infoData, setInfoData] = useState([]);

    let unmounted = true;
    useEffect(() => {
        unmounted = false;
        syllabusStore.setPageInfo([
            {
                label: "提纲模板",
                key: "/syllabus/list"
            },
            {
                label: "提纲模板详情",
                key: "/syllabus/info"
            }
        ], 'info');
        let id = props.match.params.id;
        if (id) {
            setId(id);
            querySyllabusInfo(id);
        }
        return () => {
            unmounted = true;
        }
    }, [props.match.params.id]);

    const querySyllabusInfo = _id => {
        setLoading(true);
        querySyllabusInfoApi(_id || id).then(res => {
            if (unmounted) return;
            let name = res.name;
            let list = res.questions || [];
            setLoading(false);
            setInfoData(list);
        }).catch(e =>{
            if (unmounted) return;
            setLoading(false);
        });
    };

    const QuestionLine = (type, index, item) => {
        let label, content;
        if (type === 'question') {             // 问题X
            label = `问题${index + 1}`;
            content = item.question;
        } else if (type === 'tag_names') {   // 问题类型
            label = '问题类型';
            content = item.tag_names;
        } else if (type === 'keyword') {       // 模板变量
            const columns = [
                {
                    title: '变量名',
                    dataIndex: 'key',
                    key: 'name',
                    align: "center",
                    render: text => text || '-',
                    width: 193,
                    textWrap: 'word-break',
                    ellipsis: true,
                },
                {
                    title: '默认值',
                    dataIndex: 'value',
                    key: 'value',
                    align: "center",
                    render: text => text || '-',
                    width: 193,
                    textWrap: 'word-break',
                    ellipsis: true,
                }
            ];
            label = '模板变量';
            content = (
                <Table
                    columns={columns}
                    dataSource={item.keyword}
                    bordered={true}
                    rowKey={(record, index) => {
                        return index
                    }}
                    pagination={false}
                />
            )
        }

        return (
            <div className="question-info-line">
                <div className="info-line-label">{label}</div>
                <div
                    className={type === 'question' ? "info-line-content content-b" : type === 'keyword' ? "info-line-content content-t" : "info-line-content"}>{content}</div>
            </div>
        )
    };

    return (
        <div className="syllabus-info">
            <Spin spinning={loading}>
                <ul className="syllabus-info-ul">
                    {
                        infoData.map((item, index) => {
                            return (
                                <li key={`info_${index}`} className="syllabus-info-item">
                                    {QuestionLine('question', index, item)}
                                    {item.tag_names ? QuestionLine('tag_names', index, item) : null}
                                    {item.keyword && item.keyword.length>0 ? QuestionLine('keyword', index, item) : null}
                                </li>
                            )
                        })
                    }
                </ul>
            </Spin>
        </div>
    )
}

export default observer(SyllabusInfo)
