import React, { PureComponent } from 'react';
import _ from 'lodash'
import "./index.scss"
import PdfViewer from './ResultPdfViewer'
import { webSdk } from "../../../../../api/WebSdk";

//颜色组
const colorArr = [['#FFC107', '#FFC107']]


class Index extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            keyword: "",//关键词
            artId: "",//文章id
            lightId: "",//高亮点id
            pdfUrl: "",//pdf链接
            title: "",//文章标题
            time: "",//时间
            auth: "",//作者
            source: "",//来源
            serverLightData: "",//服务器高亮数据
            lightData: {},//按页码整理后的高亮数据
            lightColor: [],//高亮颜色组
            serverCanvas: [],//服务器canvas大小
            minPage: 0,//获取最小页码
        }
    }

    componentDidMount() {

        const _this = this;

        //获取参数
        let artId = _this.props.match.params.artId;
        let lightId = _this.props.match.params.lightId || -1;
        let keyword = _this.props.match.params.keyword;

        _this.setState({
            artId, lightId, keyword
        }, () => {
            //获取pdf数据
            _this.getPDFInfo();
        })
    }

    /**获取pdf数据 */
    getPDFInfo() {
        const _this = this;

        let id = _this.state.artId
        let kpid = _this.state.lightId
        webSdk.searchResultApi.getPDFDataById({ id, kpid }).then(res => {

            if (_.isEmpty(res)) return

            try {
                console.log(res.art_url)
                let dataUrl = res.art_url
                if (!_.startsWith(dataUrl, "https")) {
                    dataUrl = dataUrl.replace("http", "https")
                }
                _this.setState({
                    title: res.title,//文章标题
                    pdfUrl: dataUrl,//pdf链接
                    // time: res.date||"",//时间
                    // auth: res.author||"",//作者
                    // source: res.source||"",//来源
                    serverLightData: res.list || [],//高亮信息
                    serverCanvas: !_.isEmpty(res.list) ? res.list[0].page_size : [],//服务器canvas大小
                }, () => {
                    //处理高亮数据
                    _this.handleHighlightData();
                })
            } catch (err) {

            }
        })
    }

    //处理高亮数据
    handleHighlightData() {
        const _this = this;

        //获取数据
        let resList = _this.state.serverLightData;//服务器返回的高亮数据 resList = [{"id":1, pos_list:[], "page_size":[], ...}, {...}]

        //处理高亮数据
        let lightData = [] //lightData = [{"pageNum":1,list:[{"page_size":....},{}]}]
        _.forEach(resList, item => {//item = {"id":1, pos_list:[], "page_size":[], ...}
            let hightlightData = {} //hightlightData = {"page_size":[], "pos_list":{}, "id":1}
            let spoPosList = item["pos_list"]//spoPosList = [{"page": "1","word_range":[],"coords": "58.575,386.477,476.795,486.545", ...}]

            if (!_.isEmpty(spoPosList)) {
                //组合pdf尺寸、高亮信息
                hightlightData['page_size'] = item['page_size']
                hightlightData['pos_list'] = spoPosList
                hightlightData['id'] = item['id']
                // lightData.push(hightlightData)

                //按页码整合数据
                _.forEach(spoPosList, item => {//item = {"page": "1","word_range":[],"coords": "58.575,386.477,476.795,486.545", ...}
                    if (!_.isEmpty(lightData) && _.findIndex(lightData, { "pageNum": item.page }) >= 0) {

                        //获取 对应页面的数据对象
                        let pageData = _.find(lightData, { "pageNum": item.page }) // lightDataPage = {"pageNum":1, "list":[...]}

                        //将该坐标的数据加入集合
                        pageData.list.push(item)

                    } else {
                        lightData.push({
                            "pageNum": item.page,
                            "list": [item]
                        })
                    }
                })
            }
        })

        //获取最小页码
        let minPage = 0
        _.forEach(lightData, item => {
            if (minPage <= 0 || item.pageNum < minPage) {
                minPage = item.pageNum
            }
        })

        //根据属性，匹配相对应的颜色
        let lightColor = colorArr;

        //设置数据
        _this.setState({
            lightData, lightColor, minPage
        }, () => {
            //高亮数据展示
            // _this.hightlightInit();
        })
    }

    render() {
        return (
            <div className="reslute-source-index">
                <div className="pdfViewer-head">
                    <h3>{this.state.title}</h3>
                </div>
                <div className="pdfViewer-content"
                    style={
                        {
                            width: `${document.body.clientWidth - 20}px`,
                            minWidth: '750px',
                            height: `${document.body.clientHeight - 110}px`,
                            boxShadow: "0px 0px 10px 1px rgba(237,237,237,1)",
                        }
                    }
                >
                    <PdfViewer
                        fileUrl={this.state.pdfUrl}
                        lightData={this.state.lightData}
                        lightColor={this.state.lightColor}
                        serverCanvas={this.state.serverCanvas}
                        minPage={this.state.minPage}
                        size={{
                            width: `${document.body.clientWidth - 20}`,
                            height: `${document.body.clientHeight - 110}`
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default Index;