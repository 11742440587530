import React, { PureComponent } from 'react'
import _ from 'lodash'
import { Input, Table, Button, Icon, Spin, message, DatePicker } from 'antd'
import { webSdk } from "../../../api/WebSdk";
import 'rsuite/dist/styles/rsuite.min.css'
import queryString from 'query-string'
import moment from 'moment';
import "./index.scss"

const { Search } = Input;
const { RangePicker } = DatePicker;

class Gathering extends PureComponent {
    constructor(props) {
        super(props)

        let searchData = props.location.search || '';
        let page = 0,
            pageSize = 10,
            date = [],
            showDate = [],
            gatherSearch = "";

        if (searchData) {
            page = JSON.parse(queryString.parse(searchData)['page'] || '0');
            pageSize = parseInt(queryString.parse(searchData)['pageSize'] || '10');
            gatherSearch = JSON.parse(queryString.parse(searchData)['gatherSearch'] || '0');
            date = JSON.parse(queryString.parse(searchData)['date'] || '[]');
            if (date.length > 0) {
                date.forEach(item => {
                    item.replace(/-/g, '.')
                })
                showDate = [moment(date[0], 'YYYY.MM.DD'), moment(date[1], 'YYYY.MM.DD')]
            }
        }

        this.state = {
            keyword: props.match.params.keyword || '',
            searchValue: gatherSearch,//搜索值
            //关键词搜索
            gatheringKeyword: {
                title: '关键词搜索',
                titleKey: 'gatheringKeyword',
            },
            //日期
            timeValue: showDate,//展示
            gatheringDate: {
                title: '日期',
                titleKey: 'gatheringDate',
                value: date
            },

            tableLoading: false,
            //表格头部
            columns: [
                {
                    title: '序号',
                    dataIndex: 'index',
                    render: (text, record, index) => `${index + 1}`,
                    align: 'center',
                    width: 70
                },
                {
                    title: '数据集标题',
                    dataIndex: 'title',
                    key: 'title',
                    render: text => text || '-'
                },
                {
                    title: '作者',
                    dataIndex: 'company',
                    key: 'company',
                    width: 120,
                    render: text => text || '-'
                },
                {
                    title: '发布日期',
                    dataIndex: 'date',
                    key: 'date',
                    width: 150,
                    render: text => text || '-'
                },
                {
                    title: '操作',
                    dataIndex: 'view',
                    key: 'view',
                    width: 90,
                    align: 'center',
                    render: (text, record) => {
                        return (
                            <div className="table-action-cell" onClick={() => this.viewClick(record)}>查看</div>
                        )
                    }
                }
            ],
            tableData: [],
            page: page,
            pageSize: pageSize,
            pageTotal: 0,
            selectItem: [],          //当前选中的对象
            addListSusBtn: true,//加入阅读列表按钮是否在可视区内
            industryLoading: false,
            addLoading: false,
            addGatherLoading: false,//加入数据集状态
            filterSwitch: true,
            selections: [],
        }

        this.changeTime = this.changeTime.bind(this);
        this.pageChange = this.pageChange.bind(this);
        this.pageSizeChange = this.pageSizeChange.bind(this);
        this.viewClick = this.viewClick.bind(this);

    }

    componentDidMount() {
        this.props.fnSetType('gathering', this.state.keyword || '')
        window.document.addEventListener("scroll", this.listerScroll.bind(this))
        this.getTableData();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.location.search !== nextProps.location.search) {
            let searchData = nextProps.location.search || '';
            let npage = 0,
                npageSize = 10,
                ndate = [],
                nshowDate = [],
                ngatherSearch = "",
                searchValue = "";
            if (searchData) {
                npage = JSON.parse(queryString.parse(searchData)['page'] || '0');
                npageSize = parseInt(queryString.parse(searchData)['pageSize'] || '10');
                ngatherSearch = JSON.parse(queryString.parse(searchData)['gatherSearch'] || '0');
                ndate = JSON.parse(queryString.parse(searchData)['date'] || '[]');
                if (ndate.length > 0) {
                    ndate.forEach(item => {
                        item.replace(/-/g, '.')
                    })
                    nshowDate = [moment(ndate[0], 'YYYY.MM.DD'), moment(ndate[1], 'YYYY.MM.DD')]
                }
            }
            let { gatheringDate, page, pageSize } = this.state;
            page = npage;
            pageSize = npageSize;
            gatheringDate.value = ndate
            searchValue = ngatherSearch

            this.setState({
                timeValue: nshowDate,
                searchValue: ngatherSearch,
                gatheringDate, page, pageSize
            }, () => {
                this.getTableData();
            })
        }
    }

    componentWillUnmount() {
        // 卸载异步操作设置状态
        this.setState = (state, callback) => {
            window.document.removeEventListener("scroll", this.listerScroll.bind(this))
            return;
        }
    }
    /**搜索框数据变更 */
    searchInputChange(ev) {
        let val = ev.target.value;
        this.setState({
            searchValue: val
        })
    }

    /**搜索 */
    searchWord(val) {
        if ((typeof val === "string" && val) || (typeof val === "object" && val.target.value)) {
            let searchValue = val
            if (typeof val === "object") {
                searchValue = val.target.value
            }
            console.log("searchWord:" + searchValue)
        }
    }

    /**改变日期值 */
    changeTime(date, dateString) {
        let gatheringDate = { ...this.state.gatheringDate };
        let timeValue = date;
        gatheringDate.value = dateString;
        this.setState({
            timeValue,
            gatheringDate,
            page: 0
        })
    }

    /**监控滚动条 */
    listerScroll(event) {
        let currentTarget = event.target
        let addListBtn = currentTarget.querySelector('div#addListBtn');
        let addListSusBtn = this.state.addListSusBtn

        if (addListBtn && addListBtn.getBoundingClientRect().top < 64) {
            addListSusBtn = false
        }

        if (addListBtn && addListBtn.getBoundingClientRect().top >= 64) {
            addListSusBtn = true
        }

        this.setState({
            addListSusBtn
        })
    }

    /**获取表格信息 */
    getTableData() {
        let postData = {
            type: 4,       //查询类型：1.企业研报2.行业研报
            keyword: this.state.keyword,
            pageSize: this.state.pageSize,
            pageIndex: this.state.page,
            startdate: this.state.gatheringDate.value[0] || '',
            enddate: this.state.gatheringDate.value[1] || '',
        }

        this.setState({
            tableLoading: true
        }, () => {
            webSdk.search.researchReport(postData).then(res => {
                try {
                    if (!res.errCode || res.errCode === 0) {
                        let pageData = res.pager;
                        let page = pageData.index,
                            pageTotal = pageData.total || 0,
                            tableData = res.reportList;
                        this.setState({
                            page, pageTotal, tableData,
                            tableLoading: false
                        })
                    } else {
                        this.setState({
                            tableLoading: false
                        })
                        message.error(res.errMsg || '获取数据集列表失败，请稍后重试');
                    }
                } catch (e) {
                    this.setState({
                        tableLoading: false
                    })
                    message.error('获取数据集列表失败，请稍后重试');
                }
            }).catch(e => {
                this.setState({
                    tableLoading: false
                })
                message.error('获取数据集列表失败，请稍后重试');
            })
        })

    }

    /**加入阅读列表事件 */
    addToList() {
        let selectItem = [...this.state.selectItem];
        if (selectItem.length > 0) {
            this.setState({
                addLoading: true
            }, () => {
                let ids = [];
                for (let i = 0; i < selectItem.length; i++) {
                    ids.push(selectItem[i].id)
                }
                let postData = {
                    ids: ids.join(','),
                    type: this.state.selectType
                }
                webSdk.search.addReadList(postData).then(res => {
                    if (res.result) {
                        message.success("加入阅读列表成功");
                        this.setState({
                            addLoading: false,
                            selectItem: [],
                            selections: []
                        })
                    } else {
                        this.setState({
                            addLoading: false
                        })
                        message.error(res.errMsg || "加入阅读列表失败,请稍后重试")
                    }
                }).catch(e => {
                    this.setState({
                        addLoading: false
                    })
                    message.error("加入阅读列表失败,请稍后重试")
                })
            })
        }
    }

    /**加入数据集 */
    addToDataGather() {
        let selectItem = [...this.state.selectItem];
        if (selectItem.length > 0) {
            this.setState({
                addGatherLoading: true
            }, () => {
                let ids = [];
                for (let i = 0; i < selectItem.length; i++) {
                    ids.push(selectItem[i].id)
                }

                message.success("加入数据集成功");
                // let postData = {
                //     ids: ids.join(','),
                //     type: this.state.selectType
                // }
                // webSdk.search.addReadList(postData).then(res => {
                //     if (res.result) {
                //         message.success("加入阅读列表成功");
                //         this.setState({
                //             addLoading: false,
                //             selectItem: [],
                //             selections: []
                //         })
                //     } else {
                //         this.setState({
                //             addLoading: false
                //         })
                //         message.error(res.errMsg || "加入阅读列表失败,请稍后重试")
                //     }
                // }).catch(e => {
                //     this.setState({
                //         addLoading: false
                //     })
                //     message.error("加入阅读列表失败,请稍后重试")
                // })
            })
        }
    }

    /**
     * 页码变更
     */
    pageChange(page, pageSize) {
        this.setState({
            page: page - 1
        })
    }

    /**
     * 页尺寸变更
     */
    pageSizeChange(current, size) {
        this.setState({
            page: 0,
            pageSize: size
        })
    }

    /**
     * 表格查看事件
     */
    viewClick(record) {
        this.props.history.push({
            pathname: `/gatheringDetail`
        })
    }

    render() {
        const rowSelection = {
            columnWidth: 50,
            selectedRowKeys: this.state.selections,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selections: selectedRowKeys,
                    selectItem: selectedRows
                })
            },
        };

        return (
            <div className="home-page__research-library">
                <div className="company-list__industry-screen__main"
                    style={this.state.filterSwitch ? {} : { display: 'none' }}>

                    <div className="company-list__industry-screen__item">
                        <div
                            className="company-list__industry-screen__item-label">{this.state.gatheringKeyword.title}</div>
                        <div className="company-list__industry-screen__item-input">
                            <Search
                                className="center-search-input"
                                placeholder="查找"
                                allowClear={true}
                                value={this.state.searchValue}
                                onChange={ev => this.searchInputChange(ev)}
                                onSearch={value => this.searchWord(value)}
                                onBlur={ev => this.searchWord(ev)}
                                style={{ width: 160, height: 30 }}
                            />
                        </div>
                    </div>

                    <div className="company-list__industry-screen__item">
                        <div
                            className="company-list__industry-screen__item-label">{this.state.gatheringDate.title}</div>
                        <div className="company-list__industry-screen__item-input">
                            <RangePicker style={{ width: 160, height: 30 }} format={'YYYY.MM.DD'}
                                placeholder={['年-月-日', '年-月-日']}
                                onChange={(date, dateString) => this.changeTime(date, dateString)}
                                value={this.state.timeValue} />
                        </div>
                    </div>
                </div>

                <div className="company-list__table-main pt20">
                    <div className={!this.state.addListSusBtn && this.state.selectItem.length > 0 ? "table-main-list year-add-btn-suspend" : "table-main-list"}>
                        <Button type="primary"
                            loading={this.state.addLoading}
                            disabled={this.state.selectItem.length === 0}
                            onClick={this.addToList.bind(this)}
                            style={{ height: 30, padding: "0 15px", fontSize: 12, verticalAlign: "middle" }}>
                            加入阅读列表<Icon type="plus" />
                        </Button>&nbsp;
                        <Button type="primary"
                            loading={this.state.addLoading}
                            disabled={this.state.selectItem.length === 0}
                            onClick={this.addToDataGather.bind(this)}
                            style={{ height: 30, padding: "0 15px", fontSize: 12 }}>
                            加入数据集<Icon type="plus" />
                        </Button>
                    </div>
                    <div id="addListBtn" style={{ width: "10px", height: "20px", display: "inline-block", }} />
                    <Spin spinning={this.state.tableLoading}>
                        <div>
                            <Table
                                columns={this.state.columns}
                                dataSource={this.state.tableData}
                                rowSelection={rowSelection}
                                rowClassName={(record, index) => index % 2 === 0 ? 'single-row' : 'double-row'}
                                rowKey={(record, index) => {
                                    return index
                                }}
                                pagination={{
                                    showSizeChanger: true,
                                    showQuickJumper: true,
                                    pageSizeOptions: ["10", "15", "20", "25"],
                                    current: this.state.page + 1,
                                    pageSize: this.state.pageSize,
                                    total: this.state.pageTotal,
                                    onChange: (page, pageSize) => this.pageChange(page, pageSize),
                                    onShowSizeChange: (current, size) => this.pageSizeChange(current, size),
                                    showTotal: total => `共 ${total} 条`
                                }}
                            />
                        </div>
                    </Spin>
                </div>
            </div>)
    }
}

export default Gathering;