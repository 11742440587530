import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { DatePicker, Spin, Table } from 'antd'
import MySearch from '../../../common/MySearch/MySearch'
import Breadcrumb from '../../../common/Breadcrumb/Breadcrumb'
import { withRouter } from 'react-router-dom'
import { webSdk } from '../../../../api/WebSdk'

const { RangePicker } = DatePicker

const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    render: (text, record, index) => `${index + 1}`,
    align: 'center',
    width: 70
  },
  {
    title: '公司名称',
    dataIndex: 'org_name',
    key: 'org_name',
    render: (text) => text || '-'
  },
  {
    title: '股票代码',
    dataIndex: 'secucode',
    key: 'secucode',
    render: (text) => text || '-'
  },
  {
    title: '简称',
    dataIndex: 'security_name_abbr',
    key: 'security_name_abbr',
    render: (text) => text || '-'
  },
  {
    title: '上市板块',
    dataIndex: 'security_type',
    key: 'security_type',
    render: (text) => text || '-'
  },
  {
    title: '财报时间',
    dataIndex: 'report_time',
    key: 'report_time',
    width: 120,
    render: (text) =>
      moment(parseInt(new Date(text).getTime())).format('YYYY-MM-DD') || '-'
  }
  // {
  //   title: '操作',
  //   dataIndex: 'action',
  //   key: 'action',
  //   width: 70,
  //   render: (text, record) => {
  //     console.log(record)
  //     const { status } = record
  //     let cls =
  //       status === 0 ? 'table-action-cell unfinished' : 'table-action-cell'
  //     return (
  //       <div className="table-action-td">
  //         <div
  //           className="table-action-cell"
  //           onClick={() => this.clickDetail(record)}
  //         >
  //           {status === 0 ? (
  //             <Popover content="查看" trigger="hover" placement="bottomLeft">
  //               查看
  //             </Popover>
  //           ) : (
  //             '查看'
  //           )}
  //         </div>
  //       </div>
  //     )
  //   }
  // }
]

function HomePage({ history }) {
  const [filterDate, setFilterDate] = useState([])
  const [loading, setLoading] = useState(false)
  const [tableData, setTableData] = useState([])
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    total: 0
  })
  const [keyword, setKeyword] = useState('')

  useEffect(() => {
    async function fetchList() {
      setLoading(true)
      let resp
      let tableData
      if (keyword) {
        resp = await webSdk.financeSubLibraryApi.getInfoByName(keyword)
        resp.data.businessRegistrationInfo.forEach(
          (item) => (item.key = item.id)
        ) // Table组件的 datasource 需要唯一的 key属性
        tableData = resp.data.businessRegistrationInfo
      } else {
        resp = await webSdk.financeSubLibraryApi.getCompList(
          pagination.page,
          pagination.pageSize
        )
        resp.data.listedCompanyBaseInfo.forEach((item) => (item.key = item.id)) // Table组件的 datasource 需要唯一的 key属性
        tableData = resp.data.listedCompanyBaseInfo
      }
      console.log(resp)
      setTableData(tableData)
      setPagination({
        ...pagination,
        total: resp.data.total
      })
      setLoading(false)
    }
    fetchList()
  }, [pagination.page, pagination.pageSize, keyword])

  // 选择时间
  const changeRangeTime = (date, dateString) => {
    // let pagination = { ...this.state.pagination }
    // pagination.page = 1
    // if (!dateString[0]) {
    //     dateString = [];
    // }
    setFilterDate(dateString)
    // this.setState({
    //     filterDate: dateString,
    //     pagination
    // }, () => {
    //     this.getArticleList();
    // })
  }

  const handlePageNum = (newPage, newPageSize) => {
    let { pageSize, total } = pagination
    let newPagination = {
      page: newPage,
      pageSize,
      total
    }
    setPagination(newPagination)
  }

  const handlePageSize = (newPage, newPageSize) => {
    let { total } = pagination
    let newPagination = {
      page: 1,
      pageSize: newPageSize,
      total
    }
    setPagination(newPagination)
  }

  // 点击行
  const onRowClick = (event, record) => {
    console.log(event, record)
    history.push({
      pathname: `/fincSubLibraryDetail/${record.id}`
    })
  }

  const searchByKeyword = async (keyword) => {
    // const res = await webSdk.financeSubLibraryApi.getInfoByName(keyword)
    // console.log(res)
    // res.data.businessRegistrationInfo.forEach((item) => {
    //   item.name = item.org_name
    //   item.stock_symbol = item.security_code
    // })
    // return res.data.businessRegistrationInfo || []
    setLoading(true)
    const res = await webSdk.financeSubLibraryApi.getInfoByName(keyword, {
      current: pagination.page,
      limit: pagination.pageSize
    })
    setPagination({
      ...pagination,
      total: res.data.total
    })
    setTableData(res.data.businessRegistrationInfo)
    setLoading(false)
  }

  const searchClick = async (keyword) => {
    setKeyword(keyword)
    setPagination({
      page: 1,
      pageSize: 10,
      total: 0
    })
  }

  return (
    <>
      <div className="finc-sublibrary-head">
        <Breadcrumb
          history={history}
          crumbList={[{ key: '/fincSubLibrary', label: '财务子库' }]}
        />
        <div className="finc-sublibrary-filter">
          <MySearch
            searchByKeyword={searchByKeyword}
            searchClick={searchClick}
          />

          <div className="finc-sublibrary-filter-item finc-sublibrary-filter-time">
            <span>财报时间</span>
            <RangePicker
              style={{ width: 200, height: 30 }}
              value={
                !_.isEmpty(filterDate)
                  ? [
                      moment(filterDate[0], 'YYYY-MM-DD'),
                      moment(filterDate[1], 'YYYY-MM-DD')
                    ]
                  : null
              }
              format="YYYY-MM-DD"
              onChange={changeRangeTime}
            />
          </div>
        </div>
      </div>
      <div className="finance-list">
        <Spin spinning={loading}>
          <div>
            <Table
              className="finance-table"
              columns={columns}
              dataSource={tableData}
              rowClassName={(record, index) =>
                index % 2 === 0 ? 'single-row' : 'double-row'
              }
              rowKey={(record, index) => {
                return index
              }}
              onRow={(record) => ({
                onClick: (event) => onRowClick(event, record)
              })}
              pagination={{
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20', '25'],
                current: pagination.page,
                pageSize: pagination.pageSize,
                total: pagination.total,
                onChange: (page, pageSize) => handlePageNum(page, pageSize),
                onShowSizeChange: (current, size) =>
                  handlePageSize(current, size),
                showTotal: (total) => `共 ${total} 条`
              }}
            />
          </div>
        </Spin>
      </div>
    </>
  )
}

export default withRouter(({ history }) => <HomePage history={history} />)
