import React, {useState, useEffect, useRef} from 'react';
import {Input, Icon} from 'antd'
import ScrollBar from "../../common/ScrollBar";

function UploadModal(props) {
    const {tagListChange} = props;
    const [fileList, setFileList] = useState([...props.fileList]);
    const [tagList, setTagList] = useState([...props.tagList]);
    const [addFlag, setAddFlag] = useState(false);

    const tagInput = useRef(null);

    useEffect(() => {
        if (addFlag && tagInput) {
            tagInput.current.input.focus();
        }
    }, [addFlag]);

    const inputChange = (ev, index) => {
        let newFileList = [...fileList];
        newFileList[index].name = ev.target.value;
        setFileList(newFileList);
    };

    const deleteTag = index => {
        let newTagList = [...tagList];
        newTagList.splice(index, 1);
        setTagList(newTagList);
        tagListChange(newTagList);
    };

    const tagAddBlur = ev => {
        let value = ev.target.value;
        if (value !== '') {
            let newTagList = [...tagList];
            newTagList.push(value);
            setTagList(newTagList);
            tagListChange(newTagList);
        }
        ev.target.value = '';
        setAddFlag(false);
    };

    const tagAddEnter = ev => {
        if (tagInput) {
            tagInput.current.input.blur();
        }
    };

    return <div className="upload-modal-content">
        <h4 className="modal-title">编辑文档信息</h4>
        <ScrollBar className="upload-modal-scroll">
            <div className="modal-form">
                {
                    fileList.map((item, index) => (
                        <div key={`modal_name_${index}`} className="file-modal-item">
                            <span className="file-modal-label">文档名称</span>
                            <div className="file-modal-value">
                                <Input className="file-modal-input" defaultValue={item.name}
                                       onChange={ev => inputChange(ev, index)}/>
                                {item.type ? <span className="file-modal-type">{item.type}</span> : null}
                            </div>
                        </div>
                    ))
                }
                <div className="file-modal-item tags-item">
                    <span className="file-modal-label">文档标签</span>
                    <div className="file-modal-value">
                        <ul className="modal-tags-list">
                            {
                                tagList.map((item, index) => (
                                    <li key={`modal_tags_${index}`} className="modal-tags-item">
                                        {item}<Icon type="close" onClick={() => deleteTag(index)}
                                                    className="tag-delete-icon"/>
                                    </li>
                                ))
                            }
                            <li className={addFlag ? "modal-tags-item add-item add-item-input" : "modal-tags-item add-item"}>
                                {
                                    addFlag ?
                                        <Input className="add-tag-input" ref={tagInput} onBlur={tagAddBlur}
                                               onPressEnter={tagAddEnter}/> :
                                        <span className="add-tag-icon" onClick={() => setAddFlag(true)}><Icon
                                            type="plus"
                                            className="add-icon"/>添加</span>
                                }
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </ScrollBar>
    </div>
}

export default UploadModal
