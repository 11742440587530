import React, {useState} from 'react';
import {Cascader, Icon, Button} from 'antd';

/*let typeList = [
    {
        "label": "主观问题",
        "value": "1"
    },
    {
        "label": "客观问题",
        "value": "2",
        "children": [
            {
                "label": "概念定义",
                "value": "2-1",
                "pid": "2"
            },
            {
                "label": "图表",
                "value": "2-2",
                "pid": "2",
                "children": [
                    {
                        "label": "折线图",
                        "value": "2-2-1",
                        "pid": "2-2"
                    },
                    {
                        "label": "柱状图",
                        "value": "2-2-2",
                        "pid": "2-2"
                    },
                    {
                        "label": "饼图",
                        "value": "2-2-3",
                        "pid": "2-2"
                    },
                    {
                        "label": "散点图",
                        "value": "2-2-4",
                        "pid": "2-2"
                    },
                    {
                        "label": "K线图",
                        "value": "2-2-5",
                        "pid": "2-2"
                    },
                    {
                        "label": "雷达图",
                        "value": "2-2-6",
                        "pid": "2-2"
                    },
                    {
                        "label": "树图",
                        "value": "2-2-7",
                        "pid": "2-2"
                    },
                    {
                        "label": "关系图",
                        "value": "2-2-8",
                        "pid": "2-2"
                    }
                ]
            }
        ]
    }
];*/

function EditModal(props) {
    const {modal, setOpen, modalOk, tagTree, defaultValue} = props;

    const [selectedValue, setSelectedValue] = useState([]);

    const onChange = (value, selectedOptions) =>{
        setSelectedValue(value);
    };

    return (
        <div className="syllabus-edit-modal" style={modal?{}:{display: 'none'}}>
            <div className="edit-modal-content" id="edit-modal-content">
                <span className="edit-icon-close" onClick={() =>setOpen(false)}><Icon type="close" /></span>
                <div className="modal-cascader" id="modal-cascader">
                    <Cascader
                        options={tagTree}
                        onChange={onChange}
                        popupVisible={true}
                        getPopupContainer={() => document.getElementById('modal-cascader')}
                        popupClassName="cascader-block"
                        fieldNames={{label: 'name', value: 'name', children: 'children'}}
                        value={defaultValue}
                    >
                        <a href="#"></a>
                    </Cascader>
                </div>
                <div className="edit-modal-btn">
                    <Button type="primary" onClick={() => modalOk(selectedValue)}>确定</Button>
                </div>
            </div>
        </div>
    )
}

export default EditModal
