import React, { PureComponent } from 'react';
import { Icon, Spin } from 'antd'
import _ from 'lodash'
import "./LeftList.scss"
import { webSdk } from '../../../../../api/WebSdk'

class LeftList extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            keyword: this.props.keyword || "",
            dataListServer: [],//当前页的原始数据列表
            dataList: [//组装后的新数据集合
                // {
                //     title: "[好文分享]如何研究透一家企业的商业模式和估值模型？ - 简书",
                //     source: {
                //         type: "pdf",//来源类型：pdf；news 网页
                //         pdfId: 106576,
                //         lightId: 2513951,
                //         webSite: "https://www.jianshu.com/p/3597844981a6"
                //     },
                //     content: "2017 年 3 月 31 日商业模式进一步分析涉及所处的产业链的地位如何?处在产业链的上游、中游还是下游?整个产业链中有哪些不同的商业模式?关键的区别是什么?那些是最有定价权的企业?为什么?企业与客户的关系是否具备很强的粘性?等等，这些决定该商业模式能否成功。",
                // },
            ],
            currentPageNum: 1,//当前页码
            totalPage: 10,//总页码
            loadStatus: false,//加载状态:false加载更多，true正在加载
        }

    }
    componentDidMount() {

        this.initData()
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.keyword !== this.state.keyword) {
            this.setState({
                keyword: nextProps.keyword,
            }, () => {
                this.initData()
            })
        }
    }

    componentWillUnmount() {
        // 卸载异步操作设置状态
        this.setState = (state, callback) => {
            return;
        }
    }

    /**初始化数据 */
    initData() {
        this.setState({
            dataList: [],
            dataListServer: [],
            currentPageNum: 1
        }, () => {
            this.getData()
        })
    }

    /**获取数据 */
    getData() {
        const _this = this

        _this.setState({
            loadStatus: true
        }, () => {
            let params = {
                keyword: _this.state.keyword,
                page: _this.state.currentPageNum
            }
            webSdk.searchResultApi.getKeywordSearchData(params).then(res => {
                let dataListServer = []
                let resList = res.list
                let totalPage = res.pageNum

                _.forEach(resList, (item, idx) => {
                    dataListServer.push({
                        title: item.art_title,
                        source: {
                            type: item.art_type,//来源类型：pdf；news 网页
                            pdfId: item.art_id,
                            // lightId: 2513951,
                            webSite: item.art_source_link
                        },
                        originalContent: item.summary,
                        content: item.summary,
                        artDate: item.art_date
                    })
                })

                _this.setState({
                    dataListServer, totalPage,
                    loadStatus: false
                }, () => {
                    _this.dealData()
                })
            })
        })
    }

    /**处理数据 */
    dealData() {
        const _this = this;
        let dataListServer = _this.state.dataListServer
        let newDataList = _.cloneDeep(dataListServer)
        let dataList = [..._this.state.dataList]
        let keywordArr = _.uniq(_.split(_this.state.keyword, " "))

        //组装正则表达式
        let regExgArr = ["/("]
        _.forEach(keywordArr, (item, idx) => {
            if (idx !== 0) {
                regExgArr.push("|")
            }
            regExgArr.push(item)
        })
        regExgArr.push(")/g")
        let regExg = _.join(regExgArr, "")

        //循环数据
        _.forEach(newDataList, item => {
            if (_this.state.keyword) {
                item.content = _.replace(item.content, eval(regExg), "<em>$1</em>")
            }

            item.content = `<time>${item.artDate}</time>` + item.content

            dataList.push(item)
        })

        _this.setState({
            dataList,
            loadStatus: false
        })
    }

    /**加载数据 */
    loadingDate() {
        const _this = this
        let loadStatus = _this.state.loadStatus
        if (!loadStatus) {
            let currentPageNum = _this.state.currentPageNum + 1

            _this.setState({
                currentPageNum,
            }, () => {
                _this.getData()
            })
        }
    }

    render() {
        return (
            <div className="search-leftlist">
                <div className={`leftlist-content ${_.isEmpty(this.state.dataList) ? "no-child" : ""}`}>
                    {
                        this.state.dataList.map((item, idx) => {
                            return (
                                <div key={idx}
                                    className="leftlist-item"
                                >
                                    <a className="leftlist-item-title"
                                        href={item.source.type === "pdf" ? `/resultSource/${item.source.pdfId}/${this.state.keyword}/${item.source.lightId ? item.source.lightId : ""}` : item.source.webSite}
                                        target="_blank">
                                        <h3 title={item.title}>{item.title}</h3>
                                        <cite>{item.source.type === "pdf" ? "" : item.source.webSite}</cite>
                                    </a>
                                    <p dangerouslySetInnerHTML={{ __html: item.content }}
                                        title={item.originalContent}
                                        className={`leftlist-item-content ${item.content && item.content.length > 190 ? "item-content-elli" : ""}`}
                                    ></p>
                                </div>
                            )
                        })
                    }
                </div>
                {
                    this.state.dataList.length > 0 && this.state.currentPageNum < this.state.totalPage ? (
                        <div className="leftlist-load"
                            onClick={this.loadingDate.bind(this)}
                        >
                            <p className="leftlist-load-txt">{this.state.loadStatus ? "正在加载" : "加载更多"}</p>
                            <Icon className="leftlist-load-icon" type="down" />
                        </div>
                    ) : null
                }
            </div>
        );
    }
}

export default LeftList;