import React, { PureComponent } from 'react'
import _ from 'lodash'
import { Popover, Input, Button, Checkbox, Spin } from 'antd';
import TableModel from './TableModel'
import "./index.scss"
import { webSdk } from '../../../../../api/WebSdk';

const { TextArea } = Input;
const { Search } = Input;

class ListTable extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            keyword: props.keyword || '',//关键词
            tableSpinning: true,//表格加载状态
            showFilter: [ //展示过滤词
                {
                    title: "收盘价>18元",//过滤条件
                    total: 999//过滤后数据总量
                },
            ],
            reminder: { //提示
                ques: "价格",
                par: "收盘价",
                reslist: ["行情收盘价", "区间收盘价"]
            },
            valuation: { //评估
                useful: false,
                useless: false,
                usefulTotal: 0,
                uselessTotal: 0,
            },
            suggestion: { //提意见
                isShow: false,
                value: ""
            },
            filterCkb: {//筛选表格列
                isInit: true,//是否是初始化
                listShowValue: [],//显示列表的值
                listHideValue: [],//隐藏列表的值
                listHideIsShow: false,//隐藏列表是否展示
                listShow: [//多选框1，展示在页面上的
                    // { label: '涨跌幅（%）', value: 'Apple' },
                ],
                listHide: [//多选框2，隐藏的
                    // { label: '收盘价：不复权（元）', value: 'pineapple' },
                ]
            },
            tableSearchVal: "",//表格关键词搜索
            tableInfoChange: 0,//表格信息更改
            tableInfo: { //表格信息
                total: 0,
                column: [],//表头数据
                list: [],//表格数据
            },
            pagination: {//分页数据
                current: 1,//当前页码
                pageSize: 10,//每页显示的条数
                total: 0,//总条数
            },
        }
    }

    componentDidMount() {
        this.props.setType('listTb')
        this.init();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.keyword !== this.state.keyword) {
            this.setState({
                keyword: nextProps.keyword,
            }, () => {
                this.init()
            })
        }
    }

    componentWillUnmount() {
        // 卸载异步操作设置状态
        this.setState = (state, callback) => {
            return;
        }
    }

    /**初始化 */
    init() {
        let svgNodes = document.querySelectorAll('body > svg')
        _.forEach(svgNodes, node => {
            if (node)
                node.remove()
        })
        this.getData()
    }

    /**获取数据 */
    getData() {
        let tableInfo = { ...this.state.tableInfo }
        let pagination = { ...this.state.pagination }

        let param = {
            keyword: this.state.keyword,
            page: pagination.current,
            pageNum: pagination.pageSize
        }

        this.setState({
            tableSpinning: true
        }, () => {
            webSdk.searchResultApi.getSearchTableData(param).then(res => {
                pagination.total = res.total

                tableInfo.column = res.head_list
                tableInfo.list = res.list

                tableInfo.total = !_.isEmpty(res.list) ? res.list.length : 0

                this.refs.tableModel.changeTotal(pagination.total)
                this.setState({
                    tableInfo, pagination, tableSpinning: false,
                    tableInfoChange: ++this.state.tableInfoChange
                }, () => {
                    this.initFilterCkb()
                })
            }).catch((e) => {
                this.setState({
                    tableSpinning: false
                })
            })
        })
    }

    /**监控滚动条 */
    listenScroll(even) {
        try {
            this.refs.tableModel.listenScroll()
        } catch (e) {

        }
    }

    /**添加筛选条件 */
    addFilter() {
        this.props.fnTriggerFilterList(true, true)
    }

    /**
     * 给评价
     * @param {string} type 类型：useful有用；useless没用
     */
    changeValuation(type) {

        const _this = this;
        let valuation = { ..._this.state.valuation }
        let useful = valuation.useful
        let useless = valuation.useless

        //关闭已启用的评价，并减少相应的值
        //开启未启用的评价，并增加相应的值
        //开启评价时，需将另一个已开启的评价关闭，并减少相应的值
        switch (type) {
            case "useful":
                if (useful) {
                    valuation.useful = false;
                    if (valuation.usefulTotal > 0)
                        valuation.usefulTotal -= 1;
                } else {
                    valuation.useful = true;
                    valuation.usefulTotal += 1;

                    if (useless) {
                        valuation.useless = false;
                        if (valuation.uselessTotal > 0)
                            valuation.uselessTotal -= 1;
                    }
                }
                break;
            case "useless":
                if (useless) {
                    valuation.useless = false;
                    if (valuation.uselessTotal > 0)
                        valuation.uselessTotal -= 1;
                } else {
                    valuation.useless = true;
                    valuation.uselessTotal += 1;

                    if (useful) {
                        valuation.useful = false;
                        if (valuation.usefulTotal > 0)
                            valuation.usefulTotal -= 1;
                    }
                }
                break;
        }

        _this.setState({
            valuation
        })

    }

    /**打开/关闭意见框 */
    triggerSuggestion() {
        const _this = this;
        let suggestion = { ..._this.state.suggestion }
        suggestion.isShow = !suggestion.isShow

        _this.setState({
            suggestion
        })
    }

    /**改变建议框的值 */
    changeSuggestionValue(target) {
        let value = target.currentTarget.value
        let suggestion = { ...this.state.suggestion }
        suggestion.value = value
        this.setState({
            suggestion
        })
    }

    /**初始化表格列 */
    initFilterCkb() {

        let tableInfo = { ...this.state.tableInfo }
        let columns = tableInfo.column
        let filterCkb = { ...this.state.filterCkb }
        let isInit = filterCkb.isInit
        let listShowValue = []//显示列表的值
        let listHideValue = []//隐藏列表的值
        let listShow = []//多选框1，展示在页面上的
        let listHide = []//多选框2，隐藏的
        _.forEach(columns, (item, idx) => {
            if (idx < 2) {
                if (isInit) {
                    listShowValue.push(item)
                }

                listShow.push({
                    label: item,
                    value: item
                })
            } else {
                if (isInit) {
                    listHideValue.push(item)
                }

                listHide.push({
                    label: item,
                    value: item
                })
            }
        })

        filterCkb.listShowValue = listShowValue
        filterCkb.listHideValue = listHideValue
        filterCkb.listShow = listShow
        filterCkb.listHide = listHide

        this.setState({
            filterCkb
        })
    }

    /**筛选表格列
     * @param {array} checkedValues 选项值
     * @param {string} flag 列表类型：show显示；false隐藏
     */
    changeFilterCkb(flag, checkedValues) {
        let filterCkb = { ...this.state.filterCkb }
        let tableInfo = { ...this.state.tableInfo }
        let listShow = filterCkb.listShow
        let hideShow = filterCkb.listHide

        switch (flag) {
            case "show":
                filterCkb.listShowValue = []
                _.forEach(listShow, item => {
                    let value = item.value
                    if (checkedValues.indexOf(value) > -1) {
                        filterCkb.listShowValue.push(value)
                    }
                })
                break;
            case "hide":
                filterCkb.listHideValue = []
                _.forEach(hideShow, item => {
                    let value = item.value
                    if (checkedValues.indexOf(value) > -1) {
                        filterCkb.listHideValue.push(value)
                    }
                })
                break;
        }

        let column = _.concat(filterCkb.listShowValue, filterCkb.listHideValue)
        tableInfo.column = column

        this.setState({
            filterCkb, tableInfo,
            tableInfoChange: ++this.state.tableInfoChange
        })
    }

    /**打开/关闭隐藏多选框框 */
    triggerHideCkbList() {
        const _this = this;
        let filterCkb = { ..._this.state.filterCkb }
        filterCkb.listHideIsShow = !filterCkb.listHideIsShow

        _this.setState({
            filterCkb
        })
    }

    /** 表格搜索框
     * @param {string} searchVal 搜索框值
     */
    searchTable(searchVal) {

        this.setState({
            tableSearchVal: searchVal
        })
    }

    /**改变每页显示条数
     * 
     * @param {number} pageSize 每页条数
     */
    changePageSize(pageSize) {
        let pagination = { ...this.state.pagination }
        pagination.pageSize = pageSize

        this.setState({
            pagination
        }, () => {
            this.getData()
        })
    }

    /**改变页码
     * 
     * @param {number} page 页码
     */
    changePage(page) {
        let pagination = { ...this.state.pagination }
        pagination.current = page

        this.setState({
            pagination
        }, () => {
            this.getData()
        })
    }

    render() {
        //建议框的表单
        let suggestionContent = (
            <>
                <h3 className="suggestion-title">意见反馈</h3>
                <TextArea
                    id="suggestionArea"
                    className="suggestion-area"
                    placeholder="留下你的宝贵意见吧~"
                    value={this.state.suggestion.value}
                    onChange={this.changeSuggestionValue.bind(this)}
                />
                {
                    this.state.suggestion.value ?
                        (
                            <Button className="suggestion-btn" type="primary">提交</Button>
                        ) : (
                            <Button className="suggestion-btn" type="primary" disabled>提交</Button>
                        )
                }

            </>
        )

        let filterCkb = (
            <Checkbox.Group
                className="ckblist-item"
                options={this.state.filterCkb.listHide}
                value={this.state.filterCkb.listHideValue}
                onChange={this.changeFilterCkb.bind(this, "hide")}
            />
        )
        return (
            <div
                id="listTable"
                className="list-table"
                onScroll={this.listenScroll.bind(this)}
            >
                {/* <p className="listtb-reminder">问句中的<span className="reminder-ques">{this.state.reminder.ques}</span>已按<span className="reminder-par">{this.state.reminder.par}</span>搜索 也可换用{this.state.reminder.reslist.map((item, idx) => (<a key={idx} className="reminder-res">{item}</a>))}</p> */}

                <div className="listtb-main">
                    <div className="listtb-filter">
                        {/* <div className="filter-words">
                            <ul>
                                {
                                    this.state.showFilter.map((item, idx) => (
                                        <li key={idx}>
                                            {item.title}
                                            <span className="filter-words-total">({item.total}个)</span>
                                        </li>
                                    ))
                                }
                                <li className="filter-words-add" onClick={this.addFilter.bind(this)}>
                                    <span className="iconfont">&#xe60c;</span>添加筛选条件
                                </li>
                            </ul>
                        </div> */}

                        {/* <div className="filter-valuation">
                            <ul>
                                {
                                    this.state.valuation.useful ?
                                        (
                                            <li className="filter-valuation-active" onClick={this.changeValuation.bind(this, "useful")}>
                                                <span className="iconfont filter-valuation-icon">&#xe611;</span>有用{this.state.valuation.usefulTotal}
                                            </li>
                                        ) : (
                                            <li onClick={this.changeValuation.bind(this, "useful")}>
                                                <span className="iconfont filter-valuation-icon">&#xe60f;</span>有用{this.state.valuation.usefulTotal}
                                            </li>
                                        )
                                }
                                {
                                    this.state.valuation.useless ?
                                        (
                                            <li className="filter-valuation-active" onClick={this.changeValuation.bind(this, "useless")}>
                                                <span className="iconfont filter-valuation-icon">&#xe610;</span>没用{this.state.valuation.uselessTotal}
                                            </li>
                                        ) : (
                                            <li onClick={this.changeValuation.bind(this, "useless")}>
                                                <span className="iconfont filter-valuation-icon">&#xe60e;</span>没用{this.state.valuation.uselessTotal}
                                            </li>
                                        )
                                }
                                <li>
                                    <Popover
                                        trigger="click"
                                        placement="bottomRight"
                                        overlayClassName="listtb-filter-suggestion"
                                        content={suggestionContent}
                                        visible={this.state.suggestion.isShow}
                                        onVisibleChange={this.triggerSuggestion.bind(this)}
                                    >
                                        <span className="iconfont filter-valuation-icon">&#xe612;</span>提建议
                                    </Popover>
                                </li>
                            </ul>
                        </div> */}

                    </div>
                    <div className="listtb-funct">
                        <div className="funct-left">
                            {/* <p className="funct-left-total">
                                选出公司数<span>{this.state.tableInfo.total}</span>
                            </p> */}

                            {/* <div className="funct-left-btn">
                                <span className="iconfont funct-left-icon">&#xe60d;</span>导数据
                            </div> */}

                            <div className="funct-left-ckblist">
                                <span className="ckblist-title">添加指标到表格</span>
                                <Checkbox.Group
                                    className="ckblist-item"
                                    options={this.state.filterCkb.listShow}
                                    value={this.state.filterCkb.listShowValue}
                                    onChange={this.changeFilterCkb.bind(this, "show")}
                                />
                                <div className="ckblist-hide">
                                    <Popover
                                        trigger="click"
                                        placement="bottomLeft"
                                        overlayClassName="ckblist-hide-list"
                                        content={filterCkb}
                                        visible={this.state.filterCkb.listHideIsShow}
                                        onVisibleChange={this.triggerHideCkbList.bind(this)}
                                    >
                                        <span className="iconfont ckblist-hide-icon">&#xe60c;</span>
                                    </Popover>
                                </div>

                            </div>
                        </div>
                        {/* <div className="funct-right">
                            <Search
                                placeholder="关键词 快速搜"
                                className="funct-search"
                                onSearch={this.searchTable.bind(this)}
                            />
                        </div> */}
                    </div>
                    <div className="listtb-table">
                        <Spin spinning={this.state.tableSpinning}>
                            <TableModel
                                ref="tableModel"
                                tableInfo={this.state.tableInfo}
                                tableInfoChange={this.state.tableInfoChange}
                                fnChangePageSize={this.changePageSize.bind(this)}
                                fnChangePage={this.changePage.bind(this)}
                            ></TableModel>
                        </Spin>
                    </div>
                </div>

            </div>
        );
    }
}

export default ListTable;