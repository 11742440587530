import axios from 'axios'
/* URLSearchParams 浏览器兼容 */
import 'url-search-params-polyfill'
import _ from 'lodash'

export default function (opts, defObj = null) {
	var obj = axios.create(opts);
	obj.postForm = function (url, data, config = []) {
		//var paras = null;
		let paras = new URLSearchParams();
		for (let i in data) {
			paras.append(i, data[i]);
		}

		return obj.post(url, paras, config);
	}

	obj.postJson = function (url, data, config = []) {

		return obj.post(url, data, config);
	}
	_.assign(obj, defObj);
	return obj;
}
