import React, { PureComponent } from 'react';
import _ from 'lodash'
import moment from "moment"
import "./index.scss"
import "../Index.scss"
import AppContext from '../../../AppContext'
import { Spin, Table, Input, Popover, DatePicker, Icon, Button, message, Modal, Empty } from 'antd';
import stores from "../../../store";
import Breadcrumb from "../../common/Breadcrumb/Breadcrumb";
import { webSdk } from '../../../api/WebSdk';
import AdvancedSearch from '../../common/AdvancedSearch/AdvancedSearch'
import AdvancedSearchContent from '../../common/AdvancedSearchContent/AdvancedSearchContent'

const { RangePicker } = DatePicker;
const { Search } = Input;

class FinanceLibrary extends PureComponent {
    static contextType = AppContext

    constructor(props) {
        super(props);
        this.state = {
            financeLibraryParamsStorKey: "financePageParams",
            loading: false,
            keyword: "",
            tableData: [],
            filterDate: [],//日期筛选
            pagination: {
                page: 1,
                pageSize: 10,
                total: 0,
            },
            columns: [
                {
                    title: '序号',
                    dataIndex: 'index',
                    render: (text, record, index) => `${index + 1}`,
                    align: 'center',
                    width: 70
                },
                {
                    title: '标题',
                    dataIndex: 'title',
                    key: 'title',
                    render: text => text || '-'
                },
                {
                    title: '来源',
                    dataIndex: 'source',
                    key: 'source',
                    render: text => text || '-'
                },
                {
                    title: '作者',
                    dataIndex: 'author',
                    key: 'author',
                    render: text => text || '-'
                },
                {
                    title: '网站名称',
                    dataIndex: 'webName',
                    key: 'webName',
                    render: text => text || '-'
                },
                {
                    title: '发布日期',
                    dataIndex: 'publicTime',
                    key: 'publicTime',
                    width: 120,
                    render: text => text || '-'
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    key: 'action',
                    width: 70,
                    render: (text, record) => {
                        const { status } = record;
                        let cls = status === 0 ? "table-action-cell unfinished" : "table-action-cell";
                        return (
                            <div className="table-action-td">
                                <div className="table-action-cell" onClick={() => this.clickDetail(record)}>
                                    {status === 0 ? <Popover content="查看" trigger="hover"
                                        placement="bottomLeft">查看</Popover> : "查看"}
                                </div>
                            </div>
                        )
                    }
                }
            ],
            selectItem: [],          //当前选中的对象
            addListSusBtn: true,//加入阅读列表按钮是否在可视区内
            selectType: parseInt(props.match.params.type || 1),
            isShowAdvancedSearch: false,
            projectList: [],
            isShowProjectModal: false,
            projectId: -1,
            filterData: []
        }
    }

    componentDidMount() {
        stores.financeStore.setPageInfo([
            {
                label: "财经库",
                key: "/finance"
            }
        ], 'list');
        this.init();
        let postData = {
            proj_id: ''
        };
        webSdk.search.queryProjectList(postData).then(res => {
            this.setState({
                projectList: res || []
            })
        })
        webSdk.search.queryProjectFilter({type: 'finance'}).then(res => {
            let data = res || [];
            this.setState({
                filterData: data
            })
        })
    }

    componentDidUpdate(nextProps, nextState) {

        let newPage = nextState.pagination.page;
        let newPageSize = nextState.pagination.pageSize;
        let { page, pageSize } = this.state.pagination;
        if (newPage != page || newPageSize != pageSize) {
            this.getArticleList();
        }
    }

    componentWillUnmount() {
        //财经库之间跳转不需要清空localStorage( entersinfo enterdetail)
        let nextPath = this.context.getHistory().location.pathname
        if (nextPath.indexOf("/financedetail") == -1) {
            this.setLocStorage(this.state.financeLibraryParamsStorKey, "")
        }

        this.setState = (state, callback) => {
            return;
        }
    }

    /** 在localStorage中保存值
 * @param {string} key 保存的键名
 * @param {object} obj 保存的对象
 */
    setLocStorage(key, obj) {
        let objJSON = ""
        if (!_.isEmpty(obj))
            objJSON = JSON.stringify(obj)
        window.localStorage.setItem(key, objJSON)
    }

    /** 从localStorage中获取值
     * @param {string} key 键名
     */
    getLocStorage(key) {
        let objStr = window.localStorage.getItem(key)
        if (!objStr) return null;
        return JSON.parse(objStr)
    }

    init() {
        let pageParams = this.getLocStorage(this.state.financeLibraryParamsStorKey)

        if (pageParams != null) {
            let keyword = pageParams.keyword
            let filterDate = pageParams.filterDate
            let pagination = pageParams.pagination

            this.setState({
                keyword, filterDate, pagination
            }, () => {
                this.getArticleList();
            })
        } else {
            this.getArticleList();
        }

    }

    clickDetail(item) {

        this.savePageParams2Storage(item);

        // this.context.getHistory().push({
        //     pathname: `/financedetail/${item.id}`
        // })
        this.props.history.push({
            pathname: `/financedetail/${item.id}`
        })
    };

    handlePageNum(newPage, newPageSize) {

        let { pageSize, total } = this.state.pagination
        let pagination = {
            page: newPage,
            pageSize: pageSize,
            total
        }
        this.setState({
            pagination
        })
    };

    handlePageSize(newPage, newPageSize) {

        let { total } = this.state.pagination
        let pagination = {
            page: 1,
            pageSize: newPageSize,
            total
        }
        this.setState({
            pagination
        })
    };

    getArticleList() {
        let { pagination, keyword, filterDate } = this.state;
        let { filterData } = this.state
        let source = '',  // 数据源
            query_type = '';
        if(filterData.length > 0) {
            for(let i = 0; i< filterData.length; i++) {
                if(filterData[i].key === 'query_type' && filterData[i].hasOwnProperty('selected') && filterData[i].selected.length > 0) {
                    query_type = filterData[i].selected.join(',')
                }else if(filterData[i].key === 'secname' && filterData[i].hasOwnProperty('selected') && filterData[i].selected.length > 0) {
                    source = filterData[i].selected.join(',')
                }
            }
        }
        // let postData =  {
        //     page: pagination.page,
        //     page_size: pagination.pageSize,
        //     art_type: 'finance',
        //     keyword: keyword,
        //     query_type: query_type,
        //     start_date: filterDate.length>0 ? filterDate[0] : '',
        //     end_date: filterDate.length>0 ? filterDate[1] : ''
        // }
        this.setState({
            loading: true
        }, () => {
            //webSdk.search.advLocalSearch(postData).then(res => {
            webSdk.financeLibraryApi.getArticleList({
                pageNum: pagination.page,
                pageSize: pagination.pageSize,
                keyword,
                publicTimes: filterDate
            }).then(res => {
    
                console.log(res)

                // let resTotal = res.data.pageNum;
                // let resData = res.data.list;
                let resTotal = res['total'];
                let resData = res["data"];
                let newTableData = [];
                if(resTotal === 0) {
                    this.setState({
                        loading: false,
                        tableData: newTableData,
                        pagination: newPagination,
                    })
                }

                _.forEach(resData, async item => {
                    let content = await webSdk.financeLibraryApi.getArticleContent(item["id"])
                    newTableData.push({
                        id: item["id"],
                        title: item["art_title"],
                        source: item["art_source"],
                        author: item["art_author"],
                        webName: item["web_name"],
                        publicTime: item["art_date"] ? _.split(item["art_date"], " ")[0] : "",
                        sourceLink: item["art_source_link"],
                        type: item["art_type"],
                        content: content["art_content"]

                    });
                    if(resData.length === newTableData.length) {
                        let newPagination = {
                            page: pagination.page,
                            pageSize: pagination.pageSize,
                            total: resTotal,
                        };
        
                        this.setState({
                            loading: false,
                            tableData: newTableData,
                            pagination: newPagination,
                        })
                    }
                });

            }).catch(e => {
                this.setState({
                    loading: false
                })
            });
        })
    };

    // 搜索关键词
    // searchKeyword(element) {
    //     if (!element) return;
    //     let pagination = { ...this.state.pagination }
    //     let keyword = ""
    //     pagination.page = 1;
    //     if (typeof element === "object") {
    //         keyword = element.currentTarget.value
    //     } else {
    //         keyword = element;
    //     }

    //     this.setState({
    //         keyword,
    //         pagination
    //     }, () => {
    //         this.getArticleList();
    //     })
    // }

    // searchChange(event) {
    //     let keyword = event.currentTarget.value;
    //     this.setState({
    //         keyword
    //     }, () => {
    //         if (keyword === "") {
    //             this.getArticleList();
    //         }
    //     })
    // }

    // 选择时间
    changeRangeTime(date, dateString) {
        let pagination = { ...this.state.pagination }
        pagination.page = 1

        if (!dateString[0]) {
            dateString = [];
        }
        this.setState({
            filterDate: dateString,
            pagination
        }, () => {
            this.getArticleList();
        })
    }

    /**保存页面参数到localStorage */
    savePageParams2Storage(financeItem) {
        let financeId = financeItem.id;
        let financeTitle = financeItem.title
        let { pagination, filterDate, keyword } = this.state
        //保存页面参数到localStorage中
        let pageParams = {//页面参数
            pagination, filterDate, keyword, financeId, financeTitle
        }
        
        this.setLocStorage(this.state.financeLibraryParamsStorKey, pageParams)
    }

    /**
     * 加入阅读列表事件
     */
     addToList() {
        console.log(this.state.selectItem);
        let selectItem = [...this.state.selectItem];
        if (selectItem.length > 0) {
            this.setState({
                addLoading: true
            }, () => {
                let ids = [];
                for (let i = 0; i < selectItem.length; i++) {
                    ids.push(selectItem[i].id)
                }
                let postData = {
                    ids: ids.join(','),
                    type: this.state.selectType
                }
                webSdk.search.addReadList(postData).then(res => {
                    if (res.result) {
                        message.success("加入阅读列表成功");
                        this.setState({
                            addLoading: false,
                            selectItem: [],
                            selections: []
                        })
                    } else {
                        this.setState({
                            addLoading: false
                        })
                        message.error(res.errMsg || "加入阅读列表失败,请稍后重试")
                    }
                }).catch(e => {
                    this.setState({
                        addLoading: false
                    })
                    message.error("加入阅读列表失败,请稍后重试")
                })
            })
        }

    }

   // 高级搜索筛选词改变
   changeFilterData(data) {
        this.setState({
            filterData: data
        })
    }

    //  搜索关键词
    searchByKeyword(keyword) {
        let pagination = { ...this.state.pagination }
        pagination.page = 1
        this.setState({
            keyword: keyword,
            pagination
        }, () => {
            this.getArticleList();
        })
    }

    searchClick() {
        this.setState({
            isShowAdvancedSearch: true
        })
    }

    handleOk() {
        let selectItem = [...this.state.selectItem];
        console.log("handleOk")
        console.log(this.state.selectItem);
        if (selectItem.length > 0) {
            this.setState({
                addLoading: true
            }, () => {
                let postData = {
                    article: []
                }
                for (let i = 0; i < selectItem.length; i++) {
                    let articleObj = {}
                    articleObj.title = selectItem[i].title
                    articleObj.doc_type = selectItem[i].type
                    articleObj.file_content = selectItem[i].content
                    articleObj.doc_url = selectItem[i].sourceLink
                    articleObj.publish_time = selectItem[i].publicTime
                    postData.article.push(articleObj)
                }
                webSdk.search.createDoc(postData,this.state.projectId).then(res => {
                    if (res.result) {
                        message.success("加入项目成功");
                        this.setState({
                            addLoading: false,
                            isShowProjectModal: false,
                            selectItem: [],
                            selections: []
                        })
                    } else {
                        this.setState({
                            addLoading: false,
                            isShowProjectModal: false
                        })
                        message.error(res.errMsg || "加入项目失败,请稍后重试")
                    }
                }).catch(e => {
                    this.setState({
                        addLoading: false,
                        isShowProjectModal: false
                    })
                    message.error("加入项目失败,请稍后重试")
                })
            })
        }

    }

    handleCancel() {
        this.setState({
            isShowProjectModal: false,
            selectItem: [],
            selections: []
        })
    }

    render() {
        let { loading, columns, tableData, pagination, keyword, filterData } = this.state;
        const rowSelection = {
            columnWidth: 50,
            selectedRowKeys: this.state.selections,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selections: selectedRowKeys,
                    selectItem: selectedRows
                })
            },
        };

        return (
            <div className="home-page__research-library finance-page">
                <div className="finance-head">
                    <Breadcrumb history={this.history} crumbList={stores.financeStore.crumbList.slice()} />
                    <div className="finance-filter">


                        {/* <div className="finance-filter-item finance-filter-keyword">
                            <span>搜索</span>
                            <Search
                                placeholder="请输入关键词"
                                onSearch={this.searchKeyword.bind(this)}
                                onChange={this.searchChange.bind(this)}
                                onPressEnter={this.searchKeyword.bind(this)}
                                style={{ width: 200 }}
                                value={keyword}
                            />
                        </div> */}

                        <div className="finance-filter-item finance-filter-time">
                            <span>创建时间</span>
                            <RangePicker
                                style={{ width: 200, height: 30 }}
                                value={!_.isEmpty(this.state.filterDate) ? [moment(this.state.filterDate[0], "YYYY-MM-DD"), moment(this.state.filterDate[1], "YYYY-MM-DD")] : null}
                                format="YYYY-MM-DD"
                                onChange={this.changeRangeTime.bind(this)}
                            />
                        </div>
                        <AdvancedSearch searchByKeyword={this.searchByKeyword.bind(this)}  searchClick={this.searchClick.bind(this)} />

                    </div>

                </div>
                {
                    this.state.isShowAdvancedSearch && <AdvancedSearchContent data={filterData} filterDataChange={this.changeFilterData.bind(this)} />
                }
                <div className="company-list__table-main pt20">
                    <div className={!this.state.addListSusBtn && this.state.selectItem.length > 0 ? "table-main-list year-add-btn-suspend" : "table-main-list"}>
                        <Button type="primary"
                            loading={this.state.addLoading}
                            disabled={this.state.selectItem.length === 0}
                            onClick={() => this.setState({isShowProjectModal: true})}
                            style={{ height: 30, padding: "0 15px", fontSize: 12, verticalAlign: "middle", marginRight: '20px' }}

                        >添加到我的项目<Icon type="plus" /></Button>
                        <Button type="primary"
                                loading={this.state.addLoading}
                                disabled={this.state.selectItem.length === 0}
                                onClick={this.addToList.bind(this)}
                            style={{ height: 30, padding: "0 15px", fontSize: 12, verticalAlign: "middle"}}>
                            加入阅读列表<Icon type="plus" />
                        </Button>
                        <div id="addListBtn" style={{width: "10px", height: "20px", display: "inline-block",}}/>
                    </div>
                    <Spin spinning={loading}>
                        <div>
                            <Table
                                className="finance-table"
                                columns={columns}
                                dataSource={tableData}
                                rowSelection={rowSelection}
                                rowClassName={(record, index) => index % 2 === 0 ? 'single-row' : 'double-row'}
                                rowKey={(record, index) => {
                                    return index
                                }}
                                pagination={{
                                    showSizeChanger: true,
                                    showQuickJumper: true,
                                    pageSizeOptions: ["10", "15", "20", "25"],
                                    current: pagination.page,
                                    pageSize: pagination.pageSize,
                                    total: pagination.total,
                                    onChange: (page, pageSize) => this.handlePageNum(page, pageSize),
                                    onShowSizeChange: (current, size) => this.handlePageSize(current, size),
                                    showTotal: total => `共 ${total} 条`
                                }}
                            />
                        </div>
                    </Spin>
                </div>
                <Modal className='project-modal' width={400} title="将“已选文档”添加到…" visible={this.state.isShowProjectModal} onCancel={this.handleCancel.bind(this)} footer={[
                        <Button key="back" onClick={this.handleCancel.bind(this)}>
                            取消
                        </Button>,
                        <Button key="submit" style={{backgroundColor:'#5B6BAE', color:'#fff'}} onClick={this.handleOk.bind(this)}>
                            添加
                        </Button>
                    ]}>
                    <ul className='project-wrapper'>
                        {
                            this.state.projectList.length > 0 ? this.state.projectList.map((item) => {
                                return <li key={item.proj_id} onClick={() => this.setState({projectId: item.proj_id})} className={item.proj_id === this.state.projectId ? "active" : null }>{item.proj_name}</li>
                            }):
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        }
                    </ul>
                </Modal>
            </div>
        );
    }
}

export default FinanceLibrary;