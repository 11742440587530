import _ from 'lodash'

/**
 * 数组元素移动位置，模拟分页数据变化
 * Example: arrOffset([0, 1, 2, 3, 4], 2) == [2, 3, 4, 0, 1]
 * @param {*} arr 
 * @param {*} offset 
 */
export function arrOffset(arr, offset) {
    if (!arr || !offset) return arr
    let len = arr.length
    let i = offset % len
    if (!i) return arr
    return _.concat(arr.slice(i, len), arr.slice(0, i))
}

/**
 * 融合真假数据Api，当RealApi返回Promise.reject('TODO')时，调用MockApi接口获取数据
 * @param {*} RealApi 真实数据Api，对接后台接口
 * @param {*} MockApi 模拟数据Api，通常返回public/mockData目录的json文件
 */
export function mergeMockApi(RealApi, MockApi) {
    class MergedApi extends RealApi {
        constructor(opts) {
            super(opts)
            const __mockApi = new MockApi(opts)
            return new Proxy(this, {
                get(target, name) {
                    if (!(name in target)) return undefined
                    let cb = target[name]
                    let mockCb = __mockApi[name]
                    if (!_.isFunction(cb) || !mockCb) return cb
                    
                    return function() {
                        let args = [].slice.call(arguments)
                        let val = cb.apply(target, args)
                        if (val instanceof Promise) {
                            return val.catch(err => {
                                if (err === 'TODO') {
                                    // console.log('call mockApi', name, args)
                                    return mockCb.apply(__mockApi, args)
                                }
                                throw err
                            })
                        }
                        return val
                    }
                }
            })
        }
    }

    return MergedApi
}