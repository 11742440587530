import {action, observable} from 'mobx'

/**
 * 首页左侧
 */
export default class HomeLeftStore {
    @observable leftOpen = true;      // 左侧展开收缩

    @observable questionList = [];      // 左侧问题列表
    @observable crtQuestionIndex = null;    // 当前选择问题index

    @action unmountAction = () =>{
        this.leftOpen = true;
        this.questionList = [];
        this.crtQuestionIndex = null;
    };

    @action setLeftOpen = status => {
        this.leftOpen = status;
    };

    @action setQuestionList = list => {
        this.questionList = list;
    };

    @action setCrtQuestionIndex = index =>{
        this.crtQuestionIndex = index;
    };

}
