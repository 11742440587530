import React, { PureComponent } from 'react'
import _ from 'lodash'
import { webSdk } from '../../../../../api/WebSdk'
import { Spin } from 'antd'
import './index.scss'
import RightList from './RightList'
import LeftList from './LeftList'
import LeftFn from './leftFnGroup'

class Index extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      keyword: props.keyword || '',
      leftFnDataServerArr: [], //左侧功能数据数组
      leftFnDataServer: {}, //左侧功能数据
      rightDataServer: [], //右侧列表数据
      leftFnLoading: true, //左侧功能模块加载状态
      hasLeftFnData: false, //左侧功能数据是否不为空
      rightListLoading: true, //右侧列表数据加载状态
      exhibition: '' //关键字展示
    }
  }

  componentDidMount() {
    this.props.setType('qa')
    this.init()
    this.getData()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.keyword !== this.state.keyword) {
      this.setState(
        {
          keyword: nextProps.keyword,
          leftFnDataServer: {},
          leftFnDataServerArr: []
        },
        () => {
          this.init()
          this.getData()
        }
      )
    }
  }

  componentWillUnmount() {
    // 卸载异步操作设置状态
    this.setState = (state, callback) => {
      return
    }
  }

  init() {
    let svgNodes = document.querySelectorAll('body > svg')
    _.forEach(svgNodes, (node) => {
      if (node) node.remove()
    })
  }

  /**获取数据 */
  getData() {
    const _this = this
    let keyword = _this.state.keyword
    let hasLeftFnData = false

    _this.setState(
      {
        hasLeftFnData,
        rightListLoading: true,
        leftFnLoading: true
      },
      () => {
        webSdk.searchResultApi.queryParser({ keyword }).then((res) => {
          if (res && res.errCode && res.errCode != 0) {
            _this.setState({
              rightListLoading: false,
              leftFnLoading: false
            })
          } else {
            webSdk.searchResultApi
              .getLearnSourceTwin({ data: res })
              .then((res) => {
                let rightDataServer = res.art_array
                let dataListRes = res.data_list
                let leftFnDataServerArr = []

                _.forEach(dataListRes, (dataObj) => {
                  let exhibition = dataObj.keywords
                  let leftFnDataServer = {}
                  leftFnDataServer.set = dataObj.set || [] //集合数据
                  leftFnDataServer.attr = dataObj.attr || [] //属性数据
                  leftFnDataServer.desc = dataObj.desc || [] //描述数据
                  leftFnDataServer.dataType = dataObj.type || '' //数据类型
                  leftFnDataServer.displayKey = dataObj.display_key || ''

                  leftFnDataServerArr.push({
                    exhibition,
                    leftFnDataServer
                  })
                })

                _this.setState({
                  rightDataServer,
                  leftFnDataServerArr,
                  rightListLoading: false,
                  leftFnLoading: false
                })
              })
          }
        })
      }
    )
  }

  /**重新加载所有连线 */
  reloadLinkAll() {
    if (!_.isEmpty(this.state.rightDataServer)) {
      this.refs.rightMain.reloadLinkAll()
    }
  }

  render() {
    return (
      <div
        className={[
          'qa-module-index',
          this.state.keyword ? '' : 'no-keyword'
        ].join(' ')}
      >
        {this.state.keyword && (
          <div className="search-leftblock">
            <Spin spinning={this.state.leftFnLoading}>
              <div
                className={`search-leftblock-fn ${
                  this.state.leftFnLoading ? 'leftfn-loading' : ''
                }`}
              >
                {this.state.leftFnDataServerArr.map((item, idx) => {
                  return (
                    <LeftFn
                      key={idx}
                      keyword={this.state.keyword}
                      exhibition={item.exhibition}
                      dataServer={item.leftFnDataServer}
                      // fnReloadLinkAll={this.reloadLinkAll.bind(this)}
                      fnReloadLinkAll={() => {}}
                    />
                  )
                })}
              </div>
            </Spin>

            <div className="search-leftblock-list">
              <LeftList keyword={this.state.keyword} />
            </div>
          </div>
        )}

        <div className="search-rightblock">
          <RightList />
          {/* <Spin spinning={this.state.rightListLoading}>
            {!_.isEmpty(this.state.rightDataServer) ? (
              <RightList
                ref="rightMain"
                keyword={this.state.keyword}
                dataServer={this.state.rightDataServer}
              />
            ) : (
              <>
                {this.state.rightListLoading ? (
                  <div className="rightlist-loading"></div>
                ) : null}
              </>
            )}
          </Spin> */}
        </div>
      </div>
    )
  }
}

export default Index
