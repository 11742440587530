import React, { useState } from 'react'
import _ from 'lodash';
import { observer } from 'mobx-react'
import Store from "../../../store";

import './SmartSearchHome.scss'

function SmartSearchHome(props) {
    const [inputValue, setInputValue] = useState('');

    /** logo点击 */
    const logoClick = () => {
        props.history.push({
            pathname: '/'
        })
    };

    /** 文字改变 */
    const valueChange = e => {
        let value = e.target.value;
        setInputValue(value);
    };

    /** 查看回答点击事件 */
    const viewAnswer = () => {
        if (!inputValue) return;

        // console.log(inputValue)
        let questions = inputValue.split('\n')

        _.remove(questions, item => {
            return item === ""
        })

        let questionList = []
        _.forEach(questions, quesItem => {
            questionList.push({
                id: null,
                text: quesItem
            })
        })

        // 一个问题一个item
        Store.smartSearchStore.setQuestionList(questionList)
        props.history.push({
            pathname: "/smartsearch/example"
        })
    };

    return (
        <div className="smart-search-home">
            <div className="smart-search-logo" onClick={logoClick}>
                <div className="logo-img" />
                <div className="logo-text">企业信息库</div>
            </div>
            <div className="smart-search-main">
                <div className="search-area">
                    <h1 className="main-title">智能问答</h1>
                    <div className="main-input">
                        <textarea className="answer-input" placeholder='请输入一组问题或题纲' onChange={valueChange} />
                        <div className="input-button">
                            <span className="answer-button" onClick={viewAnswer}>查看回答</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default observer(SmartSearchHome)