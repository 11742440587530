import React from 'react'
import ReactEcharts from 'echarts-for-react'

/*柱形图+曲线图*/
class BarAndGraph extends React.Component {
  constructor(props) {
    super(props)

    /*const option = {
      legend: {
        bottom: 0,
        right: 0,
        data: ['补助金额', '占净利润比例']      //获取的数据
      },
      tooltip: {
        trigger: 'axis',
      },
      /!*X轴数据*!/
      xAxis: [
        {
          type: 'category',
          boundaryGap: true,
          data: ['中通快递','圆通快递','顺丰快递','EMS','韵达快递','申通快递','天天快递'],      //获取的数据
        }
      ],
      yAxis: [
        {
          type: 'value',
          scale: true,
          name: '补助金额(美元)',      //获取的数据
          splitNumber: 5,
          min: 0
        },
        {
          type: 'value',
          scale: true,
          name: '占净利润比例(%)',      //获取的数据
          max: 10 ,
          min: 0,
          splitNumber: 5,
        },
      ],
      /!*图像数据*!/
      series: [
        {
          name: '补助金额',      //获取的数据
          type: 'bar',        //类型柱形
          yAxisIndex: 0,
          itemStyle: {    //柱形样式
            color: "rgb(" + Math.round(Math.random() * 255) + "," + Math.round(Math.random() * 255) + ',' + Math.round(Math.random() * 10) + ')'
          },
          animationEasing: 'elasticOut',
          animationDelay(idx) {
            return idx * 10
          },
          animationDelayUpdate(idx) {
            return idx * 10
          },
          data: ['3000','2500','3520','1580','4420','890','630'],      //获取的数据
        },
        {
          name: '占净利润比例',      //获取的数据
          type: 'line',           //类型曲线
          yAxisIndex: 1,
          lineStyle: {
            color: "rgb(" + Math.round(Math.random() * 255) + "," + Math.round(Math.random() * 255) + ',' + Math.round(Math.random() * 10) + ')'
          },
          data: ['3.25','3.68','4.50','2.54','5.86','2.21','2.54'],      //获取的数据
        },
      ],
    }*/

    this.state = {
      options: props.options,
      minHeight: props.minHeight || 400
    }

    this.fetchNewDate = this.fetchNewDate.bind(this)
  }

  /**
   * 获取图像内的数据
   */
  fetchNewDate() {

  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      options: nextProps.options
    })
  }

  render() {
    return (
      <div className="graph-show" style={{minHeight: this.state.minHeight}}>
        {(JSON.stringify(this.state.options)!=='{}')?(
            <>
              <ReactEcharts
                  ref="echarts_react"
                  option={this.state.options}
                  style={{ height: this.state.minHeight }}
              />
            </>
        ):''}
      </div>
    )
  }
}

export default BarAndGraph
