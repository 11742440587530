import React from 'react'
import {webSdk} from "../../../../../../api/WebSdk";
import BaseRightMain from "./BaseRightMain";
import {Anchor, Spin} from 'antd';

const {Link} = Anchor;

class BaseIndex extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            leftMenu: [],           //右侧菜单
            currentLeftMenu: 'a',
            keyword: props.match.params.keyword || ''
        }
    }
    componentDidMount() {
        let postData = {
            currentCard: "base",       // ''||'baseInfo'公司基本面，'financialAnalysis'财务分析
            p: this.state.keyword
        }
        webSdk.search.getCompanyBase(postData).then(res => {
            this.setState({
                leftMenu: res.leftMenu
            })
        })
    }

    /**
     * 菜单点击事件
     */
    changeList(e, link) {
        e.preventDefault();
    }

    render() {
        return (
            <div key={this.props.location.key}>
                <div className="left-menu">
                    {
                        !this.state.leftMenu || this.state.leftMenu.length === 0 ? (<Spin size="large"/>) : ''
                    }
                    <div className="left-menu__ul">
                        <Anchor affix={false} onClick={this.changeList} offsetTop={123}>
                            {this.state.leftMenu.map((item, index) => {
                                return (
                                    <Link key={index} href={'#menu_' + item.key} title={item.label}
                                          className="menu-item"/>
                                )
                            })}
                        </Anchor>
                    </div>
                </div>
                <BaseRightMain leftMenu={this.state.leftMenu} currentLeftMenu={this.state.currentLeftMenu} keyword={this.state.keyword}/> />
            </div>
        )
    }
}

export default BaseIndex