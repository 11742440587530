var inBrowser = typeof window !== 'undefined' && Object.prototype.toString.call(window) !== '[object Object]';
var UA = inBrowser && window.navigator.userAgent.toLowerCase() || '';
var isWx = /micromessenger/.test(UA),
    isWxPC = /windowswechat/.test(UA),
    isWxWork = /wework/.test(UA);
export default {
  weixin: isWx && !isWxPC && !isWxWork,
  wxPC: isWx && isWxPC,
  wxWork: isWx && isWxWork,
  inBrowser: inBrowser,
  IE: UA.indexOf('trident') > 0,
  IE9: UA.indexOf('msie 9.0') > 0,
  android: UA.indexOf('android') > -1 || UA.indexOf('adr') > -1,
  ios: /iphone|ipad/.test(UA)
};