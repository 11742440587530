import React, { PureComponent } from 'react';
import _ from 'lodash'
import { DatePicker, Pagination, Empty, Spin } from 'antd';
import moment from "moment"
import AppContext from '../../../AppContext'
import "./index.scss"
import { webSdk } from '../../../api/WebSdk';

class EntersInfo extends PureComponent {
    static contextType = AppContext

    constructor(props) {
        super(props);
        this.state = {
            keyword: this.props.match.params.keyword ? this.props.match.params.keyword.replace("企业画像", "") : "",
            enterinfoParamsStorKey: "enterinfoPageParams",
            listSpinning: true,//列表加载状态
            vocationSpinning: true,//行业加载状态
            filterVocation: {//行业筛选
                value: [
                    // {
                    //     name: "地产",
                    //     key: "地产",
                    // },
                ],
                isExpand: true,//是否展开面板
                isExpand4List: false,//是否展开列表
                list: [
                    // {
                    //     name: "地产",
                    //     key: "地产",
                    //     active: true,//是否选择
                    // },
                    // {//更多标签
                    //     type: "more",
                    //     width: "68px",
                    //     marginRight: "30px",
                    // },
                ]
            },
            filterDate: [],//日期筛选
            filterProvince: {//省份筛选
                value: [
                    // {
                    //     name: "地产",
                    //     key: "地产",
                    // },
                ],
                isExpand: true,//是否展开面板
                list: [
                    {
                        name: "北京",
                        key: "北京",
                        active: false
                    },
                    {
                        name: "天津",
                        key: "天津",
                        active: false
                    },
                    {
                        name: "上海",
                        key: "上海",
                        active: false
                    },
                    {
                        name: "重庆",
                        key: "重庆",
                        active: false
                    },
                    {
                        name: "河北",
                        key: "河北",
                        active: false
                    },
                    {
                        name: "山西",
                        key: "山西",
                        active: false
                    },
                    {
                        name: "辽宁",
                        key: "辽宁",
                        active: false
                    },
                    {
                        name: "吉林",
                        key: "吉林",
                        active: false
                    },
                    {
                        name: "黑龙江",
                        key: "黑龙江",
                        active: false
                    },
                    {
                        name: "江苏",
                        key: "江苏",
                        active: false
                    },
                    {
                        name: "浙江",
                        key: "浙江",
                        active: false
                    },
                    {
                        name: "安徽",
                        key: "安徽",
                        active: false
                    },
                    {
                        name: "福建",
                        key: "福建",
                        active: false
                    },
                    {
                        name: "江西",
                        key: "江西",
                        active: false
                    },
                    {
                        name: "山东",
                        key: "山东",
                        active: false
                    },
                    {
                        name: "河南",
                        key: "河南",
                        active: false
                    },
                    {
                        name: "湖北",
                        key: "湖北",
                        active: false
                    },
                    {
                        name: "湖南",
                        key: "湖南",
                        active: false
                    },
                    {
                        name: "广东",
                        key: "广东",
                        active: false
                    },
                    {
                        name: "海南",
                        key: "海南",
                        active: false
                    },
                    {
                        name: "四川",
                        key: "四川",
                        active: false
                    },
                    {
                        name: "贵州",
                        key: "贵州",
                        active: false
                    },
                    {
                        name: "云南",
                        key: "云南",
                        active: false
                    },
                    {
                        name: "陕西",
                        key: "陕西",
                        active: false
                    },
                    {
                        name: "甘肃",
                        key: "甘肃",
                        active: false
                    },
                    {
                        name: "青海",
                        key: "青海",
                        active: false
                    },
                    {
                        name: "台湾",
                        key: "台湾",
                        active: false
                    },
                    {
                        name: "内蒙古",
                        key: "内蒙古",
                        active: false
                    },
                    {
                        name: "广西",
                        key: "广西",
                        active: false
                    },
                    {
                        name: "西藏",
                        key: "西藏",
                        active: false
                    },
                    {
                        name: "宁夏",
                        key: "宁夏",
                        active: false
                    },
                    {
                        name: "新疆",
                        key: "新疆",
                        active: false
                    },
                    {
                        name: "香港",
                        key: "香港",
                        active: false
                    },
                    {
                        name: "澳门",
                        key: "澳门",
                        active: false
                    }

                ]
            },
            enterList: [//企业信息
                // {
                //     img: "",//公司头像
                //     name: "",//公司名
                //     status: "",//公司状态
                //     registerCaptial: "",//注册资本
                //     registerTime: "",//注册时间
                //     tickerSymbol: "",//股票代码
                //     currentMark: "",//当日的评分
                //     currentMarkLevel: "",//当日评分的等级
                // }
            ],
            pagination: {
                currentPage: 1,
                dataTotal: 0,
                pageSize: 10
            }
        }
    }

    componentDidMount() {
        this.init()
    }

    componentWillReceiveProps(nextProps) {
        let newKeyword = nextProps.match.params.keyword ? nextProps.match.params.keyword.replace("企业画像", "") : ""

        if (newKeyword != this.state.keyword) {
            this.setState({
                keyword: newKeyword
            }, () => {
                this.getEnterData()
            })
        }
    }

    componentWillUnmount() {
        //企业画像之间跳转不需要清空localStorage( entersinfo enterdetail)
        let nextPath = this.context.getHistory().location.pathname
        if (nextPath.indexOf("/entersinfo") == -1 && nextPath.indexOf("/enterdetail") == -1) {
            this.setLocStorage(this.state.enterinfoParamsStorKey, "")
        }
        this.setState = () => {
            return;
        }
    }

    /** 在localStorage中保存值
     * @param {string} key 保存的键名
     * @param {object} obj 保存的对象
     */
    setLocStorage(key, obj) {
        let objJSON = ""
        if (!_.isEmpty(obj))
            objJSON = JSON.stringify(obj)
        window.localStorage.setItem(key, objJSON)
    }

    /** 从localStorage中获取值
     * @param {string} key 键名
     */
    getLocStorage(key) {
        let objStr = window.localStorage.getItem(key)
        if (!objStr) return null;
        return JSON.parse(objStr)
    }

    /**初始化页面 */
    init() {

        let pageParams = this.getLocStorage(this.state.enterinfoParamsStorKey)
        // let pageParams = {//页面参数
        //     filterVocationValue: this.state.filterVocation.value,
        //     filterDate: this.state.filterDate,
        //     filterProvinceValue: this.state.filterProvince.value,
        //     keyword: this.state.keyword,
        //     currentPage: this.state.pagination.currentPage,
        //     pageType: "",
        //     tickerSymbol: ""
        // }
        if (pageParams != null) {
            let keyword = pageParams.keyword
            if (keyword) {
                pageParams.keyword = ""
                this.setLocStorage(this.state.enterinfoParamsStorKey, pageParams)
                this.context.getHistory().push({
                    pathname: `/entersinfo/${keyword} 企业画像`
                })
            }
            let filterVocation = { ...this.state.filterVocation }
            filterVocation.value = pageParams.filterVocationValue
            let filterDate = pageParams.filterDate
            let filterProvince = { ...this.state.filterProvince }
            filterProvince.value = pageParams.filterProvinceValue
            let pagination = { ...this.state.pagination }
            pagination.currentPage = pageParams.currentPage

            this.setState({
                filterVocation, filterDate, filterProvince, pagination
            }, () => {
                this.getEnterData()
                this.getFilterVocationData()
                this.initFilterProvince()
            })
        } else {
            this.getEnterData()
            this.getFilterVocationData()
        }
    }

    /**获取企业数据 */
    getEnterData() {
        let pagination = { ...this.state.pagination }
        let filterVocation = this.state.filterVocation
        let filterVocationList = filterVocation.value
        let filterDate = this.state.filterDate
        let filterProvince = this.state.filterProvince
        let filterProvinceList = filterProvince.value
        let keyword = this.state.keyword

        this.setState({
            listSpinning: true,
            enterList: []
        }, () => {

            //过滤行业
            let vocation = []
            _.forEach(filterVocationList, item => {
                vocation.push(item.name)
            })

            //过滤地区
            let province = []
            _.forEach(filterProvinceList, item => {
                province.push(item.name)
            })

            let params = {
                vocation: !_.isEmpty(vocation) ? _.join(vocation, ",") : "",
                filterDate: !_.isEmpty(filterDate) ? _.join(filterDate, ",") : "",
                province: !_.isEmpty(province) ? _.join(province, ",") : "",
                keyword,
                page: pagination.currentPage
            }

            webSdk.entersInforApi.getEnterpriseList(params).then(entersRes => {

                let orginAllEnters = entersRes.data
                let allEnters = []
                _.forEach(orginAllEnters, (item, idx) => {
                    allEnters.push({
                        img: "",
                        name: item.orgname,
                        status: "",
                        registerCaptial: item.regmoney,
                        registerTime: item.regdate,
                        tickerSymbol: item.seccode,
                        currentMark: "中性"
                    })
                })


                // webSdk.entersInforApi.getEnterpriseListMock(params).then(res => {

                // let enterList = res.data;
                // enterList = _.concat(enterList, allEnters)
                let enterList = allEnters
                pagination.dataTotal = entersRes.total

                //调整数据
                _.forEach(enterList, item => {

                    //企业状态默认为 存续...
                    if (!item.status) {
                        item.status = "存续(在营 开业 在册 )"
                    }

                    //注册资本、注册时间、股票代码、评分默认为 -
                    if (!item.registerCaptial) {
                        item.registerCaptial = "-"
                    }
                    if (!item.registerTime) {
                        item.registerTime = "-"
                    }
                    if (!item.tickerSymbol) {
                        item.tickerSymbol = "-"
                    }
                    if (!item.currentMark) {
                        item.currentMark = "-"
                    }

                    //设置评分等级
                    if (item.currentMark) {
                        switch (item.currentMark) {
                            case "重大负面":
                                item.currentMarkLevel = "level1"
                                break;
                            case "一般负面":
                                item.currentMarkLevel = "level2"
                                break;
                            case "中性":
                                item.currentMarkLevel = "level3"
                                break;
                            case "一般正面":
                                item.currentMarkLevel = "level4"
                                break;
                            case "重大正面":
                                item.currentMarkLevel = "level5"
                                break;
                        }
                    }
                })

                this.setState({
                    enterList, pagination, listSpinning: false
                })
                // })
            })
        })
    }

    /**获取行业标签数据 */
    getFilterVocationData() {
        let filterVocation = { ...this.state.filterVocation };
        let filterVocationValue = filterVocation.value

        this.setState({
            vocationSpinning: true
        }, () => {
            webSdk.entersInforApi.getAllIndustry().then(res => {
                let data = res
                _.forEach(data, item => {
                    let active = false
                    if (!_.isEmpty(filterVocationValue)) {
                        let valueItem = _.find(filterVocationValue, { name: item })
                        if (!_.isEmpty(valueItem)) {
                            active = true
                        }
                    }
                    filterVocation.list.push({
                        name: item,
                        key: item,
                        active
                    })
                })

                this.setState({
                    filterVocation, vocationSpinning: false
                }, () => {
                    this.initFilterVocation()
                })
            })
        })
    }

    /**初始化行业过滤条件
     * 当行业有数据，且行业标签未展开
     * 循环找出第一个标签的top值 > list高度
     * 在该标签的前面插入more数据
     */
    initFilterVocation() {
        let filterVocation = { ...this.state.filterVocation };
        let labelList = document.getElementById("labelList");
        let labelListHeight = labelList.clientHeight;//list的高度
        let labelListWidth = labelList.clientWidth;//list的宽度
        let moreOffsetLeft = 0;//more标签的左侧距离
        let moreWidth = 68;//more标签的宽度

        //当行业有数据，且行业标签未展开的情况下
        if (labelList && !filterVocation.isExpand4List) {
            let labelItems = labelList.querySelectorAll("span")

            //循环查找第一个标签的top > 标签列表的高度
            let firstItemTop = 0;//存在高度差，需要知道第一个标签的top值
            let moreIdx = 0
            _.forEach(labelItems, (item, idx) => {
                if (firstItemTop === 0) {
                    firstItemTop = item.offsetTop
                }

                let offsetTop = item.offsetTop//当前标签的top值
                if (offsetTop - firstItemTop > labelListHeight) {
                    moreIdx = idx - 1
                    moreOffsetLeft = labelItems[idx - 1].offsetLeft
                    return false;
                }
            })

            //存在该标签，则在该标签的前面插入more数据
            if (moreIdx > 0) {
                let vocationList = filterVocation.list;
                let first = _.slice(vocationList, 0, moreIdx)
                let last = _.slice(vocationList, moreIdx, vocationList.length)

                first.push({
                    type: "more",
                    width: `${moreWidth}px`,
                    marginRight: `${labelListWidth - moreOffsetLeft - moreWidth}px`,
                })
                let newVocationList = _.concat(first, last)
                filterVocation.list = newVocationList
                this.setState({
                    filterVocation
                })
            }
        }
    }

    /**触发条件筛选面板伸缩 */
    triggerFilterExpand(filterName) {
        let filter = { ...this.state[filterName] }
        filter.isExpand = !filter.isExpand

        if (filterName === 'filterVocation') {
            this.setState({
                filterVocation: filter
            })
        } else if (filterName === "filterProvince") {
            this.setState({
                filterProvince: filter
            })
        }
    }

    /**选择行业条件 */
    chooseFilterVocation(vocLableIdx) {
        let filterVocation = { ...this.state.filterVocation }
        let vocationList = filterVocation.list
        let pagination = { ...this.state.pagination }
        pagination.currentPage = 1
        let value = []
        vocationList[vocLableIdx].active = !vocationList[vocLableIdx].active

        _.forEach(vocationList, (item, idx) => {
            if ((!item.type || item.type !== "more") && item.active) {
                value.push(item)
            }
        })
        filterVocation.value = value

        this.setState({
            filterVocation, pagination
        }, () => {
            this.getEnterData()

            //保存页面参数到localStorage
            this.savePageParams2Storage()
        })
    }

    /**加载更多行业条件 */
    loadFilterVocation(vocLable) {
        let filterVocation = { ...this.state.filterVocation }
        let vocationList = filterVocation.list
        _.remove(vocationList, vocLable)
        filterVocation.isExpand4List = true

        this.setState({
            filterVocation
        })
    }

    /**改变日期选择 */
    changeDataPicker(dataMoment, dataStr) {
        let pagination = { ...this.state.pagination }
        if (!dataStr[0]) {
            dataStr = []
        } else {
            pagination.currentPage = 1
        }
        this.setState({
            filterDate: dataStr,
            pagination
        }, () => {
            this.getEnterData()

            //保存页面参数到localStorage
            this.savePageParams2Storage()
        })
    }

    /**初始化地区过滤条件 */
    initFilterProvince() {
        let filterProvince = { ...this.state.filterProvince }
        let provinceList = filterProvince.list
        let provinceValue = filterProvince.value

        if (_.isEmpty(provinceValue)) return;

        _.forEach(provinceList, item => {
            let valueItem = _.find(provinceValue, { name: item.name })
            if (!_.isEmpty(valueItem)) {
                item.active = true
            }
        })
    }

    /**选择地区条件 */
    chooseFilterProvince(vocLableIdx) {
        let filterProvince = { ...this.state.filterProvince }
        let provinceList = filterProvince.list
        let pagination = { ...this.state.pagination }
        pagination.currentPage = 1
        let value = []
        provinceList[vocLableIdx].active = !provinceList[vocLableIdx].active

        _.forEach(provinceList, (item, idx) => {
            if (item.active) {
                value.push(item)
            }
        })
        filterProvince.value = value

        this.setState({
            filterProvince, pagination
        }, () => {
            this.getEnterData()

            //保存页面参数到localStorage
            this.savePageParams2Storage()
        })
    }

    /**跳转到企业详细信息页面 */
    toEnterInfoPage(enter) {
        let pageType = "enterHistory"
        let tickerSymbol = enter.tickerSymbol
        let currentYear = moment().format("YYYY")
        let companyName = enter.name

        //保存页面参数到localStorage
        this.savePageParams2Storage(pageType, tickerSymbol, currentYear, companyName)

        this.context.getHistory().push({
            pathname: `/enterdetail`,
        })
    }

    /**保存页面参数到localStorage */
    savePageParams2Storage(pageType, tickerSymbol, currentYear, companyName) {
        //保存页面参数到localStorage中
        let pageParams = {//页面参数
            filterVocationValue: this.state.filterVocation.value,//行业筛选
            filterDate: this.state.filterDate,//时间筛选
            filterProvinceValue: this.state.filterProvince.value,//省份筛选
            keyword: _.trim(this.state.keyword),//搜索框 关键字
            currentPage: this.state.pagination.currentPage,//第几页
            pageType: pageType || "",//当前的页面类型
            tickerSymbol: tickerSymbol || "",//当前公司的股票代码
            companyName: companyName || "",//当前公司名称
            currentYear: currentYear || ""//当前的年份
        }

        this.setLocStorage(this.state.enterinfoParamsStorKey, pageParams)
    }

    /**改变页码 */
    changePage(pageNum) {
        let pagination = { ...this.state.pagination }
        pagination.currentPage = pageNum

        this.setState({
            pagination
        }, () => {
            this.getEnterData()

            //保存页面参数到localStorage
            this.savePageParams2Storage()
        })
    }

    render() {
        return (
            <div className="entersinfo">
                <div className="entersinfo-left">
                    <div
                        className={`entersinfo-left-vocation ${this.state.filterVocation.isExpand ? "" : "unexpand"}`}
                    >
                        <Spin spinning={this.state.vocationSpinning}>
                            <div className="left-vocation-title">
                                <h3>行业</h3>
                                <span
                                    className={`left-vocation-title__icon font_family ${this.state.filterVocation.isExpand ? "icon-expand" : "icon-Group"}`}
                                    onClick={this.triggerFilterExpand.bind(this, "filterVocation")}
                                ></span>
                            </div>
                            {
                                this.state.filterVocation.isExpand ? (
                                    <div id="labelList" className={`left-vocation-labellist ${this.state.filterVocation.isExpand4List ? "expandlist" : ""}`}>
                                        {
                                            this.state.filterVocation.list.map((vocLable, vocLableIdx) => {
                                                if (vocLable.type === "more") {
                                                    return (
                                                        <span
                                                            key={vocLableIdx}
                                                            className="left-vocation-labelitem iconfont iconellipsis2"
                                                            style={{
                                                                color: "#D8D8D8",
                                                                width: vocLable.width,
                                                                marginRight: vocLable.marginRight
                                                            }}
                                                            onClick={this.loadFilterVocation.bind(this, vocLable)}
                                                        ></span>
                                                    )
                                                } else {
                                                    return (
                                                        <span
                                                            key={vocLableIdx}
                                                            className={`left-vocation-labelitem ${vocLable.active ? 'active' : ''}`}
                                                            onClick={this.chooseFilterVocation.bind(this, vocLableIdx)}
                                                        >{vocLable.name}</span>
                                                    )
                                                }

                                            })
                                        }
                                    </div>
                                ) : null
                            }
                        </Spin>
                    </div>

                    <div className="entersinfo-left-createtime">
                        <div className="left-createtime-title">
                            <h3>成立时间</h3>
                        </div>
                        <DatePicker.RangePicker
                            value={!_.isEmpty(this.state.filterDate) ? [moment(this.state.filterDate[0], "YYYY/MM/DD"), moment(this.state.filterDate[1], "YYYY/MM/DD")] : null}
                            className="left-createtime-date"
                            format="YYYY/MM/DD"
                            suffixIcon={<span className="left-createtime-date__icon iconfont_mei iconcalendar"></span>}
                            onChange={this.changeDataPicker.bind(this)}
                        />
                    </div>

                    <div
                        className={`entersinfo-left-province ${this.state.filterProvince.isExpand ? "" : "unexpand"}`}
                    >
                        <div className="left-province-title">
                            <h3>所属地区</h3>
                            <span
                                className={`left-province-title__icon font_family ${this.state.filterProvince.isExpand ? "icon-expand" : "icon-Group"}`}
                                onClick={this.triggerFilterExpand.bind(this, "filterProvince")}
                            ></span>
                        </div>
                        {
                            this.state.filterProvince.isExpand ? (
                                <div className="left-province-labellist">
                                    {
                                        this.state.filterProvince.list.map((vocLable, vocLableIdx) => {
                                            return (
                                                <span
                                                    key={vocLableIdx}
                                                    className={`left-province-labelitem ${vocLable.active ? 'active' : ''}`}
                                                    onClick={this.chooseFilterProvince.bind(this, vocLableIdx)}
                                                >
                                                    <span className="iconfont_mei iconsite"></span>
                                                    {vocLable.name}
                                                </span>
                                            )

                                        })
                                    }
                                </div>
                            ) : null
                        }
                    </div>
                </div>
                <div className="entersinfo-right">
                    <Spin spinning={this.state.listSpinning}>
                        <div className="entersinfo-content">
                            <div className="entersinfo-right-content">
                                {
                                    _.isEmpty(this.state.enterList) ? (
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                    ) :
                                        this.state.enterList.map((item, idx) => {
                                            return (
                                                <div
                                                    key={idx}
                                                    className="right-enter"
                                                    onClick={this.toEnterInfoPage.bind(this, item)}
                                                >
                                                    <div className="right-enter-img">
                                                        {
                                                            item.img ? (
                                                                <img src={item.img} />
                                                            ) : (
                                                                    <span className="right-enter-img__icon iconfont_mei iconcpinfo"></span>
                                                                )
                                                        }

                                                    </div>
                                                    <div className="right-enter-info">
                                                        <h3>{item.name}
                                                            <span>{item.status}</span>
                                                        </h3>
                                                        <table className="right-enterinfo-tb">
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <span className="right-enterinfo-tb__title">注册资本：</span>
                                                                        <span className="right-enterinfo-tb__content">{item.registerCaptial}</span>
                                                                    </td>
                                                                    <td>
                                                                        <span className="right-enterinfo-tb__title">注册时间：</span>
                                                                        <span className="right-enterinfo-tb__content">{item.registerTime}</span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <span className="right-enterinfo-tb__title">股票代码：</span>
                                                                        <span className="right-enterinfo-tb__content">{item.tickerSymbol}</span>
                                                                    </td>
                                                                    <td>
                                                                        <span className="right-enterinfo-tb__title">当日舆情评分：</span>
                                                                        <span className={`right-enterinfo-tb__content ${item.currentMarkLevel}`}>{item.currentMark}</span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            )
                                        })
                                }
                            </div>
                            <div className="entersinfo-right-pagination">
                                <Pagination
                                    className="right-pagination-elem"
                                    showQuickJumper={true}
                                    current={this.state.pagination.currentPage}
                                    total={this.state.pagination.dataTotal}
                                    pageSize={this.state.pagination.pageSize}
                                    onChange={this.changePage.bind(this)}
                                />
                            </div>
                        </div>
                    </Spin>
                </div>
            </div>
        );
    }
}

export default EntersInfo;