import d3 from './setD3';

var assign = Object.assign || function(dst, src) {
    // poor man's Object.assign()
    for (var k in src) {
        if (src.hasOwnProperty(k)) {
            dst[k] = src[k];
        }
    }
    return dst;
};

function getTextWidth(text, font) {
    // re-use canvas object for better performance
    var canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
    var context = canvas.getContext("2d");
    context.font = font;
    var metrics = context.measureText(text);
    return metrics.width;
}

function traverseBranchId(node, branch, state) {
    if (!("branch" in node)) {
        node.branch = branch;
    }
    if (node.children) {
        node.children.forEach(function(d) {
            traverseBranchId(d, branch, state);
        });
    }
}

function traverseDummyNodes(node) {
    if (node.children) {
        node.children.forEach(traverseDummyNodes);

        node.children = [{
            name: '',
            dummy: true,
            children: node.children
        }];
    }
}

function traverseTruncateLabels(node, length) {
    if (node.name.length > length) {
        node.name = node.name.slice(0, length - 1) + '\u2026';
    }
    if (node.children) {
        node.children.forEach(function(n) {
            traverseTruncateLabels(n, length);
        });
    }
}

function Markmap(svg, data, options , nodeClick , zoomDrag) {
    if (!(this instanceof Markmap)) return new Markmap(svg, data, options , nodeClick , zoomDrag);
    this.init(svg, data, options , nodeClick , zoomDrag);
}

var defaultPreset = {
    nodeHeight: 20,
    nodeWidth: 180,
    nodePadding: 12,
    spacingVertical: 5,
    spacingHorizontal: 60,
    truncateLabels: 0,
    duration: 750,
    layout: 'tree',
    color: 'gray',
    linkShape: 'diagonal',
    renderer: 'boxed'
};

assign(Markmap.prototype, {
    getInitialState: function() {
        return {
            zoomScale: 1,
            zoomTranslate: [0, 0],
            autoFit: true,
            depthMaxSize: {},
            yByDepth: {},
            nodeFont: '10px sans-serif'
        };
    },
    presets: {
        'default': defaultPreset,
        'colorful': assign(assign({}, defaultPreset), {
            nodeHeight: 10,
            renderer: 'basic',
            color: 'category20',
            nodePadding: 6
        })
    },
    helperNames: ['layout', 'linkShape', 'color'],
    layouts: {
        tree: function(self) {
            return d3.layout.flextree()
                .setNodeSizes(true)
                .nodeSize(function(d) {
                    // 设置节点宽度，通过keyLabel字段长度
                    var width = d.dummy ? self.state.spacingHorizontal : getTextWidth(d.label, self.state.nodeFont);
                    if (!d.dummy && width > 0) {
                        // Add padding non-empty nodes
                        width += 2 * self.state.nodePadding;
                    }
                    return [self.state.nodeHeight, width];
                })
                .spacing(function(a, b) {
                    return a.parent === b.parent ? self.state.spacingVertical : self.state.spacingVertical*2;
                })
        }
    },
    linkShapes: {
        diagonal: function() {
            return d3.svg.diagonal()
                .projection(function(d) { return [d.y, d.x]; });
        },
        bracket: function() {
            return function(d) {
                return "M" + d.source.y + "," + d.source.x
                    + "V" + d.target.x + "H" + d.target.y;
            };
        }
    },
    colors: assign(
        {gray: function() {return function() {return '#929292';}}},
        d3.scale
    ),
    clickFn: null,
    hoverBox: null,     // 鼠标移入节点是否显示tips
    /** 鼠标移入显示hover */
    showHover: function(d) {
        if (!this.hoverBox) {
            this.hoverBox = d3.select('#mind-map-content')
                .append('div')
                .attr('class', 'd3-force-hover')
        }

        let x = d3.event.x;
        let y = d3.event.y;
        this.hoverBox.style('top', y - 80 + 'px')
            .style('left', x + 'px')
            .style('display', 'block')
            .html(`${d.keyLabel}：${d.label}`)
    },
    init: function(svg, data, options , nodeClick , zoomDrag) {
        options = options || {};
        this.clickFn = nodeClick;   //添加点击事件，用于添加节点到列表

        svg = svg.datum ? svg : d3.select(svg);

        this.helpers = {};
        this.i = 0;
        var state = this.state = this.getInitialState();
        this.set(this.presets[options.preset || 'default']);
        state.height = svg.node().getBoundingClientRect().height;
        state.width = svg.node().getBoundingClientRect().width;
        this.set(options);

        // disable panning using right mouse button
        svg.on("mousedown", function() {
            var ev = d3.event;
            if (ev.button === 2) {
                console.log('ev.button === 2')
                ev.stopImmediatePropagation();
            }
        });

        var zoom = this.zoom = d3.behavior.zoom()
            .on("zoom", function() {
                this.updateZoom(d3.event.translate, d3.event.scale);
                //zoomDrag();
            }.bind(this));

        var drag = d3.behavior.drag()
            .on("drag", function() {
                //zoomDrag();
            }.bind(this));


        this.svg = svg
            .call(zoom)
            .append("g");

        this.svg = svg
            .call(drag)
            .append("g");

        this.updateZoom(state.zoomTranslate, state.zoomScale);

        this.setData(data);
        this.update(state.root);

        if (options.autoFit === undefined || options.autoFit === null) {
            state.autoFit = false;
        }
    },
    updateZoom: function(translate, scale) {
        var state = this.state;
        state.zoomTranslate = translate;
        state.zoomScale = scale;
        this.zoom.translate(state.zoomTranslate)
            .scale(state.zoomScale);
        this.svg.attr("transform", "translate(" + state.zoomTranslate + ")" + " scale(" + state.zoomScale + ")")
    },
    set: function(values) {
        if (values.preset) {
            this.set(this.presets[values.preset]);
        }
        var state = this.state;
        var helpers = this.helpers;
        this.helperNames.forEach(function(h) {
            if (!helpers[h] || (values[h] && values[h] !== state[h])) {
                helpers[h] = this[h+'s'][values[h] || state[h]](this);
            }
        }.bind(this));
        assign(state, values || {});
        return this;
    },
    preprocessData(data, prev) {
        var state = this.state;

        if (state.truncateLabels) {
            traverseTruncateLabels(data, state.truncateLabels);
        }

        if (data.children) {
            data.children.forEach(function(d, i) {
                traverseBranchId(d, i, state);
            });
        }

        if (prev) {
            this.diffTreeState(data, prev);
        }
    },
    setData: function(data) {
        var state = this.state;

        this.preprocessData(data, state.root);

        state.root = data;
        state.root.x0 = state.height / 2;
        state.root.y0 = 0;

        return this;
    },
    diffTreeState: function(next, prev) {
        var childrenNext = next.children;
        var childrenPrev = prev.children || prev._children;

        if (childrenNext && childrenPrev) {
            // if number of children is different (nodes we likely added or removed) we create a name based index
            // else we use position based comparison as nodes were likely just renamed
            var idx;
            if (childrenNext.length !== childrenPrev.length) {
                idx = childrenPrev.reduce(function(res, node) {
                    res[node.name] = res[node.name] || [];
                    res[node.name].push(node);
                    return res;
                }, {});
            }

            for (var k = 0; k < childrenNext.length; k += 1) {
                var child;
                if (idx) {
                    var nodes = idx[childrenNext[k].name];
                    if (nodes) {
                        child = nodes[0];
                        idx[childrenNext[k].name] = nodes.slice(1);
                    }
                } else {
                    child = childrenPrev[k];
                }

                if (child) {
                    this.diffTreeState(childrenNext[k], child);
                }
            }

            if (prev._children) {
                next._children = next.children;
                delete next.children;
            }
        }

        return next;
    },
    update: function(source) {
        //console.log('mapJS.click',source)
        var state = this.state;
        //state.autoFit = true;
        source = source || state.root;
        var res = this.layout(state);
        /*if (state.autoFit) {          // 最初始设置
            var minX = d3.min(res.nodes, function(d) {return d.x;});
            var minY = d3.min(res.nodes, function(d) {return d.y;});
            var maxX = d3.max(res.nodes, function(d) {return d.x;});
            var maxY = d3.max(res.nodes, function(d) {return d.y + d.y_size;});
            var realHeight = maxX - minX;
            var realWidth = maxY - minY;
            //var scale = Math.min(state.height / realHeight, state.width / realWidth, 1);
            /!*var scale = 1;
            var translate = [
                (state.width-realWidth*scale)/2-minY*scale,
                (state.height-realHeight*scale)/2-minX*scale
            ];*!/
        }*/
        this.render(source, res.nodes, res.links);

        /*点击节点，节点保持原始位置*/
        if (source.x && source.y) {
            let area = document.getElementById('mindmap');
            // let oldBox = area.getBBox();            //获取g的宽高属性(width，height，x，y)
            // let oldWidth = oldBox.width,
            //     oldHeight = oldBox.height;
            // console.log('原来的宽高:',oldWidth,oldHeight)
            // 获取更新前的图形位移
            let areaG = area.childNodes[1];
            let oldAttr = areaG.attributes[0].value;    //'translate(361,309.5) scale(1)'
            let oldTranslate = /(.+)?(?:\()(.+)(?=\))/.exec(oldAttr.split(' ')[0])[2];
            let scale = /(.+)?(?:\()(.+)(?=\))/.exec(oldAttr.split(' ')[1])[2];
            // console.log('原来的位移和缩放：', oldTranslate , scale);
            // 更新前的位移
            let oldTranslateA = parseFloat(oldTranslate.split(',')[0]),
                oldTranslateB = parseFloat(oldTranslate.split(',')[1]);

            let w = oldTranslateA + (source.oldY - source.y)*scale,
                h = oldTranslateB + (source.oldX - source.x)*scale;
            // 设置更新后的位移
            this.updateZoom([w,h], scale);
            for (let i=0;i<res.nodes.length;i++) {
                let node = res.nodes[i];
                node.oldX = node.x;
                node.oldY = node.y;
            }

        }
        else {
            var minX = d3.min(res.nodes, function(d) {return d.x;});
            var minY = d3.min(res.nodes, function(d) {return d.y;});
            var maxX = d3.max(res.nodes, function(d) {return d.x;});
            var maxY = d3.max(res.nodes, function(d) {return d.y + d.y_size;});
            var realHeight = maxX - minX;
            var realWidth = maxY - minY;
            var scale = Math.min(state.height / realHeight, state.width / realWidth, 1);
            var translate = [
                (state.width-realWidth*scale)/2-minY*scale,
                (state.height-realHeight*scale)/2-minX*scale
            ];
            this.updateZoom(translate, scale);
        }
        return this;
    },
    layout: function(state) {
        var layout = this.helpers.layout;

        if (state.linkShape !== 'bracket') {
            // Fill in with dummy nodes to handle spacing for layout algorithm
            traverseDummyNodes(state.root);
        }

        // Compute the new tree layout.
        var nodes = layout.nodes(state.root).reverse();

        // Remove dummy nodes after layout is computed
        nodes = nodes.filter(function(n) { return !n.dummy; });
        nodes.forEach(function(n) {
            if (n.children && n.children.length === 1 && n.children[0].dummy) {
                n.children = n.children[0].children;
            }
            if (n.parent && n.parent.dummy) {
                n.parent = n.parent.parent;
            }
        });

        if (state.linkShape === 'bracket') {
            nodes.forEach(function(n) {
                n.y += n.depth * state.spacingHorizontal;
            });
        }

        for (let i=nodes.length-1;i>-1;i--) {
            if (nodes[i].name === 0) {
                nodes.splice(i,1);
                break;
            }
        }
        //nodes.splice(nodes.length-1,1)
        var links = layout.links(nodes);

       /* let newLinks = [];
        for (let i=0;i<nodes.length;i++) {
            if (nodes[i].father && nodes[i].father.length>0) {
                let target = nodes[i];
                for (let j=0;j<nodes[i].father.length;j++) {
                    let fatherName = nodes[i].father[j];
                    for (let k=0;k<nodes.length;k++) {
                        if (nodes[k].name === fatherName) {
                            newLinks.push({
                                source: nodes[k],
                                target: target
                            })
                        }
                    }
                }
            }
            if (nodes[i].son && nodes[i].son.length>0) {
                let source = nodes[i];
                for (let j=0;j<nodes[i].son.length;j++) {
                    let fatherName = nodes[i].son[j];
                    for (let k=0;k<nodes.length;k++) {
                        if (nodes[k].name === fatherName) {
                            newLinks.push({
                                source: source,
                                target: nodes[k]
                            })
                        }
                    }
                }
            }
        }

        links = links.concat(newLinks);*/

        return {
            nodes: nodes,
            links: links
        };
    },
    render: function(source, nodes, links) {
        this.renderers[this.state.renderer].call(this, source, nodes, links);
    },
    renderers: {
        boxed: function(source, nodes, links) {
            var svg = this.svg;
            var state = this.state;
            var color = this.helpers.color;
            this.renderers.basic.call(this, source, nodes, links);
            var node = svg.selectAll("g.markmap-node");

            node.attr('class',d=>{
                let name = "markmap-node";
                if (d.artIds) {
                    name += ' ' + d.artIds.join(' ')
                }
                return name
            })
            node.select('rect')
                .attr("y", -state.nodeHeight/2)
                .attr('rx', 10)
                .attr('ry', 10)
                .attr('height', state.nodeHeight)
                .attr('fill', function(d) {
                    if (d.key ==='entity1' || d.key === "entity2" || d.key === 'relation') {
                        return '#5b6bae'
                    }
                    else {
                        return d3.rgb(color(d.branch)).brighter(1.2);
                    }
                })
                .attr('stroke', function(d) {
                    if (d.key ==='entity1' || d.key === "entity2" || d.key === 'relation') {
                        return '#5b6bae'
                    }
                    else {
                        return color(d.branch);
                    }
                })
                .attr('stroke-width', 1);
            node.select('circle')
                .attr('class',d =>{
                    let name = 'markmap-node-circle';
                    if (d.loading) {
                        name += ' circle-node-loading'
                    }
                    return name
                })

            node.select('text')
                .attr("dy", "3")

            svg.selectAll("path.markmap-link")
                .attr('stroke-width', 1);
        },
        basic: function(source, nodes, links) {
            var svg = this.svg;
            var state = this.state;
            var color = this.helpers.color;
            var linkShape = this.helpers.linkShape;

            function linkWidth(d) {
                d.oldX = d.x;
                d.oldY = d.y;
                var depth = d.depth;
                if (d.name !== '' && d.children && d.children.length === 1 && d.children[0].name === '') {
                    depth += 1;
                }
                return Math.max(6 - 2*depth, 1.5);
            }

            // Update the nodes…
            var node = svg.selectAll("g.markmap-node")
                .data(nodes, function(d) { return d.id || (d.id = ++this.i); }.bind(this))
                .attr('nodeXY',function (d) {
                    return d.x + ',' + d.y
                });

            // 节点g
            var nodeEnter = node.enter().append("g")
                .attr("class", function (d) {
                    /*if (d.artIds) {
                        name += ' ' + d.artIds.join(' ')
                    }*/
                    return "markmap-node"
                })
                .attr("transform", function(d) { return "translate(" + (source.y0 + source.y_size - d.y_size) + "," + source.x0 + ")"; })
                .attr('nodeXY',function (d) {
                    return d.x + ',' + d.y
                })
                .on("click", this.click.bind(this))
                /** 鼠标移入，显示tooltip */
                .on('mouseover', d => this.showHover(d))
                .on('mousemove', d => this.showHover(d))
                .on('mouseout', d => {if (this.hoverBox) this.hoverBox.style('display', 'none')});

            /** 该节点矩形图形---如果是val节点则隐藏 */
            nodeEnter.append('rect')
                .attr('class', 'markmap-node-rect')
                .attr("y", function(d) { return -linkWidth(d) / 2 })
                .attr('x', function(d) { return d.y_size; })
                .attr('width', 0)
                //.attr('id', function(d) { if (d.children || d._children) return 'ydm_' + d.name; })    //添加节点ID，用于连线定位
                .attr('style', function(d) {
                    if (d.key==='value') {
                        return 'visibility: hidden'
                    }
                })
                .attr('height', linkWidth)
                //.attr("title", function(d) { return d.name; })      //鼠标移入显示名称
                .attr('fill', function(d) {
                    if (d.key ==='entity1' || d.key === "entity2" || d.key === 'relation') {
                        return '#5b6bae'
                    }
                    else {
                        return color(d.branch);
                    }
                });

            /** 最后一个节点下划线 */
            nodeEnter.append('line')
                .attr('class', 'markmap-node-line')
                .attr("x1", 0)
                .attr("y1", function (d) { return d.x_size / 2})
                .attr('x2', function(d) { return d.y_size; })
                .attr('y2', function(d) { return d.x_size / 2; })
                //.attr('width', 0)
                //.attr('id', function(d) { if (!d.children && !d._children) return 'ydm_' + d.name; })    //添加节点ID，用于连线定位
                .attr('style', function(d) {
                    if (d.key==='value') {
                        return 'stroke: gray;stroke-width:1'
                    }
                    else {
                        return 'visibility: hidden'
                    }
                })
            //.attr('height', linkWidth)
            //.attr('fill', function(d) { return color(d.branch); });

            /** 有子节点后面的圆点 */
            nodeEnter.append("circle")
                .attr('class', d =>{
                    /*if (d.loading) {
                        name += ' circle-node-loading'
                    }*/
                    return 'markmap-node-circle'
                })
                .attr('cx', function(d) { return d.y_size; })
                .attr('stroke', function(d) { return color(d.branch); })
                .attr("r", 1e-6)
                .attr('style', function(d) {
                    if (d.requested && (d._children && d._children.length===0)) {
                        return 'visibility: hidden'
                    }
                })
                .style("fill", function(d) {
                    return (d._children || !d.requested) ? color(d.branch) : '';
                });

            /** 文字 */
            nodeEnter.append("text")
                .attr('class', 'markmap-node-text')
                .attr("x", function(d) { return d.y_size; })
                .attr("dy", "-5")
                .attr("text-anchor", function(d) { return "start"; })
                .attr("fill",function (d) {
                    if (d.key ==='entity1' || d.key === "entity2" || d.key === 'relation') {
                        return '#ffffff'
                    }
                })
                .text(function(d) { return d.label; })     //显示的文字字段
                .style("fill-opacity", 1e-6);

            // Transition nodes to their new position.
            var nodeUpdate = node.transition()
                .duration(state.duration)
                .attr("transform", function(d) { return "translate(" + d.y + "," + d.x + ")"; });

            nodeUpdate.select('rect')
                .attr('x', -1)
                .attr('width', function(d) { return d.y_size + 2; });

            nodeUpdate.select("circle")
                .attr("r", 4.5)
                .style("fill", function(d) { return d._children ? color(d.branch) : ''; })
                .style('display', function(d) {
                    var hasChildren = d.href || d.children || d._children || !d.requested;
                    return hasChildren ?  'inline' : 'none';
                });

            /** 节点显示时 --- 最后一个下划线类型节点的起始样式和位置 */
            nodeUpdate.select("line")
                .attr("x1", 0)
                .attr("y1", function (d) { return d.x_size / 2})
                .attr('x2', function(d) { return d.y_size; })
                .attr('y2', function(d) { return d.x_size / 2; })

            nodeUpdate.select("text")
                .attr("x", 10)
                .style("fill-opacity", 1);

            // Transition exiting nodes to the parent's new position.
            var nodeExit = node.exit().transition()
                .duration(state.duration)
                .attr("transform", function(d) { return "translate(" + (source.y + source.y_size - d.y_size) + "," + source.x + ")"; })
                .remove();

            nodeExit.select('rect')
                .attr('x', function(d) { return d.y_size; })
                .attr('width', 0);

            nodeExit.select("circle")
                .attr("r", 1e-6);

            /** 节点收缩时 --- 最后一个下划线类型节点的最终样式和位置 */
            nodeExit.select('line')
                .attr('x1', function (d) { return d.y_size })
                .attr("y1", 0)
                .attr("y2", 0)

            nodeExit.select("text")
                .style("fill-opacity", 1e-6)
                .attr("x", function(d) { return d.y_size; });


            // Update the links…
            var link = svg.selectAll("path.markmap-link")
                .data(links, function(d) { return d.target.id; });

            /** 绘制线条 */
            // Enter any new links at the parent's previous position.
            link.enter().insert("path", "g")
                .attr("class", "markmap-link")
                .attr('stroke', function(d) {
                    if (d.children) {
                        d._children = [...d.children];
                        delete d.children
                    }
                    return color(d.target.branch);
                })
                .attr('stroke-width', function(l) {return linkWidth(l.target);})
                .attr("d", function(d) {
                    /*if (d.level===1 && _.isEmpty(d.children) && _.isEmpty(d._children)) {
                        let o = {
                            x: source.x0 - source.x_size,
                            y: source.y0
                        }
                        return linkShape({source: o, target: o});
                    }*/
                    var o = {x: source.x0, y: source.y0 + source.y_size};
                    return linkShape({source: o, target: o});
                })
                .attr('style', function(d) {
                    if (d.id === 0) {
                        return 'visibility: hidden'
                    }
                });

            // Transition links to their new position.
            link.transition()
                .duration(state.duration)
                .attr("d", function(d) {
                    var s = {x: d.source.x, y: d.source.y + d.source.y_size};
                    var t = {x: d.target.x, y: d.target.y};
                    return linkShape({source: s, target: t});
                });

            // Transition exiting nodes to the parent's new position.
            link.exit().transition()
                .duration(state.duration)
                .attr("d", function(d) {
                    var o = {x: source.x, y: source.y + source.y_size};
                    return linkShape({source: o, target: o});
                })
                .remove();

            // Stash the old positions for transition.
            nodes.forEach(function(d) {
                d.x0 = d.x;
                d.y0 = d.y;
            });
        }
    },
    // Toggle children on click.
    click: function(d) {
        // 如果处于选择模式，不做展开处理，返回该节点
        if (window.isCursor) {
            this.clickFn(d,'add');
            return;
        }
        // 未请求的节点
        if (!d.requested && (d.key==='attribute' || d.key==='relation' || d.key==='time')) {
            if (d.loading) return;
            d.loading = true;
            this.update(d);
            this.clickFn(d,'request',res =>{
                d.loading = false;
                if (res) {
                    let {children, artIds} = res;
                    let root = this.state.root;
                    d.children = children;
                    d._children = null;
                    d.artIds = artIds;
                    d.requested = true;
                    let newArtIds = artIds.map(artId => 'p' + artId);
                    //state.autoFit = true;
                    /*let source = d || state.root;
                    let all = this.layout(state)*/
                    let nodeData = root.children;
                    for (let i=0;i<nodeData.length;i++) {
                        if (nodeData[i].key===d.parent.key && nodeData[i].label===d.parent.label) {
                            if (nodeData[i].artIds) {
                                nodeData[i].artIds = [...nodeData[i].artIds,newArtIds];
                                break;
                            }
                        }
                    }
                }
                this.update(d);
            });
        }
        else {
            if (d.children || d._children) {
                if (d.children) {
                    d._children = d.children;
                    d.children = null;
                } else {
                    d.children = d._children;
                    d._children = null;
                }
                this.update(d);
                this.clickFn(d);
            } else {
                if (['value','entity2'].includes(d.key)) {
                    this.clickFn(d,'feedBack')
                }
            }
        }
    }
});

export default Markmap
