//import _ from 'lodash'
import BaseWebApi from './BaseWebApi'
import _ from "lodash"

class ResearchLibraryApi extends BaseWebApi {
    constructor(options) {
        super()
        this._api = this.gate.search
        this._apiV2 = this.gate.searchV2
    }

    /**根据id获取研报信息
     * 
     * @param {*} opts 
     */
    async getPDFDataById(opts) {
        let { id, type, classifier, struct_type } = opts;

        let postData = { id, type, struct_type }
        if (classifier) {
            postData["classifier"] = classifier;
        }

        // let resp = await this._api.postForm('search/getReport', postData)
        let resp = await this._apiV2.postForm('doc/get_report', postData)

        let retData = resp.data
        let retDataList = !_.isEmpty(retData.list) ? _.cloneDeep(retData.list) : []
        let newRetDataList = []
        _.forEach(retDataList, item => {
            let propsList = item[struct_type]

            _.forEach(propsList, propsItem => {
                let newItem = !_.isEmpty(item) ? _.cloneDeep(item) : []
                newItem[struct_type] = propsItem
                newRetDataList.push(newItem)
            })
        })

        retData.list = newRetDataList
        return retData
    }

    /**获取pdf所有句子分类
     * 
     * @param {Number} id
     * @param {Number} type
     */
    async getArtClassifier(opts) {

        let resp = await this._api.postForm('search/getArtClassifier', opts)
        return resp.data
    }

    /**获取单条知识点信息
     * 
     * @param {Number} id 知识点id
     */
    async getReportDetail(id) {

        let resp = await this._api.postForm('search/getReportDetail', { id: id })
        return resp.data
    }

}

export default ResearchLibraryApi