import BaseWebApi from './BaseWebApi'

class ReadListApi extends BaseWebApi {
    constructor(options) {
        super()
        this._api = this.gate.search
    }

    //获取研报信息
    async getMyCollections() {

        let resp = await this._api.postForm('search/collection/myCollections')
        return resp.data
        // return await this.TODO()
    }

    /**添加单个收藏
     * 
     * @param {*} activeId 收藏对象id
     * @param {*} type 收藏类型：1.企业研报 2.行业研报 3.年报 4.招股说明书 5企业信息 6.用户文档 100.知识点
     */
    async addCollection({ activeId, type }) {
        let postData = {
            id: activeId,
            type: type

        }

        let resp = await this._api.postForm('search/collection/add', postData)
        return resp.data
    }

    /**删除单个收藏
     * 
     * @param {*} id 收藏对象id
     */
    async removeCollection(id) {

        let resp = await this._api.postForm('search/collection/remove', { id })
        return resp.data
    }

    /**清空收藏*/
    async clearMyCollection() {

        let resp = await this._api.postForm('search/collection/clearMyCollection')
        return resp.data
    }
}

export default ReadListApi