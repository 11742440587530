import React, { useRef, useState } from 'react'
import { Input, Empty, Icon } from 'antd'
import './AdvancedSearch.scss'
import searchImg from '../../../assets/svg/searchImg.svg'
import clockImg from '../../../assets/svg/clockImg.svg'
import fileImg from '../../../assets/svg/fileImg.svg'
import closeImage from '../../../assets/svg/closeImage.svg'
function AdvancedSearch({ searchByKeyword, searchClick }) {
  const [keyword, setKeyword] = useState('')
  const [isShowHistory, setIsShowHistory] = useState(false)
  const [searchHistoryList, setSearchHistoryList] = useState([
    { id: 1, name: '万科近五年的财报'},
    { id: 2, name: '恒大季度报告'},
    { id: 3, name: '利润报告'},
  ])
  const [browseHistoryList, setBrowseHistoryList] = useState([
    { id: 1, name: '[上海钢联]拓宽供应链服务的质量拓宽供应链服务的质量'},
    { id: 2, name: '[上海钢联]拓宽供应链服务的质量拓宽供应链服务的质量'},
    { id: 3, name: '[上海钢联]拓宽供应链服务的质量拓宽供应链服务的质量'},
    { id: 4, name: '[上海钢联]拓宽供应链服务的质量拓宽供应链服务的质量'},
    { id: 5, name: '[上海钢联]拓宽供应链服务的质量拓宽供应链服务的质量'},
    { id: 6, name: '[上海钢联]拓宽供应链服务的质量拓宽供应链服务的质量'},
    { id: 7, name: '[上海钢联]拓宽供应链服务的质量拓宽供应链服务的质量'},
    { id: 8, name: '[上海钢联]拓宽供应链服务的质量拓宽供应链服务的质量'},
    { id: 9, name: '[上海钢联]拓宽供应链服务的质量拓宽供应链服务的质量'},
    { id: 10, name: '[上海钢联]拓宽供应链服务的质量拓宽供应链服务的质量'},
    { id: 11, name: '[上海钢联]拓宽供应链服务的质量拓宽供应链服务的质量'},
    { id: 12, name: '[上海钢联]拓宽供应链服务的质量拓宽供应链服务的质量'},
    { id: 13, name: '[上海钢联]拓宽供应链服务的质量拓宽供应链服务的质量'},
    { id: 14, name: '[上海钢联]拓宽供应链服务的质量拓宽供应链服务的质量'},
    { id: 15, name: '[上海钢联]拓宽供应链服务的质量拓宽供应链服务的质量'},
  ])

  const advancedSearchClick = () => {
    searchClick && searchClick()
  }

  const searchChange = (event) => {
    setIsShowHistory(true)
    const key = event.target.value
    setKeyword(key)
  }

  const searchKeyword = () => {
    searchByKeyword(keyword)
  }

  const searchHistoryItem = (item) => {
    console.log('searchHistoryClick')
  }

  const delSearchHistory = (item) => {
    console.log('delSearchHistory')
  } 

  return (
    <div className="company-list__industry-screen__item advanced-search">
      <Input
        placeholder="搜索"
        onChange={searchChange}
        onPressEnter={searchKeyword}
        style={{ width: 282 }}
        value={keyword}
        prefix={<Icon type='search' className='search-icon' onClick={searchKeyword} /> }
        onBlur={() => setTimeout(() => setIsShowHistory(false), 200)}
      />
      {
        isShowHistory &&
        <div className='history'>
            <div className='history-item' onClick={advancedSearchClick}>
                <li>
                    <img src={searchImg} alt=''/>
                    <span className='search-text'>高级搜索</span>
                </li>
            </div>
            <div className='history-item'>
                <h6>搜索历史</h6>
                <ul>
                    {
                        searchHistoryList.length>0 && searchHistoryList.map((item) => {
                            return <li key={item.id} onClick={() => searchHistoryItem(item)}>
                                <img src={clockImg} alt=''/>
                                <span>{item.name}</span>
                                <span className='del-history-item' onClick={() => delSearchHistory(item)}><img src={closeImage} alt=''/></span>
                            </li>
                        })
                    }
                </ul>
            </div>
            <div className='history-item'>
                <h6>浏览历史</h6>
                <ul>
                    {
                        browseHistoryList.length>0 && browseHistoryList.map((item) => {
                            return <li key={item.id}>
                                <img src={fileImg} alt=''/>
                                <span>{item.name}</span>
                            </li>
                        })
                    }
                </ul>
            </div>
        </div>
      }
    </div>
  )
}

export default AdvancedSearch