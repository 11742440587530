import React, {useEffect} from 'react';
import { Route, Switch, Redirect } from 'react-router-dom'
import {Button} from 'antd';
import "./index.scss";
import Breadcrumb from "../../common/Breadcrumb/Breadcrumb";
import ScrollBar from "../../common/ScrollBar";
import stores from "../../../store";
import {observer} from 'mobx-react';
import SyllabusList from "./List";
import SyllabusEdit from "./Edit";
import SyllabusInfo from "./Info";
import CreateReport from "./CreateReport";


function SyllabusIndex(props) {
    const url = props.match.url;

    const {pageType} = stores.syllabusStore;

    const editAction = () =>{
        let pathname = props.location.pathname;
        if (pathname.indexOf('/syllabus/info/') !== -1) {   // 编辑
            let id = pathname.replace(/\/syllabus\/info\//,'');
            props.history.push({
                pathname: `/syllabus/edit/${id}`
            })
        } else {
            props.history.push({
                pathname: '/syllabus/add'
            })
        }
    };

    return (
        <div className="syllabus-page">
            <div className="syllabus-header">
                <Breadcrumb history={props.history} crumbList={stores.syllabusStore.crumbList.slice()}/>
                {
                    ['list', 'info'].includes(pageType) ? <Button type="primary" style={{height: 30,padding: '0 30px'}} onClick={editAction}>{pageType==='list'? "新建" : "编辑"}</Button> : null
                }
            </div>
            <ScrollBar className="syllabus-main">
                <Switch>
                    <Route path={`${url}/list`} render={props => <SyllabusList {...props}/>}/>
                    <Route path={`${url}/edit/:id`} render={props => <SyllabusEdit {...props}/>}/>
                    <Route path={`${url}/info/:id`} render={props => <SyllabusInfo {...props}/>}/>
                    <Route path={`${url}/add`} render={props => <SyllabusEdit {...props}/>}/>
                    <Route path={`${url}/createReport/:id`} render={props => <CreateReport {...props}/>}/>
                    <Route path={`${url}/createReport`} render={props => <CreateReport {...props}/>}/>
                    <Redirect from={url} to={`${url}/list`} />
                </Switch>
            </ScrollBar>
        </div>
    )
}
export default observer(SyllabusIndex)
