import React from 'react'
import _ from 'lodash'
import './Index.scss'
//import { Affix } from 'antd'
import PublicHeader from './PublicHeader'

import { Route, Switch, Redirect } from 'react-router-dom'
//import { webSdk } from "../../../api/WebSdk";
import 'mobx-react-lite/batchingForReactDom'

import Home from './Home/Home'
import KnowledgeResultIndex from './KnowledgeResult/KnowledgeResultIndex'
import CompanyList from './CompanyList/CompanyList'
import ResearchLibrary from './ResearchLibrary/index'
import YearReport from './YearReport/YearReport'
import RegulationFall from './RegulationFall/RegulationFall'
import RegulationFallDetail from './RegulationFall/details/index'
import ResearchLibraryIndex from './ResearchLibrary/ResearchLibraryIndex'
import LoginPage from './Login/LoginPage'
import FileManage from './UserCenter/FileManage'
import SearchCompanyResult from './CompanyList/result/companyInfo/SearchCompanyResult'
import InvitationIndex from './invitation/InvitationIndex'
import SearchResultList from './SearchResultList/SearchResultList'
import Register from './Login/Register'
import FindPassword from './Login/FindPassword'
import CompanyBaseInfo from './SearchResultList/companyInfo/CompanyBaseInfo'
import RelationGraph from './RelationGraph'
import GraphSearch from './SearchResultList/ResultSearch/ResultSearch'
import ResultSource from './SearchResultList/ResultSearch/ResSource'
import PublicCycleAnswer from './PublicCycleAnswer'
import GatheringIndex from './Gathering'
import GatheringDetail from './Gathering/GatheringDetailList'
import EntersInfo from './EntersInfo'
import EnterDetail from './EntersInfo/EnterDetail'
import EnterSearch from './EntersInfo/EnterDetail/SearchResult'
import LessonPlan from './lessonPlan'
import LessonPlanCreate from './lessonPlan/CreatePlan'
import LessonPlanDetail from './lessonPlan/DetailIndex'
import ExampleList from './SmartSearch/ExampleList'
import SummaryList from './SmartSearch/SummaryList'
import SyllabusIndex from './Syllabus/SyllabusIndex'
import ReportIndex from './Report/ReportIndex'
import FinanceLibrary from './financeLibrary/index'
import FinanceLibraryDetail from './financeLibrary/details/index'
import FincSubLibrary from './FinanceSubLibrary/index'
import FincSubLibraryDetail from './FinanceSubLibrary/children/ListDetail'
import BusinessDatabase from './BusinessDatabase/index'
import BusinessDatabaseDetail from './BusinessDatabase/details/index'
import SubjectGraph from './SubjectGraph/index'

class Index extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      searchValue: '',
      searchType: 'home', //搜索框的类型
      currentMenu: 'home',
      shrinkMenu: false, //是否收缩菜单
      showMenu: false, //是否显示菜单
      pathname: this.props.location.pathname || '',
      isSearchPage: false, //是否搜索页面，控制header高度
      // 12.14 搜索框下方列表類型
      currentSearchType: 'list'
    }

    this.setMenuWord = this.setMenuWord.bind(this)
    this.setType = this.setType.bind(this)

    this.changeMenu = this.changeMenu.bind(this)
    this.changeShrinkMenu = this.changeShrinkMenu.bind(this)
    //this.doSearch = this.doSearch.bind(this);
    this.changeValue = this.changeValue.bind(this)

    this.initFilterList = this.initFilterList.bind(this)
  }

  componentDidMount() {
    this.setMenuWord(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.setMenuWord(nextProps)
    if (nextProps.location.pathname || '' !== this.state.pathname) {
      this.setState({
        pathname: nextProps.location.pathname
      })
    }
  }

  componentWillUnmount() {
    // 卸载异步操作设置状态
    this.setState = (state, callback) => {
      return
    }
  }

  /** 设置搜索框类型、搜索文字 */
  setType(searchType, searchValue) {
    const _this = this
    if (searchType !== null) {
      let isSearchPage = searchType === 'resultSearch'
      this.setState({
        searchType: searchType || 'home',
        searchValue: searchValue || '',
        isSearchPage
      })
    } else {
      this.setState({
        searchValue: searchValue || ''
      })
    }
  }

  setMenuWord(props) {
    let pathname = props.location.pathname
    let currentMenu = ''
    let showMenu = true
    if (pathname.indexOf('/companyList') !== -1) {
      currentMenu = 'companyList'
      showMenu = true
    } else if (pathname.indexOf('/companyResult') !== -1) {
      currentMenu = ''
      showMenu = false
    } else if (pathname.indexOf('/knowledge') !== -1) {
      currentMenu = 'knowledge'
      showMenu = false
    } else if (
      pathname === '/research' ||
      pathname.indexOf('/research/') !== -1
    ) {
      currentMenu = 'research'
      showMenu = true
    } else if (pathname.indexOf('/yearReport') !== -1) {
      currentMenu = 'yearReport'
      showMenu = true
    } else if (pathname.indexOf('/invitation') !== -1) {
      currentMenu = 'invitation'
      showMenu = true
    } else if (pathname.indexOf('/entersinfo') !== -1) {
      currentMenu = 'entersinfo'
      showMenu = false
    } else if (pathname.indexOf('/enterdetail') !== -1) {
      currentMenu = 'entersinfo'
      showMenu = false
    } else if (pathname.indexOf('/gathering') !== -1) {
      currentMenu = 'gathering'
      showMenu = true
    } else if (pathname.indexOf('/lessonplan') !== -1) {
      currentMenu = 'lessonplan'
      showMenu = true
    } else if (pathname.indexOf('/smartsearch') !== -1) {
      currentMenu = 'home'
      showMenu = true
    } else if (pathname.indexOf('/home') !== -1) {
      currentMenu = 'home'
      showMenu = true
    } else if (pathname.indexOf('/readList') !== -1) {
      currentMenu = 'readList'
      showMenu = true
    } else if (pathname.indexOf('/fileManage') !== -1) {
      currentMenu = 'fileManage'
      showMenu = true
    } else if (pathname.indexOf('/resultList') !== -1) {
      currentMenu = 'resultList'
      showMenu = false
    } else if (pathname.indexOf('/syllabus') !== -1) {
      currentMenu = 'syllabus'
      showMenu = true
    } else if (pathname.indexOf('/report') !== -1) {
      currentMenu = 'report'
      showMenu = true
    } else if (pathname.indexOf('/financedetail') !== -1) {
      currentMenu = 'finance'
      showMenu = false
    } else if (pathname.indexOf('/finance') !== -1) {
      currentMenu = 'finance'
      showMenu = true
    } else if (pathname.indexOf('/fincSubLibrary') !== -1) {
      currentMenu = 'fincSubLibrary'
      showMenu = true
    } else if (pathname.indexOf('/businessDatabaseDetail') !== -1) {
      currentMenu = 'businessDatabase'
      showMenu = true
    } else if (pathname.indexOf('/businessDatabase') !== -1) {
      currentMenu = 'businessDatabase'
      showMenu = true
    }else if (pathname.indexOf('/regulationFallDetail') !== -1) {
      currentMenu = 'finance'
      showMenu = false
    } else if (pathname.indexOf('/regulationFall') !== -1) {
      currentMenu = 'regulationFall'
      showMenu = true
    } else if (pathname.indexOf('/subjectGraph') !== -1) {
      currentMenu = ''
      showMenu = false
    } else {
      currentMenu = ''
      showMenu = false
    }

    if (currentMenu && currentMenu === 'resultList') {
      let pathArr = pathname.split('/')
      this.setState(
        {
          searchValue: pathArr[2]
        },
        () => {
          if (pathArr[3]) {
            this.setState({
              currentSearchType: pathArr[3]
            })
          }
        }
      )
    } else if (
      currentMenu &&
      ![
        'syllabus',
        'report',
        'home',
        'lessonplan',
        'businessDatabase'
      ].includes(currentMenu)
    ) {
      let pathArr = pathname.split('/')
      if (pathArr[2]) {
        this.setState({
          searchValue: pathArr[2]
        })
      }
    }

    this.setState({
      currentMenu,
      showMenu
    })
  }

  changeMenu(item) {
    if (item.key === 'knowledge') {
      this.setState({
        currentMenu: item.key,
        shrinkMenu: false
      })
    } else {
      this.setState({
        currentMenu: item.key
      })
    }
  }

  changeShrinkMenu() {
    this.setState({
      shrinkMenu: !this.state.shrinkMenu
    })
  }

  /*doSearch(val, type) {
        // if (this.state.currentMenu !== 'home') {
        //     this.props.history.push({
        //         pathname: '/index/' + this.state.currentMenu + '/' + val
        //     })
        // }
        this.setState({
            searchValue: val
        })
        // this.props.history.push({
        //     pathname: '/' + type + '/' + val
        // })
        if (!val) {
            this.props.history.push({
                pathname: '/home'
            })
        }
        else {
            this.props.history.push({
                pathname: `/resultList/${val}`
            })
        }

    }*/

  changeValue(val) {
    this.setState({
      searchValue: val
    })
  }

  /** 搜索结果页面点击搜索结果下方列表 */
  changeSearchType = (searchType) => {
    let url = this.props.location.pathname
    let urlArr = url.split('/')
    let type = urlArr[urlArr.length - 1]
    if (type !== searchType) {
      urlArr.splice(urlArr.length - 1)
      this.props.history.push({
        pathname: urlArr.join('/') + `/${searchType}`
      })
    }
  }

  /**多伦问答：操作显隐展示 */
  handleCycleQuestion() {
    this.refs.publicCycle.handleCycleQuestion()
  }

  /**多伦问答：操作问答图标 */
  handleCycleQuestionAuto(isShow) {
    this.refs.publicHeader.handleCycleQuestionAuto(isShow)
  }

  /**多伦问答：清除数据 */
  clearCycleAnswerStor() {
    this.refs.publicCycle.setCycleAnswerStor([])
  }

  /**设置过滤列表数据
   *
   * @param {array} list 列表数组
   */
  initFilterList = (list) => {
    this.refs.publicHeader.initFilterList(list)
  }

  /**是否显示过滤列表
   *
   * @param {boolean} type 类型：true显示；false隐藏
   */
  triggerFilterList = (type, allow) => {
    this.refs.publicHeader.triggerFilterList(type, allow)
  }

  render() {
    let pathname = this.state.pathname
    let isLoginPage =
      pathname === '/login' ||
      pathname === '/register' ||
      pathname === '/findPassword' ||
      pathname === '/markMap'

    return (
      <div className="home-page">
        {!isLoginPage ? (
          <div style={{ width: '100%', height: 60 }}>
            <PublicHeader
              ref="publicHeader"
              match={this.props.match}
              currentMenu={this.state.currentMenu}
              shrinkMenu={this.state.shrinkMenu}
              showMenu={this.state.showMenu}
              searchType={this.state.searchType}
              currentSearchType={this.state.currentSearchType}
              isSearchPage={this.state.isSearchPage}
              fnChangeMenu={this.changeMenu}
              fnShrinkMenu={this.changeShrinkMenu}
              //fnDoSearch={this.doSearch}
              fnSetType={this.setType}
              fnChangeValue={this.changeValue}
              //changeSearchType={this.changeSearchType}
              searchValue={this.state.searchValue}
              currentUrl={this.props.location.pathname}
              fnHandleCycleQuestion={this.handleCycleQuestion.bind(this)}
              fnClearCycleAnswerStor={this.clearCycleAnswerStor.bind(this)}
            />
          </div>
        ) : null}

        <div
          className="home-page-main"
          id="home-page-main"
          style={
            isLoginPage
              ? { padding: 0 }
              : !this.state.showMenu
              ? { paddingLeft: 0 }
              : this.state.shrinkMenu
              ? { paddingLeft: 120 }
              : { paddingLeft: 200 }
          }
        >
          <div
            className="home-page-content"
            style={
              isLoginPage
                ? { padding: 0 }
                : this.state.currentMenu === 'home'
                ? { minWidth: 1000 }
                : {}
            }
          >
            <Switch>
              <Route
                path="/home/:keyword"
                render={(props) => <Home {...props} fnSetType={this.setType} />}
              />
              <Route
                path="/home"
                render={(props) => <Home {...props} fnSetType={this.setType} />}
              />
              <Redirect exact from="/" to="/home" />
              {/* <Redirect exact from="/index" to="/index/home" /> */}

              {/* 2019.12.12修改，以前的版本*/}
              {/*<Route path="/resultList/:keyword" render={props =><SearchResultList {...props} fnSetType={this.setType}/>}/>
                        <Redirect from="/resultList" to="/home" />*/}
              <Route
                path="/resultList/:keyword?"
                render={(props) => (
                  <GraphSearch
                    {...props}
                    fnSetType={this.setType}
                    fnInitFilterList={this.initFilterList}
                    fnTriggerFilterList={this.triggerFilterList}
                  />
                )}
              />
              <Route
                path="/resultSource/:artId/:keyword/:lightId"
                render={(props) => (
                  <ResultSource {...props} fnSetType={this.setType} />
                )}
              />
              <Route
                path="/resultSource/:artId/:keyword"
                render={(props) => (
                  <ResultSource {...props} fnSetType={this.setType} />
                )}
              />

              {/*<Route path="/knowledge/:keyword" component={KnowledgeResultIndex} />
                        <Route path="/knowledge" component={KnowledgeResultIndex} />*/}

              <Route
                path="/companyList/:keyword"
                render={(props) => (
                  <CompanyList {...props} fnSetType={this.setType} />
                )}
              />
              <Route
                path="/companyList"
                render={(props) => (
                  <CompanyList {...props} fnSetType={this.setType} />
                )}
              />

              <Route
                path="/research/:keyword/:type"
                render={(props) => (
                  <ResearchLibrary {...props} fnSetType={this.setType} />
                )}
              />
              <Redirect
                exact
                from="/research/:keyword"
                to="/research/:keyword/1"
              />
              <Route
                path="/research"
                render={(props) => (
                  <ResearchLibrary {...props} fnSetType={this.setType} />
                )}
              />

              <Route
                path="/researchResult/:type/:id/:selectType/:lightId/:keyWord"
                render={(props) => (
                  <ResearchLibraryIndex {...props} fnSetType={this.setType} />
                )}
              />
              <Route
                path="/researchResult/:type/:id/:selectType"
                render={(props) => (
                  <ResearchLibraryIndex {...props} fnSetType={this.setType} />
                )}
              />
              {/* selectType类型(从某个页面中进入pdf的)：1.企业研报 2.行业研报 3.年报 4.招股说明书 5企业信息 6.用户文档 100.知识点 */}

              <Route
                path="/yearReport/:keyword"
                render={(props) => (
                  <YearReport {...props} fnSetType={this.setType} />
                )}
              />
              <Route
                path="/yearReport"
                render={(props) => (
                  <YearReport {...props} fnSetType={this.setType} />
                )}
              />

              <Route
                path="/invitation/:keyword"
                render={(props) => (
                  <InvitationIndex {...props} fnSetType={this.setType} />
                )}
              />
              <Route
                path="/invitation"
                render={(props) => (
                  <InvitationIndex {...props} fnSetType={this.setType} />
                )}
              />

              <Route
                path="/entersearch/:pageKeyword"
                render={(props) => (
                  <EnterSearch {...props} fnSetType={this.setType} />
                )}
              />
              <Route
                path="/enterdetail/:keyword"
                render={(props) => (
                  <EnterDetail {...props} fnSetType={this.setType} />
                )}
              />
              <Route
                path="/enterdetail"
                render={(props) => (
                  <EnterDetail {...props} fnSetType={this.setType} />
                )}
              />
              <Route
                path="/entersinfo/:keyword"
                render={(props) => (
                  <EntersInfo {...props} fnSetType={this.setType} />
                )}
              />
              <Route
                path="/entersinfo"
                render={(props) => (
                  <EntersInfo {...props} fnSetType={this.setType} />
                )}
              />

              <Route
                path="/financedetail/:keyword"
                render={(props) => <FinanceLibraryDetail {...props} />}
                fnSetType={this.setType}
              />
              <Route
                path="/finance"
                render={(props) => <FinanceLibrary {...props} />}
                fnSetType={this.setType}
              />

              <Route
                path="/fincSubLibraryDetail/:keyword"
                render={(props) => <FincSubLibraryDetail {...props} />}
                fnSetType={this.setType}
              />
              <Route
                path="/fincSubLibrary"
                render={(props) => <FincSubLibrary {...props} />}
                fnSetType={this.setType}
              />

              <Route
                path="/businessDatabaseDetail/:keyword"
                render={(props) => <BusinessDatabaseDetail {...props} />}
                fnSetType={this.setType}
              />

              <Route
                path="/businessDatabase"
                render={(props) => <BusinessDatabase {...props} />}
                fnSetType={this.setType}
              />

              <Route
                path="/regulationFallDetail/:keyword"
                render={(props) => <RegulationFallDetail {...props} />}
                fnSetType={this.setType}
              />
              <Route
                path="/regulationFall"
                render={(props) => <RegulationFall {...props} />}
                fnSetType={this.setType}
              />

              <Route
                path="/subjectGraph"
                render={(props) => <SubjectGraph {...props} />}
                fnSetType={this.setType}
              />

              <Route
                path="/gatheringDetail"
                render={(props) => (
                  <GatheringDetail {...props} fnSetType={this.setType} />
                )}
              />
              <Route
                path="/gathering"
                render={(props) => (
                  <GatheringIndex {...props} fnSetType={this.setType} />
                )}
              />

              <Route
                path="/lessonplan/examples"
                render={(props) => (
                  <LessonPlanDetail {...props} fnSetType={this.setType} />
                )}
              />
              <Route
                path="/lessonplan/edit"
                render={(props) => (
                  <LessonPlanDetail {...props} fnSetType={this.setType} />
                )}
              />
              <Route
                path="/lessonplan/display"
                render={(props) => (
                  <LessonPlanDetail {...props} fnSetType={this.setType} />
                )}
              />
              <Route
                path="/lessonplan/create"
                render={(props) => (
                  <LessonPlanCreate {...props} fnSetType={this.setType} />
                )}
              />
              <Route
                path="/lessonplan"
                render={(props) => (
                  <LessonPlan {...props} fnSetType={this.setType} />
                )}
              />

              <Route
                path="/smartsearch/example"
                render={(props) => (
                  <ExampleList {...props} fnSetType={this.setType} />
                )}
              />
              <Route
                path="/smartsearch/summary"
                render={(props) => (
                  <SummaryList {...props} fnSetType={this.setType} />
                )}
              />

              <Route
                path="/test"
                render={(props) => (
                  <RelationGraph {...props} fnSetType={this.setType} />
                )}
              />

              <Route path="/fileManage" component={FileManage} />
              <Route path="/login" component={LoginPage} />
              <Route path="/register" component={Register} />
              <Route path="/findPassword" component={FindPassword} />

              <Route
                path="/companyInfo/:id"
                render={(props) => (
                  <CompanyBaseInfo {...props} fnSetType={this.setType} />
                )}
              />
              <Redirect exact from="/companyInfo" to="/home" />

              <Route
                path="/companyResult/:keyword"
                render={(props) => (
                  <SearchCompanyResult {...props} fnSetType={this.setType} />
                )}
              />
              <Route
                path="/companyResult"
                render={(props) => (
                  <SearchCompanyResult {...props} fnSetType={this.setType} />
                )}
              />

              <Route
                path="/syllabus"
                component={(props) => <SyllabusIndex {...props} />}
              />
              <Route
                path="/report"
                component={(props) => <ReportIndex {...props} />}
              />
            </Switch>
          </div>
        </div>

        <div className="home-cycleque">
          <PublicCycleAnswer
            ref="publicCycle"
            fnHandleCycleQuestionAuto={this.handleCycleQuestionAuto.bind(this)}
            fnSetType={this.setType}
          />
        </div>
      </div>
    )
  }
}

export default Index
