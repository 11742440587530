import React from 'react'
import {webSdk} from "../../../../../../../api/WebSdk";
import {Table, DatePicker, Icon, Spin, Select} from 'antd';
import '../cash/FinancialCaseSheet.scss'

const {RangePicker} = DatePicker;
const { Option } = Select;

//assetsLiability
class FinancialAssetsLiability extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            keyword: props.keyword || '',
            selectTime: '',
            tableTitle: '',
            selectTimeArr: [
                {
                    label: '2013年',
                    key: '2013'
                },
                {
                    label: '2014年',
                    key: '2014'
                },
                {
                    label: '2015年',
                    key: '2015'
                },
                {
                    label: '2016年',
                    key: '2016'
                },
                {
                    label: '2017年',
                    key: '2017'
                },
                {
                    label: '2018年',
                    key: '2018'
                }
            ],
            //表格表头
            columns: [
                {
                    "title": "资产",
                    "dataIndex": "assets",
                    "width": '40%'
                    //"align": "center"
                },
                {
                    "title": "金额",
                    "dataIndex": "assetsAmount",
                    "align": "center",
                    "width": '10%'
                },
                /*{
                    "title": "单位",
                    "dataIndex": "assetsUnit",
                    "align": "center"
                },*/
                {
                    "title": "负债及所有者权益",
                    "dataIndex": "liability",
                    "width": '40%'
                    //"align": "center"
                },
                {
                    "title": "金额",
                    "dataIndex": "liabilityAmount",
                    "align": "center",
                    "width": '10%'
                },
                /*{
                    "title": "单位",
                    "dataIndex": "liabilityUnit",
                    "align": "center"
                }*/
            ],
            tableData:[]
        }

        this.getBaseInfo = this.getBaseInfo.bind(this);
        this.choiceTime = this.choiceTime.bind(this);
    }

    componentDidMount() {
        this.getBaseInfo()
    }

    componentWillReceiveProps(nextProps) {

    }

    /**
     * 获取页面基本信息
     */
    getBaseInfo() {
        let postData = {
            style: 'assetsLiability',    //当前菜单
            p: this.state.keyword, //当前搜索词
            selectTime: this.state.selectTime
        };

        webSdk.search.getFinancialInfo(postData).then(res => {
            this.setState({
                tableTitle: res.tableTitle,
                tableData: res.tableData
            })
        })
    }

    /*choiceTime(date, dateString) {
        this.setState({
            choiceTime: dateString[0] + '-' + dateString[1],
            tableTitle: '',
            tableData: []
        },() =>{
            this.getBaseInfo();
        })
    }*/
    choiceTime(value) {
        this.setState({
            choiceTime: value,
            tableTitle: '',
            tableData: []
        },() =>{
            this.getBaseInfo();
        })
    }

    render() {
        return (
            <div className="financial-assets-liability">
                <div className="header-title">
                    资产负债表
                    <div className="financial-assets-liability-title-right">
                        <div className="time-choice">
                            <span>选择统计时间</span>
                            <Select style={{ width: 120,marginLeft: 10 }} defaultValue={this.state.selectTimeArr[this.state.selectTimeArr.length-1].key} onChange={this.choiceTime.bind(this)}>
                                {
                                    this.state.selectTimeArr.map((item,index) =>{
                                        return (
                                            <Option key={index} value={item.key}>{item.label}</Option>
                                        )
                                    })
                                }
                            </Select>
                            {/*<RangePicker style={{width: 220,height: 32,marginLeft: 10}} size="default" format='YYYY/MM/DD' placeholder={['开始','结束']} onChange={this.choiceTime}/>*/}
                        </div>
                        <div className="download-table-button">
                            下载报表<Icon className="download-icon" type="cloud-download" />
                        </div>
                    </div>

                </div>
                <div className="table-title">{this.state.tableTitle}</div>
                <div className="table-main" style={!this.state.tableData || this.state.tableData.length===0?{miniHeight: 200}:{}}>
                    {
                        !this.state.tableData || this.state.tableData.length===0?(<Spin size="large"/>):''
                    }
                    <Table
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        rowClassName={(record, index) => {
                            let row = index % 2 === 0 ? 'first-row' : 'second-row';
                            //let color = record.setColor ? ' red-color-style' : '';
                            let leftColor = record.leftColor?' left-color':'';
                            let rightColor = record.rightColor?' right-color':'';
                            let leftLevel = ` left-level-${record.leftLevel}`;
                            let rightLevel = ` right-level-${record.rightLevel}`;
                            return  row + leftColor + rightColor + leftLevel + rightLevel
                        }}
                        rowKey={(record, index) => {
                            return index
                        }}
                        pagination={false}
                    />
                </div>
            </div>
        )
    }
}

export default FinancialAssetsLiability