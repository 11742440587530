import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// 扩展日期函数
import {} from 'bp'
import AppRouter from './router.js'
import * as serviceWorker from './serviceWorker';
import { Provider } from 'mobx-react'
import stores from './store'

ReactDOM.render(
    <Provider {...stores}>
        <AppRouter />
    </Provider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
