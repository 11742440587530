import React from 'react'
import {Checkbox, Table, Icon, Button} from 'antd'
import ScrollBar from "../../../../../common/ScrollBar";
import {forItemsAddTable, downloadExcel} from "./zFunction";

const eavColumns = [
    {
        "title": "时间",
        "dataIndex": "time",
        "align": "center"
    },
    {
        "title": "主体",
        "dataIndex": "entity",
        "align": "center"
    },
    {
        "title": "属性",
        "dataIndex": "attribute",
        "align": "center"
    },
    {
        "title": "值",
        "dataIndex": "value",
        "align": "center"
    }
];
const eaeColumns = [
    /*{
        "title": "时间",
        "dataIndex": "time",
        "align": "center"
    },*/
    {
        "title": "主体",
        "dataIndex": "entity",
        "align": "center"
    },
    {
        "title": "关系",
        "dataIndex": "relation",
        "align": "center"
    },
    {
        "title": "主体2",
        "dataIndex": "entity2",
        "align": "center"
    }
];

class TableList extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            checkedValues: [],
            checkedList: [],
            eavData: [],
            eaeData: []
        }
        this.items = [];        // 已选择的节点储存
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.keyword !== this.props.keyword) {
            this.items = [];
            this.setState({
                checkedValues: [],
                checkedList: [],
                eavData: [],
                eaeData: []
            })
        }
    }

    componentWillUnmount() {
        // 卸载异步操作设置状态
        this.setState = (state, callback) => {
            return;
        }
    }

    addNodeClick = item => {
        let items = this.items;
        //let items = this.items;
        // 判断当前点击的节点是否已存在
        if (items.length > 0) {
            let hasIn = false;
            for (let i = 0; i < items.length; i++) {
                if (item._id === items[i]._id) {
                    hasIn = true;
                    break;
                }
            }
            if (hasIn) return
        }
        items.push(item);

        // 添加该节点到右侧勾选框，并处于勾选状态
        let checkedList = [...this.state.checkedList];
        let checkedValues = [...this.state.checkedValues];
        checkedList.push({
            key: item._id,            // 标签唯一标识
            label: item.label     // 标签名称
        });
        checkedValues.push(item._id);

        // 添加数据到右侧表格
        let {eavData, eaeData} = forItemsAddTable(items, checkedValues);
        this.setState({
            checkedValues, checkedList, eavData, eaeData
        })
    }

    checkboxChange = checkedValues => {
        let items = this.items;
        let {eavData, eaeData} = forItemsAddTable(items, checkedValues);
        this.setState({
            checkedValues, eavData, eaeData
        })
    }

    deleteChecked = (item, e) => {
        let items = this.items;
        let checkedValues = [...this.state.checkedValues];
        let checkedList = [...this.state.checkedList];

        let checkedIndex = checkedValues.indexOf(item._id);
        checkedValues.splice(checkedIndex, 1);

        let itemIndex = this.items.findIndex(itm => itm._id === item._id);
        items.splice(itemIndex, 1);

        let listIndex = checkedList.findIndex(itm => item.key === item._id);
        checkedList.splice(listIndex, 1);

        let {eavData, eaeData} = forItemsAddTable(this.items, checkedValues);

        this.setState({
            checkedValues, checkedList, eavData, eaeData
        })

        e.stopPropagation();
    }

    render() {
        let {checkedValues, checkedList, eavData, eaeData} = this.state;
        return (
            <div className="result-search__graph__right__main">
                <div className="pivot-set">
                    <h3 className="first-title">数据透视表</h3>
                    <h4 className="second-title">字段列表</h4>
                    <div className="scroll-box">
                        <ScrollBar>
                            <div className="key-list">
                                <Checkbox.Group style={{width: '100%'}} onChange={this.checkboxChange}
                                                value={checkedValues}>
                                    {
                                        checkedList.map((item, index) => {
                                            return (
                                                <Checkbox key={item.key} value={item.key}>{item.label}<span
                                                    className="key-list-delete"
                                                    onClick={(e) => this.deleteChecked(item, e)}
                                                    title="删除"><Icon type="close-circle"/></span></Checkbox>
                                            )
                                        })
                                    }
                                </Checkbox.Group>
                            </div>
                        </ScrollBar>
                    </div>
                </div>
                <div className="table-view">
                    <h3 className="first-title"><span>预览</span><Button
                        disabled={eavData.length === 0 && eaeData.length === 0}
                        onClick={() => downloadExcel(this.props.keyword, eavData, eaeData)}>保存<span
                        className="font_icon">&#xe66b;</span></Button></h3>
                    <div className="graph-table">
                        <Table
                            columns={eavColumns}
                            dataSource={eavData}
                            rowKey={(record, index) => index}
                            pagination={false}
                        />
                    </div>
                    <div className="graph-table">
                        <Table
                            columns={eaeColumns}
                            dataSource={eaeData}
                            rowKey={(record, index) => index}
                            pagination={false}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default TableList