import React, { useRef, useState, useEffect } from 'react'
import {webSdk} from "../../../api/WebSdk";
import { Input, Empty, Icon } from 'antd'
import './AdvancedSearchContent.scss'
import { constant } from 'lodash';
function AdvancedSearchContent({data,filterDataChange}) {

    const [keyword, setKeyword] = useState('')
    const [isShowHistory, setIsShowHistory] = useState(false)
    const [filterData, setFilterData] = useState(data)
    const [nonDefaultBrokersTags, setNonDefaultBrokersTags] = useState([]) // 非默认券商展示标签
    const [nonDefaultAnalystTags, setNonDefaultAnalystTags] =useState([])
    const [isShowClear, setIsShowClear] = useState(false)
    const [isShowFilterUI, setIsShowFilterUI] = useState(true)

    const searchChange = (event) => {
        setIsShowHistory(true)
        const key = event.target.value
        setKeyword(key)
    }

    // 筛选词点击
	// index 外层标签 item 的索引
	// value 具体标签的名称
	const filterCardClick = (index, value) => {
		// 拷贝 filterData, 以增加 selected 属性,表示当前项已选择的标签数组
		let filterDataArr = [...filterData];
		if (filterDataArr[index].key === 'data_source') {
			if (value === '') {
				filterDataArr[index].selected = [];
			} else {
				filterDataArr[index].selected = [value];
			}
		} else {
			let selected = filterDataArr[index].selected || [];
			if (value === '') {
				selected = [];
			} else {
				if (selected.includes(value)) {  // 重复点击移除选中状态, 数据来源为什么不做这个功能?
					let i = selected.indexOf(value);
					selected.splice(i, 1);
				} else {
					selected.push(value);
				}
			}
			// 增加 selected 属性
			filterDataArr[index].selected = selected;
		}
        let flag = true;
        filterDataArr.forEach(item => {
            if(item.hasOwnProperty('selected') && item.selected.length>=1 && flag) {
                flag = false
            }
        })
        flag === true ? setIsShowClear(false) : setIsShowClear(true)
        setFilterData(filterDataArr)
        filterDataChange(filterDataArr)
	};

    const clearCurrentAll= () => {
        let filterDataArr = [...filterData];
        filterDataArr.forEach(item => {
            if(item.selected) {
                item.selected = []
            }
        })
        setFilterData(filterDataArr)
        filterDataChange(filterDataArr)
        setIsShowClear(false)
    }

    // 商券和分析师点击更多
	// name: 券商和分析师
	// key：券商和分析师对应key
	// source：资料来源
	const handleMore = (name, key, source) => {
		// 向子组件传递的props
		let cProps = {}
		if (name == '券商') {
			cProps.name = name
			cProps.filter = key
			cProps.source = source

			// this.setState({
			// 	brokersVisible: true,
			// 	brokersProps: cProps
			// })
		} else if (name == '分析师') {
			cProps.name = name
			cProps.filter = key
			cProps.source = source

			// this.setState({
			// 	analystVisible: true,
			// 	analystProps: cProps
			// })
		}

	}

    // 数据筛选部分
    const filterUI = ({ name, key, selected, values, more, source }, index) => {
        selected = selected || [];
        return (
            <div key={key} className="main-search-filter-item">
                {/* 标签名称 */}
                <span className="filter-item-label">{name}：</span>

                {/* 标签列表 */}
                <ul className="filter-item-list">
                    <li className={selected.length === 0 ? "filter-item-card selected" : "filter-item-card"}
                        onClick={() => filterCardClick(index, '')}
                        key="list_all">全部
                    </li>
                    {
                        values.map((label) => {
                            return <li
                                onClick={() => filterCardClick(index, label)}
                                className={selected.includes(label) ? "filter-item-card selected" : "filter-item-card"}
                                key={label}>{label}</li>
                        })
                    }
                    {
                        name == '券商' ? nonDefaultBrokersTags.map(val => {
                            return <li
                                onClick={() => this.delNonDefualtBrokerTag(val)}
                                className="filter-item-card selected del-icon"
                                key={nanoid()}>{val}</li>
                        }) : null
                    }
                    {
                        name == '分析师' ? nonDefaultAnalystTags.map(val => {
                            return <li
                                onClick={() => this.delNonDefualtAnalystTag(val)}
                                className="filter-item-card selected del-icon"
                                key={nanoid()}>{val}</li>
                        }) : null
                    }


                    {more === 1 ? <li className='filter-item-card filter-item-card-more' onClick={handleMore(name, key, source)}>更多</li> : null}
                </ul>
            </div>
        )
    };

    return (
        <div className={isShowFilterUI ? "advanced-search advanced-search-content": "advanced-search advanced-search-wrapper"}>
            {
                isShowFilterUI && filterData.map((item, index) => {
                    return filterUI(item, index)
                })
            }
            <ul className='main-search-filter-item current-selected-wrapper'>
                <span className="filter-item-label">当前选择：</span>
                {
                    filterData.map((item,index) => {
                        if(item.selected) {
                            return item.selected.length === 1 ? <li className='filter-item-current-selected' key={item.selected[0]} onClick={() => filterCardClick(index,item.selected[0])}>{item.selected[0]}</li> : item.selected.map((value) => {
                                return <li className='filter-item-current-selected' key={value} onClick={() => filterCardClick(index,value)}>{value}</li>
                            })
                        }
                    })
                }
                {
                    isShowClear && <span className='filter-item-clear-selected' onClick={() => clearCurrentAll()}>清空</span>
                }
                <span className={isShowFilterUI ? 'pack pack-up' : 'pack pack-down'} onClick={() => setIsShowFilterUI(!isShowFilterUI)}>{isShowFilterUI ? '收起':'展开'}</span>
            </ul>
        </div>
    )
}

export default AdvancedSearchContent