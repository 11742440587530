import React from 'react'
import '../Index.scss'
import { Input, Select, DatePicker, Table, Button, Icon, Spin, message, List, Checkbox, Modal, Empty } from 'antd'
import { webSdk } from "../../../api/WebSdk";
import { MultiCascader } from 'rsuite';
import 'rsuite/dist/styles/rsuite.min.css'
import queryString from 'query-string'
import moment from 'moment';
import AdvancedSearch from '../../common/AdvancedSearch/AdvancedSearch'
import AdvancedSearchContent from '../../common/AdvancedSearchContent/AdvancedSearchContent'

const { Search } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;

const CheckboxGroup = Checkbox.Group;


//向上查找组织树数据
function formatSelectData(id, initData, resultData, currentArr) {

    for (let i = 0; i < initData.length; i++) {
        if (initData[i].value === id) {
            let newArr = []
            if (currentArr && currentArr.length > 0) {
                newArr = [...currentArr]
            }
            newArr.push(id);
            resultData.push(newArr)
            break;
        } else {
            if (initData[i].children && initData[i].children.length > 0) {
                let newArr = []
                if (currentArr && currentArr.length > 0) {
                    newArr = [...currentArr]
                }
                newArr.push(initData[i].value);
                formatSelectData(id, initData[i].children, resultData, newArr)
            }
        }
    }
}

//删除子集
function removeArrChild(result) {
    let copyResult = [...result];
    for (let i = 0; i < result.length; i++) {
        for (let j = 0; j < copyResult.length; j++) {
            let ri = result[i].join(',');
            let rj = copyResult[j].join(',');
            if (ri !== rj) {
                let flag = false;
                flag = copyResult[j].every(val => result[i].includes(val))
                if (flag) {
                    result.splice(i, 1);
                    i = i - 1;
                    break;
                }
            }
        }
    }
}

let queryTheme = '[]';             //当前分类请求数据，用于刷新页面的请求

class ResearchLibrary extends React.PureComponent {

    constructor(props) {
        super(props)

        let searchData = props.location.search || '';
        let page = 1,
            pageSize = 10,
            theme = [],
            themeType = '1',
            company = '',
            date = [],
            showDate = [];
        queryTheme = '[]'
        if (searchData) {
            page = JSON.parse(queryString.parse(searchData)['page'] || '1');
            pageSize = parseInt(queryString.parse(searchData)['pageSize'] || '10');
            theme = JSON.parse(queryString.parse(searchData)['theme'] || '[]');
            themeType = queryString.parse(searchData)['themeType'] || '1';
            company = queryString.parse(searchData)['company'] || '';
            queryTheme = queryString.parse(searchData)['queryTheme'] || '[]';
            date = JSON.parse(queryString.parse(searchData)['date'] || '[]');
            if (date.length > 0) {
                date.forEach(item => {
                    item.replace(/-/g, '.')
                })
                showDate = [moment(date[0], 'YYYY.MM.DD'), moment(date[1], 'YYYY.MM.DD')]
            }
        }

        this.state = {
            filterSwitch: true,//条件筛选关/开
            modelSwitch: true,//模式开关：true列表模式，false摘要模式     
            listSelectItem: [],//列表选择
            keyword: props.match.params.keyword || '',
            typeList: [
                {
                    label: '公司研究',
                    value: 1
                },
                {
                    label: '行业分析',
                    value: 2
                }
            ],
            selectType: parseInt(props.match.params.type || 1),
            timeValue: showDate,
            //统计时间
            statisticalTime: {
                title: '研报日期',
                titleKey: 'statisticalTime',
                value: date
            },
            //数据来源
            dataFrom: {
                title: '来源筛选',
                titleKey: 'dataFrom',
                selectData: [],
                value: ''
            },
            //公司筛选
            company: {
                title: '公司筛选',
                titleKey: 'company',
                selectData: [],
                value: company
            },
            //行业分类标准
            industryClassification: {
                title: '行业分类标准',
                titleKey: 'industryClassification',
                selectData: [
                    {
                        label: '申万行业分类',
                        value: '1'
                    },
                    /*{
                        label: '中证指数',
                        value: '2'
                    },*/
                    {
                        label: 'CSRC标准分类',
                        value: '3'
                    }
                ],
                value: themeType
            },
            //行业分类
            industryScreen: {
                title: '行业筛选',
                titleKey: 'industryScreen',
                selectData: [],
                value: theme
            },
            //行业分类下拉数据
            classificationSelect: [],


            tableLoading: false,
            columns: [
                {
                    title: '序号',
                    dataIndex: 'index',
                    render: (text, record, index) => `${index + 1}`,
                    align: 'center',
                    width: 70
                },
                {
                    title: '研究报告标题',
                    dataIndex: 'title',
                    key: 'title',
                    render: text => text || '-'
                },
                {
                    title: '研究来源',
                    dataIndex: 'source',
                    key: 'source',
                    width: 120,
                    render: text => text || '-'
                },
                {
                    title: '研究作者',
                    dataIndex: 'author',
                    key: 'author',
                    width: 150,
                    render: text => text || '-'
                },
                {
                    title: '投资评级',
                    dataIndex: 'rate',
                    key: 'rate',
                    width: 120,
                    render: text => text || '-'
                },
                {
                    title: '研报日期',
                    dataIndex: 'date',
                    key: 'date',
                    width: 150,
                    render: text => text || '-'
                },
                {
                    title: '操作',
                    dataIndex: 'view',
                    key: 'view',
                    width: 90,
                    align: 'center',
                    render: (text, record) => {
                        return (
                            <div className="table-action-cell" onClick={() => this.viewClick(record)}>
                                查看{/*<Icon style={{marginLeft: 5}} type="eye"/>*/}
                            </div>
                        )
                    }
                }
            ],
            tableData: [],
            page: page,
            pageSize: pageSize,
            pageTotal: 0,
            selectItem: [],          //当前选中的对象
            addListSusBtn: true,//加入阅读列表按钮是否在可视区内
            industryLoading: false,
            addLoading: false,
            selections: [],
            isShowAdvancedSearch: false,
            projectList: [],
            isShowProjectModal: false,
            projectId: -1,
            filterData: []
        }

        this.getTableData = this.getTableData.bind(this);
        this.getIndustryList = this.getIndustryList.bind(this);

        this.changType = this.changType.bind(this);
        this.changeTime = this.changeTime.bind(this);
        this.changeSelectDataFrom = this.changeSelectDataFrom.bind(this);
        this.changeSelectClass = this.changeSelectClass.bind(this);
        //this.changeCascaderSelect = this.changeCascaderSelect.bind(this);
        this.pageChange = this.pageChange.bind(this);
        this.pageSizeChange = this.pageSizeChange.bind(this);
        this.viewClick = this.viewClick.bind(this);

        this.changeMulSelect = this.changeMulSelect.bind(this)
        this.mulSelectSearch = this.mulSelectSearch.bind(this)

        this.fetch = this.fetch.bind(this);
        // this.companySearch = this.companySearch.bind(this);
        // this.companyChange = this.companyChange.bind(this);
        this.searchByKeyword = this.searchByKeyword.bind(this)
        this.searchClick = this.searchClick.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
        this.handleOk = this.handleOk.bind(this)
        this.changeFilterData = this.changeFilterData.bind(this)
    }

    componentDidMount() {
        this.props.fnSetType('research', this.state.keyword || '')
        window.document.addEventListener("scroll", this.listerScroll.bind(this))
        this.getDataFromList();
        let pathname = this.props.location.pathname;
        if (pathname === '/research//1') {
            this.setState({
                selectType: 1
            }, () => {
                this.getTableData();
                if (this.state.selectType === 1) {
                    this.getClassificationList();
                }
                else {
                    this.getIndustryList()
                }
            })
        } else if (pathname === '/research//2') {
            this.setState({
                selectType: 2
            }, () => {
                this.getTableData();
                if (this.state.selectType === 1) {
                    this.getClassificationList();
                }
                else {
                    this.getIndustryList()
                }
            })
        } else {
            this.getTableData();
            this.getIndustryList()
        }
        let icons = document.querySelectorAll('.rs-picker-toggle-caret')
        icons.forEach(item => {
            item.innerHTML = `<i aria-label="图标: down" style="color: rgba(0, 0, 0, 0.25);transform: scale(0.8576)" class="anticon anticon-down ant-select-arrow-icon"><svg viewBox="64 64 896 896" class="" data-icon="down" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path></svg></i>`
        })
        let postData = {
            proj_id: ''
        };
        webSdk.search.queryProjectList(postData).then(res => {
            this.setState({
                projectList: res || []
            })
        })
        webSdk.search.queryProjectFilter({type: 'yanbao'}).then(res => {
            let data = res || [];
            this.setState({
                filterData: data
            })
        })
    }

    componentWillReceiveProps(nextProps) {
        let pathname = nextProps.location.pathname;
        let selectType = 1;
        if (pathname.indexOf('/2') !== -1) {
            selectType = 2
        }
        this.setState({
            selectType
        })
        /*if ((nextProps.match.params.keyword!==undefined && this.state.keyword && nextProps.match.params.keyword !== this.state.keyword) || (nextProps.match.params.keyword===undefined && this.state.keyword)) {
            console.log("11")
            this.setState({
                keyword: nextProps.match.params.keyword || '',
                page: 0,
                pageTotal: 0
            }, () => {
                this.getTableData()
            })
        }*/
        if (this.props.location.search !== nextProps.location.search || this.props.location.pathname !== pathname) {
            let searchData = nextProps.location.search || '';
            let npage = 1,
                npageSize = 10,
                ntheme = [],
                nthemeType = '1',
                ncompany = '',
                ndate = [],
                nshowDate = [];
            queryTheme = '[]';
            if (searchData) {
                npage = JSON.parse(queryString.parse(searchData)['page'] || '1');
                npageSize = parseInt(queryString.parse(searchData)['pageSize'] || '10');
                ntheme = JSON.parse(queryString.parse(searchData)['theme'] || '[]');
                nthemeType = queryString.parse(searchData)['themeType'] || '1';
                ncompany = queryString.parse(searchData)['company'] || '';
                queryTheme = queryString.parse(searchData)['queryTheme'] || '[]';
                ndate = JSON.parse(queryString.parse(searchData)['date'] || '[]');
                if (ndate.length > 0) {
                    ndate.forEach(item => {
                        item.replace(/-/g, '.')
                    })
                    nshowDate = [moment(ndate[0], 'YYYY.MM.DD'), moment(ndate[1], 'YYYY.MM.DD')]
                }
            }
            let { statisticalTime, company, industryClassification, industryScreen, page, pageSize } = this.state;
            statisticalTime.value = ndate;
            company.value = ncompany;
            industryClassification.value = nthemeType;
            industryScreen.value = ntheme;
            page = npage;
            pageSize = npageSize;

            this.setState({
                timeValue: nshowDate,
                statisticalTime, company, industryClassification, industryScreen, page, pageSize
            }, () => {
                this.getTableData();
            })
        }
    }

    componentWillUnmount() {
        // 卸载异步操作设置状态
        this.setState = (state, callback) => {
            window.document.removeEventListener("scroll", this.listerScroll.bind(this))
            return;
        }
    }

    changeLocation = () => {
        let themeSelect = [...this.state.industryScreen.value];
        let result = [];
        if (themeSelect.length > 0) {
            if (this.state.classificationSelect.length > 0) {
                for (let i = 0; i < themeSelect.length; i++) {
                    formatSelectData(themeSelect[i], this.state.classificationSelect, result)
                }
                removeArrChild(result)
            }
        }
        queryTheme = JSON.stringify(result)
        console.log(queryTheme)

        let searchData = '';
        if (this.state.selectType === 1) {      //公司筛选
            let date = [];
            let statisticalTime = { ...this.state.statisticalTime };
            if (statisticalTime.value.length === 2 && statisticalTime.value[0] && statisticalTime.value[1]) {
                date = [(statisticalTime.value[0] || '').replace(/\./g, '-'), (statisticalTime.value[1] || '').replace(/\./g, '-')]
            }
            searchData = `?page=${this.state.page}&pageSize=${this.state.pageSize}&themeType=${this.state.industryClassification.value}&theme=${JSON.stringify(themeSelect)}&queryTheme=${queryTheme}&date=${JSON.stringify(date)}`
            /*searchData = `?page=${this.state.page}&pageSize=${this.state.pageSize}&themeType=${this.state.industryClassification.value}&theme=${JSON.stringify(themeSelect)}&queryTheme=${queryTheme}&company=${this.state.company.value}&date=${JSON.stringify(date)}`*/
        }
        else if (this.state.selectType === 2) {     //行业筛选
            searchData = `?page=${this.state.page}&pageSize=${this.state.pageSize}&theme=${JSON.stringify(themeSelect)}&queryTheme=${queryTheme}&date=${JSON.stringify(this.state.statisticalTime.value)}`
        }
        this.props.history.push({
            pathname: `${this.props.location.pathname}`,
            search: searchData
        })
    }

    /**监控滚动条 */
    listerScroll(event) {
        let currentTarget = event.target
        let addListBtn = currentTarget.querySelector('div#addListBtn');
        let addListSusBtn = this.state.addListSusBtn

        if (addListBtn && addListBtn.getBoundingClientRect().top < 120) {
            addListSusBtn = false
        }

        if (addListBtn && addListBtn.getBoundingClientRect().top >= 120) {
            addListSusBtn = true
        }

        this.setState({
            addListSusBtn
        })
    }

    /**
     * 获取数据来源
     */
    getDataFromList() {
        let postData = {
            keyword: this.state.keyword,
            type: 1
        }
        webSdk.search.getResearchSourceList(postData).then(res => {
            try {
                if (!res.errCode || res.errCode === 0) {
                    let dataFrom = { ...this.state.dataFrom };
                    dataFrom.selectData = res;
                    this.setState({
                        dataFrom
                    })
                } else {
                    message.error(res.errMsg || '获取数据来源列表失败，请稍后重试');
                }
            } catch (e) {
                message.error('获取数据来源列表失败，请稍后重试');
            }
        }).catch(e => {
            message.error('获取数据来源列表失败，请稍后重试');
        })
    }

    //获取行业分析 行业筛选
    getIndustryList() {
        this.setState({
            classificationSelect: [],
            industryLoading: true
        }, () => {
            let postData = {
                keyword: this.state.keyword,
                type: 2
            }
            webSdk.search.getIndustryList(postData).then(res => {
                try {
                    if (!res.errCode || res.errCode === 0) {
                        for (let i = 0; i < res.length; i++) {
                            renameData(res[i]);
                        }
                        this.setState({
                            classificationSelect: res,
                            industryLoading: false
                        })
                    } else {
                        this.setState({
                            industryLoading: false
                        })
                        message.error(res.errMsg || "获取行业分类列表失败")
                    }
                } catch (e) {
                    this.setState({
                        industryLoading: false
                    })
                    console.log(e)
                    message.error("获取行业分类列表失败")
                }
            }).catch(e => {
                this.setState({
                    industryLoading: false
                })
                message.error("获取行业分类列表失败")
            })
        })

        function renameData(data) {
            data.label = data.text;
            delete data.text;
            if (data.child && data.child.length > 0) {
                data.children = data.child;
                delete data.child;
                for (let i = 0; i < data.children.length; i++) {
                    renameData(data.children[i])
                }
            }
        }
    }

    /**
     * 获取表格信息
     */
    getTableData() {
        let { filterData } = this.state
        let art_source = '',
            art_author = '',
            query_type = '';
        if(filterData.length > 0) {
            for(let i = 0; i< filterData.length; i++) {
                if(filterData[i].key === 'query_type' && filterData[i].hasOwnProperty('selected') && filterData[i].selected.length > 0) {
                    query_type = filterData[i].selected.join(',')
                }else if(filterData[i].key === 'org_name' && filterData[i].hasOwnProperty('selected') && filterData[i].selected.length > 0) {
                    art_source = filterData[i].selected.join(',')
                }else if(filterData[i].key === 'author' && filterData[i].hasOwnProperty('selected') && filterData[i].selected.length > 0) {
                    art_author = filterData[i].selected.join(',')
                }
            }
        }
        let postData = {
            art_type: this.state.selectType ===1 ?  "company" : "industry",
            //type: this.state.selectType,       //查询类型：1.企业研报2.行业研报
            keyword: this.state.keyword,
            //source: this.state.dataFrom.value,
            page_size: this.state.pageSize,
            page: this.state.page,
            art_source: art_source,
            art_author: art_author,
            query_type: query_type,
            start_date: (this.state.statisticalTime.value[0] || '').replace(/\./g, '-'),
            end_date: (this.state.statisticalTime.value[1] || '').replace(/\./g, '-'),
            theme_id: queryTheme.replace(/\[|]/g,'')
        }
        this.setState({
            tableLoading: true
        }, () => {
            webSdk.search.advLocalSearch(postData).then(res => {
                try {
                    if (res.status === 200) {
                        let pageTotal = res.data.pageNum,
                            tableData = res.data.list;
                        for (let i = 0; i < tableData.length; i++) {
                            //let abstract = tableData[i].sumary || '';
                            let abstract = tableData[i].sumary || tableData[i].abstract || '';
                            let newAbstract = abstract.split('。');
                            if (newAbstract.length > 3) {
                                abstract = newAbstract.splice(0, 3).join('。') + '...'
                            }
                            tableData[i].abstract = abstract
                        }
                        this.setState({
                            pageTotal, tableData,
                            tableLoading: false
                        })
                    } else {
                        this.setState({
                            tableLoading: false
                        })
                        message.error(res.errMsg || '获取研报列表失败，请稍后重试');
                    }
                } catch (e) {
                    this.setState({
                        tableLoading: false
                    })
                    message.error('获取研报列表失败，请稍后重试');
                }
            }).catch(e => {
                this.setState({
                    tableLoading: false
                })
                message.error('获取研报列表失败，请稍后重试');
            })
        })

    }

    /**
     * 获取公司研究类目下 行业分类列表
     */
    getClassificationList() {
        this.setState({
            classificationSelect: [],
            industryLoading: true
        }, () => {
            let postData = {
                type: this.state.industryClassification.value
            }
            webSdk.search.getClassificationList(postData).then(res => {
                try {
                    if (!res.errCode || res.errCode === 0) {
                        for (let i = 0; i < res.length; i++) {
                            renameData(res[i]);
                        }
                        this.setState({
                            classificationSelect: res,
                            industryLoading: false
                        })
                    } else {
                        this.setState({
                            industryLoading: false
                        })
                        message.error(res.errMsg || "获取行业分类列表失败")
                    }
                } catch (e) {
                    this.setState({
                        industryLoading: false
                    })
                    console.log(e)
                    message.error("获取行业分类列表失败")
                }
            }).catch(e => {
                this.setState({
                    industryLoading: false
                })
                message.error("获取行业分类列表失败")
            })
        })


        function renameData(data) {
            data.label = data.text;
            delete data.text;
            if (data.child && data.child.length > 0) {
                data.children = data.child;
                delete data.child;
                for (let i = 0; i < data.children.length; i++) {
                    renameData(data.children[i])
                }
            }
        }
    }

    /**
     * 更改类型
     */
    changType(val) {
        if (val !== this.state.selectType) {
            this.props.history.push({
                pathname: `/research/${this.state.keyword}/${val}`
            })

            let statisticalTime = { ...this.state.statisticalTime },
                timeValue = [],
                dataFrom = { ...this.state.dataFrom },
                industryClassification = { ...this.state.industryClassification },
                industryScreen = { ...this.state.industryScreen };
            statisticalTime.value = [];
            dataFrom.value = '';
            industryClassification.value = '1';
            industryScreen.value = [];
            this.setState({
                timeValue,
                statisticalTime,
                dataFrom,
                industryClassification,
                industryScreen,
                classificationSelect: [],
                page: 1,
                pageTotal: 0,
                selectType: val
            }, () => {
                this.getTableData();
                if (this.state.selectType === 1) {
                    this.getClassificationList();
                }
                else {
                    this.getIndustryList()
                }
            })
        }
    }

    /**
     * 修改研报统计时间
     */
    changeTime(date, dateString) {
        let statisticalTime = { ...this.state.statisticalTime };
        let timeValue = date;
        statisticalTime.value = dateString;
        this.setState({
            timeValue,
            statisticalTime,
            page: 1
        }, () => {
            this.changeLocation()
        })
    }

    /**
     * 修改来源筛选
     */
    changeSelectDataFrom(val) {
        let dataFrom = { ...this.state.dataFrom };
        dataFrom.value = val;
        this.setState({
            dataFrom,
            page: 1
        }, () => {
            this.changeLocation()
        })
    }

    /**
     * 修改行业分类标准
     */
    changeSelectClass(val) {
        let industryClassification = { ...this.state.industryClassification };
        industryClassification.value = val;
        let industryScreen = { ...this.state.industryScreen };
        industryScreen.value = [];
        this.setState({
            industryClassification,
            industryScreen
        }, () => {
            //this.getTableData();
            this.getClassificationList()
        })
    }

    /**
     * 修改行业筛选
     */
    // changeCascaderSelect(val) {
    //     let industryScreen = { ...this.state.industryScreen };
    //     industryScreen.value = val;
    //     this.setState({
    //         industryScreen
    //     }, () => {
    //         this.getTableData()
    //     })
    // }

    /**
     * 加入阅读列表事件
     */
    addToList() {
        let selectItem = [...this.state.selectItem];
        if (selectItem.length > 0) {
            this.setState({
                addLoading: true
            }, () => {
                let ids = [];
                for (let i = 0; i < selectItem.length; i++) {
                    ids.push(selectItem[i].id)
                }
                let postData = {
                    ids: ids.join(','),
                    type: this.state.selectType
                }
                webSdk.search.addReadList(postData).then(res => {
                    if (res.result) {
                        message.success("加入阅读列表成功");
                        this.setState({
                            addLoading: false,
                            selectItem: [],
                            selections: []
                        })
                    } else {
                        this.setState({
                            addLoading: false
                        })
                        message.error(res.errMsg || "加入阅读列表失败,请稍后重试")
                    }
                }).catch(e => {
                    this.setState({
                        addLoading: false
                    })
                    message.error("加入阅读列表失败,请稍后重试")
                })
            })
        }

    }

    /**
     * 页码变更
     */
    pageChange(page, pageSize) {
        this.setState({
            page: page
        }, () => {
            this.changeLocation()
        })
    }

    /**
     * 页尺寸变更
     */
    pageSizeChange(current, size) {
        this.setState({
            page: 1,
            pageSize: size
        }, () => {
            this.changeLocation()
        })
    }

    /**
     * 表格查看事件
     */
    viewClick(record) {
        let selectType = this.state.selectType
        this.props.history.push({
            pathname: `/researchResult/${selectType}/${record.id}/${selectType}`
        })
    }

    //列表 阅读全文 点击事件
    viewClickList(id) {
        this.viewClick({ id: id })
    }

    industryHasChange = false

    changeMulSelect(valueList) {
        if (!this.industryHasChange) {
            this.industryHasChange = true;
            let industryScreen = { ...this.state.industryScreen };
            industryScreen.value = valueList;
            this.setState({
                industryScreen
            })
        } else {
            this.industryHasChange = false;
            let industryScreen = { ...this.state.industryScreen };
            industryScreen.value = valueList;
            this.setState({
                industryScreen,
                page: 1
            }, () => {
                this.changeLocation();
            })
        }
    }

    mulSelectSearch() {
        if (this.industryHasChange) {
            this.setState({
                page: 1
            }, () => {
                this.changeLocation();
            })

            this.industryHasChange = false;
        }
    }

    clearIndustry() {
        this.industryHasChange = true;
    }

    //改变条件筛选开关
    changeFilterSwitch() {
        this.setState({
            filterSwitch: !this.state.filterSwitch
        })
    }

    //改变模式开关
    changeModelSwitch() {
        let modelSwitch = !this.state.modelSwitch
        this.setState({
            modelSwitch
        })
    }

    //列表 多选框 事件
    changeSelectList(checkedValues) {
        this.setState({
            selectItem: checkedValues
        })
    }

    timeout = null

    fetch(value, callback) {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
        this.currentValue = value;

        function fake() {
            //请求下拉数据
            setTimeout(() => {
                let data = [
                    // {
                    //     label: '下拉数据一',
                    //     value: '1'
                    // },
                    // {
                    //     label: '下拉数据二',
                    //     value: '2'
                    // },
                    // {
                    //     label: '下拉数据三',
                    //     value: '3'
                    // },
                    // {
                    //     label: '下拉数据四',
                    //     value: '4'
                    // }
                ];
                callback(data);
            }, 800)
            // const str = querystring.encode({
            //     code: 'utf-8',
            //     q: value,
            // });
            // jsonp(`https://suggest.taobao.com/sug?${str}`)
            //     .then(response => response.json())
            //     .then(d => {
            //         if (currentValue === value) {
            //             const {result} = d;
            //             const data = [];
            //             result.forEach(r => {
            //                 data.push({
            //                     value: r[0],
            //                     text: r[0],
            //                 });
            //             });
            //             callback(data);
            //         }
            //     });
        }

        this.timeout = setTimeout(fake, 300);
    }

    /** 公司筛选 */
    companyChange = (ev) => {
        let company = { ...this.state.company };
        company.value = ev.target.value;
        this.setState({
            company
        })
    }

    /** 公司搜索 */
    // companySearch = value => {
    //     this.getTableData();
    // }

    // 高级搜索筛选词改变
    changeFilterData(data) {
        this.setState({
            filterData: data
        })
    }

    //  搜索关键词
    searchByKeyword(keyword) {
        this.setState({
            keyword: keyword,
            page: 1
        }, () => {
            this.getTableData();
        })
    }

    searchClick() {
        this.setState({
            isShowAdvancedSearch: true
        })
    }

    handleOk() {
        let selectItem = [...this.state.selectItem];
        if (selectItem.length > 0) {
            this.setState({
                addLoading: true
            }, () => {
                let postData = {
                    article: []
                }
                for (let i = 0; i < selectItem.length; i++) {
                    let articleObj = {}
                    let type = selectItem[i].route.substring(selectItem[i].route.lastIndexOf(".")+1)
                    articleObj.title = selectItem[i].title
                    articleObj.doc_type = type
                    if(type === 'PDF' || type === 'pdf') {
                        articleObj.file_content = ''
                    }
                    articleObj.doc_url = selectItem[i].route
                    articleObj.publish_time = selectItem[i].date
                    postData.article.push(articleObj)
                }
                webSdk.search.createDoc(postData,this.state.projectId).then(res => {
                    if (res.result) {
                        message.success("加入项目成功");
                        this.setState({
                            addLoading: false,
                            isShowProjectModal: false,
                            selectItem: [],
                            selections: []
                        })
                    } else {
                        this.setState({
                            addLoading: false,
                            isShowProjectModal: false
                        })
                        message.error(res.errMsg || "加入项目失败,请稍后重试")
                    }
                }).catch(e => {
                    this.setState({
                        addLoading: false,
                        isShowProjectModal: false
                    })
                    message.error("加入项目失败,请稍后重试")
                })
            })
        }

    }

    handleCancel() {
        this.setState({
            isShowProjectModal: false,
            selectItem: [],
            selections: []
        })
    }

    render() {
        const rowSelection = {
            columnWidth: 50,
            selectedRowKeys: this.state.selections,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selections: selectedRowKeys,
                    selectItem: selectedRows
                })
            },
        };

        const Hearder = (
            <div className="research-library-header">
                {this.state.typeList.map((item, index) => {
                    return (
                        <div onClick={() => this.changType(item.value)}
                            className={this.state.selectType === item.value ? "select-item research-header-item" : "research-header-item"}
                            key={item.value}>{item.label}</div>
                    )
                })}
            </div>
        )

        return (
            <div className="home-page__research-library" style={{ paddingTop: 52 }}>
                {Hearder}
                <div className="company-list__industry-screen__main">
                    {
                        this.state.selectType === 1 ? (
                            <>
                                <div className="company-list__industry-screen__item">
                                    <div
                                        className="company-list__industry-screen__item-label">{this.state.industryClassification.title}</div>
                                    <div className="company-list__industry-screen__item-input">
                                        <Select style={{ width: 160, height: 30 }}
                                            value={this.state.industryClassification.value ? this.state.industryClassification.value : undefined}
                                            onChange={(val) => this.changeSelectClass(val)}
                                            placeholder="请选择行业分类标准">
                                            {
                                                this.state.industryClassification.selectData.map((item, index) => {
                                                    return (
                                                        <Option value={item.value} key={index}>{item.label}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </div>
                                </div>
                            </>
                        ) : null
                    }


                    <div className="company-list__industry-screen__item">
                        <div
                            className="company-list__industry-screen__item-label">{this.state.industryScreen.title}</div>
                        <div className="company-list__industry-screen__item-input">
                            <MultiCascader cascade={false}
                                onChange={(vals, ev) => {
                                    console.log('change')
                                    this.changeMulSelect(vals)
                                }}
                                onClose={() => {
                                    console.log('close')
                                    this.mulSelectSearch()
                                }}
                                onClean={(ev) => {
                                    console.log('clear')
                                    this.clearIndustry()
                                }}
                                placeholder="请选择行业分类"
                                style={{ width: 160 }}
                                menuClassName="mult-select-menu"
                                data={this.state.classificationSelect}
                                value={this.state.industryScreen.value}
                                menuWidth={160}
                                block />
                        </div>
                    </div>

                    <div className="company-list__industry-screen__item">
                        <div
                            className="company-list__industry-screen__item-label">{this.state.statisticalTime.title}</div>
                        <div className="company-list__industry-screen__item-input">
                            <RangePicker style={{ width: 160, height: 30 }} format={'YYYY.MM.DD'}
                                placeholder={['年-月-日', '年-月-日']}
                                onChange={(date, dateString) => this.changeTime(date, dateString)}
                                value={this.state.timeValue} />
                        </div>
                    </div>

                    <AdvancedSearch searchByKeyword={this.searchByKeyword}  searchClick={this.searchClick} />

                    {/*{
                        this.state.selectType === 1 ? (
                            <div className="company-list__industry-screen__item">
                                <div
                                    className="company-list__industry-screen__item-label">{this.state.company.title}</div>
                                <div className="company-list__industry-screen__item-input">
                                    <Search placeholder="请输入公司名称"
                                            style={{ width: 160, height: 30 }}
                                            value={this.state.company.value || undefined}
                                            onChange={ev => this.companyChange(ev)}
                                            onSearch={value => this.companySearch(value)}/>
                                </div>
                            </div>
                        ) : null
                    }*/}

                </div>

                {
                    this.state.isShowAdvancedSearch && <AdvancedSearchContent data={this.state.filterData} filterDataChange={this.changeFilterData} />
                }

                <div className="company-list__table-main pt20">
                    <div className={!this.state.addListSusBtn && this.state.selectItem.length > 0 ? "table-main-list add-btn-suspend" : "table-main-list"}>
                        <Button type="primary"
                            loading={this.state.addLoading}
                            disabled={this.state.selectItem.length === 0}
                            onClick={() => this.setState({isShowProjectModal: true})}
                            style={{ height: 30, padding: "0 15px", fontSize: 12, verticalAlign: "middle", marginRight: '20px' }}

                        >添加到我的项目<Icon type="plus" /></Button>
                        <Button type="primary"
                            loading={this.state.addLoading}
                            disabled={this.state.selectItem.length === 0}
                            onClick={this.addToList.bind(this)}
                            style={{ height: 30, padding: "0 15px", fontSize: 12, verticalAlign: "middle" }}>
                            加入阅读列表<Icon type="plus" />
                        </Button>
                    </div>
                    <div id="addListBtn" style={{ width: "10px", height: "20px", display: "inline-block", }} />

                    <div className="table-main-model">
                        <Button type="primary"
                            onClick={this.changeModelSwitch.bind(this)}
                            style={{ width: 88, height: 30, padding: 0, fontSize: 12 }}>
                            {this.state.modelSwitch ? "摘要模式" : "列表模式"}
                            <Icon type="appstore" />
                        </Button>
                    </div>
                    <Spin spinning={this.state.tableLoading}>
                        <div>
                            {
                                this.state.modelSwitch ?
                                    (
                                        <Table
                                            columns={this.state.columns}
                                            dataSource={this.state.tableData}
                                            rowSelection={rowSelection}
                                            rowClassName={(record, index) => index % 2 === 0 ? 'single-row' : 'double-row'}
                                            rowKey={(record, index) => {
                                                return index
                                            }}
                                            /*onRow={(record, index) => {
                                                return {
                                                    onClick: ev => this.viewClick(record)
                                                }
                                            }}*/
                                            pagination={{
                                                showSizeChanger: true,
                                                showQuickJumper: true,
                                                pageSizeOptions: ["10", "15", "20", "25"],
                                                current: this.state.page,
                                                pageSize: this.state.pageSize,
                                                total: this.state.pageTotal,
                                                onChange: (page, pageSize) => this.pageChange(page, pageSize),
                                                onShowSizeChange: (current, size) => this.pageSizeChange(current, size),
                                                showTotal: total => `共 ${total} 条`
                                            }}
                                        />
                                    ) :
                                    (
                                        <CheckboxGroup
                                            onChange={this.changeSelectList.bind(this)}
                                        >
                                            <List
                                                className="summary-list"
                                                itemLayout="vertical"
                                                size="large"
                                                pagination={{
                                                    showSizeChanger: true,
                                                    showQuickJumper: true,
                                                    pageSizeOptions: ["10", "15", "20", "25"],
                                                    current: this.state.page,
                                                    pageSize: this.state.pageSize,
                                                    total: this.state.pageTotal,
                                                    onChange: (page, pageSize) => this.pageChange(page, pageSize),
                                                    onShowSizeChange: (current, size) => this.pageSizeChange(current, size),
                                                    showTotal: total => `共 ${total} 条`
                                                }}
                                                dataSource={this.state.tableData}
                                                renderItem={(item, idx) => (
                                                    <List.Item
                                                        key={idx}
                                                        className="summary-list-item"
                                                    >
                                                        <Checkbox
                                                            className="summary-list-chk"
                                                            options={this.state.listSelectItem}
                                                            value={item}
                                                        >
                                                            <span className="summary-chk-title">{item.title}</span>
                                                        </Checkbox>

                                                        <ul className="summary-chk-label">
                                                            {
                                                                item.artTags && item.artTags.length > 0 ? item.artTags.map(list => {
                                                                    return (
                                                                        <li key={list.id}>{list.name}</li>
                                                                    )
                                                                }) : null
                                                            }
                                                        </ul>

                                                        <p className="summary-chk-content">{item.abstract}</p>

                                                        <ul className="summary-chk-annotation">
                                                            <li>{item.source}</li>
                                                            <li>{item.author}</li>
                                                            <li>{item.date}</li>
                                                            <li><a
                                                                onClick={this.viewClickList.bind(this, item.id)}>阅读全文</a>
                                                            </li>
                                                        </ul>
                                                    </List.Item>
                                                )}
                                            />
                                        </CheckboxGroup>
                                    )
                            }
                        </div>
                    </Spin>

                </div>
                <Modal className='project-modal' width={400} title="将“已选文档”添加到…" visible={this.state.isShowProjectModal} onCancel={this.handleCancel} footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            取消
                        </Button>,
                        <Button key="submit" style={{backgroundColor:'#5B6BAE', color:'#fff'}} onClick={this.handleOk}>
                            添加
                        </Button>
                    ]}>
                    <ul className='project-wrapper'>
                        {
                            this.state.projectList.length > 0 ? this.state.projectList.map((item) => {
                                return <li key={item.proj_id} onClick={() => this.setState({projectId: item.proj_id})} className={item.proj_id === this.state.projectId ? "active" : null }>{item.proj_name}</li>
                            }):
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        }
                    </ul>
                </Modal>
            </div>)
    }
}

export default ResearchLibrary