import React from 'react';
import {Icon} from 'antd';
import {setOpen} from "./utils";

function OpenIcon(props) {
    const {open, title, type} = props;
    return (
        <>
            <h3 className="home-content-title"
                style={open ? {marginTop: 0} : {marginTop: 35, display: 'flex', justifyContent: 'center'}}>{title}</h3>
            <div className="open-icon" style={open ? {right: 16} : {right: 10}} onClick={() => setOpen(type)}>
                {open ? <Icon type="shrink"/> : <Icon type="arrows-alt"/>}
            </div>
        </>
    )
}

export default OpenIcon
