/**
 * @author huanghw
 * @email huanghongwu@sendbp.com
 * @create date 2019-03-11 11:56:46
 * @modify date 2019-03-11 11:56:46
 * @desc App内的页面共享数据
 */
import React from 'react'

const AppContext = React.createContext({
    getHistory: ()=>{}
})

export default AppContext