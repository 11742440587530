import React, { Component } from 'react';
import AppContext from '../../../../AppContext'
import { Spin, Empty, Descriptions, Button } from 'antd';
import stores from "../../../../store";
import { observer } from 'mobx-react';
import moment from 'moment';
import Link from '../../../../assets/svg/link.svg'

class RegulationFallDetailArticle extends Component {
    static contextType = AppContext

    constructor(props) {
        super(props);
        this.state = {
            articleId: props.articleId,
            loading: false,
            content: "",
            policy: {}
        }
    }

    componentWillReceiveProps(nextState) {
        let { articleId } = this.state
        let newArticleId = nextState.articleId
        if (articleId !== newArticleId) {
            this.setState({
                articleId: newArticleId
            }, () => {
                this.getContent();
            })
        }
    }

    // 获取内容
    getContent() {
        let { articleId } = this.state
        if (articleId == null || articleId == undefined) return;

        this.setState({
            loading: true
        }, () => {
            webSdk.policyApi.getArticleContent(articleId).then(res => {
                console.log(222222222222)
                console.log(res.data.policy)

                let content = res.data.policy.content;

                this.setState({
                    loading: false,
                    content,
                    policy: res.data.policy
                })

            }).catch(e => {
                this.setState({
                    loading: false
                })
            });
        })
    };

    openLink = (link) => {
        const w=window.open('about:blank');
        w.location.href= link
    }

    render() {
        let { loading, content, policy } = this.state

        return (
            <div className="regulation-fall-detail-html">
                <Spin spinning={loading}>
                    <h2 className='html-title'>{policy.title}</h2>
                    <Descriptions>
                        {
                            policy.publish_year ? <Descriptions.Item>发布年份：{policy.publish_year}</Descriptions.Item> : null
                        }
                        {
                            policy.source_url ? <Descriptions.Item>文本来源：<Button onClick={() => this.openLink(policy.source_url)}>网页链接&nbsp;<img src={Link} alt=''/></Button></Descriptions.Item> : null
                        }
                        {
                            policy.file_number ? <Descriptions.Item>发文字号：{policy.file_number}</Descriptions.Item> : null
                        }
                        {
                            policy.publish_date ? <Descriptions.Item>发布日期：{moment(policy.publish_date).format("YYYY-MM-DD")}</Descriptions.Item> : null
                        }
                        {
                            policy.announcement_type ? <Descriptions.Item>公文类型：{policy.announcement_type}</Descriptions.Item> : null
                        }
                        {
                            policy.author ? <Descriptions.Item>发文机关：{policy.author}</Descriptions.Item> : null
                        }
                        {
                            policy.keywords && policy.keywords.length !== 0 ? <Descriptions.Item>关键词：{policy.keywords.map((item,index) => { return <span key={index}>"{item}"{index === policy.keywords.length -1 ? '':','}</span>})}</Descriptions.Item>:null
                        }
                    </Descriptions>
                    {
                        content ? (
                                <div className="detail-html" dangerouslySetInnerHTML={{ __html: content }} />
                        ) : (<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />)
                    }
                </Spin>
            </div>
        );
    }
}

export default RegulationFallDetailArticle;