//import _ from 'lodash'
import BaseWebApi from './BaseWebApi'

class SearchApi extends BaseWebApi{
    constructor(options) {
        super()
        this._api = this.gate.search
        this._apiV2 = this.gate.searchV2
        this._apiAida2 = this.gate.searchAida2

        this.mockData = async (url, delay) => {
            delay = (delay || 10) + _.random(0, 1) // 模拟服务端延迟
            const resp = await this.gate.fake.get(url);
            return await new Promise(resolve => {
                setTimeout(() => {
                    resolve(resp);
                }, delay);
            });
        }
    }


    //获取加密信息
    async loginKey() {
        let resp = await this._api.get('user/loginKey');
        return resp.data
    }

    //登录
    async doLogin(opts) {
        //let {username, pwd} = opts;
        let resp = await this._api.postForm('user/login', opts);
        return resp.data
    }

    //注册
    async doRegister(opts) {
        //let {username, pwd ,code ,inviteCode} = opts;
        let resp = await this._api.postForm('user/register', opts);
        return resp.data
    }

    // 邀请码
    async getInviteCode(opts) {
        //let {inviteCode} = opts;
        let resp = await this._apiV2.postForm('home/login_invite', opts);
        return resp.data
    }

    //获取登录验证码
    async getVerifyCode(opts) {
        //let {address} = opts;
        let resp = await this._api.postForm('user/sendCode', opts);
        return resp.data

    }

    //重置密码
    async resetPassword(opts) {
        //let {username, newpwd, confirmpwd, code} = opts;
        let resp = await this._api.postForm('user/resetPassword', opts);
        return resp.data
    }

    //登出
    async loginOut() {
        let resp = await this._api.postForm('user/loginOut');
        return resp.data
    }



    async login(opts) {
        return await this.TODO()
    }

    async hotKeywords() {
        return await this.TODO()
    }

    //首页搜索请求
    async searchWord(opts) {
        return await this.TODO()
    }

    async getAutoSearchWord(opts) {
        return await this.TODO()
    }

    //获取页面基本信息，数据表、广泛类型直接获取各个节点基本信息。企业模块获取顶部菜单信息
    async searchBaseInfo(opts){
        return await this.TODO()
    }

    //获取图形图像表格数据
    async getGraphData (opts) {
        return await this.TODO()
    }

    //企业信息模块获取页面基本信息---左侧菜单栏
    async getCompanyBase (opts) {
        return await this.TODO()
    }

    //获取公司模块，利润模块信息
    async getFinancialInfo (opts) {
        return await this.TODO()
    }

    async getHotWord(opts) {
        return await this.TODO()
    }
    async getHistoryWord(opts) {
        return await this.TODO()
    }
    async getIndustryAndAbstractFirst(opts) {
        return await this.TODO()
    }
    async getIndustryAndAbstractSecond(opts) {
        return await this.TODO()
    }
    async getIndustryAndAbstractThird(opts) {
        return await this.TODO()
    }
    async getAbstractReportFrom(opts) {
        return await this.TODO()
    }

    async searchListCompany(opts) {
        return await this.TODO()
    }

    async searchListData(opts) {
        return await this.TODO()
    }

    async getAnalysisData(opts) {
        return await this.TODO()
    }





    //获取任务池列表
    async getTaggingList(opts) {
        let resp = await this._api.get('tagging/taskPool')
        return resp.data
    }

    //获取任务列表
    async getTaskList(opts) {
        let {pool,pageSize,pageNum} = opts;
        let resp = await this._api.get('tagging/listTask',{
            params: {
                pool,
                pageSize,
                pageNum
            }
        })
        return resp.data
    }

    //添加任务
    async smartAddSentTask(opts) {
        let {pool,content} = opts;
        let postData = {
            pool,
            content
        }
        let resp = await this._api.get('tagging/smartAddSentTask', {
            params: postData
        })
        return resp.data
    }

    //获取任务池统计信息
    async poolStat(opts) {
        let {pool} = opts;
        let resp = await this._api.get('tagging/poolStat', {
            params:{
                pool
            }
        })
        return resp.data
    }

    //获取标注任务信息
    async fetchTask(opts) {
        let {pool,size} = opts;
        let resp = await this._api.get('tagging/fetchTask', {
            params:{
                pool,size
            }
        })
        return resp.data
    }

    //获取指定任务信息
    async getTaskById(opts) {
        let {task} = opts;
        let resp = await this._api.get('tagging/getTaskById', {
            params:{
                task
            }
        })
        return resp.data
    }

    //获取指定任务信息（用于上一步操作）
    async getMyTagging(opts) {
        let {task} = opts;
        let resp = await this._api.get('tagging/getMyTagging', {
            params:{
                task
            }
        })
        return resp.data
    }

    async submitTagging(opts) {
        let {task,content,choose} = opts;
        let postData = {
            task,
            content: JSON.stringify(content)
        }
        choose!==undefined && (postData['choose'] = choose)
        let resp = await this._api.postForm('tagging/submitTagging', postData)
        return resp.data
    }


    // 新版本接口
    async searchKnowledge(opts) {
        let resp = await this._api.postForm('search/knowledge', opts)
        return resp.data
    }

    //生成知识图谱
    async knowledgeGraphData(opts) {
        let resp = await this._api.postForm('search/relation', opts)
        return resp.data
        //return await this.TODO()
    }

    //获取研报列表
    async researchReport(opts) {
        let resp = await this._api.postForm('search/researchreport', opts)
        return resp.data
    }

    //获取首页信息
    async getHomeInfo() {
        let resp = await this._api.postForm('search/main')
        return resp.data
    }

    async getClassificationList(opts) {
        let resp = await this._api.postForm('search/getThemes', opts)
        return resp.data
    }

    //获取下来数据  opts.type 1为数据来源，2为行业
    async knowledgeCons(opts) {
        let resp = await this._api.postForm('search/knowledgeCons', opts)
        return resp.data
    }

    //研报库--获取来源
    async getResearchSourceList(opts) {
        let {keyword,type} = opts;
        let resp = await this._api.get('search/getSources', {
            params:{
                keyword,type
            }
        })
        return resp.data
    }

    //研报库--行业分析--行业筛选
    async getIndustryList(opts) {
        let resp = await this._api.postForm('search/getDropDownList', opts)
        return resp.data
    }

    async addReadList(opts) {
        let resp = await this._api.postForm('search/collection/batchAdd', opts)
        return resp.data
    }

    //文档管理获取文档列表
    async getFileList(opts) {
        let resp = await this._api.postForm('search/myDocuemnts', opts)
        return resp.data
    }

    //文档管理 切换'公开'/'私密'状态
    async trunPubStatus(opts) {
        let resp = await this._api.postForm('search/trunPubStatus', opts)
        return resp.data
    }

    //文档管理 删除文档
    async removeDoc(opts) {
        let resp = await this._api.postForm('search/removeDoc', opts)
        return resp.data
    }

    //文档管理 上传文档
    async uploadFile(opts) {
        // let resp = await this._api.postForm('search/uploadPdf', opts)
        let resp = await this._apiV2.postJson('doc/upload_file', opts)
        return resp.data
    }

    //文档管理  任务更新进度
    async documentProgress(opts) {
        // let resp = await this._api.postForm('search/documentProgress', opts)
        let resp = await this._apiV2.postForm('doc/progress_info', opts)
        return resp.data
    }


    //全局搜索--获取相关文章列表
    async getArticleList(opts) {
        let resp = await this._api.postForm('search/reportSearchUnion',opts)
        return resp.data
    }

    //获取公司信息
    async getCompanyInfo(opts) {
        let resp = await this._api.postForm('search/getCompanyInfo', opts)
        return resp.data
    }

    //获取热门搜索词
    async getHotkeywords(opts) {
        let resp = await this._api.postForm('search/getHotkeywords', opts)
        return resp.data
    }

    //获取搜索记录词
    async getRecentSearch(opts) {
        let resp = await this._api.postForm('search/getRecentSearch', opts)
        return resp.data
    }

    // 2020-09-21问题解析
    /*async questionAnalysis(opts) {
        let resp = await this._apiV2.postJson('qa/query_parser', opts)
        return resp.data;
    }*/
    // 2020-09-21问题解析提交
    /*async submitFeedback(opts) {
        let resp = await this._apiV2.postJson('qa/feedback', opts);
        return resp.data;
    }*/

    // 2020/10/30 问题解析--获取问题解析数据
    async questionAnalysis(opts) {
        let resp = await this._apiV2.postJson('qa/parser', opts);
        return resp.data;
    }
    // 2020/10/30 问题解析--获取语料匹配数据
    async questionAnaCorpus(opts) {
        let resp = await this._apiV2.postJson('qa/searcher', opts);
        return resp.data;
    }
    // 2020/10/30 语料解析
    async corpusAna(opts) {
        let resp = await this._apiV2.postJson('qa/text_summary', opts);
        return resp.data;
    }
    // 2021/01/27 獲取語料位置
    async getCorpusPosition(opts) {
        let resp = await this._apiV2.postJson('qa/get_report', opts);
        return resp.data;
    }
    // 2020/10/30 提交训练
    async submitTrain(opts) {
        let resp = await this._apiV2.postJson('qa/feedback', opts);
        return resp.data;
    }

    // 2020/12/01 问题解析--获取右侧问题解析数据
    async spoParser(opts) {
        let resp = await this._apiV2.postJson('qa/parser', opts);
        return resp.data;
    }

    // 2020/12/01 问题解析--获取中间语料匹配数据
    async spoSearcher(opts) {
        let resp = await this._apiV2.postJson('qa/searcher', opts);
        return resp.data;
    }

    // 2021/01/08 问句分类
    async quesCls(opts) {
        let resp = await this._apiV2.postJson('qa/ques_cls', opts);
        return resp.data;
    }

    // excel数据类型搜索
    async kpSearcher(opts) {
        let resp = await this._apiV2.postJson('qa/kp_searcher', opts);
        return resp.data;
    }
    // excel数据类型搜索
    async wordCount(opts) {
        let resp = await this._apiV2.postJson('qa/word_count', opts);
        return resp.data;
    }

    // 2021/01/06 提纲管理 -- 获取列表
    async querySyllabusList(opts) {
        let resp = await this._apiV2.postForm('rp/outline_list', opts);
        return resp.data;
    }
    async deleteSyllabus(opts) {
        let resp = await this._apiV2.postForm('rp/del_outline', opts);
        return resp.data;
    }
    async querySyllabusInfo(opts) {
        let resp = await this._apiV2.postForm('rp/select_outline', opts);
        return resp.data;
    }
    async queryTagTree(opts) {
        let resp = await this._apiV2.postForm('rp/tag_tree', opts);
        return resp.data;
    }
    async saveSyllabus(opts) {
        let resp = await this._apiV2.postJson('rp/add_outline', opts);
        return resp.data;
    }
    async updateSyllabus(opts) {
        let resp = await this._apiV2.postJson('rp/update_outline', opts);
        return resp.data;
    }
    async createReport(opts) {
        let resp = await this._apiV2.postJson('rp/add_report', opts);
        return resp.data;
    }

    // 2021/01/06 报告管理 -- 获取列表
    async queryReportList(opts) {
        let resp = await this._apiV2.postForm('rp/report_list', opts);
        return resp.data;
    }
    async deleteReport(opts) {
        let resp = await this._apiV2.postForm('rp/del_report', opts);
        return resp.data;
    }
    async queryReportInfo(opts) {
        let resp = await this._apiV2.postForm('rp/select_report', opts);
        return resp.data;
    }
    async updateReport(opts) {
        let resp = await this._apiV2.postJson('rp/update_report', opts);
        return resp.data;
    }


    // 文档管理
    async docList(opts) {
        let resp = await this._apiV2.postForm('doc/doc_list', opts);
        return resp.data;
    }
    async docEdit(opts) {
        let resp = await this._apiV2.postJson('doc/edit_doc_info', opts);
        return resp.data;
    }
    async docDelete(opts) {
        let resp = await this._apiV2.postForm('doc/delete_doc', opts);
        return resp.data;
    }
    async turnPubState(opts) {
        let resp = await this._apiV2.postForm('doc/turn_pub_state', opts);
        return resp.data;
    }

    // 高级局部搜索
    async advLocalSearch(opts) {
        let resp = await this._apiV2.postForm('search/adv_local_search',opts);
        return resp
    }

    // 获取项目筛选数据
    async queryProjectFilter(opts) {
        // outline/adv_search_filter
        let resp = await this._apiV2.postForm('search/adv_local_search_filter',opts);
        return resp.data
    }

    // 获取项目列表
    async queryProjectList(opts) {
        // list_user_project
        let resp = await this._apiAida2.postForm('list_user_proj', opts);
        return resp.data
    }

    // 从cedb获取添加文档到aida2
    async createDoc(opts,projectId) {
        // create_doc
        let resp = await this._apiAida2.postJson(`openapi/doc_mng/create_doc?proj_id=${projectId}`, opts);
        return resp.data
    }

}

export default SearchApi
