import React from 'react'
import {webSdk} from "../../../../../../../api/WebSdk";
import BarAndGraph from "../../../../../../graphComponent/BarAndGraph";
import {Table, DatePicker, Icon, Spin, Select} from 'antd';
import '../cash/FinancialCaseSheet.scss'

const {RangePicker} = DatePicker;
const { Option } = Select;

class FinancialLiabilityInfo extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            keyword: props.keyword || '',
            flowLiabilityTime: '',
            unFlowLiabilityTime: '',
            flowLiabilityId: '',
            unFlowLiabilityId: '',
            liabilityInfoId: '',
            flowLiabilityData: {},
            unFlowLiabilityData: {},
            selectTimeArr: [
                {
                    label: '2013年',
                    key: '2013'
                },
                {
                    label: '2014年',
                    key: '2014'
                },
                {
                    label: '2015年',
                    key: '2015'
                },
                {
                    label: '2016年',
                    key: '2016'
                },
                {
                    label: '2017年',
                    key: '2017'
                },
                {
                    label: '2018年',
                    key: '2018'
                }
            ],
            columns: [
                {
                    "title": "流动负债",
                    "dataIndex": "flowLiability",
                    "width": '40%'
                },
                {
                    "title": "金额",
                    "dataIndex": "flowLiabilityAmount",
                    "align": "center",
                    "width": '10%'
                },
                {
                    "title": "非流动负债",
                    "dataIndex": "unFlowLiability",
                    "width": '40%'
                },
                {
                    "title": "金额",
                    "dataIndex": "unFlowLiabilityAmount",
                    "align": "center",
                    "width": '10%'
                }
            ],
            tableData: []
        }
        this.getBaseInfo = this.getBaseInfo.bind(this);
        this.getGraphData = this.getGraphData.bind(this);
        this.choiceTime = this.choiceTime.bind(this);
        this.setOptions = this.setOptions.bind(this);
    }

    componentDidMount() {
        this.getBaseInfo(cb => {
            this.getGraphData(this.state.flowLiabilityId, this.state.flowLiabilityTime, res => {
                this.setState({
                    flowLiabilityData: this.setOptions(res)
                })
            })
            this.getGraphData(this.state.unFlowLiabilityId, this.state.unFlowLiabilityTime, res => {
                this.setState({
                    unFlowLiabilityData: this.setOptions(res)
                })
            })
            this.getGraphData(this.state.liabilityInfoId, '', res => {
                this.setState({
                    tableData: res.tableData
                })
            })
        })
    }

    componentWillReceiveProps(nextProps) {

    }

    /**
     * 获取页面基本信息
     */
    getBaseInfo(cb) {
        let postData = {
            style: 'liabilityInfo',    //当前菜单
            p: this.state.keyword //当前搜索词
        };

        webSdk.search.getFinancialInfo(postData).then(res => {
            this.setState({
                flowLiabilityId: res.flowLiabilityId,
                unFlowLiabilityId: res.unFlowLiabilityId,
                liabilityInfoId: res.liabilityInfoId,
            }, () => {
                cb && cb()
            })
        })
    }

    /**
     * 获取图像数据
     * @param dataId
     * @param time
     * @param cb
     */
    getGraphData(dataId, time, cb) {
        let postData = {
            p: this.state.keyword,
            dataId: dataId,
            time: time
        }
        webSdk.search.getGraphData(postData).then(res => {
            cb && cb(res)
        })
    }

    /**
     * 根据数据获取图形options
     * @param data
     * @returns {*}
     */
    setOptions(data) {
        const _this = this;
        return {
            tooltip: {
                trigger: 'item',
                formatter: "{b} : {c} 亿元({d}%)"
            },
            /*legend: {
                orient: 'horizontal',
                right: 'right',
                top: 'middle',
                data: legendData(data),
                width: '40%'
            },*/
            legend: legendData(data),
            series: [
                {
                    type: 'pie',
                    radius: ['50%', '70%'],
                    center: ['30%', '50%'],
                    data: data,
                    avoidLabelOverlap: false,
                    label: {
                        show: true,
                        normal: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            show: true,
                            textStyle: {
                                fontSize: '30',
                                fontWeight: 'bold'
                            }
                        }
                    },
                    itemStyle: {
                        emphasis: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    },

                    animationDelay(idx) {
                        return idx * 100
                    },
                    animationDelayUpdate(idx) {
                        return idx * 50
                    },
                }
            ]
        };

        function legendData(data) {
            _this.sortData(data,'value');
            let nameData = [];
            let num = {}
            if (data.length > 10) {
                num.a = {
                    x: 50,
                    y: 20,
                    n: 0
                }
                num.b = {
                    x: 65,
                    y: 20,
                    n: 1
                }
                num.c = {
                    x: 80,
                    y: 20,
                    n: 2
                }
            }
            else {
                num.a = {
                    x: 50,
                    y: 30,
                    n: 0
                }
                num.b = {
                    x: 80,
                    y: 30,
                    n: 1
                }
            }
            for (let i = 0; i < data.length; i++) {
                let obj = {
                    orient: 'horizontal',
                    left: '',
                    top: '',
                    align: 'left',
                    data: [data[i].name],
                    icon: "circle"
                }
                if (i>4) {
                    obj.selected = {};
                    obj.selected[data[i].name] = false
                }
                else {
                    obj.selected = {};
                    obj.selected[data[i].name] = true
                }
                if (num.c) {
                    if (num.a.n === i) {
                        obj.left=num.a.x + '%';
                        obj.top=num.a.y + '%';
                        num.a.y += 10;
                    }
                    if (num.b.n === i) {
                        obj.left=num.b.x + '%';
                        obj.top=num.b.y + '%';
                        num.b.y += 10;
                    }
                    if (num.c.n === i) {
                        obj.left=num.c.x + '%';
                        obj.top=num.c.y + '%';
                        num.c.y += 10;
                        num.a.n +=3;
                        num.b.n +=3;
                        num.c.n +=3;
                    }
                }
                else {
                    if (num.a.n === i) {
                        obj.left=num.a.x + '%';
                        obj.top=num.a.y + '%';
                        num.a.y += 10;
                    }
                    if (num.b.n === i) {
                        obj.left=num.b.x + '%';
                        obj.top=num.b.y + '%';
                        num.b.y += 10;
                        num.a.n +=2;
                        num.b.n +=2;
                    }
                }
                nameData.push(obj)
            }
            return nameData
        }
    }

    /**
     * 数据排序处理
     * @param data：当前的所有数据
     * @param sortKey：排序关键词
     * @param type：排序方式：  down：降序，up：升序
     */
    sortData(data,sortKey,type="down"){
        if (type==='down') {
            for (let i = 0; i < data.length; i++) {
                for (let j = 0; j < data.length - 1 - i; j++) {
                    if (parseFloat(data[j][sortKey]) < parseFloat(data[j+1][sortKey])) {        //相邻元素两两对比
                        let temp = data[j+1];        //元素交换
                        data[j+1] = data[j];
                        data[j] = temp;
                    }
                }
            }
        }
        else if (type === 'up') {
            for (let i = 0; i < data.length; i++) {
                for (let j = 0; j < data.length - 1 - i; j++) {
                    if (parseFloat(data[j][sortKey]) > parseFloat(data[j+1][sortKey])) {        //相邻元素两两对比
                        let temp = data[j+1];        //元素交换
                        data[j+1] = data[j];
                        data[j] = temp;
                    }
                }
            }
        }

    }

    /*choiceTime(date, dateString, type) {
        if (type === 'flowLiability') {
            this.setState({
                flowLiabilityTime: dateString[0] + '-' + dateString[1],
                flowLiabilityData: {}
            }, () => {
                this.getGraphData(this.state.flowLiabilityId, this.state.flowLiabilityTime, res => {
                    this.setState({
                        flowLiabilityData: this.setOptions(res)
                    })
                })
            })
        } else if (type === 'unFlowLiability') {
            this.setState({
                unFlowLiabilityTime: dateString[0] + '-' + dateString[1],
                unFlowLiabilityData: {}
            }, () => {
                this.getGraphData(this.state.unFlowLiabilityId, this.state.unFlowLiabilityTime, res => {
                    this.setState({
                        unFlowLiabilityData: this.setOptions(res)
                    })
                })
            })
        }
    }*/
    choiceTime(val, type) {
        if (type === 'flowLiability') {
            this.setState({
                flowLiabilityTime: val,
                flowLiabilityData: {}
            }, () => {
                this.getGraphData(this.state.flowLiabilityId, this.state.flowLiabilityTime, res => {
                    this.setState({
                        flowLiabilityData: this.setOptions(res)
                    })
                })
            })
        } else if (type === 'unFlowLiability') {
            this.setState({
                unFlowLiabilityTime: val,
                unFlowLiabilityData: {}
            }, () => {
                this.getGraphData(this.state.unFlowLiabilityId, this.state.unFlowLiabilityTime, res => {
                    this.setState({
                        unFlowLiabilityData: this.setOptions(res)
                    })
                })
            })
        }
    }

    render() {
        return (
            <div className="financial-assets-info">
                <div className="assets-info-item">
                    {
                        !this.state.flowLiabilityData || JSON.stringify(this.state.flowLiabilityData) === '{}' ? (
                            <Spin size="large"/>
                        ) : ''
                    }
                    <h3 className="assets-info-item-title">流动负债</h3>
                    <div className="assets-info-item-time">
                        <span>选择统计时间</span>

                        <Select style={{ width: 120,marginLeft: 10 }} defaultValue={this.state.selectTimeArr[this.state.selectTimeArr.length-1].key} onChange={val =>this.choiceTime(val,'flowLiability')}>
                            {
                                this.state.selectTimeArr.map((item,index) =>{
                                    return (
                                        <Option key={index} value={item.key}>{item.label}</Option>
                                    )
                                })
                            }
                        </Select>
                        {/*<RangePicker style={{width: 220, height: 32, marginLeft: 10}} size="default" format='YYYY/MM/DD'
                                     placeholder={['开始', '结束']}
                                     onChange={(date, dateString) => this.choiceTime(date, dateString, 'flowLiability')}/>*/}
                    </div>

                    <BarAndGraph options={this.state.flowLiabilityData} minHeight={340}/>
                </div>

                <div className="assets-info-item">
                    {
                        !this.state.unFlowLiabilityData || JSON.stringify(this.state.unFlowLiabilityData) === '{}' ? (
                            <Spin size="large"/>
                        ) : ''
                    }
                    <h3 className="assets-info-item-title">非流动负债</h3>
                    <div className="assets-info-item-time">
                        <span>选择统计时间</span>

                        <Select style={{ width: 120,marginLeft: 10 }} defaultValue={this.state.selectTimeArr[this.state.selectTimeArr.length-1].key} onChange={val =>this.choiceTime(val,'unFlowLiability')}>
                            {
                                this.state.selectTimeArr.map((item,index) =>{
                                    return (
                                        <Option key={index} value={item.key}>{item.label}</Option>
                                    )
                                })
                            }
                        </Select>
                        {/*<RangePicker style={{width: 220, height: 32, marginLeft: 10}} size="default" format='YYYY/MM/DD'
                                     placeholder={['开始', '结束']}
                                     onChange={(date, dateString) => this.choiceTime(date, dateString, 'unFlowLiability')}/>*/}
                    </div>

                    <BarAndGraph options={this.state.unFlowLiabilityData} minHeight={340}/>
                </div>

                <div className="assets-info-item">
                    <div className="header-title">
                        负债表详情（单位：元）
                        <span className="download-table-button">下载报表<Icon className="download-icon"
                                                                          type="cloud-download"/></span>
                    </div>
                    <Table
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        rowClassName={(record, index) => {
                            let row = index % 2 === 0 ? 'first-row' : 'second-row';
                            let leftColor = record.leftColor?" left-color":'';
                            let rightColor = record.rightColor?" right-color":'';
                            let leftLevel = ` left-level-${record.leftLevel}`;
                            let rightLevel = ` right-level-${record.rightLevel}`;
                            return row + leftColor + rightColor + leftLevel + rightLevel
                        }}
                        rowKey={(record, index) => {
                            return index
                        }}
                        pagination={false}
                    />
                </div>
            </div>
        )
    }
}

export default FinancialLiabilityInfo