import React from 'react'
import {Icon, Spin, Table, Tooltip, Drawer} from 'antd'
import '../../CompanyIndustryAndAbstract.scss'
import BarAndGraph from "../../../../../graphComponent/BarAndGraph";
import {webSdk} from "../../../../../../api/WebSdk";
import RightConditionFilter from "../../RightConditionFilter";
import RightHotSearch from "../../RightHotSearch";
import RightHistorySearch from "../../RightHistorySearch";

class ResearchIndex extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            //keyword: props.match.params.keyword || '',
            keyword: props.location.state.keyword || '',
            selectTime: '',
            dataFrom: [],
            reportFrom: [],
            //研报预测表头
            predictionColumns: [],
            //研报预测表格数据
            predictionTableData: [],
            //投资评级数据
            investmentRatingData:{},
            //宏观研报摘要数据
            reportAbstractData: {},
            //pie图右侧表格
            pieColumns: [
                {
                    "title": "相关研报",
                    "dataIndex": "report",
                    "align": "center",
                    "width": "60%",
                    onCell: () => {
                        return {
                            style: {
                                maxWidth: 150,
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow:'ellipsis',
                                cursor:'pointer'
                            }
                        }
                    },
                    render: (text,data) => {
                        let newTitle = this.formatPieTable(data.title)
                        return (<Tooltip placement="topLeft" title={data.dataFrom + '《' + data.title + '》--' + data.author}>{data.dataFrom + '《' + newTitle + '》--' + data.author}</Tooltip>)
                    }
                },
                {
                    "title": "投资评级",
                    "dataIndex": "rating",
                    "align": "center",
                    "width": "40%",
                    onCell: () => {
                        return {
                            style: {
                                maxWidth: 150,
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow:'ellipsis',
                                cursor:'pointer'
                            }
                        }
                    },
                    render: (text) => <Tooltip placement="topLeft" title={text}>{text}</Tooltip>
                }
            ],
            pieTableData: [],
            //当前表格显示的对象
            currentPieItem: '',
            showLeft: false,
            leftDataList: []
        }
        this.getFirstData = this.getFirstData.bind(this);
        this.getSecondData = this.getSecondData.bind(this);
        this.getThirdData = this.getThirdData.bind(this);
        this.choiceTime = this.choiceTime.bind(this);
        this.changeSelect = this.changeSelect.bind(this);
        this.blurSelect = this.blurSelect.bind(this);
        this.toolTipFn = this.toolTipFn.bind(this)
    }
    componentDidMount() {
        this.getFirstData();
        this.getSecondData();
        this.getThirdData();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            keyword: nextProps.location.state.keyword || '',
            selectTime: '',
            //研报预测表头
            predictionColumns: [],
            //研报预测表格数据
            predictionTableData: [],
            //投资评级数据
            investmentRatingData:{},
            //宏观研报摘要数据
            reportAbstractData: {},
            pieTableData: [],
            dataFrom: [],
            reportFrom: []
        },() =>{
            this.getFirstData();
            this.getSecondData();
            this.getThirdData();
        })
    }

    formatPieTable(val){
        let newVal = val;
        if (val.length>7) {
            newVal = val.slice(0,7) + '...'
        }
        return newVal
    }

    //获取第一行数据
    getFirstData(){
        let postData = {
            type: "research",
            p: this.state.keyword,
            time: this.state.selectTime,
            dataFrom: this.state.dataFrom.join(','),
            reportFrom: this.state.reportFrom.join(',')
        }
        webSdk.search.getIndustryAndAbstractFirst(postData).then(res => {
            //debugger
            this.setState({
                predictionColumns: res.columns || [],
                predictionTableData: res.tableData || []
            })
        })
    }

    //获取第二行数据
    getSecondData(){
        let postData = {
            type: "research",
            p: this.state.keyword,
            time: this.state.selectTime,
            dataFrom: this.state.dataFrom.join(','),
            reportFrom: this.state.reportFrom.join(',')
        }
        webSdk.search.getIndustryAndAbstractSecond(postData).then(res => {
            this.setState({
                investmentRatingData: this.setOptions(res),
                pieTableData: res[0].tableData,
            })
        })
    }

    //获取第三行数据
    getThirdData(){
        let postData = {
            type: "research",
            p: this.state.keyword,
            time: this.state.selectTime,
            dataFrom: this.state.dataFrom.join(','),
            reportFrom: this.state.reportFrom.join(',')
        }
        webSdk.search.getIndustryAndAbstractThird(postData).then(reportAbstractData => {
            this.setState({
                reportAbstractData
            })
        })
    }

    setOptions(data){
        //const _this = this;
        this.sortData(data,'value');
        return {
            /*legend: {
                orient: 'horizontal',
                right: 'right',
                top: 'middle',
                data: legendData(data)
            },*/
            tooltip:{
                formatter: (item) =>{
                    this.toolTipFn(item)
                }
            },
            series: [
                {
                    type: 'pie',
                    radius: '50%',
                    center: ['50%', '50%'],
                    data: data,
                    label: {
                        show: true,
                        position: 'outside',
                        formatter: `{b}:\n{d}%`
                    },
                    itemStyle: {
                        emphasis: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    },

                    animationDelay(idx) {
                        return idx * 100
                    },
                    animationDelayUpdate(idx) {
                        return idx * 50
                    },
                }
            ]
        };

        /*function legendData(data) {
            _this.sortData(data,'value');
            let nameData = []
            for (let i = 0; i < data.length; i++) {
                nameData.push(data[i].name)
            }
            return nameData
        }*/
    }

    toolTipFn (item){
        if (item.name!==this.state.currentPieItem) {
            //console.log(item)
            this.setState({
                pieTableData: item.data.tableData,
                currentPieItem: item.name
            })
        }
    }

    /**
     * 数据排序处理
     * @param data：当前的所有数据
     * @param sortKey：排序关键词
     * @param type：排序方式：  down：降序，up：升序
     */
    sortData(data,sortKey,type="down"){
        if (type==='down') {
            for (let i = 0; i < data.length; i++) {
                for (let j = 0; j < data.length - 1 - i; j++) {
                    if (parseFloat(data[j][sortKey]) < parseFloat(data[j+1][sortKey])) {        //相邻元素两两对比
                        let temp = data[j+1];        //元素交换
                        data[j+1] = data[j];
                        data[j] = temp;
                    }
                }
            }
        }
        else if (type === 'up') {
            for (let i = 0; i < data.length; i++) {
                for (let j = 0; j < data.length - 1 - i; j++) {
                    if (parseFloat(data[j][sortKey]) > parseFloat(data[j+1][sortKey])) {        //相邻元素两两对比
                        let temp = data[j+1];        //元素交换
                        data[j+1] = data[j];
                        data[j] = temp;
                    }
                }
            }
        }
        this.setState({
            pieTableData: data[0].tableData
        })

    }


    /** 条件筛选开始 */
    //选择时间
    choiceTime(time){
        this.setState({
            selectTime: time,
            //研报预测表头
            predictionColumns: [],
            //研报预测表格数据
            predictionTableData: [],
            //投资评级数据
            investmentRatingData:{},
            //宏观研报摘要数据
            reportAbstractData: {},
            pieTableData: []
        },() =>{
            this.getFirstData();
            this.getSecondData();
            this.getThirdData();
        })
    }

    //来源、研报筛选删除选择项做请求
    changeSelect(vals,type){
        if (type==='dataFrom') {
            this.setState({
                dataFrom: vals,
                //研报预测表头
                predictionColumns: [],
                //研报预测表格数据
                predictionTableData: [],
                //投资评级数据
                investmentRatingData:{},
                //宏观研报摘要数据
                reportAbstractData: {},
                pieTableData: []
            },() =>{
                this.getFirstData();
                this.getSecondData();
                this.getThirdData();
            })
        }
        else if (type==='reportFrom') {
            this.setState({
                reportFrom: vals,
                //研报预测表头
                predictionColumns: [],
                //研报预测表格数据
                predictionTableData: [],
                //投资评级数据
                investmentRatingData:{},
                //宏观研报摘要数据
                reportAbstractData: {},
                pieTableData: []
            },() =>{
                this.getFirstData();
                this.getSecondData();
                this.getThirdData();
            })
        }
    }

    //来源、研报筛选失去焦点做筛选结果请求
    blurSelect(vals,type) {
        if (type==='dataFrom') {
            this.setState({
                dataFrom: vals,
                //研报预测表头
                predictionColumns: [],
                //研报预测表格数据
                predictionTableData: [],
                //投资评级数据
                investmentRatingData:{},
                //宏观研报摘要数据
                reportAbstractData: {},
                pieTableData: []
            },() =>{
                this.getFirstData();
                this.getSecondData();
                this.getThirdData();
            })
        }
        else if (type==='reportFrom') {
            this.setState({
                reportFrom: vals,
                //研报预测表头
                predictionColumns: [],
                //研报预测表格数据
                predictionTableData: [],
                //投资评级数据
                investmentRatingData:{},
                //宏观研报摘要数据
                reportAbstractData: {},
                pieTableData: []
            },() =>{
                this.getFirstData();
                this.getSecondData();
                this.getThirdData();
            })
        }
    }
    /** 条件筛选结束 */

    //刷新研报摘要
    reFreshReportAbstractData(){
        this.setState({
            reportAbstractData: {}
        },() =>{
            this.getThirdData();
        })
    }

    viewDataFrom(){
        this.setState({
            showLeft: true,
            leftDataList: []
        });
        let postData = {
            p: this.state.keyword,
            time: this.state.selectTime,
            dataFrom: this.state.dataFrom.join(','),
            reportFrom: this.state.reportFrom.join(',')
        }
        webSdk.search.getAbstractReportFrom(postData).then(leftDataList => {
            this.setState({
                leftDataList
            })
        })
    }

    closeDrawer(){
        this.setState({
            showLeft: false
        })
    }

    //行情分析数据来源点击
    dataFromClick(item){
        let url = item ? item.dataUrl : this.state.reportAbstractData.dataUrl;
        this.props.history.push({
            pathname: '/research',
            state: item
        })
        // const w=window.open('about:blank');
        // w.location.href= url
    }

    render() {
        return (
            <div className="company-industryAndAbstract-main">
                <div className="company-industryAndAbstract-left">
                    <div className="industryAndAbstract-left-item">
                        {
                            !this.state.predictionColumns || this.state.predictionColumns.length===0?(<Spin size="large"/>):''
                        }
                        <h3 className="left-item-title">
                            <div className="left-item-left">
                                <span className="left-item-importTitle">公司研报预测</span>
                            </div>
                        </h3>
                        <div className="left-item-content">
                            <Table
                                columns={this.state.predictionColumns}
                                dataSource={this.state.predictionTableData}
                                rowClassName={(record, index) => {
                                    return index % 2 === 0 ? 'first-row' : 'second-row'
                                }}
                                rowKey={(record, index) => {
                                    return index
                                }}
                                pagination={false}
                            />
                        </div>
                        <div className="prediction-item-footer" style={{display: this.state.predictionColumns.length>0?'flex':'none'}}>
                            <span className="table-description">（注：统计均来自研报&年报均值）</span>
                            <span className="table-data-from" onClick={this.viewDataFrom.bind(this)}>查看数据来源<Icon className="data-from-icon" type="right" /></span>
                        </div>
                    </div>

                    <div className="industryAndAbstract-left-item">
                        {
                            !this.state.investmentRatingData || JSON.stringify(this.state.investmentRatingData)==='{}'?(<Spin size="large"/>):''
                        }
                        <h3 className="left-item-title">
                            <div className="left-item-left">
                                <span className="left-item-importTitle">投资评级</span>
                            </div>
                        </h3>
                        <div className="left-item-content" style={{display: 'flex'}}>
                            <div style={{width: '55%'}}>
                                <BarAndGraph options={this.state.investmentRatingData}/>
                            </div>

                            {
                                this.state.pieTableData && this.state.pieTableData.length>0?(
                                    <div style={{width: '45%'}}>
                                        <Table
                                            columns={this.state.pieColumns}
                                            dataSource={this.state.pieTableData}
                                            scroll={{ y: 330 }}
                                            rowClassName={(record, index) => {
                                                return index % 2 === 0 ? 'first-row' : 'second-row'
                                            }}
                                            rowKey={(record, index) => {
                                                return index
                                            }}
                                            pagination={false}
                                        />
                                    </div>
                                ):""
                            }

                        </div>
                    </div>

                    <div className="industryAndAbstract-left-item">
                        {
                            !this.state.reportAbstractData || JSON.stringify(this.state.reportAbstractData)==='{}'?(<Spin size="large"/>):''
                        }
                        <h3 className="left-item-title">
                            <div className="left-item-left">
                                <span className="left-item-importTitle">宏观研报摘要</span>
                            </div>
                            <div className="left-item-right-refresh" onClick={this.reFreshReportAbstractData.bind(this)}>
                                刷新<Icon className="refresh-icon" type="sync" />
                            </div>
                        </h3>
                        <div className="left-item-content" style={!this.state.reportAbstractData || JSON.stringify(this.state.reportAbstractData)==='{}'?{minHeight: 100}:{}}>
                            {
                                this.state.reportAbstractData && JSON.stringify(this.state.reportAbstractData)!=='{}'?(<div dangerouslySetInnerHTML={{__html: this.state.reportAbstractData.txt}}></div>):''
                            }
                        </div>
                        <div className="left-item-footer">
                            {
                                this.state.reportAbstractData && JSON.stringify(this.state.reportAbstractData)!=='{}'?(<span className="item-footer-dataFrom" onClick={() =>this.dataFromClick()}>来源：{this.state.reportAbstractData.dataFrom}</span>):''
                            }
                        </div>
                    </div>
                </div>

                <div className="company-industryAndAbstract-right" key={this.props.location.key}>
                    <RightConditionFilter keyword={this.state.keyword}
                                          currentCard="research"
                                          fnChoiceTime={this.choiceTime}
                                          fnChangeSelect={this.changeSelect}
                                          fnBlurSelect={this.blurSelect}/>

                    <RightHotSearch/>

                    <RightHistorySearch/>
                </div>

                <Drawer
                    title="查看数据来源："
                    placement="right"
                    closable={false}
                    width={400}
                    onClose={this.closeDrawer.bind(this)}
                    visible={this.state.showLeft}
                >
                    {
                        !this.state.leftDataList || this.state.leftDataList.length===0?(<Spin size="large"/>):''
                    }

                    <ul>
                        {
                            this.state.leftDataList && this.state.leftDataList.length>0?(
                                this.state.leftDataList.map((item,index) =>{
                                    return (
                                        <li key={index} className="drawer-item" onClick={() =>{this.dataFromClick(item)}}>
                                            <p className="drawer-title">{index+1}. {item.title}{item.articleTitle}</p>
                                            {/*<p className="drawer-article-title"></p>*/}
                                            <p className="drawer-author">{item.author}</p>
                                        </li>
                                    )
                                })
                            ):''
                        }
                    </ul>
                </Drawer>
            </div>
        )
    }
}

export default ResearchIndex