import React, { Component } from 'react'
import ReactWordcloud from 'react-wordcloud';

export class WordCloudViewer extends Component {
    render() {
        return (
            <>
                <ReactWordcloud words={this.props.wordsData}
                    minSize={this.props.minSize}
                    options={this.props.options}
                />
            </>
        )
    }
}

export default WordCloudViewer