import React, { PureComponent } from 'react';
import AppContext from '../../../../AppContext'
import { Spin, Empty } from 'antd';

class RegulationFallDetailIng extends PureComponent {
    static contextType = AppContext
    constructor(props) {
        super(props);
        this.state = {
            articleId: props.articleId,
            loading: props.ingredLoading,
            typeTabs: {//知识点tab头
                activeTabKey: 'eav',//当前tab页的key
                list: [
                    {
                        key: "spo",
                        value: "行为"
                    },
                    {
                        key: "eav",
                        value: "属性"
                    }, {
                        key: "eae",
                        value: "关系"
                    }
                ],
            },
            tableInfo: {//table相关数据
                columns: [],//表头数据
                data: [],//表格数据
            },
        }
    }

    componentWillReceiveProps(nextState) {
        let { articleId,loading } = this.state
        let newArticleId = nextState.articleId
        let newLoading = nextState.ingredLoading
        if (articleId !== newArticleId) {
            this.setState({
                articleId: newArticleId
            }, () => {
                this.getData();
            })
        }
    }

    //切换tab面板，处理表格数据
    changeTab(activeKey) {
        let { typeTabs } = this.state;
        let newTypeTabs = {}

        if (activeKey === typeTabs.activeTabKey) return false;

        newTypeTabs = { ...typeTabs }
        newTypeTabs.activeTabKey = activeKey
        //赋值
        this.setState({
            typeTabs: newTypeTabs
        }, () => {
            this.getData();
        })
    }

    // 获取知识点数据
    getData() {
        let { articleId } = this.state
        console.log("获取数据", articleId);
        this.setState({
            loading: true
        }, () => {
            webSdk.policyApi.getArticleKnowledge(articleId).then(res => {

                console.log(res);

                this.setState({
                    loading: false,
                })

            }).catch(e => {
                this.setState({
                    loading: false
                })
            });

            // this.handleTableData();
            // this.setState({
            //     loading: false
            // })
        })



    }

    //处理表格数据
    handleTableData() {
        let { typeTabs, tableInfo } = this.state

        let activeKey = typeTabs.activeTabKey;//当前tab页的key

        //表头
        let columns = []
        switch (activeKey) {
            case 'spo': // 行为
                columns = [
                    {
                        title: '时间',
                        dataIndex: 'time',
                        width: 14.2,
                    },
                    {
                        title: '主体',
                        dataIndex: 'subject',
                        width: 14.2,
                    },
                    {
                        title: '动作',
                        dataIndex: 'predicate',
                        width: 14.2,
                    },
                    {
                        title: '客体',
                        dataIndex: 'object',
                        width: 14.2,
                    },
                    {
                        title: '参数',
                        dataIndex: 'args',
                        width: 14.2,
                    },
                    {
                        title: '条件',
                        dataIndex: 'condition',
                        width: 14.2,
                    },
                    {
                        title: '学科',
                        dataIndex: 'classifier',
                        width: 14.2,
                    },
                ]
                break;
            case 'eav':// 属性
                columns = [
                    {
                        title: '时间',
                        dataIndex: 'time',
                        width: 20,
                    },
                    {
                        title: '主体',
                        dataIndex: 'entity',
                        width: 20,
                    },
                    {
                        title: '属性',
                        dataIndex: 'attribute',
                        width: 20,
                    },
                    {
                        title: '值',
                        dataIndex: 'value',
                        width: 20,
                    },
                    {
                        title: '学科',
                        dataIndex: 'classifier',
                        width: 20,
                    },
                ]
                break;
            case 'eae':// 关系
                columns = [
                    {
                        title: '时间',
                        dataIndex: 'time',
                        width: 20,
                    },
                    {
                        title: '主体',
                        dataIndex: 'entity',
                        width: 20,
                    },
                    {
                        title: '关系',
                        dataIndex: 'relation',
                        width: 20,
                    },
                    {
                        title: '主体2',
                        dataIndex: 'entity2',
                        width: 20,
                    },
                    {
                        title: '学科',
                        dataIndex: 'classifier',
                        width: 20,
                    },
                ]
                break;
        }

        let newTableInfo = { ...tableInfo }
        newTableInfo.columns = columns;

        this.setState({
            tableInfo: newTableInfo
        })
    }

    //点击表格数据
    clickTable(data, event) {
        // console.log(data)

        //替换样式
        if (currTr) {
            currTr.className = _.replace(currTr.className, 'active', '');
        }
        event.currentTarget.className = `${event.currentTarget.className} active`
        currTr = event.currentTarget;

        //跳转pdf中对应的位置
        // this.changeSite(data);
    }

    render() {
        let { loading, typeTabs, tableInfo } = this.state
        return (
            <div className="regulation-fall-detail-knowledge">
                <div className="detailklg-head">
                    <div className="detailklg-head-title">知识点</div>
                    <ul className="detailklg-tabs">
                        {
                            typeTabs.list.map((item, idx) => {
                                let activeTabKey = typeTabs.activeTabKey
                                return (
                                    <li key={item.key}
                                        className={item.key === activeTabKey ? "active" : ""}
                                        onClick={this.changeTab.bind(this, item.key)}
                                    >
                                        {item.value}
                                    </li>
                                )
                            })
                        }
                    </ul>
                    <ul className="detailklg-table-head">
                        {
                            tableInfo.columns.map((item, idx) => {
                                let width = item.width && item.width > 0 ? item.width : '20';

                                return (
                                    <li key={idx}
                                        style={{
                                            width: `${width}%`
                                        }}
                                    >{item.title}</li>
                                )
                            })
                        }
                    </ul>
                </div>
                <div className="detailklg-table"
                    style={{
                        height: `${document.body.clientHeight - 140 - 182}px`,
                    }}
                >
                    <Spin wrapperClassName="detailklg-content-loading"
                        spinning={loading}
                    >
                        {
                            !tableInfo.data || tableInfo.data.length <= 0 ?
                                (
                                    <div className="detailklg-content-empty"
                                        style={{
                                            height: `${document.body.clientHeight - 140 - 182}px`,
                                        }}>
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                    </div>
                                ) : (
                                    <table
                                        className="detailklg-content"
                                    >
                                        <tbody>
                                            {
                                                tableInfo.data.map((data, index) => {
                                                    let result = []
                                                    let columns = tableInfo.columns;
                                                    _.forEach(columns, (column, columnIdx) => {
                                                        let columnKey = column.dataIndex;
                                                        let propertyVal = data[columnKey]
                                                        let property = []

                                                        _.forEach(propertyVal, (propItem, propIdx) => {
                                                            property.push(`${propItem}`)
                                                            property.push('<br />')
                                                        })

                                                        result.push(`<td style="width:${column.width}%">${_.join(property, "")}</td>`)
                                                    })

                                                    return (
                                                        <tr key={index}
                                                            data-idx={data.idx}
                                                            dangerouslySetInnerHTML={{ __html: _.join(result, "") }}
                                                            onClick={this.clickTable.bind(this, data)}
                                                        />
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                )
                        }
                    </Spin>
                </div>
            </div>
        );
    }
}

export default RegulationFallDetailIng;