var fnStr = String.prototype;

fnStr.leftPad = function (len, pad) {
  return pad.repeat(Math.max(len - this.length, 0)) + this;
};

fnStr.rightPad = function (len, pad) {
  return this + pad.repeat(Math.max(len - this.length, 0));
};

var fnDate = Date.prototype;

function fmtDateNum(num) {
  return (num + '').leftPad(2, '0');
}

fnDate.bpDate = function () {
  var date = this;
  return [date.getFullYear(), fmtDateNum(date.getMonth() + 1), fmtDateNum(date.getDate())].join('-');
};

fnDate.bpTime = function () {
  var date = this;
  return [fmtDateNum(date.getHours()), fmtDateNum(date.getMinutes()), fmtDateNum(date.getSeconds())].join(':');
};

fnDate.bpFormat = function (posEnd, posStart) {
  var dateStr = this.bpDate() + ' ' + this.bpTime();
  return posEnd ? dateStr.substring(posStart || 0, posEnd) : dateStr;
};

fnDate.addSecond = function (count) {
  var date = this;
  return date.setTime(date.getTime() + count * 1e3), date;
};

fnDate.addDay = function (count) {
  return this.addSecond(count * 86400);
};

fnDate.addYear = function (count) {
  return count && this.setFullYear(this.getFullYear() + count), this;
};

fnDate.addMonth = function (count) {
  var date = this,
      month = date.getMonth(),
      newM = (month + count) % 12,
      addY = (month + count - newM) / 12;
  return date.addYear(addY).setMonth(newM), date;
};

export default fnDate;