import _ from 'lodash'
import ReadListApi from './ReadListApi'
import { mergeMockApi } from './mockWebApi'

class ReadListMock extends ReadListApi {
    constructor(opts) {
        super(opts)
        this.mockData = async (url, delay) => {
            delay = (delay || 10) + _.random(0, 1) // 模拟服务端延迟
            const resp = await this.gate.fake.get(url);
            return await new Promise(resolve => {
                setTimeout(() => {
                    resolve(resp);
                }, delay);
            });
        }
    }

    //获取研报信息
    async getMyCollections() {
        let resp = await this.mockData('readlist/getMyCollections.json', 800);
        return resp.data

    }

    /**添加单个收藏
     * 
     * @param {*} activeId 收藏对象id
     * @param {*} type 收藏类型：1.企业研报 2.行业研报 3.年报 4.招股说明书 5企业信息 6.用户文档 100.知识点
     */
    async addCollection({ activeId, type }) {
    }

    /**删除单个收藏
     * 
     * @param {*} id 收藏对象id
     */
    async removeCollection(id) {

    }

    /**清空收藏*/
    async clearMyCollection() {

    }
}

export default mergeMockApi(ReadListApi, ReadListMock)