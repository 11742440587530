import React from 'react'
import './BaseRightMain.scss'
import InfoCard from './infoCard/InfoCard'
import RelationCard from './relationCard/RelationCard'
import GraphCard from './graphCard/GraphCard'
import TableResult from "./tableCard/TableResult";

function CardModelPanel({item}) {
    if (item.type === 'list') {
        return (<InfoCard companyName={item.companyName}
                          engCompanyName={item.engCompanyName}
                          dataId={item.dataId}/>)
    }
    //企业画像
    else if (item.type === 'company') {
        return (<RelationCard companyName={item.companyName}
                              dataId={item.dataId}
                              selectData={item.filList}
                              dataFrom={item.dataFrom} />)
    }
    //图表
    else if (item.type === 'graph') {
        return (<GraphCard cardList={item.cardList}
                           currentCard={item.currentCard}
                           radioList={item.radioList}
                           choiceRadio={item.choiceRadio}
                           title={item.label}
                           dataId={item.dataId} />)
    }
    //表格
    else if (item.type === 'table') {
        return (<TableResult columns={item.columns}
                             dataId={item.dataId}
                             isExport={item.isExport}
                             title={item.label}/>)
    } else {
        return (<></>)
    }
}

/*公司基本面模块*/
class BaseRightMain extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            resultData:[],
            leftMenu: props.leftMenu,
            //originalGraphData: {}
        }
        //this.getGraphInfo = this.getGraphInfo.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            leftMenu: nextProps.leftMenu
        })
    }

    render() {

        return (

            <div className="right-main">
                <div className="card">
                    <div className="card-main">
                        {
                            this.state.leftMenu.length>0?(
                                this.state.leftMenu.map((item,index) =>(
                                    <div key={index} id={'menu_' + item.key}>
                                        <CardModelPanel item={item}></CardModelPanel>
                                    </div>
                                ))
                            ):''
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default BaseRightMain