/**
 * 语料解析
 */
import React, {useState, useEffect} from 'react'
import {Icon,Spin} from 'antd'
import AddTagComponent from "./AddTagComponent";
import stores from "../../../store";
import {observer} from 'mobx-react'

/*
[
        {
            "text":"句子文本",
            "tags":["标签1","标签2"]
        },...
]
* */

function HomeRightCorpus(props) {
    const {homeRightStore, homeCommonStore, homeCenterStore} = stores;

    let {rightStatus} = homeCommonStore,
        {crtCorpusItem} = homeCenterStore,
        {corpusAnaList, setCorpusAnaList,corpusAnaLoading} = homeRightStore;

    // 删除标签
    const deleteTag = (index, valIndex) => {
        let newList = [...corpusAnaList];
        newList[index].tags.splice(valIndex, 1);
        setCorpusAnaList(newList);
    };

    const CorpusAnaListUI = () =>{
        return corpusAnaList.map((item,index) =>{
            return (
                <li className="home-right-card" key={index}>
                    <h4 className="right-corpus-text" style={{whiteSpace: 'pre-wrap'}}>{item["text"]}</h4>
                    <ul className="corpus-item-ul">
                        {
                            item.tags.map((val,valIndex) =>{
                                return (
                                    <li key={index + "_" + valIndex}
                                        className="value-item-card">
                                        {val}<Icon type="close"
                                                   onClick={() => deleteTag(index, valIndex)}
                                                   className="tag-delete-icon"/>
                                    </li>
                                )
                            })
                        }
                        <AddTagComponent list={corpusAnaList} setList={setCorpusAnaList} item={item} index={index} placeholder="输入属性值"/>
                    </ul>
                </li>
            )
        })
    };

    return (
        <div className="home-right-module home-right-corpus" style={rightStatus === 1 ? null : {height: 0}}>
            <div className="home-right-module-content">
                <h3 className="home-right-module-title">{crtCorpusItem["_art_title"]}</h3>
                <Spin spinning={corpusAnaLoading}>
                    <ul className="home-right-module-content">
                        {
                            corpusAnaList.length === 0 ? "无语料解析数据" : CorpusAnaListUI()
                        }
                    </ul>
                </Spin>
            </div>
        </div>
    )
}

export default observer(HomeRightCorpus)
