import React from 'react'
import './InfoCard.scss'
import {webSdk} from "../../../../../../../api/WebSdk";
import { Spin } from 'antd';


/*列表类展示数据*/
class InfoCard extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            companyName: props.companyName,
            engCompanyName: props.engCompanyName,
            dataId: props.dataId,
            listData: [],
            showLi: []
        }
        this.setShowLi = this.setShowLi.bind(this);
    }
    componentWillMount(){
        let postData = {
            dataId: this.state.dataId
        }
        webSdk.search.getGraphData(postData).then(res => {
            this.setState({
                listData: res
            },()=>{
                this.setShowLi()
            })
        })
    }

    setShowLi(){
        let liArr = [];
        if (this.state.listData && this.state.listData.length>0) {
            let num = Math.ceil(this.state.listData.length / 3);
            for (let i = 1; i <= num; i++) {
                let element = (
                    <div key={i} className={i % 2 === 0 ? 'double-line list-line' : 'single-line list-line'}>
                        <div className="list-item">
                            <span className="list-item-label" title={this.state.listData[(i - 1) * 3].label}>
                                {this.state.listData[(i - 1) * 3].label}:
                            </span>
                            <span className="list-item-value" title={this.state.listData[(i - 1) * 3].value}>
                                {this.state.listData[(i - 1) * 3].value || '--'}
                            </span>
                        </div>
                        {this.state.listData[(i - 1) * 3 + 1] ?
                            (
                                <div className="list-item">
                                    <span className="list-item-label" title={this.state.listData[(i - 1) * 3 + 1].label}>
                                        {this.state.listData[(i - 1) * 3 + 1].label}:
                                    </span>
                                    <span className="list-item-value" title={this.state.listData[(i - 1) * 3 + 1].value}>
                                        {this.state.listData[(i - 1) * 3 + 1].value || '--'}
                                    </span>
                                </div>
                            ) : ''}
                        {this.state.listData[(i - 1) * 3 + 2] ?
                            (
                                <div className="list-item">
                                    <span className="list-item-label" title={this.state.listData[(i - 1) * 3 + 2].label}>
                                        {this.state.listData[(i - 1) * 3 + 2].label}:
                                    </span>
                                    <span className="list-item-value" title={this.state.listData[(i - 1) * 3 + 2].value}>
                                        {this.state.listData[(i - 1) * 3 + 2].value || '--'}
                                    </span>
                                </div>
                            ) : ''}
                    </div>
                )
                liArr.push(element)
            }
        }
        this.setState({
            showLi: liArr
        })
    }

    render() {
        return (
            <div className="card-list-type">
                {!this.state.showLi || this.state.showLi.length===0?(<Spin size="large"/>):''}
                <h3 className="list-title">{this.state.companyName}<span className="list-title-eng">{'（' + this.state.engCompanyName + '）'}</span></h3>
                <div className="list-content">
                    {/*<Spin indicator={false} size="large"/>*/}
                    {
                        this.state.showLi && this.state.showLi.length>0?this.state.showLi:''
                    }

                </div>
            </div>
        )
    }
}

export default InfoCard