import React from 'react'
import './RelationCard.scss'
import { Input ,Select ,Icon } from 'antd';
//import CompanyRelation from "../graphComponent/CompanyRelation";
import ReactEcharts from 'echarts-for-react'
import {webSdk} from "../../../../../../../api/WebSdk";
const { Option } = Select;


//数组合并去重方法
function MergeArray(arr1,arr2){
    let _arr = [];
    for(let i=0;i<arr1.length;i++){
        _arr.push(arr1[i]);
    }
    for(let i=0;i<arr2.length;i++){
        let flag = true;
        for(let j=0;j<arr1.length;j++){
            if(arr2[i]===arr1[j]){
                flag=false;
                break;
            }
        }
        if(flag){
            _arr.push(arr2[i]);
        }
    }
    return _arr;
}

class RelationCard extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            companyName: props.companyName,
            selectData: props.selectData,
            dataFrom: props.dataFrom,
            dataId: props.dataId,
            fullScreen: false,          //控制是否全屏
            relationLevel: '',
            currentSelect: 'all',       //当前筛选的分组
            currentDataFrom: 'all',       //当前筛选的分组
            initData: {},          //当前获取的所有数据
            options: {},        //图形数据
            pointData: [],          //格式化后的原始节点数据
            linksData: []           //格式化后的原始links数据
        }
        this.getGraphData = this.getGraphData.bind(this);
        this.changeFullScreen = this.changeFullScreen.bind(this);
        this.fullScreenExit = this.fullScreenExit.bind(this);
        this.formatCanvasData = this.formatCanvasData.bind(this);
        this.setPointData = this.setPointData.bind(this);
        this.clickPoint = this.clickPoint.bind(this)

        //canvas点击事件
        this.onclick = {
            'click': this.clickPoint
        }
    }
    componentDidMount() {
        this.getGraphData()
    }

    getGraphData(){
        let postData = {
            dataId: this.state.dataId,
            currentSelect: this.state.currentSelect,
            currentDataFrom: this.state.currentDataFrom,
            relationLevel: this.state.relationLevel
        };
        webSdk.search.getGraphData(postData).then(res => {
            this.setState({
                initData: res
            },() =>{
                this.formatCanvasData()
            })
        })
    }

    componentWillReceiveProps(nextProps) {

    }

    /**
     * 内容筛选
     * @param val
     */
    selectChange(val){
        this.setState({
            currentSelect: val
        })
    }

    /**
     * 数据来源筛选
     * @param val
     */
    dataFromChange(val){
        this.setState({
            currentDataFrom: val
        })
    }

    /**
     * 全屏点击事件
     */
    changeFullScreen(){
        this.setState({
            fullScreen: true
        })
    }

    /**
     * 退出全屏事件
     */
    fullScreenExit(){
        this.setState({
            fullScreen: false
        })
    }

    /**
     * 格式化初始数据，获取data和links
     */
    formatCanvasData(){
        let pointData = this.state.initData.pointData,
            linksData = this.state.initData.linksData,
            newPointData = [];
        linksData.forEach((item,index) =>{
            if (item.showLabel) {
                item.label = {
                    show: true,
                    formatter: item.showLabel
                }
            }
        });
        newPointData.push({
            name: pointData.name,
            leave: pointData.leave,
            child: pointData.child,
            currentChild: pointData.child,
            dataFrom: pointData.dataFrom,
            recordTime: pointData.recordTime,
            itemStyle:{
                color: 'red',
                borderWidth: 8,
                borderColor:{
                    type: 'radial',
                    colorStops: [
                        {
                            offset: 0, color: 'rgba(255,0,0,0.1)' // 0% 处的颜色
                        },
                        {
                            offset: 1, color: 'rgba(255,0,0,0.3)' // 100% 处的颜色
                        }
                    ],
                    global: false // 缺省为 false
                }
            },
            label: {
                fontSize: 16
            }
        })
        if (pointData.children) {
            getNewData(pointData.children);
        }
        this.setState({
            pointData: newPointData,
            linksData: linksData
        },() =>{
            let newData = [...newPointData],
                newLinks = [...linksData];
            let outArr = [];        //超过3层不显示数据
            let outFourChild = [];
            for (let i=0;i<newData.length;i++) {
                if (newData[i].leave>1 && newData[i].leave<=3) {
                    if (newData[i].child>4) {
                        let n = 0;
                        for (let j=0;j<newLinks.length;j++) {
                            if (newLinks[j].source === newData[i].name) {
                                if (n===4) {
                                    outFourChild.push(newLinks[j]);
                                    newLinks.splice(j,1);
                                    newData[i].currentChild --;
                                    j--;
                                }
                                else {
                                    n++
                                }
                            }
                        }
                    }
                }
                else if (newData[i].leave>3) {
                    outArr.push(newData[i]);
                    newData.splice(i,1);
                    i--
                }
            }

            for (let k=0;k<outFourChild.length;k++) {
                for (let l=0;l<newData.length;l++) {
                    if (newData[l].name === outFourChild[k].target) {
                        newData.splice(l,1);
                        break;
                    }
                }
            }

            for (let j=0;j<outArr.length;j++) {
                for (let n=0;n<newLinks.length;n++) {
                    if (newLinks[n].target === outArr[j].name) {
                        for (let k=0;k<newData.length;k++) {
                            if (newData[k].name === newLinks[n].source) {
                                newData[k].currentChild --
                            }
                        }
                        newLinks.splice(n,1);
                        n--;
                    }
                }
            }
            this.setPointData(newData,newLinks);
        });
        /**
         * 循环获取各个节点
         */
        function getNewData(item) {
            for (let i=0;i<item.length;i++) {
                let obj = {
                    name: item[i].name,
                    leave: item[i].leave,
                    child: item[i].child,
                    dataFrom: item[i].dataFrom,
                    recordTime: item[i].recordTime
                };
                if (item[i].child !== 0) {
                    obj.currentChild = item[i].child;
                    newPointData.push(obj);
                    getNewData(item[i].children)
                }
                else {
                    newPointData.push(obj);
                }
            }
        }
    }

    /**
     * 设置options数据
     * @param data
     * @param links
     */
    setPointData(data,links){
        let options = {
            title: {
                text: '',
                subtext: '',
                top: 'bottom',
                left: 'right'
            },
            tooltip: {},        //用于设置hover节点显示
            animationDuration: 500,
            animationEasingUpdate: 'quinticInOut',
            series : [
                {
                    name: '',     //名称
                    type: 'graph',              //类型
                    hoverAnimation: false,
                    legendHoverLink: false,     //hover 时的联动高亮
                    layout: 'force',             //图像布局  none不适用任何布局,circular环形布局，force引力布局
                    force:{
                        repulsion: 800,
                        gravity: 0.05,
                        symbolSize:10000,
                        edgeLength: 100         //
                    },
                    roam: true,         //是否开启鼠标缩放和平移漫游。默认不开启。如果只想要开启缩放或者平移，可以设置成 'scale' 或者 'move'。设置成 true 为都开启
                    focusNodeAdjacency: false,   //是否在鼠标移到节点上的时候突出显示节点以及节点的边和邻接节点。
                    itemStyle:{         //节点图形样式
                        color: {
                            // 径向渐变，前三个参数分别是圆心 x, y 和半径，取值同线性渐变
                            type: 'radial',
                            colorStops: [{
                                offset: 0,
                                color: '#1890FF' // 0% 处的颜色
                            }, {
                                offset: 1,
                                color: '#1890FF' // 100% 处的颜色
                            }],
                            global: false, // 缺省为 false
                        },
                        borderWidth: 8,
                        borderColor:{
                            type: 'radial',
                            colorStops: [
                                {
                                    offset: 0, color: 'rgba(24,144,255,0.1)' // 0% 处的颜色
                                },
                                {
                                    offset: 1, color: 'rgba(24,144,255,0.3)' // 100% 处的颜色
                                }
                            ],
                            global: false // 缺省为 false
                        }
                    },
                    //节点图形大小
                    symbolSize:(value,params) =>{
                        //console.log(params);
                        let data = params.data;
                        if (data.leave===1) {
                            return 100
                        }
                        if (data.leave>=2 && data.child>0) {
                            return 30
                        }
                        else {
                            return 50
                        }
                    },
                    lineStyle: {        //线条样式
                        color: '#fff',
                        width: 2
                    },
                    label: {            //图形上的文本标签，可用于说明图形的一些数据信息，比如值，名称等
                        show: true,
                        color: '#fff',
                        fontWeight: 'bold',
                        formatter: item =>{
                            //console.log(item)
                            //在未筛选的情况下
                            if (this.state.currentSelect==='all') {
                                //如果该节点的子节点大于4个，做+/-显示
                                if (item.data.child>4 && item.data.leave>1) {
                                    if (item.data.currentChild!==item.data.child) {
                                        return '+'
                                    }
                                    else {
                                        return '-'
                                    }
                                }
                                //如果节点子节点大于0并且小于等于4个，做空显示
                                else if (item.data.child>0 && item.data.child<=4 && item.data.leave>1) {
                                    return item.name
                                }
                                //没有子节点，默认显示该节点名称
                                else {
                                    return item.name
                                }
                            }
                            //筛选的情况下不做数据处理
                            else {
                                //如果该节点的子节点大于4个，做+/-显示
                                if (item.data.child>4 && item.data.leave>1) {
                                    return '-'
                                }
                                //如果节点子节点大于0并且小于等于4个，做空显示
                                else if (item.data.child>0 && item.data.child<=4 && item.data.leave>1) {
                                    return item.name
                                }
                                //没有子节点，默认显示该节点名称
                                else {
                                    return item.name
                                }
                            }
                        }
                    },
                    //格式化hover数据
                    tooltip:{
                        formatter: item =>{
                            if (item.dataType==='node') {
                                return item.data.name +  `<br/>·数据来源：`+item.data.dataFrom + `<br/>·时   间：` + item.data.recordTime
                            }
                        }
                    },
                    data: data,
                    links: links
                }
            ]
        }

        this.setState({
            options: options
        })
    }

    /**
     * 节点点击事件----用于展开/收缩该节点
     * @param item:当前点击节点
     */
    clickPoint(item){
        if (item.dataType==='node' && this.state.currentSelect==="all") {
            if ((item.data.leave === 2 && item.data.child>4) || (item.data.leave>2 && item.data.child>0)) {
                //当前显示的数据
                let showOptions = this.state.options,
                    showData = showOptions.series[0].data,
                    showLinks = showOptions.series[0].links;
                //获取到的原始数据 oldOptions = options,
                let oldData = this.state.pointData,
                    oldLinks = this.state.linksData;
                //新的显示数据
                let newData = [...showData],
                    newLinks = [...showLinks];

                let deleteName = [];        //删除的节点名称暂存
                let findChild = (findName) =>{
                    for (let i=0;i<newLinks.length;i++) {
                        if (newLinks[i].source === findName) {
                            deleteName.push(newLinks[i].target);
                            findChild(newLinks[i].target);
                        }
                    }
                }
                //先查询该节点再原始数据中是否有子节点
                //循环原始data
                for (let i=0;i<oldData.length;i++) {
                    //找到对应的节点获取当前节点信息
                    if (oldData[i].name===item.name) {
                        //如果该节点的层级大于等于2，并且有子级  ======二级节点收缩/展开+三级节点展开
                        let deleteItems = [];    //子节点对象暂存
                        //循环当前显示的links数据
                        for (let j=0;j<showLinks.length;j++) {
                            //获取起点为当前节点的线
                            if (showLinks[j].source===item.name) {
                                deleteItems.push(showLinks[j]);
                            }
                        }
                        //循环结束后判断删除对象的length，0为已收缩，大于0为展开
                        //第二节点点击情况
                        if (item.data.leave ===2) {
                            if (deleteItems.length>4) {
                                //展开情况，删除4条数据后面的数据
                                for (let k=4;k<deleteItems.length;k++) {
                                    //查找data里的数据，删除该节点
                                    for (let kk=0;kk<newData.length;kk++) {
                                        if (newData[kk].name===deleteItems[k].target) {
                                            newData.splice(kk,1);
                                            break;
                                        }
                                    }
                                    //查找links里的数据，删除该线数据
                                    for (let kkk=0;kkk<newLinks.length;kkk++) {
                                        if (newLinks[kkk].target===deleteItems[k].target) {
                                            newLinks.splice(kkk,1);
                                            break;
                                        }
                                    }
                                }
                                //newData和newLinks为新的展示数据和线
                                for (let n=0;n<newData.length;n++) {
                                    if (newData[n].name===item.name) {
                                        newData[n].currentChild = 4;
                                        break;
                                    }
                                }
                                //console.log(newData,'///////////',newLinks);
                                this.setPointData(newData,newLinks)
                            }
                            else {
                                //收缩的情况，把余下的数据展示出来
                                let currentAllData = [],
                                    currentAllLinks = [];
                                //获取所有的连线数据
                                for (let k=0;k<oldLinks.length;k++) {
                                    if (oldLinks[k].source===item.name) currentAllLinks.push(oldLinks[k])
                                }
                                //根据连线数据添加所有节点
                                for (let kk=0;kk<currentAllLinks.length;kk++) {
                                    for (let kkk=0;kkk<oldData.length;kkk++) {
                                        if (oldData[kkk].name===currentAllLinks[kk].target) {
                                            if (oldData[kkk].name === item.name) {
                                                oldData[kkk].currentChild = oldData[kkk].child
                                            }
                                            currentAllData.push(oldData[kkk]);
                                            break;
                                        }
                                    }
                                }
                                //合并去重
                                newData = MergeArray(newData,currentAllData);
                                newLinks = MergeArray(newLinks,currentAllLinks);
                                for (let n=0;n<newData.length;n++) {
                                    if (newData[n].name===item.name) {
                                        newData[n].currentChild = newData[n].child;
                                        break;
                                    }
                                }
                                //console.log(newData,newLinks);
                                this.setPointData(newData,newLinks)
                            }
                            break;
                        }
                        //除第二节点点击情况外
                        if (item.data.leave >2) {
                            //未显示情况---显示处理
                            if (deleteItems.length===0) {
                                let currentAllData = [],
                                    currentAllLinks = [];
                                //获取所有的连线数据
                                for (let k=0;k<oldLinks.length;k++) {
                                    if (oldLinks[k].source===item.name) currentAllLinks.push(oldLinks[k])
                                }
                                //根据连线数据添加所有节点
                                for (let kk=0;kk<currentAllLinks.length;kk++) {
                                    for (let kkk=0;kkk<oldData.length;kkk++) {
                                        if (oldData[kkk].name===currentAllLinks[kk].target) {
                                            if (oldData[kkk].name === item.name) {
                                                oldData[kkk].currentChild = oldData[kkk].child
                                            }
                                            currentAllData.push(oldData[kkk]);
                                            break;
                                        }
                                    }
                                }
                                for (let x=0;x<currentAllData.length;x++) {
                                    currentAllData[x].currentChild = 0
                                }
                                //合并去重
                                newData = MergeArray(newData,currentAllData);
                                newLinks = MergeArray(newLinks,currentAllLinks);
                                for (let n=0;n<newData.length;n++) {
                                    if (newData[n].name===item.name) {
                                        newData[n].currentChild = newData[n].child;
                                        break;
                                    }
                                }
                                //console.log(newData,newLinks);
                                this.setPointData(newData,newLinks)
                            }
                            //已显示情况---收缩处理
                            else {
                                deleteName = [];        //删除的节点名称暂存
                                findChild(item.name);   //查找除所有的子节点名称
                                for (let i=0;i<deleteName.length;i++) {
                                    for (let ii=0;ii<newData.length;ii++) {
                                        if (newData[ii].name === deleteName[i]) {
                                            newData.splice(ii,1);
                                            ii--;
                                            break;
                                        }
                                    }
                                    for (let iii=0;iii<newLinks.length;iii++) {
                                        if (newLinks[iii].source === deleteName[i] || newLinks[iii].target === deleteName[i]) {
                                            newLinks.splice(iii,1);
                                            iii--;
                                            break;
                                        }
                                    }
                                }
                                this.setPointData(newData,newLinks)
                            }
                        }
                    }
                }
            }
        }
    }


    changeData(){
        this.setState({
            options: {}
        },() =>{
            this.getGraphData()
        })
    }

    render() {
        return (
            <div className="company-portrait">
                <h3 className="portrait-title">企业画像{this.state.companyName?'（'+ this.state.companyName +'）':''}</h3>
                <div className="portrait-content">
                    <div className="left-canvas">
                        {/*<CompanyRelation currentSelect={this.state.currentSelect} options={this.state.options} fnClickPoint={this.clickPoint.bind(this)} />*/}
                        <div className="company-echarts-canvas">
                            <ReactEcharts
                                ref="echarts_graph"
                                option={this.state.options}
                                style={{ height: 382 }}
                                onEvents={this.onclick}
                            />
                        </div>
                        <div className="full-screen-icon" onClick={this.changeFullScreen}><Icon type="fullscreen" /></div>
                    </div>
                    <div className="right-info">
                        <div className="top-input">
                            <div className="input">
                                <span>关系层级：</span>
                                <Input placeholder="请输入关键层级" style={{width: 224,height: 32}}  />
                            </div>
                            <div className="select">
                                <span>内容筛选：</span>
                                <Select style={{width: 224,height: 32}} onChange={this.selectChange.bind(this)} defaultValue={this.state.currentSelect} placeholder="请选择内容">
                                    {
                                        this.state.selectData.map((item,index) =>{
                                            return (
                                                <Option value={item.value} key={index}>{item.label}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </div>
                            <div className="select" style={{marginTop:30}}>
                                <span>数据来源：</span>
                                <Select style={{width: 224,height: 32}} onChange={this.dataFromChange.bind(this)} defaultValue={this.state.currentDataFrom} placeholder="请选择内容">
                                    {
                                        this.state.dataFrom.map((item,index) =>{
                                            return (
                                                <Option value={item.value} key={index}>{item.label}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </div>
                        </div>
                        <div className="bottom-button">
                            <span onClick={this.changeData.bind(this)}>生成图谱</span>
                        </div>
                    </div>
                </div>
                {/*全屏模式下*/}
                {
                    this.state.fullScreen?(
                        <div className="show-full-screen">
                            {/*<CompanyRelation currentSelect={this.state.currentSelect} height="100vh" options={this.state.options} fnClickPoint={this.clickPoint.bind(this)} />*/}
                            <div className="company-echarts-canvas">
                                <ReactEcharts
                                    ref="echarts_graph_all"
                                    option={this.state.options}
                                    style={{ height: '100vh' }}
                                    onEvents={this.onclick}
                                />
                            </div>
                            <div className="full-screen-exit" onClick={this.fullScreenExit}><Icon type="fullscreen-exit" /></div>
                        </div>
                    ):''
                }

            </div>
        )
    }
}

export default RelationCard