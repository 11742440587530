import XLSX from 'xlsx';

/**
 * 右侧表格 格式化成表格显示数据
 * @param items 当前所有已选择的节点[]
 * @param checkedValues 当前已选择的key数据
 * @returns {{eaeData: [], eavData: []}}
 */
function forItemsAddTable(items, checkedValues) {
    let eavData = [],
        eaeData = [];
    if (items.length) {
        for (let i = 0; i < items.length; i++) {
            let item = items[i];
            if (!checkedValues.includes(item._id)) continue;
            // 查找父级
            let parentObj = {};
            parentObj[item.key] = item.label;
            setParentData(item.parent, parentObj);
            // 查找子级
            if (item.children) {
                setChildData(item.children, parentObj)
            } else if (item._children) {
                setChildData(item._children, parentObj)
            } else {
                if (['relation', 'entity2'].includes(item.key)) {
                    eaeData.push(parentObj)
                } else {
                    eavData.push(parentObj)
                }
            }
        }
    }

    return {eavData, eaeData};

    function setParentData(item, obj) {
        obj[item.key] = item.label;
        if (item.key==='time') {
            if (obj.date && obj.date!=='') {
                obj.time = obj.time + obj.date
            }
        }
        if (item.parent && item.parent.key !== 'keyword') {
            setParentData(item.parent, obj)
        }
    }

    function setChildData(list, curObj) {
        for (let i = 0; i < list.length; i++) {
            let newObj = {...curObj};
            let item = list[i];
            newObj[item.key] = item.label;
            if (item.key==='date') {
                if (newObj.time && newObj.time!=='') {
                    newObj.time = newObj.time + newObj.date
                }
            }
            if (item.children && item.children.length > 0) {
                setChildData(item.children, newObj)
            } else {
                if (['relation', 'entity2'].includes(item.key)) {
                    eaeData.push(newObj)
                } else {
                    eavData.push(newObj)
                }
            }
        }
    }
}

function downloadExcel (keyword,data1,data2){
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();
    let time = year + '' + month + day + hour + minute + second;

    let name = keyword + time;
    let sheetData1 = data1.map(item => ({
        '时间': item.time,
        '主体': item.entity,
        '属性': item.attribute,
        '值': item.value,
    }))
    let sheetData2 = data2.map(item => ({
        '主体': item.entity,
        '关系': item.relation,
        '主体2': item.entity2,
    }))

    let sheet1 = XLSX.utils.json_to_sheet(sheetData1);
    let sheet2 = XLSX.utils.json_to_sheet(sheetData2);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, sheet1, '知识点数据');
    XLSX.utils.book_append_sheet(wb, sheet2, '关系数据');
    console.log(XLSX)
    const workbookBlob = workbook2blob(wb);// 导出最后的总表
    openDownloadDialog(workbookBlob, `${name}.xlsx`);


    // 将workbook装化成blob对象
    function workbook2blob(workbook) {
        // 生成excel的配置项
        let wopts = {
            // 要生成的文件类型
            bookType: "xlsx",
            // // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
            bookSST: false,
            type: "binary"
        };
        let wbout = XLSX.write(workbook, wopts);

        // 将字符串转ArrayBuffer
        function s2ab(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
            return buf;
        }

        let blob = new Blob([s2ab(wbout)], {
            type: "application/octet-stream"
        });
        return blob;
    }

    /**
     * 通用的打开下载对话框方法，没有测试过具体兼容性
     * @param url 下载地址，也可以是一个blob对象，必选
     * @param saveName 保存文件名，可选
     */
    function openDownloadDialog(url, saveName) {
        if (typeof url == 'object' && url instanceof Blob) {
            url = URL.createObjectURL(url); // 创建blob地址
        }
        let aLink = document.createElement('a');
        aLink.href = url;
        aLink.download = saveName || ''; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
        let event;
        if (window.MouseEvent) event = new MouseEvent('click');
        else {
            event = document.createEvent('MouseEvents');
            event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
        }
        aLink.dispatchEvent(event);
    }
}

export {
    forItemsAddTable,
    downloadExcel
}