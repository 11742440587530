import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
// import DynRoute from "./tools/DynRoute"
import App from './App'
import Error404 from './pages/common/Error404'
import Index from './pages/component'
import SmartSearchHome from "./pages/component/SmartSearch/SmartSearchHome";

export default () => (
    <Router>
        <App>
            <Switch>
                <Route path="/smartsearchhome" component={props => <SmartSearchHome {...props}/>} />
                <Route path="/" component={Index} />
                <Route path="*" component={Error404} />
            </Switch>
        </App>
    </Router>)