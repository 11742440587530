import React, {useEffect} from 'react'
import {Spin, Button, Icon} from 'antd'
import _ from "lodash"
import OpenIcon from "./OpenIcon";
// import luckysheet from 'luckysheet'
import sheetDownload from "./sheetDownload"
import stores from "../../../store";
import {observer} from 'mobx-react'

const luckysheet = window.luckysheet;

function HomeExcel(props) {
    const {homeLeftStore, homeExcelStore, homeCommonStore} = stores;

    let {
        excelOpen, excelData, excelLoading
    } = homeExcelStore;
    let {questionType} = homeCommonStore

    useEffect(() => {
        if (!_.isEmpty(excelData)) {
            luckysheet.create(excelData)
        }
    }, [excelData]);

    useEffect(() =>{
        setTimeout(() =>{
            let content = document.querySelector(".luckysheet");
            if (content) {
                luckysheet.resize();
            }
        },400)
    },[homeLeftStore.leftOpen]);

    const saveAsExcel = () => {
        if (_.isEmpty(luckysheet)) return;
        sheetDownload(luckysheet);
    };

    let style = () => {
        if (questionType===1) {
            if (homeLeftStore.leftOpen) {
                if (excelOpen) {
                    return {width: "calc(66.67% - 16px)"}
                } else {
                    return {width: 44, flexShrink: 0}
                }
            }
            return {width: "calc(100% - 44px - 16px)"}
        } else {
            return {display: "none"}
        }
    };
    let sheetStyle = () => {
        if (excelOpen) {
            if (homeLeftStore.leftOpen) {
                return {width: '100%', height: '100%'}
            } else {
                return {width: '100%', height: '100%',marginTop: 0}
            }
        } else {
            return ({visibility: "hidden"})
        }
    };

    return (
        <div className="new-home-right new-home-sheet" style={style()}>
            <div className="home-content">
                <OpenIcon open={excelOpen} title="数据分析" type='excel'/>
                <Spin spinning={excelLoading} style={{width: '100%', height: '100%'}}>
                    <div className="home-sheet-content">
                        <div id="luckysheet" style={sheetStyle()}/>
                        <div className="home-sheet-button" style={!excelOpen ? {visibility: "hidden"} : null}>
                            <Button type="primary" className="home-sheet-btn"
                                    onClick={saveAsExcel}><Icon
                                type="file-excel"/>导出Excel</Button>
                        </div>

                    </div>
                </Spin>
            </div>
        </div>
    )
}

export default observer(HomeExcel)
