/*
 * @Description:
 * @LastEditTime: 2022-09-07 14:22:36
 * @LastEditors: liqun 13212226759@163.com
 */
import React from 'react'
import './index.scss'
import { observer } from 'mobx-react'
import HomePage from './children/HomePage'

function FincSubLibrary(props) {
  return (
    <div className="finc-sublibrary-page">
      <HomePage />
    </div>
  )
}

export default observer(FincSubLibrary)
