import React from 'react'
import {Icon, Spin} from 'antd'
import '../../CompanyIndustryAndAbstract.scss'
import BarAndGraph from "../../../../../graphComponent/BarAndGraph";
import {webSdk} from "../../../../../../api/WebSdk";
import RightConditionFilter from "../../RightConditionFilter";
import RightHotSearch from "../../RightHotSearch";
import RightHistorySearch from "../../RightHistorySearch";

class IndustryIndex extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            //keyword: props.match.params.keyword || '',
            keyword: props.location.state.keyword || '',
            selectTime: '',
            dataFrom: [],
            reportFrom: [],
            options: {},
            AnalysisData: {},
            bulletinData: [],
            stockData: []       //股票数据
        }
        this.getFirstData = this.getFirstData.bind(this);
        this.getSecondData = this.getSecondData.bind(this);
        this.getThirdData = this.getThirdData.bind(this);

        this.choiceTime = this.choiceTime.bind(this);
        this.changeSelect = this.changeSelect.bind(this);
        this.blurSelect = this.blurSelect.bind(this);
        this.dataFromClick = this.dataFromClick.bind(this)
    }
    componentDidMount() {
        this.getFirstData();
        this.getSecondData();
        this.getThirdData();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            keyword: nextProps.location.state.keyword || '',
            selectTime: '',
            dataFrom: [],
            reportFrom: [],
            options: {},
            AnalysisData: {},
            bulletinData: []
        },() =>{
            this.getFirstData();
            this.getSecondData();
            this.getThirdData();
        })
    }

    //获取第一行数据
    getFirstData(){
        let postData = {
            type: "industry",
            p: this.state.keyword,
            time: this.state.selectTime,
            dataFrom: this.state.dataFrom.join(','),
            reportFrom: this.state.reportFrom.join(',')
        }
        webSdk.search.getIndustryAndAbstractFirst(postData).then(res => {
            //debugger
            let HS300Arr = res.HS300Stock.split(';');
            let targetStockArr = res.targetStock.split(';');

            let HS300 = getStock(HS300Arr);
            let targetStock = getStock(targetStockArr);

            let data = [];
            for (let i=0;i<HS300.length;i++) {
                data.push({
                    "label": HS300[i].date,
                    "allLabel": HS300[i].allDate,
                    "companyName": HS300[i].allDate === targetStock[i].allDate?targetStock[i].stock:"",
                    "averagePrice": HS300[i].stock
                })
            }
            res.data = data;
            this.setState({
                stockData: data
            })

            //格式化数据
            this.formatGraph(res,options =>{
                this.setState({
                    options
                })
            })

            function getStock(item) {
                let res = [];
                for (let i=0;i<item.length;i++) {
                    if (item[i]) {
                        let Arr = item[i].split(',');
                        res.push({
                            date: Arr[0],
                            allDate: Arr[2].split(' ')[0],
                            stock: Arr[1]
                        })
                    }
                }
                return res;
            }
        })
    }

    //获取第二行数据
    getSecondData(){
        let postData = {
            type: "industry",
            p: this.state.keyword,
            time: this.state.selectTime,
            dataFrom: this.state.dataFrom.join(','),
            reportFrom: this.state.reportFrom.join(',')
        }
        webSdk.search.getIndustryAndAbstractSecond(postData).then(AnalysisData => {
            this.setState({
                AnalysisData
            })
        })
    }

    //获取第三行数据
    getThirdData(){
        let postData = {
            type: "industry",
            p: this.state.keyword,
            time: this.state.selectTime,
            dataFrom: this.state.dataFrom.join(','),
            reportFrom: this.state.reportFrom.join(',')
        }
        webSdk.search.getIndustryAndAbstractThird(postData).then(bulletinData => {
            this.setState({
                bulletinData
            })
        })
    }

    /**
     * 格式化graph图数据
     * @param item：当前的数据
     * @param cb：返回格式化后的options数据
     */
    formatGraph(item,cb){
        let options = {
            xAxis: [],
            yAxis: [],
            series: []
        };
        options.legend = {           //指示标签的数据位置及内容
            bottom: 0,
            right: 0,
            data: []      //获取的数据
        };
        options.tooltip = {
            trigger: 'axis',
            formatter: params =>{
                let txt = `${this.state.stockData[params[0].dataIndex].allLabel}<br/>`
                for (let i=0;i<params.length;i++) {
                    txt += `${params[i].marker + params[i].seriesName + ': ' + params[i].value}`
                    if (i!==params.length-1)  txt+=`<br/>`
                }
                return txt
            }
        };
        /**
         * 先对数据进行排序，再提取数据
         */
            //找出所有数据类型
        let keys = [];
        for (let key in item.data[0]) {
            if (key!=='label') keys.push(key);
        }

        let graphData = {
            labels: []
        };
        item.data.forEach((dataItem,dataIndex) =>{
            graphData["labels"].push(dataItem.label);
            for (let i=0;i<keys.length;i++) {
                let keyName = keys[i];
                if (!graphData[keyName]) {
                    graphData[keyName] = []
                }
                graphData[keyName].push(dataItem[keyName])
            }
        });

        //设置X轴数据
        options.xAxis.push({
            type: 'category',
            boundaryGap: true,
            data: graphData.labels,      //获取的数据
            axisTick:{
                alignWithLabel: true
            }
        })

        //设置series、Y轴数据
        item.opt.forEach((optItem,optIndex) =>{
            options.legend.data.push(optItem.name);

            //是否已经含有该Y轴信息
            let hasY = false;
            for (let i=0;i<options.yAxis.length;i++) {
                if (options.yAxis[i].name===optItem.showName) {
                    hasY = true;
                    break;
                }
            }
            if (!hasY) {
                options.yAxis.push({
                    type: 'value',
                    //scale: true,
                    name: optItem.showName,      //获取的数据
                    splitNumber: 5         //预估分割段数
                });
            }

            options.series.push({
                name: optItem.name,      //获取的数据
                type: optItem.type,        //类型柱形
                //symbol: 'none',            //拐角处是否圆点
                yAxisIndex: optItem.yAxisIndex,
                animationEasing: 'elasticOut',
                showSymbol: false,      //只有在hover时显示圆点
                smooth: true,           //圆滑曲线
                smoothMonotone: 'none',
                areaStyle : {
                    normal : {
                        opacity : 0.1
                    }
                },
                animationDelay(idx) {
                    return idx * 100
                },
                animationDelayUpdate(idx) {
                    return idx * 50
                },
                //barWidth: '30%',
                data: graphData[optItem.key],      //获取的数据
            })
        })
        cb && cb(options)
    }



    /** 条件筛选开始 */
    //选择时间
    choiceTime(time){
        this.setState({
            selectTime: time,
            options: {},
            AnalysisData: {},
            bulletinData: []
        },() =>{
            this.getFirstData();
            this.getSecondData();
            this.getThirdData();
        })
    }

    //来源、研报筛选删除选择项做请求
    changeSelect(vals,type){
        if (type==='dataFrom') {
            this.setState({
                dataFrom: vals,
                options: {},
                AnalysisData: {},
                bulletinData: []
            },() =>{
                this.getFirstData();
                this.getSecondData();
                this.getThirdData();
            })
        }
        else if (type==='reportFrom') {
            this.setState({
                reportFrom: vals,
                options: {},
                AnalysisData: {},
                bulletinData: []
            },() =>{
                this.getFirstData();
                this.getSecondData();
                this.getThirdData();
            })
        }
    }

    //来源、研报筛选失去焦点做筛选结果请求
    blurSelect(vals,type) {
        if (type==='dataFrom') {
            this.setState({
                dataFrom: vals,
                options: {},
                AnalysisData: {},
                bulletinData: []
            },() =>{
                this.getFirstData();
                this.getSecondData();
                this.getThirdData();
            })
        }
        else if (type==='reportFrom') {
            this.setState({
                reportFrom: vals,
                options: {},
                AnalysisData: {},
                bulletinData: []
            },() =>{
                this.getFirstData();
                this.getSecondData();
                this.getThirdData();
            })
        }
    }
    /** 条件筛选结束 */

    //刷新行情分析
    reFreshAnalysisData(){
        this.setState({
            AnalysisData: {}
        },() =>{
            this.getSecondData();
        })
    }

    //行情分析数据来源点击
    dataFromClick(){
        const w=window.open('about:blank');
        w.location.href= this.state.AnalysisData.dataUrl
    }

    render() {
        return (
            <div className="company-industryAndAbstract-main"  key={this.props.location.key}>
                <div className="company-industryAndAbstract-left">
                    <div className="industryAndAbstract-left-item">
                        {
                            !this.state.options || JSON.stringify(this.state.options)==='{}'?(<Spin size="large"/>):''
                        }
                        <h3 className="left-item-title">
                            <div className="left-item-left">
                                <span className="left-item-importTitle">行业表现</span>
                                <span className="left-item-secondTitle">（涨跌幅）</span>
                            </div>
                        </h3>
                        <div className="left-item-content" style={!this.state.options || JSON.stringify(this.state.options)==='{}'?{minHeight: 200}:{}}>
                            {
                                this.state.options && JSON.stringify(this.state.options)!=='{}'?(
                                    <BarAndGraph options={this.state.options}/>
                                ):''
                            }
                        </div>
                    </div>

                    <div className="industryAndAbstract-left-item">
                        {
                            !this.state.AnalysisData || JSON.stringify(this.state.AnalysisData)==='{}'?(<Spin size="large"/>):''
                        }
                        <h3 className="left-item-title">
                            <div className="left-item-left">
                                <span className="left-item-importTitle">行情分析</span>
                                <span className="left-item-secondTitle">（观点摘要）</span>
                            </div>
                            <div className="left-item-right-refresh" onClick={this.reFreshAnalysisData.bind(this)}>
                                刷新<Icon className="refresh-icon" type="sync" />
                            </div>
                        </h3>
                        <div className="left-item-content" style={!this.state.AnalysisData || JSON.stringify(this.state.AnalysisData)==='{}'?{minHeight: 100}:{}}>
                            {
                                this.state.AnalysisData && JSON.stringify(this.state.AnalysisData)!=='{}'?(<span dangerouslySetInnerHTML={{__html: this.state.AnalysisData.txt}}></span>):''
                            }
                        </div>
                        <div className="left-item-footer">
                            {
                                this.state.AnalysisData && JSON.stringify(this.state.AnalysisData)!=='{}'?(<span className="item-footer-dataFrom" onClick={() =>this.dataFromClick()}>来源：{this.state.AnalysisData.dataFrom}</span>):''
                            }
                        </div>
                    </div>

                    <div className="industryAndAbstract-left-item">
                        {
                            !this.state.bulletinData || this.state.bulletinData.length===0?(<Spin size="large"/>):''
                        }
                        <h3 className="left-item-title">
                            <div className="left-item-left">
                                <span className="left-item-importTitle">行业公告</span>
                            </div>
                        </h3>
                        <div className="left-item-content" style={!this.state.bulletinData || this.state.bulletinData.length===0?{minHeight: 100}:{}}>
                            <ul className="left-item-ul">
                                {
                                    this.state.bulletinData && this.state.bulletinData.length>0?(
                                        this.state.bulletinData.map((item,index) =>{
                                            return (<li key={index}>{item.label}</li>)
                                        })
                                    ):''
                                }
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="company-industryAndAbstract-right" key={this.props.location.key}>
                    <RightConditionFilter keyword={this.state.keyword}
                                          currentCard="industry"
                                          fnChoiceTime={this.choiceTime}
                                          fnChangeSelect={this.changeSelect}
                                          fnBlurSelect={this.blurSelect}/>

                    <RightHotSearch/>

                    <RightHistorySearch/>
                </div>
            </div>
        )
    }
}

export default IndustryIndex