import BaseWebApi from './BaseWebApi'

class FinanceLibraryApi extends BaseWebApi {
    constructor(options) {
        super()
        this._apiV2 = this.gate.searchV2
    }

    /**获取文章列表
     * 
     * @param {int} pageNum 页码
     * @param {int} pageSize 每页数量
     * @param {string} pageSize 关键词
     * @param {array[string]} pageSize 文章发布时间
     * @returns 
     */
    async getArticleList({ pageNum = 1, pageSize = 10, keyword = "", publicTimes }) {
        let params = {
            page_num: pageNum,
            page_size: pageSize,
            keyword: keyword || "",
        }

        if (publicTimes != null && publicTimes != undefined && publicTimes.length == 2) {
            params["start"] = publicTimes[0];
            params["end"] = publicTimes[1];

        }

        let resp = await this._apiV2.postForm('finance/article_list', params)
        return resp.data
    }

    /**获取文章内容
     * 
     * @param {int} id 文章id
     * @returns 
     */
    async getArticleContent(id) {
        let params = {
            id
        }

        let resp = await this._apiV2.postForm('finance/article', params)
        return resp.data
    }

    /**获取知识点
     * 
     * @param {int} id 文章id
     * @returns 
     */
    async getArticleKnowledge(id) {
        let params = {
            id
        }

        let resp = await this._apiV2.postForm('finance/knowledge', params)
        return resp.data
    }
}

export default FinanceLibraryApi