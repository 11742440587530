import {webSdk} from "../../../api/WebSdk";
import {message} from 'antd';

// 获取提纲模板列表
const queryReportListApi = (page, pageSize) => {
    return new Promise((resolve, reject) => {
        let postData = {
            page: page,
            page_size: pageSize
        };
        webSdk.search.queryReportList(postData).then(res => {
            if (!res.errCode || res.errCode === 0) {
                resolve(res);
            } else {
                message.error(res.errMsg || "获取报告列表失败，请稍后重试");
                reject();
            }
        }).catch(e => {
            message.error("获取报告列表失败，请稍后重试");
            reject(e);
        })
    })
};

// 删除报告
const deleteReportApi = id =>{
    return new Promise((resolve, reject) => {
        if (!id) {
            message.error("删除失败，请刷新后重试");
            reject();
        }
        let postData = {
            report_id: id
        };
        webSdk.search.deleteReport(postData).then(res => {
            if (res === undefined || !res.errCode || res.errCode === 0) {
                message.success('删除成功');
                resolve(res);
            } else {
                message.error(res.errMsg || "删除报告失败，请稍后重试");
                reject();
            }
        }).catch(e => {
            message.error("删除报告失败，请稍后重试");
            reject(e);
        })
    })
};

// 获取报告详情
const queryReportInfoApi = id => {
    return new Promise((resolve, reject) => {
        let postData = {report_id: id};
        webSdk.search.queryReportInfo(postData).then(res => {
            if (!res.errCode || res.errCode === 0) {
                resolve(res);
            } else {
                message.error(res.errMsg || "获取报告详情失败，请稍后重试");
                reject();
            }
        }).catch(e => {
            message.error("获取报告详情失败，请稍后重试");
            reject(e);
        })
    })
};

// 更新报告
const updateReportApi = postData => {
    return new Promise((resolve, reject) => {
        webSdk.search.updateReport(postData).then(res => {
            if (res === undefined || !res.errCode || res.errCode === 0) {
                message.success("保存成功");
                resolve(res);
            } else {
                message.error(res.errMsg || "保存失败，请稍后重试");
                reject();
            }
        }).catch(e => {
            message.error("保存失败，请稍后重试");
            reject(e);
        })
    })
};

export {
    queryReportListApi,
    deleteReportApi,
    queryReportInfoApi,
    updateReportApi
}
