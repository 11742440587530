import React from 'react'
import _ from 'lodash'

let pdfjs = null
let intInter = []
let addInter = []
let scrollInter = []

const PdfIframe = React.memo(function PdfIframe({ fileUrl, page, size }) {
    if (!page) {
        page = 1
    }
    let src = `/pdfjs/web/viewer.html?file=${fileUrl}#page=${page}`
    return <iframe title="pdf-viewer"
        name="iframe-pdfjs"
        ref={el => pdfjs = el}
        src={src}
        width={size && size.width ? size.width : '300'}
        height={size && size.height ? size.height : '300'}
        style={{
            border: "none"
        }}
    ></iframe>
})

/**
 * 后端的canvas坐标转换为窗口坐标
 * @param {*} canvas canvas对象
 * @param {*} dbCanvas 后端的canvas数值{width,height}
 * @param {*} coordStr 后端的坐标'x0,x1,y0,y1'
 */
function dbCanvasToWindow(canvas, dbCanvas, coordStr) {
    //获取本地canvas参数
    let cbox = canvas.getBoundingClientRect();

    //算出比例
    let xRate = cbox.width / dbCanvas.width;
    let yRate = cbox.height / dbCanvas.height;

    //抽出坐标
    let x0 = _.split(coordStr, ',')[0];
    let x1 = _.split(coordStr, ',')[1];
    let y0 = _.split(coordStr, ',')[2];
    let y1 = _.split(coordStr, ',')[3];

    return {
        x0: x0 * xRate,
        x1: x1 * xRate,
        y0: (dbCanvas.height - y0 - (y1 - y0)) * yRate,
        y1: (dbCanvas.height - y0) * yRate,
        winWidth: cbox.width,
        winHeight: cbox.height,
        winTop: cbox.top,
        winLeft: cbox.left
    }
}

class ResultPdfViewer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            page: 0,//页码
            fileUrl: '',//pdf链接
            lightData: [],//按页码归类的高亮点坐标信息
            serverCanvas: [],//后端页面canvas数据
            size: [],//pdf大小
            lightColor: [],//颜色组
            minPage: 0,//最小页码

        }
    }

    componentDidMount() {
        this.clearInterval();
    }

    componentWillReceiveProps(nextProps) {
        const _this = this;
        let lightData = nextProps.lightData
        let lightColor = nextProps.lightColor
        let serverCanvas = nextProps.serverCanvas
        let fileUrl = nextProps.fileUrl
        let size = nextProps.size
        let minPage = nextProps.minPage;

        //获取数据
        _this.setState({
            lightData, minPage, fileUrl, size, serverCanvas, lightColor
        }, () => {
            _this.initHightlightData();
        })
    }

    /**清除定时器 */
    clearInterval() {

        _.forEach(intInter, item => {
            clearInterval(item)
        })
        intInter = [];

        _.forEach(addInter, item => {
            clearInterval(_.split(item, "-")[1])
        })
        addInter = [];
    }

    /**初始化高亮数据 */
    initHightlightData() {
        const _this = this;
        let _iframe = pdfjs.contentWindow;

        //初始化
        if (!_.isEmpty(_this.state.lightData)) {


            let intervalId = setInterval(() => {

                if (_iframe.PDFViewerApplication && _iframe.PDFViewerApplication.eventBus) {

                    //清除定时器
                    _.forEach(intInter, item => {
                        clearInterval(item)
                    })
                    intInter = [];

                    //监听pdf页面加载
                    _iframe.PDFViewerApplication.eventBus.on('pagerendered', function (event) {
                        _this.addHightlight(event)
                    })
                }
            }, 100)
            intInter.push(intervalId)
        }
    }

    //添加高亮
    addHightlight(event) {

        let _this = this;
        let _iframe = pdfjs.contentWindow
        let canvas = event.source.canvas
        let serverCanvas = {
            width: _this.state.serverCanvas[0],
            height: _this.state.serverCanvas[1]
        }
        let lightColor = _this.state.lightColor
        let scrollMinPage = _this.state.minPage//滚动条所在的页码
        let scrollCoords = "";//滚动条所在的最小高亮数据

        let id = event.source.id;

        let addInterId = setInterval(() => {
            let viewer = _iframe.document.getElementById("viewer")
            let pdfPageDiv = viewer.querySelector(`div[data-page-number='${id}']`)
            if (pdfPageDiv) {

                //根据页码，获取对应的pdf页码层
                let textLayer = pdfPageDiv.getElementsByClassName("textLayer")[0]

                if (textLayer && textLayer.children && textLayer.children.length > 0) {

                    //清除定时器
                    _.forEach(addInter, item => {
                        let intervalIdArr = _.split(item, "-")
                        if (intervalIdArr[0] == id) {
                            clearInterval(intervalIdArr[1])
                            _.pull(addInter, item)
                        }
                    })

                    //处理数据
                    let lightData = _this.state.lightData//lightData = [{"pageNum":"1", "list":[...]}, ...]
                    if (!_.isEmpty(lightData) && lightData.length > 0) {

                        //获取页码对应的高亮数据
                        let pageLightData = _.find(lightData, { "pageNum": `${id}` })//pageLightData = {"pageNum":"1", "list":[...]}

                        if (!_.isEmpty(pageLightData)) {
                            let pageLightDataList = pageLightData.list;//pageLightDataList = [{"page": "1", "word_range": [...], "coords": "..."}, ...]

                            //创建高亮层
                            let lightDivArr = textLayer.getElementsByClassName("lighthight-div")
                            let lightDiv = null;
                            if (!lightDivArr || !lightDivArr[0]) {
                                lightDiv = document.createElement('div');
                                lightDiv.setAttribute("class", "lighthight-div")
                            } else {
                                lightDiv = lightDivArr[0]
                            }

                            //循环 高亮数据
                            _.forEach(pageLightDataList, lightItem => { //lightItem = {"page": "1", "word_range": [...], "coords": "..."}

                                //获取最小页面的coords数据
                                if (lightItem.page === scrollMinPage) {
                                    scrollCoords = lightItem.coords
                                }

                                //同一种属性的高亮区域
                                let lightArea = null;
                                let pointSiteX = 0;//一行字符的下标
                                //wordRange = [25,27]
                                let wordRange = lightItem.word_range;//每行的高亮下标位置
                                let pageNum = lightItem.page;//页码

                                //筛选出本页码的高亮点
                                if (id == pageNum) {
                                    let winCoord = null;

                                    //创建高亮区域
                                    if (!lightArea) {
                                        lightArea = document.createElement('div');
                                    }

                                    //循环div画布中的span节点
                                    let lightLeftStart = 0;
                                    let lightLeftEnd = 0;
                                    let lightSpanList = []
                                    _.forEach(textLayer.children, (spanChild, spanChildIdx) => {

                                        let spanOffsetTop = spanChild.offsetTop;
                                        let spanOffsetLeft = spanChild.offsetLeft;

                                        // 计算相对应的窗口坐标
                                        winCoord = dbCanvasToWindow(canvas, serverCanvas, lightItem.coords)

                                        //判断y坐标相差小于2，并且x坐标在一定范围内，便是高亮文字
                                        if (Math.abs(spanOffsetTop - winCoord.y0) < 10 && spanOffsetLeft + 3 > winCoord.x0 && spanOffsetLeft < winCoord.x1) {

                                            let spanTXT = spanChild.innerText//span的文本
                                            let spanHTML = [];
                                            //将高亮区域的文本的每个字符镶嵌上span节点
                                            _.forEach(_.split(spanTXT, ''), (str, idx) => {
                                                spanHTML.push(`<span class='span_node'>${str}</span>`)
                                            })

                                            spanChild.innerHTML = _.join(spanHTML, '')
                                            lightSpanList.push(spanChild)
                                        }
                                    })

                                    //获取高亮区域的开始、结束位置
                                    let spanWidth = 0;
                                    _.forEach(lightSpanList, (lightSpan, idx) => {

                                        _.forEach(lightSpan.children, (span, spanIdx) => {
                                            spanWidth = span.offsetWidth
                                            if (/[\s◆■\uf06e\uf0d8]/.test(span.innerText)) {
                                                return true;
                                            }

                                            if (pointSiteX == wordRange[0]) {
                                                lightLeftStart = span.getBoundingClientRect().left - winCoord.winLeft

                                            }
                                            if (pointSiteX == wordRange[1] - 1) {
                                                lightLeftEnd = span.getBoundingClientRect().left - winCoord.winLeft + spanWidth
                                            }

                                            pointSiteX++;
                                        })
                                    })
                                    if (lightLeftEnd <= 0) {
                                        lightLeftEnd = lightLeftStart + spanWidth
                                    }

                                    //创建高亮点
                                    if (lightLeftStart > 0 || lightLeftEnd > 0) {
                                        let lightPoint = document.createElement('div');
                                        lightPoint.style.position = "absolute";
                                        lightPoint.style.left = `${lightLeftStart}px`;
                                        lightPoint.style.top = `${winCoord.y0}px`;
                                        lightPoint.style.width = `${lightLeftEnd - lightLeftStart}px`;
                                        lightPoint.style.height = `${winCoord.y1 - winCoord.y0 + 2}px`;
                                        lightPoint.style.backgroundColor = `${lightColor[0][0]}`;

                                        lightArea.appendChild(lightPoint);
                                        lightDiv.appendChild(lightArea);
                                        textLayer.appendChild(lightDiv);
                                        textLayer.style.mixBlendMode = "multiply"
                                        textLayer.style.opacity = "1"
                                    }
                                }
                            })

                            //初始化滚动条
                            _this.changeScroll({
                                page: scrollMinPage,
                                coords: scrollCoords,
                                serverCanvas: _this.state.serverCanvas
                            })
                        }
                    }
                }
            }
        }, 10)

        addInter.push(`${id}-${addInterId}`)
    }

    /**改变滚动条的位置
     * 
     * @param page 页码
     * @param coords 高亮坐标数据
     * @param serverCanvas 服务器的canvas大小
     * 
     */
    changeScroll({ page, coords, serverCanvas, idx }) {
        const _this = this;

        //操作页面
        let _iframe = pdfjs.contentWindow;

        let intervalId = setInterval(() => {

            if (page && coords) {
                //操作iframe
                let viewerContainer = _iframe.document.getElementById("viewerContainer");
                let viewer = _iframe.document.getElementById("viewer");//获取对应的div

                if (viewer && viewer.children && viewer.children.length > 0) {
                    //清除定时器
                    _.forEach(scrollInter, item => {
                        clearInterval(item)
                    })
                    scrollInter = []

                    //算出滚动条高度
                    let viewerChild = viewer.querySelector(`div[data-page-number='${page}']`);
                    let avgPageHeight = viewerChild.offsetHeight;//每页的高度
                    let heightlightY1 = Number(_.split(coords, ",")[3]);//高亮区域的canva高度
                    let hightRate = 1 - heightlightY1 / Number(serverCanvas[1])//算出html上高亮的高度比
                    let height = avgPageHeight * hightRate//算出html上高亮的高度

                    let scrollTop = viewerChild.offsetTop + height;
                    viewerContainer.scrollTop = scrollTop > (avgPageHeight * 0.1) ? scrollTop - (avgPageHeight * 0.1) : scrollTop
                }
            }
        }, 10)
        scrollInter.push(intervalId)
    }

    render() {
        return <PdfIframe
            fileUrl={this.props.fileUrl}
            page={this.props.minPage}
            size={this.props.size}
        ></PdfIframe>
    }
}

export default ResultPdfViewer