import {action, observable} from 'mobx'

/**
 * 首页左侧
 */
export default class HomeCommonStore {
    @observable questionType = 0;       // 当前点击问题类型：0 三栏问句解析模式，1 两栏excel模式
    @observable rightStatus = null;        // 三栏右侧状态：0问题解析，1语料解析，2生成摘要

    @observable savedData = [];         // 保存的问题数据
    @observable questionRes = null;     // 请求问句解析结果 --- 用作请求语料的postData

    @observable quesCls = '';       // 当前问句分类   spo；slot；st

    @observable quesClsFalse = false;        // 请求分类是否错误

    @action setQuestionType = type => {
        this.questionType = type;
    };

    @action setRightStatus = status => {
        this.rightStatus = status;
    };

    @action setSavedData = data => {
        this.savedData = data;
    };

    @action setQuestionRes = data => {
        this.questionRes = data;
    };

    @action setQuesClsFalse = status => {
        this.quesClsFalse = status;
    };

    @action setQuesCls = type => {
        this.quesCls = type;
    };

    @action unmountAction = () =>{
        this.questionType = 0;
        this.rightStatus = null;
        this.savedData = [];
        this.questionRes = null;
        this.quesClsFalse = false;
        this.quesCls = '';
    }
}
