import React, { PureComponent } from 'react';
import _ from 'lodash'
import { Button } from 'antd'
import "./DetailIndex.scss"
import DetailHead from './DetailHead'
import EditPlan from './EditPlan'
import DisplayPlan from './DisplayPlan'
import Examples from './Examples'

class DetailIndex extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            pageType: "", // edit 教案编辑; display 教案展示; examples 案例页面;
        }
    }

    componentDidMount() {
        const pathName = this.props.location.pathname
        this.setPageType(pathName)
    }

    componentWillReceiveProps(nextProps) {
        const pathName = nextProps.location.pathname
        this.setPageType(pathName)
    }

    /** 设置页面类型 */
    setPageType(pathName) {
        const pageType = pathName.split("/")[2]

        this.setState({
            pageType
        })
    }

    render() {
        const { pageType } = this.state

        return (
            <div className="plan-detail">
                <DetailHead pageType={pageType} />
                {
                    pageType === "edit" ? <EditPlan />
                        : pageType === "display" ? <DisplayPlan />
                            : pageType === "examples" ? <Examples />
                                : null
                }

            </div>
        );
    }
}

export default DetailIndex;