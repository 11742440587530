import React, { PureComponent } from 'react'
import _ from 'lodash'
import { Progress } from 'antd'
import "./index.scss"
import LeftFnCollect from './LeftFnCollect'
import LeftFnProperty from './LeftFnProperty'
import LeftFnDescribe from './LeftFnDescribe'

class Index extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            keyword: this.props.keyword || "",
            exhibition: this.props.exhibition || "",//展示
            resultPercent: 90,
            dataServer: this.props.dataServer || {},//原始数据
            describeData: [],//描述数据
            collectData: [],//集合数据
            propertyData: [],//属性数据
            dataType: "",//数据类型
            displayKey: "",//数据来源：value、subject
        }
    }

    componentDidMount() {

        this.dealData()
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            keyword: nextProps.keyword,
            exhibition: nextProps.exhibition,
            dataServer: nextProps.dataServer,
        }, () => {
            this.dealData()
        })
    }

    /**处理数据数据 */
    dealData() {
        const _this = this
        let dataServer = _.cloneDeep(_this.state.dataServer)

        if (_.isEmpty(dataServer)) return;
        //集合数据
        let collectData = dataServer.set || []//集合数据
        let propertyData = dataServer.attr || []//属性数据
        let describeData = dataServer.desc || []//描述数据
        let dataType = dataServer.dataType || ""
        let displayKey = dataServer.displayKey || ""//数据来源

        _this.setState({
            collectData, propertyData, describeData, dataType, displayKey
        })
    }

    /**重新加载所有连线 */
    reloadLinkAll() {
        this.props.fnReloadLinkAll()
    }

    render() {
        let { resultPercent } = this.state;
        return (
            <div className="search-listfn">
                <div className="set-title">
                    <div className="title-text">
                        <h3 className="title-main">{this.state.exhibition}</h3>
                        <p className="title-legend">{this.state.dataType}</p>
                    </div>
                    <div className="title-progress">
                        <Progress
                            type="circle"
                            width={34}
                            percent={resultPercent}
                            format={percent => percent}
                            //strokeColor={resultPercent >= 90 ? "#CCEBA4" : resultPercent >= 50 ? "#FFDEA6" : "#FFB3A6"}
                            strokeColor="#6BA61E"
                        />
                    </div>
                </div>
                <div className="set-list">
                    {
                        !_.isEmpty(this.state.describeData) ? (
                            <LeftFnDescribe
                                keyword={this.state.keyword}
                                describeData={this.state.describeData}
                                fnReloadLinkAll={this.reloadLinkAll.bind(this)}
                                displayKey={this.state.displayKey}
                            />
                        ) : null
                    }
                    {
                        !_.isEmpty(this.state.propertyData) ? (
                            <LeftFnProperty
                                keyword={this.state.keyword}
                                propertyData={this.state.propertyData}
                                fnReloadLinkAll={this.reloadLinkAll.bind(this)}
                                displayKey={this.state.displayKey}
                            />
                        ) : null
                    }
                    {
                        !_.isEmpty(this.state.collectData) ? (
                            <LeftFnCollect
                                keyword={this.state.keyword}
                                collectData={this.state.collectData}
                                fnReloadLinkAll={this.reloadLinkAll.bind(this)}
                                displayKey={this.state.displayKey}
                            />
                        ) : null
                    }
                </div>
            </div>
        );
    }
}

export default Index;