import React from 'react'
import '../../Index.scss'
import { Icon, Table, Tooltip, message, Spin } from 'antd'
import { Route, Switch, Redirect } from 'react-router-dom'
import { webSdk } from '../../../../api/WebSdk'
import QaModuleIndex from './qaModule'
import ListModuleIndex from './listModule'
import GraphModuleIndex from './graphModule'
import './SearchResultIndex.scss'
import MindModuleIndex from './mindModule'
// import MindModuleIndex from "./resultCommon/LeftCommon/Index";
import ListTableIndex from './listTable'

class ResultSearch extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      keyword: this.props.match.params.keyword || '',
      type: 'list', // 页面内显示的类型 'list':列表,'graph':图谱
      typeList: [
        {
          label: '问答',
          key: 'qa'
        },
        /*{
                    label: '文图',
                    key: 'text'
                },*/
        {
          label: '搜索',
          key: 'list'
        },
        {
          label: '表格',
          key: 'listTb'
        },
        {
          label: '脑图',
          key: 'mind'
        },
        {
          label: '网络图',
          key: 'graph'
        }
      ]
    }
  }

  componentDidMount() {
    this.props.fnSetType('resultSearch', this.state.keyword || '')
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.keyword !== this.state.keyword) {
      this.setState({
        keyword: nextProps.match.params.keyword
      })
    }
  }

  componentWillUnmount() {
    // 卸载异步操作设置状态
    this.setState = (state, callback) => {
      return
    }
  }

  setType = (type) => {
    this.setState({
      type
    })
  }

  changeSearchType = (type) => {
    if (this.state.type !== type) {
      this.setState(
        {
          type
        },
        () => {
          this.props.history.push({
            pathname: `/resultList/${this.state.keyword || ''}/${type}`
          })
        }
      )
    }
  }

  /**设置过滤列表数据
   *
   * @param {array} list 列表数组
   */
  fnInitFilterList = (list) => {
    this.props.fnInitFilterList(list)
  }

  /**是否显示过滤列表
   *
   * @param {boolean} type 类型：true显示；false隐藏
   */
  fnTriggerFilterList = (type, allow) => {
    this.props.fnTriggerFilterList(type, allow)
  }

  render() {
    let url = this.props.match.url

    return (
      <div className="search-result-page">
        <div className="search-result-header">
          <ul className="search-type-list">
            {this.state.typeList.map((item, index) => {
              return (
                <li
                  key={item.key}
                  className={
                    this.state.type === item.key
                      ? 'active-type search-type'
                      : 'search-type'
                  }
                  onClick={() => this.changeSearchType(item.key)}
                >
                  {item.label}
                </li>
              )
            })}
          </ul>
        </div>

        <Switch>
          <Route
            path={this.state.keyword ? `${url}/qa` : `${url}//qa`}
            render={(props) => (
              <QaModuleIndex
                {...props}
                keyword={this.state.keyword}
                setType={this.setType}
              />
            )}
          />
          <Route
            path={this.state.keyword ? `${url}/list` : `${url}//list`}
            render={(props) => (
              <ListModuleIndex
                {...props}
                keyword={this.state.keyword}
                setType={this.setType}
              />
            )}
          />
          <Route
            path={this.state.keyword ? `${url}/mind` : `${url}//mind`}
            render={(props) => (
              <MindModuleIndex
                {...props}
                keyword={this.state.keyword}
                setType={this.setType}
              />
            )}
          />
          <Route
            path={this.state.keyword ? `${url}/graph` : `${url}//graph`}
            render={(props) => (
              <GraphModuleIndex
                {...props}
                keyword={this.state.keyword}
                setType={this.setType}
              />
            )}
          />
          <Route
            path={this.state.keyword ? `${url}/listTb` : `${url}//listTb`}
            render={(props) => (
              <ListTableIndex
                {...props}
                keyword={this.state.keyword}
                setType={this.setType}
                fnInitFilterList={this.fnInitFilterList}
                fnTriggerFilterList={this.fnTriggerFilterList}
              />
            )}
          />

          <Redirect exact from={url} to={`${url}/list`} />
        </Switch>
        {/*<LeftMain keyword={this.state.keyword} />
            <RightMain />*/}
      </div>
    )
  }
}

export default ResultSearch
