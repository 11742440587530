import React, {useEffect, useState} from 'react';
import {Button, Spin, Table, message, Popover, Popconfirm} from 'antd';
import stores from "../../../store";
import {observer} from 'mobx-react'
import {queryReportListApi, deleteReportApi} from './utils'


function ReportList(props) {
    const {reportStore} = stores;
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);

    // let unmounted = true;
    useEffect(() => {
        // unmounted = false;
        // console.log('加载了' + unmounted)
        reportStore.setPageInfo([
            {
                label: "报告管理",
                key: "/report/list"
            }
        ], 'list');
        queryTable();
        return () => {
            // unmounted = true;
            // console.log('卸载了' + unmounted)
        }
    }, []);

    const columns = [
        {
            title: '序号',
            dataIndex: 'index',
            render: (text, record, index) => `${index + 1}`,
            align: 'center',
            width: 70
        },
        {
            title: '标题',
            dataIndex: 'name',
            key: 'name',
            render: text => text || '-'
        },
        {
            title: '创建日期',
            dataIndex: 'create_time',
            key: 'create_time',
            width: 120,
            render: text => text || '-'
        },
        {
            title: '最后修改日期',
            dataIndex: 'update_time',
            key: 'update_time',
            width: 120,
            render: text => text || '-'
        },
        {
            title: '引用模板',          // 模板名称超出字符用气泡提示
            dataIndex: 'outline_name',
            key: 'outline_name',
            width: 162,
            render: text => {
                if (text.length > 9) {
                    return (
                        <div className="table-outline-name">
                            <Popover content={text} trigger="hover" placement="bottomLeft">
                                <span>{text}</span>
                            </Popover>
                        </div>
                    )
                }
                return text
            }
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            width: 200,
            render: (text, record) => {
                const {status} = record;
                let cls = status === 0 ? "table-action-cell unfinished" : "table-action-cell";
                return (
                    <div className="table-action-td">
                        <div className={cls} onClick={() => viewClick(record)}>
                            {status === 0? <Popover content="报告生成中..." trigger="hover"
                                                   placement="bottomLeft">查看</Popover> : "查看"}
                        </div>
                        <div className={cls} onClick={() => editClick(record)}>编辑</div>
                        <div className="table-action-cell">
                            <Popconfirm
                                title={`确认要删除"${record['name']}"？`}
                                onConfirm={() => deleteClick(record)}
                                placement="bottom"
                                okText="是"
                                cancelText="否"
                            >删除</Popconfirm>
                        </div>
                        {/*<div className={cls} onClick={() => exportClick(record)}>导出</div>*/}
                    </div>
                )
            }
        }
    ];

    const viewClick = item => {
        if (item.status === 0) return;
        props.history.push({
            pathname: `/report/info/${item.id}`
        })
    };
    const editClick = item => {
        if (item.status === 0) return;
        props.history.push({
            pathname: `/report/edit/${item.id}`
        })
    };
    const deleteClick = item => {
        // if (item.status === 0) return;
        setLoading(true);
        deleteReportApi(item.id).then(res => {
            setLoading(false);
            queryTable();
        }).catch(e => {
            setLoading(false);
        })
    };

    const exportClick = item => {
        if (item.status === 0) return;
        console.log(item)
    };

    const pageChange = (page, pageSize) => {
        setPage(page);
        queryTable(page);
    };

    const pageSizeChange = (page, pageSize) => {
        setPage(1);
        setPageSize(pageSize);
        queryTable(1, pageSize);
    };

    const queryTable = (_page, _pageSize) => {
        setLoading(true);
        queryReportListApi(_page || page, _pageSize || pageSize).then(res => {
            // if (unmounted) return;
            setLoading(false);
            let total = res.total_num;
            let list = res.data || [];
            setTotal(total);
            setTableData(list);
        }).catch(e => {
            // if (unmounted) return;
            setLoading(false);
        })
    };

    return (
        <div className="report-list">
            <Spin spinning={loading}>
                <div>
                    <Table
                        columns={columns}
                        dataSource={tableData}
                        rowClassName={(record, index) => index % 2 === 0 ? 'single-row' : 'double-row'}
                        rowKey={(record, index) => {
                            return index
                        }}
                        pagination={{
                            showSizeChanger: true,
                            showQuickJumper: true,
                            pageSizeOptions: ["10", "15", "20", "25"],
                            current: page,
                            pageSize: pageSize,
                            total: total,
                            onChange: (page, pageSize) => pageChange(page, pageSize),
                            onShowSizeChange: (current, size) => pageSizeChange(current, size),
                            showTotal: total => `共 ${total} 条`
                        }}
                    />
                </div>
            </Spin>
        </div>
    )
}

export default observer(ReportList)
