import _ from 'lodash'
/**
 * 格式化原始数据
 * @param list  原始列表
 * @param type  当前列表的类型     spo：信息；eav：属性；eae：关系    默认属性
 * 先将原始数据合并成[{},{}...]
 * 再合并同字段数据成树型结构
 * 去除值为空的节点
 */
function formatDataToGraph(list,type) {
    let formatList = [];
    for (let i=0;i<list.length;i++) {
        if (type==='spo') {     //信息
            // time: 时间 subject：主体  predicate：动作    object：客体   args：参数 condition：条件    classifier：学科
            let initData = list[i]['spo'] || [];
            if (_.isEmpty(initData)) return
            let result = {
                time: '',
                subject: '',
                predicate: '',
                object: '',
                args: '',
                condition: '',
                classifier: ''
            };        //格式化的数据
            forChildKey(initData,'time',result);
            forChildKey(initData,'subject',result);
            forChildKey(initData,'predicate',result);
            forChildKey(initData,'object',result);
            forChildKey(initData,'args',result);
            forChildKey(initData,'condition',result);
            formatList.push(result);
        }
        else if (type==='eav') {     //属性
            // time: 时间 entity：主体   attribute：属性    value：值 classifier：学科
            let initData = list[i]['eav'] || [];
            if (_.isEmpty(initData)) return
            let result = {
                time: '',
                entity: '',
                attribute: '',
                value: '',
                classifier: ''
            };        //格式化的数据
            forChildKey(initData,'time',result);
            forChildKey(initData,'entity',result);
            forChildKey(initData,'attribute',result);
            forChildKey(initData,'value',result);
            formatList.push(result);
        }
        else if (type==='eae') {     //关系
            // time: 时间 entity：主体   relation：关系 entity2：主体2 classifier：学科
            let initData = list[i]['eae'] || [];
            if (_.isEmpty(initData)) return
            let result = {
                time: '',
                entity: '',
                relation: '',
                entity2: '',
                classifier: ''
            };        //格式化的数据
            forChildKey(initData,'time',result);
            forChildKey(initData,'entity',result);
            forChildKey(initData,'relation',result);
            forChildKey(initData,'entity2',result);
            formatList.push(result);
        }
    }
    if (formatList.length===0) return;
    //初步格式化原始数据后，格式化成 {name,value,children}图像数据
    let keys = [];
    if (type==='spo') {     //信息
        // time: 时间 subject：主体  predicate：动作    object：客体   args：参数 condition：条件    classifier：学科
        keys = ['time','subject','predicate','object','args','condition'];
    }
    else if (type==='eav') {     //属性
        // time: 时间 entity：主体   attribute：属性    value：值 classifier：学科
        keys = ['entity','attribute','time','value'];
    }
    else if (type==='eae') {     //关系
        // time: 时间 entity：主体   relation：关系 entity2：主体2 classifier：学科
        keys = ['entity','relation','time','entity2'];
    }

    //console.log((new Date()).valueOf())
    setChild(formatList,0,keys);
    console.log(formatList);
    /*console.log((new Date()).valueOf())
    let result = formatChild(formatList,0,keys);
    console.log(result);
    console.log((new Date()).valueOf())*/
    return formatList;
    //return result;
}

/**
 * 循环字段
 * @param initData
 * @param key
 * @param result
 */
function forChildKey(initData,key,result) {
    let data = initData['kg_' + key] || initData[key] || [];
    if (Object.prototype.toString.call(data)==='[object Array]' && data.length>0) {
        for (let j = 0;j<data.length;j++) {
            let value = data[j].val;
            value = value.replace(/(^\s*)|(\s*$)/g, "");
            result[key] += value;
        }
    }
}

/*function formatChild(data, idx , keys) {
    // 设置名称
    let label = '';
    switch (keys[idx]) {
        case 'time':
            label = '时间';
            break;
        case 'subject':
            label = '主体';
            break;
        case 'predicate':
            label = '动作';
            break;
        case 'object':
            label = '客体';
            break;
        case 'args':
            label = '参数';
            break;
        case 'condition':
            label = '条件';
            break;
        case 'entity':
            label = '主体';
            break;
        case 'attribute':
            label = '属性';
            break;
        case 'value':
            label = '值';
            break;
        case 'relation':
            label = '关系';
            break;
        case 'entity2':
            label = '主体2';
            break;
        default:
            break;
    }

    let newData = []
    let groupData = _.groupBy(data, (item) => {
        return item[keys[idx]]
    })

    _.forOwn(groupData, (groupDataVal, groupDataKey) => {
        let childObj = {}

        let newGroupDataVal = []
        _.forEach(groupDataVal, (item) => {
            newGroupDataVal.push(_.omit(item, [keys[idx]]))
        })
        groupData[groupDataKey] = newGroupDataVal

        childObj['name'] = groupDataKey;
        childObj['label'] = label;
        //childObj['value'] = (keys.length - idx);
        if (idx < keys.length - 1) {
            childObj.children = newGroupDataVal
        }
        else {
            childObj.value = 50
        }
        newData.push(childObj)
    })

    if (idx < keys.length - 1) {
        idx++
        _.forEach(newData, item => {
            item.children = formatChild (item.children, idx , keys)
        })
    }

    return newData
}*/

function setChild(list , index , keys) {
    if (Object.prototype.toString.call(list)==='[object Array]' && list.length>0) {
        let length = list.length;
        let key = keys[index];
        let data = {};
        let dataList = [];
        for (let i = 0;i<list.length;i++) {
            if (data[list[i][key]]) {
                data[list[i][key]].push(list[i])
            }
            else {
                data[list[i][key]] = [list[i]]
            }
        }
        for (let k in data) {
            dataList.push(data[k]);
        }

        // 设置名称
        let label = '';
        switch (key) {
            case 'time':
                label = '时间';
                break;
            case 'subject':
                label = '主体';
                break;
            case 'predicate':
                label = '动作';
                break;
            case 'object':
                label = '客体';
                break;
            case 'args':
                label = '参数';
                break;
            case 'condition':
                label = '条件';
                break;
            case 'entity':
                label = '主体';
                break;
            case 'attribute':
                label = '属性';
                break;
            case 'value':
                label = '值';
                break;
            case 'relation':
                label = '关系';
                break;
            case 'entity2':
                label = '主体2';
                break;
            default:
                break;
        }

        if (Object.prototype.toString.call(dataList)==='[object Array]' && dataList.length>0) {
            //let res = [];
            for (let i=0;i<dataList.length;i++) {
                let obj = {
                    key: key,
                    name: dataList[i][0][key],          //圆点显示文字
                    label: label,                               //当前圆点属性名
                    value: index === keys.length-1 ? 5 : null           //最后节点大小
                };
                if (index !== keys.length - 1) {
                    obj.children = [];
                    if (Object.prototype.toString.call(dataList[i])==='[object Array]' && dataList[i].length>0) {
                        for (let j=0;j<dataList[i].length;j++) {
                            let item = dataList[i][j];
                            delete item[key];
                            obj.children.push(item);
                        }
                    }
                    if (index !== keys.length-1) {
                        setChild(obj.children,index+1,keys)
                    }
                }
                list.push(obj)
            }
            list.splice(0,length);
            deleteEmpty(list)
        }
    }
}

function deleteEmpty(list) {
    for (let i=0;i<list.length;i++) {
        if (list[i].name === '') {
            if (list[i].children && list[i].children.length>0) {
                for (let j=0;j<list[i].children.length;j++) {
                    list.push(list[i].children[j])
                }
            }
            list.splice(i,1);
            i--
        }
        else {
            if (list[i].children && list[i].children.length>0) {
                deleteEmpty(list[i].children)
            }
        }
    }
}

export {formatDataToGraph}
