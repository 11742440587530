import React, { PureComponent } from 'react'
import { Checkbox, Pagination, Empty } from 'antd';
import _ from 'lodash'
import "./TableModel.scss"

let tempTableModelWidth = 0;

class TableModel extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            tableInfo: props.tableInfo,
            allChecked: false,//是否全选
            allCheckedStyle: false,//全选样式
            arrowIsShow: false,//表格里的箭头是否显示
            orderWidth: 70,//序号的宽度
            rowWidth: 0,//表格每行的宽度
            pagination: {//分页数据
                current: 1,//当前页码
                pageSize: 10,//每页显示的条数
                total: 500,//总条数
                pageSizeOptions: ['10', '30', '50', '70'],//指定每页可以显示多少条
            },
            tableInfoChange: props.tableInfoChange || -1,
            tableColumn: [//表头数据
                {
                    // title: "股票代码",
                    // key: "aaa",
                    // fixed: true,//是否固定
                    // isShow: true,//是否展示
                    // cellLength: 100,//每个单元格的长度
                    // columnTitleLeg: columnTitleLeg,//每个单元格的标题长度
                }
            ],
            tableColSpace: 0,//表格每列间距

            tableContent: [//表格数据
                // {
                //     aaa: "aaa1",
                //     bbb: "bbb1",
                //     ccc: "ccc1",
                //     ddd: "ddd1",
                //     eee: "eee1",
                //     fff: "fff1",
                //     checked: false,
                // }
            ]
        }
    }

    componentDidMount() {
        this.windowResize()
        window.onresize = () => { this.windowResize() }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.tableInfoChange != this.state.tableInfoChange) {
            this.setState({
                tableInfo: nextProps.tableInfo,
                tableInfoChange: nextProps.tableInfoChange
            }, () => {
                this.manageData()
            })
        }
    }

    componentWillUnmount() {
        window.onresize = null;
        this.setState = () => {
            return;
        }
    }

    /**根据窗口大小变化，改变表格的大小 */
    windowResize() {

        let tableColumn = this.state.tableColumn
        if (tableColumn.length <= 0) return;

        let tableModel = document.getElementById('tableModel');
        if (!tableModel) return;
        let tableModelWidth = tableModel.offsetWidth
        let inter = setInterval(() => {
            if (tableModelWidth == tempTableModelWidth) {
                clearInterval(inter)
                inter = null
                this.controlTable()
                this.changeTableHead()
            }
        }, 200);
        tempTableModelWidth = tableModelWidth
    }

    /**改变表格头部的宽度 */
    changeTableHead() {
        let tableSlipedHead = document.getElementById("tableSlipedHead")
        let slipedDiv = document.getElementById("slipedDiv")
        let slipedArrowList = document.getElementsByClassName("sliped-arrow")

        if (!tableSlipedHead || !slipedDiv) return;

        let tableSlipedHeadWidth = tableSlipedHead.style.width
        let slipedDivWidth = slipedDiv.offsetWidth
        if (tableSlipedHeadWidth && tableSlipedHeadWidth != "auto") {

            tableSlipedHead.style.width = `${slipedDivWidth}px`
        }

        _.forEach(slipedArrowList, item => {//箭头

            if (item.className.includes("arrow-left")) {
                item.style.left = `${tableSlipedHead.offsetLeft}px`
            } else if (item.className.includes("arrow-right")) {
                item.style.left = `${tableSlipedHead.offsetLeft + slipedDivWidth + item.offsetWidth - 30}px`
            }
        })
    }

    /**监控滚动条 */
    listenScroll() {
        let currentTarget = document.getElementById("listTable")
        let scrollTop = currentTarget.scrollTop//165
        let tableFixedHead = document.getElementById("tableFixedHead")
        let tableSlipedHead = document.getElementById("tableSlipedHead")
        let slipedArrowList = document.getElementsByClassName("sliped-arrow")
        let slipedDiv = document.getElementById("slipedDiv")
        let slipedDivWidth = slipedDiv.offsetWidth

        if (scrollTop > 110 && !tableFixedHead.className.includes(" fixed")) {
            tableFixedHead.className += " fixed"//固定头部
            tableSlipedHead.className += " fixed"//可滑动头部
            tableSlipedHead.style.width = `${slipedDivWidth}px`

            _.forEach(slipedArrowList, item => {//箭头
                item.className += " fixed"

                if (item.className.includes("arrow-left")) {
                    item.style.left = `${tableSlipedHead.offsetLeft}px`
                } else if (item.className.includes("arrow-right")) {
                    item.style.left = `${tableSlipedHead.offsetLeft + slipedDivWidth - item.offsetWidth}px`
                }
            })
        } else if (scrollTop <= 110 && tableFixedHead.className.includes(" fixed")) {
            tableFixedHead.className = _.replace(tableFixedHead.className, " fixed", "")//固定头部
            tableSlipedHead.className = _.replace(tableSlipedHead.className, " fixed", "")//可滑动头部

            tableSlipedHead.style.width = "auto"

            _.forEach(slipedArrowList, item => {
                item.className = _.replace(item.className, " fixed", "")
                item.style.top = null
                if (item.className.includes("arrow-left")) {
                    item.style.left = `0px`
                } else if (item.className.includes("arrow-right")) {
                    // item.removeAttribute("style")
                    // item.style.right = "none"
                }
            })
        }

        //表格头部固定后，添加top值
        if (scrollTop > 110 && tableFixedHead.className.includes(" fixed")) {
            let top = currentTarget.scrollTop - tableFixedHead.offsetHeight - 10
            //固定的表格头部
            tableFixedHead.style.top = `${top}px`
            tableSlipedHead.style.top = `${top}px`

            //表格头部中的左右箭头
            _.forEach(slipedArrowList, item => {//箭头
                item.style.top = `${top}px`
            })
        }
    }

    /**处理数据 */
    manageData() {
        let tableModel = document.getElementById("tableModel")
        let tableInfo = { ...this.state.tableInfo }
        let column = tableInfo.column
        let tableContent = tableInfo.list
        // {
        //     title: "股票代码",
        //     key: "aaa",
        //     fixed: true,//是否固定
        //     isShow:true,//是否展示
        //     cellLength:100,//每个单元格的长度
        // }
        let tableColumn = []
        let columnLengthCount = 0//表格内容总长度
        _.forEach(column, item => {
            let columnInit = item && item.length ? item.length : 0;
            //标题长度
            let columnTitleLeg = columnInit * 14
            //内容最大的字数
            let contentMaxLen = columnInit;


            _.forEach(tableContent, content => {
                if (!content[item]) {
                    content[item] = "-"
                }
                if (content[item]) {
                    let contentArr = _.split(content[item], "")
                    let numCount = 0
                    _.forEach(contentArr, item => {
                        let exp = /[0-9,a-z,.,-]/
                        if (exp.test(item)) {
                            numCount++
                        }
                    })
                    let contentLen = content[item].length - numCount + numCount * 0.6
                    contentMaxLen = contentLen > contentMaxLen ? contentLen : contentMaxLen
                }
            })

            let cellLength = contentMaxLen * 16
            columnLengthCount += cellLength

            tableColumn.push({
                title: item,
                key: item,
                fixed: false,//是否固定
                isShow: true,//是否展示
                cellLength: cellLength,//每个单元格的长度
                columnTitleLeg: columnTitleLeg,//每个单元格的标题长度
                tableInfo,
            })
        })

        //表格内容长度>表格外框长度时，改变单元格的长度为适合值
        let tableModelWidth = tableModel.offsetWidth//表格外框长度
        if (columnLengthCount > tableModelWidth) {
            _.forEach(tableColumn, columnItem => {
                let cellLength = columnItem.cellLength > 250 ? 250 : columnItem.cellLength
                //与标题的长度的对比：标题>内容，则以标题为主
                columnItem.cellLength = cellLength < columnItem.columnTitleLeg ? columnItem.columnTitleLeg : cellLength
            })
        }

        this.setState({
            tableColumn, tableContent
        }, () => {
            //根据数据调整序号和内容的高度一致
            //序号
            let tableFixed = document.getElementById("tableFixed")
            let tableFixedUlList = tableFixed.querySelectorAll("ul")
            //内容
            let tableSliped = document.getElementById("tableSliped")
            let tableSlipedUlList = tableSliped.querySelectorAll("ul")

            _.forEach(tableSlipedUlList, slipedUl => {
                let dataIdx = slipedUl.getAttribute("data-idx")
                let slipedUlHeight = _.floor(slipedUl.offsetHeight, 2)
                _.forEach(tableFixedUlList, fixedUl => {
                    let fixedDataIdx = fixedUl.getAttribute("data-idx")
                    if (dataIdx == fixedDataIdx) {

                        fixedUl.style.height = `${slipedUlHeight}px`
                        slipedUl.style.height = `${slipedUlHeight}px`
                    }
                })
            })

            this.windowResize()
        })
    }

    /**改变每列间距 */
    controlTable() {
        //表格
        let tableModel = document.getElementById("tableModel")
        let tableColumn = this.state.tableColumn
        let tableColSpace = this.state.tableColSpace
        let rowWidth = 0
        let orderWidth = 60

        if (!tableColumn || tableColumn.length <= 0) {
            return false;
        }

        let contentSumLen = 0
        _.forEach(tableColumn, item => {
            contentSumLen += item.cellLength
        })
        let different = tableModel.offsetWidth - contentSumLen - 100
        if (different > 0) {
            let columnCount = tableColumn.length

            if (columnCount <= 0) return false;
            tableColSpace = Math.round((different / columnCount), 2)
        } else if (-2 <= different <= 0) {
            tableColSpace = 10
        }

        orderWidth += tableColSpace

        //计算表格每行宽度
        rowWidth = contentSumLen + tableColSpace * (tableColumn.length - 1)

        this.setState({
            tableColSpace, orderWidth, rowWidth
        }, () => {
            let tableSliped = document.getElementById("tableSliped")

            //表格宽度>=内容宽度 时，保持单元格宽度不变
            if (tableSliped.offsetWidth >= rowWidth) {
                rowWidth = tableSliped.offsetWidth - 2

                this.setState({
                    rowWidth
                })
            }
        })
    }

    /**全选 */
    chooseAllRow() {
        let tableContent = [...this.state.tableContent];

        _.forEach(tableContent, item => {
            item.checked = !this.state.allChecked
        })

        this.setState({
            tableContent,
            allCheckedStyle: false,
            allChecked: !this.state.allChecked
        })
    }

    /**改变表格总数 */
    changeTotal(total) {
        let pagination = { ...this.state.pagination }
        pagination.total = total
        this.setState({
            pagination
        })
    }

    /**选择数据
     * 通过idx，在数据里控制每行对应的checked属性，并对全选按钮做选择处理
     * @param {number} idx 数组下标
     * @param {object} row 一行的对象
     */
    chooseRow(idx, row) {
        let tableContent = [...this.state.tableContent];
        let rowData = tableContent[idx]
        let allChecked = this.state.allChecked
        let allCheckedStyle = this.state.allCheckedStyle

        //替换数组中对应的数据
        rowData.checked = !rowData.checked
        tableContent[idx] = rowData

        //判断全选按钮：
        //所有都选中，allChecked=true，allCheckedStyle=false
        //所有都没选中，allChecked=false，allCheckedStyle=false
        //其他情况，allChecked=false，allCheckedStyle=true
        let checkedCount = 0, uncheckedCount = 0, allCount = tableContent.length;
        _.forEach(tableContent, item => {
            if (item.checked) {
                checkedCount++;
            } else {
                uncheckedCount++;
            }
        })

        if (checkedCount === allCount) {
            allChecked = true;
            allCheckedStyle = false;
        } else if (uncheckedCount === allCount) {
            allChecked = false;
            allCheckedStyle = false;
        } else {
            allChecked = false;
            allCheckedStyle = true;
        }

        this.setState({
            tableContent, allChecked, allCheckedStyle
        })
    }

    /**鼠标移入悬停
     * 当鼠标悬停时，根据鼠标悬停的idx，找出两个表格中对应的节点，并加上类名
     * @param {number} idx 数组下标
     */
    enterRow(idx) {
        let tableFixed = document.getElementById("tableFixed")
        let tableSliped = document.getElementById("tableSliped")

        let fixedRowList = tableFixed.querySelectorAll("ul.fixed-row")
        let slipedRowList = tableSliped.querySelectorAll("ul.sliped-row")

        let fixedRow = fixedRowList[idx]
        let slipedRow = slipedRowList[idx]

        fixedRow.className += " hover"
        slipedRow.className += " hover"
    }

    /**鼠标移出
     * 当鼠标移出时，找出两个表格中与idx对应的节点，并修改类名
     * @param {number} idx 数组下标
     */
    leaveRow(idx) {
        let tableFixed = document.getElementById("tableFixed")
        let tableSliped = document.getElementById("tableSliped")

        let fixedRowList = tableFixed.querySelectorAll("ul.fixed-row")
        let slipedRowList = tableSliped.querySelectorAll("ul.sliped-row")

        let fixedRow = fixedRowList[idx]
        let slipedRow = slipedRowList[idx]

        fixedRow.className = _.replace(fixedRow.className, " hover", "")
        slipedRow.className = _.replace(slipedRow.className, " hover", "")
    }

    /**控制表壳箭头是否显隐
     * 判断依据：鼠标是否移入表格中，且sliped的div是否出现x轴滚动条
     * @param {boolean} isShow 是否显示
     */
    triggerArrow(isShow) {
        let tableSliped = document.getElementById("tableSliped")//表格内容
        if (tableSliped.clientHeight == tableSliped.offsetHeight) {
            isShow = false
        }
        this.setState({
            arrowIsShow: isShow
        })
    }

    /**移动表格
     * 
     * @param {string} type 类型：left向左移；right向右移
     */
    moveSlipedTable(type) {
        let tableSlipedContent = document.getElementById("tableSlipedContent")
        let tableSliped = document.getElementById("tableSliped")//表格内容
        let scrollLeft = tableSliped.scrollLeft//滚动条左侧的距离
        let tableSlipedHead = document.getElementById("tableSlipedHead")//表格标题

        let slipedRowList = tableSlipedContent.querySelectorAll("ul.sliped-row")
        let slipedColList = !_.isEmpty(slipedRowList) ? (slipedRowList[0].querySelectorAll("li.sliped-col")) : []

        _.forEach(slipedColList, (item, idx) => {

            if (type === "left") {
                if (item.offsetLeft >= scrollLeft && idx > 0) {
                    tableSliped.scrollLeft = slipedColList[idx - 1].offsetLeft
                    tableSlipedHead.scrollLeft = slipedColList[idx - 1].offsetLeft
                    return false;
                }
            } else if (type === "right") {
                if (item.offsetLeft - scrollLeft > 3) {
                    tableSliped.scrollLeft = item.offsetLeft
                    tableSlipedHead.scrollLeft = item.offsetLeft
                    return false;
                }
            }
        })
    }

    /**改变每页显示条数
     * 
     * @param {number} current 当前页数
     * @param {number} pageSize 每页条数
     */
    changePageSize(current, pageSize) {
        let pagination = { ...this.state.pagination }
        pagination.pageSize = pageSize

        this.setState({
            pagination
        }, () => {
            this.props.fnChangePageSize(pageSize)
        })
    }

    /**改变页码
     * 
     * @param {number} page 页码
     * @param {number} pageSize 每页条数
     */
    changePage(page, pageSize) {
        let pagination = { ...this.state.pagination }
        pagination.current = page

        this.setState({
            pagination
        }, () => {
            this.props.fnChangePage(page)
        })
    }

    /**拖动改变
     * @param {object} even 事件
     */
    changeScroll(even) {
        let currentTarget = even.currentTarget
        let currentId = currentTarget.id
        let scrollLeft = currentTarget.scrollLeft

        let tableSlipedHead = document.getElementById("tableSlipedHead")
        let tableSliped = document.getElementById("tableSliped")

        if (currentId === "tableSlipedHead") {
            tableSliped.scrollLeft = scrollLeft
        } else if (currentId === "tableSliped") {
            tableSlipedHead.scrollLeft = scrollLeft
        }
    }

    render() {
        return (
            <div className="listtb-table-model">

                <div
                    id="tableModel"
                    className="table-model-tb"
                    onMouseEnter={this.triggerArrow.bind(this, true)}
                    onMouseLeave={this.triggerArrow.bind(this, false)}
                >
                    <div className="table-fixed">
                        <div id="tableFixedHead"
                            className="table-head table-fixed-head"
                            style={{
                                width: this.state.orderWidth
                            }}
                        >
                            <ul>
                                <li>
                                    <Checkbox
                                        className="ckblist-item fixed-head-ckb"
                                        checked={this.state.allChecked}
                                        onChange={this.chooseAllRow.bind(this)}
                                        indeterminate={this.state.allCheckedStyle}>序号</Checkbox>
                                </li>
                                {
                                    this.state.tableColumn.map((item, idx) => {

                                        if (item.fixed && item.isShow) {
                                            return (
                                                <li
                                                    key={idx}
                                                    style={{
                                                        width: item.cellLength + this.state.tableColSpace * 2,
                                                        padding: `0 ${this.state.tableColSpace}px`,
                                                    }}
                                                >
                                                    <span>{item.title}</span>
                                                </li>
                                            )
                                        }
                                    })
                                }
                            </ul>
                        </div>
                        <div id="tableFixed"
                            className="table-content table-fixed-content"
                        >
                            {
                                this.state.tableContent.map((item, idx) => {
                                    let tableColumn = this.state.tableColumn;

                                    //找出固定列对应的对象:
                                    //在tableColumn中判断是否为固定列，在通过key找出tableContent中的值
                                    let contentList = []
                                    _.forEach(tableColumn, columnItem => {
                                        if (columnItem.fixed) {
                                            let columnKey = columnItem.key
                                            contentList.push({
                                                columnKey: columnKey,
                                                columnValue: item[columnKey],
                                                width: columnItem.cellLength,
                                            })
                                        }
                                    })

                                    return (
                                        <ul
                                            key={idx}
                                            data-idx={idx}
                                            className="table-row fixed-row"
                                            onClick={this.chooseRow.bind(this, idx)}
                                            onMouseEnter={this.enterRow.bind(this, idx)}
                                            onMouseLeave={this.leaveRow.bind(this, idx)}
                                        >
                                            <li
                                                style={{
                                                    width: this.state.orderWidth
                                                }}
                                            >
                                                <Checkbox
                                                    className="ckblist-item fixed-content-ckb"
                                                    checked={this.state.allChecked || item.checked}
                                                    value={item}>{idx + 1}
                                                </Checkbox>
                                            </li>
                                            {
                                                contentList.map((contentListItem, contentListIdx) => (
                                                    <li
                                                        key={contentListIdx}
                                                        title={contentListItem.columnValue == "-" ? null : contentListItem.columnValue}
                                                        style={{
                                                            width: contentListItem.cellLength + this.state.tableColSpace * 2,
                                                            padding: `5px ${this.state.tableColSpace}px`,
                                                        }}
                                                    >
                                                        {contentListItem.columnValue}
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div id="slipedDiv"
                        className="table-sliped"
                    >
                        <div
                            className="sliped-arrow arrow-left"
                            onClick={this.moveSlipedTable.bind(this, "left")}
                            style={{
                                display: this.state.arrowIsShow ? "block" : "none"
                            }}
                        >
                            <span className="iconfont sliped-icon">&#xe663;</span>
                        </div>
                        <div
                            className="sliped-arrow arrow-right"
                            onClick={this.moveSlipedTable.bind(this, "right")}
                            style={{
                                display: this.state.arrowIsShow ? "block" : "none"
                            }}
                        >
                            <span className="iconfont sliped-icon">&#xe601;</span>
                        </div>

                        <div id="tableSlipedContent">
                            <div
                                id="tableSlipedHead"
                                className="table-head table-sliped-head"
                                onScroll={this.changeScroll.bind(this)}
                            >
                                <ul className="sliped-row-head">
                                    {
                                        this.state.tableColumn.map((item, idx) => {

                                            if (!item.fixed && item.isShow) {
                                                return (
                                                    <li
                                                        key={idx}
                                                        className="sliped-col-head"
                                                        style={{
                                                            width: item.cellLength + this.state.tableColSpace * 2,
                                                            padding: `0px ${this.state.tableColSpace}px`,
                                                        }}
                                                    >
                                                        <span>{item.title}</span>
                                                    </li>
                                                )
                                            }
                                        })
                                    }
                                </ul>
                            </div>
                            <div
                                id="tableSliped"
                                className="table-content table-sliped-content"
                                onScroll={this.changeScroll.bind(this)}
                            >
                                {
                                    this.state.tableContent.map((item, idx) => {
                                        let tableColumn = this.state.tableColumn;

                                        //找出固定列对应的对象:
                                        //在tableColumn中判断是否为固定列，在通过key找出tableContent中的值
                                        let contentList = []
                                        _.forEach(tableColumn, (columnItem, columnIdx) => {
                                            if (!columnItem.fixed) {
                                                let columnKey = columnItem.key

                                                contentList.push({
                                                    columnKey: columnKey,
                                                    columnValue: item[columnKey],
                                                    width: columnItem.cellLength,
                                                })
                                            }
                                        })

                                        return (
                                            <ul
                                                key={idx}
                                                data-idx={idx}
                                                className="table-row sliped-row"
                                                onClick={this.chooseRow.bind(this, idx)}
                                                onMouseEnter={this.enterRow.bind(this, idx)}
                                                onMouseLeave={this.leaveRow.bind(this, idx)}
                                                style={{
                                                    width: this.state.rowWidth
                                                }}
                                            >
                                                {
                                                    contentList.map((contentListItem, contentListIdx) => (
                                                        <li
                                                            className="sliped-col"
                                                            key={contentListIdx}
                                                            title={contentListItem.columnValue == "-" ? null : contentListItem.columnValue}
                                                            style={{
                                                                width: contentListItem.width + this.state.tableColSpace * 2,
                                                                padding: `5px ${this.state.tableColSpace}px`,
                                                            }}
                                                        >
                                                            {contentListItem.columnValue}
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>

                </div>
                {
                    _.isEmpty(this.state.tableContent) ? (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    ) : null
                }

                <div className="table-pagination">
                    <Pagination
                        showSizeChanger
                        showQuickJumper
                        current={this.state.pagination.current}
                        total={this.state.pagination.total}
                        pageSizeOptions={this.state.pagination.pageSizeOptions}
                        onShowSizeChange={this.changePageSize.bind(this)}
                        onChange={this.changePage.bind(this)}
                    />
                </div>
            </div>
        );
    }
}

export default TableModel;