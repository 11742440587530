/*
 * @Description:主要指标
 * @LastEditTime: 2022-10-13 18:57:21
 * @LastEditors: liqun 13212226759@163.com
 */
import React, { useEffect, useState } from 'react'
import { Icon, Empty, Spin } from 'antd'
import './MyTable.scss'
import { webSdk } from '../../../../api/WebSdk'

// let tableDatas = [
//   {
//     name: '指标名称xxxxx',
//     Q1: '2069亿',
//     中期报: '29亿',
//     Q3: '69亿',
//     年报: '269亿'
//   }
// ]
let columns = [
  {
    key: 'indicator_name_ch',
    label: '指标名称'
  },
  {
    key: 'Q1',
    label: 'Q1'
  },
  {
    key: '中期报',
    label: '中期报'
  },
  {
    key: 'Q3',
    label: 'Q3'
  },
  {
    key: '年报',
    label: '年报'
  }
]

const formatRes = (getIndicatorRes) => {
  let res = []
  for (let [id, value] of Object.entries(getIndicatorRes)) {
    const mapChidren = (obj, children, floor = 0) => {
      for (let child of children) {
        obj.tableDatas.push({
          ...child,
          floor
        })
        if (child.children.length) {
          mapChidren(obj, child.children, floor + 1)
        }
      }
    }
    let obj = {
      chartName: value.indicator_name_ch,
      id,
      tableDatas: [],
      columns
    }
    mapChidren(obj, value.children)
    res.push(obj)
  }
  return res
}

const fetchData = async (type, selectedYear, corp_id) => {
  const getIndicator = (report_type) =>
    webSdk.financeSubLibraryApi.getIndicator({
      corp_id,
      year: selectedYear,
      belong_to: type,
      report_type
    })
  return Promise.all([
    getIndicator(0),
    getIndicator(1),
    getIndicator(2),
    getIndicator(3),
    webSdk.financeSubLibraryApi.getIndicatorTerm()
  ]).then(([quarter1, quarter2, quarter3, quarter4, IndicatorTerm]) => {
    // console.log(quarter1, quarter2, quarter3, quarter4, IndicatorTerm)
    let term = {}
    let root = {}
    IndicatorTerm.data.forEach((item) => {
      if (item.parent_id === '0') {
        root[item.id] = item
      } else {
        term[item.id] = item
      }
    })
    let rootParentId = Object.values(root).map((i) => i.id)
    let obj = {}
    let areaRoot = {} // 当前页面用到的数据
    for (let quarter of [quarter1, quarter2, quarter3, quarter4]) {
      if (quarter.data) {
        for (let indicator of quarter.data) {
          let { indicator_closing_value, report_type, id, indicator_term_id } =
            indicator
          if (!obj[indicator_term_id]) {
            obj[indicator_term_id] = { indicator_term_id }
          }
          // if (
          //   obj[indicator_term_id]['id'] &&
          //   obj[indicator_term_id]['id'] !== id
          // ) {
          //   console.error('id不相等')
          // }
          // obj[indicator_term_id]['id'] = id
          switch (report_type) {
            // 一季度
            case 0:
              obj[indicator_term_id]['Q1'] = indicator_closing_value
              break
            case 1:
              obj[indicator_term_id]['中期报'] = indicator_closing_value
              break
            case 2:
              obj[indicator_term_id]['Q3'] = indicator_closing_value
              break
            case 3:
              obj[indicator_term_id]['年报'] = indicator_closing_value
              break
            default:
              break
          }
          obj[indicator_term_id]['parent_id'] =
            term[indicator_term_id]['parent_id']
          obj[indicator_term_id]['indicator_name_ch'] =
            term[indicator_term_id]['indicator_name_ch']
          obj[indicator_term_id]['children'] = []
          if (rootParentId.includes(obj[indicator_term_id]['parent_id'])) {
            const item = root[obj[indicator_term_id]['parent_id']]
            areaRoot[item.id] = {
              ...item,
              children: []
            }
          }
        }
      }
    }
    // console.log(obj, areaRoot)
    for (let [indicator_term_id, value] of Object.entries(obj)) {
      if (areaRoot[value.parent_id]) {
        areaRoot[value.parent_id].children.push(value)
      } else if (obj[value.parent_id]) {
        obj[value.parent_id].children.push(value)
      }
    }
    // console.log(areaRoot)
    return areaRoot
  })
}

const formatCurrency = (string) => {
  if (!string) return
  // -1.374161597E7
  const [base, index] = string.split('E')
  const originNumber = base * 10 ** (index || 0)
  if (Math.abs(originNumber) / 10 ** 8 >= 1) {
    return (originNumber / 10 ** 8).toFixed(2) + ' 亿元'
  } else if (Math.abs(originNumber) / 10 ** 4 >= 1) {
    return (originNumber / 10 ** 4).toFixed(2) + ' 万元'
  } else {
    return originNumber.toFixed(2)
  }
}

const MyTable = ({ title, tableDatas, columns, loading }) => {
  return (
    <Spin spinning={loading} delay={300}>
      <div className="my-table-container">
        <div className="my-table-title">
          <Icon type="fund" />
          <span>{title}</span>
        </div>
        <table className="my-table-main">
          <thead>
            <tr className="my-table-tr">
              {columns.map((column) => (
                <td key={column.key}>{column.label}</td>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableDatas.map((data, index) => (
              <tr className="my-table-tr" key={index}>
                {columns.map((column, index) => (
                  <td
                    key={column.key + index}
                    style={{
                      paddingLeft:
                        column.label === '指标名称'
                          ? 8 + data['floor'] * 13 + 'px'
                          : '0'
                    }}
                  >
                    {column.label === '指标名称'
                      ? data[column['key']]
                      : formatCurrency(data[column['key']]) || '--'}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Spin>
  )
}

function MainIndicator({ type }) {
  const corp_id = window.location.pathname.split('/').at(-1)

  const currentYear = new Date().getFullYear()
  const [selectedYear, setSelectedYear] = useState(currentYear)
  const [showData, setShowData] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    fetchData(type, selectedYear, corp_id)
      .then((res) => {
        // console.log(res)
        console.log(formatRes(res))
        setShowData(formatRes(res))
      })
      .finally(() => {
        setLoading(false)
      })
  }, [type, selectedYear, corp_id])

  return (
    <>
      <div className="choose-year">
        <span className="year">选择年份</span>
        {new Array(10).fill(1).map((item, index) => (
          <span
            className={[
              'year',
              selectedYear === currentYear - index ? 'active' : ''
            ].join(' ')}
            key={index}
            onClick={() => setSelectedYear(currentYear - index)}
          >
            {currentYear - index}
          </span>
        ))}
        <Icon type="calendar" />
      </div>
      {loading && !showData.length ? (
        <Spin spinning={loading} delay={300}>
          <div className="my-table-container">
            <div className="my-table-title"></div>
            <table className="my-table-main"></table>
          </div>
        </Spin>
      ) : showData.length ? (
        showData.map((item) => (
          <MyTable
            title={item.chartName}
            columns={item.columns}
            tableDatas={item.tableDatas}
            key={item.id}
            loading={loading}
          />
        ))
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </>
  )
}

export default MainIndicator
