import React from 'react'
import '../Index.scss'
import {Select, Table, Icon, Spin, message} from 'antd'

import {webSdk} from "../../../api/WebSdk";

import {MultiCascader} from 'rsuite';
import 'rsuite/dist/styles/rsuite.min.css'
import queryString from 'query-string'
const {Option} = Select;


//向上查找组织树数据
function formatSelectData(id, initData, resultData, currentArr) {

    for (let i = 0; i < initData.length; i++) {
        if (initData[i].value === id) {
            let newArr = []
            if (currentArr && currentArr.length > 0) {
                newArr = [...currentArr]
            }
            newArr.push(id);
            resultData.push(newArr)
            break;
        } else {
            if (initData[i].children && initData[i].children.length > 0) {
                let newArr = []
                if (currentArr && currentArr.length > 0) {
                    newArr = [...currentArr]
                }
                newArr.push(initData[i].value);
                formatSelectData(id, initData[i].children, resultData, newArr)
            }
        }
    }
}

//删除子集
function removeArrChild(result) {
    let copyResult = [...result];
    for (let i=0;i<result.length;i++) {
        for(let j=0;j<copyResult.length;j++) {
            let ri = result[i].join(',');
            let rj = copyResult[j].join(',');
            if(ri!==rj) {
                let flag = false;
                flag = copyResult[j].every(val => result[i].includes(val))
                if(flag) {
                    result.splice(i,1);
                    i = i-1;
                    break;
                }
            }
        }
    }
}

let queryTheme = '[]';             //当前分类请求数据，用于刷新页面的请求
class CompanyList extends React.PureComponent {

    constructor(props) {
        super(props)

        let searchData = props.location.search || '';
        let page = 0;
        let theme = [];
        let themeType = '1';
        let pageSize = 10;
        queryTheme = '[]'
        if (searchData) {
            page = JSON.parse(queryString.parse(searchData)['page'] || '0');
            theme = JSON.parse(queryString.parse(searchData)['theme'] || '[]');
            themeType = queryString.parse(searchData)['themeType'] || '1';
            pageSize = parseInt(queryString.parse(searchData)['pageSize'] || '10');
            queryTheme = queryString.parse(searchData)['queryTheme'] || '[]';
        }

        this.state = {
            keyword: props.match.params.keyword || '',
            timeValue: [],
            //公司筛选
            /*company: {
                title: '公司筛选',
                titleKey: 'company',
                selectData: [],
                value: ''
            },*/
            //行业分类标准
            industryClassification: {
                title: '行业分类标准',
                titleKey: 'industryClassification',
                selectData: [
                    {
                        label: '申万行业分类',
                        value: '1'
                    },
                    {
                        label: '中证指数',
                        value: '2'
                    },
                    {
                        label: 'CSRC标准分类',
                        value: '3'
                    }
                ],
                value: themeType
            },
            //行业分类
            industryScreen: {
                title: '行业筛选',
                titleKey: 'industryScreen',
                selectData: [],
                value: theme
            },
            //行业分类下拉数据
            classificationSelect: [],


            tableLoading: false,
            columns: [
                {
                    title: '序号',
                    dataIndex: 'index',
                    render: (text, record, index) => `${index + 1}`,
                    align: 'center',
                    width: 60
                },
                {
                    title: '企业名称',
                    dataIndex: 'title',
                    key: 'title',
                    render: text =>{
                        return text || '-'
                    }
                },
                {
                    title: '股票代码',
                    dataIndex: 'stockcode',
                    key: 'stockcode',
                    render: text =>{
                        return text || '-'
                    }
                },
                {
                    title: '所属行业',
                    dataIndex: 'theme',
                    key: 'theme',
                    render: (text,record) =>{
                        let cics_classifier = record.theme.cics_classifier || [],
                            csrc_classifier = record.theme.csrc_classifier || [],
                            sw_classifier = record.theme.sw_classifier || [];
                        let show_cics_classifier = cics_classifier.join('/') || null,
                            show_csrc_classifier = csrc_classifier.join('/') || null,
                            show_sw_classifier = sw_classifier.join('/') || null;
                        let showText = '';

                        if (show_cics_classifier && show_csrc_classifier && show_sw_classifier) {
                            showText = show_cics_classifier + '\n' + show_csrc_classifier + '\n' + show_sw_classifier
                        }
                        else if (show_cics_classifier && show_csrc_classifier && !show_sw_classifier) {
                            showText = show_cics_classifier + '\n' + show_csrc_classifier
                        }
                        else if (show_cics_classifier && !show_csrc_classifier && show_sw_classifier) {
                            showText = show_cics_classifier + '\n' + show_sw_classifier
                        }
                        else if (show_cics_classifier && !show_csrc_classifier && !show_sw_classifier) {
                            showText = show_cics_classifier
                        }
                        else if (!show_cics_classifier && show_csrc_classifier && show_sw_classifier) {
                            showText = show_csrc_classifier + '\n' + show_sw_classifier
                        }
                        else if (!show_cics_classifier && show_csrc_classifier && !show_sw_classifier) {
                            showText = show_csrc_classifier
                        }
                        else if (!show_cics_classifier && !show_csrc_classifier && show_sw_classifier) {
                            showText = show_sw_classifier
                        }
                        else {
                            showText = '-'
                        }
                        return (<div style={{whiteSpace:'pre-line'}}>{showText}</div>);
                    }
                },
                {
                    title: '成立时间',
                    dataIndex: 'foundedTime',
                    key: 'foundedTime',
                    render: text =>{
                        return text || '-'
                    }
                },
                {
                    title: '上市时间',
                    dataIndex: 'marketTime',
                    key: 'marketTime',
                    render: text =>{
                        return text || '-'
                    }
                }
            ],
            tableData: [],
            page: page,
            pageSize: pageSize,
            pageTotal: 10,
            selectItem: [],          //当前选中的对象
            industryLoading: false,
            addLoading: false
        }

        this.getTableData = this.getTableData.bind(this);
        this.changeSelectClass = this.changeSelectClass.bind(this);
        this.pageChange = this.pageChange.bind(this);
        this.pageSizeChange = this.pageSizeChange.bind(this);
        this.viewClick = this.viewClick.bind(this);

        this.changeMulSelect = this.changeMulSelect.bind(this)
        this.mulSelectSearch = this.mulSelectSearch.bind(this)
    }

    componentDidMount() {
        this.props.fnSetType('companyList',this.state.keyword || '')
        //this.getDataFromList();
        this.getClassificationList();
        this.getTableData();

        let icons = document.querySelectorAll('.rs-picker-toggle-caret')
        icons.forEach(item =>{
            item.innerHTML = `<i aria-label="图标: down" style="color: rgba(0, 0, 0, 0.25);transform: scale(0.8576)" class="anticon anticon-down ant-select-arrow-icon"><svg viewBox="64 64 896 896" class="" data-icon="down" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path></svg></i>`
        })
    }

    componentWillReceiveProps(nextProps) {
        /*if ((nextProps.match.params.keyword!==undefined && this.state.keyword && nextProps.match.params.keyword !== this.state.keyword) || (nextProps.match.params.keyword===undefined && this.state.keyword)) {
            console.log("11")
            this.setState({
                keyword: nextProps.match.params.keyword || '',
                page: 0,
                pageTotal: 0
            }, () => {
                this.getTableData()
            })
        }*/
        if (this.props.location.search!==nextProps.location.search) {
            let searchData = nextProps.location.search || '';
            let npage = 0, ntheme = [], nthemeType = '1', npageSize = 10;
            queryTheme = '[]'
            if (searchData) {
                npage = JSON.parse(queryString.parse(searchData)['page'] || '0');
                npageSize = parseInt(queryString.parse(searchData)['pageSize'] || '10');
                nthemeType = queryString.parse(searchData)['themeType'] || '1';
                ntheme = JSON.parse(queryString.parse(searchData)['theme'] || '[]');
                queryTheme = queryString.parse(searchData)['queryTheme'] || '[]';
            }
            let {industryClassification, industryScreen, page, pageSize} = this.state;
            industryClassification.value = nthemeType;
            industryScreen.value = ntheme;
            page = npage;
            pageSize = npageSize;

            this.setState({
                industryClassification, industryScreen, page, pageSize
            }, () => {
                this.getTableData();
            })
        }

    }

    componentWillUnmount() {
        // 卸载异步操作设置状态
        this.setState = (state, callback) => {
            return;
        }
    }

    changeLocation = () =>{
        let themeSelect = [...this.state.industryScreen.value];
        let result = [];
        if (themeSelect.length > 0) {
            if (this.state.classificationSelect.length>0) {
                for (let i = 0; i < themeSelect.length; i++) {
                    formatSelectData(themeSelect[i], this.state.classificationSelect, result)
                }
                removeArrChild(result)
            }
        }
        queryTheme = JSON.stringify(result)
        console.log(queryTheme)

        let searchData = `?page=${this.state.page}&pageSize=${this.state.pageSize}&themeType=${this.state.industryClassification.value}&theme=${JSON.stringify(themeSelect)}&queryTheme=${queryTheme}`
        this.props.history.push({
            pathname: `${this.props.location.pathname}`,
            search: searchData
        })
    }

    /**
     * 获取表格信息
     */
    getTableData() {
        let postData = {
            type: 5,
            keyword: this.state.keyword,
            pageSize: this.state.pageSize,
            pageIndex: this.state.page,
            theme: queryTheme
        }
        this.setState({tableLoading: true}, () => {
            webSdk.search.researchReport(postData).then(res => {
                try {
                    if (!res.errCode || res.errCode === 0) {
                        let pageData = res.pager;
                        let page = pageData.index,
                            pageTotal = pageData.total,
                            tableData = res.reportList;
                        for (let i=0;i<tableData.length;i++) {
                            let abstract = tableData[i].abstract || '';
                            let newAbstract = abstract.split('。');
                            if (newAbstract.length>3) {
                                abstract = newAbstract.splice(0,3).join('。') + '...'
                            }
                            tableData[i].abstract = abstract
                        }
                        this.setState({
                            page, pageTotal, tableData,
                            tableLoading: false
                        })
                    } else {
                        this.setState({
                            tableLoading: false
                        })
                        message.error(res.errMsg || '获取企业列表失败，请稍后重试');
                    }
                } catch (e) {
                    this.setState({
                        tableLoading: false
                    })
                    message.error('获取企业列表失败，请稍后重试');
                }
            }).catch(e => {
                this.setState({
                    tableLoading: false
                })
                message.error('获取企业列表失败，请稍后重试');
            })
        })

    }

    /**
     * 获取行业分类列表
     */
    getClassificationList() {
        this.setState({
            classificationSelect: [],
            industryLoading: true
        }, () => {
            let postData = {
                type: this.state.industryClassification.value
            }
            webSdk.search.getClassificationList(postData).then(res => {
                try {
                    if (!res.errCode || res.errCode === 0) {
                        for (let i = 0; i < res.length; i++) {
                            renameData(res[i]);
                        }
                        //let industryScreen = { ...this.state.industryScreen };
                        //industryScreen.selectData = res;
                        this.setState({
                            //industryScreen,
                            classificationSelect: res,
                            industryLoading: false
                        })
                    } else {
                        this.setState({
                            industryLoading: false
                        })
                        message.error(res.errMsg || "获取行业分类列表失败")
                    }
                } catch (e) {
                    this.setState({
                        industryLoading: false
                    })
                    console.log(e)
                    message.error("获取行业分类列表失败")
                }
            }).catch(e => {
                this.setState({
                    industryLoading: false
                })
                message.error("获取行业分类列表失败")
            })
        })


        function renameData(data) {
            data.label = data.text;
            delete data.text;
            if (data.child && data.child.length > 0) {
                data.children = data.child;
                delete data.child;
                for (let i = 0; i < data.children.length; i++) {
                    renameData(data.children[i])
                }
            }
        }
    }

    /**
     * 修改行业分类标准
     */
    changeSelectClass(val) {
        let industryClassification = {...this.state.industryClassification};
        industryClassification.value = val;
        let industryScreen = {...this.state.industryScreen};
        industryScreen.value = [];
        this.setState({
            industryClassification,
            industryScreen
        }, () => {
            this.getClassificationList()
        })
    }

    /**
     * 页码变更
     */
    pageChange(page, pageSize) {
        this.setState({
            page: page - 1
        }, () => {
            this.changeLocation()
        })
    }

    /**
     * 页尺寸变更
     */
    pageSizeChange(current, size) {
        this.setState({
            page: 0,
            pageSize: size
        }, () => {
            this.changeLocation()
        })
    }

    /**
     * 表格查看事件
     */
    viewClick(record) {
        this.props.history.push({
            pathname: `/companyInfo/${record.id}`
        })
    }

    industryHasChange = false

    changeMulSelect(valueList) {
        if (!this.industryHasChange) {
            this.industryHasChange = true;
            let industryScreen = {...this.state.industryScreen};
            industryScreen.value = valueList;
            this.setState({
                industryScreen
            })
        } else {
            this.industryHasChange = false;
            let industryScreen = {...this.state.industryScreen};
            industryScreen.value = valueList;
            this.setState({
                industryScreen,
                page: 0
            }, () => {
                this.changeLocation();
            })
        }
    }

    mulSelectSearch() {
        if (this.industryHasChange) {
            this.setState({
                page: 0
            }, () => {
                this.changeLocation();
            })

            this.industryHasChange = false;
        }
    }

    clearIndustry() {
        this.industryHasChange = true;
    }

    render() {
        return (
            <div className="home-page__research-library">
                <div className="company-list__industry-screen__main">

                    <div className="company-list__industry-screen__item">
                        <div
                            className="company-list__industry-screen__item-label">{this.state.industryClassification.title}</div>
                        <div className="company-list__industry-screen__item-input">
                            <Select style={{ width: 160, height: 30 }}
                                value={this.state.industryClassification.value ? this.state.industryClassification.value : undefined}
                                onChange={(val) => this.changeSelectClass(val)}
                                placeholder="请选择行业分类标准">
                                {
                                    this.state.industryClassification.selectData.map((item, index) => {
                                        return (
                                            <Option value={item.value} key={index}>{item.label}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </div>
                    </div>


                    <div className="company-list__industry-screen__item">
                        <div
                            className="company-list__industry-screen__item-label">{this.state.industryScreen.title}</div>
                        <div className="company-list__industry-screen__item-input">
                            <MultiCascader cascade={false}
                                onChange={(vals, ev) => {
                                    console.log('change')
                                    this.changeMulSelect(vals)
                                }}
                                onClose={() => {
                                    console.log('close')
                                    this.mulSelectSearch()
                                }}
                                onClean={(ev) => {
                                    console.log('clear')
                                    this.clearIndustry()
                                }}
                                placeholder="请选择行业分类"
                                style={{ width: 160 }}
                                menuClassName="mult-select-menu"
                                data={this.state.classificationSelect}
                                value={this.state.industryScreen.value}
                                menuWidth={160}
                                block />
                                {/*disabled={this.state.industryLoading}*/}
                        </div>
                    </div>
                </div>

                <div className="company-list__table-main">
                    <Spin spinning={this.state.tableLoading}>
                        <div>
                            <Table
                                columns={this.state.columns}
                                dataSource={this.state.tableData}
                                rowClassName={(record, index) => index % 2 === 0 ? 'single-row report-row' : 'double-row report-row'}
                                rowKey={(record, index) => {
                                    return index
                                }}
                                onRow={(record, index) => {
                                    return {
                                        onClick: ev => this.viewClick(record)
                                    }
                                }}
                                pagination={{
                                    showSizeChanger: true,
                                    showQuickJumper: true,
                                    pageSizeOptions: ["10", "15", "20", "25"],
                                    current: this.state.page + 1,
                                    pageSize: this.state.pageSize,
                                    total: this.state.pageTotal,
                                    onChange: (page, pageSize) => this.pageChange(page, pageSize),
                                    onShowSizeChange: (current, size) => this.pageSizeChange(current, size),
                                    showTotal: total => `共 ${total} 条`
                                }}
                            />
                        </div>
                    </Spin>

                </div>
            </div>)
    }
}

export default CompanyList